import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../components/ProgressBar';
import ProgramSetup from './ProgramSetup';
import DeviceSetup from './DeviceSetup';
import QuestionnaireSetup from './QuestionnaireSetup';
import ContentSetup from './ContentSetup';
import { useSelector, useDispatch } from 'react-redux';
import { resetProgramState } from './AddProgramSlice';
import inputStyles from './ProgramManagement.module.css';
import useAxios from '../../services/AxiosConfig';
import styles from '../ManagementPages.module.css';
import containerStyles from './ProgramManagement.module.css';
import { LeftArrowIcon } from '../../components/icons/Icons';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { setModalitiesState } from './ModalitiesSlice';

const AddProgram = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const data = useSelector((state)=>state.addProgram);
    const [selectedOrg, setSelectedOrg] = useState(data.program.organizationId ?? 0);
    const modalities = useSelector((state)=>state.modalities.modalities);
    const devices = useSelector((state)=>state.programTemplateLists.devices);
    const [errorMessages, setErrorMessages] = useState({
        program:{
            nameMessage:"Please choose a name for the program",
            nameShowing:false,
            groupMessage:"Please select a group for the program",
            groupShowing:false
        },
        device:{
            message:"All devices are required to have a valid start time and frequency",
            showing:false,
            modalityMessage: "Please make sure upper limit > lower limit and target value is in between values",
            modalityShowing: false,
            modalityErrors: [],
            deviceIdErrors: []
        },
        questionnaire:{message:"All questionnaires are required to have a valid start time and frequency",showing:false},
        content:{
            contentMessage:"All content are required to have a valid start time and frequency",
            contentShowing:false,
            programMessage:"At least one device, questionnaire, or content is required to create a program",
            programShowing:false,
        },
    });

    const [page, setPage] = useState(0);

    const PageDisplay = () => {
        if (page === 0) {
            return <ProgramSetup
                        errors={errorMessages.program}
                        selectedOrg={selectedOrg}
                        setSelectedOrg={setSelectedOrg}
                    />
        } else if (page === 1) {
            return <DeviceSetup errors={errorMessages.device}/>
        } else if (page === 2) {
            return <QuestionnaireSetup errors={errorMessages.questionnaire}/>
        } else if (page === 3){
            return <ContentSetup errors={errorMessages.content}/>
        }
        else {
            navigate('/program-management');
        }
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Program Setup";
        } else if (step === 1) {
            return "Device Setup";
        } else if (step === 2) {
            return "Questionnaire Setup";
        } else if (step === 3){
            return "Content Setup";
        } 
    };

    const ProgressBarNavigation = (status) => {
        const pages = {
            "Program Setup": 0,
            'Device Setup': 1,
            "Questionnaire Setup": 2,
            "Content Setup": 3
        };
        if (pages[status] || pages[status]===0) {
            setPage(pages[status]);
        } else {
            return;
        }
    };

    useEffect(()=>{
        // check if we're updating a program and save the modalities for device selection to the slice
        if (data.updating) {
            dispatch(setModalitiesState({programDevices:data.modalities, devicesList:devices}));
        } 
    },[devices]);

    const nextPage = () => {
       setPage(page + 1);
    };

    const prevPage = () => {
        if (page !== 0) {
            setPage(page - 1);
        }
    };

    const checkPageValidity = () => {
        if (page===0) {
            let tempError = {...errorMessages};
            let nameInvalid = false;
            let groupInvalid = false;
            if (data.program.name === "") {
                // program needs a name and needs to display error message
                nameInvalid = true;
            } 
            if (data.program.groupId === 0 || data.program.groupId === "") {
                //program doesnt have a valid groupId
                groupInvalid = true;
            }
            tempError.program.nameShowing = nameInvalid;
            tempError.program.groupShowing = groupInvalid;
            setErrorMessages(tempError);
            return groupInvalid || nameInvalid;
        } else if ( page === 1) {
            let tempError = {...errorMessages};
            let invalid = false;
            let modalitiesInvalid = false;
            let modalitiesListInvalid = [];
            let devicesListInvalid = [];
            if (data.devices.length >= 1) {
                let countValid = 0;
                data.devices.forEach((device)=>{
                    // check if all devices have a start time and a frequency/isContinuous
                    if (device.startTime !== "" && (device.frequency >= 0 || device.isContinuous)) {
                        if (!device.isContinous) {
                            if (device.interval === "Day") {
                                if (device.frequency > 365) {
                                    return;
                                }
                            } else if (device.interval === "Min") {
                                if (device.frequency > 60) {
                                    return;
                                }
                            }  else if (device.interval === "Hour") {
                                if (device.frequency > 24) {
                                    return;
                                }
                            } 
                        }
                        countValid++;
                    };
                })
                if (countValid !== data.devices.length) {
                    // devices are missing information
                    invalid = true;
                };
                modalities.forEach((mod)=> {
                    if (mod.upperLimit!==0 && mod.lowerLimit !== 0) {
                        if (mod.upperLimit < mod.lowerLimit || ( mod.targetValue !== 0 && (mod.targetValue > mod.upperLimit || mod.targetValue < mod.lowerLimit))) {
                            modalitiesInvalid = true;
                            modalitiesListInvalid.push(mod.deviceModalityId)
                            if (!devicesListInvalid.some(id => id === mod.deviceId)) {
                                devicesListInvalid.push(mod.deviceId);
                            }
                        }
                    }
                })
            } 
            tempError.device.showing = invalid;
            tempError.device.modalityShowing = modalitiesInvalid;
            tempError.device.modalityErrors = modalitiesListInvalid;
            tempError.device.deviceIdErrors = devicesListInvalid; 
            setErrorMessages(tempError);
            return invalid || modalitiesInvalid;
        } else if ( page === 2) {
            let tempError = {...errorMessages};
            let invalid = false;
            if (data.questionnaires.length >= 1) {
                let countValid = 0;
                data.questionnaires.forEach((q)=>{
                    // check if all devices have a start time and a frequency/isContinuous
                    if (q.startTime !== "" && q.frequency >= 1) {
                        if (q.interval === "Day") {
                            if (q.frequency > 365) {
                                return;
                            }
                        } else if (q.interval === "Min") {
                            if (q.frequency > 60) {
                                return;
                            }
                        }  else if (q.interval === "Hour") {
                            if (q.frequency > 24) {
                                return;
                            }
                        } 
                        countValid++;
                    };
                })
                if (countValid !== data.questionnaires.length) {
                    // devices are missing information
                    invalid = true;
                };
            } 
            tempError.questionnaire.showing = invalid;
            setErrorMessages(tempError);
            return invalid;
        }
        else if (page === 3) {
            let tempError = {...errorMessages};
            let contentInvalid = false;
            let programInvalid = false;
            if (data.contents.length >= 1) {
                let countValid = 0;
                data.contents.forEach((content)=>{
                    // check if all devices have a start time and a frequency/isContinuous
                    if (content.startTime !== "" && content.frequency >= 1) {
                        if (content.interval === "Day") {
                            if (content.frequency > 365) {
                                return;
                            }
                        } else if (content.interval === "Min") {
                            if (content.frequency > 60) {
                                return;
                            }
                        }  else if (content.interval === "Hour") {
                            if (content.frequency > 24) {
                                return;
                            }
                        } 
                        countValid++;
                    };
                })
                if (countValid !== data.contents.length) {
                    // devices are missing information
                    contentInvalid = true;
                };
            } if (data.devices.length === 0 && data.questionnaires.length === 0 && data.contents.length === 0) {
                // check if there's at least one selected device, questionnaire, or content
                programInvalid = true;
            } 
            tempError.content.contentShowing = contentInvalid;
            tempError.content.programShowing = programInvalid;
            setErrorMessages(tempError);
            return contentInvalid || programInvalid;
        }
        return false;
    };

    const formatProgramData = () => {
        const devices = data.devices;
        let newDevices = [];
        if (devices.length >= 1) {
            // for each unique device id, iterate through all modalities and create new object with modality info (lower + upper limit, target goal) + device info combined (frequency, interval, etc,)
            devices.forEach((device)=> {
                let tempDevices = [];
                modalities.forEach((mod)=> {
                    if (mod.deviceId === device.deviceId){
                            let tempDevice = {...device};
                            tempDevice['deviceModalityId'] = mod.deviceModalityId;
                            tempDevice['upperLimit'] = mod.upperLimit;
                            tempDevice['lowerLimit'] = mod.lowerLimit;
                            tempDevice['targetValue'] = mod.targetValue;
                            tempDevice['isAlarmActive'] = false;
                            if (device.deviceId === 1) {
                                tempDevice['startTime'] = mod.startTime;
                                tempDevice['frequency'] = mod.frequency;
                                tempDevice['interval'] = mod.interval;
                                tempDevice['isContinuous'] = mod.isContinuous;
                            }
                            if (mod.hasOwnProperty("templateId")) {
                                tempDevice['templateId'] = mod.templateId;
                            } else {
                                delete tempDevice['templateId'];
                            }
                            tempDevices.push(tempDevice);
                    }
                })
                if (tempDevices.length >= 1) {
                    newDevices = newDevices.concat(tempDevices);
                } else {
                    newDevices.push({...device});
                }
            })
        }
        // check if duration was filled out, if not set duration to 0
        const newProgram = { ...data.program, duration: data.program.duration === "" ? 0 : data.program.duration};
         //return new formatted data
        return { ...data, devices: newDevices, program: newProgram };

    };

    const handleSubmit = async () => {
        const newProgram = formatProgramData();
        delete newProgram['templateModalities'];
        const method = data.updating ? 'PUT' : 'POST';
        const url = data.updating ? `careprogram/${data.id}` : 'careprogram';
        // send program data
        try {
            const response = await httpService.request({
                method: method,
                headers: {"Content-Type": "application/json"},
                url: url,
                data: JSON.stringify(newProgram),
            });
            if (response) {
                navigate('/program-management');
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully ${data.updating ? 'updated' : 'added'} program`,
                        type: 'success'
                    })
                );
            }
        } catch (error) {
            console.log("error:"+error);
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Error ${data.updating ? 'updating' : 'adding'} program`,
                    type: 'danger'
                })
            );
        }
    };

    const onBackHandler = async () => {
        navigate('/program-management');
        dispatch(resetProgramState());
    };

    return (
        <div className={styles.MainContent}>
            <div className={styles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Program Management</span>
            </div>
            <div className={styles.MiddleContentAdd} style={{justifyContent:"start"}}>
                <div className={styles.ContentTitle}>{data.updating ? "Update " : "Add "}Program</div>
                <div style={{padding:"20px 0 10px"}}>
                    <ProgressBar page={page} navigateToStep={ProgressBarNavigation} getPageName={getPageName} totalSteps={4}/>
                </div>
                <div className={containerStyles.HideScrollContainer}>
                    {PageDisplay()}
                </div>
                {errorMessages.content.programShowing && 
                    <div className={inputStyles.ValidationMessageContainer}>
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="sm" style={{color:"red"}}/>
                        <span 
                            className={inputStyles.ValidationMessage}
                        >
                            {errorMessages.content.programMessage}
                        </span>
                    </div>
                }
                <div style={{display:"flex", paddingBottom:"10px", gap:"10px"}}>
                        <button 
                            className={styles.CreateButton}
                            style={{marginRight: "10px"}}
                            onClick={(e) => {
                                e.preventDefault()
                                if (checkPageValidity()){
                                    return;
                                }
                                if (page===3) {
                                   handleSubmit();
                                } else {
                                    nextPage();
                                }
                            }}
        
                        >
                        {(page===3 && data.updating) ? "Update" : page===3 ? "Create Program" : "Next"} 
                        </button>
                        {page!==0 ? (
                            <button 
                                className={styles.BackButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    prevPage();
                                }}
                            >
                            Back
                            </button>)
                        : null }
                </div>
            </div>
        </div>
    );

}

export default AddProgram;