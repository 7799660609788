import React from "react";
import Select from 'react-select';
import { customStyles } from '../../components/AccountManagement/CustomStyles';
import '../../components/AccountManagement/Accounts.css';

const SelectOrganization = () => {

    const handleSubmit = () => {
        alert("success");
    };

    return (
       <div className="main-container">
           <div className="activate-account-container">
               <div style={{width:"80%", display:"flex", flexDirection:"column", height:"95%"}}>
                    <h2 className="reg-title" style={{margin:"30px 0 20px 0"}}>Select</h2>
                    <p className="input-name">Please choose your organization to access the homepage</p>
                    <div style={{paddingTop:"15px"}}></div>
                    <form style={{display:"flex", flexDirection:"column", justifyContent:"space-between", flex:"1"}}>
                        <div style={{position: "relative"}}>
                            <span className="floating-label" style={{zIndex:"2"}}>Organization</span>
                            <Select
                                styles={customStyles.addressStyleActivate}
                                className="dropdown-input"
                                placeholder=""
                                isSearchable={true}
                            />
                        </div>
                        <button className="login-button" type="submit" onClick={handleSubmit}>Continue to App</button>
                    </form>
               </div>
           </div>
       </div>
    )
}

export default SelectOrganization;