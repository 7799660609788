import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Patient.module.css';
import VitalsView from './PatientViews/VitalsView';
import CareProgramView from './PatientViews/CareProgramView';
import { resetFilterState } from './Slices/PatientDetailFilterSlice';
import DetailsView from './PatientViews/DetailsView';
import CareTeamView from './PatientViews/CareTeamView';
import ReportsView from './PatientViews/ReportsView';
import NotesView from './PatientViews/NotesView';
import QuestionnaireView from './PatientViews/QuestionnaireView';
import { setMenuItem } from './Slices/PatientDetailSlice';

const PatientDetailBody = () => {

  const selectedMenuItem = useSelector((state)=>state.patientDetailsSlice.detailTab);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(resetFilterState());
  },[]);

  const views = {
    'Vitals': <VitalsView />,
    'CareProgram': <CareProgramView />,
    'PatientDetails': <DetailsView />,
    'CareTeam': <CareTeamView />,
    'Reports': <ReportsView />,
    'Notes': <NotesView />,
    'Questionnaire': <QuestionnaireView />
  };

  return (
    <div className={styles.BodyContainer}>
      <div className={styles.PatientDetailsList}>
        <div
          className={selectedMenuItem === 'Vitals' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('Vitals'))}
        >
          <p>Vitals</p>
        </div>
        <div
          className={selectedMenuItem === 'Questionnaire' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('Questionnaire'))}
        >
          <p>Questionnaire</p>
        </div>
        <div
          className={selectedMenuItem === 'Notes' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('Notes'))}
        >
          <p>Notes</p>
        </div>
        <div
          className={selectedMenuItem === 'Reports' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('Reports'))}
        >
          <p>Patient-Submitted Reports</p>
        </div>
        <div
          className={selectedMenuItem === 'CareProgram' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('CareProgram'))}
        >
            <p>Care Program</p>
        </div>
        <div
          className={selectedMenuItem === 'CareTeam' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('CareTeam'))}
        >
          <p>Care Team</p>
        </div>
        <div
          className={selectedMenuItem === 'PatientDetails' ? styles.Selected : ''}
          onClick={() => dispatch(setMenuItem('PatientDetails'))}
        >
          <p>Patient Details</p>
        </div>
      </div>
      {views[selectedMenuItem]}
    </div>
  );
};

export default PatientDetailBody;
