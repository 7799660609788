import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    groupList: [],
    locationList: [],
    teamList: [],
    selectedRows: [],
    selectAll: false,
    groupName: '',
    name: '',
    description: '',
    groupId: '',
    type: '',
    patientType: '',
    organizaitonId: '',
};

const organizationManagementSlice = createSlice({
    name: 'organizationManagement',
    initialState,
    reducers: {
        setGroupList: (state, action) => {
            state.groupList = action.payload;
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setFieldValue: (state, action) => {
            const { controlName, value } = action.payload;
            state[controlName] = value;
        },
        setGroupName: (state, action) => {
            state.groupName = action.payload;
        },

        setGroupId: (state, action) => {
            state.groupId = action.payload;
        },

        setName: (state, action) => {
            state.name = action.payload;
        },

        setDescription: (state, action) => {
            state.description = action.payload;
        },

        deleteQuestion: (state, action) => {
            const questionId = action.payload;
            state.questions = state.questions.filter((question) => question.questionId !== questionId);
        },

        clearQuestion: (state, action) => {
            state.questions = [];
        },

        resetAddQuestionnaire: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const {
    setGroupList,
    setSelectAll,
    setSelectedRows,
    setDataList,
    setFieldValue,
    setGroupName,
    setName,
    setDescription,
    setType,
    setGroupId,
    setPatientType,
    setQuestionnaireId,
    setSelectedQuestionnaireType,
    setUploadedFiles,
    setQuestion,
    setAnswer,
    deleteQuestion,
    addQuestionToPreview,
    clearQuestion,
    resetAddQuestionnaire,
    setShowPreview, } = organizationManagementSlice.actions;

export default organizationManagementSlice.reducer;
