import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form} from 'react-bootstrap';
import styles from '../OrganizationManagement.module.css';
import { AddButtonOrgHierarchyIcon } from '../../../components/icons/Icons';
import deleteIcon from '../../../assets/delete_icon.png';
import { useSelector, useDispatch } from 'react-redux';
import managementStyles from '../../ManagementPages.module.css';
import { showAlert } from '../../../components/PerinHealthAlertSlice';
import useAxios from '../../../services/AxiosConfig';
import { setUserGroups, setOrganizationsHierarchy } from '../../../slicers/UserSlice';


const AddGroupRowComponent = ({ existingData, selectedGroupId, isAdmin, organizationName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [collapsedSubGroups, setCollapsedSubGroups] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const userId = useSelector((state)=>state.userInfo.id);
  const scrollRef = useRef(null);

  const axiosConfig = useSelector((state) => state.axiosConfig);
  const axios = useAxios(axiosConfig.envURL);

  const columnStyles = {
    name: { width: '30%' },
    isActive: { width: '8%' }, // Adjust widths as needed
    delete: { width: '2%'}
  };

  useEffect(() => {
    if (existingData && existingData.groups) {
      setData(existingData.groups); // Set the groups array as the initial data
    }
  }, [existingData]);

  const addGroup = () => {
    setData([...data, { organizationName: '', isActive: true, subGroups: [] }]);
    setTimeout(() => scrollToBottom(), 0);
  };

  const addSubGroup = (groupIndex) => {
    const newData = [...data];

    // Check if 'subGroups' is not an array or doesn't exist, then initialize it as an empty array
  if (!Array.isArray(newData[groupIndex].subGroups)) {
    newData[groupIndex].subGroups = [];
  }

    newData[groupIndex].subGroups.push({ subGroupName: '', isActive: true, teams: [] });
    setData(newData);
    setTimeout(() => scrollToBottom(), 0);
  };

  const addTeam = (groupIndex, subGroupIndex) => {
    const newData = [...data];
    if (!Array.isArray(newData[groupIndex].subGroups[subGroupIndex].teams)) {
      newData[groupIndex].subGroups[subGroupIndex].teams = [];
    }
    newData[groupIndex].subGroups[subGroupIndex].teams.push({ teamName: '', isActive: true });
    setData(newData);
    setTimeout(() => scrollToBottom(), 0);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const deleteGroup = (groupIndex) => {
    const newData = [...data];
    newData.splice(groupIndex, 1); // Remove the group at the specified index
    setData(newData);
  };
  
  const deleteSubGroup = (groupIndex, subGroupIndex) => {
    const newData = [...data];
    newData[groupIndex].subGroups.splice(subGroupIndex, 1); // Remove the subgroup
    setData(newData);
  };
  
  const deleteTeam = (groupIndex, subGroupIndex, teamIndex) => {
    const newData = [...data];
    newData[groupIndex].subGroups[subGroupIndex].teams.splice(teamIndex, 1); // Remove the team
    setData(newData);
  };

  const handleInputChange = (event, path) => {
    const { name, value, type, checked } = event.target;
    const newData = [...data];
  
    // Access the node that was changed
    const node = path.reduce((acc, cur, idx) => {
      if (idx === path.length - 1) {
        acc[cur][name] = type === 'checkbox' ? checked : value;
      }
      return acc[cur];
    }, newData);
  
    // Propagate changes down the hierarchy
    if (type === 'checkbox' && name === 'isActive') {
      const propagateDown = (node) => {
        if (Array.isArray(node.subGroups)) {
          node.subGroups.forEach(subGroup => {
            subGroup.isActive = node.isActive; 
            if (Array.isArray(subGroup.teams)) {
              subGroup.teams.forEach(team => {
                team.isActive = node.isActive;
              });
            }
            propagateDown(subGroup); 
          });
        }
  
        if (Array.isArray(node.teams)) {
          node.teams.forEach(team => {
            team.isActive = node.isActive; 
          });
        }
      };
  
      propagateDown(node);
    }
  
    // Propagate changes up the hierarchy
    if (type === 'checkbox' && name === 'isActive' && checked) {
      const propagateUp = (path) => {
        path.reduce((acc, cur, idx) => {
          if (idx < path.length - 1) {
            acc[cur].isActive = true; // Set parent node to active
          }
          return acc[cur];
        }, newData);
      };
      propagateUp(path);
    }
  
    setData(newData);
  };
  
  

  const resetUserGroups = () => {
    let groupsApi = isAdmin ? "admin/groups" : `list/groups/${userId}`;
    axios.get(groupsApi)
    .then((responseData) => {
        if (responseData.data.data) {
            dispatch(setUserGroups(responseData.data.data));
            dispatch(setOrganizationsHierarchy(responseData.data.data));
        } else {
            console.error('error');
        }
    })
    .catch((err) => {
        console.log(err);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasInvalidGroups = false;
    let errorMessage = '';

    data.forEach(group => {
      if (!group.subGroups || group.subGroups.length === 0) {
        dispatch(
          showAlert({
              header: "Error",
              message: "Every group must have at least one subgroup with a team.",
              type: 'danger'
          })
        );
        // errorMessage = 'Every group must have at least one subgroup with a team.';
        hasInvalidGroups = true;
      } else {
        group.subGroups.forEach(subGroup => {
          if (!subGroup.teams || subGroup.teams.length === 0) {
            dispatch(
              showAlert({
                  header: "Error",
                  message: "Every subgroup must have at least one team.",
                  type: 'danger'
              })
            );
            // errorMessage = 'Every subgroup must have at least one team.';
            hasInvalidGroups = true;
          }
        });
      }
    });

    if (hasInvalidGroups) {
      setErrorMessage(errorMessage);
      return;
    }

    const normalizedData = data.map(group => ({
      ...group,
      subGroups: (group.subGroups || []).map(subGroup => ({
        ...subGroup,
        teams: (subGroup.teams || [])
      }))
    }));

    const requests = normalizedData.map(group => {
      const groupWithOrganizationId = { ...group, organizationId: selectedGroupId, organizationName: organizationName };
      const url = group.groupId === undefined ? 
          `${axiosConfig.envURL}organization/group/subgroups/teams` : 
          `${axiosConfig.envURL}organization/group/${group.groupId}/subgroups/teams`;
    
      return axios.request(url, {
          method: group.groupId === undefined ? 'POST' : 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(groupWithOrganizationId),
      }).then(response => {
          return response.data; // Use response.data instead of response.json()
      });
  });
  
    // Wait for all requests to finish
    Promise.all(requests)
      .then(data => {
        setErrorMessage('');
        resetUserGroups();
        
        // Wait for 2 seconds before navigating or refreshing
        setTimeout(() => {          
          navigate('/organization-management');
          dispatch(
            showAlert({
                header: "Success",
                message: `Saved organization successfully.`,
                type: 'success'
            })
          );
           window.location.reload();
        }, 2000);
      })
      .catch(error => {
        console.error('Error:', error);
        setErrorMessage(`An error occurred: ${error.message}. Please try again.`);
        dispatch(
            showAlert({
                header: "Error",
                message: `Error saving organization: ${error.message}`,
                type: 'danger'
            })
        );
    });
    
  };

  const toggleGroupCollapse = (groupId) => {
    setCollapsedGroups(prev => ({ ...prev, [groupId]: !prev[groupId] }));
  };

  const toggleSubGroupCollapse = (groupId, subGroupId) => {
    const key = `${groupId}-${subGroupId}`;
    setCollapsedSubGroups(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <form style={{height:"100%"}} onSubmit={handleSubmit}>
      {isAdmin && (
        <span onClick={addGroup} className={styles.MainButtonText}>
          <AddButtonOrgHierarchyIcon  /> Add Group
        </span>
      )}
      <div className={styles.ScrollContainer} ref={scrollRef}>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      <table className={styles.MainTable}>
        <thead>
          <tr>
            <th style={columnStyles.name}>Group</th>
            <th style={columnStyles.name}>Subgroup</th>
            <th style={columnStyles.name}>Team</th>
            <th style={columnStyles.isActive}>IsActive</th>
            <th style={columnStyles.delete}></th>
          </tr>
        </thead>
        <tbody>
          {data.map((group, gIndex) => (
            <React.Fragment key={group.id || gIndex}>
              <tr onClick={() => toggleGroupCollapse(group.id || gIndex)}>
                <td>
                <div className={styles.flexRow}>
                  <span>{collapsedGroups[group.id || gIndex] ? '+' : '-'}</span>
                  <Form.Control
                    className={styles.inputField}
                    type="text"
                    value={group.groupName}
                    name="groupName"
                    onChange={(e) => handleInputChange(e, [gIndex])}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>     
                </td>
                <td></td>
                <td></td>
                <td>
                  <Form.Check
                      type="switch"
                      label="Active"
                      checked={group.isActive}
                      name="isActive"
                      onChange={(e) => handleInputChange(e, [gIndex])}
                      onClick={(e) => e.stopPropagation()}
                    />
                </td>
                {!group.groupId && ( // Conditionally render delete button for groups
                  <td onClick={(e) => e.stopPropagation()}>
                    <img src={deleteIcon} alt="delete" onClick={() => deleteGroup(gIndex)} className="delete-icon" />
                  </td>
                )}
              </tr>
              {!collapsedGroups[group.id || gIndex] && (group.subGroups || []).map((subGroup, sgIndex) => (
                <React.Fragment key={subGroup.id || sgIndex}>
                  <tr onClick={() => toggleSubGroupCollapse(group.id || gIndex, subGroup.id || sgIndex)}>
                    <td></td>
                    <td>
                      <div className={styles.flexRow}>
                        <span>{collapsedSubGroups[`${group.id || gIndex}-${subGroup.id || sgIndex}`] ? '+' : '-'}</span>
                        <Form.Control
                          className={styles.inputField}
                          type="text"
                          value={subGroup.subGroupName}
                          name="subGroupName"
                          onChange={(e) => handleInputChange(e, [gIndex, 'subGroups', sgIndex])}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>                  
                    </td>
                    <td></td>
                    <td>
                    <Form.Check
                      type="switch"
                      label="Active"
                      checked={subGroup.isActive}
                      name="isActive"
                      onChange={(e) => handleInputChange(e, [gIndex, 'subGroups', sgIndex])}
                      onClick={(e) => e.stopPropagation()}
                    />
                    </td>
                    {!subGroup.subGroupId && ( // Conditionally render delete button for subgroups
                      <td onClick={(e) => e.stopPropagation()}>
                        <img src={deleteIcon} alt="delete" onClick={() => deleteSubGroup(gIndex, sgIndex)} className="delete-icon" />
                      </td>
                    )}
                  </tr>
                  {!collapsedSubGroups[`${group.id || gIndex}-${subGroup.id || sgIndex}`] && (subGroup.teams || []).map((team, tIndex) => (
                    <tr key={team.id || tIndex}>
                      <td></td>
                      <td></td>
                      <td>
                        <div className={styles.flexRow}> {/* Flex container for alignment */}
                          {/* Assuming there's no need to collapse/expand individual teams, so no +/- sign here */}
                          <Form.Control
                            className={styles.inputField} // Ensure the input takes up the majority of the cell width
                            type="text"
                            value={team.teamName}
                            name="teamName"
                            onChange={(e) => handleInputChange(e, [gIndex, 'subGroups', sgIndex, 'teams', tIndex])}
                            onClick={(e) => e.stopPropagation()} // Prevent collapsing/expanding when interacting with the input
                          />
                        </div>
                      </td>
                      <td>
                      <Form.Check
                        type="switch"
                        label="Active"
                        checked={team.isActive}
                        name="isActive"
                        onChange={(e) => handleInputChange(e, [gIndex, 'subGroups', sgIndex, 'teams', tIndex])}
                        onClick={(e) => e.stopPropagation()}
                      />
                      </td>
                        {!team.teamId && ( // Conditionally render delete button for subgroups
                            <td onClick={(e) => e.stopPropagation()}>
                              <img src={deleteIcon} alt="delete" onClick={() => deleteTeam(gIndex, sgIndex, tIndex)} className="delete-icon" />
                            </td>
                          )}

                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td colSpan="2">
                      {!collapsedSubGroups[`${group.id || gIndex}-${subGroup.id || sgIndex}`] && 
                        <span onClick={() => addTeam(gIndex, sgIndex)} className={styles.ButtonText}>
                          <AddButtonOrgHierarchyIcon  /> Add Team
                        </span>
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <td></td>
                <td colSpan="3">                  
                  {!collapsedGroups[group.id || gIndex] && 
                    <span onClick={() => addSubGroup(gIndex)} className={styles.ButtonText}>
                    <AddButtonOrgHierarchyIcon  /> Add Subgroup
                  </span>}
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td colSpan="4">

            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div style={{ marginTop: '10px' }}>
      <button type="submit" className={managementStyles.CreateButton}>Submit</button>
      </div>
    </form>
  );
};

export default AddGroupRowComponent;
