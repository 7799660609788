import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { closeAlert } from './PerinHealthAlertSlice'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PerinHealthAlert.module.css';

const PerinHealthAlert = ( props) => {

    const { 
        show, 
        type,
        header, 
        message, 
        dismissable
    } = props;

    const getAlertClass = (type) => {
        switch (type) {
          case 'success':
            return styles.successAlert;
          case 'danger':
            return styles.dangerAlert;
          case 'warning':
            return styles.warningAlert;
          case 'info':
            return styles.infoAlert;
          default:
            return styles.warningAlert;
        }
    };
    

    const dispatch = useDispatch();

    useEffect(() => {
        if (!dismissable) {
            const timeoutId = setTimeout(() => {
                dispatch(closeAlert());
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [dismissable, dispatch]);

    return (
        <div className={styles.AlertContainer}>
            <Alert variant={type} 
                show={show} 
                onClose={() => dispatch(closeAlert())} 
                style={{padding:"5px 5px"}}
                className={`${styles.Alert} ${getAlertClass(type)}`}
            >
                <Alert.Heading className={styles.AlertHeading}>
                    <div style={{display:"flex", columnGap:"8px"}}>
                        {type==='success' &&
                            <div>
                                <FontAwesomeIcon icon="fa-solid fa-circle-check" style={{color: "#ffffff",}} />
                            </div>
                        }
                        {type==='danger' &&
                            <div>
                                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" style={{color: "#721c24",}}/>
                            </div>
                        }
                        {header}
                    </div>
                    {dismissable &&
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => dispatch(closeAlert())}></button>
                    }
                </Alert.Heading>
                {message &&
                    <p className={styles.AlertBody}>
                        {message}
                    </p>
                }
            </Alert>
        </div>
    );
};

export default PerinHealthAlert;
