import React, { useState } from 'react';
import './DeviceManagement.css';
import styles from '../ManagementPages.module.css';
import { Container, Form, FloatingLabel, Row, Col } from 'react-bootstrap';

const DeviceSelection = ({index, setDevices, deviceList, updating = false, updateDeviceInfo = []}) => {

    const [device, setDevice] = useState({
        id:"",
        quantity:0
    });
    
    const initialQuantity = updating ? updateDeviceInfo.quantity : 0;
    let [updatingQuantity, setUpdatingQuantity] = useState(initialQuantity);
    

    const [modelList, setModelList] = useState([]);
    
    const handleDeviceChange = (e) => {
        handleModelList(e.target.value);
    };

    const handleModelList = (manufacturer) => {
        let tempModelList = [];
        deviceList.forEach((device) => {
            if(device.manufacturer===manufacturer){
                device.models.forEach((model)=>{
                    tempModelList.push({model:model.model,id:model.id})
                })
            }
        });
        setModelList(tempModelList);
        if (tempModelList.length === 0) {
            setDevice(prevDevice => ({
                ...prevDevice,
                id: ""
            }));
            updateDevices("");
        } else {
            setDevice(prevDevice => ({
                ...prevDevice,
                id: Number(tempModelList[0].id)
            }));
            updateDevices(Number(tempModelList[0].id));
        }
    };

    const handleModelChange = (e) => {
        setDevice(prevDevice => ({
            ...prevDevice,
            id: Number(e.target.value)
        }));
        updateDevices(Number(e.target.value));
    };

    const handleQuantityChange = (e) => {
        if (updating) {
            setUpdatingQuantity(e.target.value);
            setDevices(e.target.value);
        } else {
            setDevice(prevDevice => ({
                ...prevDevice,
                quantity: Number(e.target.value)
            }));
            updateDevices(null, Number(e.target.value));
        };
    };


    const updateDevices = (i=null,q=null,) => {
        setDevices(prevDevices => {
            const updatedDevices = [...prevDevices];
            if (i !== null) {
                updatedDevices[index] = { ...updatedDevices[index], id: i};
            }
            if (q !== null) {
                updatedDevices[index] = { ...updatedDevices[index], quantity: q };
            }
            return updatedDevices;
        });
    };

    return (
        <Container style={{paddingLeft:"0px"}}>
            <Form>
                <Row className='device-row' gap={10} xs="auto">
                    <Col>
                        <Form.Group className="mb-3">
                        <FloatingLabel controlId="floatingSelect" label="Device">
                        <Form.Select
                            className={`${styles.BorderedComponent}`}
                            onChange={handleDeviceChange}
                            style={{width:"300px"}}
                            disabled={updating}
                        >
                            {updating ? <option value={0}>{updateDeviceInfo.manufacturer}</option> : <option value={0}>Device</option>}
                            {deviceList.map((item, index) => (
                                <option key={index} value={item.manufacturer}>{item.manufacturer}</option>
                            ))}
                        </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="floatingSelect" label="Manufacturer/Model">
                            <Form.Select
                                className={`${styles.BorderedComponent}`}
                                onChange={handleModelChange}
                                disabled={updating ? true : false}
                                style={{width:"300px"}}
                            >
                            {updating && <option value={0}>{updateDeviceInfo.model}</option>}
                            {device.id === "" && 
                            <option value={0}>Manufacturer/Model</option>} 
                            {modelList.map((item, index) => (
                                <option key={index} value={item.id}>{item.model}</option>
                            ))}
                            </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel 
                                controlId="floatingInput"
                                label="Quantity*"
                                className="mb-3"
                            >
                            <Form.Control
                                placeholder="Quantity*"
                                className={`${styles.BorderedComponent}`}
                                type="number"
                                style={{width:"130px"}}
                                defaultValue={updatingQuantity}
                                onChange={handleQuantityChange}
                            />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>  
        </Container>
    );

}

export default DeviceSelection;