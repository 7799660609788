import { useContext, useEffect, useState } from 'react';
import { WebSocketContext } from './webSocketContext';

export const useWebSocket = () => {
    const { ws, wsConnected } = useContext(WebSocketContext);

    const [receivedMessage, setReceivedMessage] = useState(null);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            setReceivedMessage(message);
        };

        if (wsConnected && ws) {
            ws.addEventListener('message', handleMessage);
        }

        return () => {
            if (wsConnected && ws) {
                ws.removeEventListener('message', handleMessage);
            }
        };
    }, [wsConnected, ws]);

    const sendMessage = (message) => {
        if (wsConnected && ws) {
            ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not connected.');
        }
    };

    return { sendMessage,receivedMessage, wsConnected };
};
