import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../components/PerinHealthGridSlice';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import FilterManagementView from '../../components/FilterManagementView';
import managementStyles from '../ManagementPages.module.css';
import styles from './HubManagement.module.css';
import { LeftArrowIcon, OnlineIcon, OfflineIcon } from '../../components/icons/Icons';
import PatientHubInfoPopup from './components/PatientHubInfoPopup';
import useAxios from '../../services/AxiosConfig'; // Import useAxios
import { useLocation } from 'react-router-dom';

const PatientHubManagement = () => {
    const location = useLocation();
    const groupName = location.state?.groupName || 'Default Group';
    const groupId = location.state?.groupId;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountId = useSelector((state) => state.userInfo.id);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({ items: [] }); // Ensure items is always an array
    const [patientHubData, setPatientHubData] = useState([]);

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const iconMapping = {
        'Online': { icon: <OnlineIcon />, text: 'Online' },
        'Offline': { icon: <OfflineIcon />, text: 'Offline' },
      };

    const headers = [
        { key: 'id', label: "ID" },
        { key: 'assignedTo', label: "Assigned to"},
        { key: 'serialNumber', label: "Serial Number"},
        { key: 'status', label: "Status"},
        { key: 'OrganizationGroup_Name', label: "Group"},
        { key: 'AccountProgramHub_Genesis', label: "Date Assigned"},
    ];

    useEffect(() => {
        dispatch(setSelectedItem({}));
        getHubs(); // Fetch hubs data on component mount
    }, []);
    
    const formatDate = (dateString) => {
        if (!dateString) {
            return "N/A"; // Return "N/A" if dateString is null or undefined
        }
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "N/A"; // Check if the date object is valid
        }
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    };
    

    function formatTime(dateTimeString) {
        if (!dateTimeString) {
            return "N/A"; // Return "N/A" if dateTimeString is null or undefined
        }
        const time = new Date(dateTimeString);
        if (isNaN(time.getTime())) {
            return "N/A"; // Check if the datetime object is valid
        }
        return time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };    

    const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
        return "N/A"; // Return "N/A" if dateTimeString is null or undefined
    }
    const dateTime = new Date(dateTimeString);
    if (isNaN(dateTime.getTime())) {
        return "N/A"; // Check if the datetime object is valid
    }
    return dateTime.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true // Ensure AM/PM is included
    });
};
    

    const getHubs = async () => {    
        httpService.get(`hub/group/${accountId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const groupData = responseData.data.data.find(hub => hub.groupId === groupId);
                    
                    if (groupData) {
                        const filteredHubs = groupData.AssignedHubs || [];
    
                        const formattedHubs = filteredHubs.map(hub => ({
                            id: hub.accountHubId,  // Ensure the ID is included
                            assignedTo: `${hub.firstName} ${hub.lastName}`,
                            serialNumber: hub.serialNumber,
                            status: hub.status || 'Offline', // Assign "Offline" if status is null
                            OrganizationGroup_Name: groupData.OrganizationGroup_Name, // Ensure the group name is included
                            AccountProgramHub_Genesis: formatDate(hub.AccountProgramHub_Genesis),
                            lastTransmissionDate: formatDate(hub.AccountProgramHub_LastTransmission),
                            lastTransmissionTime: formatTime(hub.AccountProgramHub_LastTransmission),
                            lastConnection: formatDateTime(hub.AccountProgramHub_LastConnection),
                            providerName: hub.AccountProgramHub_ProviderName,
                            eID: hub.AccountProgramHub_eID
                        }));
    
                        setPatientHubData(formattedHubs);
                        setTotalHubs(groupData.GroupHub_Quantity);
                        setAssignedHubs(filteredHubs.length);
                    } else {
                        console.error('Group data not found');
                    }
                } else {
                    console.error('Invalid data format');
                }
            })
            .catch((err) => {
                console.error('Error fetching hubs:', err);
            });
    };    
    
    const [totalHubs, setTotalHubs] = useState(0);
    const [assignedHubs, setAssignedHubs] = useState(0);

    const onRowClickHandler = (rowItem) => {
        const defaultData = {
            assignedTo: '',
            serialNumber: '',
            allStatuses: [], // Ensure this is always an array
            lastTransmissionDate: '',
            lastTransmissionTime: '',
            lastConnection: '',
            providerName: '',
            eID: ''
        };
    
        const rowData = {
            ...defaultData,
            ...rowItem
        };
    
        setPopupData(rowData);
        setShowPopup(true);
    };
       
     
    const onDeleteHandler = () => {
        // Define your delete logic here
    };

    const onBackHandler = () => {
        navigate('/hub-management');
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
            <div className={managementStyles.MainContent}>
                <div className={managementStyles.ReturnToManagement} onClick={onBackHandler}>
                    <LeftArrowIcon />
                    <span>Back to Hub Management</span>
                </div>
                <div className={managementStyles.MiddleContent}>
                    <div style={{display:"flex", alignItems:"center", columnGap:"5px"}}>
                        <span className="subtitle-1">{`${groupName} |`}</span>
                        <span className={styles.HubsNumber}>{assignedHubs}</span>
                        <span className={styles.HubsLabel}>/{totalHubs}</span>
                        <span className={styles.HubsLabel} style={{color:"#30584A"}}>Hubs Assigned</span>
                    </div>
                    <FilterManagementView
                        isOrganization={false}
                        isGroup={false}
                        showAddButton={false}
                        showDeleteButton={false}
                        onDeleteHandler={onDeleteHandler}
                        modelHeader='Delete Hub?' 
                    />
                    <PerinHealthGrid
                        dataList={patientHubData}
                        headers={headers}
                        showCheckbox={false}
                        iconMapping={iconMapping} 
                        onDeleteRow={onDeleteHandler}   
                        onRowValueClick={onRowClickHandler}
                        rowValueAction = {'assignedTo'}
                        gridHeight='100%'
                    />
                </div>
                {showPopup && 
                    <PatientHubInfoPopup data={popupData} onClose={closePopup} />
                }
            </div>
    )
}

export default PatientHubManagement;
