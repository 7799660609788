import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedRows: [],
    selectedIndex: -1,
    chatList:[],
    recipient:{},
    reloadGrid: true,
    newChat:false,
    isMeetingVisible: false,
    isUpdateMode: false,
    isChatVisible : false,
    reloadMessageList:false,
    currentMeetings: [],
};

const careTeamSlice = createSlice({
    name: 'careTeam',
    initialState,
    reducers: {
        setStaffSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setStaffSelectedIndex: (state, action) => {
            state.selectedIndex = action.payload;
        },
        setStaffNewChat: (state, action) => {
            state.newChat = action.payload;
        },
        setStaffChatList: (state, action) => {
            state.chatList = action.payload.slice().reverse();
        },
        appendMessageToStaffList: (state, action) => {
            state.chatList.push(action.payload);
          },
        setStaffRecipient: (state, action) => {
            state.recipient = action.payload;
        },
        setFieldValue: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = value;
                }
            });
        },
        setFormValue: (state, action) => {
            const { name, value } = action.payload;
            state[name] = value;
        },
        toggleUpdateMode: (state) => {
            state.isUpdateMode = !state.isUpdateMode;
        },

        setStaffChatVisible : (state, action) => {
            state.isChatVisible = action.payload;
        },

        setIsStaffMeetingVisible : (state, action) => {
            state.isMeetingVisible = action.payload;
        },

        setReloadStaffMessageList : (state, action) => {
            state.reloadMessageList = action.payload;
        },
        resetStaffMessage: (state) => {
            Object.assign(state, initialState);
        },
        setCurrentCareTeamMeetings: (state, action) => {
            // Find index of the meeting with the same appointmentId
            const index = state.currentMeetings.findIndex(meeting => meeting.meetingId === action.payload.meetingId);
            if (index !== -1) {
                // Replace the old meeting with the new one
                state.currentMeetings[index] = action.payload;
            } else {
                state.currentMeetings.push(action.payload);
            }
        },
        setCurrentCareTeamMeetingJoined: (state, action) => {
            // Find index of the meeting with the same appointmentId
            const index = state.currentMeetings.findIndex(meeting => meeting.meetingId === action.payload.meeting.MeetingId);
            if (index !== -1) {
                // Replace the old meeting with the new one
                state.currentMeetings[index] = action.payload;
            } else {
                state.currentMeetings.push(action.payload);
            }
        },
        removeCareTeamMeeting: (state, action) => {
            state.currentMeetings = state.currentMeetings.filter(
                meeting => meeting.meetingId !== action.payload.meetingId
            );
        },
        removeCareTeamMeetingById: (state, action) => {
            state.currentMeetings = state.currentMeetings.filter(
                meeting => meeting.meeting.MeetingId !== action.payload
            );
        }
    },
});

export const {
    setStaffChatList,
    setStaffNewChat,
    setStaffSelectedRows,
    setStaffSelectedIndex,
    setStaffRecipient,
    setFieldValue,
    setFormValue,
    setIsStaffMeetingVisible,
    appendMessageToStaffList,
    resetStaffMessage,
    toggleUpdateMode,
    setStaffChatVisible,
    setReloadStaffMessageList,
    setCurrentCareTeamMeetings,
    removeCareTeamMeeting,
    setCurrentCareTeamMeetingJoined,
    removeCareTeamMeetingById
} = careTeamSlice.actions;

export default careTeamSlice.reducer;
