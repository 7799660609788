import { useState, useEffect, useCallback } from 'react';

const useIdleTimeout = (timeout, onTimeout) => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  const resetTimer = useCallback(() => {
    setLastActivity(Date.now());
  }, []);

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    const events = ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    events.forEach((event) => window.addEventListener(event, handleActivity));

    const interval = setInterval(() => {
      if (Date.now() - lastActivity >= timeout) {
        onTimeout();
      }
    }, 1000);

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleActivity));
      clearInterval(interval);
    };
  }, [lastActivity, resetTimer, timeout, onTimeout]);

  return;
};

export default useIdleTimeout;
