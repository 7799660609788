import { createSlice } from '@reduxjs/toolkit';

const  initialState = {
    showDetail: false,
    selectedAppointment: {},

};
const appointmentDetailSlice = createSlice({
    name: 'appointmentDetail',
    initialState,
    reducers: {
        setShowDetail: (state, action) => {
            state.showDetail = action.payload;
        },
        setSelectedAppointment: (state, action) => {
            state.selectedAppointment = action.payload;
        }
    },
});

export const { setShowDetail, setSelectedAppointment} = appointmentDetailSlice.actions;

export default appointmentDetailSlice.reducer;
