import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../ProgressBar';
import GroupRoleInfo from './GroupRoleInfo';
import PersonalInfo from './PersonalInfo';
import Preferences from './Preferences';
import HealthInfo from './HealthInfo'
import EmergencyContact from './EmergencyContact';
import { useSelector, useDispatch } from 'react-redux';
import useAxios from "../../services/AxiosConfig";
import styles from '../../views/ManagementPages.module.css';
import { openModal } from '../PerinHealthModelSlice';
import PerinHealthModal from "../PerinHealthModel";
import { showAlert } from '../PerinHealthAlertSlice';
import './Accounts.css';
import { LeftArrowIcon } from "../icons/Icons";
import { setFormField } from './RegistrationSlice';


const CreateAccount = () => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const perinHealthModal = useSelector((state) => state.perinHealthModal);
    const userData = useSelector((state) => state.registration);
    const updating = userData.updating;
    const selectedRole = userData.role.category;
    const roleName = userData.role.name;
    const [profileFile, setProfileFile] = useState(null);

    const initOrg = (updating && userData.groups.length !==0) ? userData.groups[0].organizationId : 0;
    const [selectedOrganization, setSelectedOrganization] = useState(initOrg);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);

    const navigateAccountManagment = () => {
        navigate('/account-management')
    };

    const [errorMessages, setErrorMessages] = useState({
        groupRoleInfo:{
            groupsMessage:"Please assign groups",
            groupsShowing:false,
            employeeShowing: false
        },
        personalInfo:{
            firstNameShowing:false,
            lastNameShowing: false,
            emailShowing: false,
            phoneShowing: false,
        },
    });

    // Regular expression to validate phone numbers in E.164 format
    const phoneNumberRegex = /^\+\d{1,15}$/;

    const handleProfileDrop = (acceptedFiles) => {
        const logoFile = acceptedFiles[0];
        if (logoFile) {
            setProfileFile(logoFile);
            const objectURL = URL.createObjectURL(logoFile);
            dispatch(setFormField({ key: "profilePictureURL", value: objectURL }));
        }
    };

    const PageDisplay = () => {
        if (page === 0) {
            return <GroupRoleInfo 
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                errors={errorMessages.groupRoleInfo}
                setProfileFile={handleProfileDrop}
            />;
        } else if (page === 1) {
            return <PersonalInfo errors={errorMessages.personalInfo}/>;
        } else if (page === 2 && selectedRole==="Patient") {
            return <HealthInfo />;
        } else if (page === 2 && selectedRole!=="Patient"){
            return <Preferences />;
        } else if (page === 3 && selectedRole==="Patient"){
            return <EmergencyContact />;
        }
        else {
            navigate('/account-management');
        }
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Group & Role Info";
        } else if (step === 1) {
            return "Personal Info";
        } else if (step === 2 && selectedRole==="Patient") {
            return "Health Info";
        } else if (step === 2 && selectedRole!=="Patient"){
            return "Preferences";
        } else if (step === 3 && selectedRole==="Patient"){
            return "Emergency Contact";
        }
    };

    const ProgressBarNavigation = (status) => {
        const pages = {
            "Group & Role Info": 0,
            "Personal Info": 1,
            "Health Info": 2,
            "Preferences": 2,
            "Emergency Contact": 3,
        };
        if (pages[status] || pages[status]===0) {
            setPage(pages[status]);
        } else {
            return;
        }
    };

    const lastPage = () => {
        if ((selectedRole === "Patient" && page === 3) || (selectedRole !== "Patient" && page === 2)){
            return true;
        } else {
            return false;
        }
    };

    const nextPage = () => {
        if (selectedRole === "Patient" && page < 3) {
            setPage(page + 1);
        } else if (selectedRole !== "Patient" && page < 2) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page !== 0) {
            setPage(page - 1);
        }
    };

    const checkPageValidity = () => {
        let tempError = {...errorMessages};
        if (page===0) {
            let employeeInvalid = false;
            let groupsInvalid = false;
            if (userData.groups.length === 0) {
                groupsInvalid = true
            }
            if ((userData.employeeId==="" && userData.npi === "") && selectedRole !== "Patient") {
                employeeInvalid = true;
            }
            tempError.groupRoleInfo.employeeShowing = employeeInvalid;
            tempError.groupRoleInfo.groupsShowing = groupsInvalid;
            setErrorMessages(tempError);
            return employeeInvalid || groupsInvalid;
        } else if (page===1) {
            let firstNameInvalid = false;
            let lastNameInvalid = false;
            let emailInvalid = false;
            let phoneInvalid = false;
            if (userData.firstName === "") {
                firstNameInvalid = true;
            }

            if (userData.lastName === "") {
                lastNameInvalid = true;
            }
            
            if (userData.email === "") {
                emailInvalid = true;
            }

            const fullPhoneNumber = `${userData.countryCode}${userData.phone}`;
            if (!phoneNumberRegex.test(fullPhoneNumber)) {
                phoneInvalid = true;
            }

            tempError.personalInfo.firstNameShowing = firstNameInvalid;
            tempError.personalInfo.lastNameShowing = lastNameInvalid;
            tempError.personalInfo.phoneShowing = phoneInvalid;
            tempError.personalInfo.emailShowing = emailInvalid;
            setErrorMessages(tempError);
            return firstNameInvalid || lastNameInvalid || emailInvalid || phoneInvalid;
        } else {
            return false;
        }
    };

    const closeModal = (onClickKey) => {
        dispatch(closeModal());
    };

    const handleSubmit = async () => {
        const method = updating ? 'PUT' : 'POST';
        const url = updating ? `user/${userData.id}` : 'registration';
        try {
            const response = await httpService.request({
                method: method,
                headers: {"Content-Type": "application/json"},
                url: url,
                data: JSON.stringify(userData),
            });

            const formData = new FormData();
            formData.append('file', profileFile);
            if (response) {
                if (updating) {
                    await httpService.request({
                        method: 'PUT',
                        url: `user/profilePicture/${userData.id}`,
                        data: formData,
                    });
                    dispatch(
                        showAlert({
                            header: "Success",
                            message: "Updated account successfully",
                            type: 'success',
                        })
                    );
                } else {
                    await httpService.request({
                        method: 'PUT',
                        url: `user/profilePicture/${response.data.data.id}`,
                        data: formData,
                    });
                    dispatch(
                        openModal({
                            header: "Account Created Successfully",
                            message: "Check your email to activate the account",
                            buttons: [
                                { label: 'Ok', variant: 'primary', onClickKey: 'close' },
                            ],
                        })
                    );
                }
                navigate('/account-management');
            }
        } catch (error) {
            const errorMessage = error.response.data.message;
            dispatch(
                showAlert({
                    header: "Error",
                    message: errorMessage,
                    type: 'danger',
                })
            );
        }
    };

    return (
        <div className={styles.MainContent}>
            <div className={styles.ReturnToManagement} onClick={navigateAccountManagment}>
                <LeftArrowIcon />
                <span>Back to Account Management</span>
            </div>
            <div className={styles.MiddleContentAdd}>
                <div className={styles.ContentTitle}>
                    {updating ? "Update Account" : "Create Account"} - {roleName}
                </div>
                <div style={{paddingTop:"20px"}}>
                    <ProgressBar page={page} navigateToStep={ProgressBarNavigation} getPageName={getPageName} totalSteps={selectedRole==="Patient" ? 4 : 3}/>
                </div>
                <div style={{display:"flex", flexDirection:"column", overflow:"auto", flex:"1"}}>
                    <div className="inputs">
                        {PageDisplay()}
                    </div>
                    <div className="button-container1">
                        <button 
                            className={styles.CreateButton}
                            onClick={(e) => {
                                e.preventDefault()
                                if (checkPageValidity()){
                                    return;
                                };
                                if (lastPage()) {
                                    handleSubmit();
                                } else {
                                    nextPage();
                                }
                            }}
                        >
                        {lastPage() && updating ? "Update Account" : lastPage() ? "Create Account" : "Next"} 
                        </button>
                        {page!==0 ? (
                            <button 
                                className={styles.BackButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    prevPage();
                                }}
                            >
                            Back
                            </button>)
                        : null }
                    </div>
                </div>
                {perinHealthModal.show && (
                    <PerinHealthModal
                        show={perinHealthModal.show}
                        header={perinHealthModal.header}
                        message={perinHealthModal.message}
                        buttons={perinHealthModal.buttons}
                        handleButtonClick={closeModal}
                    />
                )}
            </div>
        </div>
    )
}

export default CreateAccount;
