import { createSlice } from '@reduxjs/toolkit';

const  initialState = {
    currentMeetings: [],
    leaveMeetingData: {
        shouldLeave: false,
        meetingId: null,
        patientId: null,
    },

    appointmentData: {},
    minimized: false,
    showing: false
};
const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        setCurrentMeetings: (state, action) => {
            // Find index of the meeting with the same appointmentId
            const index = state.currentMeetings.findIndex(meeting => meeting.appointmentId === action.payload.appointmentId);
            if (index !== -1) {
                // Replace the old meeting with the new one
                state.currentMeetings[index] = action.payload;
            } else {
                state.currentMeetings.push(action.payload);
            }
        },
        removeMeeting: (state, action) => {
            // Filter out the meeting with the specified appointmentId
            state.currentMeetings = state.currentMeetings.filter(
                meeting => meeting.appointmentId !== action.payload
            );
        },
        leaveMeeting: (state, action) => {
            // Store meetingId and patientId with the leave flag
            state.leaveMeetingData = {
                shouldLeave: true,
                meetingId: action.payload.meetingId,
                patientId: action.payload.patientId
            };
        },
        resetLeaveMeetingFlag: (state) => {
            state.leaveMeetingData = {
                shouldLeave: false,
                meetingId: null,
                patientId: null
            };
        },
        toggleMinimized: (state) => {
            state.minimized = !state.minimized
        },
        setAppointmentData: (state, action) => {
            state.appointmentData = action.payload;
            state.showing = true;
        },
        closeAppointment: (state) => {
            state.showing = false;
            state.minimized = false;
            state.appointmentData = {};
        }
    },
});

export const { setCurrentMeetings, removeMeeting, leaveMeeting, resetLeaveMeetingFlag, toggleMinimized, setAppointmentData, closeAppointment} = appointmentSlice.actions;

export default appointmentSlice.reducer;
