import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import styles from './CareTeamMessage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import CareTeamListGroup from './CareTeamListGroup';
import { setReloadStaffMessageList, setStaffChatList, setStaffNewChat, setStaffRecipient, setStaffSelectedIndex, setStaffChatVisible } from './CareTeamMessageSlicer';
import useAxios from '../../services/AxiosConfig';
import ChatContainer from '../../components/ChatContainer';
import { setVideoCallVisibility } from '../../components/VideoAndMessageSlice';
import { addUnreadStaffMessageId, removeUnreadStaffMessageId} from '../../slicers/sidebarSlice';
import { useWebSocket } from '../../services/webSocket/useWebSocket';
import { setCallInformation, openCallContainer } from '../../slicers/CallContainerSlice';


const CareTeamMessageList = ({ onItemClick, onCloseClick }) => {
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const userInfoState = useSelector((state) => state.userInfo);
    const userId = userInfoState.id;
    const userFullName = userInfoState.fullName;
    const careTeamSlicer = useSelector((state) => state.careTeam);
    const isChatVisible = careTeamSlicer.isChatVisible;
    const recipient = careTeamSlicer.recipient;
    const videoAndMessageSlicer = useSelector((state) => state.videoAndMessage);
    const isVideoCallVisible = videoAndMessageSlicer.isVideoCallVisible;
    const filterChatSlice = useSelector((state) => state.filtersChat);

    const [originalDataList, setOriginalDataList] = useState([]);
    const [filteredDataList, setFilteredDataList] = useState([]);

    const callContainerShowing = useSelector((state) => state.callContainer.callContainerShowing);

    const { receivedMessage } = useWebSocket();

    const searchMessages = (arr, id) => {
        return arr.flat().filter(message => message.senderId === id || message.recipientId === id);
    };

    const getAllMessageByUserId = useCallback(async () => {
        try {
            const responseData = await httpService.get(`chime/getAllMessageByUserId?userId=${userId}&recipientType=STAFF`);
            const messages = responseData.data.getAllMessage || [];
            setOriginalDataList(messages);
            const filteredMessages = filterMessages(messages, filterChatSlice.searchText);
            setFilteredDataList(filteredMessages);
            dispatch(setReloadStaffMessageList(false));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [userId, filterChatSlice.searchText, careTeamSlicer.reloadMessageList, dispatch, httpService]);

    useEffect(() => {
        getAllMessageByUserId();
    }, [getAllMessageByUserId]);

    //If the recipient changes make sure to set the chat reflects the change/ or handle a new message without a channel arn
    useEffect(() => {
        var newRecipient = searchMessages(originalDataList, recipient.id);
        if( newRecipient.length > 0){
            dispatch(setStaffChatList(newRecipient));
            dispatch(setStaffSelectedIndex(newRecipient[0].channelArn));
        }
    }, [recipient, originalDataList]);

    useEffect(() => {
        if (careTeamSlicer.selectedIndex !== -1) {
            const chatListMessages = originalDataList.find(array => 
                array.length > 0 && array[0].channelArn === careTeamSlicer.selectedIndex
            );
            dispatch(setStaffChatList(chatListMessages || []));
        }
    }, [careTeamSlicer.selectedIndex, originalDataList, dispatch]);

    useEffect(() => {
        if (receivedMessage || careTeamSlicer.reloadMessageList) {
            getAllMessageByUserId();
        }
    }, [receivedMessage, careTeamSlicer.reloadMessageList, getAllMessageByUserId]);


    useEffect(() => {
        const filteredMessages = filterMessages(originalDataList, filterChatSlice.searchText);
        setFilteredDataList(filteredMessages);
    }, [filterChatSlice.searchText, originalDataList]);

    const filterMessages = (messages, searchText) => {
        const filterValue = searchText.toLowerCase().trim();
        const sortMessages = [...messages].sort((a, b) => new Date(b[0].createdTime) - new Date(a[0].createdTime));
    
        if (filterValue === '') {
            return sortMessages;
        } else {
            return sortMessages.filter(message => 
                    message.recipientName?.toLowerCase().includes(filterValue)
                );
        }
    };

    const handleItemClick = async (item) => {
        if (callContainerShowing) {
            return;
        }
        dispatch(setStaffSelectedIndex(item[0].channelArn));
        dispatch(setStaffChatList(item));
        dispatch(setStaffNewChat(false));
        dispatch(setVideoCallVisibility(false));
        const messageFromUser = item.find(message => message.senderId === userId);
        if (messageFromUser) {
            dispatch(setStaffRecipient({ 'id': parseInt(messageFromUser.recipientId), name: messageFromUser.recipientName }));
            dispatch(removeUnreadStaffMessageId(messageFromUser.recipientId));
        } else {
            const messageToUser = item.find(message => message.recipientId === userId);
            if (messageToUser) {
                dispatch(setStaffRecipient({ 'id': parseInt(messageToUser.senderId), name: messageToUser.senderName }));
                dispatch(removeUnreadStaffMessageId(messageToUser.senderId));
            }
        }
        dispatch(setStaffChatVisible(true));
        /*  const params = {
             channelArn : item[0].channelArn, 
             userId: userId 
         }
         await httpService.post('chime/updateReadStatus', params); */
    };

    const handleChatContainerClose = () => {
        onCloseClick();
    };

    const handleStartCall = (audioOnly) => {
        const callData = {
            title: `Staff Message ${audioOnly ? "Audio" : "Video"} Call - ${userFullName} & ${recipient.name}`,
            appointmentId: null,
            senderId: userId,
            recipientId: recipient.id,
            senderName: userFullName,
            recipientName: recipient.name
        };
        dispatch(setStaffSelectedIndex(-1));
        dispatch(setStaffChatList([]));
        dispatch(setStaffChatVisible(false));
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData,viewType: "staffMessage"}));
        dispatch(openCallContainer());
    };

    const handleListStartCall = (item, audioOnly) => {
        let id, name;
        const messageFromUser = item.find(message => message.senderId === userId);
        if (messageFromUser) {
            name = messageFromUser.recipientName;
            id = parseInt(messageFromUser.recipientId);
        } else {
            const messageToUser = item.find(message => message.recipientId === userId);
            if (messageToUser) {
                name = messageToUser.senderName;
                id = parseInt(messageToUser.senderId);
            }
        }
        const callData = {
            title: `Staff Message ${audioOnly ? "Audio" : "Video"} Call - ${userFullName} & ${name}`,
            appointmentId: null,
            senderId: userId,
            recipientId: id,
            senderName: userFullName,
            recipientName: name
        };
        dispatch(setStaffSelectedIndex(-1));
        dispatch(setStaffChatList([]));
        dispatch(setStaffChatVisible(false));
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData,viewType: "staffMessage"}));
        dispatch(openCallContainer());
    };

    const deleteChannelMembershipRequest = (item) => {
        const params = {
            channelArn: item[0].channelArn,
            userId: userId
        }
        const responseData = httpService.post('chime/deleteChannelMembershipRequest', params);
    };

    return (
            <div className={styles.PatientMainContainer}>
                <Form className={styles.PatientMessageContainer}>
                    <CareTeamListGroup 
                        items={filteredDataList}
                        onStartCall={handleListStartCall}
                        onDeleteChannelMembership={deleteChannelMembershipRequest}
                        onItemClick={handleItemClick} />
                </Form>
                {isChatVisible && !isVideoCallVisible && (
                    <Form className={styles.ChatContainer}>
                        <ChatContainer view="staffMessage"
                            handleClose={handleChatContainerClose}
                            onStartVideoCall={() => handleStartCall(false)}
                            onStartAudioCall={() => handleStartCall(true)}
                        />
                    </Form>
                )}
            </div>
    );
};

export default CareTeamMessageList;