import React, { useRef, useState } from "react";
import PatientAppointment from "../../components/PatientAppointment";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Appointment.module.css";

function AppointmentsContainer({ appointments }) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  return (
    <div className={styles["appointment-container"]}>
      <div className={styles["text-calendar-container"]}>
        <div className={styles["appointments-title"]}>Upcoming Appointments</div>
        <div className={styles["appointments-upcoming"]}></div>
      </div>
      <div className={styles["patient-appointment-container"]}>
        <PatientAppointment appointments={appointments} />
      </div>
    </div>
  );
}

export default AppointmentsContainer;
