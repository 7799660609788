import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import ReactDOM from "react-dom";
import styles from '../HubManagement.module.css';
import { XOut } from "../../../components/icons/Icons";
import managementStyles from '../../ManagementPages.module.css';
import { Form, Button, FloatingLabel } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import useAxios from '../../../services/AxiosConfig';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const AssignHubPopup = ({ options, onClose, onSave }) => {
    
    const label = "Group";
    const nameKey = "name";
    const valueKey = "id";

    const [groupId, setGroupId] = useState("");
    const [quantity, setQuantity] = useState("");

    const buttonDisabled = groupId === "" || quantity === "";

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const navigate = useNavigate();

    const assignHubs = async (groupId, quantity) => {
        const hubInfo = {
            groupId,
            quantity
        };
    
        try {
            const response = await httpService.post('/hub/group', hubInfo);
            console.log('Hubs assigned successfully:', response.data);
            dispatch(
                showAlert({
                    header: "Success",
                    message: "Hubs assigned successfully",
                    type: 'success'
                })
            );
            navigate('/hub-management');
            onClose();
            onSave();
        } catch (error) {
            console.error('Error assigning hubs:', error);
            dispatch(
                showAlert({
                    header: "Error",
                    message: "Failed to assign hubs",
                    type: 'danger'
                })
            );
        }
    };
      
    return ReactDOM.createPortal(
        <div className="overlay">
            <div className={styles.PopupContainer}>
                <div style={{width:"100%",display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <span className={styles.HubsLabel}>Assign Hub</span>
                    <div onClick={onClose} style={{cursor:"pointer"}}>
                        <XOut onClick={onClose}/>
                    </div>
                </div>
                <span className={styles.HubBody2}>{`Assign Hubs to an ${label}`}</span>
                <Form style={{width:"300px"}}>
                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="floatingSelect" label={`${label}`}>
                        <Form.Select
                            className={`${managementStyles.BorderedComponent}`}
                            value={groupId}
                            onChange={(e) => setGroupId(e.target.value)}
                            style={{width:"300px"}}
                        >
                            <option value="">{`Select ${label}`}</option>
                            {options.map((option) => (
                                <option key={option[valueKey]} value={option[valueKey]}>{option[nameKey]}</option>
                            ))}
                        </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel 
                            controlId="floatingInput"
                            label="Quantity*"
                            className="mb-3"
                        >
                        <Form.Control
                            placeholder="Quantity*"
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        </FloatingLabel>
                    </Form.Group>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <Button className={managementStyles.CancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button disabled={buttonDisabled} 
                                onClick={() => assignHubs(groupId, quantity)} 
                                className={managementStyles.CreateButton}>
                            Assign
                        </Button>
                    </div>
                </Form>
            </div>
        </div>,
        document.body
    );
}

export default AssignHubPopup;
