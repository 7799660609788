import React from 'react';
import './Cards.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCard } from '../slicers/FilterSlice';

const Cards = ({ icon, count, text, onClick, selectedCardText }) => {
  const selectedCard = useSelector((state) => state.filters.selectedCard);
  const dispatch = useDispatch();

  const handleSelection = () => {
    const newSelectedCard = selectedCard === text ? null : text;
    dispatch(setSelectedCard(newSelectedCard));
    if (onClick) {
      onClick(newSelectedCard);
    }
  };

  return (
    <div
      className={`custom-card ${selectedCard === text ? 'selected-card' : ''}`}
      onClick={handleSelection}
    >
      <div className="icon-row">
        <h2 style={{ marginBottom: '0px', fontSize:"24px", fontWeight:"700", fontFamily:"DM Sans" }}>{count}</h2>
        {icon}
      </div>
      <div className="text-row">
        <div style={{marginTop:"0px !important"}}>{text}</div>
      </div>
    </div>
  );
};


export default Cards;
