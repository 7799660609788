import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../components/AccountManagement/Accounts.css';


const SetPassword = ({credentials, handleCredentials, setCredentials, setPasswordsMatch, testPassword}) => {


    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        setPasswordsMatch(password1 === password2);
    }, [password1, password2, setPasswordsMatch]);


    const handlePassword = e => {
        if (e.target.id === "pass1"){
            setPassword1(e.target.value);
        } else {
            setPassword2(e.target.value);
        }
        if (testPassword(e.target.value)) {
            setCredentials(prevCredentials => ({
                ...prevCredentials,
                newPassword: e.target.value
            }));
        };
    };

    return (
        <div style={{display: "flex", flexDirection:"column", gap:"10px"}}>

                <div style={{position: "relative"}}>
                    <span className="floating-label">Email</span>
                    <input className="edit-account-input" style={{height:"44px"}}
                        id="username"
                        type="email"
                        disabled={false}
                        defaultValue={credentials.username}
                        onChange={handleCredentials}
                        required
                    ></input>
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Temporary Password</span>
                    <input className="edit-account-input" style={{height:"44px"}}
                        id="password"
                        type="text"
                        disabled={false}
                        defaultValue={credentials.password}
                        onChange={handleCredentials}
                        required
                    ></input>
                </div>
                
                <div style={{position:"relative"}}>
                    <span className="floating-label">New Password</span>
                    <input className="edit-account-input"
                        style={{height:"44px"}}
                        type={
                            showPassword1 ? "text" : "password"
                        }
                        id="pass1"
                        onChange={e => handlePassword(e)}
                        required
                    ></input>
                    <span 
                        className="icon-container" 
                        style={{right: '10px'}}
                        onClick={() =>
                            setShowPassword1((prev) => !prev)}
                    >
                        {showPassword1 ? <FontAwesomeIcon icon="fa-regular fa-eye" className="eye-icon" size="lg"/>
                            : <FontAwesomeIcon icon="fa-regular fa-eye-slash" className="eye-icon" size="lg"/> }
                    </span>
                </div>
                <div style={{position:"relative"}}> 
                    <span className="floating-label">Confirm New Password</span>
                    <input className="edit-account-input"
                        style={{height:"44px"}}
                        id="pass2"
                        type={
                            showPassword2 ? "text" : "password"
                        }
                        onChange={e => handlePassword(e)}
                        required
                    ></input>
                    <span 
                        className="icon-container" 
                        style={{right: '10px'}}
                        onClick={() =>
                        setShowPassword2((prev) => !prev)}
                    >
                        {showPassword2 ? <FontAwesomeIcon icon="fa-regular fa-eye" className="eye-icon" size="lg"/>
                            : <FontAwesomeIcon icon="fa-regular fa-eye-slash" className="eye-icon" size="lg"/> }
                    </span>
                </div>
        </div>
    )

}

export default SetPassword;