import React from 'react';
import { Ellipse } from './Icons';

const EllipsesIcon = () => {
    return (
        <div style={{display:"flex", columnGap:"4px", width:"18px", alignItems:"center"}}>
            <Ellipse />
            <Ellipse />
            <Ellipse />
        </div>
    )
}
export default EllipsesIcon;