import { createSlice } from '@reduxjs/toolkit';

const  initialState = {
    selectedRows: [],
    selectAll: false,
    reloadGrid: true,
};
const roleManagementSlice = createSlice({
    name: 'roleManagement',
    initialState,
    reducers: {
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setReloadGrid: (state, action) => {
            state.reloadGrid = action.payload;
        },
        resetRoleManagement: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const { setSelectAll, setSelectedRows, setReloadGrid , resetRoleManagement} = roleManagementSlice.actions;

export default roleManagementSlice.reducer;
