import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { setDataList, setSelectAll, setSelectedItem, setSelectedRows } from '../../components/PerinHealthGridSlice';
import styles from './NewOrganization.module.css';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import { resetNewOrganization, setFieldValue, toggleUpdateMode } from './NewOrganizationSlicer';
import { closeModal } from '../../components/PerinHealthModelSlice';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const NewOrganization = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const newOrganization = useSelector((state) => state.newOrganization);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);

    const headers = [
        { key: 'id', label: "ID" },
        { key: 'name', label: "Organization Name" },
        { key: 'email', label: "Email" },
        { key: 'phone', label: "Phone" },
        { key: 'organizationStatus', label: "Organization Status" },
    ];

    useEffect(() => {
        getOrganizationById();
    }, []);

    const getOrganizationById = () => {
        httpService.get('organization')
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const updatedDataList = responseData.data.data.map((item, index) => {
                        if (!item.id) {
                            return { ...item, id: index };
                        }
                        return item;
                    });
                
                    dispatch(setDataList(updatedDataList));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            }); 
    }

    const onRowClickHandler = (rowItem) => {
        dispatch(resetNewOrganization());
        console.log(`row data ${rowItem}`);
        dispatch(setSelectedItem(rowItem));
        dispatch(setFieldValue(rowItem));
        dispatch(toggleUpdateMode(true));
        navigate('/add-new-organization');
    }
    const onDeleteHandler = () => {
        const deletePromises = perinHealthGrid.selectedRows.map((selectedItem) => {
            const itemId = selectedItem.id;
            console.log(`Deleting row with ID ${itemId}`);
            return httpService.delete(`organization/${itemId}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                console.log('Successfully deleted selected rows:', responses);
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                dispatch(resetNewOrganization());
                dispatch(closeModal());
                getOrganizationById();
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully deleted organizations`,
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Error deleting organizations`,
                        type: 'danger'
                    })
                );
            });
    }

    const onAddOrganizationHandler = () => {
        dispatch(resetNewOrganization());
        navigate('/add-new-organization');
    };

    return (
        <div className={managementStyles.MainContent}>
           {/*  <div className={managementStyles.Title}>
                <span className={styles.label}>Add New Organization</span>
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    addButtonLabel={'Add Organization'}
                    modelHeader = "Delete Organization?"
                    onAddHandler={onAddOrganizationHandler}
                    onDeleteHandler={onDeleteHandler} />
                <PerinHealthGrid
                    dataList={perinHealthGrid.dataList}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    gridHeight="100%"
                />
            </div>


        </div>
    );
};

export default NewOrganization;
