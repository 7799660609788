import React, { useState, useEffect } from "react";
import {Form} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setUsername, setPassword, setConfirmationCode, resetPasswordState  } from './ForgotPasswordSlice';
import styles from '../account-management/AccountManagement.module.css';
import useAxios from "../../services/AxiosConfig";


const ForgotPassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const passInfo = useSelector((state)=>state.forgotPassword);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const [page, setPage] = useState(0);

    const [passwordsMatch, setPasswordsMatch] = useState(null);
    const [passwordValid, setPasswordValid] = useState(true);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [passwordErrors, setPasswordErrors] = useState({
        special: {
            value: null,
            message: "1 allowed special character"
        },
        uppercase: {
            value: null,
            message: "1 uppercase character"
        },
        lowercase: {
            value: null,
            message: "1 lowercase character"
        },
        length: {
            value: null,
            message: "8 characters minimum"
        },
        number: {
            value: null,
            message: "1 number"
        }
    });


    const testPassword = (pass) => {
        const errors = {
            special: /^(?=.*[!@#$%^&*])/.test(pass),
            uppercase: /^(?=.*[A-Z])/.test(pass),
            lowercase: /^(?=.*[a-z])/.test(pass),
            length: pass.length >= 8,
            number: /^(?=.*\d)/.test(pass)
        };
    
        setPasswordErrors(prevErrors => ({
            ...prevErrors,
            special: { ...prevErrors.special, value: errors.special },
            uppercase: { ...prevErrors.uppercase, value: errors.uppercase },
            lowercase: { ...prevErrors.lowercase, value: errors.lowercase },
            length: { ...prevErrors.length, value: errors.length },
            number: { ...prevErrors.number, value: errors.number }
        }));
    
        setPasswordValid(Object.values(errors).every(error => error));
        return errors;
    };
    
    const handlePassword = (e) => {
        if (e.target.id === "pass1"){
            setPassword1(e.target.value);
        } else {
            setPassword2(e.target.value);
        }
        const errors = testPassword(e.target.value);
        if (Object.values(errors).every(error => error)) {
            dispatch(setPassword(e.target.value))
        }
    };


    useEffect(() => {
        setPasswordsMatch(password1 === password2);
    }, [password1, password2, setPasswordsMatch]);

    const pageInfo = {
        0: {
            title: "Forgot Password",
            message: "Please enter your email to get a verification code",
            buttonLabel: "Send",
            url: 'forgot-password',
            data: {"username":passInfo.username},
            errorMessage: 'There was an error sending you the verification code, please verify your email is correct and try again'
        },
        1: {
            title: "Forgot Password",
            message: "Please enter the 4 digit code sent to your email",
            buttonLabel: "Verify"
        },
        2: {
            title: "Reset Password",
            message: "Enter a new password to reset your account",
            buttonLabel: "Save",
            url:'forgot-password/confirmation',
            data: passInfo,
            errorMessage: 'There was an error saving your new password, please try again'
        },
        3: {
            title: "Congratulations",
            message: "Password changed successfully",
            buttonLabel: "Continue to login",
            data: {
                "username":passInfo.username,
                "password":passInfo.password
            }
        },

    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (page!==1 && page!==3) {
            try {
                const response = await httpService.request({
                    method: 'POST',
                    headers: {"Content-Type": "application/json"},
                    url: pageInfo[page].url,
                    data: JSON.stringify(pageInfo[page].data),
                });
                if (response) {
                    nextPage();
                }
            } catch (error) {
                const errorInfo = error.response.data;
                if (page === 0) {
                    if (errorInfo && errorInfo.code === 500 && errorInfo.message.includes("LimitExceededException")) {
                       alert("Forgot password failed: Attempt limit exceeded, please try after some time.");
                    }
                    navigate('/');
                } else if (page === 2) {
                    if (errorInfo && errorInfo.code === 500 && errorInfo.message.includes("CodeMismatchException")) {
                        alert("Reset Password Failed: Invalid verification code, please try again.");
                    }
                    setPage(0);
                } 
                else {
                    alert(pageInfo[page].errorMessage);
                }
                console.error('Error', error);
            }
        } else {
            nextPage();
        }
    };

    const handleResend = async () => {
        try {
            const response = await httpService.request({
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                url: pageInfo[0].url,
                data: JSON.stringify(pageInfo[0].data),
            });
            if (response) {
                console.log("response", response);
            }
        } catch (error) {
            alert(pageInfo[0].errorMessage);
            console.error('Error', error);
        }
    };
    
    const nextPage = () => {
        if (page === 3) {
            dispatch(resetPasswordState());
            navigate('/');
        } else {
            setPage(page+1);
        }
    };

    return (
        <div className='background-container'>
            <div className='main-container'>
                <div className="login-container">
                    <h2 className="reg-title" style={{color:"#00162E", fontSize:"24px"}}>{pageInfo[page].title}</h2>
                    <Form onSubmit={handleSubmit} style={{display:"flex", flexDirection:"column", align:"left", width:"80%", flex: "1", overflow:"auto"}}>
                        <div style={{display: "flex", flexDirection:"column", gap:"30px"}}>
                            <p style={{alignSelf:"center", color:"#373737"}}>{pageInfo[page].message}</p>
                            {page === 0 && 
                                <input className="edit-account-input" style={{height:"44px"}}
                                    id="username"
                                    type="email"
                                    placeholder="Enter Email"
                                    onChange={(e)=>dispatch(setUsername(e.target.value))}
                                    required
                                ></input>
                            }
                            {page === 1 && 
                                <input className="edit-account-input" style={{height:"44px"}}
                                    id="username"
                                    type="text"
                                    placeholder="Verification Code"
                                    onChange={(e)=>dispatch(setConfirmationCode(e.target.value))}
                                    required
                                ></input>
                            }
                            {page === 2 && 
                                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                                    <input className="edit-account-input" style={{height:"44px"}}
                                        id="pass1"
                                        type="text"
                                        onChange={handlePassword}
                                        placeholder="New Password"
                                        required
                                    ></input>
                                    <input className="edit-account-input" style={{height:"44px"}}
                                        id="pass2"
                                        type="text"
                                        onChange={handlePassword}
                                        placeholder="Confirm New Password"
                                        required
                                    ></input>
                                    {!passwordValid && 
                                        <div className={styles.PasswordErrorContainer}>
                                            <span className={styles.PasswordErrorSpan}>Your password must have the following</span>
                                            <ul>
                                                {Object.entries(passwordErrors).map(([key, error]) => (
                                                        <li key={key} className={styles.PasswordErrorSpan} style={error.value ? { color: "green", paddingLeft: "10px" } : { color: "red", paddingLeft: "10px" }}>
                                                            {error.message}
                                                        </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                    {!passwordsMatch && password1!=="" && password2!=="" && <span style={{color:"grey", fontSize:"10px"}}>passwords do not match</span>}
                                </div>
                            }
                            <button className={styles.LoginButton}
                                style={(!passwordsMatch || !passwordValid) ? {border:"none",backgroundColor:"#D6D6D6"}:{backgroundColor:"#1D5769"}}
                                type="submit"
                                disabled={!passwordsMatch || !passwordValid}
                            >
                                {pageInfo[page].buttonLabel}
                            </button>
                            {page === 1 &&
                                <div
                                    style={{backgroundColor:"white", color:"#00162E", fontSize:"16px", fontWeight:"700", alignSelf:"center", cursor:"pointer"}}
                                    onClick={handleResend}
                                >
                                    Resend Code
                                </div>
                            }
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;