import React from "react";
import ReactDOM from "react-dom";
import popupStyles from '../views/patient-enrollment/PatientEnrollmentPopUp.module.css';
import styles from './GroupSelectPopup.module.css';
import OrganizationTreeSelect, { useSelectedTeams, SelectionProvider } from './OrganizationTreeSelect';
import { XOut } from "./icons/Icons";


const GroupSelectPopup = ({initialSelections, organizationData, handleSave, onClose}) => {

    const ExtractInfoButton = () => {
        const extract = useSelectedTeams();
      
        const handleExtract = () => {
            const info = extract();
            const groups = info.groups.filter((group)=>group.organizationId !== 0 && group.teamId !== 0);
            handleSave(groups);
            onClose();
        };
      
        return (
            <div className={styles.ButtonContainer}>
                <button className={styles.CancelButton} onClick={onClose}>Cancel</button>
                <button className={styles.SaveButton} onClick={handleExtract}>Save</button>
            </div>
        );
      };

    return ReactDOM.createPortal(
        <div className={popupStyles.PopupOverlay}>
            <div className={styles.PopupContainer}>
                <div onClick={()=>onClose()} className={styles.CloseOut}>
                   <XOut />
                </div>
                    <div>
                        <SelectionProvider initialSelections={initialSelections}>
                            <OrganizationTreeSelect organizations={organizationData} />
                            <ExtractInfoButton />
                        </SelectionProvider>
                    </div>
            </div>
        </div>,
        document.body
    );
}

export default GroupSelectPopup;