import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './CustomToggle.module.css';

const CustomTasksToggle = (props) => {
    const {
       label1,
       label2,
       handleToggle
    } = props;

    const [selected, setSelected] = useState(label1);
    const taskListPanel = useSelector((state) => state.taskListPanel);

    const handleChange = (value) => {
        setSelected(value);
        handleToggle(value);
    }

    return (
        <div className={styles.Toggle}>
            <input type="radio" name="customToggle" value={label1}
                checked={selected===label1}
                onChange={handleChange}
            ></input>
            <label onClick={()=>handleChange(label1)}> Pending  ({taskListPanel?.pendingTaskTypeCounts})</label>
            <input type="radio" name="customToggle" value={label2}
                checked={selected===label2} 
                onChange={handleChange}
            ></input>
            <label onClick={()=>handleChange(label2)}> Completed ({taskListPanel?.completedTaskTypeCounts})</label>
        </div>
    );
};

export default CustomTasksToggle;
