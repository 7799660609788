import React, { useState } from "react";
import { DropdownData } from "./DropdownData";
import './Accounts.css';
import styles from '../../views/ManagementPages.module.css';
import { setNestedField } from './RegistrationSlice';
import { useSelector, useDispatch } from 'react-redux';


const HealthInfo = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state)=>state.registration);
    const unitType = userData.preferences.unitType === "Metric" ? "Metric" : "Imperial";
    const birthday = userData.healthInfo.dateOfBirth ? userData.healthInfo.dateOfBirth.substring(0,10) : "";
    const today = new Date().toLocaleDateString('fr-ca');

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id==="weight") {
            setWeight(value);
        };
        dispatch(setNestedField({parentKey:"healthInfo",nestedKey:id, value:value}));
    };
    //weight
    const initWeight = () => {
      if (userData.healthInfo.weight){
        return parseFloat(userData.healthInfo.weight);
      } else {
        return "";
      }
    }
    const [weight, setWeight] = useState(initWeight());

    // height
    const [height, setHeight] = useState(parseInitialHeight(userData.healthInfo.height, unitType));
    function parseInitialHeight(heightString, unitType) {
        if (!heightString) {
            return { feet: "", inches: "", cm: "" };
        }
        if (unitType === "Imperial") {
            const [feet, inches] = heightString.split("'").map(str => parseInt(str));
            if (feet) {
                return { feet, inches };
            } else {
                return { feet: "", inches: "" };
            }
        } else {
            const cm = parseInt(heightString);
            return { cm };
        }
    };
  
    const handleHeightChange = (e, id) => {
        if (e.target.value) {
          const value = parseInt(e.target.value);
          setHeight(prevHeight => ({
              ...prevHeight,
              [id]: value
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${value}' ${height.inches}`;
              } else {
                  formattedHeight = `${height.feet}' ${value}`;
              }
          } else {
              formattedHeight = `${value}cm`;
          }
          dispatch(setNestedField({parentKey:"healthInfo",nestedKey:"height", value:formattedHeight}));
        } else {
          setHeight(prevHeight => ({
            ...prevHeight,
            [id]: ""
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${height.inches}"`;
              } else {
                  formattedHeight = `${height.feet}' ${""}`;
              }
          } else {
              formattedHeight = `${""}`;
          }
          dispatch(setNestedField({parentKey:"healthInfo",nestedKey:"height", value:formattedHeight}));
        }
    };

    const [count, setCount] = useState(userData.healthInfo.notes.length);
    const handleNotes = (e) => {
      setCount(e.target.value.length);
      handleChange(e);
    };
    
    return (
        <div className="create-account-inputs">
            <div className="input-name"
              style={{paddingTop:"10px"}}>
                Date of Birth
            </div>
            <div className={styles["styled-input-container"]}>
                  <span className="floating-label" >Date of Birth</span>
                  <input 
                    className="edit-account-input"
                    style={{padding:"20px 12px 10px 10px", width:"300px"}}
                    type="date"
                    max={today}
                    id="dateOfBirth"
                    defaultValue={birthday}
                    onChange={handleChange}
                  ></input>
            </div>
            <div className="input-name"
              style={{paddingTop:"10px"}}>
                Height
            </div>
            {unitType === "Metric" ?
                <div className={styles["styled-input-container"]}>
                    <input
                        id="cm"
                        type="number"
                        defaultValue={height.cm}
                        onChange={(e) => handleHeightChange(e, "cm")}
                    ></input>
                    <label className={userData.healthInfo.height && styles['filled']}>
                    cm
                    </label>
                </div>
              :
              <div className="input-block" style={{width: "300px", marginBottom:"0"}}>
                <div className={styles["styled-input-container"]}>
                    <input
                        style={{width:"147px"}}
                        id="feet"
                        min="0"
                        max="8"
                        type="number"
                        defaultValue={height.feet}
                        onChange={(e) => handleHeightChange(e, "feet")}
                    ></input>
                        <label className={userData.healthInfo.height && styles['filled']}>
                        ft
                        </label>
                </div>
                <div className={styles["styled-input-container"]}>
                    <input
                        style={{width:"147px"}}
                        id="inches"
                        type="number"
                        min="0"
                        max="11"
                        defaultValue={height.inches}
                        onChange={(e) => handleHeightChange(e, "inches")}
                    ></input>
                        <label className={userData.healthInfo.height && styles['filled']}>
                        in
                        </label>
                </div>
              </div>
            }
            <div className="input-name">
                Weight
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    style={{width:"147px"}}
                    type="number"
                    id="weight"
                    value={weight}
                    onChange={handleChange}
                ></input>
                <label className={userData.healthInfo.weight && styles['filled']}>
                    {unitType==="Metric" ? "kg" : "lbs"}
                </label>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Gender</span>
                <select
                    className={styles["styled-input-select"]}
                    id="gender"
                    value={userData.healthInfo.gender}
                    onChange={handleChange}
                    style={{width:"300px"}}
                >
                    <option value="">Select a Gender</option>
                    {DropdownData.gender.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Ethnicity</span>
                <select
                    className={styles["styled-input-select"]}
                    id="ethnicity"
                    value={userData.healthInfo.ethnicity}
                    onChange={handleChange}
                    style={{width:"300px"}}
                >
                    <option value="">Select Ethnicity</option>
                    {DropdownData.ethnicity.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="conditions"
                    defaultValue={userData.healthInfo.conditions}
                    onChange={handleChange}
                    style={{width:"300px"}}
                ></input>
                <label className={userData.healthInfo.conditions && styles['filled']}>
                    Condition
                </label>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Critical Level</span>
                <select
                    className={styles["styled-input-select"]}
                    id="criticalLevel"
                    value={userData.healthInfo.criticalLevel}
                    onChange={handleChange}
                    style={{width:"300px"}}
                >
                    <option value="">Select Critical Level</option>
                    {DropdownData.levels.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Phase</span>
                <select
                    className={styles["styled-input-select"]}
                    id="phase"
                    value={userData.healthInfo.phase}
                    onChange={handleChange}
                    style={{width:"300px"}}
                >
                    <option value="">Select Phase</option>
                    {DropdownData.phases.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles["styled-input-container"]} style={{width:"300px"}}>
                <textarea 
                    className={styles["note-input"]}
                    id="notes"
                    type="text"
                    maxLength={250}
                    style={{width:"300px"}}
                    defaultValue={userData.healthInfo.notes}
                    onChange={e => handleNotes(e)}
                >
                </textarea>
                <label className={userData.healthInfo.notes && styles['filled']}>
                    Notes
                </label>
                <span style={{fontSize:"10px", color:"#929395", marginLeft:"auto"}}>
                    {count}/250
                </span>
            </div>
        </div>
  );
};

export default HealthInfo;
