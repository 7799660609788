import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  hasUnread: false,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action) {
      state.notifications.push(action.payload);
    },
    setHasUnread(state, action) {
      state.hasUnread = action.payload;
    },
    markAllAsRead(state) {
      state.hasUnread = false;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
      state.hasUnread = action.payload.some(notification => !notification.readStatus);
    },
  },
});

export const { addNotification, setHasUnread, markAllAsRead, setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
