import React, { useRef, useEffect, useState } from 'react';
import styles from './Chart.module.css';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import QuestionnaireGraphScore from './QuestionnaireGraphScore';
import { SdkIndexFrame } from 'amazon-chime-sdk-js/build/signalingprotocol/SignalingProtocol';

const QuestionnaireScoreCardView = ({data, opacity, setOpacity}) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleLegendClick = (e) => {
    const qName = e.value;
    setOpacity((op) => ({ ...op, [qName]: op[qName] === 1 ? 0 : 1 }));
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = containerWidth - 50;
        setChartWidth(width);
      }
      if (chartContainerRef.current) {
        const containerHeight = chartContainerRef.current.offsetHeight;
        const height = containerHeight - 50;
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const questionnaireIds = useSelector((state)=>state.patient.questionnaireIds);

  const handlePointClick = (payload) => {
    setModalData(payload);
    handleShowModal()
  };

  const maxYRange = () => {
    return questionnaireIds.reduce((total, item) => Math.max(total, item.totalPossibleScore), 0);
  };

  const yTicks = Array.from(Array(maxYRange() + 1).keys());

  const colors = {
    0: '#496CA3',
    1: '#2A7F38',
    2: "#946705",
  };

  return (
    <div className={styles.questionnairePanel} ref={chartContainerRef}>
      <LineChart width={chartWidth} height={chartHeight} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="qDate" />
        <YAxis domain={[-1, maxYRange]} ticks={yTicks} />
        <Tooltip
          contentStyle={{
            fontSize: '0.625rem',
            fontFamily: 'Lato, sans-serif',
          }}
        />
        <Legend 
          verticalAlign="top"
          height={50} 
          iconType="square"
          wrapperStyle={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }} 
          payload={questionnaireIds.map((q, index) => ({
            value: q.name,
            type: 'square',
            color: colors[index] || '#000000',
          }))}
          onClick={handleLegendClick}
        />
          {questionnaireIds.map((q, index) => (
            <Line
              connectNulls
              type="linear"
              key={q.questionnaireId}
              dataKey={"score"}
              stroke={colors[index] }
              strokeOpacity={opacity[q.name] || 0}
              strokeWidth={2}
              dot={(props) => {
                const { cx, cy, stroke, value, key, payload } = props;
                const dotColor = value === -1 ? 'red' : colors[index];
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={7}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handlePointClick(payload);
                      }}
                      style={{ pointerEvents: 'visible' }}
                      fill={ "white"}
                      stroke={dotColor}
                      strokeOpacity={opacity[q.name] || 0}
                      strokeWidth={2}
                      key={key}
                    />
                  );
                } else {
                  return null;
                }
              }}
              activeDot={(props) => {
                const { cx, cy, stroke, value, key , payload} = props;
                const dotColor = value === -1 ? 'red' : colors[index];
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={7}
                      onClick={(e) => {
                        e.stopPropagation(); // Ensure the event doesn't bubble up
                        handlePointClick(payload);
                      }}
                      style={{ pointerEvents: 'visible' }}
                      fill={dotColor}
                      stroke={dotColor}
                      strokeOpacity={opacity[q.name] || 0}
                      strokeWidth={2}
                      key={key}
                    />
                );}else{
                  return null;
                }
              }}
            />
          ))}
      </LineChart>
      <QuestionnaireGraphScore 
        isModalVisible={isModalVisible} 
        handleCloseModal={handleCloseModal} 
        modalData={modalData}
      />
    </div>
  );
};

export default QuestionnaireScoreCardView;
