import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Form, CloseButton } from 'react-bootstrap';
import createAxiosInstance from '../../services/AxiosConfig';
import styles from "./Appointment.module.css";
import { UserProfilePictureIcon, HangupIcon, ExpandIcon } from "../../components/icons/Icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebSocket } from '../../services/webSocket/useWebSocket';
import { removeMeeting, leaveMeeting, resetLeaveMeetingFlag, toggleMinimized } from '../appointment/AppointmentSlicer';

import {
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  ConsoleLogger,
  DefaultDeviceController,
} from 'amazon-chime-sdk-js';

function AppointmentVideoCall({audioOnly = false, onClose, data }) {
  const meetingSessionRef = useRef(null);
  const meetingIdRef = useRef(null);
  const meetingArnRef = useRef(null);
  const audioElementRef = useRef(null);
  const previewVideoRef = useRef(null);
  const [startTime, setStartTime] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [videoTiles, setVideoTiles] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [captureMedia, setCaptureMedia] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [inPreviewMode, setInPreviewMode] = useState(true);
  const [transcriptions, setTranscriptions] = useState([]);
  const [showVideoCallModal, setShowVideoCallModal] = useState(true);
  const [showTranscription, setShowTranscription] = useState(false);
  const axiosConfig = useSelector(state => state.axiosConfig);
  const userRole = useSelector((state) => state.userInfo.roleCategory);
  const userId = useSelector((state) => state.userInfo.id);
  const dispatch = useDispatch();
  const localAttendeeIdRef = useRef(null);
  const [callDuration, setCallDuration] = useState(0);
  const {leaveMeetingData, minimized} = useSelector(state => state.appointment);
  
  const {sendMessage} = useWebSocket();
  const httpService = createAxiosInstance(axiosConfig.envURL, dispatch);

  const currentMeetings = useSelector((state) => state.appointment.currentMeetings).flat();

  const toggleMute = useCallback(async () => {
    if (isMuted) {
      await meetingSessionRef.current.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      await meetingSessionRef.current.audioVideo.realtimeMuteLocalAudio();
    }
    setIsMuted(!isMuted);
  }, [isMuted]);

  const toggleCaptureMedia = () => {
    setCaptureMedia(!captureMedia);
  }

  const toggleVideo = useCallback(async () => {
    if (!meetingSessionRef.current) return;
    const audioVideo = meetingSessionRef.current.audioVideo;
    if (!audioVideo) return;
  
    if (!hideVideo) {
      audioVideo.stopLocalVideoTile(); 
      setHideVideo(true);
    } else {
        audioVideo.startLocalVideoTile(); 
        setHideVideo(false);
      }
      
    
  }, [hideVideo]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    let timer;
    if (!inPreviewMode) {
      timer = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setCallDuration(0);
    }
    return () => clearInterval(timer);
  }, [inPreviewMode]);

  const startMediaCapture = useCallback(async () => {
    try {
      await httpService.post(`/meeting/mediacapture/start`, { 
        meetingArn: meetingArnRef.current,
        meetingId: meetingIdRef.current,
        patientId: data.patientId,
      });

    } catch (err) {
      console.error('Failed to start media capture:', err);
      throw err;
    }
  }, [httpService, data]);

  const joinMeeting = useCallback(async () => {
    if (!meetingData) return;
    
    if (!audioOnly) {
      meetingSessionRef.current.audioVideo.stopVideoPreviewForVideoInput(previewVideoRef.current);
    }
    meetingSessionRef.current.audioVideo.start();

    if (!audioOnly) {
      meetingSessionRef.current.audioVideo.startLocalVideoTile();
    }
    sendMessage({
      type: 'doctorHasJoined',
      meetingId: meetingIdRef,
      physicianId: data.physicianId,
      patientId: data.patientId,
    })
    setInPreviewMode(false);
    
    try {
      // await startMediaCapture();
    } catch (err) {
      console.error('Failed to start media capture:', err);
    }
    setStartTime(new Date().toISOString());
  }, [meetingData, audioOnly, data, sendMessage]);

  const toggleTranscription = useCallback( async() => {
    if (showTranscription) {
      // Stop transcription
      httpService.post(`/meeting/transcription/stop`, { meetingId: meetingIdRef.current })
        .then(response => {
          console.log('Transcription stopped:', response);
          setShowTranscription(false);
        })
        .catch(err => console.error('Failed to stop transcription:', err));
    } else {
      // Start transcription
      httpService.post(`/meeting/transcription/start`, { meetingId: meetingIdRef.current })
        .then(response => {
          console.log('Transcription started:', response);
          setShowTranscription(true);
        })
        .catch(err => console.error('Failed to start transcription:', err));
    }
  }, [showTranscription, httpService]);

  useEffect(() => {
    if (!inPreviewMode && captureMedia) {
      startMediaCapture();
    }
  }, [inPreviewMode, startMediaCapture]);
  
  const leaveMeeting = async () => {
    if (meetingSessionRef.current) {
      const audioVideo = meetingSessionRef.current.audioVideo;
      if (audioVideo) {
        await audioVideo.stopLocalVideoTile();  // Stop local video
        await audioVideo.stopVideoInput();      // Stop video input
        await audioVideo.stopAudioInput();      // Stop audio input
        await audioVideo.stop();                // Stop the session
        audioVideo.unbindAudioElement();        // Unbind the audio element
      }
      if (meetingIdRef.current) {
        try {
          await httpService.delete(`/meeting/${meetingIdRef.current}`);
          // Clean up any other resources or perform additional state cleanup here
        } catch (err) {
          console.error('Failed to delete meeting:', err);
        }
      }
      console.log("Meeting and video stopped");
    }
   
    onClose();
    setShowVideoCallModal(false);
    dispatch(removeMeeting(data.appointmentId));
    sendMessage({
      type: 'doctorHasLeft',
      meetingId: meetingIdRef,
      physicianId: data.physicianId,
      patientId: data.patientId,
    });
    if(!inPreviewMode){
      updateMeetingMinutes();      
    }else{
      setMeetingInactive();
    }
    // Reset any state or refs that might persist
    setMeetingData(null);
    meetingSessionRef.current = null;
  };

  const setMeetingInactive = async() => {
    const formData = {
      isActive: false
    };

    httpService.put(`/appointment/${data.appointmentId}`, formData)
        .then(response => {
          console.log('Appointment duration Updated');
        })
        .catch(err => console.error('Failed to update appointment:', err));
  }



  const updateMeetingMinutes = async() => {
    const formData = {
      duration: callDuration,
      actualAppointmentStartTime: startTime || new Date().toISOString(),
      actualAppointmentEndTime: new Date().toISOString(),
      meetingId: meetingIdRef.current,
      isActive: false
  };
  
    httpService.put(`/appointment/${data.appointmentId}`, formData)
        .then(response => {
          console.log('Appointment duration Updated');
        })
        .catch(err => console.error('Failed to update appointment:', err));
  }

  useEffect(() => {
    if (leaveMeetingData?.shouldLeave) {
        if (leaveMeetingData.meetingId === meetingIdRef.current && leaveMeetingData.patientId === data.patientId) {
            leaveMeeting();
            dispatch(resetLeaveMeetingFlag());
        }
    }
}, [leaveMeetingData, dispatch, leaveMeeting]);

  useEffect(() =>  {
    const startMeetingSetup = async () => {

      const currentMeeting = currentMeetings.find(
        meeting => meeting.appointmentId === data.appointmentId
      );
      try {
          const postData = {
            title: data.title,
            appointmentId: data.appointmentId,
            senderId: data.physicianId,
            recipientId: data.patientId,
            senderName: data.accountName,
            audioOnly: false
        };

        console.log(postData);

          // Only add meetingId if it exists
        if (currentMeeting?.meeting?.MeetingId) {
            postData.meetingId = currentMeeting.meeting.MeetingId;
        }else if (data.meetingId){
            postData.meetingId = data.meetingId;
        }

        const meetingResponse = await httpService.request({
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          url: `/meeting/join`,
          data: JSON.stringify(postData),
        });

        if (meetingResponse.data.data.meeting && meetingResponse.data.data.attendee) {
          meetingIdRef.current = meetingResponse.data.data.meeting.MeetingId;
          meetingArnRef.current = meetingResponse.data.data.meeting.MeetingArn;
          setMeetingData({
            Meeting: meetingResponse.data.data.meeting,
            Attendee: meetingResponse.data.data.attendee,
          });
        }
      } catch (err) {
        console.error('Error setting up the meeting:', err);
      }
    };

    startMeetingSetup();
  }, [userId, data, userRole, httpService]);

  useEffect(() => {
    if (meetingData) {
      localAttendeeIdRef.current = meetingData.Attendee.AttendeeId;
    }
  }, [meetingData]); 

  useEffect(() => {
    if (!meetingData) return;

    const logger = new ConsoleLogger('MeetingLogs', LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    const configuration = new MeetingSessionConfiguration(meetingData.Meeting, meetingData.Attendee);
    const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

    meetingSessionRef.current = meetingSession;

    if (meetingSessionRef.current) {
      meetingSessionRef.current.audioVideo.transcriptionController.subscribeToTranscriptEvent((transcriptEvent) => {
        const results = transcriptEvent.results || []; 
        setTranscriptions((currentTranscriptions) => {

          if (results.length === 0) {
            return currentTranscriptions;
          }
      
          return results.reduce((updatedTranscriptions, result) => {
            const index = updatedTranscriptions.findIndex(t => t.resultId === result.resultId);
            const items = result.alternatives[0]?.items || [];
      
            const transcriptionText = items.map(item => item.content).join(' ');
      
            const transcriptionData = {
              resultId: result.resultId,
              text: transcriptionText,
              isFinal: !result.isPartial,
              speaker: items[0]?.attendee?.externalUserId 
                        ? items[0].attendee.externalUserId.split('_')[1] 
                        : "Unknown",
              startTime: items[0]?.startTimeMs || 0,
              endTime: items[0]?.endTimeMs || 0,
            };
      
            if (index === -1) {
              // New transcription result
              return [...updatedTranscriptions, transcriptionData];
            } else {
              // Update existing transcription result if partial
              if (result.isPartial) {
                updatedTranscriptions[index] = transcriptionData;
              }
              return updatedTranscriptions;
            }
          }, currentTranscriptions);
        });
      });      
    }

    const observer = {
      videoTileDidUpdate: tileState => {
        if (!tileState.boundAttendeeId || audioOnly) return;

        setVideoTiles(prevTiles => {
          // Check if tile already exists
          const tileIndex = prevTiles.findIndex(t => t.tileId === tileState.tileId);
          if (tileIndex !== -1) return prevTiles;

          // Create a new ref for the video element
          const newRef = React.createRef();
          return [...prevTiles, { tileId: tileState.tileId, ref: newRef, boundAttendeeId: tileState.boundAttendeeId }];
        });
      },
      videoTileWasRemoved: tileId => {
        if (audioOnly) return;
        setVideoTiles(prevTiles => prevTiles.filter(tile => tile.tileId !== tileId));
      }
    };

    meetingSession.audioVideo.addObserver(observer);
    const setupDevices = async () => {
      // Invoke devices
      meetingSession.audioVideo.setDeviceLabelTrigger(async () => {
        const constraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          },
          video: !audioOnly
        };
        return await navigator.mediaDevices.getUserMedia(constraints);
      });

      const audioInputDevices = await deviceController.listAudioInputDevices();
      await deviceController.startAudioInput(audioInputDevices[0].deviceId);
      meetingSession.audioVideo.bindAudioElement(audioElementRef.current);

      if (!audioOnly) {
        const videoInputDevices = await deviceController.listVideoInputDevices();
        if (videoInputDevices.length > 0) {
          await deviceController.startVideoInput(videoInputDevices[0].deviceId);
          await meetingSession.audioVideo.startVideoPreviewForVideoInput(previewVideoRef.current);
        }
      }

      const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
      if (audioOutputDevices.length > 0) {
        await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
      }
    };

    if (previewVideoRef.current) {
      setupDevices();
    }

    return () => {
      meetingSession.audioVideo.transcriptionController.unsubscribeFromTranscriptEvent()
      meetingSession.audioVideo.removeObserver(observer);
    };
  }, [meetingData, audioOnly]);

  useEffect(() => {
    videoTiles.forEach(tile => {
      if (tile.ref.current) {
        meetingSessionRef.current.audioVideo.bindVideoElement(tile.tileId, tile.ref.current);
      }
    });
  }, [videoTiles]);
  
  const isSolo = videoTiles.length === 1;
  
  return (
    <>
      <div className={minimized ? styles.AppointmentCallContainerMinimized : styles.AppointmentCallContainer}>
        <div className={styles.videoHeader}>
          <Row> 
              <Col xs={3}></Col>
              <Col xs={6} className={styles.VideoHeaderText}>
                {!minimized && 
                <>
                  <div className={styles.iconContainer}>
                    <span className={styles.iconText}>{data.patientName?.split(' ')[0][0] + data.patientName?.split(' ')[1][0]}</span>
                    <UserProfilePictureIcon width="38" height="38" />
                  </div>
                  {`${data.patientName} Appointment`}
                </>
                }
              </Col>
              <Col xs={3} className="d-flex align-items-center justify-content-end">
                <Button variant="white" style={{padding:"0"}} onClick={()=>dispatch(toggleMinimized())}>
                  <ExpandIcon />
                </Button>
                <CloseButton onClick={leaveMeeting} style={{paddingRight:"30px"}}/>
              </Col>
          </Row>
        </div>
        <div className={styles.videoMiddle}>
          {/* <Row className={styles.videoRow}> */}
            <Col md={showTranscription ? 9 : 12} className={styles.videoCol}>
            {audioOnly ? (
              <div style={{ width: '100%', height: '450px', background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100px', height: '100px', borderRadius: '50%', background: 'grey', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '30px', color: 'white' }}>
                  {'NB'}
                </div>
              </div>
            ) : (
              inPreviewMode ? (
                <>
                  <div>
                    <p className={styles.videoWelcomeText}>Welcome to Telehealth!</p>
                    <p className={styles.videoEnsure}>Please ensure your camera and microphone are enabled</p>
                  </div>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                      <video ref={previewVideoRef} autoPlay playsInline className={styles.previewVideo} />
                    </Col>
                  </Row>
                </>
              ) : (
                videoTiles.map(tile => (
                  <>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                    <video
                      style= { (showTranscription && tile.boundAttendeeId === localAttendeeIdRef.current ) ? {} : {}  }
                      key={tile.tileId}
                      ref={tile.ref}
                      autoPlay
                      playsInline
                      className={`${styles.videoElement} ${
                        isSolo ? styles.primaryVideo : (tile.boundAttendeeId === localAttendeeIdRef.current ? (minimized ? styles.localVideoMinimized : styles.localVideo) : styles.remoteVideo)
                      }`}
                    />
                    </Col>
                  </Row>
                </>
                ))
              )
            )}
            </Col>    
            {showTranscription && (
              <Col md={3} style={{ overflowY: 'auto', height: '100%' }}>
                {transcriptions.map((transcription) => (
                  <div key={transcription.resultId}>
                    <strong>{transcription.speaker}</strong>: {transcription.text}
                  </div>
                ))}
              </Col>
            )}   
          {/* </Row> */}
        </div>
        <div>
          <Row className="w-100 justify-center-content"> 
          {(inPreviewMode || (!inPreviewMode && !minimized)) &&
            <Col xs={3} md={3} className="d-flex justify-content-flex-start">
              {!inPreviewMode &&  !minimized &&    
                <div style={{ textAlign: 'left', display:"flex", alignSelf:"center"}}>
                  <span><FontAwesomeIcon icon={['far', 'fa-clock']} /> {formatTime(callDuration)}</span>
                </div>
              }
            </Col>
          }
            <Col xs={6} md={6} className={styles.rowContent}>
                {inPreviewMode ?
                  <Form style={{display:"flex", alignItems:"center"}}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={!isMuted}
                        onChange={toggleMute}
                    />
                    {isMuted ? (
                      <FontAwesomeIcon icon="fa-solid fa-microphone-slash" style={{color: "#4F4F4F", width:"20px" }} size="lg"/>
                    ) : (
                      <FontAwesomeIcon icon="fa-solid fa-microphone" style={{color: "#4F4F4F", width:"20px"}} size="lg" />
                    )}                    
                  <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={captureMedia}
                        onChange={toggleCaptureMedia}
                        style={{marginLeft: '25px'}}
                    />
                    <FontAwesomeIcon icon="fa-solid fa-notes-medical" style={{color: "#4F4F4F"}} size="lg" />
                  </Form>
                  : ( 
                    <>
                      <Button className={styles.AppointmentToggleButtons} onClick={toggleVideo} variant="secondary">
                        {hideVideo ? (
                          <FontAwesomeIcon icon={['fas', 'video-slash']} style={{color:"#4F4F4F"}} size="xl"/>
                        ) : (
                          <FontAwesomeIcon icon={['fas', 'video']} style={{color:"#4F4F4F"}} size="xl"/>
                        )}
                      </Button>
                      <Button className={styles.AppointmentToggleButtons} onClick={toggleMute} variant="secondary">
                        {isMuted ? (
                          <FontAwesomeIcon icon={['fas', 'microphone-slash']} style={{color:"#4F4F4F"}} size="xl"/>
                        ) : (
                          <FontAwesomeIcon icon={['fas', 'microphone']} style={{color:"#4F4F4F"}} size="xl"/>
                        )}
                      </Button>
                      {!minimized &&        
                        <Button className={styles.AppointmentToggleButtons} onClick={toggleTranscription} variant="secondary">
                          {showTranscription ? (
                            <FontAwesomeIcon icon={['fas', 'stop']} style={{color:"#4F4F4F"}} size="xl"/>
                          ) : (
                            <FontAwesomeIcon icon={['fas', 'quote-right']} style={{color:"#4F4F4F"}} size="xl"/>
                          )}
                        </Button>
                      }
                    </>  
                  )
                }     
            </Col>
            <Col xs={(!inPreviewMode && minimized) ? 6 : 3} md={(!inPreviewMode && minimized) ? 6 : 3} className={`d-flex align-items-center ${minimized ? "justify-content-center" : "justify-content-end"}`} style={(!inPreviewMode && minimized) ? {gap: "10px"} : {}}>
              {!inPreviewMode &&
                <Button onClick={leaveMeeting} variant="danger" className={styles.endCallButtonAppointments}>
                  <HangupIcon />
                  {!minimized && 'End Call'}
                </Button>
              }
              {!inPreviewMode && minimized &&
                  <div style={{ textAlign: 'left', display:"flex", alignSelf:"center"}}>
                    <span>{formatTime(callDuration)}</span>
                  </div>
              }
            </Col>
          </Row>
          {inPreviewMode && 
            <Row className="w-100 mt-2 justify-center-content">
              <Col xs={6} md={6}>
                <Button onClick={joinMeeting} variant="success" className={styles.StartCallAppointment}>Start Call</Button>
              </Col>
              <Col xs={6} md={6}>
                <Button onClick={leaveMeeting} variant="danger" className={styles.EndCallAppointment}>End Call</Button>
              </Col>
            </Row>
          }
          
        </div>
      </div>
      <audio ref={audioElementRef} style={{ display: 'none' }}></audio>
    </>
  );
  
}
export default AppointmentVideoCall;