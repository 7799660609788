import React, { useState } from 'react';
import styles from '../../Patient.module.css';
import { useSelector, useDispatch } from 'react-redux';
import inputStyles from '../../../ManagementPages.module.css';
import { DropdownData } from '../../../../components/AccountManagement/DropdownData';
import { setFormField } from '../../../../slicers/PatientSlice';

const EmergencyContactDetail = ({ setModal, setUpdateFn, setCancelFn }) => {

    const dispatch = useDispatch();
    const patient = useSelector((state)=>state.patient);
    const {emergencyContact, firstName, lastName} = patient;
    const [showUpdateButtons, setShowUpdateButtons] = useState(false);
    const initialState = {
        fullName: emergencyContact.fullName || "",
        relation: emergencyContact.relation || "",
        gender: emergencyContact.gender || "",
        countryCode: emergencyContact.countryCode || "",
        phone: emergencyContact.phone || "",
        email: emergencyContact.email || "",
        address1: emergencyContact.address1 || "",
        address2: emergencyContact.address2 || "",
        city: emergencyContact.city || "",
        state: emergencyContact.state || "",
        zip: emergencyContact.zip || "",
        country: emergencyContact.country || ""
    };
    const [ updatedInfo, setUpdatedInfo ] = useState(initialState);

    const handleChange = (e) => {
        const { id, value } = e.target;
        const slicedId = id.substring(10);
        const tempData = {...updatedInfo};
        tempData[slicedId] = value;
        setUpdatedInfo(tempData);
        if (!showUpdateButtons) {
            setShowUpdateButtons(true);
        }
    };

    const handleUpdate = () => {
        const updatingInfo = {...patient,emergencyContact:updatedInfo};
        const updateFn = () => {
            setShowUpdateButtons(false);
            dispatch(setFormField({key:"emergencyContact", value:updatingInfo}));
        };
        setUpdateFn(()=>updateFn);
        setCancelFn(()=>handleCancel);
        setModal({title:"Emergency Contact", message:`Update ${firstName} ${lastName}'s emergency contact?`, data:updatingInfo});
    };

    const handleCancel = () => {
        setUpdatedInfo(initialState);
        setShowUpdateButtons(false);
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"15px"}}>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="emergency_fullName"
                    value={updatedInfo.fullName}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.fullName && inputStyles['filled']}>
                    Emergency Contact Full Name
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="emergency_relation"
                    value={updatedInfo.relation}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.relation && inputStyles['filled']}>
                    Relationship
                </label>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Gender</span>
                <select
                    className={inputStyles["styled-input-select"]}
                    id="emergency_gender"
                    value={updatedInfo.gender}
                    onChange={handleChange}
                >
                    <option value="">Select a Gender</option>
                    {DropdownData.gender.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="emergency_email"
                    type="email"
                    value={updatedInfo.email}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.email && inputStyles['filled']}>
                    Email
                </label>
            </div>
            <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        style={{width:"120px"}}
                        id="emergency_countryCode"
                        type="number"
                        value={updatedInfo.countryCode}
                        onChange={handleChange}
                    ></input>
                    <label className={updatedInfo.countryCode && inputStyles['filled']}>
                        Country Code
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        style={{width:"175px"}}
                        id="emergency_phone"
                        type="number"
                        value={updatedInfo.phone}
                        onChange={handleChange}
                    ></input>
                    <label className={updatedInfo.phone && inputStyles['filled']}>
                        Phone Number
                    </label>
                </div>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="emergency_address1"
                    value={updatedInfo.address1}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.address1 && inputStyles['filled']}>
                    Street Address (Ave, St, Blvd, etc.)
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="emergency_address2"
                    value={updatedInfo.address2}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.address2 && inputStyles['filled']}>
                Apartment, Suite, etc
                </label>
            </div>
            <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                <div className="input-block">
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="emergency_city"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.city}
                            onChange={handleChange}
                        ></input>
                        <label className={updatedInfo.city && inputStyles['filled']}>
                        City
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="emergency_state"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.state}
                            onChange={handleChange}
                        ></input>
                        <label className={updatedInfo.state && inputStyles['filled']}>
                        State
                        </label>
                    </div>
                </div>
                <div className="input-block" style={{marginBottom:"0px"}}>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="emergency_country"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.country}
                            onChange={handleChange}
                        ></input>
                        <label className={updatedInfo.country && inputStyles['filled']}>
                        Country
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            style={{width:"147px"}}
                            type="number"
                            id="emergency_zip"
                            value={updatedInfo.zip}
                            onChange={handleChange}
                        ></input>
                        <label className={updatedInfo.zip && inputStyles['filled']}>
                        Postal Code
                        </label>
                    </div>
                </div>
            </div>
            {showUpdateButtons &&
                <div style={{display:"flex", width: "100%", justifyContent:"space-between"}}>
                    <button variant='primary' className={styles.ModelButton} onClick={handleUpdate} >Save</button>
                    <button variant='secondary' className={styles.SecondaryButton} onClick={handleCancel} >Cancel</button>
                </div>
            }
        </div>
    );
}

export default EmergencyContactDetail;