import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ fileType, onDrop }) => {
    const acceptType = (() => {
        if (fileType === 'image') {
            return {
                'image/jpeg': [],
                'image/png': [],
            };
        } else if (fileType === 'audio') {
            return {
                'audio/*': [],
            };
        } else if (fileType === 'mp4') {
            return {
                'video/mp4': [],
            };
        } else if (fileType === 'pdf') {
            return {
                'application/pdf':[]
            };
        }
        return {}; 
    })();
    

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: acceptType,
        onDrop: onDrop
    });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
            <em>(Only {fileType} files will be accepted)</em>
        </div>
    );
}

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default FileUpload;
