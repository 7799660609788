import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./PatientEnrollment.module.css";
import { LeftArrowIcon } from "../../components/icons/Icons";
import ManagementDropdownCard from "../../components/ManagementDropdownCard";
import { useNavigate } from "react-router-dom";
import { resetProgramState } from "./PatientEnrollmentSlice";
import ManagementStyles from '../ManagementPages.module.css';
import { setFormValue } from "./EnrollmentListSlicer";
import containerStyles from '../program-management/ProgramManagement.module.css';
import useAxios from '../../services/AxiosConfig';
import { setModalitiesState } from "./PatientModalitiesSlice";

const ViewProgram = () => {

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const userId = useSelector((state)=>state.userInfo.id);
    const dispatch = useDispatch();
    const data = useSelector((state)=>state.enrollPatientProgram);
    const deviceList = useSelector((state)=>state.enrollPatientProgram.devices);
    const questionnaireList = useSelector((state)=>state.enrollPatientProgram.questionnaires);
    const contentList = useSelector((state)=>state.enrollPatientProgram.contents);
    const [allModalities, setAllModalities] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
    const [selectedContent, setSelectedContent] = useState([]);
    const { notifications, modalityFrequencies } = useSelector((state)=>state.patientEnrollmentModalities);
    const hubs = useSelector(state => state.enrollPatientProgram.hubs);
    const hubSerialNumber = (hubs?.length > 0 && hubs[0]?.serialNumber) ? hubs[0].serialNumber : "";

    const savedModalityValues = useSelector((state) => state.savedPatientModalities.patientRecentModalities[data.patient.id]) ?? {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    contentResponse,
                    questionnaireResponse,
                    deviceResponse
                  ] = await Promise.all([
                    httpService.get(`content/list/${userId}`),
                    httpService.get(`questionnaire/list/${userId}`),
                    httpService.get(`device/group/${userId}`)
                  ]);
                  dispatch(setFormValue({key:"content", value:contentResponse.data.data}));
                  dispatch(setFormValue({key:"questionnaires", value:questionnaireResponse.data.data}));
                  await initSelectedQuestionnaires(questionnaireResponse.data.data);
                  await initSelectedContent(contentResponse.data.data);
                  const deviceData = deviceResponse.data.data;
                    let tempDevices = [];
                    let uniqueDevices = new Set();
                    if (deviceData.length >= 1) {
                        deviceData.forEach((device)=>{
                            if (!uniqueDevices.has(device.deviceId)) {
                                tempDevices.push(device);
                                uniqueDevices.add(device.deviceId);
                            }
                        })
                    }
                    dispatch(setFormValue({key:"devices", value:tempDevices}));
                    dispatch(setModalitiesState({programDevices: deviceList, devicesList:tempDevices, modalityValues:savedModalityValues}));
                    await initModalities(tempDevices)
                    await initSelectedDevices(tempDevices)
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
        fetchData();
      }, []);

    const initModalities = async (devices) => {
        const modalitiesList = [];
        deviceList.forEach((device)=>{
            let foundDevice = devices.find(item => item.deviceId === device.deviceId)
            if (foundDevice) {
                foundDevice.modalities.forEach((mod)=>{
                    modalitiesList.push({id: mod.modalityId, name:mod.modalityName})
                })
            }
        })
        setAllModalities(modalitiesList);
    };

    const initSelectedDevices = async (devices) => {
        // check to see if content already exists in the state,
        const tempDevices = [];
        deviceList.forEach((item)=> {
            let exisitingDeviceIndex = devices.findIndex(device => device.deviceId === item.deviceId);
            if (exisitingDeviceIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempDevices.push(devices[exisitingDeviceIndex]);
            } else {
                tempDevices.push({
                    id: item.deviceId, 
                    name: item.deviceName
                })
            }
        })
        setSelectedDevices(tempDevices);
    };

    const initSelectedQuestionnaires = async (questionnaires) => {
        // check to see if questionnaire already exists in the state,
        const tempQs = [];
        questionnaireList.forEach((item)=> {
            let existingQIndex = questionnaires.findIndex(q => q.questionnaireId === item.questionnaireId);
            if (existingQIndex !== -1){
                // if addProgram.questionnaire has a match in the questionnaire list, add to selectedQuestionnaires
                tempQs.push(questionnaires[existingQIndex]);
            } else {
                return;
            }
        })
        setSelectedQuestionnaires(tempQs);
    };

    const initSelectedContent = async (content) => {
        // check to see if content already exists in the state,
        const tempContent = [];
        contentList.forEach((item)=> {
            let existingContentIndex = content.findIndex(content => content.id === item.contentId);
            if (existingContentIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempContent.push(content[existingContentIndex]);
            } else {
                return;
            }
        });
        setSelectedContent(tempContent);
    };

    const navigate = useNavigate();
    const onBackHandler = async () => {
        navigate('/patient-enrollment');
        dispatch(resetProgramState());
    };

    const errorMessages = {
        program:{patientShowing:false,programShowing:false,durationShowing:false,groupShowing:false,},
        device:{showing:false, modalityShowing: false,modalityErrors: [],deviceIdErrors: []
        },
        rules: {repeatNotificationShowing: false,repeatModalityFrequencyShowing: false,
        },
        questionnaire:{showing:false},
        content:{contentShowing:false,programShowing:false,},
    };

    const initTeamNames = () => {
        let retString = "";
        data.enrollment.groups.forEach((team)=> {
            retString += `${team.teamName}, `;
        })
        return retString.slice(0, -2);
    };

    const teamNames = initTeamNames();

    const initGroupNames = () => {
        const groupNames = Array.from(new Set(data.enrollment.groups.map((group) => group.groupName)));
        let retString = "";
        groupNames.forEach((group)=> {
            retString += `${group}, `;
        })
        return retString.slice(0, -2);
    };

    const groupNames = initGroupNames();

    return (
        <div className={ManagementStyles.MainContent}>
            <div className={ManagementStyles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Patient Enrollment</span>
            </div>
            <div className={ManagementStyles.MiddleContentAdd}>
                <div className={containerStyles.HideScrollContainer}>
                    <div className={styles.viewProgramContent}>
                        <span style={{color:"4F4F4F"}} className="headline-6">View Program</span>
                        <div>
                            <span className="subtitle-2">Patient</span>
                            <div className="body-2">{`${data.patient.firstName} ${data.patient.lastName}`}</div>
                        </div>
                        <hr></hr>
                        <span className="subtitle-2">Program Information</span>
                        <div>{`Program Name: ${data.name}`}</div>
                        <div>{`Group: ${groupNames}`}</div>
                        <div>{`Team: ${teamNames}`}</div>
                        <div>{`Notes: ${data.description}`}</div>
                        <div>{`Duration: ${data.duration} days`}</div>
                        <div>{`Admit Date: ${data.enrollment?.startDate}`}</div>
                        <div>{`Expected Discharge Date: ${data.enrollment?.endDate}`}</div>
                        <hr></hr>
                        <span className="subtitle-2">Device Setup</span>
                        {hubSerialNumber !== "" && 
                            <>
                            <span>Hub</span>
                            <div>{`Serial Number:: ${hubSerialNumber}`}</div>
                            </>
                        }
                        {selectedDevices.length > 0 &&
                            <>
                                <div className={styles.viewProgramContentCards}>
                                {selectedDevices.map((device, index) => (
                                    <div key={index}>
                                        <ManagementDropdownCard 
                                            open={index === 0}
                                            contentInfo={{title:device.model, id:device.deviceId, modalities:device.modalities}} 
                                            cardType={"Device"} 
                                            dataList={deviceList}
                                            patientEnrollment={true}
                                            viewOnly={true}
                                            errorShowing={errorMessages.device}
                                        />
                                    </div>
                                ))}
                                </div>
                                <div>{`Notes: ${data.deviceNotes}`}</div>
                            </>
                        }
                        <hr></hr>
                        <span className="subtitle-2">Enrollment Rules</span>
                        {notifications.length > 0 &&
                            <>
                                <span>Notification</span>
                                {notifications.map((not) => {
                                    const modalityName = allModalities.find((mod)=>mod.id === not.modalityId)?.name;
                                    return (
                                        <span key={not.ruleId} style={{paddingLeft: "10px"}}>
                                        {` - Notify me if ${modalityName} is outside the threshold ${not.thresholdCount} times in ${not.timeFrequency} ${not.timeInterval}`}
                                        </span>
                                    )
                                })}
                            </>
                        }
                        {modalityFrequencies.length > 0 &&
                            <>
                               <span>Modality Frequency</span>
                               {modalityFrequencies.map((mod) => {
                                    const modalityName = allModalities.find((m)=>m.id === mod.modalityId)?.name;
                                    return (
                                        <span key={mod.ruleId} style={{paddingLeft: "10px"}}>
                                        {` - Notify me if ${modalityName} is ${mod.thresholdType} the threshold ${mod.thresholdCount} times in ${mod.timeFrequency} ${mod.timeInterval} increase frequency for ${mod.frequencyMultiplier}x for ${mod.frequencyDurationValue} hour`}
                                        </span>
                                    )
                                })}
                            </>
                        }
                        <hr></hr>
                        <span className="subtitle-2">Questionnaire Setup</span>
                        {selectedQuestionnaires.length > 0 && 
                            <>
                                <div className={styles.viewProgramContentCards}>
                                    {selectedQuestionnaires.map((q, index) => (
                                        <div key={q.questionnaireId}>
                                            <ManagementDropdownCard 
                                                open={index!==0 ? false : true} 
                                                contentInfo={{title:q.name, id:q.questionnaireId}}
                                                cardType={"Questionnaire"} 
                                                dataList={questionnaireList}
                                                viewOnly={true}
                                                errorShowing={errorMessages.questionnaire}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div>{`Notes: ${data.questionnaireNotes}`}</div>
                            </>
                        }
                        <hr></hr>
                        <span className="subtitle-2">Content Setup</span>
                        {selectedContent.length > 0 && 
                            <>
                                <div className={styles.viewProgramContentCards}>
                                    {selectedContent.map((content, index) => (
                                        <div key={content.id}>
                                            <ManagementDropdownCard 
                                                open={index!==0 ? false : true} 
                                                contentInfo={{title:content.name, id:content.id}}
                                                cardType={"Content"} 
                                                dataList={contentList}
                                                viewOnly={true}
                                                errorShowing={errorMessages.content}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div>{`Notes: ${data.contentNotes}`}</div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProgram;
