import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { resetProgramState, setProgramState } from './AddProgramSlice';
import { resetModalitiesState, setModalitiesState } from './ModalitiesSlice';
import { setSelectedRows, setSelectAll } from '../../components/PerinHealthGridSlice';
import styles from '../ManagementPages.module.css';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import { closeModal } from '../../components/PerinHealthModelSlice';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { setFormValue } from './SetupListsSlice';


const ProgramManagement = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetProgramState());
        dispatch(resetModalitiesState());
    }, []);
    

    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    let selectedRows = perinHealthGrid.selectedRows;
    const userId = useSelector((state)=>state.userInfo.id);
    const devices = useSelector((state)=>state.programTemplateLists.devices);

    const headers = [
        { key: 'id', label: "ID"},
        { key: 'name', label: "Program Name"},
        { key: 'duration', label: "Program Duration"},
        { key: 'dateCreated', label: "Date Created"},
        { key: 'groupName', label: "Group"},
    ];

    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        getProgramsById();
    }, []);

    const getProgramsById = () => {
        httpService.get(`careprogram/list/${userId}`)
        .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
                // const formattedPrograms = formatPrograms(responseData.data.data);
                setPrograms(responseData.data.data);
            } else {
                console.error('Invalid data format:');
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const fetchItems = () => {
        httpService.get(`content/list/${userId}`)
        .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
                dispatch(setFormValue({key:"content", value:responseData.data.data}));
            } else {
                console.error('Invalid data format:');
            }
        })
        .catch((err) => {
            console.log(err);
        });

        httpService.get(`device/group/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const deviceData = responseData.data.data;
                    let tempDevices = [];
                    let uniqueDevices = new Set();
                    if (deviceData.length >= 1) {
                        deviceData.forEach((device)=>{
                            if (!uniqueDevices.has(device.deviceId)) {
                                tempDevices.push(device);
                                uniqueDevices.add(device.deviceId);
                            }
                        })
                    }
                    dispatch(setFormValue({key:"devices", value:tempDevices}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
        });

        httpService.get(`questionnaire/list/${userId}`)
        .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
                dispatch(setFormValue({key:"questionnaires", value:responseData.data.data}));
            } else {
                console.error('Invalid data format:');
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }
    
    const onAddProgramHandler = () => {
        fetchItems();
        navigate(`/add-program`);
    };

    const onRowClickHandler = (rowItem) => {
        fetchItems();
        dispatch(setProgramState(rowItem));
        // dispatch(setModalitiesState({programDevices:rowItem.devices, devicesList:devices}));
        navigate(`/add-program`);
    };

    const onDeleteHandler = () => {
        const deletePromises = selectedRows.map((program) => {
            const id = program.id;
            console.log(`Deleting row with ID ${id}`);
            return httpService.delete(`careprogram/${id}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getProgramsById();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully deleted programs`,
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Error deleting programs`,
                        type: 'danger'
                    })
                );
                
            });
    };

    return (
        <div className={styles.MainContent}>
            {/* <div className={styles.Title}>
                Program Management
            </div> */}
            <div className={styles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={false}
                    isTeam={false}
                    addButtonLabel='Add Program'
                    onAddHandler={onAddProgramHandler}
                    onDeleteHandler={onDeleteHandler} 
                    modelHeader='Remove Program?'
                />
                <PerinHealthGrid
                    dataList={programs}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    showHeaderFilter={true}
                    gridHeight="100%"
                />
            </div>
        </div>
    )
}

export default ProgramManagement;
