import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../QuestionnaireManagement.module.css';

import deleteIcon from '../../../assets/delete_icon.png';
import { EditIcon } from '../../../components/icons/Icons';

const InternalPreviewComponent = ({ question, onEdit, onDelete }) => {

    const addQuestionnaireState = useSelector((state) => state.addQuestionnaire);
    const isUpdateQuestion = addQuestionnaireState.isUpdateQuestion;

    return (
        <div>
            <Row>
                <Col className={`pe-0 ${styles.NumberColumn}`}>
                    <Form.Label className='mb-0'>{`${question.questionId}.`}</Form.Label>
                </Col>
                <Col xs={9}>
                    <Form.Label size='sm' className={styles.PreviewQuestionLabel}>
                        {question?.questionText}
                    </Form.Label>
                </Col>
                {!isUpdateQuestion &&
                    <Col xs={2} className="d-flex justify-content-end align-items-center">
                        <Button variant="link" className={styles.EditButton} onClick={() => onEdit(question)}>
                            <EditIcon />
                        </Button>
                        <img src={deleteIcon} alt="delete" className={styles.DeleteIcon} onClick={() => onDelete(question)} />
                    </Col>
                }
            </Row>
            {question?.possibleAnswers?.map((answer, answerIndex) => (
                <Row key={answer.answerId} style={{ padding: '0 0 5px 10px', display:"flex", alignItems:"center"}}>
                    <Col xs={1} className={`pe-0 ${styles.NumberColumn}`}>
                        <Form.Check
                            className={styles.CheckboxComponent}
                            type={question.answerType === "Multi Select" ? "checkbox" : "radio"}
                            id={`question-${question.questionId}-answer-${answer.answerId}`}
                            disabled
                        />
                    </Col>
                    <Col xs={7}>
                        <Form.Label size='sm' className={styles.PreviewAnswerLabel}>
                            {answer.answerText}
                        </Form.Label>
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default InternalPreviewComponent;
