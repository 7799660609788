import React, { useState } from 'react';
import styles from './UserOptions.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfilePictureIcon } from '../../components/icons/Icons';

const UserOptionsCard = ({ onClose, onManageAccountClick, onPreferencesClick, onSignOut }) => {

    const userInfo = useSelector((state) => state.userInfo);
    const {fullName, firstName, lastName, email, roleName} = userInfo;
    
    return (
        <div className={styles.userOptionsCard}>
            <span className="subtitle-1">Profile</span>
            <div style={{display: "flex", alignItems: "center", justifyContent:"flex-start", padding:"10px 0", columnGap: "10px"}}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <div>
                    {userInfo.profilePictureURL !== "" ?
                        <img
                        src={userInfo.profilePictureURL} // Replace with your actual user object property
                        alt="Profile_Picture"
                        style={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '50%',
                        border: '1px solid #1D5769', // Adds a grey border around the image
                        objectFit: 'cover' // Ensures the image covers the area completely without distortion
                        }}
                    />
                    :
                    <div style={{position:"relative", display:"inline-block", cursor:"pointer"}}>
                        <span className={"profile-initials"} style={{fontSize: "16px"}}>
                        {`${firstName?.[0]}${lastName?.[0]}`}
                        </span>
                        <UserProfilePictureIcon width={42} height={42}/>
                    </div>
                    }
                    </div>
                </div>
                <div style={{display: "flex", flexDirection:"column"}}>
                    <span className="subtitle-2">{fullName}</span>
                    <span className="caption" style={{paddingBottom: "5px"}}>{email}</span>
                    <span className="caption">{roleName}</span>
                </div>
            </div>
            <div className={styles.option} onClick={onManageAccountClick}>Manage Account</div>
            <div className={styles.option} onClick={onPreferencesClick}>Preferences</div>
            {/* <hr></hr> */}
            <div className={styles.option}>Change Password</div>
            <div className={styles.option}>Security Activity</div>
            {/* <hr></hr> */}
            <div className={styles.option}>About</div>
            <button onClick={onSignOut} className={styles.logoutButton}>Logout</button>
            <span className={styles.version}>{`App V ${process.env.REACT_APP_VERSION} | Perin Patch V ${process.env.REACT_APP_VERSION}`}</span>
        </div>
    );
};

export default UserOptionsCard;
