import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../ManagementPages.module.css';
import Select from 'react-select';
import { components } from 'react-select';
import useAxios from '../../services/AxiosConfig';
import { customStyles } from "../../components/AccountManagement/CustomStyles";
import { setModalitiesState, resetModalitiesState } from './PatientModalitiesSlice';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { setProgramState, setEnrollmentState, setPatientInfo, setProgramStartDate, setProgramEndDate, setGroups, resetNewProgram, setGroupId, setSelectedProgram, setFormValue } from './PatientEnrollmentSlice';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PerinHealthModal from '../../components/PerinHealthModel';
import { openModal, closeModal } from '../../components/PerinHealthModelSlice';
import { TrashIcon } from '../../components/icons/Icons';

const PatientProgramSetup = ({ errors }) => {

    const { patients, programs, devices } = useSelector((state) => state.enrollmentLists);
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const perinHealthModal = useSelector((state) => state.perinHealthModal);

    const data = useSelector((state) => state.enrollPatientProgram);

    const { updating, adding, reenrolling, viewing } = data;
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    // teams that the signed in user is a part of
    const signedInUserGroups = useSelector((state) => state.userInfo.groups);

    // any teams the current patient is a part of that the signed in user is not
    const [excludedUserTeams, setExcludedUserTeams] = useState([]);

    const updatingId = updating ? data.enrollmentId : 0;
    const [currentUpdatingProgram, setCurrentUpdatingProgram] = useState(updatingId);

    const [count, setCount] = useState(0);
    const selectedProgram = useSelector((state) => state.enrollPatientProgram.selectedProgram);
    
    // all team dropdown options -- all teams patient is in 
    const [teamOptions, setTeamOptions] = useState([]);
    
    // teams currently assigned to the current enrollment record
    const selectedPatientGroups = data.enrollment.groups;


    const [possibleNewTeams, setPossibleNewTeams] = useState([])

    const patientId = useSelector((state) => state.enrollPatientProgram.patient.id);
    const savedModalityValues = useSelector((state) => state.savedPatientModalities.patientRecentModalities[patientId]) ?? {};

    const date = new Date();

    const getTodaysDate = () => {
        let convertingString = new Date().toISOString().split('T')[0];
        let returnDate = convertToUTC(convertingString);
        dispatch(setProgramStartDate(returnDate));
        return returnDate.split('T')[0];
    };

    const convertToUTC = (dateString) => {
        // converting selected date (yyyy-mm-dd) to UTC
        if (!date) return "";

        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const localDate = new Date(dateString);
        const utcDate = new Date(localDate.getTime() + timezoneOffset);
        const utcTimestamp = utcDate.toISOString();
        return utcTimestamp;
    };

    const formatDateFromUTC = (date) => {
        // converting utc time stamp to date input format (yyyy-mm-dd)
        if (date === "") return "";
        if (date.length === 10) return date;
        const utcDate = new Date(date);
        const localDate = utcDate.toLocaleString();
        const splitDate = localDate.split(',')[0].split('/');
        let retDate = new Date(splitDate[2], splitDate[0] - 1, splitDate[1]);
        let formattedDate = retDate.getFullYear() + '-' +
            ('0' + (retDate.getMonth() + 1)).slice(-2) + '-' +
            ('0' + retDate.getDate()).slice(-2);
        return formattedDate;
    };

    let admitDisplayDate = data.enrollment.startDate !== "" ? formatDateFromUTC(data.enrollment.startDate) : getTodaysDate();
    const admitDate = data.enrollment.startDate;
    const dischargeDateDisplay = formatDateFromUTC(data.enrollment.endDate);

    useEffect(() => {
        admitDisplayDate = formatDateFromUTC(data.enrollment.startDate);
    }, [data.enrollment.startDate]);

    useEffect(() => {
        calculateDischargeDate();
    }, [data.enrollment.startDate, data.duration]);

    useEffect(() => {
        getPatientGroups(data.patient.id);
    }, []);

    const calculateDischargeDate = () => {
        const duration = data.duration;
        const admitDateSplit = admitDisplayDate.split('-');
        const admitDateObj = new Date(admitDateSplit[0], admitDateSplit[1] - 1, admitDateSplit[2]);
        if (duration === "" || data.enrollment.startDate === "") {
            return "";
        } else {
            admitDateObj.setDate(admitDateObj.getDate() + duration);
            const conversionString = admitDateObj.toISOString().split('T')[0];
            dispatch(setProgramEndDate(convertToUTC(conversionString)));
        }
    };

    useEffect(() => {
        setSelectedProgram(data.name !== "" ? { name: data.name, id: data.enrollmentId } : { name: "" });
    }, [data]);

    const showPatientError = errors.patientShowing && data.patient.id === "";
    const showProgramError = errors.programShowing && data.name === "";
    const showDurationError = errors.durationShowing && data.duration === "";
    const showGroupsError = errors.groupShowing && data.enrollment.groups.length === 0;


    const handleNotes = (e) => {
        setCount(e.target.value.length);
        dispatch(setFormValue({ key: "description", value: e.target.value }));
    };

    const getPatientGroups = (id, initializeSelected = false) => {
        httpService.get(`list/groups/${id}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const groupData = responseData.data.data;
                    dispatch(setGroupId(groupData[0].groups[0].groupId));

                    // Create a flat array of all teams
                    const allTeams = groupData.flatMap(org => 
                        org.groups.flatMap(group => 
                            group.subgroups.flatMap(subgroup => 
                                subgroup.teams.map(team => ({
                                    organizationId: org.organizationId,
                                    groupId: group.groupId,
                                    subGroupId: subgroup.subGroupId,
                                    teamId: team.teamId,
                                    groupName: group.groupName,
                                    subGroupName: subgroup.subGroupName,
                                    teamName: team.teamName,
                                    value: `${group.groupName} - ${subgroup.subGroupName} - ${team.teamName}`,
                                    label: `${group.groupName} - ${subgroup.subGroupName} - ${team.teamName}`,
                                    key: {"organizationId": org.organizationId, "groupId": group.groupId, "subGroupId": subgroup.subGroupId, "teamId": team.teamId}
                                }))
                            )
                        )
                    );

                    const signedInUserTeams = signedInUserGroups.flatMap(org => 
                        org.groups.flatMap(group => 
                            group.subgroups.flatMap(subgroup => 
                                subgroup.teams.map(team => ({
                                    organizationId: org.organizationId,
                                    groupId: group.groupId,
                                    subGroupId: subgroup.subGroupId,
                                    teamId: team.teamId,
                                    groupName: group.groupName,
                                    subGroupName: subgroup.subGroupName,
                                    teamName: team.teamName
                                }))
                            )
                        )
                    );

                    setTeamOptions(allTeams);
                    setExcludedUserTeams(allTeams.filter(o1 => !signedInUserTeams.some(o2 => o1.teamId === o2.teamId)));


                    if (initializeSelected) {
                        const patientDoctorTeams = allTeams.filter((patientTeam)=> signedInUserTeams.find((userTeam)=>userTeam.teamId === patientTeam.teamId) )
                        dispatch(setGroups(patientDoctorTeams));
                    }
                } else {
                    console.error("Can't fetch patient groups");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChangeInUpdatingProgram = (enrollmentId) => {
        dispatch(setGroups([]));
        setCurrentUpdatingProgram(enrollmentId);
        const updatingPrograms = data.updatingProgramOptions;
        try {
            httpService.get(`/careprogram/patient/enroll/${enrollmentId}`)
            .then((responseData) => {
                if (responseData.data.data) {
                    const programData = responseData.data.data[0];
                    dispatch(setEnrollmentState(programData));
                    dispatch(setFormValue({key:"updatingProgramOptions", value:updatingPrograms}));
                    dispatch(setModalitiesState({ programDevices: programData.devices, devicesList: devices, modalityValues: savedModalityValues}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        } catch (error) {
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Could not switch programs to update`,
                    type: 'danger'
                })
            );
        }
    }

    const handlePatientSelection = (selected) => {
        dispatch(setPatientInfo(selected.value));
        dispatch(setGroups([]));
        getPatientGroups(selected.value.id, true);
    };

    const handleProgramSelection = (program) => {
        if (program.label === "Create New Program") {
            dispatch(resetNewProgram());
            dispatch(resetModalitiesState());
            dispatch(setSelectedProgram({ name: "Create New Program", id: 0 }));
            dispatch(setGroupId(data.enrollment.groups[0].groupId));
        } else {
            dispatch(setSelectedProgram({ id: program.value.id, name: program.value.name }));
            dispatch(setProgramState(program.value));
            dispatch(setModalitiesState({ programDevices: program.value.devices, devicesList: devices, modalityValues: savedModalityValues}));
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === "duration") {
            dispatch(setFormValue({ key: id, value: value === "" ? "" : Number(value) }));
            return;
        }
        dispatch(setFormValue({ key: id, value: value }));
    };

    const notesTooltip = (
        <Tooltip id="notes-tooltip">
            Add any additional information, description, or comments about the patient's program here. <strong>This is an optional field.</strong>
        </Tooltip>
    );

    // Custom Option component with checkbox
    const Option = props => (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{ marginRight: '8px' }}
                />
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{props.label}</span>
            </div>
        </components.Option>
    );

    // Custom MultiValue component to render selected options as chips
    const MultiValue = ({ children, ...props }) => (
        <components.MultiValue {...props}>
            {children}
        </components.MultiValue>
    );

    // Custom ValueContainer to show selected count
    const ValueContainer = ({ children, ...props }) => {
        const { getValue, hasValue } = props;
        const selectedCount = getValue().length;
        return (
            <components.ValueContainer {...props}>
                {hasValue ? `${selectedCount} Teams Selected` : children}
            </components.ValueContainer>
        );
    };

    // Custom styles for the multi-select dropdown
    const multiSelectStyles = {
        ...customStyles.popup,
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#e6f3ff' : 'white',
            color: 'black',
            padding: '8px',
            '&:hover': {
                backgroundColor: '#f0f0f0',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e6f3ff',
            maxWidth: 'calc(100% - 20px)',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'black',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'auto',
            minWidth: '100%',
            maxWidth: '500px',
            zIndex: 9999,
            backgroundColor: '#fff',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '300px',
        }),
        container: (provided) => ({
            ...provided,
            width: '300px',
        }),
        control: (provided, state) => ({
            ...provided,
            height: '44px',
            paddingRight: '30px',
            alignItems: 'center',
            gap: '10px',
            flexShrink: 0,
            borderRadius: '6px',
            border: '0.5px solid var(--Colors-ol-input-default, #686869)',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '16px',
            fontFamily: '"Roboto" !important',
            fontWeight: 400,
            lineHeight: '24px !important',
            width: '300px',
            backgroundColor: '#FFFFFF',
            color: '#000',
            padding: '15px 12px 10px 12px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
            display: 'flex',
            alignItems: 'flex-end', // Align items to the bottom
            paddingBottom: '4px', // Add some padding at the bottom
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            right: '0',
            top: '0',
            paddingRight: '8px',
            zIndex: 1,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            height: '70%',
            alignSelf: 'center',
            marginRight: '8px',
        }),
    };

    const handleTeamSelection = (selectedOptions) => {
        const newTeams = selectedOptions.filter(o1 => !selectedPatientGroups.some(o2 => o1.teamId === o2.teamId));
        const newTeamsExcluded = newTeams.filter(teamId => excludedUserTeams.includes((teamId)));
        
        if (newTeamsExcluded.length >= 1) {
            dispatch(
                openModal({
                    header: "Add Team to Enrollment?",
                    message: `You are not a part of the ${newTeamsExcluded[0].teamName} team.\n Would you still like to add this team to ${data.patient.firstName + " " + data.patient.lastName}'s enrollment`,
                    buttons: [
                        { label: 'Add', variant: 'primary', onClickKey: "Add" },
                        { label: 'Cancel', variant: 'secondary', onClickKey: "Cancel" },
                    ],
                })
            );
            setPossibleNewTeams(selectedOptions)
        } else {
            dispatch(setGroups(selectedOptions));
        }
    };

    const handleModalOptions = (onClickKey) => {
        if (onClickKey === "Add") {
            dispatch(closeModal());
            dispatch(setGroups(possibleNewTeams));
        
        } else {
            dispatch(closeModal());
        }
    };

    const handleRemoveTeam = (teamToRemove) => {
        const updatedTeams = selectedPatientGroups.filter(team => 
            team.organizationId !== teamToRemove.organizationId ||
            team.groupId !== teamToRemove.groupId ||
            team.subGroupId !== teamToRemove.subGroupId ||
            team.teamId !== teamToRemove.teamId
        );
        dispatch(setGroups(updatedTeams));
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            {showPatientError &&
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="2xs" style={{ color: "red" }} />
                    <span
                        className={styles.ValidationMessage}
                    >
                        {errors.patientMessage}
                    </span>
                </div>
            }
            {updating &&
                <div style={{position: "relative"}}>
                <span className={"floating-label"} style={{zIndex:"1"}}>Program Updating</span>
                    <select
                        style={{ width: "300px" }}
                        className={styles["styled-input-select"]}
                        onChange={(e) => handleChangeInUpdatingProgram(e.target.value)}
                        value={currentUpdatingProgram}
                    >
                        {data.updatingProgramOptions.map((enrollment) => (
                            <option key={enrollment.enrollmentId} value={enrollment.enrollmentId}>
                                {enrollment.name}
                            </option>
                        ))}
                    </select>
            </div>
            }
            <div style={{position: "relative", width: "300px"}}>
                <span className={"floating-label"} style={{zIndex:"1"}}>Patient Name</span>
                    <Select
                        options={patients}
                        styles={{
                            ...customStyles.popup,
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                            }),
                        }}
                        value={{value:data.patient.id, label:(data.patient.firstName + " " + data.patient.lastName)}}
                        placeholder=''
                        isSearchable={true}
                        isDisabled={adding || updating || reenrolling || viewing}
                        onChange={handlePatientSelection}
                    />
            </div>
            {data.patient.id !== "" && !reenrolling &&
                <div style={{position: "relative", width: "100%"}}>
                    <span className={"floating-label"} style={{zIndex:"1"}}>
                        {viewing ? "View Teams" : "Assign Teams"}
                    </span>
                    <Select
                        key={currentUpdatingProgram}
                        options={teamOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, ValueContainer }}
                        value={teamOptions.filter(option => 
                            selectedPatientGroups.some(group =>
                                group.groupId === option.groupId &&
                                group.subGroupId === option.subGroupId &&
                                group.teamId === option.teamId
                            )
                        )}
                        onChange={handleTeamSelection}
                        styles={multiSelectStyles}
                        isDisabled={viewing}
                        placeholder=""
                    />
                </div>
            }
                {selectedPatientGroups.length > 0 && (
                     <div>
                        <ul className="groups-list">
                        {selectedPatientGroups.map((group, index) =>  (
                                <li
                                    key={group.teamId}
                                    className="group-list-item"
                                >
                                    {`${group.groupName} - ${group.subGroupName} - ${group.teamName}`}
                                    <div style={{cursor:"pointer"}} onClick={() => handleRemoveTeam(group)}>
                                        <TrashIcon />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> 
                )}
            {showGroupsError && selectedProgram.id !== 0 &&
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="2xs" style={{ color: "red" }} />
                    <span
                        className={styles.ValidationMessage}
                    >
                        {errors.groupMessage}
                    </span>
                </div>
            }
            {data.enrollment.groups.length >= 1 && !updating &&
                <div style={{position: "relative", width: "300px"}}>
                    <span className={"floating-label"} style={{zIndex:"1"}}>Select Program</span>
                        <Select
                            options={isAdmin ? programs.filter((program)=>program.value.organizationId === data.enrollment.groups[0].organizationId || program.value.id === 0) : programs}
                            styles={{...customStyles.popup, container: (provided) => ({...provided, width: '100%'})}}
                            value={{value:data.enrollmentId, label:(selectedProgram.name)}}
                            placeholder=''
                            isSearchable={true}
                            isDisabled={reenrolling || viewing}
                            onChange={handleProgramSelection}
                        />
                </div>
            }
            {showProgramError && selectedProgram.id !== 0 &&
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="2xs" style={{ color: "red" }} />
                    <span
                        className={styles.ValidationMessage}
                    >
                        {errors.programMessage}
                    </span>
                </div>
            }
            {(selectedProgram.name !== "" || selectedProgram.id === 0) &&
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {selectedProgram.id === 0 &&
                        <div className={styles["styled-input-container"]}>
                            <input
                                id="name"
                                disabled={viewing}
                                defaultValue={data.name}
                                style={showProgramError && selectedProgram.id === 0 ? { border: "1px solid red", width:"300px" } : {width:"300px"}}
                                onChange={handleChange}
                            ></input>
                            <label className={data.name && styles['filled']}>
                                Program Name
                            </label>
                        </div>
                    }
                    {showProgramError && selectedProgram.id === 0 &&
                        <div className={styles.ValidationMessageContainer}>
                            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="2xs" style={{ color: "red" }} />
                            <span
                                className={styles.ValidationMessage}
                            >
                                Please choose a program name
                            </span>
                        </div>
                    }
                    <div className={styles["styled-input-container"]} style={{ width: "300px" }}>
                        <div style={{ position: "relative", width: "100%" }}>
                            <label className={data.description && styles['filled']}>
                                Notes
                            </label>
                            <OverlayTrigger placement="right" overlay={notesTooltip}>
                                <FontAwesomeIcon 
                                    icon="fa-solid fa-circle-info" 
                                    style={{ 
                                        color: '#686869',
                                        position: 'absolute',
                                        right: '5px',
                                        top: 'calc(50% + 5px)',
                                        transform: 'translateY(10%)'
                                    }} 
                                />
                            </OverlayTrigger>
                        </div>
                        <textarea
                            className={styles["note-input"]}
                            id="description"
                            type="text"
                            maxLength={250}
                            value={data.description}
                            style={{width: "300px"}}
                            disabled={reenrolling || viewing}
                            onChange={e => handleNotes(e)}
                        >
                        </textarea>
                        <span style={{ fontSize: "10px", color: "#929395", marginLeft: "auto" }}>
                            {count}/250
                        </span>
                    </div>
                    <div style={{ display: "flex", columnGap: "5px" }}>
                        <div className={styles["styled-input-container"]}>
                            <input
                                id="admitDate"
                                type="date"
                                value={admitDisplayDate}
                                style={{width:"145px"}} 
                                disabled={updating || viewing}
                                onChange={(e) => dispatch(setProgramStartDate(convertToUTC(e.target.value)))}
                            ></input>
                            <label className={`${admitDate && styles['filled']} ${updating ? styles['disabled'] : ''}`}>
                                Admit Date
                            </label>
                        </div>
                        <div className={styles["styled-input-container"]}>
                            <span className="floating-label">Expected Discharge Date</span>
                            <input
                                id="dischargeDate"
                                type="date"
                                disabled={true}
                                value={dischargeDateDisplay}
                                style={{ width: "150px" }}
                                onChange={(e) => dispatch(setProgramEndDate(convertToUTC(e.target.value)))}
                            ></input>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <div className={styles["styled-input-container"]}>
                            <input
                                id="duration"
                                inputMode="numeric"
                                    onKeyDown={(e) => {
                                        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                            e.preventDefault();
                                        }
                                    }}
                                value={data.duration}
                                disabled={viewing}
                                style={showDurationError ? { border: "1px solid red", width: "140px" } : { width: "140px" }}
                                onChange={handleChange}
                            ></input>
                            <label className={data.duration && styles['filled']}>
                                Duration
                            </label>
                        </div>
                        <span>Days</span>
                    </div>
                </div>
            }
            {perinHealthModal.show && (
                <PerinHealthModal
                    show={perinHealthModal.show}
                    header={perinHealthModal.header}
                    message={perinHealthModal.message}
                    buttons={perinHealthModal.buttons}
                    handleButtonClick={handleModalOptions}
                />
            )}
        </div>
    )
}

export default PatientProgramSetup;