import React, { useState, useEffect } from 'react';
import styles from '../Patient.module.css';
import FilterPatientView from '../FilterPatientDetail';
import BloodPressureCardView from '../modality-charts/BloodPressureCardView';
import HeartRateCardView from '../modality-charts/HeartRateCardView';
import OxygenSaturationCardView from '../modality-charts/OxygenSaturationCardView';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../../services/AxiosConfig';
import TemperatureCardView from '../modality-charts/TemperatureCardView';
import RespirationCardView from '../modality-charts/RespirationCardView';
import ECGCardView from '../modality-charts/ECGCardView';
import WeightCardView from '../modality-charts/WeightCardView';
import AuscultationCardView from '../modality-charts/AuscultationCardView';
import GlucoseLevelCardView from '../modality-charts/GlucoseLevelCardView';
import { resetVitalState, setItem, setModality } from './VitalSlice';
import StepCardView from '../modality-charts/StepCardView';
import ECGHRVCardView from '../modality-charts/ECGHRVCardView';
import { toggleVitalsExpanded, toggleShowingTrue } from '../Slices/PatientDetailFilterSlice';
import { ExpandIcon } from '../../../components/icons/Icons';
import { Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const VitalsView = () => {
    const dispatch = useDispatch();
    const [healthEvaluationData, setHealthEvaluationData] = useState([]);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const { startDate, endDate, vitalsExpanded, selectedCharts } = useSelector((state) => state.patientDetailFilter);
    const patient = useSelector((state) => state.patient);
    const { id, carePrograms } = patient;
    const vitals = useSelector((state) => state.vital);
    const [vitalChartOrder, setVitalChartOrder] = useState(['heartRate', 'oxygenSaturation', 'ECG', 'ECGHRV', 'bloodPressure', 'temperature', 'respiratoryRate', 'weight', 'AUSC', 'steps', 'glucoseLevel']);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await httpService.get(`device/readings/${id}?startDate=${startDate}&endDate=${endDate}`);
                const fetchedData = response.data.data;

                const groupedData = {};

                fetchedData.forEach(entry => {
                    const dateString = entry.completedDate;
                    const completedDate = new Date(dateString);
                    
                    // Timezone offset in milliseconds
                    const timezoneOffset = completedDate.getTimezoneOffset() * 60 * 1000;
                    
                    // Adjust the local date by the timezone offset
                    const utcDate = new Date(completedDate.getTime() + timezoneOffset);
                    
                    // Extract components of the adjusted UTC date
                    const month = String(utcDate.getMonth() + 1).padStart(2, '0');
                    const day = String(utcDate.getDate()).padStart(2, '0');
                    const hour = String(utcDate.getHours()).padStart(2, '0');
                    const minute = String(utcDate.getMinutes()).padStart(2, '0');
                    
                    const groupKey = `${month}/${day}-${hour}:${minute}`;
                    

                    const displayKey = completedDate.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit'
                    });

                    if (!groupedData[groupKey]) {
                        groupedData[groupKey] = {
                            displayKey,
                            timeStamp: entry.completedDate,
                            data: []
                        };
                    }

                    let deviceGroup = groupedData[groupKey].data.find(group => group.deviceId === entry.deviceId);
                    if (!deviceGroup) {
                        deviceGroup = {
                            deviceId: entry.deviceId,
                            deviceName: entry.model,
                            measurements: {
                                temperature: null,
                                heartRate: null,
                                oxygenSaturation: null,
                                ECG: null,
                                ECGHRV: null,
                                systolic: null,
                                respiratoryRate: null,
                                AUSC: null,
                                weight: null,
                                diastolic: null,
                                glucoseLevel: null,
                                steps: null
                            }
                        };
                        groupedData[groupKey].data.push(deviceGroup);
                    }

                    switch (entry.modalityId) {
                        case 1:
                            deviceGroup.measurements.temperature = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 2:
                            deviceGroup.measurements.heartRate = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 3:
                            deviceGroup.measurements.oxygenSaturation = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 4:
                            deviceGroup.measurements.ECG = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 5:
                            deviceGroup.measurements.systolic = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 6:
                            deviceGroup.measurements.respiratoryRate = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 7:
                            deviceGroup.measurements.AUSC = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 8:
                            deviceGroup.measurements.weight = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 10:
                            deviceGroup.measurements.diastolic = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 11:
                            deviceGroup.measurements.glucoseLevel = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 13:
                            deviceGroup.measurements.steps = parseFloat(entry.measurement.toFixed(1));
                            break;
                        case 15:
                            deviceGroup.measurements.ECGHRV = parseFloat(entry.measurement.toFixed(1));
                            break;
                        default:
                            break;
                    }

                });

                const transformedData = [];

                Object.keys(groupedData).forEach(date => {
                    groupedData[date].data.forEach(deviceGroup => {
                        transformedData.push({
                            date,
                            displayDate: groupedData[date].displayKey,
                            timeStamp: groupedData[date].timeStamp,
                            deviceId: deviceGroup.deviceId,
                            deviceName: deviceGroup.deviceName,
                            ...deviceGroup.measurements
                        });
                    });
                });
                setHealthEvaluationData(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [startDate, endDate, httpService, id]);

    useEffect(() => {
        dispatch(resetVitalState());
        carePrograms.forEach(item => {
            if (item.patient.id === id) {
                item.devices.forEach(device => {
                    switch (device.modalityId) {
                        case 1:
                            dispatch(setItem({ key: "isTemperature", value: true }));
                            dispatch(toggleShowingTrue("temperature"));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            break;
                        case 2:
                            dispatch(setItem({ key: "isHeartRate", value: true }));
                            dispatch(toggleShowingTrue("heartRate"));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            break;
                        case 3:
                            dispatch(setItem({ key: "isOxygenSaturation", value: true }));
                            dispatch(toggleShowingTrue("oxygenSaturation"));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            break;
                        case 4:
                            dispatch(setItem({ key: "isECG", value: true }));
                            dispatch(toggleShowingTrue("ECG"));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            break;
                        case 5:
                            dispatch(setItem({ key: "isBloodPressure", value: true }));
                            dispatch(setItem({ key: "bloodPressureDeviceName", value: device.modalityName }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("bloodPressure"));
                            break;
                        case 6:
                            dispatch(setItem({ key: "isRespiratoryRate", value: true }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("respiratoryRate"));
                            break;
                        case 7:
                            dispatch(setItem({ key: "isAUSC", value: true }));
                            dispatch(setItem({ key: "auscDeviceName", value: device.modalityName }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("AUSC"));
                            break;
                        case 8:
                            dispatch(setItem({ key: "isWeight", value: true }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("weight"));
                            break;
                        case 11:
                            dispatch(setItem({ key: "isGlucoseLevel", value: true }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("glucoseLevel"));
                            break;
                        case 13:
                            dispatch(setItem({ key: "isSteps", value: true }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("steps"));
                            break;
                        case 15:
                            dispatch(setItem({ key: "isECGHRV", value: true }));
                            dispatch(setModality({ modalityId: device.modalityId, updates: device }));
                            dispatch(toggleShowingTrue("ECGHRV"));
                            break;
                        default:
                            break;
                    }
                });
            }
        });
    }, [carePrograms, dispatch, id]);

    const chartComponents = {
        heartRate: {
            component: HeartRateCardView,
            props: { vitalName: 'heartRate', vitalUnit: ' BPM' }
        },
        oxygenSaturation: {
            component: OxygenSaturationCardView,
            props: { vitalName: 'oxygenSaturation', vitalUnit: ' %' }
        },
        ECG: {
            component: ECGCardView,
            props: { vitalName: 'ECG' }
        },
        ECGHRV: {
            component: ECGHRVCardView,
            props: { vitalName: 'ECGHRV' }
        },
        bloodPressure: {
            component: BloodPressureCardView,
            props: { vitalName: 'bloodPressure' }
        },
        temperature: {
            component: TemperatureCardView,
            props: { vitalName: 'temperature' }
        },
        respiratoryRate: {
            component: RespirationCardView,
            props: { vitalName: 'respiratoryRate', vitalUnit: ' BPM' }
        },
        weight: {
            component: WeightCardView,
            props: { vitalName: 'weight', vitalUnit: ' lbs' }
        },
        AUSC: {
            component: AuscultationCardView,
            props: { vitalName: 'AUSC' }
        },
        steps: {
            component: StepCardView,
            props: { vitalName: 'steps', vitalUnit: ' Steps' }
        },
        glucoseLevel: {
            component: GlucoseLevelCardView,
            props: { vitalName: 'glucoseLevel' }
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedCharts = Array.from(vitalChartOrder);
        const [reorderedChart] = reorderedCharts.splice(result.source.index, 1);
        reorderedCharts.splice(result.destination.index, 0, reorderedChart);

        setVitalChartOrder(reorderedCharts);
    };

    const vitalsView = () => {
        return (
            <div className={styles.ViewContainer}>
                <div style={{width: "100%", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                    <FilterPatientView isTimePeriod={true} expand={true} />
                    <div onClick={()=>dispatch(toggleVitalsExpanded())}>
                        <ExpandIcon />
                    </div>    
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="vitalCharts">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className={styles.scrollableContainer}>
                                {vitalChartOrder.map((chartName, index) => {
                                    const chartInfo = chartComponents[chartName];
                                    if (vitals[`is${chartName.charAt(0).toUpperCase() + chartName.slice(1)}`] && selectedCharts?.includes(chartName)) {
                                        return (
                                            <Draggable key={chartName} draggableId={chartName} index={index}>
                                                {(provided) => (
                                                   <div
                                                   ref={provided.innerRef}
                                                   {...provided.draggableProps}
                                                   {...provided.dragHandleProps}
                                                   className={styles.child}
                                               >
                                                   {React.createElement(chartInfo.component, {
                                                       ...chartInfo.props,
                                                       className: styles.child,
                                                       healthEvaluationData: healthEvaluationData
                                                    })}
                                                </div> 
                                                )}
                                            </Draggable>
                                        );
                                    }
                                    return null;
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    };

    return (
        vitalsExpanded ? 
            <Modal
            show={vitalsExpanded}
            onHide={() => dispatch(toggleVitalsExpanded())}
            size="xl"
            aria-labelledby="vitals-modal-title"
            centered
            dialogClassName="vitals-modal"
        >
            <Modal.Body style={{overflowY: 'auto'}}>
                {vitalsView()}
            </Modal.Body>
        </Modal>
        :
        vitalsView()
    );
};

export default VitalsView;
