import React from 'react';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import styles from '../CardDetails/CardDetails.module.css';

function QuestionnaireGraphScore({ isModalVisible, handleCloseModal, modalData }) {
    const questions = modalData?.questions ?? [];
    const answers = modalData?.answers ?? [];
    const missed = modalData?.missedStatus;
    
    const convertDate = (dateStr) => {
        const completedDate = new Date(dateStr);
        const timezoneOffset = completedDate.getTimezoneOffset() * 60 * 1000;
        const localDate = new Date(completedDate.getTime() + timezoneOffset);
        return localDate;
    };

    return (
        <Modal
            show={isModalVisible}
            onHide={handleCloseModal}
            centered={true}
            backdrop="static"
        >
            <Modal.Header className="d-flex justify-content-between align-items-center" style={{ borderBottom: "none" }}>
                <div className={styles.QScoreHeaderItems}>
                    <div className={styles.QScoreTitle}>{modalData?.name}</div>
                    <div className={styles.QScoreSubHeader}>{modalData?.qDate}</div>
                </div>
                <div className={styles.QScoreBadge} style={{background: missed ? "#9F1919" : "#1F5C29"}}>Completed</div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.DetailsContainer} style={{height:"90%", overflowY: "auto"}}>
                    <span>{`Total ${questions.length} questions ${modalData?.score !== undefined ? `Score | ${modalData.score}/${modalData.possibleScore}` : ""}`}</span>
                    {questions.length > 0 &&
                        questions.map((question, index) => {
                            return (
                                <div key={index} className={styles.Question}>
                                    <span>{`${index+1}. ${question.questionText}`}</span>
                                    <span>{answers[index].selectedAnswer}</span>
                                    <span>{`Score: ${answers[index].score}`}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', borderTop: "0.5px solid #B9B9B9" }}>
                <Button className={styles.CloseModalButton} onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QuestionnaireGraphScore;
