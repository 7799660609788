import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './Chart.module.css';
import { OxigenSaturationIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';
import ChartDetailView from './ChartDetailView';
import PerinLineChart from './PerinLineChart';

const OxygenSaturationCardView = React.forwardRef(({ className, healthEvaluationData , vitalName,vitalUnit}, ref) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const { startDate, endDate } = useSelector((state) => state.patientDetailFilter);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const vitals = useSelector((state) => state.vital.modalities[vitalName]);
  const [opacity, setOpacity] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [highestMeasurement, setHighestMeasurement] = useState(null);
  const [lowestMeasurement, setLowestMeasurement] = useState(null);

  const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = containerWidth; // Use full width of the container
        setChartWidth(width);

        const height = 200; // Set a fixed height
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateData = useCallback(() => {
    // Group data by date and deviceName
    const groupedData = healthEvaluationData.reduce((acc, entry) => {
      const date = entry.date;
      if (!acc[date]) {
        acc[date] = { date };
      }
      acc[date][entry.deviceName] = entry;
      return acc;
    }, {});

    const transformedData = Object.values(groupedData);
    transformedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    setData(transformedData);

    const deviceOpacity = healthEvaluationData.reduce((acc, entry) => {
      const { deviceName } = entry;

      if (!acc[deviceName]) {
        acc[deviceName] = [];
      }
      acc[deviceName].push(entry);

      return acc;
    }, {});

    setOpacity(Object.keys(deviceOpacity).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {}));

    const perinPatchData = healthEvaluationData.filter(entry => entry.deviceName === "Perin Patch");
    const noninData = healthEvaluationData.filter(entry => entry.deviceName === "Nonin 3230");

    let highestMeasurement = null;
    let lowestMeasurement = null;

    const calculateHighestAndLowest = (data) => {
      data.forEach(entry => {
        if (highestMeasurement === null || entry[vitalName] > highestMeasurement[vitalName]) {
          highestMeasurement = entry;
        }
        if (lowestMeasurement === null || entry[vitalName] < lowestMeasurement[vitalName]) {
          lowestMeasurement = entry;
        }
      });
    };

    if (perinPatchData.length > 0) {
      calculateHighestAndLowest(perinPatchData);
    } else if (noninData.length > 0) {
      calculateHighestAndLowest(noninData);
    }

    setDisplayData(getLatestMeasurement(healthEvaluationData));

    if (highestMeasurement && lowestMeasurement) {
      setHighestMeasurement(highestMeasurement[vitalName]);
      setLowestMeasurement(lowestMeasurement[vitalName]);
    }
  }, [healthEvaluationData, vitalName]);

  useEffect(() => {
    updateData();
  }, [updateData, startDate, endDate]);

  const handleLegendClick = (e) => {
    const deviceName = e.value;
    setOpacity((op) => ({ ...op, [deviceName]: op[deviceName] === 1 ? 0 : 1 }));
  };

  const getLatestMeasurement = useCallback((healthEvaluationData) => {
    if (!healthEvaluationData || healthEvaluationData.length === 0) {
      return { measurement: 'No Data', latestDate: 'N/A' };
    }

    const deviceNamesData = healthEvaluationData.filter(entry => entry[vitalName] !== null);
    const latestMeasurement = deviceNamesData.reduce((latest, entry) => {
      const dateA = new Date(latest.completedDate);
      const dateB = new Date(entry.completedDate);
      return dateB > dateA ? entry : latest;
    }, deviceNamesData[0]);

    return {
      measurement: latestMeasurement?.[vitalName] !== undefined ? latestMeasurement?.[vitalName]?.toFixed(1) + vitalUnit : 'No Data',
      latestDate: 'Latest: ' + (latestMeasurement?.date  || 'N/A')
    };
  }, []);

  const deviceNames = Array.from(new Set(healthEvaluationData.map(entry => entry.deviceName)));

  // Filter device names for which there is heart rate data
  const deviceNamesData = deviceNames.filter(deviceName =>
    healthEvaluationData.some(entry => entry.deviceName === deviceName && entry[vitalName] !== null)
  );

  const colors = {
    0: '#496CA3',
    1: '#2A7F38',
  };


  const handlePointClick = (deviceName, payload) => {
    const timeStamp = payload.timeStamp;
    setModalContent({ timeStamp, deviceName, modalityId: vitals.modalityId, vital: vitals.modalityName });
    setIsModalOpen(true);
  };


  return (
    <div className={`${styles.cardContainer} ${className}`} ref={chartContainerRef}>
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <OxigenSaturationIcon className={styles.icon} />
          <span className={styles.vitalName}>{vitals.modalityName}</span>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.measurement}>
            <span className={styles.value}>{displayData.measurement}</span>
          </div>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.latestDate}>{displayData.latestDate}</div>
        </div>
      </div>
      <div className={styles.chartWrapper} ref={ref}>
        <PerinLineChart
          vitalName={vitalName}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          data={data}
          vitals={vitals}
          deviceNamesData={deviceNamesData}
          colors={colors}
          opacity={opacity}
          handleLegendClick={handleLegendClick}
          handlePointClick={handlePointClick}
          highestMeasurement={highestMeasurement}
          lowestMeasurement={lowestMeasurement}
        />
      </div>
      <ChartDetailView
        isModalVisible={isModalOpen}
        customModal={styles.customModal}
        handleCloseModal={() => setIsModalOpen(false)}
        modalData={modalContent}
      />
    </div>
  );
});

export default OxygenSaturationCardView;
