import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from './Appointment.module.css';

const CancelConfirmationModal = ({ show, onHide, appointmentId, cancelAppointment, onAppointmentCanceled }) => {
    const handleConfirmCancellation = async () => {
      try {
        await cancelAppointment(appointmentId);
        console.log('Appointment cancelled successfully');
        onHide();  // Close modal after successful cancellation
        onAppointmentCanceled(); // Refresh the page
      } catch (error) {
        console.error('Cancellation failed:', error);
        // Keep the modal open if there's an error
      }
    };
  
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header style={{padding:"28px 28px 0px 28px", borderBottom:"none"}} closeButton>
          <Modal.Title className={styles.detailHeader}>Cancel Appointment?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:"5px 28px 20px 28px"}}>
          <div className="body-2">
            This action cannot be undone.
          </div>
        </Modal.Body>
        <Modal.Footer style={{borderTop:"none", padding:"0px 28px 28px 28px"}}>
          <Button variant="secondary" className={styles.detailDropdown} onClick={onHide}>
            Not now
          </Button>
          <Button variant="danger" className={styles.startCallButton} onClick={handleConfirmCancellation}>
            Cancel appointment
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default CancelConfirmationModal;
