import React, { useState, useEffect } from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import styles from './CareTeamMessage.module.css';
import { UnreadMessageIconIcon, UserProfilePictureIcon } from '../../components/icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faPhone, faTrash,faUser } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import JoinMeetingTest from '../patient-message/JoinMeetingTest';
import EllipsesIcon from '../../components/icons/EllipsesIcon';

function CareTeamListGroup({ items, onStartCall, onItemClick, onDeleteChannelMembership }) {
    const [visibleOptionsIndex, setVisibleOptionsIndex] = useState(null);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const userInfoState = useSelector((state) => state.userInfo);
    const userId = userInfoState.id;
    const unreadStaffMessageIds = useSelector((state) => state.sidebar.unreadStaffMessageIds);

    const currentMeetings = useSelector((state) => state.careTeam.currentMeetings).flat();

    const handleToggleOptions = (index) => {
        setVisibleOptionsIndex(prevIndex => prevIndex === index ? null : index);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (visibleOptionsIndex !== null && !event.target.closest(`.${styles.optionsContainer}`)) {
                setVisibleOptionsIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [visibleOptionsIndex]);

    const handleItemClick = (item, index) => {
        onItemClick(item, index);
    };

    const handleShowJoinModal = () => setShowJoinModal(true);
    const handleCloseJoinModal = () => setShowJoinModal(false);

    const joinCall = (item, name) => {
        const meeting = currentMeetings.filter((meeting)=>meeting.participantName === name);
        const audioOnly = meeting[0].title.includes("Audio");
        onStartCall(item, audioOnly);
    };

    function formatTime(item) {
        if (item[0]) {
            const messageDate = new Date(item[0].createdTime);
            const currentDate = new Date();
            const yesterday = new Date(currentDate);
            yesterday.setDate(currentDate.getDate() - 1);
        
            if (messageDate.toDateString() === currentDate.toDateString()) {
            // Today's message
            return messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            } else if (messageDate.toDateString() === yesterday.toDateString()) {
            // Yesterday's message
            return `Yesterday ${messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
            } else {
            // Older than yesterday
            return `${messageDate.getMonth() + 1}/${messageDate.getDate()} ${messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
            }
        } else {
            return "";
        }
    };

    const labelColors = {
        "Doctor": "rgba(183, 192, 238, 0.38)",
        "Nurse": "rgba(71, 140, 170, 0.20)",
        "Staff": "rgba(71, 140, 170, 0.20)",
    };


    return (
        <>
            <ListGroup variant="flush">
                {items?.map((item, index) => {
                    const hasUnreadForUser = unreadStaffMessageIds.includes(item[0]?.senderId) || unreadStaffMessageIds.includes(item[0]?.recipientId);
                    const messageName = item[0]?.senderId === userId ? item[0]?.recipientName : item[0]?.senderName;
                    const hasCall = currentMeetings.some((meeting)=>meeting.participantName === messageName);
                    const uniqueKey = item[0]?.channelArn || index;
                    // Find the first message where senderId does not equal userId
                    const firstNonUserMessage = item.find(message => message.senderId !== userId);
                    const profilePictureURL = firstNonUserMessage?.profilePictureURL || null;
                    return (
                        <ListGroup.Item key={uniqueKey} className={`${styles.listGroupItem} ${hasUnreadForUser ? styles.unread : ''}`}>
                            <div className={styles.mainContainer}>
                                <div className={`${styles.innerContainer} col-md-9`} onClick={() => handleItemClick(item, index)}>
                                    <div className={`${styles.iconContainer} ${styles.icon}`}>
                                    {!profilePictureURL && (                                            
                                        <span className={styles.initials}>
                                            {`${messageName?.split(' ')[0][0] + messageName?.split(' ')[1][0]}`}
                                        </span>
                                        )}
                                        {profilePictureURL ? (
                                            <img 
                                                src={profilePictureURL} 
                                                alt="User Profile" 
                                                style={{
                                                    width: '42px',
                                                    height: '42px',
                                                    borderRadius: '50%',
                                                    border: '2px solid grey',
                                                    objectFit: 'cover'
                                                  }}
                                            />
                                        ) : (
                                            <UserProfilePictureIcon width="42" height="42"/>
                                        )}
                                        {hasUnreadForUser && (<div className={styles.unreadMessageIcon} ><UnreadMessageIconIcon/></div>)}
                                    </div>
                                    <div className={styles.userDetails}>
                                        <div style={{display:"flex", alignItems:"center", columnGap: "10px"}}>
                                            <div className={hasUnreadForUser ? styles.userNameNew : styles.userName}>
                                                {messageName}
                                            </div>
                                            {item[0].staffTitle &&
                                                <div className={styles.staffSpecialtyContainer} style={{backgroundColor:`${labelColors[item[0].staffRole]}`}}>
                                                    {item[0].staffTitle}
                                                </div>
                                            }
                                        </div>
                                        <div className={`${hasUnreadForUser ? styles.listMessageNew : styles.listMessage} ${!item[0]?.message && styles.listMessageItalic}`}>{item[0]?.message || "Attachment"}</div>
                                    </div>
                                    {hasCall &&
                                        <div onClick={(e) => { e.stopPropagation(); joinCall(item, messageName); }} className={styles.joinCallLabel}>
                                            Join Call
                                        </div>
                                    }
                                </div>
                                <div className={`${styles.textContainer} col-md-3`}>
                                    <div className={styles.itemTimeContainer}>
                                        <p className={styles.itemTime}  style={{color:hasUnreadForUser ? "#000" : "#4F4F4F"}}>{formatTime(item)}</p>
                                    </div>

                                    <button
                                        type="button"
                                        className={styles.dotsButton}
                                        onClick={(e) => { e.stopPropagation(); handleToggleOptions(index); }}
                                    >
                                        <EllipsesIcon />
                                    </button>
                                    <div className={styles.optionsMenu} style={{ display: visibleOptionsIndex === index ? 'block' : 'none' }}>
                                        <div className={styles.optionItem} onClick={(e) => { e.stopPropagation(); onStartCall(item, false); }}>
                                            <FontAwesomeIcon icon={faVideo} />
                                            Start Video Call
                                        </div>
                                        <div className={styles.optionItem} onClick={(e) => { e.stopPropagation(); onStartCall(item, true); }}>
                                            <FontAwesomeIcon icon={faPhone} />
                                            Start Audio Call
                                        </div>
                                        <div className={styles.optionItem} onClick={(e) => {
                                        e.stopPropagation();
                                        handleShowJoinModal();
                                    }}>
                                        <FontAwesomeIcon icon={faUser}/>
                                        Join Meeting
                                    </div>
                                        <div className={styles.optionItem} onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteChannelMembership(item);
                                        }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete Message
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
            <Modal show={showJoinModal} onHide={handleCloseJoinModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Join a Meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JoinMeetingTest />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CareTeamListGroup;
