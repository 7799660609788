import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    devices: [],
    questionnaires: [],
    content: [],
};

const programTemplateListsSlice = createSlice({
    name: "programTemplateLists",
    initialState,
    reducers: {
        resetListState: (state) => {
            return initialState;
        },
        setFormValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        
    }
});

export const {
    resetListState,
    setFormValue,
  } = programTemplateListsSlice.actions;
  
  export default programTemplateListsSlice.reducer;