import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import image from '../../assets/Guide.png';
import styles from './AppTour.module.css';

const ApplicationGuide = ({ handleClose, handleNext }) => {
    const appTour = useSelector((state) => state.appTour);
    return (
        <Modal show={appTour.showAppGuide} onHide={handleClose}>
            <Modal.Header closeButton className={styles.ModelHeader}></Modal.Header>
            <Modal.Body>
                <h3 className={styles.Heading}>Application Guide</h3>
                <p className='text-center'>Let us guide you through a quick tour.</p>
                <img src={image} alt="Guide" style={{ width: '100%' }} />
            </Modal.Body>
            <Modal.Footer className={styles.ModelFooter}>
                <Button onClick={handleClose} className={styles.BackButton}>
                    Skip
                </Button>
                <Button onClick={handleNext} className={styles.NextButton}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ApplicationGuide;
