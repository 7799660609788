import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/style.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import reportWebVitals from './reportWebVitals';

import AxiosConfigSlice, { setEnvURLAndMode } from './services/AxiosConfigSlice';
import { configureStore } from '@reduxjs/toolkit';
import sidebarSlice from './slicers/sidebarSlice';
import { Provider } from 'react-redux';
import FilterSlice from './slicers/FilterSlice';
import SortingSlice from './slicers/SortingSlice';
import PatientSlice from './slicers/PatientSlice';
import MainSlice from './slicers/MainSlice';
import PerinHealthGridSlice from './components/PerinHealthGridSlice';
import UserSlice from './slicers/UserSlice';
import ContentManagementSlicer from './views/content-management/ContentManagementSlicer';
import AddEditContentSlice from './views/content-management/add-edit-content/AddEditContentSlice';
import QuestionnaireManagementSlicer from './views/questionnaire-management/QuestionnaireManagementSlicer';
import AddQuestionnaireSlice from './views/questionnaire-management/add-edit-questionnaire/AddQuestionnaireSlice';
import UserRoleSlice from './slicers/UserRoleSlice';
import AddProgramSlice from './views/program-management/AddProgramSlice';
import ModalitiesSlice from './views/program-management/ModalitiesSlice';
import PatientEnrollmentSlice from './views/patient-enrollment/PatientEnrollmentSlice';
import PatientModalitiesSlice from './views/patient-enrollment/PatientModalitiesSlice';
import OrganizationManagementSlicer from './views/organization-management/OrganizationManagementSlicer';
import ForgotPasswordSlice from './views/forgot-password/ForgotPasswordSlice';
import NewOrganizationSlicer from './views/new-organization/NewOrganizationSlicer';
import PerinHealthModelSlice from './components/PerinHealthModelSlice';
import RoleManagementSlicer from './views/role-management/RoleManagementSlicer';
import AddEditRoleSlice from './views/role-management/add-edit-role/AddEditRoleSlice';
import PerinHealthAlertSlice from './components/PerinHealthAlertSlice';
import CardSlice from './views/overiview-dashboard/CardSlice';
import RegistrationSlice from './components/AccountManagement/RegistrationSlice';
import PatientDetailFilterSlice from './views/patient/Slices/PatientDetailFilterSlice';
import PatientDetailNotesSlice from './views/patient/Slices/PatientDetailNotesSlice';
import HubSlice from './views/hub-management/HubSlice';
import AppointmentSlicer from './views/appointment/AppointmentSlicer';
import PatientDetailSlice from './views/patient/Slices/PatientDetailSlice';
import TimeOutAlertSlice from './slicers/TimeOutAlertSlice';
import EnrollmentListSlicer from './views/patient-enrollment/EnrollmentListSlicer';
import PatientSubmittedReportsSlice from './views/patient-submitted-reports/PatientSubmittedReportsSlice';
import TasksSlice from './views/tasks/TasksSlice'
import SetupListsSlice from './views/program-management/SetupListsSlice';
import SavedModalitiesSlicer from './views/patient-enrollment/SavedModalitiesSlicer';
import CallContainerSlice from './slicers/CallContainerSlice';
import AppointmentDetailSlicer from './views/appointment/AppointmentDetailSlicer';
import CallNotificationSlice from './components/Notifications/CallNotificationSlice';

//Imports related to keeping rootReducer in session
import storage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import PatientMessageSlicer from './views/patient-message/PatientMessageSlicer';
import CareTeamMessageSlicer from './views/care-team-message/CareTeamMessageSlicer';
import VideoAndMessageSlice from './components/VideoAndMessageSlice';
import FilterChatSlice from './slicers/FilterChatSlice';
import VitalSlice from './views/patient/PatientViews/VitalSlice';
import AppTourSlice from './views/app-tour/AppTourSlice';
import NotificationSlice from './slicers/NotificationSlice';



const env = process.env.REACT_APP_ENVIRONMENT_STATUS;
let envURL;

switch (env) {
  case 'local':
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_LOCAL_MODE;
    break;
  case 'test':
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_TEST_MODE;
    break;
  case 'dev':
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE;
    break;
  case 'sandbox':
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE;
    break;
  case 'prod':
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_PROD_MODE;
    break;
  default:
    envURL = process.env.REACT_APP_BACKEND_DOMAIN_LOCAL_MODE;
}


const rootReducer = combineReducers({
  axiosConfig: AxiosConfigSlice,
  main: MainSlice,
  user: UserRoleSlice,
  sidebar: sidebarSlice,
  filters: FilterSlice,
  sorting: SortingSlice,
  patient: PatientSlice,
  perinHealthGrid: PerinHealthGridSlice,
  contentManagement: ContentManagementSlicer,
  addEditContent: AddEditContentSlice,
  addQuestionnaire: AddQuestionnaireSlice,
  questionnaireManagement: QuestionnaireManagementSlicer,
  userInfo: UserSlice,
  addProgram: AddProgramSlice,
  modalities: ModalitiesSlice,
  organizationManagement: OrganizationManagementSlicer,
  enrollPatientProgram: PatientEnrollmentSlice,
  patientEnrollmentModalities: PatientModalitiesSlice,
  forgotPassword: ForgotPasswordSlice,
  newOrganization: NewOrganizationSlicer,
  perinHealthModal: PerinHealthModelSlice,
  patientMessage: PatientMessageSlicer,
  roleManagement: RoleManagementSlicer,
  addRoleMain: AddEditRoleSlice,
  perinHealthAlert: PerinHealthAlertSlice,
  dashboardCardFilter: CardSlice,
  registration: RegistrationSlice,
  patientDetailFilter: PatientDetailFilterSlice,
  patientDetailNotes: PatientDetailNotesSlice,
  hubManagementSlice: HubSlice,
  careTeam: CareTeamMessageSlicer,
  videoAndMessage: VideoAndMessageSlice,
  appointment: AppointmentSlicer,
  filtersChat: FilterChatSlice,
  patientDetailsSlice: PatientDetailSlice,
  timeOutAlert: TimeOutAlertSlice,
  enrollmentLists: EnrollmentListSlicer,
  vital: VitalSlice,
  PatientSubmittedReports: PatientSubmittedReportsSlice,
  taskListPanel: TasksSlice,
  programTemplateLists: SetupListsSlice,
  appTour:AppTourSlice,
  savedPatientModalities: SavedModalitiesSlicer,
  callContainer: CallContainerSlice,
  notificationsData: NotificationSlice,
  appointmentDetail: AppointmentDetailSlicer,
  callNotification: CallNotificationSlice
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['axiosConfig.envURL'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const appStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/REGISTER'],
      },
    }),
});

library.add(fab, fas, far);

appStore.dispatch(setEnvURLAndMode({
  envURL: envURL,
  envMode: env,
}));

const persistor = persistStore(appStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={appStore}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
