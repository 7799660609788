import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  DevicesNeedsServicingIcon, NewMedicalReportIcon, NewMessagesIcon,
  NewPatientCardIcon, PatientAlarmIcon, PatientDischargeIcon, PatientsUnderMonitoringIcon,
  ScheduleAppointmentIcon, BatteryOkIcon, HighSeverityIcon,
  LowSeverityIcon, MediumSeverityIcon, TenToTwentyBatteryIcon, OverviewDashboardBell,
  OverviewDashboardLowQSignal, OverviewDashboardNoReading
} from '../../components/icons/Icons';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import Cards from '../../components/Cards';
import AppointmentsContainer from '../appointment/AppointmentsContainer';
import FilterManagementView from '../../components/FilterManagementView';
import { resetCardFilterState } from './CardSlice';
import { updatePatientInfo, resetPatientState } from '../../slicers/PatientSlice';
import { setMenuItem } from '../patient/Slices/PatientDetailSlice';
import useAxios from '../../services/AxiosConfig';
import managementStyles from '../ManagementPages.module.css';
import styles from './OverviewDashboard.module.css';
import Modal from '../../components/RawReadingsGraphModal';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler,
);

const OverviewDashboard = () => {
  const [patientList, setPatientList] = useState([]);
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [selectedCard, setCard] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [openReports, setOpenReports] = useState([]);
  const [serviceNeeded, setServiceNeeded] = useState([]);
  const [messages, setMessages] = useState([]);
  const [patientReadings, setPatientReadings] = useState({});
  const [batteryLevels, setBatteryLevels] = useState({});
  const [chartData, setChartData] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [emptyFilter, setEmptyFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enrollmentData, setEnrollmentData] = useState([])

  const patientReadingsRef = useRef(patientReadings);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const userAccountId = useSelector((state) => state.userInfo.id);
  const filterCard = useSelector((state) => state.filters.selectedCard);
  const notifications = useSelector((state) => state.notificationsData.notifications);

  const httpService = useAxios(axiosConfig.envURL);

  const newPatientFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const currentDate = new Date();
      const filteredPatients = patientList.filter((patient) => {
        const createdDate = new Date(patient.createdDate);
        const dateDiff = (currentDate - createdDate) / (1000 * 3600 * 24);
        return dateDiff <= 14;
      });
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [patientList]);
  
  const dischargeFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const filteredPatients = patientList.filter((patient) => {
        return patient.dischargeNeededFlg;
      });
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [patientList]);
  
  const appointmentFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const filteredAppointments = appointments.filter(appointment => new Date(appointment.appointmentEndDateTime) > new Date());

      const patientIdsWithAppointments = new Set(filteredAppointments.map(appointment => appointment.patientId));
      const filteredPatients = patientList.filter(patient => patientIdsWithAppointments.has(patient.id));
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [appointments, patientList]);
  
  const unreadMessageFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const patientIdsWithUnreadMessages = new Set(messages.map(message => message.senderId));
      const filteredPatients = patientList.filter(patient => patientIdsWithUnreadMessages.has(patient.id));
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [messages, patientList]);
  
  const activelyEnrolledFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const filteredPatients = patientList.filter((patient) => {
        return patient.activelyEnrolledFlg;
      });
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [patientList]);
  
  const reportFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const patientIdsWithOpenReport = new Set(openReports.map(report => report.id));
      const filteredPatients = patientList.filter(patient => patientIdsWithOpenReport.has(patient.id));
  
      setEmptyFilter(filteredPatients.length === 0);
      setFilteredPatientList(filteredPatients);
    }
  }, [openReports, patientList]);

  const serviceNeededFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {
      const patientIdsWithServiceNeeded = new Set(serviceNeeded);

      // Filter the patients based on the account IDs from the array
      const filteredPatients = patientList.filter(patient => patientIdsWithServiceNeeded.has(patient.id));
  
       setEmptyFilter(filteredPatients.length === 0);
       setFilteredPatientList(filteredPatients);
       
    }
  }, [serviceNeeded, patientList]);

  const patientAlarmsFilter = useCallback((newSelectedCard) => {
    if (newSelectedCard === null) {
      setFilteredPatientList([]);
      setEmptyFilter(false);
    } else {

      const filteredNotifications = notifications.filter(notification => 
        notification.accountType === "Staff" &&
        notification.notificationType === "Bell" &&
        notification.readStatus === false &&
        notification.ruleType === "enrollment"
      );

      const patientsWithAlarms = new Set(filteredNotifications.map(notification => notification.linkedAccountId));
      const filteredPatients = patientList.filter(patient => patientsWithAlarms.has(patient.id));
    
       setEmptyFilter(filteredPatients.length === 0);
       setFilteredPatientList(filteredPatients);       
    }
  }, [serviceNeeded, patientList]);
  

  const updatePatientFilterList = useCallback(() =>
    {
      if(filterCard === "New Patients"){
        newPatientFilter("initial");
      }else if (filterCard === "Discharge Patient"){
        dischargeFilter("initial");
      }else if (filterCard === "Patient Alerts"){
        patientAlarmsFilter("initial");
      }else if (filterCard === "Enrolled Patients"){
        activelyEnrolledFilter("initial");
      }else if (filterCard === "Upcoming Appointments"){
        appointmentFilter("initial");
      }else if (filterCard === "New Messages"){
        unreadMessageFilter("initial");
      }else if (filterCard === "Open Medical Report"){
        reportFilter("initial");
      }else if (filterCard === "Devices Need Servicing"){
        serviceNeededFilter("initial");
      }else{
        setFilteredPatientList([]);
      }
    }, [newPatientFilter, activelyEnrolledFilter, appointmentFilter, dischargeFilter, filterCard, reportFilter, unreadMessageFilter])

  useEffect(() => {
    dispatch(resetCardFilterState());
    dispatch(resetPatientState());
    if(filterCard !== null){
      updatePatientFilterList();
    }
  }, [dispatch, filterCard, updatePatientFilterList]);

  useEffect(() => {
    if(filterCard == null){
      setEmptyFilter(false);
      setFilteredPatientList([]);
    }
  }, [filterCard]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          patientResponse,
          appointmentResponse,
          messageResponse,
          //reportResponse,
          enrollmentsResponse,
        ] = await Promise.all([
          httpService.get(`careprogram/patient/list/${userAccountId}`),
          httpService.get(`/appointment?physicianIds=${encodeURIComponent(userAccountId)}`),
          httpService.get(`chime/getAllMessageByUserId?userId=${userAccountId}&recipientType=PATIENT`),
          //httpService.get(`/contact/list/open/nonCriticalReport/${userAccountId}`),
          httpService.get(`/careprograms/patient/enrollments/list/${userAccountId}`),
        ]);
        
        setEnrollmentData(enrollmentsResponse.data.data.filter(detail => detail.enrollment.dischargeDate == null))
  
        const formattedPatients = formatPatients(patientResponse?.data?.data);
        setPatientList(formattedPatients);
  
        const validAppointments = appointmentResponse?.data?.data?.filter(appointment => !appointment.deletedAt);
        setAppointments(validAppointments);
  
        const unreadMessages = messageResponse?.data?.getAllMessage?.flat().filter(message => message.recipientId === userAccountId && message.readStatus === false);
        setMessages(unreadMessages || []);
  
        //setOpenReports(reportResponse.data.data);

        //setServiceNeeded(serviceResponse.data.data)
  
        await Promise.all([
          fetchNonCriticalReport(userAccountId),
          fetchServiceNeeded(userAccountId),
          fetchPatientReadings(patientResponse.data.data),
          fetchBatteryLevels(patientResponse.data.data)
        ]);

        
        await addEnrollmentDataToPatients(patientReadingsRef.current, enrollmentsResponse.data.data.filter(detail => detail.enrollment.dischargeDate == null));
  
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [httpService, userAccountId]);
  

  const addEnrollmentDataToPatients = useCallback(async (patientData, enrollmentData) => {
    // Create a lookup object for enrollment data by patientId and modalityId
    const enrollmentLookup = enrollmentData.reduce((acc, enrollment, index) => {
      enrollment.devices.forEach((device) => {
        const key = `${enrollment.patient.id}-${device.deviceId}-${device.modalityId}`;
        acc[key] = {
          lowerLimit: device.lowerLimit,
          upperLimit: device.upperLimit,
          targetValue: device.targetValue
        };
  
        // Check if the device has rules and if there's an active system rule
        const systemRule = device.rules?.find(rule => rule.ruleType === 'system' && rule.isActive && rule.modalityId === device.modalityId);
        if (systemRule) {
          acc[key].systemTargetLowerValue = systemRule.systemTargetLowerValue;
          acc[key].systemTargetUpperValue = systemRule.systemTargetUpperValue;
        }
      });
      return acc;
    }, {});
  
    // Iterate over patientData and add the enrollment data
    for (const patientId in patientData) {
      patientData[patientId].forEach((device) => {
        const key = `${patientId}-${device.deviceId}-${device.modalityId}`;
        const enrollmentInfo = enrollmentLookup[key];
        if (enrollmentInfo) {
          device.lowerLimit = enrollmentInfo.lowerLimit;
          device.upperLimit = enrollmentInfo.upperLimit;
          device.targetValue = enrollmentInfo.targetValue;
          if (enrollmentInfo.systemTargetLowerValue !== undefined) {
            device.systemTargetLowerValue = enrollmentInfo.systemTargetLowerValue;
          }
          if (enrollmentInfo.systemTargetUpperValue !== undefined) {
            device.systemTargetUpperValue = enrollmentInfo.systemTargetUpperValue;
          }
        }
      });
    }
  
    setPatientReadings(patientData);
    patientReadingsRef.current = patientData;
  }, [patientReadingsRef]);
  

  const fetchPatientReadings = useCallback(async (patients) => {
    try {
      const accountIds = patients.map(patient => patient.id).join(',');
    
      const response = await httpService.get(`/device/readings/latestByIds?accountIds=${accountIds}`);
      const readingsResponses = response.data.data;
    
      const readings = readingsResponses.reduce((acc, patientData) => {
        const patientId = patientData.accountId;
    
        if (patientData.readings && patientData.readings.length > 0) {
          // Group readings by modalityId
          const groupedReadings = patientData.readings.reduce((group, reading) => {
            if (!group[reading.modalityId]) {
              group[reading.modalityId] = [];
            }
            group[reading.modalityId].push(reading);
            return group;
          }, {});
  
          // For each modality, select the reading from deviceId 1 if it exists
          const patientReadings = Object.values(groupedReadings).map(modalityReadings => {
            const deviceOneReading = modalityReadings.find(r => r.deviceId === 1);
            const selectedReading = deviceOneReading || modalityReadings[0];
  
            const matchingNotification = notifications.find(
              notification =>
                notification.linkedAccountId === patientId &&
                notification.modalityId === selectedReading.modalityId
            );
  
            return {
              ...selectedReading,
              hasNotification: !!matchingNotification,
              notificationContent: matchingNotification ? matchingNotification.notificationContent : null
            };
          });
  
          acc[patientId] = patientReadings;
        }
    
        return acc;
      }, {});
    
      // Update state and ref
      setPatientReadings(readings);
      patientReadingsRef.current = readings;
    } catch (error) {
      console.error("Failed to fetch patient readings:", error);
    }
    
  }, [httpService, notifications]);


  const fetchBatteryLevels = useCallback(async (patients) => {
    try {
      const accountIds = patients.map(patient => patient.id).join(',');
      const response = await httpService.get(`/measurements/battery/latestByIds?accountIds=${accountIds}`);
      const batteryLevels = response.data.data;
      const levels = patients.reduce((acc, patient) => {
        const batteryLevel = batteryLevels.find(item => item.accountId === patient.id);
        acc[patient.id] = batteryLevel ? batteryLevel.batteryLevel : null;
        return acc;
      }, {});
      setBatteryLevels(levels);
    } catch (error) {
      console.error("Failed to fetch patient battery levels:", error);
    }
  }, [httpService]);

  const fetchServiceNeeded = useCallback(async (userAccountId) => {
    try {
      const response = await httpService.get(`/notifications/list/serviceNeeded/${userAccountId}`);
      setServiceNeeded(response.data.data)
    } catch (error) {
      console.error("Failed to fetch patient readings:", error);
    }
    
  }, [httpService, notifications]);

  const fetchNonCriticalReport = useCallback(async (userAccountId) => {
    try {
      const response = await httpService.get(`/contact/list/open/nonCriticalReport/${userAccountId}`);
      setOpenReports(response.data.data);
    } catch (error) {
      console.error("Failed to fetch patient readings:", error);
    }
    
  }, [httpService, notifications]);
  

  const data = [
    {
      "id": 0,
      "patient": "John1 Doe",
      "device_status": "7%",
      "patient_severity": "Critical",
      "spo2": 99,
      "hr": 70,
      "rr": 18,
      "temp": 98.6,
      "weight": 70,
      "ausc": "Clear",
      "ecg": "Normal",
      "bp": "120/80",
      "isDummy": true,
    },
    {
      "id": 1,
      "patient": "John Doe",
      "device_status": "7%",
      "patient_severity": "Critical",
      "spo2": 98,
      "hr": 75,
      "rr": 18,
      "temp": 98.6,
      "weight": 70,
      "ausc": "Clear",
      "ecg": "Normal",
      "bp": "120/80",
      "isDummy": true,
    },
    {
      "id": 2,
      "patient": "Jane Smith",
      "device_status": "78%",
      "patient_severity": "Moderate",
      "spo2": 96,
      "hr": 80,
      "rr": 20,
      "temp": 99.2,
      "weight": 65,
      "ausc": "Mild Crackles",
      "ecg": "Abnormal",
      "bp": "130/85",
      "isDummy": true,
    },
    {
      "id": 3,
      "patient": "Alice Johnson",
      "device_status": "14%",
      "patient_severity": "Low",
      "spo2": 99,
      "hr": 70,
      "rr": 16,
      "temp": 98.0,
      "weight": 62,
      "ausc": "Clear",
      "ecg": "Normal",
      "bp": "115/75",
      "isDummy": true,
    },
    {
      "id": 4,
      "patient": "Bob Williams",
      "device_status": "82%",
      "patient_severity": "Critical",
      "spo2": 94,
      "hr": 85,
      "rr": 22,
      "temp": 99.5,
      "weight": 75,
      "ausc": "Crackles",
      "ecg": "Abnormal",
      "bp": "140/90",
      "isDummy": true,
    },
    {
      "id": 5,
      "patient": "Eva Davis",
      "device_status": "9%",
      "patient_severity": "Low",
      "spo2": 97,
      "hr": 72,
      "rr": 18,
      "temp": 98.8,
      "weight": 68,
      "ausc": "Clear",
      "ecg": "Normal",
      "bp": "118/78",
      "isDummy": true,
    },
    {
      "id": 6,
      "patient": "Michael Miller",
      "device_status": "43%",
      "patient_severity": "Moderate",
      "spo2": 95,
      "hr": 78,
      "rr": 20,
      "temp": 99.0,
      "weight": 80,
      "ausc": "Crackles",
      "ecg": "Abnormal",
      "bp": "125/82",
      "isDummy": true,
    },
    {
      "id": 7,
      "patient": "Sophia Wilson",
      "device_status": "43%",
      "patient_severity": "Low",
      "spo2": 98,
      "hr": 68,
      "rr": 16,
      "temp": 98.4,
      "weight": 60,
      "ausc": "Clear",
      "ecg": "Normal",
      "bp": "112/72",
      "isDummy": true,
    },
    {
      "id": 8,
      "patient": "Oliver Brown",
      "device_status": "57%",
      "patient_severity": "Moderate",
      "spo2": 96,
      "hr": 82,
      "rr": 22,
      "temp": 99.3,
      "weight": 72,
      "ausc": "Mild Crackles",
      "ecg": "Abnormal",
      "bp": "135/88",
      "isDummy": true,
    },
  ];

  const criticalLevels = {
    1: "Low",
    2: "Moderate",
    3: "Critical",
  }

  const formatPatients = (patientData) => {
    const userIdSet = new Set();
    let newFormattedPatientList = [];
    if (patientData.length >= 1) {
      newFormattedPatientList = patientData.reduce((acc, user) => {
        if (!userIdSet.has(user.id)) { 
            userIdSet.add(user.id);
            let groupIds = [];
            let subGroupIds = [];
            let teamIds = [];
            let organizationIds = [];
            if (user.groups) {
                user.groups.forEach(group => {
                    if (group.groupId){
                        groupIds.push(group.groupId);
                    } 
                    if (group.subGroupId) {
                        subGroupIds.push(group.subGroupId);
                    } 
                    if (group.teamId ) {
                        teamIds.push(group.teamId);
                    }
                    if (group.organizationId){
                        organizationIds.push(group.organizationId);
                    }
                });
            }

            acc.push({
                ...user,
                organizationIds: organizationIds,
                groupIds: groupIds,
                subGroupIds: subGroupIds,
                teamIds: teamIds,
                patient: user.lastName + ", " + user.firstName,
                patient_severity: user.healthInfo["criticalLevel"] ? criticalLevels[user.healthInfo.criticalLevel] : null
            });

        }
        return acc;
      }, []);
    }
    newFormattedPatientList.push(...data);
    return newFormattedPatientList;
  };

  const iconMapping = {
    'Critical': { icon: <HighSeverityIcon />, text: 'High' },
    'Moderate': { icon: <MediumSeverityIcon />, text: 'Med' },
    'Low': { icon: <LowSeverityIcon />, text: 'Low' },
  };

  const handlePatientSelection = (patient) => {
    dispatch(updatePatientInfo(patient.id));
    dispatch(setMenuItem('Vitals'));
    navigate('/patient-detail');
  };

  const headers = [
    { key: 'patient', label: 'Patient' },
    { key: 'data_status', label: 'Data Status' },
    { key: 'device_status', label: 'Device Status' },
    { key: 'patient_severity', label: 'Patient Severity' },
    { key: 'spo2', label: 'SpO2' },
    { key: 'hr', label: 'HR' },
    { key: 'rr', label: 'RR' },
    { key: 'temp', label: 'TEMP' },
    { key: 'weight', label: 'WEIGHT' },
    { key: 'ausc', label: 'AUSC' },
    { key: 'ecgHrv', label: 'ECG-HRV' },
    { key: 'bp', label: 'BP' },
  ];

  const getMeasurement = useCallback((patientId, modalityId) => {
    const readings = patientReadingsRef.current[patientId];
    if (!readings) return { value: '', completedDate: null, modalityName: '' };

    const reading = readings.find(r => r.modalityId === modalityId);
    
    if(reading) {
      switch(modalityId) {
        case 7:
          return {
            value: Math.random() > 0.5 ? "Clear" : "Crackles", 
            completedDate: reading.completedDate, 
            modalityName: reading.modalityName,
            lowerLimit: reading.lowerLimit,
            upperLimit: reading.upperLimit,
            targetValue: reading.targetValue,
            systemTargetLowerValue: reading.systemTargetLowerValue,
            systemTargetUpperValue: reading.systemTargetUpperValue,
            hasNotification: reading.hasNotification,
            notificationContent: reading.notificationContent
          }
        default: 
          return {
            value: reading.measurement, 
            completedDate: reading.completedDate, 
            modalityName: reading.modalityName, 
            lowerLimit: reading.lowerLimit,
            upperLimit: reading.upperLimit,
            targetValue: reading.targetValue ,
            systemTargetLowerValue: reading.systemTargetLowerValue,
            systemTargetUpperValue: reading.systemTargetUpperValue,
            hasNotification: reading.hasNotification,
            notificationContent: reading.notificationContent
          } 
      }
    } else {
      return { 
        value: '', 
        completedDate: null, 
        modalityName: '', 
        lowerLimit: null, 
        upperLimit: null, 
        targetValue: null,
        systemTargetLowerValue: null,
        systemTargetUpperValue: null
      };
    }
  }, [patientReadingsRef]);

  const getBackgroundColor = (value, lowerLimit = null, upperLimit = null, systemTargetLowerValue = null, systemTargetUpperValue = null) => {
    let color = 'white';
    let isOutOfLimit = false;
    
    if (value !== null && value !== undefined && (lowerLimit !== 0 && upperLimit !==0)) {
      const isOutsideLimit = (limit, comparison) => limit !== null && limit !== 0 && comparison;
  
      if (
        isOutsideLimit(lowerLimit, value < lowerLimit) ||
        isOutsideLimit(upperLimit, value > upperLimit) ||
        isOutsideLimit(systemTargetLowerValue, value < systemTargetLowerValue) ||
        isOutsideLimit(systemTargetUpperValue, value > systemTargetUpperValue)
      ) {
        color = '#EBB6B6'
        isOutOfLimit = true

        return {color, isOutOfLimit}
      }
    }
    
    return {color, isOutOfLimit};
  };
  

  const filterCount = (type) => {
    if (type === "new") {
      return patientList.filter((patient) => {
        const currentDate = new Date();
        const createdDate = new Date(patient.createdDate);
        const dateDiff = (currentDate - createdDate) / (1000 * 3600 * 24);
        return dateDiff <= 14;
      }).length;
    }
  
    if (type === "discharge") {
      return patientList.filter(patient => patient.dischargeNeededFlg).length;
    }
  
    if (type === "appointment") {
      const filteredAppointments = appointments.filter(appointment => new Date(appointment.appointmentEndDateTime) > new Date());
      return filteredAppointments.length;
    }
  
    if (type === "unreadMessage") {
      const uniquePatientIds = new Set();
      messages.forEach(message => {
        if (message.senderId !== userAccountId && !message.readStatus) {
          uniquePatientIds.add(message.senderId);
        }
      });
      return uniquePatientIds.size;
    }
  
    if (type === "activelyEnrolled") {
      return patientList.filter(patient => patient.activelyEnrolledFlg).length;
    }
  
    if (type === "reports") {
      return new Set(openReports.map(report => report.id)).size;
    }

    if(type === "service"){
      return serviceNeeded.length;
    }

    if(type === "alarm"){
      const filteredNotifications = notifications.filter(notification => 
        notification.accountType === "Staff" &&
        notification.notificationType === "Bell" &&
        notification.readStatus === false &&
        notification.ruleType === "enrollment"
      );

      const patientsWithAlarms = new Set(filteredNotifications.map(notification => notification.linkedAccountId));      

      return patientsWithAlarms.size || 0;
    }
  
    return 0;
  };  
  

  const fetchRawReadings = useCallback(async (patientId, completedDate, modalityName, modalityId) => {
    try {
      const response = await httpService.get(`device/readings/raw/${modalityId}`, {
        params: {
          completedDate: completedDate,
          accountId: patientId,
        }
      });

      const processRawData = async (data) => {
        const labels = data.map((_, index) => index);
        const values = data.map(value => parseFloat(value));
    
        setChartData({
          labels,
          datasets: [
            {
              label: modalityName,
              data: values,
              fill: false,
              borderColor: 'rgba(75,192,192,1)',
              tension: 0.1,
              pointRadius: 0
            }
          ]
        });
      };
      
      if (response.data.data.fileType === 'csv') {
        processRawData(response.data.data.fileData);
        setShowChart(true);
        setShowAudio(false);
      } else if (response.data.data.fileType === 'wav') {
        const audioData = response.data.data.fileData;
        const audioArray = new Uint8Array(Object.values(audioData));
        const blob = new Blob([audioArray], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        setShowAudio(true);
        setShowChart(false);
      }
    } catch (error) {
      console.error("Failed to fetch raw readings:", error);
    }
  }, [httpService]);

  const renderTooltip = (iconType, measurement) => {
    if (iconType === 'lowQSignal') {
      return <Tooltip id="tooltip-lowQSignal">Low quality signal for {measurement.modalityName}</Tooltip>;
    }
    if (iconType === 'notification' && measurement.notificationContent) {
      return <Tooltip id="tooltip-notification">{measurement.notificationContent}</Tooltip>;
    }
    if (iconType === 'wrongReading') {
      return <Tooltip id="tooltip-notification">-1 {measurement.modalityName}</Tooltip>;
    }
    return null;
  };

  const gridData = useMemo(() => (filteredPatientList.length || emptyFilter ? filteredPatientList : patientList).map(patient => {
    if (loading) {
      return [];
    }

    if (patient.isDummy) {
      let deviceStatusSort = parseInt(patient.device_status.slice(0, -1),10) / 100;
      return {
        ...patient,
        device_status: (
          <span style={{display:"flex", alignItems:"center", columnGap:"5px"}}>
            {patient.device_status.split('%')[0] > 20 ? 
              <BatteryOkIcon /> :
              <TenToTwentyBatteryIcon />
            }
            {patient.device_status}
          </span>
        ),
        device_status_sort: deviceStatusSort,
        spo2: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 3)}>
            {patient.spo2}
          </span>
        ),
        spo2_sort: patient.spo2,
        hr: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 2)}>
            {patient.hr}
          </span>
        ),
        hr_sort: patient.hr,
        temp: (
          <span style={{ cursor: "pointer" }} onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 1)}>
            {patient.temp}
          </span>
        ),
        temp_sort: patient.temp,
        weight: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 8)}>
            {patient.weight}
          </span>
        ),
        weight_sort: patient.weight,
        ausc: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 7)}>
            {patient.ausc}
          </span>
        ),
        ausc_sort: patient.ausc,
        ecgHrv: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 4)}>
            {patient.ecg}
          </span>
        ),
        ecgHrv_sort: patient.ecg,
        rr: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 4)}>
            {patient.rr}
          </span>
        ),
        rr_sort: patient.rr,
        bp: (
          <span onClick={() => fetchRawReadings(patient.id, patient.completedDate, patient.modalityName, 4)}>
            {patient.bp}
          </span>
        ),
        bp_sort: patient.bp,
      };
    } else {
      const measurements = {
        temp: getMeasurement(patient.id, 1),
        hr: getMeasurement(patient.id, 2),
        spo2: getMeasurement(patient.id, 3),
        systolic: getMeasurement(patient.id, 5),
        rr: getMeasurement(patient.id, 6),
        weight: getMeasurement(patient.id, 8),
        ausc: getMeasurement(patient.id, 7),
        diastolic: getMeasurement(patient.id, 10),
        ecgHrv: getMeasurement(patient.id, 15),
      };
  
      const hasNotificationMatch = Object.values(measurements).find(measurement => measurement.hasNotification);
      const lowQSignalMatch = Object.values(measurements).find(measurement => measurement.sqi);
      const wrongReadingMatch = Object.values(measurements).find(measurement => measurement.value === -1);

      const batteryLevel = batteryLevels[patient.id];

      let dataStatusCount = 0;
      if (hasNotificationMatch) {
        dataStatusCount += 1;
      }
      if (lowQSignalMatch) {
        dataStatusCount += 1;
      }
      if (wrongReadingMatch) {
        dataStatusCount += 1;
      }

      const hasEnrollment = enrollmentData.some(enrollment => enrollment.patient.id === patient.id);

      // Initialize the flag for the row
      let rowHasOutOfLimit = false;

      const spo2Result = getBackgroundColor(
        measurements.spo2.value,
        measurements.spo2.lowerLimit,
        measurements.spo2.upperLimit,
        measurements.spo2.systemTargetLowerValue,
        measurements.spo2.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || spo2Result.isOutOfLimit; 

      const hrResult = getBackgroundColor(
        measurements.hr.value,
        measurements.hr.lowerLimit,
        measurements.hr.upperLimit,
        measurements.hr.systemTargetLowerValue,
        measurements.hr.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || hrResult.isOutOfLimit; 

      const tempResult = getBackgroundColor(
        measurements.temp.value,
        measurements.temp.lowerLimit,
        measurements.temp.upperLimit,
        measurements.temp.systemTargetLowerValue,
        measurements.temp.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || tempResult.isOutOfLimit; 

      const weightResult = getBackgroundColor(
        measurements.weight.value,
        measurements.weight.lowerLimit,
        measurements.weight.upperLimit,
        measurements.weight.systemTargetLowerValue,
        measurements.weight.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || weightResult.isOutOfLimit; 

      const auscResult = getBackgroundColor(
        measurements.ausc.value,
        measurements.ausc.lowerLimit,
        measurements.ausc.upperLimit,
        measurements.ausc.systemTargetLowerValue,
        measurements.ausc.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || auscResult.isOutOfLimit; 

      const ecgHrvResult = getBackgroundColor(
        measurements.ecgHrv.value,
        measurements.ecgHrv.lowerLimit,
        measurements.ecgHrv.upperLimit,
        measurements.ecgHrv.systemTargetLowerValue,
        measurements.ecgHrv.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || ecgHrvResult.isOutOfLimit; 

      const rrResult = getBackgroundColor(
        measurements.rr.value,
        measurements.rr.lowerLimit,
        measurements.rr.upperLimit,
        measurements.rr.systemTargetLowerValue,
        measurements.rr.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || rrResult.isOutOfLimit; 

      const bpResult = getBackgroundColor(
        measurements.systolic.value,
        measurements.systolic.lowerLimit,
        measurements.systolic.upperLimit,
        measurements.systolic.systemTargetLowerValue,
        measurements.systolic.systemTargetUpperValue
      );
      rowHasOutOfLimit = rowHasOutOfLimit || bpResult.isOutOfLimit; 

      return {
        ...patient,
        data_status: (
          <span style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
            {hasNotificationMatch && 
              <OverlayTrigger overlay={renderTooltip('notification', hasNotificationMatch)}>
                <span>
                  <OverviewDashboardBell />
                </span>
              </OverlayTrigger>
            }
            {lowQSignalMatch && 
              <OverlayTrigger overlay={renderTooltip('lowQSignal', lowQSignalMatch)}>
                <span>
                  <OverviewDashboardLowQSignal />
                </span>
              </OverlayTrigger>
            }
            {wrongReadingMatch && 
              <OverlayTrigger overlay={renderTooltip('wrongReading', wrongReadingMatch)}>
                <span>
                  <OverviewDashboardNoReading />
                </span>
              </OverlayTrigger>
            }
          </span>
        ),
        data_status_sort: dataStatusCount,
        device_status: (
          <span style={{ display: "flex", alignItems: "center", columnGap: "5px" }}>
            {(batteryLevel === -1 || !batteryLevel) ?
              "" : batteryLevel > .2 ?
                <BatteryOkIcon /> :
                <TenToTwentyBatteryIcon />
            }
            {batteryLevel !== -1 && batteryLevel && `${Math.floor(batteryLevel * 100)}%`}
          </span>
        ),
        device_status_sort: batteryLevel,
        spo2: (
          <span
            data-bgcolor={spo2Result.color}
            onClick={() => fetchRawReadings(patient.id,  measurements.spo2.completedDate,  measurements.spo2.modalityName, 3)}
            style={{ color: Math.round( measurements.spo2.value) ===  measurements.spo2.targetValue ? 'green' : 'inherit' }}
          >
            { measurements.spo2.value ? Math.round( measurements.spo2.value) : ''}
          </span>
        ),
        spo2_sort:  measurements.spo2.value ? Math.round( measurements.spo2.value) : '',
        hr: (
          <span
            data-bgcolor={hrResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.hr.completedDate, measurements.hr.modalityName, 2)}
            style={{ color: Math.round(measurements.hr.value) === measurements.hr.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.hr.value ? Math.round(measurements.hr.value) : ''}
          </span>
        ),
        hr_sort: measurements.hr.value ? Math.round(measurements.hr.value) : '',
        temp: (
          <span
            data-bgcolor={tempResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.temp.completedDate, measurements.temp.modalityName, 1)}
            style={{ color: measurements.temp.value === measurements.temp.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.temp.value ? parseFloat(measurements.temp.value).toFixed(1) : ''}
          </span>
        ),
        temp_sort: measurements.temp.value ? parseFloat(measurements.temp.value).toFixed(1) : '',
        weight: (
          <span
            data-bgcolor={weightResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.weight.completedDate, measurements.weight.modalityName, 8)}
            style={{ color: measurements.weight.value === measurements.weight.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.weight.value ? parseFloat(measurements.weight.value).toFixed(1) : ''}
          </span>
        ),
        weight_sort: measurements.weight.value ? parseFloat(measurements.weight.value).toFixed(1) : '',
        ausc: (
          <span
            data-bgcolor={auscResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.ausc.completedDate, measurements.ausc.modalityName, 7)}
            style={{ color: measurements.ausc.value === measurements.ausc.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.ausc.value}
          </span>
        ),
        ausc_sort: measurements.ausc.value,
        ecgHrv: (
          <span
            data-bgcolor={ecgHrvResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.ecgHrv.completedDate, measurements.ecgHrv.modalityName, 4)}
            style={{ color: Math.round(measurements.ecgHrv.value) === measurements.ecgHrv.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.ecgHrv.value ? Math.round(measurements.ecgHrv.value) : ''}
          </span>
        ),
        ecgHrv_sort: measurements.ecgHrv.value ? Math.round(measurements.ecgHrv.value) : '',
        rr: (
          <span
            data-bgcolor={rrResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.rr.completedDate, measurements.rr.modalityName, 4)}
            style={{ color: Math.round(measurements.rr.value) === measurements.rr.targetValue ? 'green' : 'inherit' }}
          >
            {measurements.rr.value ? Math.round(measurements.rr.value) : ''}
          </span>
        ),
        rr_sort: measurements.rr.value ? Math.round(measurements.rr.value) : '',
        bp: (
          <span
            data-bgcolor={bpResult.color}
            onClick={() => fetchRawReadings(patient.id, measurements.systolic.completedDate, measurements.systolic.modalityName, 4)}
            style={{ color: (Math.round(measurements.systolic.value) === measurements.systolic.targetValue && measurements.diastolic.value === measurements.diastolic.targetValue) ? '#B7D7BD' : 'inherit' }}
          >
            {measurements.systolic.value && measurements.diastolic.value ? `${Math.round(measurements.systolic.value)}/${Math.round(measurements.diastolic.value)}` : ''}
          </span>
        ),
        bp_sort: measurements.systolic.value && measurements.diastolic.value ? `${Math.round(measurements.systolic.value)}/${Math.round(measurements.diastolic.value)}` : '',
        outOfLimit_sort: rowHasOutOfLimit ? 1 : 0,
        enrollment_sort: hasEnrollment ? 1 : 0
      };
    }
  }), [filteredPatientList, patientList, getMeasurement, fetchRawReadings, batteryLevels, loading]);


  return (
    <div className="main-content full-width-content">
     {/*  <div className={styles.HeaderContent}>
        <div className={managementStyles.Title} style={{ paddingLeft: "10px" }}>Patient Dashboard</div>
      </div> */}

      <div className={styles.MiddleContent}>
        <div className={styles.CardContent}>
          <div className={styles.CardRow}>
            <Cards icon={<NewPatientCardIcon />} count={filterCount("new")} text="New Patients" onClick={newPatientFilter} selectedCardText={selectedCard} />
            <Cards icon={<PatientDischargeIcon />} count={filterCount("discharge")} text="Discharge Patient" onClick={dischargeFilter} selectedCardText={selectedCard} />
            <Cards icon={<PatientAlarmIcon />} count={filterCount("alarm")} text="Patient Alerts" onClick={patientAlarmsFilter} selectedCardText={selectedCard}/>
            <Cards icon={<ScheduleAppointmentIcon />} count={filterCount("appointment")} text="Upcoming Appointments" onClick={appointmentFilter} selectedCardText={selectedCard} />
          </div>
          <div className={styles.CardRow}>
            <Cards icon={<NewMessagesIcon />} count={filterCount("unreadMessage")} text="New Messages" onClick={unreadMessageFilter} selectedCardText={selectedCard}/>
            <Cards icon={<NewMedicalReportIcon />} count={filterCount("reports")} text="Open Medical Report" onClick={reportFilter} selectedCardText={selectedCard}/>
            <Cards icon={<DevicesNeedsServicingIcon />} count={filterCount("service")} text="Devices Need Servicing" onClick={serviceNeededFilter} selectedCardText={selectedCard} />
            <Cards icon={<PatientsUnderMonitoringIcon />} count={filterCount("activelyEnrolled")} text="Enrolled Patients" onClick={activelyEnrolledFilter} selectedCardText={selectedCard} />
          </div>
        </div>
        <div className="appointment-content">
          <AppointmentsContainer appointments={appointments} />
        </div>
      </div>

      <div className={styles.GridContent}>
        <FilterManagementView
          isOrganization
          isGroup
          isSubGroup
          isTeam
          showAddButton={false}
          showDeleteButton={false}
          cardFilter
        />
        <PerinHealthGrid
          dataList={gridData}
          headers={headers}
          iconMapping={iconMapping}
          accounts={true}
          showHeaderFilter
          showProfilePicture="patient"
          gridHeight="52vh"
          rowValueAction="patient"
          onRowValueClick={handlePatientSelection}
        />
      </div>

      <Modal show={showChart} onClose={() => setShowChart(false)} title="Graph" subtitle="Duration: 30 seconds" isAudio={false}>
        {chartData && (
          <div style={{ width: '100%', height: 'calc(100% - 50px)', padding: '20px' }}>
            <Line 
            data={chartData} 
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    drawTicks: false,
                    drawBorder: false,
                  },
                  ticks: {
                    display: false,
                    maxTicksLimit: 50,
                  }
                  
                },
                y: {
                  display: true,
                  grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    drawTicks: false,
                    drawBorder: false,
                  },
                  ticks: {
                    display: false
                  }
                },
              },
            }}
          />
          </div>
        )}
      </Modal>

      <Modal show={showAudio} onClose={() => setShowAudio(false)} title="Audio" subtitle="Duration: 30 seconds" isAudio={true}>
        {audioUrl && (
          <div className={styles.audioContainer}>
            <audio controls>
              <source src={audioUrl} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </Modal>
    </div>
  );
};
export default OverviewDashboard;
