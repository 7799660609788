import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showWelcome: false,
    showAppGuide: false,
    showAppTour: false,
    currentStepLabel :'',
    currentStepIndex :0,
    sidebarRef:{},
    itemRect:{},
    navigateToItem:false,
};

const AppTour = createSlice({
    name: 'appTour',
    initialState,
    reducers: {
        resetAppTourState: (state) => {
            Object.assign(state, initialState);
        },
        setFieldValue: (state, action) => {
            const { name, value } = action.payload;
            state[name] = value;
        },
    },
});

export const {
    resetAppTourState,
    setFieldValue
} = AppTour.actions;

export default AppTour.reducer;
