import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ManageRolePermissions from './ManageRolePermissions';
import styles from '../../ManagementPages.module.css';
import '../add-edit-role/RoleManagement.css';
import useAxios from '../../../services/AxiosConfig';
import { LeftArrowIcon } from '../../../components/icons/Icons';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const AddRoleMain = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const accountId = useSelector((state) => state.userInfo.id);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const organizations = useSelector((state) => state.userInfo.organizations);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const [updatingRole, setUpdatingRole] = useState(location.state && location.state.editRoleData);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedRoleType, setSelectedRoleType] = useState(0);
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [roleTypes, setRoleTypes] = useState([]);

    useEffect(() => {
        if (updatingRole) {
            fetchRoleData(location.state.editRoleData.id);
        }
    }, [updatingRole, location.state]);

    const fetchRoleData = async (roleId) => {
        try {
            const response = await httpService.get(`role/${roleId}`);
            const { name, description, category, organizationId, permissions } = response.data.data; // Access data using response.data.data
            console.log('Category:', category); // Log the category
            setRoleName(name || '');
            setRoleDescription(description || '');
            function getRoleId(category) {
                switch (category) {
                    case 'Admin':
                        return 1;
                    case 'Staff':
                        return 2;
                    case 'Patient':
                        return 3;
                    case 'Doctor':
                        return 4;
                    case 'Nurse':
                        return 5;
                    default:
                        return null; // or a default value if there's no match
                }
            }

            setSelectedRoleType(getRoleId(category));            
            setSelectedOrganization(organizationId || '');
            setSelectedPermissions(Array.isArray(permissions) ? permissions.map(p => p.permissionId) : []);
        } catch (error) {
            console.error('Error fetching role data:', error);
            setErrorMessage('Failed to fetch role data');
        }
    };

    const onSaveHandler = () => {
        if (!selectedRoleType || !roleName.trim() || (isAdmin && !selectedOrganization)) {
            setErrorMessage('Please fill out all required fields.');
            return;
        }

        if (!isAdmin && !organizations[0]){
            setErrorMessage('Error setting user organizations.');
            return;
        }

        const roleTypeNames = {
            1: 'Admin',
            2: 'Staff',
            3: 'Patient',
            4: 'Doctor',
            5: 'Nurse'
        };

        const roleData = {
            organizationId: isAdmin ? selectedOrganization : organizations[0].id,
            name: roleName,
            description: roleDescription,
            category: roleTypeNames[selectedRoleType] || 'Unknown', // Default to 'Unknown' if no match is found
            createdBy: {
                accountId: accountId
            },
            permissions: selectedPermissions.map(permission => ({ permissionId: permission }))
        };

        if (updatingRole) {
            updateRole(roleData, location.state.editRoleData.id);
        } else {
            createRole(roleData);
        }
    };

    const createRole = async (roleData) => {
        try {
            const response = await httpService.post('role', roleData);
            console.log('Role created successfully:', response.data);
            dispatch(
                showAlert({
                    header: "Success",
                    message: "Role created successfully",
                    type: 'success',
                })
            );
            navigate('/role-management');
        } catch (error) {
            console.error('Error creating role:', error);
            setErrorMessage('Failed to create role');
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Failed to create role`,
                    type: 'danger'
                })
            );
        }
    };

    const updateRole = async (roleData, roleId) => {
        try {
            const response = await httpService.put(`role/${roleId}`, roleData);
            console.log('Role updated successfully:', response.data);
            dispatch(
                showAlert({
                    header: "Success",
                    message: "Role updated successfully",
                    type: 'success',
                })
            );
            navigate('/role-management');
        } catch (error) {
            console.error('Error updating role:', error);
            setErrorMessage('Failed to update role');
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Failed to update role`,
                    type: 'danger'
                })
            );
        }
    };

    const roleTypesSample = [
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Staff' },
        { id: 3, name: 'Patient' },
        { id: 4, name: 'Doctor' },
        { id: 5, name: 'Nurse' }
    ];

    useEffect(() => {
        setRoleTypes(roleTypesSample);
    }, []);

    const getRoleTypeName = (roleTypeId) => {
        const role = roleTypesSample.find(role => role.id === roleTypeId);
        return role ? role.name.toLowerCase() : '';
    };

    return (
        <div className={styles.MainContent}>
            <div className={styles.ReturnToManagement} onClick={() => navigate('/role-management')}>
                <LeftArrowIcon />
                <span>Back to Role Management</span>
            </div>
            <div className={styles.MiddleContentAdd} style={{ justifyContent: "start" }}>
                <div className={styles.ContentTitle}>{updatingRole ? "Update Role" : "Add Role"}</div>
                <div className="role-inputs">
                    <span hidden={errorMessage === ""} style={{ color: "red", fontSize: "12px" }}>{errorMessage}</span>
                    {isAdmin && (
                        <select
                            className="role-input"
                            value={selectedOrganization}
                            onChange={(e) => setSelectedOrganization(e.target.value)}
                            style={{ width: "300px" }}
                            disabled={updatingRole}
                        >
                            <option value="">Select an Organization</option>
                            {organizations.map((organization) => (
                                <option key={organization.id} value={organization.id}>
                                    {organization.name}
                                </option>
                            ))}
                        </select>
                    )}
                    <select
                        className="role-input"
                        disabled={updatingRole}
                        style={{ width: "300px" }}
                        value={selectedRoleType}
                        onChange={(e) => setSelectedRoleType(Number(e.target.value))}
                    >
                        {selectedRoleType === 0 && <option value={0}>Select a Role Type</option>}
                        {roleTypes.map((roleType) => (
                            <option key={roleType.id} value={roleType.id}>{roleType.name}</option>
                        ))}
                    </select>
                    {selectedRoleType !== 0 && (
                        <>
                            <input
                                type="text"
                                className="role-input"
                                style={{ width: "300px" }}
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                                placeholder="Enter role name"
                            />
                            <textarea
                                className="role-input"
                                style={{ width: "300px", height: "100px" }}
                                value={roleDescription}
                                onChange={(e) => setRoleDescription(e.target.value)}
                                placeholder="Enter role description"
                            />
                            <div style={{ paddingBottom: "px" }}>
                                <ManageRolePermissions
                                    roleType={getRoleTypeName(selectedRoleType)}
                                    initialPermissions={selectedPermissions}
                                    updatePermissions={setSelectedPermissions}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div style={{ display: "flex", paddingBottom: "10px", gap: "10px" }}>
                    <button className={`${styles.CreateButton} ${!selectedRoleType || !roleName.trim() || (isAdmin && !selectedOrganization) ? 'localDisabledButton' : ''}`}
                        onClick={onSaveHandler}
                        disabled={!selectedRoleType || !roleName.trim() || (isAdmin && !selectedOrganization)}>
                        {updatingRole ? "Update" : "Add"}
                    </button>
                    <button className={styles.CancelButton} onClick={() => navigate('/role-management')}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddRoleMain;
