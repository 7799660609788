import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    devices: [],
    questionnaires: [],
    programs: [],
    content: [],
    patients: [],
    previousEnrollments: []
};

const patientEnrollmentSlice = createSlice({
    name: "enrollmentLists",
    initialState,
    reducers: {
        resetListState: (state) => {
            return initialState;
        },
        setFormValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        
    }
});

export const {
    resetListState,
    setFormValue,
  } = patientEnrollmentSlice.actions;
  
  export default patientEnrollmentSlice.reducer;