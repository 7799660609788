import { createSlice } from '@reduxjs/toolkit';


const date = new Date();
const today = new Date(date);
today.setDate(today.getDate() + 1);

// vitals - month
let startDate = new Date(today);
startDate.setDate(today.getDate() - 30);

const endDate = today.toISOString().split('T')[0];
startDate = startDate.toISOString().split('T')[0];

// questionnaire - week
let qStartDate = new Date(today);
qStartDate.setDate(today.getDate() - 7);
qStartDate = qStartDate.toISOString().split('T')[0];

const initialState = {
  searchText: '',
  dateOrder: 'descending',
  timePeriod: 'Month',
  noteType: '',
  program: '',
  questionnaireFilter: 'timeline',
  startDate: startDate,
  endDate: endDate,
  questionnaireStartDate: qStartDate,
  questionnaireEndDate: endDate,
  questionnaireTimePeriod: 'Week',
  chartOptions: [
    { label: 'Heart Rate', value: 'heartRate', showing: false },
    { label: 'Oxygen Saturation', value: 'oxygenSaturation', showing: false },
    { label: 'ECG', value: 'ECG', showing: false },
    { label: 'ECG HRV', value: 'ECGHRV', showing: false },
    { label: 'Blood Pressure', value: 'bloodPressure', showing: false },
    { label: 'Temperature', value: 'temperature', showing: false },
    { label: 'Respiratory Rate', value: 'respiratoryRate', showing: false },
    { label: 'Weight', value: 'weight', showing: false },
    { label: 'Auscultation', value: 'AUSC', showing: false },
    { label: 'Steps', value: 'steps', showing: false },
    { label: 'Glucose Level', value: 'glucoseLevel', showing: false }
  ],
  vitalsExpanded: false,
  selectedCharts: [], // We'll initialize this in the reducer,
  reportType: 'all'
};

const patientDetailFilterSlice = createSlice({
  name: 'patientDetailFilter',
  initialState,
  reducers: {
    resetFilterState: (state) => {
      Object.assign(state, initialState);
    },
    setFilterItem: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },

    toggleVitalsExpanded(state) {
      state.vitalsExpanded = !state.vitalsExpanded;
    },
    toggleShowingTrue(state, action) {
      const value = action.payload;
      const isItemAlreadySelected = state.selectedCharts.some(item => item === value);
      if (!isItemAlreadySelected) {
        state.selectedCharts = [...state.selectedCharts, value]
      }
      state.chartOptions = state.chartOptions.map(option => {
          if (option.value === value) {
              return { ...option, showing: true };
          }
          return option;
      });
    },
    setSelectedCharts(state, action) {
      state.selectedCharts = action.payload;
    }
  },
});

export const {
  resetFilterState,
  setFilterItem,
  toggleVitalsExpanded,
  setSelectedCharts,
  toggleShowingTrue,
} = patientDetailFilterSlice.actions;

export default patientDetailFilterSlice.reducer;
