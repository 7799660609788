import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  name: "",
  id: 0,
  audioOnly: false,
  meetingId: "",
};

const CallNotificationSlice = createSlice({
  name: 'callNotifcation',
  initialState,
  reducers: {
    showCallNotification: (state, action) => {
      const { name, id, audioOnly, meetingId} = action.payload;
      return {
        show: true,
        name: name,
        id: id,
        audioOnly: audioOnly,
        meetingId: meetingId
      };
    },
    closeCallNotification: (state) => {
      return {
        ...state,
        show: false,
      };
    },
  },
});

export const { showCallNotification, closeCallNotification } = CallNotificationSlice.actions;
export default CallNotificationSlice.reducer;
