import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { setDataList, setSelectAll, setSelectedItem, setSelectedRows } from '../../components/PerinHealthGridSlice';
import styles from './ContentManagement.module.css';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import { setReloadGrid } from './ContentManagementSlicer';
import FilterManagementView from '../../components/FilterManagementView';
import { resetAddEditContent, setFieldValue, toggleUpdateMode } from './add-edit-content/AddEditContentSlice';
import { resetFilterState } from '../../slicers/FilterSlice';
import { closeModal } from '../../components/PerinHealthModelSlice';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const ContentManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useSelector((state) => state.userInfo.id);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const contentManagementState = useSelector((state) => state.contentManagement);
    const reloadGrid = contentManagementState.reloadGrid;
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);

    const headers = [
        { key: 'id', label: "ID"},
        { key: 'name', label: "Name",},
        { key: 'type', label: "Type",},
        { key: 'groupName', label: "Group",},
        { key: 'date_created', label: 'Date Created',}];


    useEffect(() => {
        if (reloadGrid) {
            getContentById()
        }
    }, [reloadGrid])

    const getContentById = () => {
        dispatch(resetFilterState());
        httpService.get('content/list/' + userId)
            .then((responseData) => {
                dispatch(setDataList([]));
                dispatch(setReloadGrid(false));
                if (Array.isArray(responseData.data.data)) {
                    const contentFormatted = responseData.data.data.map((item)=>(
                        {...item, type:item.file.ContentType}
                    ))
                    dispatch(setDataList(contentFormatted));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setDataList([]));
            });
    }

    const onRowClickHandler = (rowItem) => {
        console.log("rowItem", rowItem);
        dispatch(resetAddEditContent());
        dispatch(setSelectedItem(rowItem));
        dispatch(setFieldValue(rowItem));
        dispatch(toggleUpdateMode(true));
        navigate('/add-edit-content');
    }
    const onDeleteHandler = () => {
        const deletePromises = perinHealthGrid.selectedRows.map((selectedItem) => {
            const itemId = selectedItem.id;
            console.log(`Deleting row with ID ${itemId}`);
            return httpService.delete(`content/${itemId}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                console.log('Successfully deleted selected rows:', responses);
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                dispatch(setReloadGrid(true));
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully deleted content`,
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Error deleting content`,
                        type: 'danger'
                    })
                );
            });
    }

    const onAddContentHandler = () => {
        dispatch(resetAddEditContent());
        navigate('/add-edit-content');
    }

    return (
        <div className={managementStyles.MainContent}>
           {/*  <div className={managementStyles.Title} >
                <span className={styles.label}>Content Management</span>
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={false}
                    isTeam={false}
                    addButtonLabel='Add Content'
                    modelHeader = "Delete Content?"
                    onAddHandler={onAddContentHandler}
                    onDeleteHandler={onDeleteHandler} />
                <PerinHealthGrid
                    dataList={perinHealthGrid.dataList}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    showHeaderFilter={true}
                    gridHeight='100%'
                />
            </div>
        </div>
    );
};

export default ContentManagement;
