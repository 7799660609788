import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  description: '',
  file: {},
  groupId: null,
  id: null,
  name: '',
  organizationId: null,
  organizationName: '',
  subGroupId: null,
  teamId: null,
  isUpdateMode: false,
  orgGroups: [],
};

const addEditContentSlice = createSlice({
  name: 'addEditContent',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (state.hasOwnProperty(key)) {
          state[key] = value;
        }
      });
    },
    setFormValue: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    toggleUpdateMode: (state) => {
      state.isUpdateMode = !state.isUpdateMode;
    },
    setOrgGroups: (state, action) => {
      state.orgGroups = action.payload;
    },
    resetAddEditContent: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const { setFieldValue, toggleUpdateMode, setOrgGroups, setFormValue, resetAddEditContent } = addEditContentSlice.actions;

export default addEditContentSlice.reducer;
