import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { setDataList, setSelectAll, setSelectedItem, setSelectedRows } from '../../components/PerinHealthGridSlice';
import styles from './QuestionnaireManagement.module.css';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import { addQuestionToPreview, clearQuestion, resetAddQuestionnaire, setFieldValue, setIsUpdateMode } from './add-edit-questionnaire/AddQuestionnaireSlice';
import { resetFilterState } from '../../slicers/FilterSlice';
import { closeModal } from '../../components/PerinHealthModelSlice';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const QuestionnaireManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const filter = useSelector((state) => state.filter);
    const addQuestionnaireState = useSelector((state) => state.addQuestionnaire);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    let selectedRows = perinHealthGrid.selectedRows;
    const userId = useSelector((state) => state.userInfo.id);

    const headers = [
        { key: 'id', label: "ID" },
        { key: 'name', label: "Name" },
        { key: 'type', label: "Type" },
        { key: 'patientType', label: "Patient Type" },
        { key: 'groupName', label: "Group" },
    ];

    useEffect(() => {
        getQuestionnaireById();
    }, []);

    const getQuestionnaireById = () => {
        dispatch(resetFilterState());
        httpService.get('/questionnaire/list/' + userId)
            .then((responseData) => {
                dispatch(setDataList([]));
                if (Array.isArray(responseData.data.data)) {
                    const updatedDataList = responseData.data.data.map((item, index) => {
                        if (!item.id) {
                            return { ...item, id: index };
                        }
                        return item;
                    }).filter(item => !item.deletedAt);

                    dispatch(setDataList(updatedDataList));
                    // dispatch(setDataList(responseData.data.data));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onRowClickHandler = (rowItem) => {
        console.log(`row data ${rowItem}`);
        dispatch(resetAddQuestionnaire());
        dispatch(setSelectedItem(rowItem));

        const updatedQuestions = rowItem.questions.map((question, questionIndex) => {
            const updatedPossibleAnswers = question.possibleAnswers.map((possibleAnswer, answerIndex) => ({
                ...possibleAnswer,
                answerId: answerIndex + 1,
                text: `Answer ${answerIndex + 1}`
            }));
            return {
                ...question,
                questionId: questionIndex + 1,
                possibleAnswers: updatedPossibleAnswers
            };
        });

        const updatedRowItem = {
            ...rowItem,
            previewQuestions: updatedQuestions,
        };
        dispatch(setFieldValue(updatedRowItem));
       // dispatch(addQuestionToPreview(updatedRowItem.questions));
        dispatch(clearQuestion());
        dispatch(setIsUpdateMode(true));
        navigate('/questionnaire-setup');
    }
    const onDeleteHandler = () => {
        const deletePromises = perinHealthGrid.selectedRows.map((selectedItem) => {
            const { groupId, questionnaireId } = selectedItem;
            console.log(`Deleting row with ID ${questionnaireId}`);
            return httpService.delete(`questionnaire/${groupId}?questionnaireId=${questionnaireId}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                console.log('Successfully deleted selected rows:', responses);
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getQuestionnaireById();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Questionnaire Deleted",
                        message: "",
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "There was an error deleting questionnaires",
                        type: 'danger'
                    })
                );
            });
    }

    const onAddQuestionnaireHandler = () => {
        dispatch(resetAddQuestionnaire());
        navigate('/questionnaire-setup');
    }

    return (
        <div className={managementStyles.MainContent}>
            {/* <div className={managementStyles.Title}>
                <span className={styles.label}>Questionnaire Management</span>
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    addButtonLabel={'Add Questionnaire'}
                    modelHeader = "Delete Questionnaire?"
                    onAddHandler={onAddQuestionnaireHandler}
                    onDeleteHandler={onDeleteHandler} />
                <PerinHealthGrid
                    dataList={perinHealthGrid.dataList}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    showHeaderFilter={true}
                    gridHeight="100%"
                />
            </div>


        </div>
    );
};

export default QuestionnaireManagement;
