const DateUtils = {
  // Converts a date to UTC string
  toUTCString: (date) => {
    return date.toISOString();
  },

  // Converts a UTC date string to a local date object
  fromUTCString: (utcDateStr) => {
    return new Date(utcDateStr);
  },

  // Converts a UTC date string to a local date string
  utcToLocalString: (utcDateStr, options = {}) => {
    const utcDate = new Date(utcDateStr);
    return utcDate.toLocaleString(undefined, options);
  },

  // Converts a local date string to a UTC date string
  localToUTCString: (localDateStr) => {
    const localDate = new Date(localDateStr);
    return localDate.toISOString();
  },

  // Converts a local Date object to a UTC date string
  localDateToUTCString: (localDate) => {
    const offset = localDate.getTimezoneOffset() * 60000;
    const utcDate = new Date(localDate.getTime() + offset);
    return utcDate.toISOString();
  },

  formatDateToTaskMMMDDYYYY: (dateString) => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const dayOfMonth = date.getDate();
    const daySuffix = dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? 'st'
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
        ? 'nd'
        : dayOfMonth % 10 === 3 && dayOfMonth !== 13
          ? 'rd'
          : 'th';

    return formattedDate.replace(dayOfMonth, `${dayOfMonth}${daySuffix}`);
  },

  formatDateToMMDDYYYY: (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  },

  formatDateStringToMMDDYYYY : (dateString) => {
    if (!dateString) {
      return ''; 
    }
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; 
    }
  
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  },

  formatDateToMMDDYYYYHHMM: (dateString) => {
    const date = new Date(dateString); // Convert the date string to a Date object

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${month}/${day}/${year} ${hours}:${minutes}`;
}

};

export default DateUtils;
