import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectAll: false,
  selectedItem: {},
  selectedRows: [],
  dataList: [],
  selectedHeaders: [],
  filter: {
    key: "filter",
    value: "Pending"
  },
  searchText: '',
  taskOptions: [],
  selectedTaskTypes: [],
  startDate: null,
  endDate: null,
  startDateTimestamp: null,
  endDateTimestamp: null,
  reloadTaskList: true,
  pendingTaskTypeCounts: 0,
  completedTaskTypeCounts: 0
};

const taskSlice = createSlice({
  name: 'taskListPanel',
  initialState,
  reducers: {
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setSelectAll: (state, action) => {
      state.selectAll = action.payload;
      state.dataList = state.dataList.map(item => ({ ...item, checked: state.selectAll }));
      state.selectedRows = state.selectAll ? state.dataList.map(item => item.value) : [];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;

      const uniqueTasksMap = new Map();

      action.payload.forEach((task, index) => {
        const uniqueKey = `${task.type}-${task.status}`;
        if (!uniqueTasksMap.has(uniqueKey)) {
            uniqueTasksMap.set(uniqueKey, {
                label: task.type,
                value: index,
                status: task.status
            });
        }
      });

      // Convert the Map values to an array and set it to taskOptions
      state.taskOptions = Array.from(uniqueTasksMap.values());
      state.taskOptions.sort((a, b) => a.label.localeCompare(b.label));
    },

    setSelectedHeaders: (state, action) => {
      state.selectedHeaders = action.payload;
    },
    toggleItem: (state, action) => {
      const itemId = action.payload;
      const item = state.dataList.find(item => item.staffTaskId === itemId);
      if (item) {
        item.checked = !item.checked;
        state.selectedRows = state.dataList.filter(item => item.checked);
        state.selectAll = state.dataList.every(item => item.checked);
      }
    },
    setFilterItem: (state, action) => {
      state.filter = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setDateRange: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setSelectedTaskTypes: (state, action) => {
      state.selectedTaskTypes = action.payload;
    },

    setReloadTaskList: (state, action) => {
      state.reloadTaskList = action.payload;
    },

    setPendingTaskTypeCounts: (state, action) => {
      state.pendingTaskTypeCounts = action.payload;
    },
    setCompletedTaskTypeCounts: (state, action) => {
      state.completedTaskTypeCounts = action.payload;
    },
    resetTaskState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setSelectedRows, setSelectedItem, setSelectAll, setDataList,
  setSelectedHeaders, toggleItem, setFilterItem, setSearchText, setDateRange,
  setSelectedTaskTypes, resetTaskState, setReloadTaskList, setPendingTaskTypeCounts,setCompletedTaskTypeCounts } = taskSlice.actions;
export default taskSlice.reducer;
