import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './Chart.module.css';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, Rectangle, ReferenceLine, Legend, Customized, ReferenceArea } from 'recharts';
import { BloodPressureIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';

const BloodPressureCardView = React.forwardRef(({ className, healthEvaluationData }, ref) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const { startDate, endDate } = useSelector((state) => state.patientDetailFilter);
  const vitals = useSelector((state) => state.vital);
  const [opacity, setOpacity] = useState({});

  const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = containerWidth; // Use full width of the container
        setChartWidth(width);

        const height = 200; // Set a fixed height
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateData = useCallback(() => {
    healthEvaluationData.sort((a, b) => new Date(a.date) - new Date(b.date));
    setData(healthEvaluationData);
    setDisplayData(getLatestMeasurement(healthEvaluationData));
  }, [healthEvaluationData]);

  useEffect(() => {
    updateData();
  }, [updateData, startDate, endDate]);

  const handleLegendClick = (e) => {
    const deviceName = e.value;
    setOpacity((op) => ({ ...op, [deviceName]: op[deviceName] === 1 ? 0 : 1 }));
  };

  const getLatestMeasurement = useCallback((healthEvaluationData) => {
    if (!healthEvaluationData || healthEvaluationData.length === 0) {
      return { measurement: 'No Data', latestDate: 'N/A' };
    }

    const deviceNamesData = healthEvaluationData.filter(entry => entry.systolic !== null);
    const latestMeasurement = deviceNamesData.reduce((latest, entry) => {
      const dateA = new Date(latest.completedDate);
      const dateB = new Date(entry.completedDate);
      return dateB > dateA ? entry : latest;
    }, deviceNamesData[0]);

    return {
      measurement: (latestMeasurement?.systolic !== undefined && latestMeasurement?.diastolic !== undefined)
        ? `${latestMeasurement?.systolic?.toFixed(1)}/${latestMeasurement?.diastolic?.toFixed(1)} mmHG`
        : 'No Data',
      latestDate: 'Latest: ' + (latestMeasurement?.date || 'N/A')
    };

  }, []);

  const deviceNames = Array.from(new Set(healthEvaluationData.map(entry => entry.deviceName)));
  const deviceNamesData = deviceNames.filter(deviceName =>
    healthEvaluationData.some(entry => entry.deviceName === deviceName && (entry.systolic !== null && entry.diastolic !== null))
  );

  const colors = {
    0: '#496CA3',
    1: '#478CAA',
  };

  const CustomizedRectangle = ({ formattedGraphicalItems }) => {
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    // render custom content using points from the graph
    return firstSeries?.props?.points.map((firstSeriesPoint, index) => {
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;
      let fillColor = '';
      let stroke = '';
      if (firstSeriesPoint.payload.systolic > 120 || firstSeriesPoint.payload.diastolic < 80) {
        fillColor = '#EBB6B6';
        stroke = '#333'
      } else {
        fillColor = 'lightgrey';
        stroke = '#999';
      }

      // Ensure each Rectangle has a unique key
      const key = `${firstSeriesPoint.payload.name}-${index}`;

      return (
        <Rectangle
          key={key}
          width={10}
          height={yDifference}
          x={secondSeriesPoint.x - 5}
          y={secondSeriesPoint.y}
          fill={fillColor}
          stroke={stroke}
          strokeWidth={1}
        />
      );
    });
  };



  const PerinTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.PerinTooltip}>
          <p className="label">{`${label}`}</p>
          <p >{`Systolic: ${payload[0].payload.systolic}`}</p>
          <p >{`Diastolic : ${payload[0].payload.diastolic}`}</p>
        </div>
      );
    }

    return null;
  };


  return (
    <div className={`${styles.cardContainer} ${className}`} ref={chartContainerRef}>
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <BloodPressureIcon className={styles.icon} />
          <span className={styles.vitalName}>{vitals.bloodPressureDeviceName}</span>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.measurement}>
            <span className={styles.value}>{displayData.measurement}</span>
          </div>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.latestDate}>{displayData.latestDate}</div>
        </div>
      </div>
      <div className={styles.chartWrapper} ref={ref}>
        <ResponsiveContainer width={chartWidth} height={chartHeight}>
          <LineChart
            width={chartWidth}
            height={chartHeight}
            data={data}
            margin={{ left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" fontSize={12} />
            <YAxis
              domain={[60, 160]}
              ticks={[60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160]}
              tickCount={11}
              fontSize={12}
            />
            <Legend
              verticalAlign="top"
              height={30}
              align={"right"}
              iconType="square"
              wrapperStyle={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
              payload={deviceNamesData.map((deviceName, index) => ({
                value: deviceName,
                type: 'square',
                color: colors[index] || '#000000',
              }))}
              onClick={handleLegendClick}
            />
            <Tooltip contentStyle={{ backgroundColor: 'white' }} content={<PerinTooltip />} />
            <Line type="linear" dataKey="systolic" stroke="transparent" />
            <Line type="linear" dataKey="diastolic" stroke="transparent" />
            <ReferenceLine y={70} stroke="gray" strokeDasharray="3 3" />
            <ReferenceLine y={130} stroke="gray" strokeDasharray="3 3" />
            <ReferenceLine y={150} stroke="gray" strokeDasharray="3 3" />
            <ReferenceArea y1={60} y2={80} fill="rgba(235, 182, 182, 0.12)" stroke="red" strokeDasharray="3 3" />
            <ReferenceArea y1={120} y2={160} fill="rgba(235, 182, 182, 0.12)" stroke="red" strokeDasharray="3 3" />
            <Customized component={CustomizedRectangle} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

export default BloodPressureCardView;
