import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './PatientMessage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import PatientListGroup from './PatientListGroup';
import { setChatList, setNewChat, setRecipient, setReloadMessageList, setSelectedIndex, setChatVisible } from './PatientMessageSlicer';
import useAxios from '../../services/AxiosConfig';
import ChatContainer from '../../components/ChatContainer';
import { setVideoCallVisibility } from '../../components/VideoAndMessageSlice';
import { addUnreadPatientMessageId, clearUnreadPatientMessageIds, removeUnreadPatientMessageId  } from '../../slicers/sidebarSlice';
import { setNewPatientMessageCount } from '../../slicers/sidebarSlice';
import { useWebSocket } from '../../services/webSocket/useWebSocket';
import { setCallInformation, openCallContainer } from '../../slicers/CallContainerSlice';


const PatientMessageList = ({ onItemClick, onCloseClick }) => {
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL, dispatch);
    const userInfoState = useSelector((state) => state.userInfo);
    const userId = userInfoState.id;
    const userFullName = userInfoState.fullName;
    const patientMessage = useSelector((state) => state.patientMessage);
    const isChatVisible = patientMessage.isChatVisible;
    const recipient = patientMessage.recipient;
    const videoAndMessageSlicer = useSelector((state) => state.videoAndMessage);
    const filterChatSlice = useSelector((state) => state.filtersChat);
    const isVideoCallVisible = videoAndMessageSlicer.isVideoCallVisible;

    const [originalDataList, setOriginalDataList] = useState([]);
    const [filteredDataList, setFilteredDataList] = useState([]);
    const { receivedMessage } = useWebSocket();

    const callContainerShowing = useSelector((state) => state.callContainer.callContainerShowing);


    const getAllMessageByUserId = useCallback(async () => {
        try {
            const responseData = await httpService.get(`chime/getAllMessageByUserId?userId=${userId}&recipientType=PATIENT`);
            const messages = responseData.data.getAllMessage || [];
            setOriginalDataList(messages);
            const filteredMessages = filterMessages(messages, filterChatSlice.searchText);
            setFilteredDataList(filteredMessages);  
            dispatch(setReloadMessageList(false));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [userId, filterChatSlice.searchText, dispatch, httpService]);

    const searchMessages = (arr, id) => {
        return arr.flat().filter(message => message.senderId === id || message.recipientId === id);
    };
    

    useEffect(() => {
        getAllMessageByUserId();
    }, [getAllMessageByUserId]);

    //If the recipient changes make sure to set the chat reflects the change.
    useEffect(() => {
        var newRecipient = searchMessages(originalDataList, recipient.id);
        if( newRecipient.length > 0){
            dispatch(setChatList(newRecipient));
            dispatch(setSelectedIndex(newRecipient[0].channelArn));
        }
    }, [recipient, originalDataList]);
    


    useEffect(() => {
        if (patientMessage.selectedIndex !== -1) {
            const chatListMessages = originalDataList.find(array => 
                array.length > 0 && array[0].channelArn === patientMessage.selectedIndex
            );
            dispatch(setChatList(chatListMessages || []));
        }
    }, [patientMessage.selectedIndex, originalDataList, dispatch]);

    useEffect(() => {
        if (receivedMessage || patientMessage.reloadMessageList) {
            getAllMessageByUserId();
        }
    }, [receivedMessage, patientMessage.reloadMessageList]);


    useEffect(() => {
        const filteredMessages = filterMessages(originalDataList, filterChatSlice.searchText);
        setFilteredDataList(filteredMessages);
    }, [filterChatSlice.searchText, originalDataList]);

    const filterMessages = (messages, searchText) => {
        const filterValue = searchText.toLowerCase().trim();
    
        // Ensure messages array is sorted by latestMessage
        const sortMessages = [...messages].sort((a, b) => new Date(b[0].createdTime) - new Date(a[0].createdTime));
    
        if (filterValue === '') {
            return sortMessages;
        } else {
            return sortMessages.filter(message => 
                    message.recipientName?.toLowerCase().includes(filterValue)
                );
        }
    };

    const handleItemClick = (item) => {
        if (callContainerShowing) {
            return;
        }
        dispatch(setSelectedIndex(item[0].channelArn));
        dispatch(setChatList(item));
        dispatch(setNewChat(false));
        const messageFromUser = item.find(message => message.senderId === userId);
        if (messageFromUser) {
            dispatch(setRecipient({ 'id': parseInt(messageFromUser.recipientId), name: messageFromUser.recipientName }));
            dispatch(removeUnreadPatientMessageId(messageFromUser.recipientId));
        } else {
            const messageToUser = item.find(message => message.recipientId === userId);
            if (messageToUser) {
                dispatch(setRecipient({ 'id': parseInt(messageToUser.senderId), name: messageToUser.senderName }));
                dispatch(removeUnreadPatientMessageId(messageToUser.senderId));
            }
        }        
        dispatch(setChatVisible(true));
    };

    const handleChatContainerClose = () => {
        onCloseClick();
    };

    const handleStartCall = (audioOnly) => {
        const callData = {
            title: `Patient Message Call - ${userFullName} & ${recipient.name}`,
            appointmentId: null,
            senderId: userId,
            recipientId: recipient.id,
            senderName: userFullName,
            recipientName: recipient.name
        };
        dispatch(setSelectedIndex(-1));
        dispatch(setChatList([]));
        dispatch(setChatVisible(false));
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData,viewType: "patientMessage"}));
        dispatch(openCallContainer());
    };

    const handleListStartCall = (item, audioOnly) => {
        let id, name;
        const messageFromUser = item.find(message => message.senderId === userId);
        if (messageFromUser) {
            name = messageFromUser.recipientName;
            id = parseInt(messageFromUser.recipientId);
        } else {
            const messageToUser = item.find(message => message.recipientId === userId);
            if (messageToUser) {
                name = messageToUser.senderName;
                id = parseInt(messageToUser.senderId);
            }
        }
        const callData = {
            title: `Patient Message ${audioOnly ? "Audio" : "Video"} Call - ${userFullName} & ${name}`,
            appointmentId: null,
            senderId: userId,
            recipientId: id,
            senderName: userFullName,
            recipientName: name
        };
        dispatch(setSelectedIndex(-1));
        dispatch(setChatList([]));
        dispatch(setChatVisible(false));
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData,viewType: "patientMessage"}));
        dispatch(openCallContainer());
    };

    const deleteChannelMembershipRequest = async (item) => {
        const params = {
            channelArn: item[0].channelArn,
            userId: userId
        };
        try {
            await httpService.post('chime/deleteChannelMembershipRequest', params);

            // Update the state to remove the deleted item
            const updatedOriginalDataList = originalDataList.filter(message => message[0].channelArn !== item[0].channelArn);
            const updatedFilteredDataList = filteredDataList.filter(message => message[0].channelArn !== item[0].channelArn);

            setOriginalDataList(updatedOriginalDataList);
            setFilteredDataList(updatedFilteredDataList);
        } catch (error) {
            console.error('Error deleting channel membership:', error);
        }
    };



    return (
        <div className={styles.PatientMainContainer}>
            <Form className={styles.PatientMessageContainer}>
                <PatientListGroup 
                    items={filteredDataList}
                    onStartCall={handleListStartCall}
                    onDeleteChannelMembership={deleteChannelMembershipRequest}
                    onItemClick={handleItemClick}
                />
            </Form>
            {isChatVisible && !isVideoCallVisible && (
                <Form className={styles.ChatContainer}>
                    <ChatContainer view='patientMessage'
                        handleClose={handleChatContainerClose}
                        onStartVideoCall={() => handleStartCall(false)}
                        onStartAudioCall={() => handleStartCall(true)} />
                </Form>
            )}
        </div>
    );
};

export default PatientMessageList;
