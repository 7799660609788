import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVideoCallVisible: false,
};

const videoAndMessageSlice = createSlice({
  name: 'videoAndMessage',
  initialState,
  reducers: {
    setVideoCallVisibility: (state, action) => {
      state.isVideoCallVisible = action.payload;
    },
  },
});

export const { setVideoCallVisibility } = videoAndMessageSlice.actions;

export default videoAndMessageSlice.reducer;
