import { createSlice } from '@reduxjs/toolkit';

const perinHealthGridSlice = createSlice({
    name: 'perinHealthGrid',
    initialState: {
        selectedItem: {},
        selectedRows: [],
        selectAll: false,
        dataList: [],
        selectedHeaders: [],
    },
    reducers: {
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setDataList: (state, action) => {
            state.dataList = action.payload;
        },
        setSelectedHeaders: (state, action) => {
            state.selectedHeaders = action.payload;
        }
    },
});

export const { setSelectAll, setSelectedItem, setSelectedRows, setDataList, setSelectedHeaders } = perinHealthGridSlice.actions;

export default perinHealthGridSlice.reducer;
