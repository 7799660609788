// src/components/BellNotification.js
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationIcon, NotificationIconUnread } from './icons/Icons';
import { markAllAsRead, setNotifications } from '../slicers/NotificationSlice';
import useAxios from '../services/AxiosConfig';
import image from "../assets/NoBellNotification.png";
import './BellNotification.css';

const BellNotification = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUnread, setShowUnread] = useState(true);
  const notifications = useSelector((state) => state.notificationsData.notifications);
  const hasUnread = useSelector((state) => state.notificationsData.hasUnread);
  const axiosConfig = useSelector(state => state.axiosConfig);
  const userId = useSelector((state) => state.userInfo.id);
  const dispatch = useDispatch();

  const httpService = useAxios(axiosConfig.envURL, dispatch)

  // Ref to track if the initial fetch has been performed
  const initialFetchRef = useRef(false);

  const fetchNotifications = async () => {
    try {
      const response = await httpService.get(`/notifications/list/${userId}`);

      dispatch(setNotifications(response.data.data));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const body = notifications.map(notification => ({
        accountId: notification.accountId,
        createdDate: notification.createdDate
      }));
      await httpService.put(`/notifications/read/${userId}`, body);

      dispatch(markAllAsRead());
      fetchNotifications();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const markNotificationAsRead = async (notification) => {
    try {
      if (!notification.readStatus) {
        const body = [{
            accountId: notification.accountId,
            createdDate: notification.createdDate
          }];
          await httpService.put(`/notifications/read/${userId}`, body);
          fetchNotifications();
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    if (!initialFetchRef.current) {
      fetchNotifications();
      initialFetchRef.current = true;
    }
  });


  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);

    if (!showNotifications) {
      fetchNotifications();
    }
  };

  const toggleShowUnread = () => {
    setShowUnread(!showUnread);
  };

  const filteredNotifications = showUnread
    ? notifications.filter(notification => !notification.readStatus)
    : notifications.filter(notification => notification.readStatus);

  return (
    <div className="bell-notification">
      <div className="icon-container" onClick={toggleNotifications}>
        {hasUnread ? <NotificationIconUnread /> : <NotificationIcon />}
        {/* <NotificationIcon />
        {hasUnread && <span className="unread-dot"></span>} */}
      </div>
      {showNotifications && (
        <div className="notifications-dropdown">
          <div className="notifications-header">
            <div className="notifications-title">Notifications</div>
            <div className="mark-all-as-read" onClick={markAllNotificationsAsRead}>Mark all as read</div>
          </div>
          <div className="toggle-container">
            <label className="switch">
              <input type="checkbox" checked={showUnread} onChange={toggleShowUnread} />
              <span className="slider round"></span>
            </label>
            <span>Show unread notifications</span>
          </div>
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map((notification, index) => (
              <div 
                key={index} 
                className="notification-item"
                onClick={() => markNotificationAsRead(notification)}
                >
                    <div>{notification.notificationContent}</div>
                    <div className="notification-time">{new Date(notification.createdDate).toLocaleString()}</div>
              </div>
            ))
          ) : (
            <div className="no-notifications">
              <img src={image} alt="No notifications" />
              <div>No notification yet!</div>
              <div>Check back later for updates.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BellNotification;
