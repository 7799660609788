import React, { useCallback, useEffect } from 'react';
import styles from './Patient.module.css';
import ChatContainer from '../../components/ChatContainer';
import useAxios from '../../services/AxiosConfig';
import { useSelector, useDispatch } from 'react-redux';
import { setChatList, setNewChat, setSelectedIndex, setChatVisible } from '../patient-message/PatientMessageSlicer';
import { setVideoCallVisibility } from '../../components/VideoAndMessageSlice';
import { DetailsPopupChatIcon, DetailsCollapsePopupIcon, DetailsOpenPopupIcon, DetailsCalendarIcon } from '../../components/icons/Icons';
import PopupCalendarView from './PatientViews/PopupCalendarView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebSocket } from '../../services/webSocket/useWebSocket';

const ChatVideoPopup = ({ closePopup, handleTabSwitch }) => {

    const {popupView, popupOpen} = useSelector((state)=>state.patientDetailsSlice);
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL, dispatch);
    const patientMessage = useSelector((state) => state.patientMessage);
    const { receivedMessage } = useWebSocket();
    const userInfoState = useSelector((state) => state.userInfo);
    const userId = userInfoState.id;


    const handleContainerClose = () => {
        // closing chat container or chat video container
        dispatch(setChatVisible(false));
        dispatch(setSelectedIndex(-1));
        dispatch(setChatList([]));
        dispatch(setNewChat(false));
        dispatch(setVideoCallVisibility(false));
    };

    const tabs = [
        {label:"Chat", value:"messages", icon:<DetailsPopupChatIcon/>},
        {label:"Calendar", value:"calendar", icon:<DetailsCalendarIcon/>}
    ];

    const checkRightBorder = (tab) => {
        if (tab === "messages" && popupView === "calendar") {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (receivedMessage || patientMessage.reloadMessageList) {
            getAllMessageByUserId();  
        }
    }, [receivedMessage, patientMessage.reloadMessageList]);

    const getAllMessageByUserId = useCallback(async () => {
        try {
            const responseData = await httpService.get(`chime/getAllMessageByUserId?userId=${userId}&recipientType=PATIENT`);
            const messages = responseData.data.getAllMessage || [];

            var recipientMessages = searchMessages(messages, patientMessage.recipient.id);
            dispatch(setChatList(recipientMessages));
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [userId, dispatch, httpService, patientMessage]);

    const searchMessages = (arr, id) => {
        return arr.flat().filter(message => message.senderId === id || message.recipientId === id);
    };



    return (
        <div className={styles.PopupContainer} style={popupOpen ? {height:"653px"} : {height:"62px"}}>
            <div className={styles.PopupMain}>
                <div className={styles.NavBar}>
                    <div className={styles.NavTabs}>
                        {tabs.map((tab, index)=>(
                            <div key={index} onClick={()=>handleTabSwitch(tab.value)} className={popupView === tab.value ? styles.selectedNav : ''} style={checkRightBorder(tab.value) ? {borderRight:"0.5px solid var(--Neutral-400, #9D9D9E"} : {}}>
                                {tab.icon}
                                {popupView === tab.value && tab.label}
                            </div>
                        ))}
                    </div>
                    <div style={{display:"flex", alignItems:"center", columnGap:"10px"}}>
                        <div style={{display:"flex", alignItems:"center", height:"43px", cursor:"pointer"}}
                            onClick={()=>handleTabSwitch()}
                        >
                            {popupOpen ? <DetailsCollapsePopupIcon/> : <DetailsOpenPopupIcon/>}
                        </div>
                        <div style={{display:"flex", alignItems:"center", height:"43px", cursor:"pointer"}}
                            onClick={closePopup}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" style={{color:"#828282"}} size="xl"/>
                        </div>
                    </div>
                </div>
                <div style={{height:"585px"}} hidden={!popupOpen}>
                    {popupView === "messages" &&
                        <div style={{width:"96%", height:"100%", margin:"auto"}}>
                            <ChatContainer 
                                view={"patientMessage"} 
                                handleClose={handleContainerClose} 
                                showActions={false} 
                            />
                        </div>
                    }
                    {popupView === "calendar" &&
                        <div style={{width:"100%", height:"100%"}}>
                            <PopupCalendarView />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ChatVideoPopup;
