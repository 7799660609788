import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  callContainerShowing: false,
  audioOnly: false,
  callData: {},
  viewType: '',
  expanded: false,
  minimized: false,
  currentMeetingId: "",
  joiningStaffCall: false
};

const callContainerSlice = createSlice({
  name: 'callContainer',
  initialState,
  reducers: {
    setCallInformation: (state, action) => {
        const {audioOnly, callData, viewType} = action.payload;
        return {
            ...state,
            audioOnly: audioOnly,
            callData: callData,
            viewType: viewType
        }
    },
    setCurrentMeetingId: (state, action) => {
      state.currentMeetingId = action.payload;
    },
    closeCall: (state, action) => {
        Object.assign(state, initialState);
    },
    openCallContainer: (state) => {
        state.callContainerShowing = true
    },
    setExpanded: (state) => {
        if (state.minimized) {
          state.minimized = false
        }
        state.expanded = !state.expanded
    },
    setMinimized: (state) => {
      if (state.expanded) {
        state.expanded = false
      }
      state.minimized = !state.minimized
    },
    setJoiningStaffCall: (state) => {
      state.joiningStaffCall = true
    },
  },
});

export const {
    closeCall,
    setCallInformation,
    openCallContainer,
    setExpanded,
    setMinimized,
    setCurrentMeetingId,
    setJoiningStaffCall
} = callContainerSlice.actions;

export default callContainerSlice.reducer;
