import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './CareTeamMessage.module.css';
import CareTeamMessageList from './CareTeamMessageList';
import { setStaffChatList, setStaffChatVisible, setStaffNewChat, setStaffSelectedIndex, setStaffRecipient } from './CareTeamMessageSlicer';
import FilterChatView from '../../components/FilterChatView';

const CareTeamMainContainer = () => {
    const dispatch = useDispatch();

    const onNewMessageHandler = () => {
        dispatch(setStaffSelectedIndex(-1));
        dispatch(setStaffChatList([]));
        dispatch(setStaffChatVisible(true));
        dispatch(setStaffNewChat(true));
    }

    const handleChatContainerClose = () => {
        dispatch(setStaffChatVisible(false));
        dispatch(setStaffSelectedIndex(-1));
        dispatch(setStaffChatList([]));
        dispatch(setStaffNewChat(false));
        dispatch(setStaffRecipient({ 'id': 0, name: "" }));
    };

    return (
    <div className={styles[`main-content full-width-content`]}>
       {/*  <div className={styles.HeaderContent} >
            <span className={styles.label}>Staff Message</span>
        </div> */}
        <div className={styles.FilterContainer}>
            <FilterChatView
                view='StaffMessage'
                isOrganization={true}
                isGroup={true}
                isSubGroup={true}
                isTeam={true}
                showDeleteButton={false}
                addButtonLabel={'New Message'}
                onAddHandler={onNewMessageHandler}
            />
            <CareTeamMessageList
                onCloseClick={handleChatContainerClose}
                onItemClick={onNewMessageHandler} />
        </div>
    </div>
    );
};

export default CareTeamMainContainer;
