import React, { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row, FloatingLabel } from 'react-bootstrap';
import styles from '../QuestionnaireManagement.module.css';
import deleteIcon from '../../../assets/delete_icon.png';
import { setAnswer } from './AddQuestionnaireSlice';
import { useDispatch } from 'react-redux';
import managementStyles from '../../ManagementPages.module.css';
import { AddQuestionIcon, PlusIcon, MinusIcon } from '../../../components/icons/Icons';

const createAnswer = (index, answerText) => ({
    answerId: index,
    text: `Answer ${index}`,
    answerText: answerText,
    score: 0,
});

const AnswerComponent = ({ questionType, onAddOption, questionId, possibleAnswers, optionsCount }) => {
    const dispatch = useDispatch();

    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        const initialAnswers = [...possibleAnswers];
        setAnswers(initialAnswers);
    }, [possibleAnswers]);

    const handleAddAnswer = () => {

        const newAnswerId = optionsCount + 1;
        const newAnswer = createAnswer(newAnswerId, ''); 
        const newAnswers = [...answers, newAnswer];
        setAnswers(newAnswers);

        const updatedPossibleAnswers = newAnswers.map(answer => ({
            text: answer.text,
            answerText: answer.answerText,
            answerId: answer.answerId,
            score: answer.score
        }));
        dispatch(setAnswer({ questionId, possibleAnswers: updatedPossibleAnswers }));
        onAddOption(questionId, updatedPossibleAnswers);
    };

    const handleDeleteAnswer = (answerId) => {
        const updatedAnswers = answers.filter((answer) => answer.answerId !== answerId);
        setAnswers(updatedAnswers);
        const updatedPossibleAnswers = updatedAnswers.map(answer => ({
            text: answer.text,
            answerText: answer.answerText,
            answerId: answer.answerId,
            score: answer.score
        }));
        dispatch(setAnswer({ questionId, possibleAnswers: updatedPossibleAnswers }));
    };

    const handleIncrement = (answerId) => {
        const updatedAnswers = answers.map((answer) =>
            answer.answerId === answerId ? { ...answer, score: answer.score + 1 } : answer
        );
        setAnswers(updatedAnswers);
        dispatch(setAnswer({ questionId, possibleAnswers: updatedAnswers }));
    };

    const handleDecrement = (answerId) => {
        const updatedAnswers = answers.map((answer) =>
            answer.answerId === answerId ? { ...answer, score: Math.max(answer.score - 1, 0) } : answer
        );
        setAnswers(updatedAnswers);
        dispatch(setAnswer({ questionId, possibleAnswers: updatedAnswers }));
    };

    const handleScoreChange = (e, answerId) => {
        let value = e.target.value;
        if (value === "" || (Number.isInteger(Number(value)) && Number(value) >= 0)) {
            const updatedAnswers = answers.map((answer) =>
                answer.answerId === answerId ? { ...answer, score: Number(value) } : answer
            );
            setAnswers(updatedAnswers);
            dispatch(setAnswer({ questionId, possibleAnswers: updatedAnswers }));
        }
    };

    const onInputChange = (e, answerId) => {
        const { name, value } = e.target;

        const updatedAnswers = answers.map((answer) => {
            if (answer.answerId === answerId) {
                return { ...answer, [name]: value };
            }
            return answer;
        });
        setAnswers(updatedAnswers);
        dispatch(setAnswer({ questionId, possibleAnswers: updatedAnswers }));
    };

    return (
        <div className={styles.MainContainer} hidden={answers.length===0} style={answers.length === 0 ? {display:'none'} : {paddingTop:"0px"}}>
            <Row className={styles.AnswerLabels}>
                <Col xs={8}>
                    <InputGroup>
                        <Form.Label className='mb-0'>Answers</Form.Label>
                    </InputGroup>
                </Col>
                <Col xs={3}>
                    <InputGroup>
                        <Form.Label className='mb-0'>Score</Form.Label>
                    </InputGroup>
                </Col>
            </Row>
            {answers.map((answer) => (
                <Row key={answer.answerId} className="mb-2 align-items-center">
                    <Col xs={8}>
                        <FloatingLabel 
                            controlId="floatingInput"
                            label={`Enter ${answer.text}`}
                        >
                        <Form.Control
                            size='sm'
                            className={`${managementStyles.BorderedComponent}`}
                            type="text"
                            style={{width:"100%"}}
                            name="answerText"
                            value={answer.answerText || ''}
                            placeholder="Enter your answer"
                            onChange={(e) => onInputChange(e, answer.answerId)}
                        />
                        </FloatingLabel>
                    </Col>
                    <Col xs={2}>
                        <InputGroup>
                            <Button variant="outline-secondary" className={`${styles.ScoreButton} me-1`} onClick={() => handleDecrement(answer.answerId)}>
                                <MinusIcon />
                            </Button>
                            <Form.Control 
                                className={styles.FormControlNumberText} 
                                value={answer.score === 0 ? '' : answer.score}
                                onChange={(e) => {
                                    const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
                                    if (!isNaN(value) && value >= 0) {
                                        onInputChange({ target: { name: 'score', value } }, answer.answerId);
                                    }
                                }}
                                type="number"
                                min="0"
                                placeholder="0"
                            />
                            <Button variant="outline-secondary" className={`${styles.ScoreButton} ms-1`} onClick={() => handleIncrement(answer.answerId)}>
                                <PlusIcon />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col xs={1}>
                        <img src={deleteIcon} alt="delete" onClick={() => handleDeleteAnswer(answer.answerId)} />
                    </Col>
                </Row>
            ))}
            {questionType !== 'Yes or No' && (<div className={styles.Footer}>
                <Row>
                    <Col>
                        <div className={styles.AddQuestionLabel} onClick={handleAddAnswer}>
                            <AddQuestionIcon /> Add Answer
                        </div>
                    </Col>
                </Row>
            </div>)}
        </div>
    );
};

export default AnswerComponent;
