import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem, setSelectedRows, setSelectAll } from '../../components/PerinHealthGridSlice';
import { resetFilterState } from '../../slicers/FilterSlice';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import FilterManagementView from '../../components/FilterManagementView';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { closeModal } from '../../components/PerinHealthModelSlice';
import styles from './HubManagement.module.css';
import AssignHubPopup from './components/AssignHubPopup';
import useAxios from '../../services/AxiosConfig';

const HubManagement = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountId = useSelector((state) => state.userInfo.id);
    const { uniqueGroups } = useSelector(state => state.userInfo);
    const [showPopup, setShowPopup] = useState(false);
    
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    let selectedRows = perinHealthGrid.selectedRows;
    
    useEffect(() => {
        dispatch(resetFilterState());
        dispatch(setSelectedRows([]));
        dispatch(setSelectedItem({}));
    }, [dispatch]);

    const [hubData, setHubData] = useState([]);

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const headers = [
        { key: 'groupId', label: "ID" },
        { key: 'OrganizationGroup_Name', label: "Group" },
        { key: 'GroupHub_Quantity', label: "Quantity" },
        { key: 'GroupHub_Genesis', label: "Date Assigned" },
    ];

    const getTotalHubs = () => {
        let total = 0;
        hubData.forEach((item)=> {
            total += item.GroupHub_Quantity;
        });
        return total;
    };

    const totalHubsAssigned = getTotalHubs();

    const getTotalAssignedHubs = () => {
        let totalAssigned = 0;
        hubData.forEach((item) => {
            totalAssigned += item.GroupHub_AssignedCount;  // Ensure that GroupHub_AssignedCount is a numeric value
        });
        return totalAssigned;
    };

    const totalAssignedHubs = getTotalAssignedHubs();

    useEffect(() => {
        getHubs();
    }, []);

    const onRowClickHandler = (group) => {
        navigate('/hub-management-patient', { state: { groupName: group.OrganizationGroup_Name, groupId: group.groupId  } });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    const getHubs = async () => {
        dispatch(resetFilterState());
        httpService.get(`hub/group/${accountId}`)
          .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
              const formattedHubs = responseData.data.data.map(hub => ({
                ...hub,
                GroupHub_Genesis: formatDate(hub.GroupHub_Genesis)
              }));
              setHubData(formattedHubs);
            } else {
              console.error('Invalid data format:');
            }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const onDeleteHandler = () => {
        const deletePromises = selectedRows.map((hub) => {
            const id = hub.id;
            return httpService.delete(`hub/group/${id}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getHubs();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Hubs deleted",
                        message: "",
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "There was an error deleting hubs",
                        type: 'danger'
                    })
                );
            });
    };

    return (
        <div className={managementStyles.MainContent}>
           {/*  <div className={managementStyles.Title}>Hub Management</div> */}
            <div className={managementStyles.MiddleContent}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={styles.HubsNumber}>{totalAssignedHubs}</span>
                    <span className={styles.HubsLabel}>/{totalHubsAssigned}</span>
                    <span className={styles.HubsLabel} style={{ color: "#30584A", paddingLeft: "5px" }}>Hubs Assigned</span>
                </div>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    addButtonLabel='Assign Hub'
                    onAddHandler={() => setShowPopup(true)}
                    onDeleteHandler={onDeleteHandler}
                    modelHeader='Delete Hub?'
                />
                <PerinHealthGrid
                    dataList={hubData}
                    headers={headers}
                    showCheckbox={true}
                    onRowValueClick={(group) => onRowClickHandler(group)}
                    onDeleteRow={onDeleteHandler}
                    gridHeight='100%'
                    rowValueAction={'OrganizationGroup_Name'} 
                />     
                </div>
            {showPopup &&
                <AssignHubPopup
                    onSave={() => {
                        getHubs();
                        setShowPopup(false);
                        dispatch(showAlert({
                            header: "Success",
                            message: `Successfully added hubs`,
                            type: 'success'
                        }));
                    }}
                    onClose={() => setShowPopup(false)}
                    options={uniqueGroups}
                />
            }
        </div>
    );
}

export default HubManagement;