import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import pageStyles from '../ManagementPages.module.css';
import useAxios from '../../services/AxiosConfig';
import { LeftArrowIcon, AddTimeIcon, TrashIcon } from '../../components/icons/Icons';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { Form, FloatingLabel, Row, Col, Button } from 'react-bootstrap';
import styles from './ReferenceCode.module.css';

const AddReferenceCode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const accountId = useSelector((state) => state.userInfo.id);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const updatingCodes = false;

    const [errorMessage, setErrorMessage] = useState('');

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [columns, setColumns] = useState(['']);

    const onSaveHandler = () => {
        return;
    };

    const handleColumnChange = (value, index) => {
        let newCols = [...columns];
        newCols[index] = value;
        setColumns(newCols);
    };

    const handleNewColumn = () => {
        let newCols = [...columns];
        newCols.push([''])
        setColumns(newCols);
    };

    const removeColumn = (index) => {
        let newCols = [...columns];
        newCols.splice(index, 1)
        setColumns(newCols);
    };

    console.log("columns", columns);

    return (
        <div className={pageStyles.MainContent}>
            <div className={pageStyles.ReturnToManagement} onClick={() => navigate('/reference-code')}>
                <LeftArrowIcon />
                <span>Back to Reference Code</span>
            </div>
            <div className={pageStyles.MainCard} style={{ justifyContent: "start" }}>
                <div className={pageStyles.ContentTitle}>{updatingCodes ? "Update Reference Code" : "Add Reference Code"}</div>
                <div className={pageStyles.ScrollContainer} style={{height:"90%"}}>
                    <Form>
                        <Form.Group className="mb-3">
                            <FloatingLabel 
                                controlId="floatingInput"
                                label="Name*"
                                className="mb-3"
                            >
                            <Form.Control
                                name="name"
                                placeholder="Name*"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                className={`${pageStyles.BorderedComponent}`}
                            />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Col xs={8} style={{paddingLeft: "5px"}}>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="radio"
                                        label="Standard"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios1"
                                        onChange={() => setType('standard')}
                                        checked={type === 'standard'}
                                    />
                                </div>
                            </Col>
                            <Col xs={8} style={{padding: "5px 0 0 5px"}}>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="radio"
                                        label="Organization"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios2"
                                        onChange={() => setType('organization')}
                                        checked={type === 'organization'}
                                    />
                                </div>
                            </Col>
                            </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel 
                                controlId="floatingInput"
                                label="Description"
                                className="mb-3"
                            >
                            <Form.Control
                                value={description}
                                as="textarea"
                                rows={2}
                                className={pageStyles.BorderedComponentTextArea}
                                placeholder='Description'
                                onChange={(e) =>
                                    setDescription(e.target.value)
                                }
                            />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group style={{display: "flex", flexDirection:"column"}}>
                            <Form.Label>Columns</Form.Label>
                            {columns.map((col, index)=> (
                                <Form.Group className="d-flex align-items-center">
                                    <FloatingLabel 
                                        controlId="floatingInput"
                                        label={`Column ${index + 1}`}
                                        className="mb-3"
                                    >
                                    <Form.Control
                                        name="name"
                                        placeholder={`Column ${index + 1}`}
                                        value={col}
                                        onChange={(e)=>handleColumnChange(e.target.value, index)}
                                        className={`${pageStyles.BorderedComponent}`}
                                    />
                                    </FloatingLabel>
                                    <Button
                                        className={`${styles.RemoveColButton} mb-3`}
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={()=>removeColumn(index)}
                                        >
                                        <div style={{display:"flex", alignItems:"center", justifyContent: "center", columnGap:"10px"}}>
                                            <TrashIcon />
                                        </div>
                                    </Button>
                                </Form.Group>
                            ))}
                            <Button
                                className={styles.AddColButton}
                                variant="outline-primary"
                                size="sm"
                                onClick={handleNewColumn}
                                >
                                <div style={{display:"flex", alignItems:"center", columnGap:"10px"}}>
                                    <AddTimeIcon />
                                    Add Column
                                </div>
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
                <div style={{ display: "flex", paddingBottom: "10px", gap: "10px" }}>
                    <button 
                        className={pageStyles.CreateButton}
                        onClick={onSaveHandler}
                    >
                        {updatingCodes ? "Update" : "Add"}
                    </button>
                    <button className={pageStyles.CancelButton} onClick={() => navigate('/reference-code')}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddReferenceCode;
