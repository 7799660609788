import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceSetupCard from './DeviceSetupCard';
import { useSelector, useDispatch } from 'react-redux';
import { setSerialNumber } from '../views/patient-enrollment/PatientEnrollmentSlice';
import QuestionnaireSetupCard from './QuestionnaireSetupCard';
import inputStyles from '../views/program-management/ProgramManagement.module.css';
import styles from './ManagementDropdownCard.module.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PerinPatchSetupCard from './PerinPatchSetupCard';

const ManagementDropdownCard = (props) => {

    const {
        open=false,
        contentInfo,
        cardType,
        removeHandler,
        updateHandler, // update whole device info like startTime, interval, frequency
        patientEnrollment=false,
        dataList, //list of current devices, questionnaires, or content in program/enrollment, questionnaire, or content
        setModality, //setModality function to update goal, upper+lower limit, passed in as param to differentiate between program/enrollment 
        errorShowing,
        disabled=false,
        viewOnly=false
    } = props;

    const [openCardToggle, setOpenCardToggle] = useState(open);
    const toggleCardOpen = () => {
        setOpenCardToggle(!openCardToggle);
    };

    const dispatch = useDispatch();

    const title = contentInfo.title;
    const id = contentInfo.id;
    const programModalityList = useSelector((state)=>state.modalities.modalities);
    const patientModalityList = useSelector((state)=>state.patientEnrollmentModalities.modalities);
    const isDevice = cardType === "Device";
    const isQuestionnaire = cardType === "Questionnaire";
    const isContent = cardType === "Content";

    const serialNumber = patientEnrollment ? dataList.find((item)=> (item.deviceId === id))?.serialNumber : "";

    const cardWidth = isDevice ? "1018px" : "740px";

    const allEnrollmentDevices = useSelector((state) => state.enrollmentLists.devices);
    const allTemplateDevices = useSelector((state)=>state.programTemplateLists.devices);
    const allDevices = patientEnrollment ? allEnrollmentDevices : allTemplateDevices;

    const serialNumberInfoTooltip = (
        <Tooltip id="hub-info-tooltip">
            {title === "Perin Patch" ?
                "The Serial Number for the Perin Patch can be found on the outside of the box, next to the barcode on the back, bottom right-hand side of the box." :
                "The Serial Number for RPM devices can be found on the device or its packaging, usually near the barcode."
            }
        </Tooltip>
    );

    return (
        <div style={{width:cardWidth}} className={styles.DropdownContainer}>
            <div className={openCardToggle ? styles.CardTitleOpened : styles.CardTitleClosed}>
                <div onClick={toggleCardOpen}>
                    <FontAwesomeIcon icon={`fa-solid fa-angle-${openCardToggle ? 'down' : 'right'}`}/>
                </div>
                <span className={styles.CardTitleLabel}>{title}</span>
                {patientEnrollment && isDevice && (
                    viewOnly ?
                    <div>{serialNumber}</div>
                    :
                    <>
                        <input 
                            type="text" 
                            placeholder="Serial Number" 
                            style={(errorShowing.showing && serialNumber === "") ? {border:"1px solid red", width:"158px", height:"38px"} : {width:"158px", height:"38px"}}
                            className={inputStyles.InputStyling}
                            defaultValue={serialNumber}
                            onChange={(e) => dispatch(setSerialNumber({id:id, serial:e.target.value}))}
                        />
                        <OverlayTrigger placement="right" overlay={serialNumberInfoTooltip}>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{ marginLeft: "10px", color: '#686869' }} />
                        </OverlayTrigger>
                    </>
                )}
                {!viewOnly &&
                    <div onClick={()=>{
                            if (disabled) {
                                return;
                            } else {
                                removeHandler(id)
                            }
                        }} 
                        style={{float:"right", marginLeft:"auto", cursor:"pointer"}}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{color:"#4F4F4F"}}/>
                    </div>
                }
            </div>
            <div className={styles.OpenCardContainer} 
                style={isDevice ? {height:"auto"} : {height:"166px"}}
                hidden={!openCardToggle}
            >
                {isDevice && (
                    id === 1 ?
                    <PerinPatchSetupCard 
                        id={id} 
                        modalities={contentInfo.modalities} 
                        patientEnrollment={patientEnrollment} 
                        setModality={setModality} 
                        modalityState={patientEnrollment ? patientModalityList : programModalityList}
                        errorShowing={errorShowing}
                        disabled={disabled}
                        viewOnly={viewOnly}
                        allDevices={allDevices}
                    />
                    :
                    <DeviceSetupCard 
                        id={id} 
                        modalities={contentInfo.modalities} 
                        dataList={dataList} 
                        setCardData={updateHandler} 
                        patientEnrollment={patientEnrollment} 
                        setModality={setModality} 
                        modalityState={patientEnrollment ? patientModalityList : programModalityList}
                        errorShowing={errorShowing}
                        disabled={disabled}
                        viewOnly={viewOnly}
                        allDevices={allDevices}
                    />
                )
                }
                {(isContent || isQuestionnaire) &&
                    <QuestionnaireSetupCard 
                        isQuestionnaire={isQuestionnaire}
                        id={id} 
                        dataList={dataList} 
                        setCardData={updateHandler}
                        errorShowing={errorShowing}
                        disabled={disabled}
                        viewOnly={viewOnly}
                    />
                }               
            </div>
        </div>
    )
}

export default ManagementDropdownCard;

