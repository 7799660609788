import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ProgramManagement.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setModality, removeModality } from './ModalitiesSlice';
import { setDevice, removeDevice} from './AddProgramSlice';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';

const DeviceSetup = ({errors}) => {

    const devices = useSelector((state)=>state.programTemplateLists.devices);
    const dispatch = useDispatch();
    const deviceList = useSelector((state)=>state.addProgram.devices);

    const initSelectedDevices = () => {
        // check to see if content already exists in the state,
        const tempDevices = [];
        deviceList.forEach((item)=> {
            let exisitingDeviceIndex = devices.findIndex(device => device.deviceId === item.deviceId);
            if (exisitingDeviceIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempDevices.push(devices[exisitingDeviceIndex]);
            } else {
                tempDevices.push({
                    id: item.deviceId, 
                    name: item.deviceName
                });
            }
        })
        return tempDevices;
    };
    const [selectedDevices, setSelectedDevices] = useState(initSelectedDevices());

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const deviceInfo = {
        deviceId: '',
        deviceModalityId: 0,
        lowerLimit: 0,
        upperLimit: 0,
        targetValue: 0,
        isAlarmActive: false,
        startTime: getInitialStartTime(),
        frequency: 1,
        interval: "Day",
        readingType: "Automated",
        isContinuous: false
    };

    const modalityInfo = {
        deviceId: '',
        deviceModalityId: '',
        upperLimit: 0,
        lowerLimit: 0,
        targetValue: 0,
        isAlarmActive: false,
        isContinuous: false,
        startTime: getInitialStartTime(),
        frequency: 1,
        interval: "Day"
    };

    const checkSelectedItem = (model) => {
        let deviceInList = false;
        selectedDevices.forEach((item)=>{
            if (item.model===model){
                deviceInList=true;
            } 
        });
        return deviceInList;
    };

    const handleDeviceChange = (device) => {
        let deviceInList = false;
        // check if device is already in selectedDevices
        selectedDevices.forEach((item)=>{
            if (item.deviceId===device.deviceId){
                deviceInList = true;
            } 
        });
        if (deviceInList){
            // remove device + device modalities from selectedDevices, program slice, and modalities slice
            setSelectedDevices(selectedDevices.filter(item => item.deviceId !== device.deviceId));
            dispatch(removeDevice(device.deviceId));
            dispatch(removeModality(device.deviceId));
        } else {
            setSelectedDevices([device, ...selectedDevices]);
            let tempDevice = deviceInfo;
            deviceInfo.deviceId = device.deviceId;
            dispatch(setDevice(tempDevice));
            // add a new modality object for each modality in device
            device.modalities.forEach((m)=>{
                let tempModality = modalityInfo;
                tempModality['deviceId'] = device.deviceId;
                tempModality['deviceModalityId'] = m.deviceModalityId;
                dispatch(setModality(tempModality));
            })
        }
    };

    const removeDeviceCard = (id) => {
        setSelectedDevices(selectedDevices.filter(item => item.deviceId !== id));
        dispatch(removeDevice(id));
        dispatch(removeModality(id));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <MultiSelectDropdown 
                options={devices}
                label='model'
                checkedKey='model'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleDeviceChange}
                selectedItems={selectedDevices}
                name='Devices'
            />
            {errors.showing && 
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span className={styles.ValidationMessage}>
                        {errors.message}
                    </span>
                </div>
            }
            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                {selectedDevices.map((device, index) => (
                    <div key={device.deviceId}>
                        <ManagementDropdownCard 
                            open={index === 0}
                            contentInfo={{title:device.model, id:device.deviceId, modalities:device.modalities}} 
                            cardType={"Device"} 
                            removeHandler={removeDeviceCard}
                            updateHandler={setDevice}
                            dataList={deviceList}
                            setModality={setModality}
                            errorShowing={errors}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DeviceSetup;