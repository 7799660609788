import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    selectedMenuItem: "",
    isSidebarOpen: true,
    unreadPatientMessageIds: [],
    unreadStaffMessageIds :[],
    newStaffMessageCount: 0,
    taskListCount: 0
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    addUnreadPatientMessageId: (state, action) => {
      if (!state.unreadPatientMessageIds.includes(action.payload)) {
        state.unreadPatientMessageIds.push(action.payload);
      }
    },
    removeUnreadPatientMessageId: (state, action) => {
      state.unreadPatientMessageIds = state.unreadPatientMessageIds.filter(id => id !== action.payload);
    },
    addUnreadStaffMessageId: (state, action) => {
      if (!state.unreadStaffMessageIds.includes(action.payload)) {
        state.unreadStaffMessageIds.push(action.payload);
      }
    },
    removeUnreadStaffMessageId: (state, action) => {
      state.unreadStaffMessageIds = state.unreadStaffMessageIds.filter(id => id !== action.payload);
    },
    setNewStaffMessageCount: (state,action) => {
      state.newStaffMessageCount = action.payload;
    },
    clearUnreadPatientMessageIds: (state) => {
      state.unreadPatientMessageIds = [];
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },

    setTaskListCount: (state, action) => {
      state.taskListCount = action.payload;
    }
  },
});

export const { toggleSidebar , addUnreadPatientMessageId, removeUnreadPatientMessageId , 
  clearUnreadPatientMessageIds,addUnreadStaffMessageId, removeUnreadStaffMessageId, 
  setNewStaffMessageCount, setSelectedMenuItem, setTaskListCount} = sidebarSlice.actions;
export default sidebarSlice.reducer;
