import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Col, Row, Container, FloatingLabel  } from 'react-bootstrap';
import styles from "./UserOptions.module.css";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../services/AxiosConfig";
import managementStyles from '../ManagementPages.module.css';
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import { useDropzone } from "react-dropzone";
import { showAlert } from "../../components/PerinHealthAlertSlice";
import { setUser } from "../../slicers/UserSlice";

const ManageAccountModal = ({ show, handleClose }) => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const userData = useSelector((state) => state.userInfo);
    const userAccountId = userData.id;

    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [addressType, setAddressType] = useState(userData?.address?.type || '');
    const [streetAddress, setStreetAddress] = useState(userData?.address?.address1 || '');
    const [apt, setApt] = useState(userData?.address?.address2 || '');
    const [city, setCity] = useState(userData?.address?.city || '');
    const [state, setState] = useState(userData?.address?.state || '');
    const [country, setCountry] = useState(userData?.address?.country || '');
    const [postalCode, setPostalCode] = useState(userData?.address?.zip || '');
    const [previewUrl, setPreviewUrl] = useState(userData.profilePictureURL);
    const [selectedFile, setSelectedFile] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png", // Restrict file types to images only
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        if (selectedFile) {
            formData.append("file", selectedFile);
        };
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("type", addressType);
        formData.append("address1", streetAddress);
        formData.append("address2", apt.trim());
        formData.append("city", city.trim());
        formData.append("state", state.trim());
        formData.append("country", country.trim());
        formData.append("zip", postalCode);

        const method = "PUT";
        const url = `/user/profile/${userAccountId}?updateType=account`;
        
        httpService
          .request({
            method,
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            dispatch(setUser(response.data.data));
            handleClose();
          })
          .catch((error) => {
            dispatch(
              showAlert({
                header: "Error",
                message: `Error Updating Account Information`,
                type: "danger",
              })
            );
            console.error("Error saving data:", error);
          });
          
      };

      function formatPhoneNumber(phoneNumber) {
        let phoneStr = phoneNumber.toString();
    
        // Remove any non-digit characters
        phoneStr = phoneStr.replace(/\D/g, '');
    
        if (phoneStr.length === 10) {
            return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        } else {
            // Return the original input if it does not have 10 digits
            return phoneNumber;
        }
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Manage Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleSubmit} style={{height:"99%", display:"flex", flexDirection:"column", gap:"12px"}}>
                <div style={{height:"90%", overflowX:"hidden", overflowY:"scroll", display:"flex", flexDirection:"column", gap:"12px"}}>
                    <span className={styles.formHeader}>Contact Info </span>
                    <Row>
                        <Col sm={12}>
                            <span class={styles.formContactInfo}>{userData.email ?? ""}</span>
                            <br />
                            <span class={styles.formContactInfo}>{formatPhoneNumber(userData.phone)}</span>
                        </Col>
                    </Row>
                    <span className={styles.formHeader}>Personal Info </span>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <div className={styles.styledInputContainer}>
                            <input
                                id="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            ></input>
                            <label className={firstName && styles.filled}>
                                First Name*
                            </label>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>                           
                        <div className={styles.styledInputContainer}>
                            <input
                                id="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            ></input>
                            <label className={lastName && styles.filled}>
                                Last Name*
                            </label>
                        </div>
                    </div>

                    <span className={styles.formHeader}>Address Info </span>

                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>   
                        <div style={{position: "relative"}}>
                            <span className={styles.floatingLabel} style={{zIndex:"1"}}>Address Type</span>
                                <select
                                    style={{ width: "300px" }}
                                    className={styles.styledInputSelect}
                                    onChange={(e) => setStreetAddress(e.target.value)}
                                    value={addressType}
                                >
                                    {DropdownData.addressTypes.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </select>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <div className={styles.styledInputContainer}>
                            <input
                                id="streetAddress"
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                            ></input>
                            <label className={streetAddress && styles.filled}>
                                Street Address
                            </label>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <div className={styles.styledInputContainer}>
                            <input
                                id="apt"
                                value={apt}
                                onChange={(e) => setApt(e.target.value)}
                            ></input>
                            <label className={apt && styles.filled}>
                                Apartment, Suite, etc
                            </label>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px", width: "300px" }}>
                        <div className={styles.styledInputContainer} style={{width: "50%"}}>
                            <input
                                id="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                style={{width: "100%"}}
                            ></input>
                            <label className={city && styles.filled} style={{width: "50%"}}>
                                City
                            </label>
                        </div>

                        <div className={styles.styledInputContainer} style={{width: "50%"}}>
                            <input
                                id="state"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                style={{width: "100%"}}
                            ></input>
                            <label className={state && styles.filled} style={{width: "50%"}}>
                                State
                            </label>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px", width: "300px" }}>
                        <div className={styles.styledInputContainer} style={{width: "50%"}}>
                            <input
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                style={{width: "100%"}}
                            ></input>
                            <label className={country && styles.filled} style={{width: "50%"}}>
                                Country
                            </label>
                        </div>

                        <div className={styles.styledInputContainer} style={{width: "50%"}}>
                            <input
                                id="postalCode"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                style={{width: "100%"}}
                            ></input>
                            <label className={postalCode && styles.filled} style={{width: "50%", "white-space": "nowrap"}}>
                                Postal Code
                            </label>
                        </div>
                    </div>

                    <span className={styles.formHeader}>Profile Picture</span>
                    <div
                    {...getRootProps()}
                    style={{
                        border: "2px dashed gray",
                        padding: "20px",
                        textAlign: "center",
                        width:"300px",
                        cursor: "pointer"
                    }}
                    >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop File or browse</p>
                    ) : (
                        <p>Drop File or browse</p>
                    )}
                    </div>
                    {previewUrl && (
                    <div style={{ marginTop: "20px", position: "relative" }}>
                        <img
                        src={previewUrl}
                        alt="Preview"
                        style={{ width: "60%", height: "20%", right: 0 }}
                        />
                    </div>
                    )}
                </div>                
             <Form.Group as={Row} style={{paddingLeft:"10px", display:"flex", columnGap:"10px"}}>
                <Button
                    className={managementStyles.BackButton}
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    className={managementStyles.CreateButton}
                    variant="secondary"
                    type="submit"
                >
                    Save
                </Button>
                </Form.Group>
            </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ManageAccountModal;
