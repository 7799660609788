import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    patientRecentModalities: [],
};

const savedModalitiesSlicer = createSlice({
    name: "savedPatientModalities",
    initialState,
    reducers: {
        resetModalitiesState: (state) => {
            Object.assign(state, initialState);
        },
        setSavedPatientModalitiesState : (state, action) => {
            state.patientRecentModalities = action.payload;
        },
    }
});

export const { resetSavedPatientModalities, setSavedPatientModalitiesState } = savedModalitiesSlicer.actions;
  
  export default savedModalitiesSlicer.reducer;