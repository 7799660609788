import React from 'react';
import styles from './RawReadingsGraphModal.module.css';

const Modal = ({ show, onClose, children, title, subtitle, isAudio }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modal} ${isAudio ? styles.modalAudio : ''}`}>
        <button className={styles.closeButton} onClick={onClose}>&#x2715;</button>
        {title && <div className={styles.modalTitle}>{title}</div>}
        {subtitle && <div className={styles.modalSubtitle}>{subtitle}</div>}
        <div className={isAudio ? styles.audioContainer : styles.chartContainer}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
