import React, { useState } from "react";
import { DropdownData } from './DropdownData';
import './Accounts.css';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../views/ManagementPages.module.css';
import { setNestedField, setFormField } from './RegistrationSlice';


const PersonalInfo = ({errors}) => {

    const dispatch = useDispatch();
    const userData = useSelector((state)=>state.registration);
    const role = userData.role.name;
    const userUnitType = userData.preferences.unitType.includes("Metric") ? "Metric" : userData.preferences.unitType.includes("Imperial") ? "Imperial" : "";
    const [unitType, setUnitType] = useState(userUnitType);

    const firstNameError = errors.firstNameShowing && userData.firstName === "";
    const lastNameError = errors.lastNameShowing && userData.lastName === "";
    const phoneError = errors.phoneShowing;
    const emailError = errors.emailShowing && userData.email === "";
    const countryCodeError = errors.phoneShowing;

    const handleUnitChange = (e) => {
        setUnitType(e.target.value);
        dispatch(setNestedField({parentKey:"preferences",nestedKey:e.target.id,value:e.target.value}))
    };

    return (
        <div className="create-account-inputs">
            <div className="input-name">
                Account Info
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="firstName"
                    pattern="[A-Za-z]+" 
                    title="Please enter letters only"
                    style={firstNameError ? {border:"1px solid red", width:"300px"}:{width:"300px"}}
                    value={userData.firstName}
                    onChange={(e) => dispatch(setFormField({key:"firstName",value:e.target.value}))}
                ></input>
                <label className={userData.firstName && styles['filled']}>
                    First Name*
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input 
                    id="lastName"
                    style={lastNameError ? {border:"1px solid red", width:"300px"}:{width:"300px"}}
                    defaultValue={userData.lastName}
                    onChange={(e) => dispatch(setFormField({key:"lastName",value:e.target.value}))}
                ></input>
                <label className={userData.lastName && styles['filled']}>
                    Last Name*
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="email"
                    type="email"
                    style={emailError ? {border:"1px solid red", width:"300px"}:{width:"300px"}}
                    defaultValue={userData.email}
                    onChange={(e) => dispatch(setFormField({key:"email",value:e.target.value}))}
                ></input>
                <label className={userData.email && styles['filled']}>
                    Email*
                </label>
            </div>
            <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Country Code*</span>
                    <select
                        className={styles["styled-input-select"]}
                        id="type"
                        style={countryCodeError ? {border:"1px solid red",width:"120px"}:{width:"120px"}}
                        value={userData.countryCode}
                        onChange={(e) => dispatch(setFormField({key:"countryCode",value:e.target.value}))}
                    >
                        <option value="">Code*</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                    </select>
                </div>
                <div className={styles["styled-input-container"]}>
                    <input
                        id="phone"
                        type="number"
                        style={phoneError ? {border:"1px solid red",width:"170px"}:{width:"170px"}}
                        defaultValue={userData.phone}
                        onChange={(e) => dispatch(setFormField({key:"phone",value:e.target.value}))}
                    ></input>
                    <label className={userData.phone && styles['filled']}>
                        Phone Number*
                    </label>
                </div>
            </div>
            <div className="input-name">
                Address Info
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Address Type</span>
                <select
                    className={styles["styled-input-select"]}
                    id="type"
                    value={userData.address.type}
                    onChange={(e) => 
                        dispatch(setNestedField({parentKey:"address",nestedKey:"type",value:e.target.value}))
                    }
                    style={{width:"300px"}}
                >
                    <option value="">Address Type</option>
                    {DropdownData.addressTypes.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="address1"
                    defaultValue={userData.address.address1}
                    onChange={(e) => 
                        dispatch(setNestedField({parentKey:"address",nestedKey:"address1",value:e.target.value}))
                    }
                    style={{width:"300px"}}
                ></input>
                <label className={userData.address.address1 && styles['filled']}>
                    Street Address (Ave, St, Blvd, etc.)
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="address2"
                    defaultValue={userData.address.address2}
                    onChange={(e) => 
                        dispatch(setNestedField({parentKey:"address",nestedKey:"address2",value:e.target.value}))
                    }
                    style={{width:"300px"}}
                ></input>
                <label className={userData.address.address2 && styles['filled']}>
                Apartment, Suite, etc
                </label>
            </div>
            <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                <div className="input-block">
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="city"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.address.city}
                            onChange={(e) => 
                                dispatch(setNestedField({parentKey:"address",nestedKey:"city",value:e.target.value}))
                            }
                        ></input>
                        <label className={userData.address.city && styles['filled']}>
                        City
                        </label>
                    </div>
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="state"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.address.state}
                            onChange={(e) => 
                                dispatch(setNestedField({parentKey:"address",nestedKey:"state",value:e.target.value}))
                            }
                        ></input>
                        <label className={userData.address.state && styles['filled']}>
                        State
                        </label>
                    </div>
                </div>
                <div className="input-block" style={{marginBottom:"0px"}}>
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="country"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.address.country}
                            onChange={(e) => 
                                dispatch(setNestedField({parentKey:"address",nestedKey:"country",value:e.target.value}))
                            }
                        ></input>
                        <label className={userData.address.country && styles['filled']}>
                        Country
                        </label>
                    </div>
                    <div className={styles["styled-input-container"]}>
                        <input
                            style={{width:"147px"}}
                            type="number"
                            id="zip"
                            defaultValue={userData.address.zip}
                            onChange={(e) => 
                                dispatch(setNestedField({parentKey:"address",nestedKey:"zip",value:e.target.value}))
                            }
                        ></input>
                        <label className={userData.address.zip && styles['filled']}>
                        Postal Code
                        </label>
                    </div>
                </div>
            </div>
            
            {role==="Patient" &&
                <div style={{display: "flex", flexDirection:"column", rowGap:"10px"}}>
                    <div className="input-name">
                        Preferences
                    </div>
                    <div style={{position: "relative"}}>
                        <span className="floating-label">Date Format</span>
                        <select
                            className={styles["styled-input-select"]}
                            id="dateFormat"
                            value={userData.preferences.dateFormat}
                            onChange={(e) => 
                                dispatch(setNestedField({parentKey:"preferences",nestedKey:"dateFormat",value:e.target.value}))
                            }
                            style={{width:"300px"}}
                        >
                            <option value="">Select Date Format</option>
                            {DropdownData.dateFormats.map((type) => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="unit-types" style={{display: "flex", width: "330px", justifyContent:"space-between", marginBottom:"20px"}}>
                        Unit Type
                        <div>
                            <input
                                id="unitType" 
                                type="radio"
                                value="Imperial"
                                checked={unitType === 'Imperial'}
                                onChange={handleUnitChange}
                            /> 
                            <span style={{paddingLeft: "5px"}}>Imperial (lbs, F)</span>
                        </div>
                        <div>
                            <input
                                id="unitType" 
                                type="radio"
                                value="Metric"
                                checked={unitType === 'Metric'}
                                onChange={handleUnitChange}
                            /> 
                            <span style={{paddingLeft: "5px"}}>Metric (kg, C)</span>
                        </div>
                    </div>
                </div>
            }
        </div>
  );
};

export default PersonalInfo;
