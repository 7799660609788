import React from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LeftArrowIcon } from '../../../components/icons/Icons';
import useAxios from '../../../services/AxiosConfig';
import styles from '../QuestionnaireManagement.module.css';
import { resetAddQuestionnaire } from './AddQuestionnaireSlice';
import managementStyles from '../../ManagementPages.module.css';
import { showAlert } from '../../../components/PerinHealthAlertSlice';
import ProgressBar from '../../../components/ProgressBar';

const QuestionPreviewComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const addQuestionnaireState = useSelector((state) => state.addQuestionnaire);
    const previewQuestions = addQuestionnaireState.previewQuestions || [];
    const groupId = addQuestionnaireState.groupId || '';
    const isUpdateMode = addQuestionnaireState.isUpdateMode;

    const handleFinishClick = () => {
        const url = isUpdateMode ? `questionnaire/${groupId}` : 'questionnaire';
        const method = isUpdateMode ? 'PUT' : 'POST';

        const cleanedData = formatDataForSaving();

        httpService.request({
            method,
            url,
            data: cleanedData,
        })
            .then((response) => {
                console.log('Data saved successfully');
                dispatch(resetAddQuestionnaire());
                navigate('/questionnaire-management');
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Questionnaire saved successfully`,
                        type: 'success'
                    })
                );
            })
            .catch((error) => {
                console.error('Error saving data:', error);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Error saving questionnaire",
                        type: 'danger'
                    })
                );
            });
    };

    const formatDataForSaving = () => {
        console.log('formatDataForSaving' + addQuestionnaireState);
        return removeUndefined(addQuestionnaireState);
    };

    const removeUndefined = (obj) => {
        const excludedAttributes = ['showPreview', 'isUpdateMode','isUpdateQuestion', 'groups', 'questionId', 'answerId', 'text'];
        console.log('removeUndefined' + obj);
        const cleanedData = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                if (Array.isArray(value) && key === 'previewQuestions') {
                    const updatedArray = value.map(item => {
                        const cleanedItem = { ...item };
                        if (cleanedItem.hasOwnProperty('questionId')) {
                            delete cleanedItem['questionId'];
                        }
                        if (cleanedItem.hasOwnProperty('possibleAnswers')) {
                            cleanedItem.possibleAnswers = cleanedItem.possibleAnswers.map(answer => {
                                const cleanedAnswer = { ...answer };
                                delete cleanedAnswer.answerId;
                                delete cleanedAnswer.text;
                                return cleanedAnswer;
                            });
                        }
                        return cleanedItem;
                    });
                    return ['questions', updatedArray];
                } else if (!excludedAttributes.includes(key) && (value !== undefined && value !== '')) {
                    return [key, value];
                } else {
                    return null;
                }
            }).filter(entry => entry !== null)
        );

        cleanedData.questions = obj.previewQuestions.map(item => {
            const cleanedItem = { ...item };
            delete cleanedItem['questionId'];
            if (cleanedItem.hasOwnProperty('possibleAnswers')) {
                cleanedItem.possibleAnswers = cleanedItem.possibleAnswers.map(answer => {
                    const cleanedAnswer = { ...answer };
                    delete cleanedAnswer.answerId;
                    delete cleanedAnswer.text;
                    return cleanedAnswer;
                });
            }
            return cleanedItem;
        });
        return cleanedData;
    };

    const navigateToManagement = () => {
        navigate('/questionnaire-management');
    };

    const navigateProgressBar = (page) => {
        if (page === "Add Questions") {
            navigate('/add-questionnaire');
        } else if (page === "Questionnaire Setup") {
            navigate('/questionnaire-setup');
        } else {
            return;
        }
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Questionnaire Setup";
        } else if (step === 1) {
            return "Add Questions";
        } else if (step === 2) {
            return "Preview & Publish";
        }
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={navigateToManagement}>
                <LeftArrowIcon />
                <span>Back to Questionnaire Management</span>
            </div>
            <div className={managementStyles.MainCard}>
                <Container className={managementStyles.Container}>
                    <h3 className={managementStyles.ContentTitle} >Preview Questionnaire</h3>
                    <div style={{paddingBottom:"15px"}}>
                    <ProgressBar page={2} getPageName={getPageName} navigateToStep={navigateProgressBar} totalSteps={3}/>
                    </div>
                    <div className={styles.ScrollContainer}>
                        <div className={styles.PreviewQuestionnaire}>
                            <p className={styles.PreviewTitle} >{addQuestionnaireState.name}</p>
                            <div className={styles.PreviewGroups}>
                                <span>{addQuestionnaireState.patientType}</span>
                                <span>{addQuestionnaireState.groupName}</span>
                            </div>
                            <p className={styles.PreviewDescription} >{addQuestionnaireState.description}</p>
                        </div>
                        <Form className={styles.QuestionFormPreview}>
                            <div>
                                {previewQuestions && previewQuestions.map((question, questionIndex) => (
                                    <React.Fragment key={question.questionId}>
                                        <Row>
                                            <Col className={`pe-0 ${styles.NumberColumn}`}>
                                                <Form.Label className='mb-0'>{`${question?.questionId}.`}</Form.Label>
                                            </Col>
                                            <Col xs={11}>
                                                <Form.Label size='sm' className={styles.PreviewquestionText}>
                                                    {question?.questionText}
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                        {question?.possibleAnswers?.map((answer, answerIndex) => (
                                            <Row key={answer.answerId || answerIndex}>
                                                <Col xs={1} className={`pe-0 ${styles.NumberColumn}`}>
                                                    <Form.Check
                                                        className={styles.CheckboxComponent}
                                                        type={question.answerType === "Multi Select" ? "checkbox" : "radio"}
                                                        id={`question-${question.questionId}-answer-${answer.answerId || answerIndex}`}
                                                        disabled
                                                    />
                                                </Col>
                                                <Col xs={7}>
                                                    <Form.Label size='sm' className={styles.PreviewanswerText}>
                                                        {answer.answerText}
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Form>
                    </div>
                </Container>
                <div className={styles.Footer}>
                    <Row>
                        <Col>
                            <Button variant="primary" className={managementStyles.CreateButton} onClick={handleFinishClick}>{isUpdateMode ? 'Update' : 'Publish'}</Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" className={managementStyles.BackButton} onClick={() => navigate('/add-questionnaire')}>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default QuestionPreviewComponent;
