import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Form, FloatingLabel } from 'react-bootstrap';
import DeviceSelection from './DeviceSelection';
import { useSelector, useDispatch } from 'react-redux';
import useAxios from '../../services/AxiosConfig';
import styles from '../ManagementPages.module.css';
import './DeviceManagement.css';
import { AddQuestionIcon } from '../../components/icons/Icons';
import { LeftArrowIcon } from '../../components/icons/Icons';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const AddDevice = () => {

    const groupData = useSelector((state) => state.userInfo.groups);
    const uniqueGroups = useSelector((state) => state.userInfo.uniqueGroups);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);

    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const location = useLocation();
    let editDeviceData = [];
    let updatingDevice = false;
    let [updateQuantity, setUpdateQuantity] = useState(0);
    if (location.state && location.state.editDeviceData){
        editDeviceData = location.state.editDeviceData;
        updatingDevice = true;
    };

    const initSelectedGroup = updatingDevice ? editDeviceData.groupId : (uniqueGroups.length ===1 ) ? uniqueGroups[0].id : 0;
    const [selectedGroup, setSelectedGroup] = useState(initSelectedGroup);

    const initSelectedOrg = updatingDevice ? editDeviceData.organizationId : 0;
    const [selectedOrg, setSelectedOrg] = useState(initSelectedOrg);

    const [devices, setDevices] = useState([]);
    const [deviceCount, setDeviceCount] = useState(1);
    const [hiddenIndexes, setHiddenIndexes] = useState([]);
    const navigate = useNavigate();

    const [deviceData, setDeviceData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            httpService.get(`device`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    setDeviceData(transformedDeviceList(responseData.data.data));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        };
    
        fetchData();
    }, []);


    const transformedDeviceList = (data) => {
        let uniqueManufacturers = [];
        data.forEach((device)=>{
            if (uniqueManufacturers[device.manufacturer]) {
                uniqueManufacturers[device.manufacturer].models.push({ model: device.model, id: device.id });
            } else {
                uniqueManufacturers[device.manufacturer] = {
                    manufacturer: device.manufacturer,
                    models: [{ model: device.model, id: device.id }]
                };
            }
        });
        return Object.values(uniqueManufacturers);
    };

    const sendDevices = async (devices) => {
        const deviceInfo = {
            "groupId": selectedGroup,
            "devices": devices
        };
        try {
            const response = await httpService.request({
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                url: 'device/group',
                data: JSON.stringify(deviceInfo),
            });
            if (response) {
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Added devices successfully`,
                        type: 'success'
                    })
                );
                navigate('/device-management')
            }
        } catch (error) {
            console.log("error:"+error);
            dispatch(
                showAlert({
                    header: "Error",
                    message: "Could not add device",
                    type: 'danger',
                })
            );
        }
        
    };

    const updateDevice = async () => {
        if (updateQuantity === "" || updateQuantity <= 0) {
            alert("Please enter a valid quantity");
        } else {
            const info = { quantity: Number(updateQuantity) };
            try {
                const response = await httpService.request({
                    method: 'PUT',
                    headers: { "Content-Type": "application/json" },
                    url: `device/group/${editDeviceData.id}`,
                    data: JSON.stringify(info),
                });
                if (response) {
                    dispatch(
                        showAlert({
                            header: "Success",
                            message: "Device updated successfully",
                            type: 'success'
                        })
                    );
                    navigate('/device-management');
                }
            } catch (error) {
                console.log("error:" + error);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Error updating device",
                        type: 'danger',
                        dismissable: true
                    })
                );
            }
        }
    };

    const validateDevices = () => {
        let newDevices = [];
        devices.forEach((device, index) => {
            if (!hiddenIndexes.includes(index) && devices[index]!==undefined) {
                if (device.hasOwnProperty('id') && device.id !== "" && device.hasOwnProperty('quantity') && device.quantity > 0){
                    newDevices.push(device);
                }
            }
        });
        if (newDevices.length >=1 && selectedGroup !== 0 && selectedGroup!==""){
            sendDevices(newDevices);
        } else {
            setErrorMessage("Could not add devices, please check the information entered");
        }
    };

    const handleAnotherDevice = () => {
        setDeviceCount((prevDeviceCount)=>prevDeviceCount+1);
    };

    const handleDelete = (index) => {
        setHiddenIndexes([...hiddenIndexes, index]);
    };

    const handleGroupChange = (e) => {
        setSelectedGroup(Number(e.target.value));
    };

    const deviceSelections = () => {
        const selectionComponents = [];
        for (let i = 0; i < deviceCount; i++) {
            if (!hiddenIndexes.includes(i)) {
                selectionComponents.push(
                    <div key={i} className="device-selection-container">
                        <DeviceSelection index={i} setDevices={setDevices} deviceList={deviceData} />
                        <div onClick={() => handleDelete(i)}>
                            <FontAwesomeIcon icon="fa-solid fa-trash-can" size="sm" 
                                hidden={i===(deviceCount-1) ? true : false}
                                className="trash-icon"
                            />
                        </div>
                    </div>
                )
            }
        };
        return selectionComponents;
    };

    return (
        <div className={styles.MainContent}>
            <div className={styles.ReturnToManagement}onClick={() => navigate('/device-management')}>
                <LeftArrowIcon />
                <span>Back to Device Management</span>
            </div>
            <div className={styles.MainCard}>
                <Container className={styles.Container}>
                    <div className={styles.ContentTitle}>{updatingDevice ? "Update Device" : "Add Device"}</div>
                    <span hidden={errorMessage===""} style={{color:"red", fontSize:"12px"}}>{errorMessage}</span>
                    <div className={styles.ScrollContainer} style={{height:"90%"}}>
                    <Form>
                    {isAdmin &&
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="floatingSelect" label="Organization">
                            <Form.Select
                                disabled={updatingDevice}
                                value={selectedOrg}
                                style={{width:"300px"}}
                                onChange={(e)=>setSelectedOrg(Number(e.target.value))}
                                className={`${styles.BorderedComponent}`}
                            >
                                <option key={0} value={0}>Select an Organization</option>
                                {groupData.map((org)=> (
                                    <option key={org.organizationId} id={org.organizationId} value={org.organizationId}>{org.organizationName}</option>
                                ))}
                            </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="floatingSelect" label="Group">
                        <Form.Select
                            className={`${styles.BorderedComponent}`}
                            onChange={handleGroupChange}
                            value={selectedGroup}
                            style={{width: "300px"}}
                        >
                            <option key={0} value={0}>Select a group</option>
                            {isAdmin ? 
                                groupData.filter(organization => organization.organizationId === selectedOrg)
                                    .map((organization) => (
                                        organization.groups.map((group) => (
                                            <option key={group.groupId} value={group.groupId}>
                                            {group.groupName}
                                            </option>
                                        ))
                                    ))
                                :
                                uniqueGroups.map((group)=> (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))
                            }
                        </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    </Form>
                    {updatingDevice ? 
                        <div className="device-selection-container">
                            <DeviceSelection index={0} setDevices={setUpdateQuantity} deviceList={deviceData} updating={updatingDevice} updateDeviceInfo={editDeviceData}/>
                        </div>
                    :
                        <>
                            {deviceSelections()}
                            <Button variant="primary" style={{marginBottom:"30px"}} className={styles.AddAnotherButton} onClick={handleAnotherDevice}>
                                <AddQuestionIcon /> Add Another Device
                            </Button>
                        </>
                    }
                    </div>
                </Container>
                <div style={{display:"flex", columnGap:"15px", paddingBottom:"10px"}}>
                    <Button variant="primary" className={styles.CreateButton}
                        onClick={updatingDevice ? updateDevice : validateDevices}>
                        {updatingDevice ? "Update" : "Add"}
                    </Button>
                    <Button variant="secondary" className={styles.CancelButton} onClick={() => navigate('/device-management')}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default AddDevice;