import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    groupId: '',
    description: '',
    duration: '',
    patient: {
        id: '',
        firstName: '',
        lastName: '',
    },
    enrollment: {
        startDate: '',
        endDate: '',
        groups: []
    },
    hubs: [],  
    devices: [],
    deviceNotes: "",
    questionnaires: [],
    questionnaireNotes: "",
    contents: [],
    contentNotes: "",
    templateModalities: [],
    selectedProgram: {
        name: "",
        id: ""
    },
    updating: false,
    adding: false,
    reenrolling: false,
    viewing: false,
    originalDevices: [],
    updatingProgramOptions: [],
};

const patientEnrollmentSlice = createSlice({
    name: "enrollPatientProgram",
    initialState,
    reducers: {
        setHubSerialNumber: (state, action) => {
            const index = action.payload.index || 0;
            const serialNumber = action.payload.serialNumber;

            // Ensure the hubs array exists and has an object at the specified index
            if (!state.hubs) {
                state.hubs = [];
            }
            while (state.hubs.length <= index) {
                state.hubs.push({ accountProgramHubId: '', serialNumber: '' });
            }

            // Set the serial number
            state.hubs[index].serialNumber = serialNumber;
        },
        resetProgramState: (state) => {
            return initialState;
        },
        setFormValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setSelectedProgram: (state, action) => {
            const {name, id} = action.payload;
            state.selectedProgram.id = id;
            state.selectedProgram.name = name;
        },
        setGroupId: (state, action) => {
            state.groupId = action.payload;
        },
        setPatientInfo: (state, action) => {
            const {firstName, lastName, id} = action.payload;
            state.patient.id = id;
            state.patient.firstName = firstName;
            state.patient.lastName = lastName;
        },
        setProgramStartDate: (state, action) => {
            state.enrollment.startDate = action.payload;
        },
        setProgramEndDate: (state, action) => {
            state.enrollment.endDate = action.payload;
        },
        setGroups: (state, action) => {
            state.enrollment.groups = action.payload;
        },
        setAddingProgram: (state, action) => {
            state.adding = action.payload;
        },
        setOrganizationId: (state, action) => {
            state.organizationId = action.payload;
        },
        setDevice : (state, action) => {
            const { deviceId, ...rest } = action.payload;
            const existingDeviceIndex = state.devices.findIndex(d => d.deviceId === deviceId);
            if (existingDeviceIndex !== -1) {
                const updatedDevices = state.devices.map((d, index) => 
                    index === existingDeviceIndex ? { ...d, ...rest} : d
                )
                return { ...state, devices: updatedDevices };
            } else {
                const newDevice = { deviceId: deviceId, ...rest };
                return { ...state, devices: [...state.devices, newDevice] };
            }
        },
        setSerialNumber : (state, action) => {
            const {serial, id} = action.payload;
            const index = state.devices.findIndex(device => device.deviceId === id);

            if (index !== -1) {
                const updatedDevices = [...state.devices];
                // Update the serial number of the device at the found index
                updatedDevices[index] = {
                    ...updatedDevices[index], // Keep existing properties
                    serialNumber: serial // Update serial number
                };
                return {
                    ...state,
                    devices: updatedDevices
                };
            } else {
                return state;
            }
        },
        removeDevice : (state, action) => {
            const deviceId = action.payload;
            const updatedDevice = state.devices.filter(item => item.deviceId !== deviceId);
            state.devices = updatedDevice;
        },
        setQuestionnaire : (state, action) => {
            const { id, ...rest } = action.payload;
            const existingQIndex = state.questionnaires.findIndex(q => q.questionnaireId === id);
            if (existingQIndex !== -1) {
                const updatedQuestionnaires = state.questionnaires.map((q, index) => 
                    index === existingQIndex ? { ...q, ...rest} : q
                )
                return { ...state, questionnaires: updatedQuestionnaires };
            } else {
                const newQuestionnaire = { questionnaireId: id, ...rest };
                return { ...state, questionnaires: [...state.questionnaires, newQuestionnaire] };
            }
        },
        removeQuestionnaire : (state, action) => {
            const qId = action.payload;
            const updatedQ = state.questionnaires.filter(item => item.questionnaireId !== qId);
            state.questionnaires = updatedQ;
        },
        setContent : (state, action) => {
            const { id, ...rest } = action.payload;
            const existingContentIndex = state.contents.findIndex(content => content.contentId === id);
            if (existingContentIndex !== -1) {
                const updatedContent = state.contents.map((content, index) => 
                    index === existingContentIndex ? { ...content, ...rest} : content
                )
                return { ...state, contents: updatedContent };
            } else {
                const newContent = { contentId: id, ...rest };
                return { ...state, contents: [...state.contents, newContent] };
            }
        },
        removeContent : (state, action) => {
            const contentId = action.payload;
            const updatedContent = state.contents.filter(item => item.contentId !== contentId);
            state.contents = updatedContent;
        },
        setProgramState: (state, action) => {
            const { name, groupId, description, duration, devices, questionnaires, contents, ...rest} = action.payload;
            // set devices to only have one device object for each unique deviceId in the program data and reset 
            // the modalities
            let unique = [];
            let newTemplateModalities = [];
            if (devices.length >= 1) {
                devices.forEach(device => {
                    if (!newTemplateModalities[device.deviceId]) {
                        newTemplateModalities[device.deviceId] = [];
                    }
                    if (!newTemplateModalities[device.deviceId].includes(device.deviceModalityId)) {
                        newTemplateModalities[device.deviceId].push({deviceModalityId:device.deviceModalityId, templateId:device.templateId});
                    }
                    if (!unique.some(e => e.deviceId === device.deviceId)) {
                        let tempData = {...device};
                        if (!("serialNumber" in device)) {
                            tempData["serialNumber"] = "";
                        }
                        unique.push(tempData);
                    }
                });
            }
            const updatedTemplateModalities = { ...state.templateModalities, ...newTemplateModalities };
            if (
                state.name === name &&
                state.groupId === groupId &&
                state.description === description &&
                state.duration === duration &&
                state.questionnaires === questionnaires &&
                state.contents === contents &&
                JSON.stringify(state.devices) === JSON.stringify(unique)
            ) {
                return state; // Return current state to avoid unnecessary updates
            } else {
                return {
                    ...rest,
                    name: name,
                    groupId: groupId,
                    description: description,
                    duration: duration,
                    patient: {
                        id: state.patient.id,
                        firstName: state.patient.firstName,
                        lastName: state.patient.lastName
                    },
                    enrollment: {
                            startDate: state.enrollment.startDate,
                            endDate: state.enrollment.endDate,
                            groups: state.enrollment.groups
                    },
                    questionnaires: questionnaires,
                    contents: contents,
                    devices: unique,
                    deviceNotes: state.deviceNotes,
                    questionnaireNotes: state.questionnaireNotes,
                    contentNotes: state.contentNotes,
                    templateModalities: updatedTemplateModalities,
                    selectedProgram: state.selectedProgram,
                };
            }
        },
        setEnrollmentState: (state, action) => {
            const {enrollmentId, devices, questionnaires, contents, name, ...rest} = action.payload;
            if ("enrollmentId" in state && state.enrollmentId === rest.enrollmentId) {
                return state;
            } else {
                let unique = [];
                let newTemplateModalities = [];
                if (devices.length >= 1) {
                    devices.forEach(device => {
                        if (!newTemplateModalities[device.deviceId]) {
                            newTemplateModalities[device.deviceId] = [];
                        }
                        if (!newTemplateModalities[device.deviceId].includes(device.accountProgramDeviceId)) {
                            newTemplateModalities[device.deviceId].push({deviceModalityId:device.deviceModalityId, accountProgramDeviceId:device.accountProgramDeviceId});
                        }
                        if (!unique.some(e => e.deviceId === device.deviceId)) {
                            let tempData = {...device};
                            tempData["modalityId"] = 0;
                            tempData["lowerLimit"] = 0;
                            tempData["upperLimit"] = 0;
                            tempData["targetValue"] = 0;
                            if (!("serialNumber" in device)) {
                                tempData["serialNumber"] = "";
                            }
                            unique.push(tempData);
                        }
                    });
                }
                const updatedTemplateModalities = { ...state.templateModalities, ...newTemplateModalities };
                const newContentNotes = contents.length >= 1 ? contents[0].notes : "";
                const newQuestionnaireNotes = questionnaires.length >= 1 ? questionnaires[0].notes : "";
                const newDeviceNotes = devices.length >= 1 ? devices[0].notes : "";
                return {
                    enrollmentId: enrollmentId,
                    devices: unique,
                    templateModalities: updatedTemplateModalities,
                    deviceNotes: newDeviceNotes,
                    questionnaireNotes: newQuestionnaireNotes,
                    contentNotes: newContentNotes,
                    questionnaires: questionnaires,
                    contents: contents,
                    updating: true,
                    name: name,
                    originalDevices: devices,
                    selectedProgram: {name: name, id: enrollmentId},
                    ...rest,
                }
            }
        },
        setReenrollment: (state) => {
            state.reenrolling = true;
            state.updating = false;
            state.enrollment.startDate = "";
            state.enrollment.endDate = "";
        },
        resetNewProgram: (state, action) => {
            // when creating a new program, need to clear devices, content, questionnaires in case they had previously selected another progrma
            const patient = {
                id: state.patient.id,
                firstName: state.patient.firstName,
                lastName: state.patient.lastName,
            };
            const enrollment = {
                startDate: state.enrollment.startDate,
                endDate: state.enrollment.endDate,
                groups: state.enrollment.groups
            }
            const newState = {
                ...state,  
                patient: patient, 
                enrollment: enrollment,
                devices: [],
                contents: [],
                questionnaires: [],
                templateModalities: [],
                questionnaireNotes: "",
                contentNotes: "",
                deviceNotes: "",
                description: '',
                duration: '',
            };
            return newState;
        },
        setDefaultTeams: (state, action) => {
            const allTeams = action.payload;
            if (Array.isArray(allTeams) && allTeams.length > 0) {
                state.enrollment.groups = allTeams.map(team => ({
                    organizationId: team.organizationId,
                    groupId: team.groupId,
                    subGroupId: team.subGroupId,
                    teamId: team.teamId,
                    fullPath: `${team.groupName} - ${team.subGroupName} - ${team.teamName}`
                }));
            }
        },
    }
});

export const {
    resetProgramState,
    setGroupId,
    setPatientInfo,
    setProgramStartDate,
    setProgramEndDate,
    setGroups,
    setSerialNumber,
    setDevice,
    setHubSerialNumber,
    removeDevice,
    setQuestionnaire,
    removeQuestionnaire,
    setContent,
    removeContent,
    setProgramState,
    setEnrollmentState,
    resetNewProgram,
    setSelectedProgram,
    setAddingProgram,
    setOrganizationId,
    setReenrollment,
    setFormValue,
    setDefaultTeams,
  } = patientEnrollmentSlice.actions;
  
export default patientEnrollmentSlice.reducer;