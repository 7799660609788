import React from "react";
import { Modal, Button, Row } from "react-bootstrap";
import styles from "./Appointment.module.css";

const AppointmentSummary = ({ show, onHide, appointment, physicianName }) => {
  console.log("Appointment data:", appointment);

  const formatTimeWithSeconds = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) { 
        return ""; 
    }
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    if (isNaN(date.getTime())) { 
      return ""; 
    }
    return date.toLocaleDateString(); 
  }

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const durationMs = endDate - startDate;
    const minutes = Math.floor(durationMs / 60000);
    return `${minutes} minutes`;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className={styles.summaryHeader}>Visit Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.summaryBody}>
        <Row className={styles.summarySectionHeader}>Patient</Row>
        <Row>{appointment?.patientName || 'N/A'}</Row>
        <Row></Row>
        <Row className={styles.summarySectionHeader}>Visit Information</Row>
        <Row>Provider: {physicianName || 'N/A'}</Row>
        <Row>
          Date: {formatDate(appointment?.appointmentDate) || 'N/A'}
        </Row>
        <Row>
          Start time: {formatTimeWithSeconds(appointment?.actualAppointmentStartTime) || 'N/A'}
        </Row>
        <Row>
          End time: {formatTimeWithSeconds(appointment?.actualAppointmentEndTime) || 'N/A'}
        </Row>
        <Row>
          Duration: {
            (appointment?.actualAppointmentStartTime && appointment?.actualAppointmentEndTime) 
              ? calculateDuration(appointment.actualAppointmentStartTime, appointment.actualAppointmentEndTime) 
              : 'N/A'
          }
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentSummary;
