import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styles from '../Patient.module.css';
import { LeftVector, RightVector } from '../../../components/icons/Icons';
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    getDay,
    isSameMonth,
    isSameDay,
    isToday,
    parse,
    isAfter,
    startOfToday,
    startOfWeek,
} from "date-fns";
import { BigCalendarAlertIcon, SmallCalendarAlertIcon, BigCalendarAppointmentIcon, SmallCalendarAppointmentIcon, SmallCalendarReportIcon, BigCalendarReportIcon} from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';

const PopupCalendarView = () => {

    const appointments = useSelector((state)=>state.patient.appointments);

    const iconMapping = {
        "appointment":<BigCalendarAppointmentIcon />,
        "report":<BigCalendarReportIcon />,
        "alert":<BigCalendarAlertIcon />,
    };

    const today = startOfToday();
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const colStartClasses = [
        "",
        "col-start-2",
        "col-start-3",
        "col-start-4",
        "col-start-5",
        "col-start-6",
        "col-start-7",
    ];
    const [selectedDay, setSelectedDay] = useState(null);
    const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(parse(currMonth, "MMM-yyyy", new Date()));
    useEffect(() => {
        setFirstDayOfMonth(parse(currMonth, "MMM-yyyy", new Date()));
    }, [currMonth]);

    const daysInMonth = eachDayOfInterval({
        start: startOfWeek(firstDayOfMonth),
        end: endOfWeek(endOfMonth(firstDayOfMonth)),
    });

    const getPrevMonth = () => {
        const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
        setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
    };

    const getNextMonth = () => {
        const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
        setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
    };

    const formatApptDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const checkEvents = (day) => {
        const icons = {
            "appointment":<SmallCalendarAppointmentIcon />,
            "report":<SmallCalendarReportIcon />,
            "alert":<SmallCalendarAlertIcon />,
        }
        const matches = appointments.filter((alert) => {
            const alertDate = parse(alert.appointmentStartDateTime, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            return isSameDay(alertDate, day);
        });
        const eventIcons = matches.map(match => icons[match.type]);
        return eventIcons; 
    };

    

    const handleDayClick = (day) => {
        setSelectedDay(isSameDay(day, selectedDay) ? null : day);
    };

    const sortedEvents = useMemo(() => {
        if (selectedDay) {
            const sorted = appointments.filter((alert) => {
                const alertDate = parse(alert.appointmentStartDateTime, "yyyy-MM-dd'T'HH:mm:ss", new Date());
                return isSameDay(alertDate, selectedDay);
            });
            return sorted;
        } else {
          return appointments.filter((alert) => {
            const alertDate = parse(alert.appointmentStartDateTime, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            return isAfter(alertDate, today);
          });
        }
    }, [selectedDay, appointments]);

    return (
        <div style={{width:"100%", height:"591px", padding:"10px", display:"flex", flexDirection:"column", gap:"10px"}}>
            <div className={styles.popupCalendarContainer}>
                <div className={styles.calendarHeader}>
                    <div className="body-1">{format(firstDayOfMonth, "MMMM yyyy")}</div>
                    <div className={styles.calendarNavigation}>
                        <button onClick={() => getPrevMonth()}><LeftVector /></button>
                        <button onClick={() => getNextMonth()}><RightVector /></button>
                    </div>
                </div>
                <div style={{width:"100%", height:"100%", padding:"10px", display:"flex", flexDirection:"column", gap:"10px"}}>
                    <div className={styles.dayOfWeekContainer}>
                        {days.map((day, idx) => (
                            <div key={idx} className={styles.dayLabel}>
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className={styles.daysContainer}>
                        {daysInMonth.map((day, idx) => (
                            <div key={idx} className={`${styles.day} ${styles[colStartClasses[getDay(day)]]}`} onClick={()=>handleDayClick(day)}>
                                <div
                                    className={`${styles.dayNumber} ${
                                        isSameMonth(day, firstDayOfMonth) ? styles.currentMonth : styles.otherMonth
                                    } ${isToday(day) ? styles.today : isSameDay(day, selectedDay) ? styles.selectedDay : styles.notToday}`}
                                >
                                    {format(day, "d")}
                                    <div className={styles.calendarIcons}>
                                        {checkEvents(day).map((icon, index) => (
                                            <div key={index} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                                {icon}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.CalendarAlertsTitle}>Alerts & Events</div>
            <div style={{height:"100%", overflowY:"scroll"}}>
                {sortedEvents.map((appt, index) => (
                    <div key={index} className={styles.AlertContainer}>
                        {iconMapping[appt.type]}
                        <div>
                            <span className="body-1">{appt.type === "Appointment" ? "Appointment" : ""}</span>
                            <div style={{display:"flex", columnGap:"10px"}}>
                                <span className="caption" style={{paddingRight:"10px", borderRight:"0.5px solid #B9B9B9"}}>{formatApptDate(appt.appointmentStartDateTime)}</span>
                                <span className="caption">{appt.physicianName ?? ""}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PopupCalendarView;