import React, { useEffect } from 'react';
import styles from './FilterManagementView.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSearchText, setSelectedGroup, setSelectedLocation, setSelectedTeam, setSelectedOrganization, setGroup, setLocation, setTeam, setSelectedCard } from '../slicers/FilterChatSlice';
import { closeModal, openModal } from './PerinHealthModelSlice';
import PerinHealthModal from './PerinHealthModel';
import inputStyles from '../views/ManagementPages.module.css';

const FilterChatView = (props) => {
    const {
        addButtonLabel,
        onAddHandler,
        onDeleteHandler,
        isOrganization,
        isGroup,
        isSubGroup,
        isTeam,
        isSortBy,
        view,
        modelHeader,
        showAddButton = true,
    } = props;

    const dispatch = useDispatch();
    const { groups, locations, teams, selectedCard } = useSelector((state) => state.filters);
    const userGroups = useSelector((state) => state.userInfo.organizationsHierarchy);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const perinHealthModal = useSelector((state) => state.perinHealthModal);

    const [defaultOrganizationId] = userGroups.map((organization) => organization.organizationId);

    const initDropdowns = (allGroups) => {
        const defaultGroupId = allGroups[0].groupId;
        const selectedGroup = allGroups.find(group => group.groupId === defaultGroupId);
        if (selectedGroup && selectedGroup.subgroups && selectedGroup.subgroups.length > 0 && isSubGroup) {
            const allSubgroups = selectedGroup.subgroups;
            let dispatchingSubGroups = allSubgroups;
            if (allSubgroups.length > 1) {
                dispatchingSubGroups = [{ subGroupId: '', subGroupName: 'All' }, ...allSubgroups];
            }
            dispatch(setSelectedLocation(allSubgroups.length > 1 ? "" : allSubgroups[0].subGroupId));
            dispatch(setLocation(dispatchingSubGroups));

            if (allSubgroups.length === 1 && allSubgroups[0].teams && allSubgroups[0].teams.length > 0) {
                const allTeams = allSubgroups[0].teams;
                let dispatchingTeams = allTeams;
                if (allTeams.length > 1) {
                    dispatchingTeams = [{ teamId: '', teamName: 'All' }, ...allTeams];
                }
                dispatch(setTeam(dispatchingTeams));

                const defaultTeamId = allTeams[0].teamId;
                dispatch(setSelectedTeam(allTeams.length > 1 ? "" : defaultTeamId));
            }
        }
    };

    useEffect(() => {
        if (!isAdmin && userGroups.length === 1) {
            // only one organization
            const organization = userGroups.find((org) => org.organizationId === Number(defaultOrganizationId));
            if (organization && organization.groups.length > 0) {
                const allGroups = [...organization.groups];
                dispatch(setGroup(allGroups));
                // dispatch(setSelectedGroup(allGroups[0].groupId));
                initDropdowns(allGroups);
            }
        } else if (!isAdmin && userGroups.length > 1) {
            // multiple organizations
            const allGroups = [];
            userGroups.forEach((org) => {
                allGroups.push(...org.groups);
            })
            dispatch(setGroup([{ groupId: '', groupName: 'All' }, ...allGroups]));
        } else {
            // user is admin, all other dropdowns will be displayed on org selection
            dispatch(setSearchText(''));
            dispatch(setGroup([]));
            dispatch(setLocation([]));
            dispatch(setTeam([]));
        }
    }, [isAdmin, defaultOrganizationId, dispatch]);

    const handleSearchChange = (e) => {
        dispatch(setSearchText(e.target.value));
    };

    const handleOrganizationChange = (e) => {
        dispatch(setSelectedOrganization(e.target.value));
        dispatch(setSelectedGroup(''));
        dispatch(setSelectedLocation(''));
        dispatch(setSelectedTeam(''));
        dispatch(setLocation([]));
        dispatch(setTeam([]));

        const organization = userGroups.find((org) => org.organizationId === Number(e.target.value));
        if (organization && organization.groups.length > 0) {
            const allGroups = [...organization.groups];
            dispatch(setGroup(allGroups));

            if (allGroups.length === 1) {
                initDropdowns(allGroups);
            } else {
                dispatch(setGroup([{ groupId: '', groupName: 'All' }, ...organization.groups]));
            }
        } else {
            dispatch(setGroup([]));
        }
    };



    const handleGroupChange = (e) => {
        dispatch(setSelectedGroup(e.target.value));
        dispatch(setSelectedLocation(''));
        dispatch(setSelectedTeam(''));
        dispatch(setLocation([{ subGroupId: '', subGroupName: 'All' }]));
        dispatch(setTeam([{ teamId: '', teamName: 'All' }]));

        const selectedGroup = groups?.find((group) => group.groupId === Number(e.target.value));
        if (selectedGroup && selectedGroup.subgroups && selectedGroup.subgroups.length > 0) {
            const allSubgroups = selectedGroup.subgroups;
            let dispatchingSubGroups = allSubgroups;
            if (allSubgroups.length > 1) {
                dispatchingSubGroups = [{ subGroupId: '', subGroupName: 'All' }, ...allSubgroups];
            }
            dispatch(setLocation(dispatchingSubGroups));
            if (allSubgroups.length === 1 && isSubGroup) {
                const selectedSubgroup = allSubgroups.find((subgroup) => subgroup.subGroupId === Number(allSubgroups[0].subGroupId));
                if (selectedSubgroup && selectedSubgroup.teams && selectedSubgroup.teams.length > 0) {
                    const allTeams = [{ teamId: '', teamName: 'All' }, ...selectedSubgroup.teams];
                    dispatch(setTeam(allTeams));
                }
            }

        }
    };

    const handleLocationChange = (e) => {
        dispatch(setSelectedLocation(e.target.value));
        dispatch(setSelectedTeam(''));
        dispatch(setTeam([{ teamId: '', teamName: 'All' }]));

        const selectedSubgroup = locations?.find((subgroup) => subgroup.subGroupId === Number(e.target.value));
        if (selectedSubgroup && selectedSubgroup.teams && selectedSubgroup.teams.length > 0) {
            const allTeams = [{ teamId: '', teamName: 'All' }, ...selectedSubgroup.teams];
            dispatch(setTeam(allTeams));
        }
    };

    const handleTeamChange = (e) => {
        dispatch(setSelectedTeam(e.target.value));
    };

    const handleSortByChange = () => {
        // dispatch(setSelectedTeam(e.target.value));
    };

    const onAddClickHandler = () => {
        onAddHandler();
    };

    const handleDialogButtonClick = (onClickKey) => {
        if (onClickKey === 'YES') {
            onDeleteHandler();
        } else {
            dispatch(closeModal());
        }
    };

    const onDeleteRowHandler = () => {
        dispatch(
            openModal({
                header: modelHeader,
                message: "Do you want to delete the selected record?",
                buttons: [
                    { label: 'Delete', variant: 'primary', onClickKey: 'YES' },
                    { label: 'Cancel', variant: 'secondary', onClickKey: 'NO' },
                ],
            })
        );

    };

    return (
        <div className={styles.FilterManagmentView}>
            <div className={styles.LeftSection}>
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                    <div style={{ position: "absolute", paddingLeft: "10px" }}>
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size="xs" style={{ color: "D9D9D9" }} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearchChange}
                        className={styles.SearchInput}
                    />
                </div>
            </div>

            <div className={styles.RightSection}>
                {selectedCard !== "" && (
                    <div className={styles.ClearAllLabel} onClick={() => dispatch(setSelectedCard(""))}>
                        Clear All
                    </div>
                )}
                {isAdmin && isOrganization && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Organization</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleOrganizationChange}
                            
                        >
                            <option value="">Select</option>
                            {userGroups.map((organization) => (
                                <option key={organization.organizationId} value={organization.organizationId}>
                                    {organization.organizationName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {isGroup && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Group</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleGroupChange}
                        >
                            {groups?.map((group) => (
                                <option key={group.groupId} value={group.groupId}>
                                    {group.groupName}
                                </option>
                            ))}
                        </select>
                    </div>)
                }


                {isSubGroup && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Location</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleLocationChange}
                        >
                            {locations?.map((subgroup) => (
                                <option key={subgroup.subGroupId} value={subgroup.subGroupId}>
                                    {subgroup.subGroupName}
                                </option>
                            ))}
                        </select>
                    </div>)
                }
                {isTeam && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Team</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleTeamChange}
                        >
                            {teams.map((team) => (
                                <option key={team.teamId} value={team.teamId}>
                                    {team.teamName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {showAddButton &&
                    (<Button
                        variant="secondary"
                        className={styles.AddContentButton}
                        onClick={onAddClickHandler}
                    >
                        {addButtonLabel}
                    </Button>)
                }

                {isSortBy && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Sort By</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleSortByChange}
                        >
                            <option value="name">Name</option>
                            <option value="date">Date</option>
                            <option value="rating">Rating</option>
                        </select>
                    </div>
                )}
                {perinHealthModal.show && (
                    <PerinHealthModal
                        show={perinHealthModal.show}
                        header={perinHealthModal.header}
                        message={perinHealthModal.message}
                        buttons={perinHealthModal.buttons}
                        handleButtonClick={handleDialogButtonClick}
                    />
                )}
            </div>
        </div>
    );
};

export default FilterChatView;
