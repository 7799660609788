import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  confirmationCode: '',
  password: ''
};
 
export const ForgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setConfirmationCode: (state, action) => {
      state.confirmationCode = action.payload;
    },
    resetPasswordState: (state) => {
      Object.assign(state, initialState);
    },
  },
});
 
export const { setUsername, setPassword, setConfirmationCode, resetPasswordState  } = ForgotPasswordSlice.actions;
 
export default ForgotPasswordSlice.reducer;