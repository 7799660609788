import React, { useState } from "react";
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import '../../components/AccountManagement/Accounts.css';
import inputStyles from '../ManagementPages.module.css';

const ActivateHealthInfo = ({userData, handleChange}) => {

    const birthday = userData.healthInfo.dateOfBirth ? userData.healthInfo.dateOfBirth.substring(0,10) : "";
    const unitType = userData.preferences.unitType.toLowerCase().includes('metric') ? 'Metric' : 'Imperial';

    // handle weight
    const initWeight = () => {
        if (userData.healthInfo.weight){
          return parseFloat(userData.healthInfo.weight);
        } else {
          return "";
        }
    };
    const [weight, setWeight] = useState(initWeight());

    const handleWeight = (e) => {
        const value = e.target.value;
        setWeight(value);
        handleChange(value, "weight", "healthInfo");
    };

    // handle given height string
    const [height, setHeight] = useState(parseInitialHeight(userData.healthInfo.height, unitType));
    function parseInitialHeight(heightString, unitType) {
        if (!heightString) {
            return { feet: "", inches: "", cm: "" };
        }
        if (unitType === "Imperial") {
            const [feet, inches] = heightString.split("'").map(str => parseInt(str));
            if (feet) {
                return { feet, inches };
            } else {
                return { feet: "", inches: "" };
            }
        } else {
            const cm = parseInt(heightString);
            return { cm };
        }
    };

    const handleHeightChange = (e, id) => {
        if (e.target.value) {
          const value = parseInt(e.target.value);
          setHeight(prevHeight => ({
              ...prevHeight,
              [id]: value
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${value}' ${height.inches}`;
              } else {
                  formattedHeight = `${height.feet}' ${value}`;
              }
          } else {
              formattedHeight = `${value}cm`;
          }
          handleChange(formattedHeight, "height", "healthInfo");
        } else {
          setHeight(prevHeight => ({
            ...prevHeight,
            [id]: ""
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${height.inches}"`;
              } else {
                  formattedHeight = `${height.feet}' ${""}`;
              }
          } else {
              formattedHeight = `${""}`;
          }
          handleChange(formattedHeight, "height", "healthInfo");
        }
    };
    
    const today = new Date().toLocaleDateString('fr-ca');

    const [count, setCount] = useState(userData.healthInfo.notes.length);
    const handleNotes = (e) => {
        setCount(e.target.value.length);
        handleChange(e,"notes","healthInfo")
    };


    return (
        <div className="personal-info-container">
            <div className="personal-info-content">
                <div className={inputStyles["styled-input-container"]}>
                    <span className="floating-label" >Date of Birth</span>
                    <input
                        defaultValue={birthday}
                        type="date"
                        max={today}
                        id="dateOfBirth"
                        onChange={handleChange}
                    ></input>
                </div>
                <div className="input-name" style={{color:"#5E5E5E",fontSize:"13px"}}>Height</div>
                {unitType === "Imperial" ?
                    <div className="input-block" style={{width: "300px", marginBottom:"0"}}>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                style={{width:"100%"}}
                                id="feet"
                                min="0"
                                max="8"
                                type="number"
                                defaultValue={height.feet}
                                onChange={(e) => handleHeightChange(e, "feet")}
                            ></input>
                            <label className={height.feet && inputStyles['filled']}>
                                ft
                            </label>
                        </div>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                style={{width:"100%"}}
                                id="inches"
                                min="0"
                                max="8"
                                type="number"
                                defaultValue={height.inches}
                                onChange={(e) => handleHeightChange(e, "inches")}
                            ></input>
                            <label className={height.inches && inputStyles['filled']}>
                                in
                            </label>
                        </div>
                    </div>
                    :
                    <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                        <input
                            style={{width:"100%"}}
                            id="cm"
                            type="number"
                            value={height.cm}
                            onChange={(e) => handleHeightChange(e, "cm")}
                        ></input>
                        <label className={height.cm && inputStyles['filled']}>
                            cm
                        </label>
                    </div>
                }
                <div className="input-name" style={{color:"#5E5E5E",fontSize:"13px"}}>Weight</div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        style={{width:"150px"}}
                        id="weight"
                        type="number"
                        value={weight}
                        onChange={handleWeight}
                    ></input>
                    <label className={weight && inputStyles['filled']}>
                        {unitType==="Imperial" ? "lbs" : "kg"}
                    </label>
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Gender</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="gender"
                        value={userData.healthInfo.gender}
                        onChange={(e)=>handleChange(e,"gender","healthInfo")}
                    >
                        <option value="">Select a Gender</option>
                        {DropdownData.gender.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="conditions"
                        value={userData.healthInfo.conditions}
                        onChange={(e)=>handleChange(e,null,"healthInfo")}
                    ></input>
                    <label className={userData.healthInfo.conditions && inputStyles['filled']}>
                        Condition
                    </label>
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Critical Level</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="criticalLevel"
                        value={userData.healthInfo.criticalLevel}
                        onChange={(e)=>handleChange(e,"criticalLevel","healthInfo")}
                    >
                        <option value="">Select Critical Level</option>
                        {DropdownData.levels.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Phase</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="phase"
                        value={userData.healthInfo.phase}
                        onChange={(e)=>handleChange(e,"phase","healthInfo")}
                    >
                        <option value="">Select Phase</option>
                        {DropdownData.phases.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={inputStyles["styled-input-container"]} style={{width:"300px"}}>
                    <textarea 
                        className={inputStyles["note-input"]}
                        id="notes"
                        type="text"
                        maxLength={250}
                        value={userData.healthInfo.notes}
                        onChange={e => handleNotes(e)}
                    >
                    </textarea>
                    <label className={userData.healthInfo.notes && inputStyles['filled']}>
                        Notes
                    </label>
                    <span style={{fontSize:"10px", color:"#929395", marginLeft:"auto"}}>
                        {count}/250
                    </span>
                </div>
            </div>
        </div>
    )

}

export default ActivateHealthInfo;