import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import useAxios from '../../../services/AxiosConfig';
import { useSelector } from 'react-redux';

const ChartDetailView = ({ isModalVisible, handleCloseModal, modalData }) => {
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);
  const [chartData, setChartData] = useState(null);
  const [vital, setVital] = useState('');
  const [showChart, setShowChart] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const patient = useSelector((state) => state.patient);
  const { id } = patient;

  const fetchRawReadings = useCallback(async (patientId, completedDate, deviceName, modalityId, vital) => {
    try {
      const response = await httpService.get(`device/readings/raw/${modalityId}`, {
        params: {
          completedDate: completedDate,
          accountId: patientId,
        }
      });

      if (response.data.data.fileType === 'csv') {
        const data = response.data.data.fileData;
        const labels = data.map((_, index) => index);
        const values = data.map(value => parseFloat(value));

        setChartData({
          labels,
          datasets: [
            {
              label: deviceName,
              data: values,
              fill: false,
              borderColor: 'rgba(75,192,192,1)',
              tension: 0.1,
              pointRadius: 0
            }
          ]
        });
        setVital(vital);
        setShowChart(true);
        setShowAudio(false);
        
      } else if (response.data.data.fileType === 'wav') {
        const audioData = response.data.data.fileData;
        const audioArray = new Uint8Array(Object.values(audioData));
        const blob = new Blob([audioArray], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        setVital(vital);
        setAudioUrl(url);
        setShowAudio(true);
        setShowChart(false);
      }
    } catch (error) {
      console.error("Failed to fetch raw readings:", error);
    }
  }, [httpService]);

  useEffect(() => {
    if (modalData) {
      const { timeStamp, deviceName, modalityId, vital } = modalData;
      fetchRawReadings(id, timeStamp, deviceName, modalityId, vital);
    }
  }, [modalData, fetchRawReadings, id]);

  const modalBodyWidth = window.innerWidth * 0.9;
  const modalBodyHeight = window.innerHeight * 0.9;

  const modalStyles = {
    '--bs-modal-zindex': 1055,
    '--bs-modal-padding': '1rem',
    '--bs-modal-margin': '0.5rem',
    '--bs-modal-width': showAudio ? '50%' : '90%', 
    '--bs-modal-height': showAudio ? '30%' : '90%',
  };

  return (
    <Modal
      show={isModalVisible}
      onHide={handleCloseModal}
      fullscreen='xl-down'
      centered={true}
      backdrop="static"
      style={modalStyles}
    >
      <Modal.Header className="d-flex justify-content-end align-items-center" style={{ borderBottom: "none" }} closeButton>
        <Modal.Title>
          {vital}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: showAudio ? 'auto' : modalBodyHeight, overflow: 'auto' }}>
        {showChart && chartData && (
          <Line data={chartData} options={{ maintainAspectRatio: false }} />
        )}
        {showAudio && audioUrl && (
          <audio controls style={{ width: '100%' }}>
            <source src={audioUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        )}
        {!showChart && !showAudio && (
          <div>No data available</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ChartDetailView;
