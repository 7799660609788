import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    program: {
        organizationId: '',
        groupId: '',
        name: '',
        description: '',
        duration: '',
    },
    devices: [],
    questionnaires: [],
    contents: [],
    updating: false,
    modalities: [],
};

const addProgramSlice = createSlice({
    name: "addProgram",
    initialState,
    reducers: {
        resetProgramState: (state) => {
            Object.assign(state, initialState);
        },
        setProgramGroupId: (state, action) => {
            state.program.groupId = action.payload;
        },
        setProgramName: (state, action) => {
            state.program.name = action.payload;
        },
        setProgramDescription: (state, action) => {
            state.program.description = action.payload;
        },
        setProgramDuration: (state, action) => {
            state.program.duration = action.payload;
        },
        setDevice : (state, action) => {
            const { deviceId, ...rest } = action.payload;
            const existingDeviceIndex = state.devices.findIndex(d => d.deviceId === deviceId);
            if (existingDeviceIndex !== -1) {
                const updatedDevices = state.devices.map((d, index) => 
                    index === existingDeviceIndex ? { ...d, ...rest} : d
                )
                return { ...state, devices: updatedDevices };
            } else {
                const newDevice = { deviceId: deviceId, ...rest };
                return { ...state, devices: [...state.devices, newDevice] };
            }
        },
        removeDevice : (state, action) => {
            const deviceId = action.payload;
            const updatedDevice = state.devices.filter(item => item.deviceId !== deviceId);
            state.devices = updatedDevice;
        },
        setQuestionnaire : (state, action) => {
            const { id, ...rest } = action.payload;
            const existingQIndex = state.questionnaires.findIndex(q => q.questionnaireId === id);
            if (existingQIndex !== -1) {
                const updatedQuestionnaires = state.questionnaires.map((q, index) => 
                    index === existingQIndex ? { ...q, ...rest} : q
                )
                return { ...state, questionnaires: updatedQuestionnaires };
            } else {
                const newQuestionnaire = { questionnaireId: id, ...rest };
                return { ...state, questionnaires: [...state.questionnaires, newQuestionnaire] };
            }
        },
        removeQuestionnaire : (state, action) => {
            const qId = action.payload;
            const updatedQ = state.questionnaires.filter(item => item.questionnaireId !== qId);
            state.questionnaires = updatedQ;
        },
        setContent : (state, action) => {
            const { id, ...rest } = action.payload;
            const existingContentIndex = state.contents.findIndex(content => content.contentId === id);
            if (existingContentIndex !== -1) {
                const updatedContent = state.contents.map((content, index) => 
                    index === existingContentIndex ? { ...content, ...rest} : content
                )
                return { ...state, contents: updatedContent };
            } else {
                const newContent = { contentId: id, ...rest };
                return { ...state, contents: [...state.contents, newContent] };
            }
        },
        removeContent : (state, action) => {
            const contentId = action.payload;
            const updatedContent = state.contents.filter(item => item.contentId !== contentId);
            state.contents = updatedContent;
        },
        setProgramState: (state, action) => {
            const { id, organizationId, groupId, name, description, duration, devices, questionnaires, contents} = action.payload;
            // set devices to only have one device object for each unique deviceId in the program data and reset 
            // the modalities
            let unique = [];
            if (devices.length >= 1) {
                devices.forEach(device => {
                    if (!unique.some(e => e.deviceId === device.deviceId)) {
                        let tempData = {...device};
                        tempData["deviceModalityId"] = 0;
                        tempData["lowerLimit"] = 0;
                        tempData["upperLimit"] = 0;
                        tempData["targetValue"] = 0;
                        unique.push(tempData);
                    }
                });
            }
            if (
                state.id === id &&
                state.program.groupId === groupId &&
                state.program.name === name &&
                state.program.description === description &&
                state.program.duration === duration &&
                state.questionnaires === questionnaires &&
                state.contents === contents &&
                JSON.stringify(state.devices) === JSON.stringify(unique)
            ) {
                return state; 
            } else {
                return {
                    id: id,
                    program: {
                        organizationId: organizationId,
                        groupId: groupId,
                        name: name,
                        description: description,
                        duration: duration,
                    },
                    questionnaires: questionnaires,
                    contents: contents,
                    devices: unique,
                    modalities: devices,
                    updating: true
                };
            }
        },
    }
});

export const {
    resetProgramState,
    setProgramGroupId,
    setProgramName,
    setProgramDescription,
    setProgramDuration,
    setDevice,
    removeDevice,
    setQuestionnaire,
    removeQuestionnaire,
    setContent,
    removeContent,
    setProgramState
  } = addProgramSlice.actions;
  
  export default addProgramSlice.reducer;