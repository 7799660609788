import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    countryCode: "",
    phone: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    gender: "",
    role: {
        id: "",
        name: "",
        category: ""
    },
    address: {
        type: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
    },
    preferences: {
        unitType: "Imperial",
        dateFormat: "MM/DD/YYYY"
    },
    mrn: "",
    ssn: "",
    groups: [],
    emergencyContact: {
        fullName: "",
        relation: "",
        gender: "",
        countryCode: "",
        phone: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
    },
    healthInfo: {
        criticalLevel: 0,
        phase: "",
        notes: "",
        gender: "",
        height: "",
        weight: "",
        ethnicity: "",
        conditions: "",
        dateOfBirth: ""
    },
    specialties: [],
    npi: "",
    employeeId: "",
    canHaveAppointment: false,
    canManageAvailability: false,
    updating: false,
    profilePictureURL: '',
    title: ''
};

const emContact = {
    fullName: "",
    relation: "",
    gender: "",
    countryCode: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: ""
}; 

const hInfo = {
    criticalLevel: 0,
    phase: "",
    notes: "",
    gender: "",
    height: "",
    weight: "",
    ethnicity: "",
    conditions: "",
    dateOfBirth: ""
};

const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        resetRegistrationState: () => initialState,
        
        setRoleInfo: (state, action) => {
            const { name } = action.payload;
            state.role = action.payload;
            if (name === "Doctor") {
                state.canHaveAppointment = true;
            }
        },
        setFormField: (state, action) => {
            let { key, value } = action.payload;

            // Ensure country code has a leading "+"
            if (key === 'countryCode') {
                if (!value.startsWith('+')) {
                    value = `+${value}`;
                }
            }
            
            state[key] = value;
        },
        setNestedField: (state, action) => {
            const { parentKey, nestedKey, value } = action.payload;
            state[parentKey][nestedKey] = value;
        },
        setUpdatingState: (state, action) => {
            const updatingState = action.payload;
            if (updatingState.role.name.includes("Patient")) {
                if (!updatingState.emergencyContact) {
                    updatingState.emergencyContact = emContact;
                }
                if (!updatingState.healthInfo) {
                    updatingState.healthInfo = hInfo;
                }
            }
            const retState = { ...updatingState, updating:true};
            Object.assign(state, retState);
        },
    }
});

export const {
    resetRegistrationState,
    setRoleInfo,
    setFormField,
    setNestedField,
    setUpdatingState
  } = registrationSlice.actions;
  
  export default registrationSlice.reducer;