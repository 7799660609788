const commonStyles = {
    container: (provided) => ({
      ...provided,
      width: '300px',
      fontSize: '16px',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '6px',
      height: '44px',
      borderColor: '#686869',
      borderWidth: '0.5px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#B2C7CE' : state.isFocused ? '#B2C7CE' : provided.backgroundColor,
    }),
  };
  
  export const customStyles = {
    specialtyStyle: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        fontSize: '14px',
        marginBottom: '10px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
    
      placeholder: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 3,
      }),
    },

    groupSelect: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        fontSize: '12px',
        marginBottom: '10px',
      })
    },
  
    addressStyle: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '300px',
        fontSize: '12px',
        paddingTop: '0px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
    
      placeholder: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 3,
      }),
    },

    addressStyleActivate: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '100%',
        fontSize: '16px',
        paddingTop: '0px',
      }),
      control: (provided) => ({
        ...provided,
        borderRadius: '6px',
        height: '44px',
        borderColor: '#686869',
        borderWidth: '0.5px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
    
      placeholder: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 3,
      }),
    },
  
    flagDropdown: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '25%',
      }),
    },
  
    addressInput: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '100%',
      }),
    },

    popup: {
      ...commonStyles,
      control: (provided, {isDisabled }) => ({
        ...provided,
        borderRadius: '6px',
        height: '44px',
        borderColor: '#686869',
        borderWidth: '0.5px',
        backgroundColor: isDisabled ? "light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))" : "white",
      }),
      container: (provided) => ({
        ...provided,
        width: '100%',
        fontSize: '16px',
        paddingTop: '0px',
        color: "#000",
        borderRadius: '6px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        // marginRight: '5px', 
        paddingLeft: '1px',
        color: "#000"
      }),
    
      placeholder: (provided) => ({
        ...provided,
        paddingTop: '5px', 
        // marginRight: '5px', 
        paddingLeft: '1px',
        fontWeight: '400',
        color: "#4F4F4F",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.024px",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },

    popupDate: {
      ...commonStyles,
      control: (provided, {isDisabled }) => ({
        ...provided,
        borderRadius: '6px',
        height: '44px',
        borderColor: '#686869',
        borderWidth: '0.5px',
        backgroundColor: isDisabled ? "light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))" : "white",
      }),
      container: (provided) => ({
        ...provided,
        width: '110%',
        fontSize: '16px',
        paddingTop: '0px',
        color: "#000",
        borderRadius: '6px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        // marginRight: '5px', 
        paddingLeft: '1px',
        color: "#000"
      }),
    
      placeholder: (provided) => ({
        ...provided,
        paddingTop: '5px', 
        // marginRight: '5px', 
        paddingLeft: '1px',
        fontWeight: '400',
        color: "#4F4F4F",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.024px",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        position: 'fixed',  // Ensures the dropdown is fixed to the viewport
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,  // Ensures the dropdown portal is on top of everything
    }),
    },

    addressInputActivate: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '100%',
        fontSize: '12px',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
      placeholder: (provided) => ({
        ...provided,
        marginTop: '12px', 
        marginRight: '5px', 
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 3,
      }),
    },

    searchInput: {
      ...commonStyles,
      container: (provided) => ({
        ...commonStyles.container(provided),
        width: '140px',
      }),
      control: (provided, state) => ({
        ...commonStyles.control(provided,state),
        height: '36px',
        borderRadius: '25px',
        minHeight: '20px',
      }),
    },
  };
  