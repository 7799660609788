import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import FilterManagementView from '../../components/FilterManagementView';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import styles from './PatientSubmittedReports.module.css';
import useAxios from '../../services/AxiosConfig';
import { updatePatientInfo } from '../../slicers/PatientSlice';
import { setMenuItem } from '../patient/Slices/PatientDetailSlice';
import NewStatusBadge from '../../components/icons/NewStatusBadge';

const PatientSubmittedReports = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reportData, setReportData] = useState([]);

    const headers = [
        { key: 'id', label: "ID", sortable: true },
        { key: 'name', label: "Name", sortable: true },
        { key: 'date', label: "Date", sortable: true },
        { key: 'status', label: "Status", sortable: true },
        { key: 'openReportCount', label: "Reports", sortable: true }
    ];

    const getTotalNewReports = () => {
        return reportData.reduce((total, item) => total + (Math.min(item.newReportCount,1)), 0);
    };

    const totalNewReports = getTotalNewReports();

    const onRowClickHandler = (patient) => {
        dispatch(showAlert({
            header: "Redirecting",
            message: "Taking you to the patient details page.",
            type: 'info'
        }));
        dispatch(updatePatientInfo(patient.id));
        navigate('/patient-detail/Reports');
    };   
    
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const userAccountId = useSelector((state) => state.userInfo.id);
    const axios = useAxios(axiosConfig.envURL);

    const checkForBadge = (item) => {
        if (item.newReportCount > 0 ) {
            return <NewStatusBadge count={item.newReportCount}/>;
        } else { return null};
    };

    useEffect(() => {
        const fetchData = async () => {
        try {
            const reportResponse = await axios.get(`/contact/list/open/nonCriticalReport/${userAccountId}`);
            const formatted = reportResponse.data.data.map(item => {
                let retItem = item;
                const utcDate = new Date(item.date);
                retItem.date = utcDate.toLocaleDateString();
                return retItem;
            })
            setReportData(formatted);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
        };
        fetchData();
    }, [axios, userAccountId]);

    return (
        <div className={managementStyles.MainContent}>
            {/* <div className={managementStyles.Title}>Patient-submitted Reports</div> */}
            <div className={managementStyles.MiddleContent}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={styles.ReportsNumber}>{totalNewReports}</span>
                    <span className={styles.ReportsLabel2} style={{ paddingLeft: "5px" }}>Patients with New Reports</span>
                </div>
                <FilterManagementView
                    isOrganization={false}
                    isGroup={false}
                    showAddButton={false}
                    showDeleteButton={false}
                />
                <PerinHealthGrid
                    dataList={reportData}
                    showProfilePicture={'name'}
                    headers={headers}
                    showCheckbox={false}
                    showHeaderFilter={true}
                    onRowValueClick={onRowClickHandler}
                    gridHeight='100%'
                    rowValueAction={'name'} 
                    showBadge={checkForBadge}
                />     
            </div>
        </div>
    );
}

export default PatientSubmittedReports;
