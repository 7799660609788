import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PerinHealthGrid.module.css';
import { setSelectAll, setSelectedRows, setSelectedHeaders } from './PerinHealthGridSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlueProfileIcon, SortingDownArrowIcon, SortingUpArrowIcon, DefaultHeaderFilterIcon, ActiveHeaderFilterIcon } from './icons/Icons';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import EllipsesIcon from './icons/EllipsesIcon';
import PerinHealthGridResizable from './PerinHealthGridResizable';

const PerinHealthGrid = (props) => {
  const {
    headers,
    dataList,
    showCheckbox = false,
    itemsPerPage = 15,
    iconMapping = [],
    showEditIcon = false,
    accounts = false,
    onRowClick,
    moreEditOptions = false,
    showOptions = false,
    renderOptionCard,
    gridHeight = "500px",
    showHeaderFilter = false,
    showProfilePicture = false,
    rowValueAction,
    onRowValueClick, 
    useChips = false,
    showBadge
  } = props;

  const [hoveredRow, setHoveredRow] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setItemsPerPage] = useState(itemsPerPage);
  const [headerFilterOpen, setHeaderFilterOpen] = useState(false);
  const moreEditOptionsButtonRef = useRef(null);
  const allButtonRef = useRef(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");


  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filters);

  const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
  const { selectedRows, selectAll, selectedHeaders, selectedItem } = perinHealthGrid;

  useEffect(() => {
    setSelectedRows([]);
  }, [dataList]);

  useEffect(() => {
    dispatch(setSelectedHeaders(headers));
  }, []);


  
  const filteredData = useMemo(() => {
    if (!Array.isArray(dataList) || dataList.length === 0) {
      return [];
    }

    if (!headers || headers.length === 0) {
      return dataList;
    }

    return dataList.filter((item) => {
      const matchesSearch = headers.some((header) => {
        const headerValue = item[header.key];
        return String(headerValue).toLowerCase().includes(filter.searchText.toLowerCase());
      });

      if (accounts) {
        const matchesOrganization = filter.selectedOrganization === '' || item['organizationIds']?.includes((Number(filter.selectedOrganization)));
        const matchesGroup = filter.selectedGroup === '' || item['groupIds']?.includes(Number(filter.selectedGroup));
        const matchesLocation = filter.selectedLocation === '' || item['subGroupIds']?.includes(Number(filter.selectedLocation));
        const matchesTeam = filter.selectedTeam === '' || item['teamIds']?.includes(Number(filter.selectedTeam));
        return matchesOrganization && matchesSearch && matchesGroup && matchesLocation && matchesTeam;
      } else if (filter.selectedOrganization !== '') {
        const organizationHeaderValue = item['organizationId'];
        const organization = filter.selectedOrganization === 'All' || String(organizationHeaderValue).toLowerCase().startsWith(String(filter.selectedOrganization).toLowerCase());

        if (filter.selectedGroup !== '') {
          const groupHeaderValue = item['groupId'];
          const group = filter.selectedGroup === 'All' || String(groupHeaderValue).toLowerCase().startsWith(String(filter.selectedGroup).toLowerCase());
          return group && matchesSearch;
        } else {
          return organization && matchesSearch;
        }
      }
      else {
        return matchesSearch;
      }
    });
  }, [dataList, filter, headers, accounts]);

  const sortKeys = {
    spo2: 'spo2_sort',
    hr: 'hr_sort',
    temp: 'temp_sort',
    weight: 'weight_sort',
    ausc: 'ausc_sort',
    ecgHrv: 'ecgHrv_sort',
    rr: 'rr_sort',
    bp: 'bp_sort',
    device_status: 'device_status_sort',
    data_status: 'data_status_sort',
    out_of_limit: 'outOfLimit_sort',
    enrollment: 'enrollment_sort',
  };

  const handleSortingChange = (key, direction) => {
    if (sortField === key && sortOrder === direction) {
      // reset rows
      setSortField("");
      setSortOrder("asc");
    } else {
      setSortField(key);
      setSortOrder(direction);
    }
  };

  const sortedData = useMemo(() => {
    if (sortField) {
      let sortKey = sortKeys[sortField] ?? sortField;
      const sorted = [...filteredData].sort((a, b) => {
        if (!a[sortKey] && a[sortKey] !== "") return 1;
        if (!b[sortKey] && b[sortKey] !== "") return -1;
        if (!a[sortKey] && !b[sortKey]) return 0;
        return (
          a[sortKey].toString().localeCompare(b[sortKey].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      return sorted;
    } else {
      // Default sorting: out_of_limit first, then enrollment
      const sorted = [...filteredData].sort((a, b) => {
        const outOfLimitComparison = b.outOfLimit_sort - a.outOfLimit_sort;  
        if (outOfLimitComparison !== 0) return outOfLimitComparison;

        const enrollmentComparison = b.enrollment_sort - a.enrollment_sort;  
        if (enrollmentComparison !== 0) return enrollmentComparison;

        return 0;
      });

    return sorted;
      }
    }, [sortField, sortOrder, filteredData]);

  const handleHeaderFilterSelection = (header) => {
    const index = selectedHeaders.findIndex((selectedHeader) => selectedHeader.label === header.label);
    if (index !== -1) {
      if (selectedHeaders.length === 1) {
        return;
      }
      const updatedHeaders = [...selectedHeaders];
      updatedHeaders.splice(index, 1);
      dispatch(setSelectedHeaders(updatedHeaders));
    } else {
      dispatch(setSelectedHeaders([...selectedHeaders, header]));
    }
  };

  const handleHeaderCheckboxChange = () => {
    const allItems = filteredData;
    dispatch(setSelectAll(!selectAll));
    dispatch(setSelectedRows(!selectAll ? allItems : []));
  };

  const handleRowCheckboxChange = (item) => {
    const updatedSelectedRows = [...selectedRows];
    const index = updatedSelectedRows.findIndex((selectedItem) => selectedItem.id === item.id);

    if (index !== -1) {
      updatedSelectedRows.splice(index, 1);
    } else {
      updatedSelectedRows.push(item);
    }

    dispatch(setSelectedRows(updatedSelectedRows));
    dispatch(setSelectAll(updatedSelectedRows.length === filteredData.length));
  };

  const onEditClickHandler = (item) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  const totalPages = Math.ceil(filteredData.length / rowPerPage);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage >= totalPages;

  const handleChangeRowPerPage = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * rowPerPage;
  const indexOfFirstItem = indexOfLastItem - rowPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const createTooltipChip = (item) => {
    let chipTooltip;
    let overlayLabel;
    if (item.length === 0) {
      return;
    } else if (item.length === 1) {
      if (item[0].length > 15) {
        chipTooltip = (
          <Tooltip id="tooltip">
            {item[0]}
          </Tooltip>
        );
        overlayLabel = `${item[0].substring(0, 11)}...`;
      } else {
        return (
          <div className={styles['chip-container']}>
            {item[0]}
          </div>
        );
      }
    } else {
      chipTooltip = (
        <Tooltip id="tooltip">
          {item.map((arrayItem, arrayIndex) => (
            <React.Fragment key={arrayIndex}>
              {arrayItem}
              {arrayIndex < item.length - 1 && <br />}
            </React.Fragment>
          ))}
        </Tooltip>
      );
      overlayLabel = `${item[0].length > 9 ? `${item[0].substring(0, 8)}...` : item[0]} ${item.length >= 2 ? `+${item.length - 1}` : ""}`;
    }
    return (
      <OverlayTrigger placement="bottom" overlay={chipTooltip}>
        <div style={{ cursor: "pointer" }} className={styles['chip-container']}>
          {overlayLabel}
        </div>
      </OverlayTrigger>
    );
  };

  const handleResize = useCallback((index, width) => {
    setColumnWidths(prevWidths => ({
      ...prevWidths,
      [index]: width
    }));
  }, []);

  const handleRowValueClick = (value, item) => {
    if (value !== rowValueAction || !rowValueAction) {
      return;
    } else {
      onRowValueClick(item);
    }
  };

  const getBackgroundColor = (key, item) => {
    if (React.isValidElement(item[key])) {
      const { props } = item[key];

      // Check and return the data-bgcolor prop if it exists
      return props['data-bgcolor'] || null;
    }
  };

  const checkForBadge = (item) => {
    if (!rowValueAction) {
      return;
    } else {
      return (
        <td>{showBadge(item)}</td>
      );
    }
  };

  return (
    <>
      <div style={{ height: gridHeight, overflowY: "auto" }}>
        <table className={styles['data-table']}>
          <thead>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              {showCheckbox && (
                <th style={{ width: '10px' }}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleHeaderCheckboxChange}
                  />
                </th>
              )}
              {headers.map((header, index) => (
                selectedHeaders.findIndex((selectedHeader) => selectedHeader.label === header.label) !== -1 &&
                (
                  <PerinHealthGridResizable key={index}>
                    {({ ref, styles }) => (
                      <th style={{ width: columnWidths[index] || header.width}}>
                        <div className={styles['resizable-column']} data-index={index} ref={ref}>
                          {header.label}
                            <div className={styles['sorting-icon']}>
                              <div onClick={() => handleSortingChange(header.key, "asc")}>
                                <SortingUpArrowIcon color={(sortField === header.key && sortOrder === "asc") ? "#686869" : "#B9B9B9"} />
                              </div>
                              <div onClick={() => handleSortingChange(header.key, "desc")}>
                                <SortingDownArrowIcon color={(sortField === header.key && sortOrder === "desc") ? "#686869" : "#B9B9B9"} />
                              </div>
                            </div>
                          {index !== headers.length - 1 && (
                            <div className={styles['resizer']}></div>
                          )}
                        </div>
                      </th>
                    )}
                  </PerinHealthGridResizable>
                )
              ))}
              {showEditIcon && <th style={{ width: '20px' }}></th>}
              {showHeaderFilter &&
                <th style={{ width: '5px' }}>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }} onClick={() => setHeaderFilterOpen((prev) => !prev)}>
                      {headerFilterOpen ?
                        <ActiveHeaderFilterIcon />
                        :
                        <DefaultHeaderFilterIcon />
                      }
                    </div>
                    {headerFilterOpen &&
                      <div className={styles.HeaderFilterMenu}>
                        {headers.map((header, index) => (
                          <label className={styles.HeaderFilterItem} key={index}>
                            <input type="checkbox"
                              checked={selectedHeaders.findIndex((selectedHeader) => selectedHeader.label === header.label) !== -1}
                              onChange={() => handleHeaderFilterSelection(header)}
                              style={{ marginRight: "5px" }} />
                            {header.label}
                          </label>
                        ))}
                      </div>
                    }
                  </div>
                </th>}
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={headers.length + (showCheckbox ? 1 : 0) + (showEditIcon ? 1 : 0)}>
                  <div className={styles.empty}>
                    No content found for this user
                  </div>
                </td>
              </tr>
            )}
            {currentItems.map((item, rowIndex) => (
              <tr
                key={`row-${rowIndex}`} // Use rowIndex to ensure each row has a unique key
                className={`${hoveredRow === rowIndex ? styles['hovered-row'] : ''} ${selectedRows.some(row => row.id === rowIndex) ? styles['selected-row'] : ''}`}
                onMouseEnter={() => setHoveredRow(rowIndex)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                {showCheckbox && (
                  <td style={{ width: '20px' }}>
                    <input
                      type="checkbox"
                      checked={selectedRows.findIndex((selectedItem) => selectedItem.id === item.id) !== -1}
                      onChange={() => handleRowCheckboxChange(item)}
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  selectedHeaders.findIndex((selectedHeader) => selectedHeader.label === header.label) !== -1 &&
                  <td key={`cell-${rowIndex}-${colIndex}`} className={styles['grid-cell']} style={{ width: columnWidths[colIndex] || header.width, backgroundColor: getBackgroundColor(header.key, item), 'borderRight': getBackgroundColor(header.key, item) === null ? 'none' : '1px solid white' }}>
                    {iconMapping[item[header.key]] ? (
                      <div className={styles['icon-container']}>
                        {iconMapping[item[header.key]].icon}
                        {iconMapping[item[header.key]].text && (
                          <span>{iconMapping[item[header.key]].text}</span>
                        )}
                      </div>
                    ) : Array.isArray(item[header.key]) ? (
                      useChips ? (
                        // if header is an array and useing chips, show first item as a chip
                        createTooltipChip(item[header.key])
                      ) : (
                        // if header is an array, show each item on a new line
                        item[header.key].map((arrayItem, arrayIndex) => (
                          <React.Fragment key={`arrayItem-${rowIndex}-${colIndex}-${arrayIndex}`}>
                            {arrayItem}
                            {arrayIndex < item[header.key].length - 1 && <br />}
                          </React.Fragment>
                        ))
                      )
                    ) : showProfilePicture === header.key || rowValueAction === header.key ? (
                      <div className={styles['icon-container']}>
                        {/* show profile photo icon next to header value */}
                        {showProfilePicture === header.key &&
                          <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                            {item.profilePictureURL ? (
                              <img
                                src={item.profilePictureURL}
                                alt={`${item.firstName} ${item.lastName}`}
                                style={{
                                  width: '28px',
                                  height: '28px',
                                  borderRadius: '50%',
                                  border: '2px solid grey',
                                  objectFit: 'cover'
                                }}
                              />
                            ) : (
                              <>
                                {item.firstName && item.lastName && 
                                  <span className={styles.GridInitialsIcon}>
                                    {`${item.firstName[0]}${item.lastName[0]}`}
                                  </span>
                                }
                                <BlueProfileIcon width='28' height='28' />
                              </>
                            )}
                          </div>
                        }
                        <div className={rowValueAction === header.key ? styles['row-value-link'] : ''} onClick={() => handleRowValueClick(header.key, item)}>
                          {item[header.key]}
                        </div>
                      </div>
                    ) : (
                      item[header.key]
                    )}
                  </td>
                ))}
                {showEditIcon && (moreEditOptions ?
                  <td style={{ width: '90px', position: "relative" }}>
                    {showEditIcon &&
                      <button
                        ref={item.id === selectedItem.id ? moreEditOptionsButtonRef : allButtonRef}
                        className={styles['more-options-update-button']}
                        disabled={selectedRows.length >= 1}
                        onClick={() => onEditClickHandler(item)}
                      > <EllipsesIcon />
                      </button>
                    }
                    {showOptions &&
                      renderOptionCard(item, moreEditOptionsButtonRef)
                    }
                  </td>
                  :
                  <td style={{ width: '90px', cursor:"pointer"}}> {selectedRows.length <= 0 && showEditIcon && hoveredRow === rowIndex && <button className={styles['update-button']} disabled={selectedRows.length >= 1} onClick={() => onEditClickHandler(item)}>Update</button>}</td>
                )}
                {showBadge &&
                  checkForBadge(item)
                }
                {showHeaderFilter &&
                  <td style={{ width: '5px' }}></td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ paddingTop: '0px' }}>
        <hr />
        <div className={styles.container} style={{ paddingBottom: "10px" }}>
          <span style={{ fontSize: '12px' }}>Total Records: {filteredData.length}</span>
          <div className={styles.pagination}>
            <label style={{ fontSize: '12px', paddingRight: '10px' }}>Show</label>
            <select value={rowPerPage} onChange={handleChangeRowPerPage} style={{ fontSize: '12px', paddingRight: '10px' }}>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={filteredData.length}>All</option>
            </select>
            <label style={{ fontSize: '12px', paddingLeft: '10px' }}>Entries</label>
          </div>
          <div className={styles.pagination}>
            <Button variant="primary" onClick={() => setCurrentPage(1)} disabled={isFirstPage}>
              <FontAwesomeIcon icon="fa-solid fa-backward-fast" size="sm" />
            </Button>
            <Button variant="primary" onClick={() => setCurrentPage(currentPage - 1)} disabled={isFirstPage}>
              <FontAwesomeIcon icon="fa-solid fa-backward" size="sm" />
            </Button>
            <span style={{ fontSize: '14px', paddingLeft: '5px' }}>{currentPage}</span>
            <Button variant="primary" onClick={() => setCurrentPage(currentPage + 1)} disabled={isLastPage}>
              <FontAwesomeIcon icon="fa-solid fa-forward" size="sm" />
            </Button>
            <Button variant="primary" onClick={() => setCurrentPage(totalPages)} disabled={isLastPage}>
              <FontAwesomeIcon icon="fa-solid fa-forward-fast" size="sm" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerinHealthGrid;
