import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Container, Form, Row, FloatingLabel } from 'react-bootstrap';
import styles from '../NewOrganization.module.css';
import managementStyles from '../../ManagementPages.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetNewOrganization, setFormValue } from '../NewOrganizationSlicer';
import { LeftArrowIcon } from '../../../components/icons/Icons';
import useAxios from '../../../services/AxiosConfig';
import FileUploadOrganization from './FileUploadOrganization';
import { showAlert } from '../../../components/PerinHealthAlertSlice';
import { setUserGroups, setOrganizationsHierarchy } from '../../../slicers/UserSlice';

const AddNewOrganization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const { isAdmin, id } = useSelector((state) => state.userInfo);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const newOrganization = useSelector((state) => state.newOrganization);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    const selectedItem = perinHealthGrid.selectedItem;
    const isUpdateMode = newOrganization.isUpdateMode;

    useEffect(() => {
    }, [selectedItem, dispatch]);

    const countryCodes = [
        { value: '+1', label: '+1 (US)' },
        { value: '+44', label: '+44 (UK)' },
    ];

    const handleControlChange = (name, value) => {
        dispatch(setFormValue({ name, value }));
    };


    const onBackHandler = () => {
        navigate('/new-organization');
    };

    const resetUserGroups = () => {
        let groupsApi = isAdmin ? "admin/groups" : `list/groups/${id}`;
        httpService.get(groupsApi)
        .then((responseData) => {
            if (responseData.data.data) {
                dispatch(setUserGroups(responseData.data.data));
                dispatch(setOrganizationsHierarchy(responseData.data.data));
            } else {
                console.error('error');
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const OnAddNewOrganization = () => {
        const formData = formatDataForSaving();
    
        // Create a new FormData object
        const formDataWithFile = new FormData();
    
        // Append JSON data
        formDataWithFile.append('data', JSON.stringify(formData));
    
        // Append the file
        if (selectedFile) {
            formDataWithFile.append('file', selectedFile);
        }
    
        const endpoint = isUpdateMode ? `organization/${formData.id}` : 'organization';
        httpService({
            method: isUpdateMode ? 'put' : 'post',
            url: endpoint,
            data: formDataWithFile,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            console.log(`Organization ${isUpdateMode ? 'updated' : 'added'} successfully:`, response.data);
            dispatch(resetNewOrganization());
            resetUserGroups();
            navigate('/new-organization');
            dispatch(
                showAlert({
                    header: "Success",
                    message: `Organization ${isUpdateMode ? 'updated' : 'added'} successfully:`,
                    type: 'success'
                })
            );
        })
        .catch((error) => {
            console.error(`Error ${isUpdateMode ? 'updating' : 'adding'} Organization:`, error);
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Error ${isUpdateMode ? 'updating' : 'adding'} Organization`,
                    type: 'danger'
                })
            );
        });
    };

    const formatDataForSaving = () => {
        return removeUndefined(newOrganization);
    };

    const removeUndefined = (obj) => {
        const excludedAttributes = ['isUpdateMode', 'logoId', 'selectAll', 'teamName', 'subGroupName', 'selectedRows', 'reloadGrid', 'organizationStatus', 'logoURL', 'logoId', 'groupName'];
        if (!isUpdateMode) {
            excludedAttributes.push('id');
        }

        const cleanedData = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                if (!excludedAttributes.includes(key) && value !== undefined) {
                    return [key, value];
                }
                return null;
            }).filter(entry => entry !== null)
        );

        return cleanedData;
    };

    const handleLogoDrop = (acceptedFiles) => {
        const logoFile = acceptedFiles[0];
        handleControlChange('logoId', "i123");

        if (logoFile) {
            setSelectedFile(logoFile);
            const objectURL = URL.createObjectURL(logoFile);
            handleControlChange('logoURL', objectURL);
        }
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Add New Organization</span>
            </div>
            <div className={managementStyles.MainCard}>
                <Container className={managementStyles.Container}>
                    <h3 className={managementStyles.ContentTitle}>{isUpdateMode ? 'Update ' : 'Add '} Organization</h3>
                    <Form className={styles.FormContainer}>
                        <Form.Group className="mb-3">
                            <FloatingLabel 
                                controlId="floatingInput"
                                label="Organization Name*"
                                className="mb-3"
                            >
                            <Form.Control
                                type="text"
                                placeholder="name"
                                value={newOrganization.name}
                                className={`${managementStyles.BorderedComponent}`}
                                onChange={(e) => handleControlChange('name', e.target.value)}
                            />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <FloatingLabel 
                                controlId="floatingInput"
                                label="Email*"
                                className="mb-3"
                            >
                            <Form.Control
                                type="text"
                                placeholder="email"
                                value={newOrganization.email}
                                className={`${managementStyles.BorderedComponent}`}
                                onChange={(e) => handleControlChange('email', e.target.value)}
                            />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group style={{width:"300px"}} className="mb-3">
                            <Row>
                                <Col xs={4}>
                                    <FloatingLabel controlId="floatingSelect" label="Country Code">
                                    <Form.Select
                                        style={{width:"100px"}}
                                        name="code"
                                        value={countryCodes.find(
                                            (option) => option.value === newOrganization.countryCode
                                        )}
                                        onChange={(selectedOption) =>
                                            handleControlChange('countryCode', selectedOption.value)
                                        }
                                        className={`${managementStyles.BorderedComponent}`}
                                    >
                                        <option value="">Select</option>
                                        {countryCodes.map((countryCode) =>
                                            <option key={countryCode.value} value={countryCode.value}>
                                                {countryCode.label}
                                            </option>
                                        )}
                                    </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={8}>
                                    <FloatingLabel controlId="floatingSelect" label="Phone Number*">
                                    <Form.Control
                                        style={{width:"100%"}}
                                        type="phone"
                                        placeholder={''}
                                        value={newOrganization.phone}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={(e) =>
                                            handleControlChange('phone', e.target.value)
                                        }
                                    />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label className="headline-6">Organization Label</Form.Label>
                            <FloatingLabel controlId="floatingSelect" label="Group Name*">
                            <Form.Control
                                placeholder="Group Name"
                                value={newOrganization.groupName}
                                className={`${managementStyles.BorderedComponent}`}
                                onChange={(e) => {
                                    handleControlChange('groupName', e.target.value);
                                }}
                            />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="floatingSelect" label="Subgroup Name*">
                            <Form.Control
                                placeholder="Subgroup Name"
                                type="text"
                                value={newOrganization.subGroupName}
                                className={`${managementStyles.BorderedComponent}`}
                                onChange={(e) => handleControlChange('subGroupName', e.target.value)}
                            />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="floatingSelect" label="Team Name*">
                            <Form.Control
                                placeholder="Team Name"
                                type="text"
                                value={newOrganization.teamName}
                                className={`${managementStyles.BorderedComponent}`}
                                onChange={(e) => handleControlChange('teamName', e.target.value)}
                            />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{width:"300px"}}>
                            <Form.Label className="headline-6">Upload Logo</Form.Label>
                            <FileUploadOrganization
                                fileType='image'
                                onDrop={handleLogoDrop}
                                imageURL={newOrganization.logoURL} 
                                acceptedTypes={"PNG,JPG | 8MB Max"}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label className="headline-6" as="legend" column sm={6}>
                                Organization Status
                            </Form.Label>
                                <Form.Group>
                                    <Form.Check
                                        className={styles.FormCheck}
                                        type="switch"
                                        label="Active"
                                        checked={newOrganization.organizationStatus}
                                        onChange={(e) => handleControlChange('organizationStatus', e.target.checked)}
                                    />
                                </Form.Group>
                        </Form.Group>
                    </Form>
                </Container>
                <div className={styles.Footer}>
                    <Row>
                        <Col>
                            <Button variant="primary" className={managementStyles.CreateButton} onClick={OnAddNewOrganization}>
                                {isUpdateMode ? 'Update ' : 'Add '}
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" className={managementStyles.BackButton} onClick={onBackHandler}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default AddNewOrganization;
