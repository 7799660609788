import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  header: 'Default Header',
  message: 'Default Message',
  buttons: [
    {
      label: 'Close',
      variant: 'secondary',
      onClickKey: 'close',
    },
  ],
};

const PerinHealthModalSlice = createSlice({
  name: 'perinHealthModal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { header, message, buttons } = action.payload;
      return {
        ...state,
        show: true,
        header,
        message,
        buttons,
      };
    },
    closeModal: (state) => {
      return {
        ...state,
        show: false,
      };
    },
  },
});

export const { openModal, closeModal, setInputValue } = PerinHealthModalSlice.actions;
export default PerinHealthModalSlice.reducer;
