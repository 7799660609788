import React, { useState } from 'react';
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange, setReloadTaskList, setSelectAll, setSelectedRows } from './TasksSlice';
import './DateRangePicker.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import useAxios from '../../services/AxiosConfig';
import DateUtils from '../../components/DateUtils';

const DateTimePicker = ({ show, onClose }) => {
  const [dueDate, setDueDate] = useState(null);
  const dispatch = useDispatch();
  const {
    selectedItem,
  } = useSelector((state) => state.taskListPanel);
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const axios = useAxios(axiosConfig.envURL);

  const handleSave = async () => {
    if (dueDate) {
      const dueDateString = DateUtils.toUTCString(dueDate)
      const payload = {
        accountId: selectedItem.accountId,
        staffTaskId: selectedItem.staffTaskId,
        dueDate: dueDateString,
      };

      try {
        const response = await axios.post('/staffTasks/updateDueDate', payload);
        if (response.data.data) {
          dispatch(setSelectedRows([]));
          dispatch(setSelectAll(false));
          onClose();
          dispatch(setReloadTaskList(true));
          dispatch(
            showAlert({
              header: "Success",
              message: "Successfully Updated Due Date",
              type: 'success',
            })
          );
        }
      } catch (err) {
        onClose();
        dispatch(
          showAlert({
            header: "Error",
            message: `Could not Update Due Date: ${err}`,
            type: 'danger',
          })
        );
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="md"
      backdrop={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Reminder Date</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '320px' }}>
        <Form.Group className='ms-3'>
          <Form.Label className='me-3'>Due Date:  </Form.Label>
          <DatePicker
            selected={dueDate}
            onChange={date => setDueDate(date)}
            showTimeSelect
            dateFormat="MM/dd/yyyy h:mm aa"
            isClearable
            timeIntervals={15}
            open={true} // Corrected to a boolean value
            className='due-date-picker'
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateTimePicker;
