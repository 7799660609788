import React, {useState, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import styles from './ManagementDropdownCard.module.css';
import PerinPatchModalityTypeSection from './PerinPatchModalityTypeSection';

const PerinPatchSetupCard = (props) => {
    const {
        id, 
        modalities,
        setModality,
        modalityState, 
        errorShowing, 
        disabled, 
        viewOnly, 
        allDevices
    } = props;

    const dispatch = useDispatch();

    const groupedModalities = Object.values(modalities.reduce((acc, modality) => {
        const key = `${modality.modalityType}|${modality.modalityTypeDisplayName}`;
        if (!acc[key]) {
            acc[key] = {
                id: modality.modalityType,
                name: modality.modalityTypeDisplayName,
                modalities: []
            };
        }
        acc[key].modalities.push(modality);
        return acc;
    }, {}));

    const initAllSameStartTime = () => {
        const filteredVitals = modalityState.filter(vital => vital.deviceId === 1 && vital.startTime);
        if (filteredVitals.length === 0) {
            return true;
        };
        const firstStartTime = filteredVitals[0].startTime;
        return filteredVitals.every(vital => vital.startTime === firstStartTime);
    };

    const [allSameStartTime, setAllSameStartTime] = useState(initAllSameStartTime());
    const [selectedModalityType, setSelectedModalityType] = useState(groupedModalities[0] ?? 0);
    const [selectedVital, setSelectedVital] = useState(groupedModalities[0].modalities[0] ?? 0);

    // const data = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === selectedModalityType.modalities[0].deviceModalityId));
    const data = useMemo(() => {
        return modalityState.find((item) => 
            item.deviceId === id && 
            item.deviceModalityId === selectedVital.deviceModalityId
        );
    }, [selectedVital, modalityState, id]);

    const updateStartTime = (name, value, startTimeView) => {
        // Calculate ISO string and dispatch action
        const isoString = convertStartTime(
            name === "startInt" ? value : startTimeView.startInt,
            name === "interval" ? value : startTimeView.interval
        );

        if (allSameStartTime) {
            groupedModalities.forEach(modalityType =>
                modalityType.modalities.forEach( modality => {
                    const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
                    let tempData = {...currentVitalData};
                    tempData['startTime'] = isoString;
                    dispatch(setModality(tempData));
                })
            )
        } else {
            selectedModalityType.modalities.forEach((modality)=> {
                const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
                let tempData = {...currentVitalData};
                tempData['startTime'] = isoString;
                dispatch(setModality(tempData));
            });
        }
    };

    const convertStartTime = (hour12, period) => {
        let hour24 = hour12;
        if (hour12 === -1) {
            return "";
        }
        if (period === 'PM' && hour12 !== 12) {
            hour24 += 12; // Add 12 hours for PM
        } else if (period === 'AM' && hour12===12){
            hour24 = 0;
        }
        // Construct the ISO string
        const currentDate = new Date();
        currentDate.setHours(hour24,0,0,0);
        let utcTimeStamp = "";
        try {
            utcTimeStamp = currentDate.toISOString();
        } catch (error) {
            console.error(error);
        }
        return utcTimeStamp;
    };

    const handleContinuousChange = (newContinuous) => {
        
        selectedModalityType.modalities.forEach((modality)=> {
            const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
            let tempData = {...currentVitalData};
            tempData['isContinuous']= newContinuous;
            if (newContinuous) {
                // set frequency and interval to 0 to reset if continuous is checked
                tempData['frequency']=0;
                tempData['interval']='';
            }
            dispatch(setModality(tempData));
        });
    };

    const handleFrequency = (e) => {
        selectedModalityType.modalities.forEach((modality)=> {
            const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
            let tempData = {...currentVitalData};
            tempData['frequency'] = Number(e.target.value);
            if (data["interval"] === "") {
                tempData['interval'] = "Day";
            };
            dispatch(setModality(tempData));
        });
    };

    const handleIntervalChange = (e) => {
        selectedModalityType.modalities.forEach((modality)=> {
            const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
            let tempData = {...currentVitalData};
            if (e.target.value === "Min") {
                if (data.frequency % 5 !== 0 && data.frequency >= 60) {
                    tempData["frequency"] = 5;
                }
            }
            tempData['interval'] =e.target.value;
            dispatch(setModality(tempData));
        });
    };

    const handleModalityTypeChange = (modalityType) => {
        setSelectedModalityType(modalityType);
        setSelectedVital(modalityType.modalities[0]);
    };

    const handleVitalChange = (vital) => {
        setSelectedVital(vital);
    };

    const handleAllSameStartTime = () => {
        let newValue = !allSameStartTime;
        setAllSameStartTime(newValue);
        if (newValue) {
            const currentStartTime = data.startTime;
            groupedModalities.forEach(modalityType => {
                modalityType.modalities.forEach( modality => {
                    const currentVitalData = modalityState.find((item)=> (item.deviceId === id && item.deviceModalityId === modality.deviceModalityId));
                    let tempData = {...currentVitalData};
                    tempData['startTime'] = currentStartTime;
                    dispatch(setModality(tempData));
                })
            })
        }
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"100%", height:"100%"}}>
            <div className={styles.ModalitiesList}>
                {groupedModalities.map((item) => (
                    <div 
                        key={item.id} 
                        onClick={() => handleModalityTypeChange(item)} 
                        className={selectedModalityType.id===item.id ? styles.ModalityLabelActive : styles.ModalityLabel}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
            <div style={{display:"flex", flexDirection:"column", gap:"10px", padding:"20px 0 20px 20px"}}>
                {groupedModalities.map((item, index) => (
                    <div key={index} hidden={item.id===selectedModalityType.id ? false : true}>
                        <PerinPatchModalityTypeSection 
                            modalityVitals={item.modalities}
                            selectedVital={selectedVital}
                            handleChange={handleVitalChange}
                            updateStartTime={updateStartTime}
                            handleContinuousChange={handleContinuousChange}
                            handleFrequency={handleFrequency}
                            handleIntervalChange={handleIntervalChange}
                            viewOnly={viewOnly}
                            errorShowing={errorShowing}
                            disabled={disabled}
                            allDevices={allDevices} //all devices available to user
                            modalityState={modalityState} // current modalities in enrollment/program
                            setModality={setModality}
                            allSameStartTime={allSameStartTime}
                            handleAllSameStartTime={handleAllSameStartTime}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PerinPatchSetupCard;