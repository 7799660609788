import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Col, Row, Container, FloatingLabel  } from 'react-bootstrap';
import styles from "./UserOptions.module.css";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../services/AxiosConfig";
import managementStyles from '../ManagementPages.module.css';
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import { showAlert } from "../../components/PerinHealthAlertSlice";
import { setUser } from "../../slicers/UserSlice";
import Select from 'react-select';
import { customStyles } from "../../components/AccountManagement/CustomStyles";

const PreferencesModal = ({ show, handleClose }) => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const userData = useSelector((state) => state.userInfo);
    const userAccountId = userData.id;

    const initCurrentTimezone = () => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone,
            hour12: false,
            timeZoneName: 'shortOffset'
        });

        const parts = formatter.formatToParts(new Date());
        const offsetPart = parts.find(part => part.type === 'timeZoneName').value;
        const label = `(${offsetPart}) ${timeZone}`;
        return {
            value: timeZone,
            label: label
        };
    };

    const [dateFormat, setDateFormat] = useState(userData.dateFormat ?? '');
    const [unitType, setUnitType] = useState(userData.unitType ?? '');
    const currentTimeZone = initCurrentTimezone();
    const [timeZone, setTimeZone] = useState(userData.timeZone === "" ? currentTimeZone : userData.timeZone);


    const saveDisabled = dateFormat === userData.dateFormat && unitType === userData.unitType && timeZone === userData.timeZone;


    function getTimeZonesWithOffsetAndLabel() {
        const timeZones = Intl.supportedValuesOf('timeZone');
    
        return timeZones.map(timeZone => {
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone,
                hour12: false,
                timeZoneName: 'shortOffset'
            });
    
            const parts = formatter.formatToParts(new Date());
            const offsetPart = parts.find(part => part.type === 'timeZoneName').value;
            const label = `(${offsetPart}) ${timeZone}`;
    
            return {
                value: timeZone,
                label: label
            };
        });
    }

    const timeZones = getTimeZonesWithOffsetAndLabel();

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("dateFormat", dateFormat);
        formData.append("unitType", unitType);
        formData.append("timeZone", timeZone.value);

        const method = "PUT";
        const url = `/user/profile/${userAccountId}?updateType=preferences`;
        
        httpService
          .request({
            method,
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            dispatch(setUser(response.data.data));
            handleClose();
          })
          .catch((error) => {
            dispatch(
              showAlert({
                header: "Error",
                message: `Error Updating Account Information`,
                type: "danger",
              })
            );
            console.error("Error saving data:", error);
          });
          
      };

      const handleTimeZone = (timeZone) => {
          setTimeZone({"label":timeZone.label, "value":timeZone.value});
      }

    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName="preference-modal">
            <Modal.Header closeButton>
                <Modal.Title>Preferences</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleSubmit} style={{maxHeight:"50vh", display:"flex", flexDirection:"column", gap:"12px"}}>
                <div style={{height:"90%", overflowX:"hidden", overflowY:"scroll", display:"flex", flexDirection:"column", gap:"12px", padding: "0 0 30px 10px"}}>
                    <span className={styles.formHeader}>Date Format </span>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>   
                        <div style={{position: "relative"}}>
                            <span className={styles.floatingLabel} style={{zIndex:"1"}}>Date Format</span>
                                <select
                                    style={{ width: "300px" }}
                                    className={styles.styledInputSelect}
                                    onChange={(e) => setDateFormat(e.target.value)}
                                    value={dateFormat}
                                >
                                    {DropdownData.dateFormats.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </select>
                        </div>
                    </div>
                    <span className={styles.formHeader}>Unit Type</span>
                    <div>
                    <input
                        id="unitType" 
                        type="radio"
                        value="Imperial"
                        checked={unitType === 'Imperial'}
                        onChange={() => setUnitType("Imperial")}
                    /> 
                    <span style={{paddingLeft: "5px"}}>Imperial (lbs, F)</span>
                </div>
                <div>
                    <input
                        id="unitType" 
                        type="radio"
                        value="Metric"
                        checked={unitType === 'Metric'}
                        onChange={() => setUnitType("Metric")}
                    /> 

                    <span style={{paddingLeft: "5px"}}>Metric (kg, C)</span>
                </div>
                <span className={styles.formHeader}>Time Zone</span>
                    <div style={{position: "relative", width:"300px"}}>
                        <span className={"floating-label"} style={{zIndex:"1"}}>Time Zone</span>
                            <Select
                                options={timeZones}
                                styles={customStyles.popup}
                                value={timeZone}
                                placeholder=''
                                isSearchable={true}
                                onChange={handleTimeZone}
                                menuPortalTarget={document.querySelector('.preference-modal')}
                                menuPosition="fixed"
                            />
                    </div>
                </div>                
             <Form.Group as={Row} style={{paddingLeft:"10px", display:"flex", columnGap:"10px"}}>
                <Button
                    className={managementStyles.BackButton}
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    className={managementStyles.CreateButton}
                    variant="secondary"
                    type="submit"
                    disabled={saveDisabled}
                >
                    Save
                </Button>
                </Form.Group>
            </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PreferencesModal;
