import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import inputStyles from '../program-management/ProgramManagement.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setQuestionnaire, removeQuestionnaire, setFormValue } from './PatientEnrollmentSlice';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import styles from '../program-management/ProgramManagement.module.css';

const QuestionnaireEnrollment = ({errors}) => {

    const questionnaires = useSelector((state)=>state.enrollmentLists.questionnaires);
    const questionnaireNotes = useSelector((state)=>state.enrollPatientProgram.questionnaireNotes);
    const dispatch = useDispatch();
    const questionnaireList = useSelector((state)=>state.enrollPatientProgram.questionnaires);
    const [count, setCount] = useState(0);
    const {reenrolling, viewing} = useSelector((state)=>state.enrollPatientProgram);

    const initSelectedQuestionnaires = () => {
        // check to see if questionnaire already exists in the state,
        const tempQs = [];
        questionnaireList.forEach((item)=> {
            let existingQIndex = questionnaires.findIndex(q => q.questionnaireId === item.questionnaireId);
            if (existingQIndex !== -1){
                // if addProgram.questionnaire has a match in the questionnaire list, add to selectedQuestionnaires
                tempQs.push(questionnaires[existingQIndex]);
            } else {
                tempQs.push({
                    id: item.questionnaireId,
                    name: item.questionnaireName
                })
            }
        })
        return tempQs;
    };

    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState(initSelectedQuestionnaires());
    const checkSelectedItem = (id) => {
        let qInList = false;
        selectedQuestionnaires.forEach((item)=>{
            if (item.questionnaireId===id){
                qInList=true;
            } 
        });
        return qInList;
    };

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const handleQuestionnaireChange = (q) => {
        let qInList = false;
        selectedQuestionnaires.forEach((item)=>{
            if (item.questionnaireId===q.questionnaireId){
                qInList = true;
            } 
        });
        if (qInList){
            setSelectedQuestionnaires(selectedQuestionnaires.filter(item => item.questionnaireId !== q.questionnaireId));
            dispatch(removeQuestionnaire(q.questionnaireId))
        } else {
            setSelectedQuestionnaires([q, ...selectedQuestionnaires]);
            let id = q.questionnaireId;
            let questionnaireName = q.name;
            dispatch(setQuestionnaire(
                {
                    id, 
                    questionnaireName,
                    startTime: getInitialStartTime(),
                    frequency: 1,
                    interval: "Day"
                }
            ))
        }
    };

    const removeQuestionnaireCard = (id) => {
        setSelectedQuestionnaires(selectedQuestionnaires.filter(item => item.questionnaireId !== id));
        dispatch(removeQuestionnaire(id));
    };

    const handleNotes = (e) => {
        setCount(e.target.value.length);
        dispatch(setFormValue({key:"questionnaireNotes",value:e.target.value}));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <MultiSelectDropdown 
                options={questionnaires}
                label='name'
                checkedKey='questionnaireId'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleQuestionnaireChange}
                selectedItems={selectedQuestionnaires}
                name='Questionnaires'
                disabled={reenrolling || viewing}
            />
            {errors.showing && 
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span className={styles.ValidationMessage}>
                        {errors.message}
                    </span>
                </div>
            }
            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                {selectedQuestionnaires.map((q, index) => (
                    <div key={q.questionnaireId}>
                        <ManagementDropdownCard 
                            open={index!==0 ? false : true} 
                            contentInfo={{title:q.name, id:q.questionnaireId}}
                            cardType={"Questionnaire"} 
                            updateHandler={setQuestionnaire}
                            removeHandler={removeQuestionnaireCard}
                            dataList={questionnaireList}
                            errorShowing={errors.showing}
                            disabled={reenrolling || viewing}
                        />
                    </div>
                ))}
                {selectedQuestionnaires.length >= 1 &&
                    <div style={{position: "relative", display:"flex", flexDirection:"column", width:"300px", paddingTop:"20px"}}>
                        <span className={inputStyles.FloatingLabel}>Notes</span>
                        <textarea className={inputStyles.NoteInput} 
                            id="description"
                            type="text"
                            maxLength={250}
                            disabled={reenrolling || viewing}
                            defaultValue={questionnaireNotes}
                            onChange={e => handleNotes(e)}
                        >
                        </textarea>
                        <span style={{fontSize:"10px", color:"#929395", marginLeft:"auto"}}>
                            {count}/250
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default QuestionnaireEnrollment;