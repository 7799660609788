import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styles from '../OrganizationManagement.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../../components/PerinHealthGridSlice';
import { LeftArrowIcon } from '../../../components/icons/Icons';
import EditOrganizationHierarchyComponent from './EditOrganizationHeirarchyComponent';
import useAxios from '../../../services/AxiosConfig';
import managementStyles from '../../ManagementPages.module.css';

const AddOrganizationMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const [showGroupRows, setShowGroupRows] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [existingData, setExistingData] = useState('');
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const assignedGroup = useSelector((state) => state.userInfo.groups);

    const selectedItem = useSelector(
        (state) => state.perinHealthGrid.selectedItem || {}
    );


    useEffect(() => {
        if (!isAdmin && assignedGroup.length > 0) {
            httpService.get('organization/' + assignedGroup[0].organizationId.toString() + '/group/subgroups/teams')
            .then((responseData) => {
                setExistingData(responseData.data.data);
                setSelectedGroupId(responseData.data.data.organizationId)
                setSelectedGroupName(responseData.data.data.organizationName)
            })
            .catch((err) => {
                console.log(err);
            }); 
        }

        if(selectedItem.organizationId > 0 ){
            httpService.get('organization/' + selectedItem.organizationId + '/group/subgroups/teams')
            .then((responseData) => {
                setExistingData(responseData.data.data);
                setSelectedGroupId(responseData.data.data.organizationId)
                setSelectedGroupName(responseData.data.data.organizationName)
            })
            .catch((err) => {
                console.log(err);
            }); 
        }
    }, [isAdmin, assignedGroup, selectedItem]);

    const onBackHandler = () => {
        dispatch(setSelectedItem({}));
        navigate('/organization-management');
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Organization Management</span>
            </div>
            <div className={managementStyles.MainCard}>
                <Container className={managementStyles.Container} style={{margin:"0px 5px"}}>
                    <div className={managementStyles.ContentTitle}>Organization Hierarchy Management</div>
                        <div className={styles.OrgSelect}>
                        <label className={styles.SelectLabel}>Organization Name</label>         
                            <div className={styles.GroupName}>{selectedGroupName} </div>                       
                    </div>
                    <br />
                    <div style={{height:"78%"}}>
                        <EditOrganizationHierarchyComponent existingData={existingData} selectedGroupId={selectedGroupId} isAdmin={isAdmin} organizationName={selectedGroupName}/>
                    </div>
                </Container>
            </div>
        </div >
    );
};

export default AddOrganizationMain;
