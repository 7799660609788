import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/style.css'
import Main from './views/Main';
import Login from './views/Login';
import ActivateAccount from './views/ActivateAccount';
import SelectOrganization from './views/account-activation/SelectOrganization';
import ForgotPassword from './views/forgot-password/ForgotPassword';
import { WebSocketProvider } from './services/webSocket/webSocketContext';
import ProtectedRoute from './views/ProtectedRoute';

const App = () => {
    const axiosConfig = useSelector(state => state.axiosConfig);
    const userRole = useSelector((state) => state.userInfo.roleCategory);
    const userId = useSelector((state) => state.userInfo.id);
    const baseURL = axiosConfig.envURL.replace(/^http(s)?:/, 'ws$1:').replace(/\/$/, '');

    return (
        <Router>
            <Routes>
                <Route
                    path="*"
                    element={
                        <ProtectedRoute>
                        <WebSocketProvider baseURL={baseURL} userId={userId} userRole={userRole}>
                            <Main />
                        </WebSocketProvider>
                        </ProtectedRoute>
                    }
                />
                <Route path="/" element={<Login />} />
                <Route path="/activate" element={<ActivateAccount />} />
                <Route path="/select-organization" element={<SelectOrganization />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
        </Router>
    );
};

export default App;
