import React, { useState } from 'react';
import styles from '../Patient.module.css';
import PatientDetailDropdownCard from '../../../components/PatientDetailDropdownCard';
import { HealthInfoIcon, EmergencyContactIcon, GroupInfoIcon, PersonalInfoIcon } from '../../../components/icons/Icons';
import HealthInfoDetail from '../CardDetails/PatientDetailCards/HealthInfoDetail';
import PersonalInfoDetail from '../CardDetails/PatientDetailCards/PersonalInfoDetail';
import EmergencyContactDetail from '../CardDetails/PatientDetailCards/EmergencyContactDetail';
import GroupInfoDetail from '../CardDetails/PatientDetailCards/GroupInfoDetail';
import { openModal, closeModal } from '../../../components/PerinHealthModelSlice';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthModal from '../../../components/PerinHealthModel';
import useAxios from '../../../services/AxiosConfig';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const DetailsView= () => {

    const patient = useSelector((state)=>state.patient);
    const dispatch = useDispatch();
    const perinHealthModal = useSelector((state)=>state.perinHealthModal);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);

    const [ updateFn, setUpdateFn] = useState(null);
    const [ cancelFn, setCancelFn] = useState(null);
    const [ updatingState, setUpdatingState] = useState({});

    const setModal = ({title, message, data}) => {
        setUpdatingState(data);
        dispatch(
            openModal({
                header: title,
                message: message,
                buttons: [
                    { label: 'Update', variant: 'primary', onClickKey: 'update' },
                    { label: 'Cancel', variant: 'secondary', onClickKey: 'cancel' },
                ],
            })
        );
    };

    const handleModalButtons = async (onClickKey) => {
        dispatch(closeModal());
        if (onClickKey === "update" && updatingState) {
            try {
                const response = await axios.request({
                    method: 'PUT',
                    headers: {"Content-Type": "application/json"},
                    url: `user/${updatingState.id}`,
                    data: JSON.stringify(updatingState),
                });
                if (response) {
                    dispatch(
                        showAlert({
                            header: "Success",
                            message: "Updated account successfully",
                            type: 'success',
                        })
                    );
                    updateFn();
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || "Server Error";
                // cancelFn();
                dispatch(
                    showAlert({
                        header: "Error",
                        message: errorMessage,
                        type: 'danger',
                    })
                );
            }
        } else {
            cancelFn();
        }
    };

    const cards = [
        {title:"Personal Info", icon: <PersonalInfoIcon />, detail: <PersonalInfoDetail setModal={setModal} setUpdateFn={setUpdateFn} setCancelFn={setCancelFn}/>},
        {title:"Group Info", icon: <GroupInfoIcon />, detail: <GroupInfoDetail setModal={setModal} setUpdateFn={setUpdateFn} setCancelFn={setCancelFn}/>},
        {title:"Health Info", icon: <HealthInfoIcon />, detail: <HealthInfoDetail setModal={setModal} setUpdateFn={setUpdateFn} setCancelFn={setCancelFn}/>},
        {title:"Emergency Contact", icon: <EmergencyContactIcon />, detail: <EmergencyContactDetail setModal={setModal} setUpdateFn={setUpdateFn} setCancelFn={setCancelFn}/>},
    ];

    return (
        <div className={styles.ViewContainer}>
           {cards.map((card, index) => {
                return (
                    <div key={index}>
                        <PatientDetailDropdownCard 
                            page={"Patient Detail"} 
                            title={card.title} 
                            icon={card.icon}
                            cardHeight={"auto"}
                            cardInfo={card.detail}
                        />
                    </div>
                )
            })
            }
            {perinHealthModal.show && (
                <PerinHealthModal
                    show={perinHealthModal.show}
                    header={perinHealthModal.header}
                    message={perinHealthModal.message}
                    buttons={perinHealthModal.buttons}
                    handleButtonClick={handleModalButtons}
                />
            )}
        </div>
    );
}

export default DetailsView;