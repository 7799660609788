import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newNoteText: '',
    meetingId: '',
    title: '',
    notes: [],
    draftId: '',
    updatingAttachments: []
};

const patientDetailNotesSlice = createSlice({
  name: 'patientDetailNotes',
  initialState,
  reducers: {
    resetNotesState: (state) => {
      Object.assign(state, initialState);
    },
    setNoteTitle: (state, action) => {
        const {title, meetingId} = action.payload;
        state.title = title;
        state.meetingId = meetingId;
    },
    setNewNote: (state, action) => {
        state.newNoteText = action.payload;
    },
    addNote: (state, action) => {
        const { title, note, date, doctor } = action.payload; 
        const updatedNotes = [...state.notes,{title:title || "Doctor Notes", doctor:doctor, submissionDate:date, notes:note}];
        state.notes = updatedNotes;
        state.newNoteText = "";
        state.meetingId = '';
        state.title = '';
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    editDraft: (state, action) => {
      const { noteId, notes, title, attachmentUrls } = action.payload; 
      state.draftId = noteId;
      state.newNoteText = notes;
      state.title = title;
      state.updatingAttachments = attachmentUrls
    },
    clearNote: (state) => {
      state.draftId = '';
      state.newNoteText = '';
      state.title = '';
      state.meetingId = '';
    },
  },
});

export const {
    resetNotesState,
    setNewNote,
    addNote,
    setNotes,
    setNoteTitle,
    editDraft,
    clearNote
} = patientDetailNotesSlice.actions;

export default patientDetailNotesSlice.reducer;
