import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from '../HubManagement.module.css';
import { XOut, OnlineIcon, OfflineIcon } from "../../../components/icons/Icons";
import XOutIcon from '../../../assets/hubIcon.svg'


const PatientHubInfoPopup = ({onClose, data}) => {

const statusIcons = {
    'Online': { icon: <OnlineIcon />, text: 'Online' },
    'Offline': { icon: <OfflineIcon />, text: 'Offline' },
};

const effectiveStatuses = data.allStatuses && data.allStatuses.length > 0 ? data.allStatuses : [data.status || 'Offline'];


    return ReactDOM.createPortal(
        <div className="overlay">
            <div className={styles.PopupContainer} style={{ width: "516px", height: "755px" }}>
                <div onClick={onClose} className="close-out-button">
                    <XOut onClick={onClose}/>
                </div>
                <span className="headline-4">{`Hub - ${data.assignedTo || 'No assignee'}`}</span>
                <span className="subtitle-2" style={{ color: "#4C5793" }}>{`Serial Number: ${data.serialNumber || 'N/A'}`}</span>
                
                <div style={{ height: "400px" }}>
                    <img src={XOutIcon} alt="Close" />
                </div>
                
                <div className={styles.PopupListItems}>
                    <div>
                        <span className="subtitle-2">Hub Status:</span>
                        {effectiveStatuses.map((status, index) => (
                            <React.Fragment key={index}>
                                {statusIcons[status]?.icon}
                                <span className="body-2">{status || 'Offline'}</span>
                            </React.Fragment>
                        ))}
                    </div>
                    <div>
                        <span className="subtitle-2">Last Data Transmission Date:</span>
                        <span className="body-2">{data.lastTransmissionDate}</span>
                    </div>
                    <div>
                        <span className="subtitle-2">Last Data Transmission Time:</span>
                        <span className="body-2">{data.lastTransmissionTime}</span>
                    </div>
                    <div>
                        <span className="subtitle-2">Last Connection:</span>
                        <span className="body-2">{data.lastConnection}</span>
                    </div>
                    <div>
                        <span className="subtitle-2">Provider Name:</span>
                        <span className="body-2">{data.providerName || 'N/A'}</span>
                    </div>
                    <div>
                        <span className="subtitle-2">eID:</span>
                        <span className="body-2">{data.eID || 'N/A'}</span>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
}

export default PatientHubInfoPopup;