import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../components/ProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import PatientProgramSetup from './PatientProgramSetup';
import DeviceEnrollment from './DeviceEnrollment';
import QuestionnaireEnrollment from './QuestionnaireEnrollment';
import ContentEnrollment from './ContentEnrollment';
import EnrollmentRules from './EnrollmentRules';
import { setModalitiesState } from './PatientModalitiesSlice';
import { resetProgramState, setGroups } from './PatientEnrollmentSlice';
import useAxios from '../../services/AxiosConfig';
import styles from '../ManagementPages.module.css';
import containerStyles from '../program-management/ProgramManagement.module.css';
import { LeftArrowIcon } from '../../components/icons/Icons';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { setFormValue } from './EnrollmentListSlicer';
import ReviewEnrollment from './ReviewEnrollment';

const EnrollPatient = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const data = useSelector((state)=>state.enrollPatientProgram);
    const updatingProgram = data.updating;
    const addingProgram = data.adding;
    const reenrollingProgram = data.reenrolling;
    const { modalities, notifications, modalityFrequencies } = useSelector((state)=>state.patientEnrollmentModalities);
    const templateModalities = data.templateModalities;
    const userId = useSelector((state)=>state.userInfo.id);
    const patientId = useSelector((state) => state.enrollPatientProgram.patient.id);
    const savedModalityValues = useSelector((state) => state.savedPatientModalities.patientRecentModalities[patientId]) ?? {};
    const patientEnrollmentsList = useSelector((state)=>state.enrollmentLists.previousEnrollments);

    useEffect(() => {
        fetchData();
    }, []);

    const formatPatientsDropdown = (patientData) => {
        let uniqueIds = new Set();
        const formatted = [];
        patientData.forEach((patient)=> {
            if (!uniqueIds.has(patient.id)) {
                uniqueIds.add(patient.id);
                formatted.push({
                    label: patient.firstName +  ' ' + patient.lastName, 
                    value:{firstName: patient.firstName, lastName:patient.lastName, id:patient.id, groups:patient.groups}
                })
            }
        });
        return formatted;
    };

    const formatPrograms = (programData) => {
        const formatted = programData.map((program)=> ({
            label: program.name,
            value: program
        }));
        const addingNewProgram = {label:"Create New Program", value: {name:"Create New Program", id:0}};
        return [addingNewProgram, ...formatted];
    };

    const fetchData = async () => {
            httpService.get(`content/list/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    dispatch(setFormValue({key:"content", value:responseData.data.data}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });

            httpService.get(`careprogram/patient/list/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const formattedPatients = formatPatientsDropdown(responseData.data.data);
                    dispatch(setFormValue({key:"patients", value:formattedPatients}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });

            httpService.get(`questionnaire/list/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    dispatch(setFormValue({key:"questionnaires", value:responseData.data.data}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });

            httpService.get(`device/group/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const deviceData = responseData.data.data;
                    let tempDevices = [];
                    let uniqueDevices = new Set();
                    if (deviceData.length >= 1) {
                        deviceData.forEach((device)=>{
                            if (!uniqueDevices.has(device.deviceId)) {
                                tempDevices.push(device);
                                uniqueDevices.add(device.deviceId);
                            }
                        })
                    }
                    dispatch(setFormValue({key:"devices", value:tempDevices}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });

            httpService.get(`careprogram/list/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const formattedPrograms = formatPrograms(responseData.data.data);
                    dispatch(setFormValue({key:"programs", value:formattedPrograms}));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [errorMessages, setErrorMessages] = useState({
        program:{
            patientMessage:"Please choose a patient to enroll",
            patientShowing:false,
            programMessage:"Please select a program",
            programShowing:false,
            durationMessage:"Please select a duration",
            durationShowing:false,
            groupShowing:false,
            groupMessage:"Please select teams"
        },
        device:{
            message:"All devices are required to have a valid serial number, start time, and frequency",
            showing:false,
            modalityMessage: "Please make sure upper limit > lower limit and target value is in between values",
            modalityShowing: false,
            modalityErrors: [],
            deviceIdErrors: [],
            serialNumberMessage: "Please enter a valid serial number for all devices",
            serialNumberShowing: false
        },
        rules: {
            repeatNotificationShowing: false,
            repeatNotificationMessage: "Please choose one notification rule per modality",
            repeatModalityFrequencyShowing: false,
            repeatModalityFrequencyMessage: "Please choose one modality frequency rule per modality",
        },
        questionnaire:{message:"All questionnaires are required to have a valid start time and frequency",showing:false},
        content:{
            contentMessage:"All content are required to have a valid start time and frequency",
            contentShowing:false,
            programMessage:"At least one device, questionnaire, or content is required to enroll a patient in a program",
            programShowing:false,
        },
    });

    const devices = useSelector((state)=>state.enrollmentLists.devices);

    useEffect(()=>{
        // check if we're updating a program and save the modalities for device selection to the slice
        if (updatingProgram || reenrollingProgram) {
            dispatch(setModalitiesState({programDevices: data.originalDevices, devicesList:devices, modalityValues: savedModalityValues}));
        } 
        if (addingProgram || updatingProgram || reenrollingProgram) {
            const groups = data.enrollment.groups;
            const organizationId = data.hasOwnProperty("organizationId") ? data.organizationId : false;
            const newGroups = groups.map((group)=> {
                return organizationId ? {...group,organizationId:organizationId} : group
            });
            dispatch(setGroups(newGroups));
        }
    },[devices]);

    const [page, setPage] = useState(0);

    const PageDisplay = () => {
        if (page === 0) {
            return <PatientProgramSetup errors={errorMessages.program}/>
        } else if (page === 1) {
            return <DeviceEnrollment errors={errorMessages.device}/>
        } else if (page === 2) {
            return <EnrollmentRules errors={errorMessages.rules}/>
        } else if (page === 3){
            return <QuestionnaireEnrollment errors={errorMessages.questionnaire}/>
        } else if (page === 4){
            return <ContentEnrollment errors={errorMessages.content}/>
        } else if (page === 5){
            return <ReviewEnrollment />
        } else {
            navigate('/patient-enrollment');
        }
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Program Setup";
        } else if (step === 1) {
            return "Device Setup";
        } else if (step === 2) {
            return "Enrollment Rules";
        } else if (step === 3){
            return "Questionnaire Setup";
        } else if (step === 4){
            return "Content Setup";
        } else if (step === 5){
            return "Review Enrollment";
        } 
    };

    const ProgressBarNavigation = (status) => {
        const pages = {
            "Program Setup": 0,
            'Device Setup': 1,
            'Enrollment Rules': 2,
            "Questionnaire Setup": 3,
            "Content Setup": 4,
            "Review Enrollment": 5
        };
        if (pages[status] || pages[status]===0) {
            setPage(pages[status]);
        } else {
            return;
        }
    };

    const nextPage = () => {
       setPage(page + 1);
    };

    const prevPage = () => {
        if (page !== 0) {
            setPage(page - 1);
        }
    };

    const checkPageValidity = () => {
        let tempError = {...errorMessages};
        if (page===0) {
            let patientInvalid = false;
            let programInvalid = false;
            let durationInvalid = false;
            let groupInvalid = false;
            if (data.patient.id === "") {
                patientInvalid = true;
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Please select a patient to enroll",
                        type: 'danger'
                    })
                );
                // Add a class to the patient selection field
                const patientSelect = document.getElementById('patient-select');
                if (patientSelect) {
                    patientSelect.classList.add(styles['error-input']);
                }
                return true; // Indicates that there's an error
            } else {
                // Remove the error class if the input is valid
                const patientSelect = document.getElementById('patient-select');
                if (patientSelect) {
                    patientSelect.classList.remove(styles['error-input']);
                }
            }
            if (data.patient.id !== "" && data.enrollment.groups.length === 0) {
                groupInvalid = true;
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Please select teams",
                        type: 'danger'
                    })
                );
                // Add a class to the teams selection field
                const teamsSelect = document.getElementById('teams-select');
                if (teamsSelect) {
                    teamsSelect.classList.add(styles['error-input']);
                }
                return true; // Indicates that there's an error
            } else {
                // Remove the error class if the input is valid
                const teamsSelect = document.getElementById('teams-select');
                if (teamsSelect) {
                    teamsSelect.classList.remove(styles['error-input']);
                }
            }
            if (data.patient.id !== "" && data.enrollment.groups.length !== 0 && data.name === "") {
                programInvalid = true;
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Please select a program name",
                        type: 'danger'
                    })
                );
                // Add a class to the program selection field
                const programSelect = document.getElementById('program-select');
                if (programSelect) {
                    programSelect.classList.add(styles['error-input']);
                }
                return true; // Indicates that there's an error
            } else {
                // Remove the error class if the input is valid
                const programSelect = document.getElementById('program-select');
                if (programSelect) {
                    programSelect.classList.remove(styles['error-input']);
                }
            }          
            if (data.name !== "" && data.duration === "") {
                durationInvalid = true;
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Please enter a valid duration for the program",
                        type: 'danger'
                    })
                );
                return true; // Indicates that there's an error
            }
            // check for previous/current enrollments by same name if not updating
            if (patientEnrollmentsList[data.patient.id] && !updatingProgram) {
                const enrollments = patientEnrollmentsList[data.patient.id];
                if (enrollments["current"].find((name)=>name===data.name)) {
                    dispatch(
                        showAlert({
                            header: "Error",
                            message: "Patient is currently enrolled in a program by the same name",
                            type: 'danger'
                        })
                    );
                    return true;
                } else if (enrollments["previous"].find((name)=>name===data.name) && !reenrollingProgram) {
                    dispatch(
                        showAlert({
                            header: "Error",
                            message: "Patient has been previously enrolled in a program by the same name, please re-enroll from the enrollment page",
                            type: 'danger'
                        })
                    );
                    return true;
                }

            }
            tempError.program.patientShowing = patientInvalid;
            tempError.program.programShowing = programInvalid;
            tempError.program.durationShowing = durationInvalid;
            tempError.program.groupShowing = groupInvalid;
            setErrorMessages(tempError);
            return patientInvalid || programInvalid || durationInvalid || groupInvalid;
        } else if ( page === 1) {
            let invalid = false;
            let modalitiesInvalid = false;
            let modalitiesListInvalid = [];
            let devicesListInvalid = [];
            let serialNumberInvalid = false;
            if (data.devices.length >= 1) {
                let countValid = 0;
                data.devices.forEach((device)=>{
                    // check if all devices have a start time and a frequency/isContinuous
                    if (device.startTime !== "" && (device.frequency >= 0 || device.isContinuous) && device.serialNumber !== "") {
                        if (!device.isContinous) {
                            if (device.interval === "Day") {
                                if (device.frequency > 365) {
                                    return;
                                }
                            } else if (device.interval === "Min") {
                                if (device.frequency > 60) {
                                    return;
                                }
                            }  else if (device.interval === "Hour") {
                                if (device.frequency > 24) {
                                    return;
                                }
                            } 
                        }
                        countValid++;
                    } else if (device.serialNumber === "") {
                        serialNumberInvalid = true;
                    }
                })
                if (countValid !== data.devices.length) {
                    // devices are missing information
                    invalid = true;
                };
                modalities.forEach((mod)=> {
                    if (mod.upperLimit!==0 && mod.lowerLimit !== 0) {
                        if (mod.upperLimit < mod.lowerLimit || ( mod.targetValue !== 0 && (mod.targetValue > mod.upperLimit || mod.targetValue < mod.lowerLimit))) {
                            modalitiesInvalid = true;
                            modalitiesListInvalid.push(mod.deviceModalityId)
                            if (!devicesListInvalid.some(id => id === mod.deviceId)) {
                                devicesListInvalid.push(mod.deviceId);
                            }
                        }
                    }
                });
            }
            if (serialNumberInvalid) {
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Please enter a valid serial number for all devices",
                        type: 'danger'
                    })
                );
                return true; // Indicates that there's an error
            }
            tempError.device.modalityShowing = modalitiesInvalid;
            tempError.device.modalityErrors = modalitiesListInvalid; 
            tempError.device.deviceIdErrors = devicesListInvalid; 
            tempError.device.showing = invalid;
            tempError.device.serialNumberShowing = serialNumberInvalid;
            setErrorMessages(tempError);
            return invalid || modalitiesInvalid || serialNumberInvalid;
        } else if (page === 2) {
            let notificationInvalid = false;
            let modalityFrequencyInvalid = false;
            const notificationUniqueCount = new Set(notifications.map((not)=>not.modalityId)).size;
            if (notificationUniqueCount !== notifications.length) {
                notificationInvalid = true;
            }
            const modalityFrequenciesUniqueCount = new Set(modalityFrequencies.map((not)=>not.modalityId)).size;
            if (modalityFrequenciesUniqueCount !== modalityFrequencies.length) {
                modalityFrequencyInvalid = true;
            }
            tempError.rules.repeatNotificationShowing = notificationInvalid;
            tempError.rules.repeatModalityFrequencyShowing = modalityFrequencyInvalid;
            setErrorMessages(tempError);
            return notificationInvalid || modalityFrequencyInvalid;
        } else if ( page === 3) {
            let invalid = false;
            if (data.questionnaires.length >= 1) {
                let countValid = 0;
                data.questionnaires.forEach((q)=>{
                    // check if all devices have a valid start time and frequency
                    if (q.startTime !== "" && q.frequency >= 1) {
                        if (q.interval === "Day") {
                            if (q.frequency > 365) {
                                return;
                            }
                        } else if (q.interval === "Min") {
                            if (q.frequency > 60) {
                                return;
                            }
                        }  else if (q.interval === "Hour") {
                            if (q.frequency > 24) {
                                return;
                            }
                        } 
                        countValid++;
                    };
                })
                if (countValid !== data.questionnaires.length) {
                    // devices are missing information
                    invalid = true;
                };
            } 
            tempError.questionnaire.showing = invalid;
            setErrorMessages(tempError);
            return invalid;
        }
        else if (page === 4) {
            let contentInvalid = false;
            let programInvalid = false;
            if (data.contents.length >= 1) {
                let countValid = 0;
                data.contents.forEach((content)=>{
                    // check if all devices have a start time and a frequency/isContinuous
                    if (content.startTime !== "" && content.frequency >= 1) {
                        if (content.interval === "Day") {
                            if (content.frequency > 365) {
                                return;
                            }
                        } else if (content.interval === "Min") {
                            if (content.frequency > 60) {
                                return;
                            }
                        }  else if (content.interval === "Hour") {
                            if (content.frequency > 24) {
                                return;
                            }
                        } 
                        countValid++;
                    };
                })
                if (countValid !== data.contents.length) {
                    // devices are missing information
                    contentInvalid = true;
                };
            } if (data.devices.length === 0 && data.questionnaires.length === 0 && data.contents.length === 0) {
                // check if there's at least one selected device, questionnaire, or content
                programInvalid = true;
            } 
            tempError.content.contentShowing = contentInvalid;
            tempError.content.programShowing = programInvalid;
            setErrorMessages(tempError);
            return contentInvalid || programInvalid;
        }
        return false;
    };

    const formatProgramData = () => {
        const devices = data.devices;
        const deviceNotes = data.deviceNotes;
        const contents = data.contents;
        const contentNotes = data.contentNotes;
        const questionnaires = data.questionnaires;
        const questionnaireNotes = data.questionnaireNotes;
        const hubs = data.hubs;

        let newDevices = [];
        if (devices.length >= 1) {
            // same process as creation but for each modality, check if it was already there and add accountProgramDeviceId to device, otherwise remove accountProgramDeviceId
            devices.forEach((device)=> {
                let tempDevices = [];
                modalities.forEach((mod)=> {
                    if (mod.deviceId === device.deviceId){
                        let tempDevice = {...device};
                        tempDevice['deviceModalityId'] = mod.deviceModalityId;
                        tempDevice['modalityName'] = mod.modalityName;
                        tempDevice['modalityId'] = mod.modalityId;
                        tempDevice['upperLimit'] = mod.upperLimit;
                        tempDevice['lowerLimit'] = mod.lowerLimit;
                        tempDevice['targetValue'] = mod.targetValue;
                        tempDevice['isAlarmActive'] = mod.isAlarmActive;
                        tempDevice['notes'] = deviceNotes;
                        // if device is perin patch, update start time, frequency, and interval as these can be unique to each modalityType
                        // with other devices, the entire device + all its modalities have common start time, frequency, and interval values
                        if (device.deviceId === 1) {
                            tempDevice['startTime'] = mod.startTime;
                            tempDevice['frequency'] = mod.frequency;
                            tempDevice['interval'] = mod.interval;
                            tempDevice['isContinuous'] = mod.isContinuous;
                        }
                        // if we're updating a previously created modality
                        if (templateModalities[device.deviceId] && templateModalities[device.deviceId].find(mod => mod.deviceModalityId === tempDevice.deviceModalityId)?.accountProgramDeviceId) {
                            // Get templateId from templateModalities

                            tempDevice['accountProgramDeviceId'] = templateModalities[device.deviceId].find(mod => mod.deviceModalityId === tempDevice.deviceModalityId).accountProgramDeviceId;
                        } else {
                            delete tempDevice['accountProgramDeviceId'];
                        }
                        delete tempDevice['templateId'];


                        // add rules
                        let rules = [];
                        notifications.forEach((notification) => {
                            if (notification.modalityId === mod.modalityId) {
                                let tempNotification = {...notification};
                                tempNotification["deviceId"] = device.deviceId;
                                rules.push(tempNotification);
                            }
                        });
                        modalityFrequencies.forEach((modFrequency) => {
                            if (modFrequency.modalityId === mod.modalityId) {
                                let tempModFrequency = {...modFrequency};
                                tempModFrequency["deviceId"] = device.deviceId;
                                rules.push(tempModFrequency);
                            }
                        });
                        tempDevice['rules'] = rules;
    
                        tempDevices.push(tempDevice);

                    }
                })
                if (tempDevices.length >= 1) {
                    newDevices = newDevices.concat(tempDevices);
                } else {
                    newDevices.push({...device});
                }
            })
        }
        let newContents = [];
        if (contents.length >= 1) {
            newContents = contents.map((content)=>({...content,notes:contentNotes}));
        };
        let newQuestionnaires = [];
        if (questionnaires.length >= 1) {
            newQuestionnaires = questionnaires.map((q)=>({...q,notes:questionnaireNotes}));
        };
        let newHubs = hubs && hubs.length > 0 && hubs[0].serialNumber ? hubs : [];

        // check if duration was filled out, if not set duration to 0
        const returnData = { 
            ...data, 
            devices: newDevices, 
            contents: newContents,
            questionnaires: newQuestionnaires,
            hubs: newHubs,
            duration: data.duration === "" ? 0 : data.duration
        };
        delete returnData['templateModalities'];
        delete returnData['deviceNotes'];
        delete returnData['contentNotes'];
        delete returnData['questionnaireNotes'];
        delete returnData['updating'];
        delete returnData['adding'];
        delete returnData['selectedProgram'];
        delete returnData['originalDevices'];
        return returnData;
    };

    const handleSubmit = async () => {
        const newProgram = formatProgramData();
        const url = updatingProgram ? `careprogram/patient/enroll/${data.enrollmentId}` : 'careprogram/patient/enroll';
        const method = updatingProgram ? 'PUT' : 'POST';
        const alertMessage = updatingProgram ? 'updating' : addingProgram ? 'adding' : reenrollingProgram ? 're-enrolling' : 'enrolling';
        try {
            const response = await httpService.request({
                method: method,
                headers: {"Content-Type": "application/json"},
                url: url,
                data: JSON.stringify(newProgram),
            });
            if (response) {
                if (updatingProgram) {
                    setPage(0);
                } else {
                    navigate('/patient-enrollment');
                }
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Success ${alertMessage} patient`,
                        type: 'success'
                    })
                );
            }
        } catch (error) {
            console.log("error:"+error);
            dispatch(
                showAlert({
                    header: "Error",
                    message: `Error ${alertMessage} patient`,
                    type: 'danger'
                })
            );
        }
    };

    const onBackHandler = async () => {
        navigate('/patient-enrollment');
        dispatch(resetProgramState());
    };

    return (
        <div className={styles.MainContent}>
            <div className={styles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Patient Enrollment</span>
            </div>
            <div className={styles.MiddleContentAdd} style={{justifyContent:"start"}}>
                <div className={styles.ContentTitle}>{updatingProgram ? "Updating Patient Enrollment" : addingProgram ? "Add Patient Enrollment" : reenrollingProgram ? "Re-enroll in previous program" :"Patient Enrollment"}</div>
                <div style={{paddingTop:"20px"}}>
                    <ProgressBar page={page} navigateToStep={ProgressBarNavigation} getPageName={getPageName} totalSteps={6}/>
                </div>
                <div className={containerStyles.HideScrollContainer} style={{ width: '100%' }}>
                    {PageDisplay()}
                </div>
                {errorMessages.content.programShowing && 
                    <div className={containerStyles.ValidationMessageContainer}>
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="sm" style={{color:"red"}}/>
                        <span 
                            className={containerStyles.ValidationMessage}
                        >
                            {errorMessages.content.programMessage}
                        </span>
                    </div>
                }
                <div style={{display:"flex", paddingBottom:"10px", gap:"5px"}}>
                    {page!==0 ? (
                        <button 
                            className={styles.BackButton}
                            onClick={(e) => {
                                e.preventDefault();
                                prevPage();
                            }}
                        >
                        Back
                        </button>)
                    : null }
                    <button 
                        className={(page === 5 && (updatingProgram || !updatingProgram)) ? styles.UpdateButton : styles.NextButton}
                        style={{marginRight: "10px"}}
                        onClick={(e) => {
                            e.preventDefault();
                            if (checkPageValidity()){
                                return;
                            };
                            if (page === 5) {
                                handleSubmit()
                            } else {
                                nextPage();
                            };
                        }}
                    >
                        {(page===5 && updatingProgram) ? "Update" : (page===5 && reenrollingProgram) ? "Re-enroll" : page===5 ? "Enroll Patient" : "Next"} 
                    </button>
                    {updatingProgram && page !== 5 && (
                        <button 
                            className={styles.UpdateButton}
                            onClick={(e) => {
                                e.preventDefault();
                                if (checkPageValidity()){
                                    return;
                                };
                                handleSubmit();
                            }}
                        >
                        Update
                        </button>
                    )}
                    {(page === 1 || page === 2 || page === 3 || page === 4) && !updatingProgram && (
                        <button 
                            className={styles.UpdateButton}
                            onClick={(e) => {
                                e.preventDefault();
                                if (checkPageValidity()){
                                    return;
                                };
                                handleSubmit();
                            }}
                        >
                        Enroll Patient
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

}

export default EnrollPatient;