import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {Form} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import ProgressBar from "../components/ProgressBar";
import SetPassword from '../views/account-activation/SetPassword';
import RoleInfo from './account-activation/ActivateRoleInfo';
import PersonalInfo from './account-activation/ActivatePersonalInfo';
import Preferences from './account-activation/ActivatePreferences';
import HealthInfo from './account-activation/ActivateHealthInfo';
import EmergencyContact from "./account-activation/ActivateEmergencyContact";
import styles from '../views/account-management/AccountManagement.module.css';
import useAxios from "../services/AxiosConfig";
import '../components/AccountManagement/Accounts.css';
import PerinHealthAlert from "../components/PerinHealthAlert";
import { showAlert } from "../components/PerinHealthAlertSlice";
import managementStyles from './ManagementPages.module.css'

const ActivateAccount = () => {

    const dispatch = useDispatch();
    const perinHealthAlert = useSelector((state) => state.perinHealthAlert);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [originalUserData, setOriginalUserData] = useState([]);
    const [userData, setUserData] = useState({role: {
        id: "",
        name: "",
        category: "",
    }});

    const getUrlParameter = (name) => {
        const url = window.location.search.substring(1); // Remove the "?" at the start
        const regex = new RegExp(`[?&]${name}=([^&]*)`);
        const results = regex.exec('?' + url); // Append '?' to ensure the regex works correctly
        return results === null ? '' : decodeURIComponent(results[1]);
    };
    
    const paramsUsername = getUrlParameter('username');
    const paramsTempPass = window.location.search.split('code=')[1]; // Extract everything after 'code='



    const [passwordsMatch, setPasswordsMatch] = useState(null);
    const [passwordValid, setPasswordValid] = useState(true);
    const [credentials, setCredentials] = useState({
        username: paramsUsername,
        password: paramsTempPass,
        newPassword: ''
    });

    const testPassword = (pass) => {
        const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        const ret = regex.test(pass);
        setPasswordValid(ret);
        return ret;
    };

    const handleUserData = (data) => {
        setUserData(data);
        setOriginalUserData(data);
        const emContact = {
            fullName: "",
            relation: "",
            gender: "",
            countryCode: "",
            phone: "",
            email: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: ""
        }
        const hInfo = {
            criticalLevel: 0,
            phase: "",
            notes: "",
            gender: "",
            height: "",
            weight: "",
            ethnicity: "",
            conditions: "",
            dateOfBirth: ""
        }
        if (data.role.category==="Patient"){
            if (!data.emergencyContact) {
                setUserData(prevUserData => ({
                    ...prevUserData,
                    emergencyContact: emContact
                }));
                setOriginalUserData(prevUserData => ({
                    ...prevUserData,
                    emergencyContact: emContact
                }));
            } 
            if (!data.healthInfo) {
                setUserData(prevUserData => ({
                    ...prevUserData,
                    healthInfo: hInfo
                }));
                setOriginalUserData(prevUserData => ({
                    ...prevUserData,
                    healthInfo: hInfo
                }));
            }
        }
    };

    const roleCategory = userData.role.category;


    const PageDisplay = () => {
        if (page === 0) {
            return <SetPassword credentials={credentials} handleCredentials={handleCredentials} setCredentials={setCredentials} setPasswordsMatch={setPasswordsMatch} testPassword={testPassword}/>;
        } else if (page === 1) {
            return <RoleInfo userData={userData} handleChange={handleChange}/>
        } else if (page === 2) {
            return <PersonalInfo userData={userData} handleChange={handleChange}/>
        } else if (page === 3 && roleCategory!=="Patient"){
            return <Preferences userData={userData} handleChange={handleChange}/>
        } else if (page === 3 && roleCategory==="Patient"){
            return <HealthInfo userData={userData} handleChange={handleChange}/>
        } else if (page === 4 && roleCategory==="Patient"){
            return <EmergencyContact userData={userData}  handleChange={handleChange}/>
        }
        else {
           return;
        }
    }

    const getPageName = (step) => {
        if (step === 0 && roleCategory==="Patient") {
            return "Patient Care Info";
        } else if (step === 0 && roleCategory!=="Patient") {
            return "Group & Role Info";
        } else if (step === 1) {
            return "Personal Info";
        } else if (step === 2 && roleCategory==="Patient"){
            return "Health Info";
        } else if (step === 2 && roleCategory!=="Patient"){
            return "Preferences";
        }
        else if (step === 3 && roleCategory==="Patient"){
            return "Emergency Contact";
        }
    };

    const ProgressBarNavigation = (status) => {
        const pages = {
            "Patient Care Info": 1,
            "Group & Role Info": 1,
            "Personal Info": 2,
            "Health Info": 3,
            "Preferences": 3,
            "Emergency Contact": 4
        };
        if (pages[status] || pages[status]===0) {
            setPage(pages[status]);
        } else {
            return;
        }
    };

    const lastPage = () => {
        if ((roleCategory === "Patient" && page === 4) || (roleCategory !== "Patient" && page === 3)){
            return true;
        } else {
            return false;
        }
    }

    const nextPage = () => {
        if (roleCategory === "Patient" && page < 4) {
            setPage(page + 1);
        } else if (roleCategory !== "Patient" && page < 3) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page !== 0) {
            setPage(page - 1);
        }
    };

    const handleCredentials = e => {
        let name = e.target.id;
        let value = e.target.value;

        setCredentials(prevCredentials => ({
            ...prevCredentials,
            [name]: value
        }));
    };

    const handleChange = (e, identifier = null, blockName = null) => {
        let value, name;
        if (identifier==="weight" || identifier==="height"){
            value = e;
            name=identifier;
        } else if (e.hasOwnProperty('value')) {
            value = e.value;
            name = identifier;
        }
        else {
            value = e.target.value;
            name = e.target.id;
        }
        if (blockName) {
            setUserData(prevUserData => ({
                ...prevUserData,
                [blockName]: {
                    ...prevUserData[blockName],
                    [name] : value
                }
            }));

        } else {
            setUserData(prevUserData => ({
                ...prevUserData,
                [name]: value
            }));
        }
    };

    const checkUserObjectsSame = (obj1, obj2) => {
        if (obj1 === null && obj2 === null) {
            return true;
        }
    
        // If either object is null or undefined, they are not equal
        if (obj1 === null || obj2 === null || obj1 === undefined || obj2 === undefined) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // If number of keys is different, objects are not the same
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check each key and value recursively
        for (let key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            // If the value is an object, recursively compare it
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!checkUserObjectsSame(val1, val2)) {
                    return false;
                }
            } else {
                // Otherwise, compare the values directly
                if (val1 !== val2) {
                    return false;
                }
            }
        }
        // If all keys and values are the same, objects are the same
        return true;
    };

    const handleCredentialsSubmit = async () => {
        try {
            const response = await httpService.request({
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                url: 'confirm-signup',
                data: JSON.stringify(credentials),
            });
            if (response.data.data) {
                handleUserData(response.data.data);
                nextPage();
            }
        } catch (error) {
            const errorMessage = error.response.data.message;
            alert(errorMessage);
        }
    };

    const handleUpdateSubmit = async () => {
        const updatedInfo = checkUserObjectsSame(userData, originalUserData);
        if (!updatedInfo) {
            try {
                const response = await httpService.request({
                    method: 'PUT',
                    headers: {"Content-Type": "application/json"},
                    url: `user/${userData.id}`,
                    data: JSON.stringify(userData),
                });
                if (response) {
                    navigate('/');
                }
            } catch (error) {
                const errorMessage = error.response.data.message;
                dispatch(
                    showAlert({
                        header: "Error saving updates",
                        message: errorMessage,
                        type: 'danger',
                    })
                );
                navigate('/');
            }
        } else {
            navigate('/');
        }
    };

    return (
        <div className='background-container'>
        <div className="main-container">
            <div className="activate-account-container">
                <div className="activate-account-inner-container">
                {page!== 5 ? <div className="headline-5" style={{paddingLeft:"0", paddingTop:"30px", fontSize:"26px"}}>
                    Account Activation
                    </div> : <div className="headline-5" style={{paddingLeft:"0", paddingTop:"30px", fontSize:"26px"}}>Congratulations</div>}
                    <p className="body-2">{ page===0 ? "Set a secure password for your account" : page!== 5 ? "Please confirm your Account Information. Contact our support team at [Support Email] for any necessary corrections." : "Your account has been successfully activated. You can now login and explore Perin health app."}</p>
                {page!==0 && page!==5 && <ProgressBar page={page-1} navigateToStep={ProgressBarNavigation} totalSteps={roleCategory === "Patient" ? 4 : 3} getPageName={getPageName}/>}
                <Form style={{display:"flex", flexDirection:"column", align:"left",flex: "1", overflow:"auto"}}>
                    <div className="inputs">
                        {PageDisplay()}
                    </div>
                    {!passwordsMatch && <span style={{color:"grey", fontSize:"10px"}}>passwords do not match</span>}
                    {!passwordValid && <span style={{color:"red", fontSize:"9px", paddingBottom:"5px"}}>Password must contain at least one uppercase letter, one lowercase letter, one special character, and be 8 characters or longer.</span>}
                    <div className="button-container2">
                        <button 
                                disabled={!passwordsMatch || !passwordValid}
                                style={!passwordsMatch || !passwordValid ? {backgroundColor:"#BEBFBF", border:"none"} : {}}
                                className={page!==0 && page!==5 ? managementStyles.CreateButton : styles.SetPasswordButton}
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (page===0){
                                        handleCredentialsSubmit()
                                    }
                                    else if (lastPage()) {
                                        handleUpdateSubmit()
                                    } else if (page===5){
                                        navigate('/')
                                    } else {
                                        nextPage()
                                    }
                                }}
                            >
                            {page===0 ? "Set Password" : page===5 ? "Continue to Your Account" : lastPage() ? "Create Account" : "Next"} 
                            </button>
                        {page>1 && page!==5  ? (
                                <button 
                                    className={managementStyles.BackButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        prevPage();
                                    }}
                                >
                                Back
                                </button>)
                            : null }
                    </div>
                </Form>
                </div>
            </div>
            {perinHealthAlert.show && (
                <PerinHealthAlert
                    show={perinHealthAlert.show}
                    type={perinHealthAlert.type}
                    header={perinHealthAlert.header}
                    message={perinHealthAlert.message}
                    dismissable={perinHealthAlert.dismissable}
                />
            )}
        </div>
        </div>
    )

}

export default ActivateAccount;