import React, { useEffect } from 'react';
import PatientHeader from './PatientHeader';
import managementStyles from '../ManagementPages.module.css';
import { useNavigate } from 'react-router-dom';
import { LeftArrowIcon } from '../../components/icons/Icons';
import PatientDetailBody from './PatientDetailBody';
import { setPatientPrograms, resetPatientState, setPatientDetails, setPatientOrgHierarchy, setFormField } from '../../slicers/PatientSlice';
import useAxios from '../../services/AxiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { resetNotesState } from './Slices/PatientDetailNotesSlice';
import { resetPatientDetailsState, setMenuItem } from './Slices/PatientDetailSlice';
import { useParams } from 'react-router-dom';

const PatientDetail = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const axios = useAxios(axiosConfig.envURL);
  const patientInfo = useSelector((state) => state.patient);
  const patientId = patientInfo.id;
  const sidebarSlicer = useSelector((state) => state.sidebar);

  const { tab } = useParams();

  useEffect(()=> {
    if (tab) {
      dispatch(setMenuItem(tab));
    } else {
      dispatch(setMenuItem("Vitals"));
    }
  },[])

  const onBackHandler = () => {
    dispatch(resetPatientState());
    dispatch(resetNotesState());
    dispatch(resetPatientDetailsState());
    navigate(-1);
  };

  const filterCarePrograms = (data) => {
    return data.filter(item => item.patient.id === patientId);
  };

  const getQuestionnaires = async (data) => {
    let currentEnrollments = data.filter(item => !item.enrollment.dischargeDate);
    let questionnaires = [];
    currentEnrollments.forEach((enrollment) => {
      if (enrollment.questionnaires.length !== 0) {
        enrollment.questionnaires.forEach((q) => {
          questionnaires.push(q.questionnaireId);
        })
      }
    })
    let qIds = Array.from(new Set(questionnaires.map(q => q)));
    const qPromises = qIds.map(async (q) => {
      try {
        const response = await axios.get(`/questionnaire/qId/${q}`);
        if (response.data.data) {
          let question = response.data.data;
          let scoreCount = 0;
          response.data.data.questions.forEach(q => {
            let maxScore = 0;
            q.possibleAnswers.forEach(a => {
              maxScore = Math.max(a.score, maxScore);
            })
            scoreCount += maxScore;
          })
          question["totalPossibleScore"] = scoreCount;
          return question;
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return [];
      }
    });
    const qResults = await Promise.all(qPromises);
    return qResults;
  };

  const formatAppointments = (data) => {
    return data.map(appt => {
      let newAppt = appt;
      let physician = patientInfo.careTeam.filter(staff => staff.id === appt.physicianId);
      newAppt.physicianName = physician.length > 0 ? `${physician[0].firstName} ${physician[0].lastName}` : "";
      newAppt.type = "Appointment";
      return newAppt;
    })
  };

  const getCareTeam = async (groups) => {
    try {
      const careTeamPromises = groups.map(async (group) => {
        try {
          const response = await axios.get(`list/doctorNurse/team/${group.teamId}`);
          if (Array.isArray(response.data.data)) {
            return response.data.data.map(item => {
              let updated = item;
              updated.teamId = group.teamId;
              return updated;
            });
          } else {
            return [];
          }
        } catch (err) {
          console.log(err);
          return [];
        }
      });

      const careTeamResults = await Promise.all(careTeamPromises);
      const careTeam = careTeamResults.flat();
      const uniqueCareTeamMap = new Map();
      careTeam.forEach(member => {
        if (!uniqueCareTeamMap.has(member.id)) {
          uniqueCareTeamMap.set(member.id, member);
        }
      });
      return Array.from(uniqueCareTeamMap.values());
    } catch (err) {
      console.error('Error fetching care team:', err);
      return [];
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      // get patient info
      try {
        const patientResponse = await axios.get(`user/${patientId}`)
        if (patientResponse.data.data) {
          dispatch(setPatientDetails(patientResponse.data.data));

          const careTeam = patientResponse.data.data.careTeam;
          dispatch(setFormField({ key: "careTeam", value: careTeam }));
        } else {
          console.error('Error fetching patient info');
        }
      } catch (err) {
        console.log(err);
      }

      // get patient enrollments + questionnaire responses
      try {
        const enrollmentResponse = await axios.get(`careprograms/patient/enrollments/list/${patientId}`)
        if (Array.isArray(enrollmentResponse.data.data)) {
          const filteredData = filterCarePrograms(enrollmentResponse.data.data);
          const questionnaireIds = await getQuestionnaires(filteredData);
          dispatch(setFormField({ key: "questionnaireIds", value: questionnaireIds }));
          dispatch(setPatientPrograms(filteredData));
        } else {
          console.error("Can't fetch patient groups");
        }
      } catch (err) {
        console.log(err);
      }

      // get groups
      axios.get(`list/groups/${patientId}`)
        .then((responseData) => {
          if (Array.isArray(responseData.data.data)) {
            dispatch(setPatientOrgHierarchy(responseData.data.data));
          } else {
            console.error("Can't fetch patient groups");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // get appointments
      axios.get(`appointment/temp/patient?patientId=${patientId}`)
        .then((responseData) => {
          if (Array.isArray(responseData.data.data)) {
            const formattedAppointments = formatAppointments(responseData.data.data);
            dispatch(setFormField({ key: "appointments", value: formattedAppointments }));
          } else {
            console.error("Can't fetch patient groups");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (patientInfo.id) {
      fetchData();
    }
  }, []);

  return (
    <div className={managementStyles.MainContent}>
      <div className={`${managementStyles.ReturnToOverviewManagement} align-items-start`} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} onClick={onBackHandler}>
          <LeftArrowIcon style={{ cursor: 'pointer', marginRight: '8px' }} />
          <span style={{ cursor: 'pointer', paddingLeft: "5px" }}>{`Back to ${sidebarSlicer.selectedMenuItem}`}</span>
        </div>

        <PatientHeader />
      </div>

      <PatientDetailBody />
    </div>
  );
}

export default PatientDetail;