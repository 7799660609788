import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAppTourState, setFieldValue } from './AppTourSlice';
import StepPopup from './StepPopup';

const AppTourPopup = ({
    handleNext, handleBack, 
    dashboardRef,
    patientEnrollmentRef,
    appointmentsRef,
    billingRef,
    tasksRef,
    newOrgRef,
    orgManagementRef,
    accountManagementRef,
    roleManagementRef,
    hubManagementRef,
    deviceManagementRef,
    contentManagementRef,
    questionnaireManagementRef,
    programManagementRef,
    patientMessageRef,
    careTeamMessageRef,
    patientSubmittedReportRef,
    referenceCodeRef
}) => {
    const dispatch = useDispatch();
    const appTour = useSelector((state) => state.appTour);
    const { isAdmin, permissions } = useSelector((state) => state.userInfo);
    
    const isPermissionExists = (permissionId) => {
        return permissions.some(permission => permission.permissionId === permissionId || permission.permissionId === "*");
    };
    
    const sideBarSteps = [
        { targetRef: dashboardRef, permissionId: 'Patient Dashboard', title: 'Dashboard', content: 'A quick overview of tasks and patient information.' },
        { targetRef: patientEnrollmentRef, permissionId: 'Patient Enrollment', title: 'Enrollment', content: 'Enroll patients and assign programs, content, and questionnaires.' },
        { targetRef: appointmentsRef, permissionId: 'Appointments', title: 'Appointments', content: 'View  your appointments and set your availability.' },
        { targetRef: tasksRef, permissionId: 'Tasks', title: 'Tasks', content: 'View tasks related to patient appointments and discharges, device alerts, messages, billing, tele-visit notes, and patient-submitted reports.' },
        { targetRef: billingRef, permissionId: 'Billing', title: 'Billing', content: 'Check out all your billing-related information and manage it right here.' },
        { targetRef: newOrgRef, permissionId: 'Add New Organization', title: 'Organization', content: 'Add a new organization here.' },
        { targetRef: orgManagementRef, permissionId: 'Organization Hierarchy Management', title: 'Hierarchy', content: 'Manage organizations here.' },
        { targetRef: accountManagementRef, permissionId: 'Account Management', title: 'Account', content: 'Create accounts for patients and other staff members within your organization.' },
        { targetRef: roleManagementRef, permissionId: 'Role Management', title: 'Role', content: 'Manage roles here.' },
        { targetRef: referenceCodeRef, permissionId: 'Reference Code Management', title: 'Reference Code', content: 'Reference Code Management here.' },
        { targetRef: hubManagementRef, permissionId: 'Hub Management', title: 'Hub', content: 'Manage hubs here.' },
        { targetRef: deviceManagementRef, permissionId: 'Device Management', title: 'Device', content: 'Manage devices here.' },
        { targetRef: contentManagementRef, permissionId: 'Content Management', title: 'Content', content: 'Manage content here.' },
        { targetRef: questionnaireManagementRef, permissionId: 'Questionnaire Management', title: 'Questionnaire', content: 'Manage questionnaires here.' },
        { targetRef: programManagementRef, permissionId: 'Program Management', title: 'Program', content: 'Manage programs here.' },
        { targetRef: patientMessageRef, permissionId: 'Patient Messages', title: 'Patient Messages', content: 'Check and send patient messages.' },
        { targetRef: careTeamMessageRef, permissionId: 'Care Team Messages', title: 'Staff Messages', content: 'Check and send team messages.' },
        { targetRef: patientSubmittedReportRef, permissionId: 'Patient Medical Reports', title: 'Patient-submitted Reports', content: 'View reports submitted by patients.' }
    ];

    const tourSteps = sideBarSteps.filter(step => isPermissionExists(step.permissionId));

    const handleNextStep = () => {
        if (appTour.currentStepIndex < tourSteps.length - 1) {
            dispatch(setFieldValue({ name: 'currentStepIndex', value: appTour.currentStepIndex + 1 }));
            dispatch(setFieldValue({ name: 'currentStepLabel', value: tourSteps[appTour.currentStepIndex + 1].permissionId }));
        } else {
            dispatch(setFieldValue({ name: 'showAppTour', value: false }));
            dispatch(resetAppTourState());
        }
    };

    const handleBackStep = () => {
        if (appTour.currentStepIndex > 0) {
            dispatch(setFieldValue({ name: 'currentStepIndex', value: appTour.currentStepIndex - 1 }));
        }
    };

    const handleClose = () => {
        dispatch(setFieldValue({ name: 'showAppTour', value: false }));
        dispatch(resetAppTourState());
    };

    return (
        <>
            {appTour.showAppTour && (
                <StepPopup
                    show={appTour.showAppTour}
                    handleClose={handleClose}
                    handleNext={handleNextStep}
                    handleBack={appTour.currentStepIndex > 0 ? handleBackStep : null}
                    targetRef={tourSteps[appTour.currentStepIndex].targetRef}
                    title={tourSteps[appTour.currentStepIndex].title}
                    content={tourSteps[appTour.currentStepIndex].content}
                    isFirstStep={appTour.currentStepIndex === 0}
                    isLastStep={tourSteps.length - 1 === appTour.currentStepIndex}
                    currentStepIndex={appTour.currentStepIndex}
                    tourStepsLength={tourSteps.length}
                />
            )}
        </>
    );
};

export default AppTourPopup;
