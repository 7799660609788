import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxios from "../../services/AxiosConfig";
import GroupSelectPopup from "../GroupSelectPopup";
import './Accounts.css';
import inputStyles from '../../views/ManagementPages.module.css';
import { setFormField } from './RegistrationSlice';
import { Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import MultiSelectDropdown from "../MultiSelectDropdown";
import FileUploadOrganization from "../../views/new-organization/add-edit-organization/FileUploadOrganization";
import { SpecialtySelectedIcon, RemoveIcon } from "../icons/Icons";
import { TrashIcon } from "../icons/Icons";

const GroupRoleInfo = ({selectedOrganization, setSelectedOrganization, errors, setProfileFile}) => {

    const dispatch = useDispatch()
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const groupData = useSelector((state) => state.userInfo.organizationsHierarchy);

    const userData = useSelector((state)=>state.registration);
    const roleCategory = userData.role.category;
    const initialSpecialties = userData.specialties ? userData.specialties : [];
    const [selectedSpecialties, setSelectedSpecialties] = useState(initialSpecialties);
    const [isGroupSelectVisible, setIsGroupSelectVisible] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const userGroups = userData.groups;
    const signedInUserOrganizationData = useSelector((state)=>state.userInfo.organizationsHierarchy);
    const initEmployeeInfo = userData.npi !== "" ? "npi" : "employeeId";
    const [employeeInfo, setEmployeeInfo] = useState(initEmployeeInfo);

    const groupsErrorShowing = errors.groupsShowing && userGroups.length === 0;
    const npiErrorShowing = errors.employeeShowing && userData.npi === "";
    const employeeIdErrorShowing = errors.employeeShowing && userData.employeeId === "";

    const AppointmentsTooltip = (
        <Tooltip id="tooltip">
            This enables staff, like nurse practitioners, to meet with anyone in the organization and have appointments.
        </Tooltip>
    );

    const AvailabilityTooltip = (
        <Tooltip id="tooltip">
            Allows accounts to manage team availability and appointments.
        </Tooltip>
    );

    const toggleGroupSelectVisibility = () => {
        setIsGroupSelectVisible(!isGroupSelectVisible);
    };

    const initGroupList = (orgId, selectingGroups = false) => {
        let tempGroups = [];
        groupData.forEach((org)=> {
            if (org.organizationId === orgId) {
                tempGroups = tempGroups.concat(
                    org.groups.map((group) => ({
                        groupId: group.groupId,
                        groupName: group.groupName
                    }))
                );
            }
        });
        if (selectingGroups) {
            tempGroups = tempGroups.filter(group => {
                return userData.groups.some(userGroup => userGroup.groupId === group.groupId);
            });
        }
        return tempGroups;
    };

    const [groupsDisplay, setGroupsDisplay] = useState(initGroupList(selectedOrganization));
    const [selectedGroups, setSelectedGroups] = useState(initGroupList(selectedOrganization, true));

    const [specialtiesList, setSpecialtiesList] = useState([]);
    useEffect(() => {
        httpService.get(`specialties`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    let tempSpecialties = responseData.data.data.map((s) => ({
                        value: s, category: s
                    }));
                    setSpecialtiesList(tempSpecialties);
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [httpService]);

    const initPrimary = () => {
        if (userData.specialties){
            const primaryCategory = selectedSpecialties.find(item => item.isPrimary);
            return primaryCategory ? primaryCategory.category : "";
        } else {
            return "";
        }
    };
    const [primarySpecialty, setPrimarySpecialty] = useState(initPrimary);

    const checkSelectedSpecialty = (value) => {
        let inList = false;
        selectedSpecialties.forEach((item)=>{
            if (item.category===value){
                inList=true;
            } 
        });
        return inList;
    };

    const handlePrimaryClick = (specialty) => {
        setPrimarySpecialty(specialty.category);

        const updatedSpecialties = selectedSpecialties.map(option => {
            if (option.category === specialty.category) {
                return { ...option, isPrimary: true };
            } else {
                return { ...option, isPrimary: false }; 
            }
        });
        setSelectedSpecialties(updatedSpecialties);
        dispatch(setFormField({ key: "specialties", value: updatedSpecialties.map(s => ({ category: s.category, isPrimary: s.isPrimary })) }));
    };

    const handleSpecialtiesChange = (selectedSpecialty) => {
        let inSpecialtiesList = false;
        selectedSpecialties.forEach((item)=>{
            if (item.category===selectedSpecialty.category){
                inSpecialtiesList = true;
            } 
        });
        let updatedSpecialties;
        if (inSpecialtiesList){
            updatedSpecialties = selectedSpecialties.filter(item => item.category !== selectedSpecialty.category);
            setSelectedSpecialties(updatedSpecialties);
        } else {
            const newSpecialty = { category: selectedSpecialty.category, isPrimary: selectedSpecialty.category === primarySpecialty};
            setSelectedSpecialties([...selectedSpecialties, newSpecialty]);
            updatedSpecialties = [...selectedSpecialties, newSpecialty];
        }
        dispatch(setFormField({ key: "specialties", value: updatedSpecialties}))
    };

    const summarizeGroups = () => {
        if (userGroups && userGroups.length >= 1 && userGroups[0].hasOwnProperty("subGroupId") && groupData.length >= 1) {
            return userGroups.map(({ organizationId, groupId, subGroupId, teamId }) => {
                const organization = groupData.find(org => org.organizationId === organizationId);
                if (!organization) return null;
    
                const group = organization.groups.find(grp => grp.groupId === groupId);
                if (!group) return null;
    
                const subgroup = group.subgroups.find(sub => sub.subGroupId === subGroupId);
                if (!subgroup) return null;
    
                const team = subgroup.teams.find(t => t.teamId === teamId);
                if (!team) return null;
    
                return {
                    organizationName: organization.organizationName,
                    groupName: group.groupName,
                    subGroupName: subgroup.subGroupName,
                    teamName: team.teamName,
                    teamId: teamId,
                };
            }).filter(Boolean);
        } else {
            return [];
        }
    };    
    
    
    const groupSummary = summarizeGroups();

    const handleOrganization = (e) => {
        setSelectedOrganization(Number(e.target.value));
        let groupsList = initGroupList(Number(e.target.value));
        dispatch(setFormField({key:"groups",value:[]}));
        setGroupsDisplay(groupsList);
        setSelectedGroups([]);
    };

    const removeOrganization = (teamId) => {
        const newGroups = userGroups.filter((team) => team.teamId !== teamId);
        dispatch(setFormField({key:"groups",value:newGroups}));
    };

    const checkSelectedItem = (groupId) => {
        let groupInList = false;
        selectedGroups.forEach((item)=>{
            if (item.groupId===groupId){
                groupInList=true;
            } 
        });
        return groupInList;
    };

    const handleGroupChange = (group) => {
        let groupInList = checkSelectedItem(group.groupId);
        let newGroups = [];
        if (groupInList) {
            // remove from selectedGroups and from userData
            setSelectedGroups(selectedGroups.filter(item => item.groupId !== group.groupId));
            newGroups = userGroups.filter(item => item.groupId !== group.groupId);
        } else {
            setSelectedGroups([group, ...selectedGroups]);
            if (userGroups.length === 1 && userGroups[0].groupId === 0) {
                newGroups = [{groupId:group.groupId, organizationId:selectedOrganization}];
            } else {
                newGroups = [{groupId:group.groupId, organizationId:selectedOrganization},...userGroups];
            }
        }
        dispatch(setFormField({key:"groups",value:newGroups}));
    };

    const dispatchGroups = (groups) => {
        dispatch(setFormField({ key: "groups", value: groups }));
    };

    const handleIDChange = (e) => {
        if (e.target.id === "employeeId") {
            setSelectedSpecialties([]);
            setPrimarySpecialty("");
            dispatch(setFormField({ key: "specialties", value: [] }));
            dispatch(setFormField({ key: "npi", value: ''}));
        } else {
            dispatch(setFormField({ key: "employeeId", value: ''}));
        }
        setEmployeeInfo(e.target.value);
    };

    return (
        <div className="create-account-inputs" style={{height:"100%"}}>
            <div style={groupsErrorShowing ? {color:"red"}:{}}className="input-name">
                Group Info
            </div>
            {roleCategory!==("Admin") && 
                <button type="button" style={groupsErrorShowing ? {border:"1px solid red"}:{}} className="group-button" onClick={toggleGroupSelectVisibility}>
                    Assign
                </button>
            }
            {isGroupSelectVisible && 
                    <GroupSelectPopup 
                        initialSelections={userGroups}
                        organizationData={signedInUserOrganizationData}
                        handleSave={dispatchGroups}
                        onClose={toggleGroupSelectVisibility}
                    />
            }
            {roleCategory!==("Admin") && 
                <div>
                    <ul className="groups-list">
                        {groupSummary.map((group) => (
                            <li
                                key={group.teamId}
                                className="group-list-item"
                            >
                                {group.groupName} - {group.subGroupName} - {group.teamName}
                                <div style={{cursor:"pointer"}} onClick={()=>removeOrganization(group.teamId)}>
                                    <TrashIcon />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>   
            }
            {roleCategory===("Admin") &&
                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <div style={{position: "relative"}}>
                        <span className="floating-label">Organization</span>
                        <select 
                            className={inputStyles["styled-input-select"]}
                            id="groupId"
                            value={selectedOrganization}
                            style={groupsErrorShowing ? {border:"1px solid red", width:"300px"}:{width:"300px"}}
                            onChange={handleOrganization}
                        >
                            <option value={0}>Select an Organization</option>
                            {groupData.map((org)=> (
                                <option key={org.organizationId} id={org.organizationName} value={org.organizationId}>{org.organizationName}</option>
                            ))}
                        </select>
                    </div> 
                    {selectedOrganization !== 0 && 
                        <MultiSelectDropdown 
                            options={groupsDisplay}
                            label='groupName'
                            checkedKey='groupId'
                            checkSelectedItem={checkSelectedItem}
                            handleChange={handleGroupChange}
                            selectedItems={selectedGroups}
                            name='Groups'
                            error={groupsErrorShowing}
                        />
                    }
                </div>
                
            }
            <div className="input-name">
                Role Info
            </div>
            {roleCategory!=="Patient" &&
                <div>
                    <div style={{display:"flex", marginBottom:"10px", columnGap:"10px"}}>
                        <div>
                            <input
                                id="npi" 
                                type="radio"
                                value="npi"
                                checked={employeeInfo === "npi"}
                                onChange={handleIDChange}
                            /> 
                            <span style={{paddingLeft: "5px"}}>NPI Number</span>
                        </div>
                        <div>
                            <input
                                id="employeeId" 
                                type="radio"
                                value="employeeId"
                                checked={employeeInfo === "employeeId"}
                                onChange={handleIDChange}
                            /> 

                            <span style={{paddingLeft: "5px"}}>Employee ID</span>
                        </div>
                    </div>
                    {employeeInfo === "npi" ?
                        <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                            <MultiSelectDropdown 
                                options={specialtiesList}
                                label='category'
                                checkedKey='category'
                                checkSelectedItem={checkSelectedSpecialty}
                                handleChange={handleSpecialtiesChange}
                                selectedItems={selectedSpecialties}
                                name='Specialties'
                            />
                            {selectedSpecialties.length >= 1 && selectedSpecialties[0].category !== '' &&
                                <div className="input-name">
                                    <span>Select Primary Specialty</span>
                                    <ul className="primary-specialties">
                                        {selectedSpecialties.map((specialty) => (
                                            <li
                                                key={specialty.category}
                                                className={primarySpecialty === specialty.category ? 'li-specialty' : 'li-primary'}
                                            >
                                                <div style={{alignItems:"center", display:"flex", columnGap:"10px"}} onClick={() => handlePrimaryClick(specialty)}>
                                                    {primarySpecialty === specialty.category &&
                                                        <SpecialtySelectedIcon />
                                                    }
                                                    {specialty.category}
                                                </div>
                                                <div style={{alignSelf:"center", display:"flex"}} onClick={()=>handleSpecialtiesChange(specialty)}>
                                                    <RemoveIcon />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            <div className={inputStyles["styled-input-container"]}>
                                <input
                                    style={npiErrorShowing ? {border:"1px solid red", width: "300px"}:{width: "300px"}}
                                    id="npi"
                                    defaultValue={userData.npi}
                                    onChange={(e) => dispatch(setFormField({key:"npi",value:e.target.value}))}
                                ></input>
                                <label className={userData.npi && inputStyles['filled']}>
                                    NPI**
                                </label>
                            </div>
                        </div>
                        :
                        <div className={inputStyles["styled-input-container"]}>
                            <input
                                id="employeeId"
                                type="text"
                                style={employeeIdErrorShowing ? {border:"1px solid red", width: "300px"}:{width: "300px"}}
                                defaultValue={userData.employeeId}
                                onChange={(e) => dispatch(setFormField({key:"employeeId",value:e.target.value}))}
                            ></input>
                            <label className={userData.employeeId && inputStyles['filled']}>
                                Employee Id *
                            </label>
                        </div>
                    }
                    <div style={{marginTop:"10px"}} className={inputStyles["styled-input-container"]}>
                        <input
                            id="title"
                            defaultValue={userData.title || ""}
                            style={{width: "300px"}}
                            onChange={(e) => dispatch(setFormField({key:"title",value:e.target.value}))}
                        ></input>
                        <label className={userData.title && inputStyles['filled']}>
                            Staff Title
                        </label>
                    </div>
                </div>
            }
            {roleCategory==="Patient" &&
                <div style={{display: "flex", flexDirection:"column", rowGap: "10px"}}>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="mrn"
                            defaultValue={userData.mrn}
                            style={{width: "300px"}}
                            onChange={(e) => dispatch(setFormField({key:"mrn",value:e.target.value}))}
                        ></input>
                        <label className={userData.mrn && inputStyles['filled']}>
                            Medical Record Number
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]} style={{width:"300px"}}>
                        <input
                            type={
                                showPassword ? "text" : "password"
                            }
                            maxLength="9"
                            id="ssn"
                            style={{width: "300px"}}
                            defaultValue={userData.ssn}
                            onChange={(e) => dispatch(setFormField({key:"ssn",value:e.target.value}))}
                        ></input>
                        <label className={userData.ssn && inputStyles['filled']}>
                            Social Security Number
                        </label>
                        <div className={inputStyles["styled-icon-container"]} onClick={() =>
                                setShowPassword((prev) => !prev)}
                        >
                            {showPassword ? <FontAwesomeIcon icon="fa-regular fa-eye" className="eye-icon" size="lg"/>
                                : <FontAwesomeIcon icon="fa-regular fa-eye-slash" className="eye-icon" size="lg"/> }
                        </div>
                    </div>
                </div>
            }
            <div style={{display:"flex", columnGap:"5px", alignItems:"center", marginTop:"10px"}}>
                <Form>
                    <Form.Check
                        style={{paddingBottom:"2px"}}
                        type="switch"
                        id="custom-switch"
                        checked={userData.canHaveAppointment}
                        onChange={(e) => dispatch(setFormField({key:"canHaveAppointment",value:e.target.checked}))}
                    />
                </Form>
                <div className="input-name">Appointment Eligible</div>
                <OverlayTrigger placement="right" overlay={AppointmentsTooltip}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: '#686869'}}/>
                </OverlayTrigger>
            </div>
            <div style={{display:"flex", columnGap:"5px", alignItems:"center", marginTop:"10px"}}>
                <Form>
                    <Form.Check
                        style={{paddingBottom:"2px"}}
                        type="switch"
                        id="custom-switch"
                        checked={userData.canManageAvailability}
                        onChange={(e) => dispatch(setFormField({key:"canManageAvailability",value:e.target.checked}))}
                    />
                </Form>
                <div className="input-name">Manage Availability</div>
                <OverlayTrigger placement="right" overlay={AvailabilityTooltip}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: '#686869'}}/>
                </OverlayTrigger>
            </div>
            <div style={{marginTop:"20px"}} className="input-name">
                Profile Photo
            </div>
            <div style={{width:"300px"}}>
                <FileUploadOrganization
                    fileType='image'
                    onDrop={setProfileFile}
                    imageURL={userData.profilePictureURL} 
                    acceptedTypes={"PNG,JPG | 8MB Max"}
                />
            </div>
        </div>
  );
};

export default GroupRoleInfo;
