import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

const initialModalities = {
  heartRate: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 2,
    modalityName: 'Heart Rate',
    targetValue: null,
    upperLimit: null,
  },
  bloodPressure: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 5,
    modalityName: 'Blood Pressure',
    targetValue: null,
    upperLimit: null,
  },
  oxygenSaturation: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 3,
    modalityName: 'Oxygen Saturation',
    targetValue: null,
    upperLimit: null,
  },
  temperature: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 1,
    modalityName: 'Temperature',
    targetValue: null,
    upperLimit: null,
  },
  weight: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 8,
    modalityName: 'Weight',
    targetValue: null,
    upperLimit: null,
  },
  ECG: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 4,
    modalityName: 'ECG - HR',
    targetValue: null,
    upperLimit: null,
  },
  ECGHRV: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 15,
    modalityName: 'ECG - HRV',
    targetValue: null,
    upperLimit: null,
  },
  respiratoryRate: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 6,
    modalityName: 'Respiratory Rate',
    targetValue: null,
    upperLimit: null,
  },
  AUSC: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 7,
    modalityName: 'Auscultation',
    targetValue: null,
    upperLimit: null,
  },
  glucoseLevel: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 11,
    modalityName: 'Glucose Level',
    targetValue: null,
    upperLimit: null,
  },
  steps: {
    isEnabled: false,
    deviceName: '',
    lowerLimit: null,
    modalityId: 13,
    modalityName: 'Steps',
    targetValue: null,
    upperLimit: null,
  },
};

const initialState = {
  isHeartRate: false,
  heartRateDeviceName: '',
  isBloodPressure: false,
  bloodPressureDeviceName: '',
  isOxygenSaturation: false,
  oxygenSaturationDeviceName: '',
  isTemperature: false,
  temperatureDeviceName: '',
  isWeight: false,
  weightDeviceName: '',
  isECG: false,
  ecgDeviceName: '',
  isECGHRV: false,
  ecgHRVDeviceName: '',
  isRespiratoryRate: false,
  respiratoryRateDeviceName: '',
  isAUSC: false,
  auscDeviceName: '',
  isGlucoseLevel: false,
  glucoseLevelDeviceName: '',
  isSteps: false,
  stepsDeviceName: '',
  modalities: { ...initialModalities }, // Include modalities in the initial state
};

const vitalSlice = createSlice({
  name: 'vital',
  initialState,
  reducers: {
    resetVitalState: () => initialState,
    setItem: (state, action) => {
      const { key, value } = action.payload;
      return produce(state, draftState => {
        draftState[key] = value;
      });
    },
    setModalityEnabled: (state, action) => {
      const { modalityName, isEnabled } = action.payload;
      return produce(state, draftState => {
        draftState.modalities[modalityName].isEnabled = isEnabled;
      });
    },
    setModality: (state, action) => {
      const { modalityId, updates } = action.payload;
      const modalityName = Object.keys(initialModalities).find(key => initialModalities[key].modalityId === modalityId);
      if (modalityName) {
        return produce(state, draftState => {
          draftState.modalities[modalityName] = {
            ...draftState.modalities[modalityName],
            ...updates,
          };
        });
      }
    },
  },
});

export const {
  resetVitalState,
  setItem,
  setModalityEnabled,
  setModality,
} = vitalSlice.actions;

export default vitalSlice.reducer;
