import React, { useState } from 'react';
import styles from '../PatientEnrollment.module.css';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { TrashIcon } from '../../../components/icons/Icons';
import { setModFrequency, removeModFrequency } from '../PatientModalitiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import inputStyles from '../../program-management/ProgramManagement.module.css';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const ModalityFrequencySetupCard = (props) => {
    const {
        options,
        modalityId
    } = props;

    const repeatIntervals = [
        {value:"day", label:"Day"},
        {value:"hour", label:"Hour"},
        {value:"month", label:"Month"},
    ];

    const increaseIntervals = [
        {value:"1", label:"1X"},
        {value:"2", label:"2X"},
        {value:"3", label:"3X"},
    ];

    const increaseTimePeriods = [
        {value:"1", label:"1 hour"},
        {value:"2", label:"2 hours"},
        {value:"6", label:"6 hours"},
        {value:"24", label:"24 hours"},
    ];

    const dispatch = useDispatch();
    const allModalityFrequencies = useSelector((state)=>state.patientEnrollmentModalities.modalityFrequencies);
    const modalityFrequency = allModalityFrequencies.find(item => item.modalityId === modalityId);

    const handleDelete = () => {
        dispatch(removeModFrequency(modalityId));
    };

    const handleChange = (e) => {
        const id = e.target.id;
        let value = e.target.value;
        if (id !== "timeInterval" && id !== "thresholdType") {
            value = parseInt(value);
        }
        dispatch(setModFrequency({key:id,value:value, modalityId:modalityId}));
    };

    const handleModalityChange = (e) => {
        const {id, value} = e.target;
        if (allModalityFrequencies.some((mod) => mod.modalityId === parseInt(value))) {
            dispatch(
                showAlert({
                    header: "Error",
                    message: "Each Modality Frequency rule must have a unique id",
                    type: 'danger',
                })
            );
            dispatch(setModFrequency({key:id,value:0, modalityId:modalityId}));
        } else {
            dispatch(setModFrequency({key:id,value:parseInt(value), modalityId:modalityId}));
        }
    };

    return (
        <div className={styles.notificationSetupContainer}>
            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                <div style={{display: "flex", columnGap: "12px", alignItems: "center"}}>
                    <span>Notify me if</span>
                    <select 
                        className={inputStyles.InputStyling} 
                        id="modalityId"
                        style={{width:"175px"}}
                        value={modalityFrequency.modalityId}
                        onChange={handleModalityChange}
                    >
                        <option value={0}>
                            {"Select Modality"}
                        </option>
                        {options.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                    <span>is</span>
                    <select 
                        className={inputStyles.InputStyling} 
                        id="thresholdType"
                        style={{width:"90px"}}
                        defaultValue={modalityFrequency.thresholdType}
                        onChange={handleChange}
                    >
                        <option value={"above"}>
                            {"above"}
                        </option>
                        <option value={"below"}>
                            {"below"}
                        </option>
                    </select>
                    <span>the threshold</span>
                    <select 
                        className={inputStyles.InputStyling} 
                        id="thresholdCount"
                        style={{width:"80px"}}
                        defaultValue={modalityFrequency.thresholdCount}
                        onChange={handleChange}
                    >
                        {Array.from(Array(10).keys()).map((index) => (
                            <option key={index + 1} value={index + 1}>
                                {index + 1}
                            </option>
                        ))}
                    </select>
                    <span>times in</span>
                    <select 
                        className={inputStyles.InputStyling} 
                        id="timeFrequency"
                        style={{width:"80px"}}
                        defaultValue={modalityFrequency.timeFrequency}
                        onChange={handleChange}
                    >
                        {Array.from(Array(10).keys()).map((index) => (
                            <option key={index + 1} value={index + 1}>
                                {index + 1}
                            </option>
                        ))}
                    </select>
                    <select 
                        className={inputStyles.InputStyling} 
                        id="timeInterval"
                        style={{width:"104px"}}
                        defaultValue={modalityFrequency.timeInterval}
                        onChange={handleChange}
                    >
                        {repeatIntervals.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{display: "flex", gap: "12px", alignItems: "center"}}>
                        <span>increase frequency for </span>
                        <select 
                            className={inputStyles.InputStyling} 
                            id="frequencyMultiplier"
                            style={{width:"80px"}}
                            defaultValue={modalityFrequency.frequencyMultiplier}
                            onChange={handleChange}
                        >
                            {increaseIntervals.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <span>for </span>
                        <select 
                            className={inputStyles.InputStyling} 
                            id="frequencyDurationValue"
                            style={{width:"104px"}}
                            defaultValue={modalityFrequency.frequencyDurationValue}
                            onChange={handleChange}
                        >
                            {increaseTimePeriods.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>        
                    </div>
            </div>
            <div style={{display:"flex", alignItems:"center"}} onClick={handleDelete}>
                <TrashIcon />
            </div>
        </div>
    )

}

export default ModalityFrequencySetupCard;