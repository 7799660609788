import React, {useState} from "react";
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import styles from './PatientEnrollmentPopUp.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setEnrollmentState, setReenrollment, setFormValue, setGroups } from "./PatientEnrollmentSlice";
import { customStyles } from "../../components/AccountManagement/CustomStyles";
import { UserProfilePictureIcon, XOut } from "../../components/icons/Icons";
import { showAlert } from "../../components/PerinHealthAlertSlice";
import useAxios from "../../services/AxiosConfig";
import modelStyles from '../../components/PerinHealthModel.module.css';


const PatientEnrollmentPopUp = ({type, item, onClose}) => {
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const labels = {
        remove: {
            title: "Discharge",
            buttonLabel: "Discharge"
        },
        update: {
            title: "Update Care Program",
            buttonLabel: "Update"
        },
        reenroll: {
            title: "Re-enroll in a Care Program",
            buttonLabel: "Re-enroll"
        },
        view: {
            title: "View Program (read-only)",
            buttonLabel: "View"
        }
    };

    const dispatch = useDispatch();
    const [page, setPage] = useState("selecting");
    const [displayMessage, setDisplayMessage] = useState("");
    
    // use previouslyEnrolledSelection if re-enrolling and has active enrollments
    const programs = (type === "reenroll" && item.previouslyEnrolledSelection.length !== 0) ? item.previouslyEnrolledSelection.map((program)=> (
        {value:program.id, label:program.name})) : item.selectOptions.map((program)=> (
            {value:program.id, label:program.name}));
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    
    const navigate = useNavigate();

    const onActionHandler = (e) => {
        e.preventDefault();
        if (type === "remove") {
            if (page==="selecting") {
                if (selectedPrograms.length===programs.length) {
                    setDisplayMessage(`Are you sure you want to remove all programs for ${item.name}? By removing all programs, you will unenroll this patient.`);
                } else {
                    let displayNames = "";
                    if (selectedPrograms.length >= 2) {
                        displayNames = `${selectedPrograms.length} programs`;
                    } else {
                        displayNames = `"${selectedPrograms[0].label}" program`
                    }
                    setDisplayMessage(`Are you sure you want to remove ${displayNames} for ${item.name}?`);
                }
                setPage("confirming");
            } else {
                const deletePromises = selectedPrograms.map((program) => {
                    return httpService.delete(`careprogram/patient/enroll/${program.value}`);
                });
        
                Promise.all(deletePromises)
                    .then((responses) => {
                        dispatch(
                            showAlert({
                                header: "Patient Discharged",
                                message: "",
                                type: 'success'
                            })
                        );
                    })
                    .catch((errors) => {
                        showAlert({
                            header: "Error",
                            message: "There was an error discharging from this program",
                            type: 'danger'
                        })
                    });
                onClose(true);
            }
        } else {
            const data = item.data;
            const updatingInfo = data.find((enrollment)=> enrollment.enrollmentId === selectedPrograms.value);
            dispatch(setEnrollmentState(updatingInfo));
            if (type==='reenroll') {
                dispatch(setReenrollment());
            }
            if (type==='update') {
                dispatch(setFormValue({key:"updatingProgramOptions", value:data.filter((item) => !item.enrollment.dischargeDate)}));
            }
            if (type==='view') {
                const groups = updatingInfo.enrollment.groups;
                const organizationId = updatingInfo.hasOwnProperty("organizationId") ? updatingInfo.organizationId : false;
                const newGroups = groups.map((group)=> {
                    return organizationId ? {...group,organizationId:organizationId} : group
                });
                dispatch(setGroups(newGroups));
                dispatch(setFormValue({key:"viewing", value:true}));
                dispatch(setFormValue({key:"updating", value:false}));
                navigate('/view-program');
            }
            if (type !=='view') {
                navigate('/enroll-patient');
            }
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <div className={styles.PopupOverlay}>
            <div className={styles.PopupContainer}>
                <div onClick={()=>onClose()} className={styles.XOut}>
                    <XOut />
                </div>
                <div className={styles.PopupTitle}>
                    {labels[type].title}
                </div>
                {page==="confirming" &&
                    <div className={styles.PopupMessage}>
                        {displayMessage}
                    </div>
                }
                {page === 'selecting' && type==='remove' &&
                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <span className={styles.PopupMessage}>Please verify patient discharge details:</span>
                    <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <span className={styles.InitialsIcon}>{`${item.firstName[0]}${item.lastName[0]}`}</span>
                            <UserProfilePictureIcon />
                        </div>
                        {item.name}      
                    </div>
                </div>
                }
                {page==='selecting' && 
                    <Select
                        options={programs}
                        styles={customStyles.addressInput}
                        className="dropdown-input"
                        placeholder='Select a program'
                        isSearchable={true}
                        isMulti={type==="remove"}
                        onChange={(selected) => setSelectedPrograms(selected)}
                    />
                }
                <div style={{display:"flex",justifyContent:"space-between", width:"100%", alignSelf:"center", borderTop:"1px solid #B9B9B9", paddingTop:"10px"}}>
                    <button onClick={()=>onClose()} className={modelStyles.SecondaryButton}>
                        Cancel
                    </button>
                    <button onClick={onActionHandler} disabled={selectedPrograms.length === 0} className={modelStyles.ModelButton}>
                        {labels[type].buttonLabel}
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
}

export default PatientEnrollmentPopUp;