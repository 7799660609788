import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSearchText, setDateRange, setSelectedTaskTypes } from './TasksSlice';
import DateRangePicker from './DateRangePicker';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import styles from '../../components/FilterManagementView.module.css';
import PerinHealthModal from '../../components/PerinHealthModel';
import { closeModal, openModal } from '../../components/PerinHealthModelSlice';

const TaskFilterView = (props) => {
    const dispatch = useDispatch();
    const {
        taskOptions,
        selectedTaskTypes = [],
        selectedRows,
        searchText,
        dateRange, filter
    } = useSelector((state) => state.taskListPanel);
    const perinHealthModal = useSelector((state) => state.perinHealthModal);
    const addDisabled = selectedRows.length <= 0;;

    const handleSearchChange = (e) => {
        dispatch(setSearchText(e.target.value));
    };

    const handleDateRangeChange = (range) => {
        dispatch(setDateRange(range));
    };

    const safeSelectedTaskTypes = Array.isArray(selectedTaskTypes) ? selectedTaskTypes : [];

    const checkSelectedItem = (value) => {
        return safeSelectedTaskTypes.some(item => item.value === value);
    };

    const handleTaskChange = (item) => {
        if (checkSelectedItem(item.value)) {
            // Remove item from selectedTaskTypes
            const updatedSelectedTaskTypes = safeSelectedTaskTypes.filter(selectedItem => selectedItem?.type?.toLowerCase().includes(searchText?.toLowerCase(item.value)));
            dispatch(setSelectedTaskTypes(updatedSelectedTaskTypes));
        } else {
            // Add item to selectedTaskTypes
            const updatedSelectedTaskTypes = [...safeSelectedTaskTypes, item];
            dispatch(setSelectedTaskTypes(updatedSelectedTaskTypes));
        }
    };

    const onClickHandler = (event) => {
        if(event.target.textContent === 'Mark as Complete'){
            dispatch(
                openModal({
                    header: "Mark as Complete?",
                    message: "Marked tasks will be considered finished and will move to the Completed Tasks tab.",
                    buttons: [
                        { label: 'No', variant: 'secondary', onClickKey: 'NO' },
                        { label: 'Yes', variant: 'primary', onClickKey: 'YES_MARK_COMPLETE' },
                    ],
                })
            );
        }else if(event.target.textContent === 'Archive'){
            dispatch(
                openModal({
                    header: "Archive Task?",
                    message: "Archived tasks will no longer be available. To bring back the archived tasks, contact IT at: [phone number].",
                    buttons: [
                        { label: 'No', variant: 'secondary', onClickKey: 'NO' },
                        { label: 'Yes', variant: 'primary', onClickKey: 'YES_ARCHIVE' },
                    ],
                })
            );
        }
    };

    return (
            <div className={styles.TaskSection}>
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                    <div style={{ position: "absolute", paddingLeft: "10px" }}>
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size="xs" style={{ color: "D9D9D9" }} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        className={styles.SearchInput}
                    />
                </div>

                <div style={{ position: "relative" }}>
                    <DateRangePicker 
                        onChange={handleDateRangeChange}
                        value={dateRange}
                    />
                </div>
                <div style={{ position: "relative" }}>
                    <MultiSelectDropdown inputWidth='25vh'
                        options={taskOptions?.filter((task) => task?.status === filter?.value)}
                        label='label'
                        checkedKey='value'
                        checkSelectedItem={checkSelectedItem}
                        handleChange={handleTaskChange}
                        selectedItems={safeSelectedTaskTypes}
                        name='Task Type'
                    />
                </div>

                <Button
                    variant="secondary"
                    className={addDisabled ? styles.AddContentButtonDisabled : styles.AddContentButton}
                    disabled={addDisabled}
                    onClick={(event) => onClickHandler(event)}
                >
                    {filter?.value === 'Pending' ? 'Mark as Complete' : 'Archive'}
                </Button>

            </div>
    );
};

export default TaskFilterView;
