import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import FilterManagementView from '../../components/FilterManagementView';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import useAxios from '../../services/AxiosConfig';

const ReferenceCodeManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const headers = [
        { key: 'id', label: "ID", sortable: true },
        { key: 'codeName', label: "Code Name", sortable: true },
        { key: 'description', label: "Description", sortable: true },
        { key: 'column', label: "Column", sortable: true },
        { key: 'rows', label: "Rows", sortable: true }
    ];

    const onRowClickHandler = (item) => {
        navigate('/add-reference-code');
    };   
    
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const userAccountId = useSelector((state) => state.userInfo.id);
    const axios = useAxios(axiosConfig.envURL);

    // useEffect(() => {
    //     const fetchData = async () => {
    //     try {
    //         const reportResponse = await axios.get(`/contact/list/open/nonCriticalReport/${userAccountId}`);
    //         const formatted = reportResponse.data.data.map(item => {
    //             let retItem = item;
    //             const utcDate = new Date(item.date);
    //             retItem.date = utcDate.toLocaleDateString();
    //             return retItem;
    //         })
    //         setReportData(formatted);
    //     } catch (error) {
    //         console.error("Failed to fetch data:", error);
    //     }
    //     };
    //     fetchData();
    // }, [axios, userAccountId]);

    const onAddReferenceCodeHandler = () => {
        navigate('/add-reference-code');
    };

    return (
        <div className={managementStyles.MainContent}>
           {/*  <div className={managementStyles.Title}>Reference Code</div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={false}
                    isTeam={false}
                    addButtonLabel='Add Reference Code'
                    onAddHandler={onAddReferenceCodeHandler}
                    showDeleteButton={false}
                />
                <PerinHealthGrid
                    dataList={data}
                    showProfilePicture={'name'}
                    headers={headers}
                    showCheckbox={false}
                    showHeaderFilter={true}
                    onRowValueClick={onRowClickHandler}
                    gridHeight='100%'
                />     
            </div>
        </div>
    );
}

export default ReferenceCodeManagement;
