import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import inputStyles from '../../../ManagementPages.module.css';
import styles from '../../Patient.module.css';
import { setFormField } from '../../../../slicers/PatientSlice';
import { ExpandedArrow, CollapsedArrow } from '../../../../components/icons/Icons';
import FileUploadOrganization from '../../../new-organization/add-edit-organization/FileUploadOrganization';

const GroupInfoDetail= ({ setModal, setUpdateFn, setCancelFn }) => {

    const dispatch = useDispatch();
    const patient = useSelector((state)=>state.patient);
    const {orgHierarchy, mrn, ssn, firstName, lastName} = patient;

   
    const initialState = {
        mrn: mrn || "",
        ssn: ssn || ""
    };
    const [updatingState, setUpdatingState] = useState(initialState);
    const [showSSN, setShowSSN] = useState(false);
    const [showUpdateButtons, setShowUpdateButtons] = useState(false);

    const handleChange = (e) => {
        const tempData = {...updatingState};
        tempData[e.target.id] = e.target.value;
        setUpdatingState(tempData);
        if (!showUpdateButtons) {
            setShowUpdateButtons(true);
        }
    };

    const handleUpdate = () => {
        const updatingInfo = {
            ...patient,
            mrn:updatingState.mrn,
            ssn:updatingState.ssn,
        };
        const updateFn = () => {
            setShowUpdateButtons(false);
            dispatch(setFormField({key:"mrn", value:updatingState.mrn}));
            dispatch(setFormField({key:"ssn", value:updatingState.ssn}));
        };
        setUpdateFn(()=>updateFn);
        setCancelFn(()=>handleCancel);
        setModal({title:"Patient Info", message:`Update ${firstName} ${lastName}'s info?`, data:updatingInfo});
    };

    const handleCancel = () => {
        setUpdatingState(initialState);
        setShowUpdateButtons(false);
    };

    const handleProfileDrop = (acceptedFiles) => {
        const logoFile = acceptedFiles[0];
        if (logoFile) {
            const objectURL = URL.createObjectURL(logoFile);
            dispatch(setFormField({ key: "profilePictureURL", value: objectURL }));
        }
    };

    const TreeNode = ({node}) => {
        const [isExpanded, setIsExpanded] = useState(true);
        const nodeName = node.groupName || node.subGroupName || node.teamName;
        const children = node.subgroups || node.teams || [];
        const hasChildren = children.length > 0;
        const toggleExpand = () => setIsExpanded(!isExpanded);

        return (
            <li>
                <div className={styles["group-list-name"]}>
                    {hasChildren &&
                        <div className={styles["group-list-name"]} onClick={(toggleExpand)}>
                            {isExpanded ? <ExpandedArrow /> : <CollapsedArrow />} 
                        </div>
                    }
                    <span>{nodeName}</span>
                </div>
                {hasChildren && isExpanded &&
                    <ul className={styles["group-list-item"]}>
                        {children.map((child) => {
                            const childId = child.subGroupId || child.teamId;
                            return (
                                <TreeNode
                                    key={childId}
                                    node={child}
                                />
                            );
                        })}
                    </ul>
                }
            </li>
        )
    };

    const groupsDisplay = () => {
        return (
            orgHierarchy.map((org)=>(
                <ul key={org.organizationId} style={{paddingLeft:"0"}} className={styles["group-list-item"]}>
                    {org.groups &&
                        org.groups.map((group)=>(
                            <div key={group}>
                                <TreeNode node={group}/>
                            </div>
                        ))
                    }
                </ul>
            ))
        )
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", justifyContent:"space-between"}}>
            {groupsDisplay()}
            <span className="subtitle-2" style={{color:"#4F4F4F"}}>Patient Info</span>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="mrn"
                    value={updatingState.mrn}
                    onChange={handleChange}
                ></input>
                <label className={updatingState.mrn && inputStyles['filled']}>
                    Medical Record Number
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]} style={{width:"300px"}}>
                <input
                    type={showSSN ? "text" : "password"}
                    maxLength="9"
                    id="ssn"
                    value={updatingState.ssn}
                    onChange={handleChange}
                ></input>
                <label className={updatingState.ssn && inputStyles['filled']}>
                    Social Security Number
                </label>
                <div className={inputStyles["styled-icon-container"]} onClick={() =>
                        setShowSSN((prev) => !prev)}
                >
                    {showSSN ? <FontAwesomeIcon icon="fa-regular fa-eye" className="eye-icon" size="lg"/>
                        : <FontAwesomeIcon icon="fa-regular fa-eye-slash" className="eye-icon" size="lg"/> }
                </div>
            </div>
            <div style={{width:"300px"}}>
                <FileUploadOrganization
                    fileType='image'
                    onDrop={handleProfileDrop}
                    imageURL={patient.profilePictureURL} 
                    acceptedTypes={"PNG,JPG | 8MB Max"}
                />
            </div>
            {showUpdateButtons &&
                <div style={{display:"flex", width: "100%", justifyContent:"space-between"}}>
                    <button variant='primary' className={styles.ModelButton} onClick={handleUpdate} >Save</button>
                    <button variant='secondary' className={styles.SecondaryButton} onClick={handleCancel} >Cancel</button>
                </div>
            }
        </div>
    );
}

export default GroupInfoDetail;