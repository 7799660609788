import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ProgramManagement.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setQuestionnaire, removeQuestionnaire } from './AddProgramSlice';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';

const QuestionnaireSetup = ({errors}) => {

    const questionnaires = useSelector((state)=>state.programTemplateLists.questionnaires);
    const dispatch = useDispatch();
    const questionnaireList = useSelector((state)=>state.addProgram.questionnaires);

    const initSelectedQuestionnaires = () => {
        // check to see if questionnaire already exists in the state,
        const tempQs = [];
        questionnaireList.forEach((item)=> {
            let existingQIndex = questionnaires.findIndex(q => q.questionnaireId === item.questionnaireId);
            if (existingQIndex !== -1){
                // if addProgram.questionnaire has a match in the questionnaire list, add to selectedQuestionnaires
                tempQs.push(questionnaires[existingQIndex]);
            } else {
                tempQs.push({
                    id: item.questionnaireId,
                    name: item.questionnaireName
                });
            }
        })
        return tempQs;
    };

    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState(initSelectedQuestionnaires());
    const checkSelectedItem = (id) => {
        let qInList = false;
        selectedQuestionnaires.forEach((item)=>{
            if (item.questionnaireId===id){
                qInList=true;
            } 
        });
        return qInList;
    };

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const handleQuestionnaireChange = (q) => {
        let qInList = false;
        selectedQuestionnaires.forEach((item)=>{
            if (item.questionnaireId===q.questionnaireId){
                qInList = true;
            } 
        });
        if (qInList){
            setSelectedQuestionnaires(selectedQuestionnaires.filter(item => item.questionnaireId !== q.questionnaireId));
            dispatch(removeQuestionnaire(q.questionnaireId))
        } else {
            setSelectedQuestionnaires([q, ...selectedQuestionnaires]);
            let id = q.questionnaireId;
            let questionnaireName = q.name;
            dispatch(setQuestionnaire(
                {
                    id, 
                    questionnaireName,
                    startTime: getInitialStartTime(),
                    frequency: 1,
                    interval: "Day"
                }
            ))
        }
    };

    const removeQuestionnaireCard = (id) => {
        setSelectedQuestionnaires(selectedQuestionnaires.filter(item => item.questionnaireId !== id));
        dispatch(removeQuestionnaire(id));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <MultiSelectDropdown 
                options={questionnaires}
                label='name'
                checkedKey='questionnaireId'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleQuestionnaireChange}
                selectedItems={selectedQuestionnaires}
                name='Questionnaires'
            />
            {errors.showing && 
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span 
                        className={styles.ValidationMessage}
                    >
                        {errors.message}
                    </span>
                </div>
            }
            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                {selectedQuestionnaires.map((q, index) => (
                    <div key={q.questionnaireId}>
                        <ManagementDropdownCard 
                            open={index!==0 ? false : true} 
                            contentInfo={{title:q.name, id:q.questionnaireId}}
                            cardType={"Questionnaire"} 
                            removeHandler={removeQuestionnaireCard}
                            updateHandler={setQuestionnaire}
                            dataList={questionnaireList}
                            errorShowing={errors.showing}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default QuestionnaireSetup;