import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { setContent, removeContent } from './AddProgramSlice';
import styles from './ProgramManagement.module.css';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';

const ContentSetup = ({errors }) => {
    
    const content = useSelector((state)=>state.programTemplateLists.content);
    const dispatch = useDispatch();
    const contentList = useSelector((state)=>state.addProgram.contents);

    const initSelectedContent = () => {
        // check to see if content already exists in the state,
        const tempContent = [];
        contentList.forEach((item, index)=> {
            let existingContentIndex = content.findIndex(content => content.id === item.contentId);
            if (existingContentIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempContent.push(content[existingContentIndex]);
            } else {
                tempContent.push({
                    id: item.contentId, 
                    name: item.contentName
                });
            }
        })
        return tempContent;
    };
    const [selectedContent, setSelectedContent] = useState(initSelectedContent());

    const checkSelectedItem = (id) => {
        let contentInList = false;
        selectedContent.forEach((item)=>{
            if (item.id===id){
                contentInList=true;
            } 
        });
        return contentInList;
    };

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const handleContentChange = (content) => {
        let contentInList = false;
        selectedContent.forEach((item)=>{
            if (item.id===content.id){
                contentInList = true;
            } 
        });
        if (contentInList){
            setSelectedContent(selectedContent.filter(item => item.id !== content.id));
            dispatch(removeContent(content.id))
        } else {
            setSelectedContent([content, ...selectedContent]);
            let id = content.id;
            dispatch(setContent({
                id, 
                startTime: getInitialStartTime(),
                frequency: 1,
                interval: "Day"
            }));
        }
    };

    const removeContentCard = (id) => {
        setSelectedContent(selectedContent.filter(item => item.id !== id));
        dispatch(removeContent(id));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <MultiSelectDropdown 
                options={content}
                label='name'
                checkedKey='id'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleContentChange}
                selectedItems={selectedContent}
                name='Content'
            />
            {errors.contentShowing && 
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span 
                        className={styles.ValidationMessage}
                    >
                        {errors.contentMessage}
                    </span>
                </div>
            }
            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                {selectedContent.map((content, index) => (
                    <div key={content.id}>
                        <ManagementDropdownCard 
                            open={index===0} 
                            contentInfo={{title:content.name, id:content.id}}
                            cardType={"Content"} 
                            removeHandler={removeContentCard}
                            updateHandler={setContent}
                            dataList={contentList}
                            errorShowing={errors.contentShowing}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ContentSetup;