import React, { useState } from 'react';
import styles from './PatientDetailDropdownCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PatientDetailDropdownCard = (props) => {

    const {
        title,
        subtitle,
        subtitle2,
        label,
        icon,
        cardInfo,
        onFirstOpen,
    } = props;

    const [openCardToggle, setOpenCardToggle] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const toggleCardOpen = () => {
        setOpenCardToggle(!openCardToggle);
        if (!hasOpened && onFirstOpen) {
            onFirstOpen();
            setHasOpened(true);
        }
    };

    return (
        <div className={styles.DropdownContainer}>
            <div className={openCardToggle ? styles.OpenPatientCard : styles.ClosedPatientCard}>
                <div className={styles.Header} onClick={toggleCardOpen}>
                    <div className={styles.HeaderLeft}>
                        <div>
                            {openCardToggle ?
                                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                            :
                                <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                            }
                        </div>
                        {icon}
                        <span className={styles.CardTitle}>{decodeURIComponent(title)}</span>
                        {subtitle &&
                            <span style={{fontWeight: "500"}} className={styles.BorderLeftLabel}>{subtitle}</span>
                        } 
                        {subtitle2 &&
                            <span className={styles.BorderLeftLabel}>{subtitle2}</span>
                        } 
                    </div>
                    <div className={styles.HeaderRight}>
                        {label &&
                            <div className={styles.CardTag} style={{backgroundColor:`${label.color}`}}>
                                {label.name}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.PatientCardBody} hidden={!openCardToggle}>
                {cardInfo}
            </div>
        </div>
    )

}

export default PatientDetailDropdownCard;