import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AddEditContent from './content-management/add-edit-content/AddEditContent';
import ContentManagement from './content-management/ContentManagement';
import OverviewDashboard from './overiview-dashboard/OverviewDashboard';
import PatientDetail from './patient/PatientDetail';
import AccountManagement from './account-management/AccountManagement';
import CreateAccount from '../components/AccountManagement/CreateAccount';
import DeviceManagement from './device-management/DeviceManagement';
import AddDevice from './device-management/AddDevice';
import AddQuestionnair from './questionnaire-management/add-edit-questionnaire/AddQuestonnaire';
import QuestionnaireSetup from './questionnaire-management/add-edit-questionnaire/QuestionnaireSetup';
import QuestionnaireManagement from './questionnaire-management/QuestionnaireManagement';
import QuestionPreviewComponent from './questionnaire-management/add-edit-questionnaire/QuestionPreviewComponent';
import OrganizationManagement from './organization-management/OrganizationManagement';
import AddOrganizationMain from './organization-management/add-edit-organization/AddOrganizationMain';
import ProgramManagement from './program-management/ProgramManagement';
import AddProgram from './program-management/AddProgram';
import PatientEnrollment from './patient-enrollment/PatientEnrollment';
import EnrollPatient from './patient-enrollment/EnrollPatient';
import NewOrganization from './new-organization/NewOrganization';
import AddNewOrganization from './new-organization/add-edit-organization/AddNewOrganizationMain';
import RoleManagement from './role-management/RoleManagement';
import AddRoleMain from './role-management/add-edit-role/AddRoleMain';
import { resetAddEditContent } from './content-management/add-edit-content/AddEditContent';
import { resetFilterState } from '../slicers/FilterSlice';
import { useDispatch } from 'react-redux';
import { setDataList, setSelectedRows } from '../components/PerinHealthGridSlice';
import { resetQuestionnaireManagement } from './questionnaire-management/QuestionnaireManagementSlicer';
import { resetContentManagement } from './content-management/ContentManagementSlicer';
import PatientMessage from './patient-message/PatientMessage';
import HubManagement from './hub-management/HubManagement';
import PatientHubManagement from './hub-management/PatientHubManagment';
import CareTeamMainContainer from './care-team-message/CareTeamMainContainer';
import Appointment from './appointment/AppointmentCalendar'
import { resetPatientMessage } from './patient-message/PatientMessageSlicer';
import { resetStaffMessage } from './care-team-message/CareTeamMessageSlicer';
import { resetFilterChatState } from '../slicers/FilterChatSlice';
import { resetPatientDetailsState } from './patient/Slices/PatientDetailSlice';
import { setVideoCallVisibility } from '../components/VideoAndMessageSlice';
import ViewProgram from './patient-enrollment/ViewProgram';
import PatientSubmittedReports from './patient-submitted-reports/PatientSubmittedReports';
import Tasks from './tasks/Tasks'
import { resetTaskState } from './tasks/TasksSlice';
import ReferenceCodeManagement from './reference-code/ReferenceCodeManagement';
import AddReferenceCode from './reference-code/AddReferenceCode';

const MainContent = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(resetFilterState());
        dispatch(setSelectedRows([]));
        dispatch(setDataList());
        dispatch(resetQuestionnaireManagement());
        dispatch(resetContentManagement());
        dispatch(resetPatientMessage());
        dispatch(resetStaffMessage());
        dispatch(resetFilterChatState());
        dispatch(resetPatientDetailsState());
        dispatch(setVideoCallVisibility(false));
        dispatch(resetTaskState());
    }, [location.pathname, dispatch]);
    return (
        <div>
            <Routes>
                <Route path="/add-program" element={<AddProgram />} />
                <Route path="/program-management" element={<ProgramManagement />} />
                <Route path="/add-device" element={<AddDevice />} />
                <Route path="/device-management" element={<DeviceManagement />} />
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/account-management" element={<AccountManagement />} />
                <Route path="/patient-detail/:tab?" element={<PatientDetail />} />
                <Route path="/overview-dashboard" element={<OverviewDashboard />} />
                <Route path="/content-management" element={<ContentManagement />} />
                <Route path="/add-edit-content" element={<AddEditContent />} />
                <Route path="/questionnaire-management" element={<QuestionnaireManagement />} />
                <Route path="/questionnaire-setup" element={<QuestionnaireSetup />} />
                <Route path="/add-questionnaire" element={<AddQuestionnair />} />
                <Route path="/preview-question" element={<QuestionPreviewComponent />} />
                <Route path="/organization-management" element={<OrganizationManagement />} />
                <Route path="/new-organization-management" element={<AddOrganizationMain />} />
                <Route path="/new-organization" element={<NewOrganization />} />
                <Route path="/add-new-organization" element={<AddNewOrganization />} />
                <Route path="/patient-enrollment" element={<PatientEnrollment />} />
                <Route path="/enroll-patient" element={<EnrollPatient />} />
                <Route path="/patient-message" element={<PatientMessage />} />
                <Route path="/care-team-message" element={<CareTeamMainContainer />} />
                <Route path="/role-management" element={<RoleManagement />} />
                <Route path="/add-edit-role" element={<AddRoleMain />} />
                <Route path="/hub-management" element={<HubManagement />} />
                <Route path="/hub-management-patient" element={<PatientHubManagement />} />
                <Route path="/patient-appointment" element={<Appointment />} />
                <Route path="/view-program" element={<ViewProgram />} />
                <Route path="/patient-submitted-reports" element={<PatientSubmittedReports />} />
                <Route path="/tasks" element={<Tasks/>} />
                <Route path="/reference-code" element={<ReferenceCodeManagement/>} />
                <Route path="/add-reference-code" element={<AddReferenceCode/>} />
            </Routes>
        </div>
    );
};

export default MainContent;
