import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { setSelectedItem } from '../../components/PerinHealthGridSlice';
import styles from '../ManagementPages.module.css';
import { useNavigate } from 'react-router-dom';
import { resetProgramState } from './PatientEnrollmentSlice';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import PatientEnrollmentGridOptionCard from './PatientEnrollmentGridOptionCard';
import PatientEnrollmentPopUp from './PatientEnrollmentPopUp';
import { setFormValue } from './EnrollmentListSlicer';
import { setSavedPatientModalitiesState }  from './SavedModalitiesSlicer';
import { resetModalitiesState } from './PatientModalitiesSlice';


const PatientEnrollment = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetProgramState());
        dispatch(resetModalitiesState());
    }, []);
    

    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const userId = useSelector((state)=>state.userInfo.id);
    const [showPatientOptions, setShowPatientOptions] = useState(false);
    const [patientOptionsId, setPatientOptionsId] = useState(null);
    const [showEnrollmentPopup, setShowEnrollmentPopup] = useState({
        showing: false,
        item: null,
        type: null,
    });

    const headers = [
        { key: 'patientId', label: "ID"},
        { key: 'name', label: "Patient Name"},
        { key: 'status', label: "Status"},
        { key: 'programNames', label: "Program Name"},
        { key: 'durations', label: "Program Duration"},
        { key: 'datesEnrolled', label: "Date Enrolled"},
        { key: 'expectedDischargeDates', label: "Expected Discharge Date"},
        { key: 'groupName', label:"Group"}
    ];

    
    const onAddPatientHandler = () => {
       navigate(`/enroll-patient`);
    };

    const onRowClickHandler = async (item) => {
        setPatientOptionsId(item.id);
        dispatch(setSelectedItem(item));
        setShowPatientOptions(!showPatientOptions);
    };

    const handleCloseOptions = () => {
        setShowPatientOptions(false);
    };

    const renderPatientCard = (item, buttonRef) => {
        if (item.id === patientOptionsId) 
        return <PatientEnrollmentGridOptionCard item={item} onClose={handleCloseOptions} setPopup={setShowEnrollmentPopup} buttonRef={buttonRef}/>;
    };

    const closePopup = (updateGrid = false) => {
        if (updateGrid) {
            getPatients();
        }
       setShowEnrollmentPopup({showing:false});
    };

    const renderPatientEnrollmentPopUp = () => {
        return <PatientEnrollmentPopUp type={showEnrollmentPopup.type} item={showEnrollmentPopup.item} onClose={closePopup}/>
    };

    const [patientList, setPatientList] = useState([]);

    useEffect(() => {
        getPatients();
    }, []);

    const formatDate = (dateStr) => {
        if (dateStr.length === 10) return dateStr;
        const utcDate = new Date(dateStr);
        const localDate = utcDate.toLocaleString();
        const splitDate = localDate.split(',')[0].split('/');
        let retDate = new Date(splitDate[2], splitDate[0] - 1, splitDate[1]);
        let formattedDate = retDate.getFullYear() + '/' +
        ('0' + (retDate.getMonth() + 1)).slice(-2) + '/' +
        ('0' + retDate.getDate()).slice(-2);
        return formattedDate;
    };


    const formatPatients = (data, validPatientIds) => {
        const formattedEnrollments = {};
        const uniqueIds = [];
        const currentModalities = {};
        const enrollmentsByPatientId = {};

    // Iterate through each enrollment
        data.forEach(enrollment => {
            const { patient, organizationId, groupId, groupName, enrollment: { status, groups, dischargeDate, startDate, endDate }, name, duration, enrollmentId, devices } = enrollment;
            if (!validPatientIds.includes(patient.id)) {
                return;
            }
            const statusName = dischargeDate ? "Previously Enrolled" : status;
            const key = `${patient.id}_${statusName}`;
            if (!uniqueIds.includes(patient.id)) {
                uniqueIds.push(patient.id);
                enrollmentsByPatientId[patient.id] = {"previous": [], "current":[]};
            }
            
            // Create or update the formatted enrollment object for this patient ID and status
            if (!formattedEnrollments[key]) {
                formattedEnrollments[key] = {
                    id: key,
                    patientId: patient.id,
                    status: statusName,
                    name: `${patient.firstName} ${patient.lastName}`,
                    profilePictureURL: patient.profilePictureURL,
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    groupId: groupId,
                    groupName: groupName,
                    organizationId: organizationId,
                    programNames: [],
                    durations: [],
                    datesEnrolled: [],
                    expectedDischargeDates: [],
                    datesDischarged: [],
                    selectOptions: [],
                    data: [],
                    organizationIds: [organizationId],
                    groupIds: [],
                    subGroupIds: [],
                    teamIds: [],
                    previouslyEnrolledSelection: [],
                    universalModalityValues: {}
                };
            }

            groups.forEach(group => {
                if ("groupId" in group && group.groupId && !formattedEnrollments[key].groupIds.includes(group.groupId)){
                    formattedEnrollments[key].groupIds.push(group.groupId);
                } 
                if ("subGroupId" in group && group.subGroupId && !formattedEnrollments[key].subGroupIds.includes(group.subGroupId)) {
                    formattedEnrollments[key].subGroupIds.push(group.subGroupId);
                } 
                if ("teamId" in group && group.subGroupId && !formattedEnrollments[key].teamIds.includes(group.teamId)) {
                    formattedEnrollments[key].teamIds.push(group.teamId);
                }
            });
            

            // Add program names, durations, and dates enrolled
            formattedEnrollments[key].programNames.push(name);
            formattedEnrollments[key].durations.push(duration);
            formattedEnrollments[key].datesEnrolled.push((startDate));
            formattedEnrollments[key].expectedDischargeDates.push((endDate));

            if (dischargeDate) {
                formattedEnrollments[key].datesDischarged.push(formatDate(dischargeDate));
                let updatedPreviousEnrollments = [...enrollmentsByPatientId[patient.id]["previous"]];
                enrollmentsByPatientId[patient.id]["previous"] = [...updatedPreviousEnrollments, name];
            };

            if (!dischargeDate) {
                let updatedCurrentEnrollments = [...enrollmentsByPatientId[patient.id]["current"]];
                enrollmentsByPatientId[patient.id]["current"] = [...updatedCurrentEnrollments, name];
                if (!currentModalities[patient.id]) {
                    currentModalities[patient.id] = {};
                };
                devices.forEach(deviceModality => {
                    if (!currentModalities[patient.id].hasOwnProperty(deviceModality.modalityId)) {
                        currentModalities[patient.id][deviceModality.modalityId] = {
                            upperLimit: deviceModality.upperLimit,
                            lowerLimit: deviceModality.lowerLimit,
                            targetValue: deviceModality.targetValue,
                            isContinuous: deviceModality.isContinuous,
                            startTime: deviceModality.startTime,
                            frequency: deviceModality.frequency,
                            interval:  deviceModality.interval,
                        }
                    }
                })
            }

            // use this for selecting programs to remove + update
            formattedEnrollments[key].selectOptions.push({name:name, id:enrollmentId });

            // Add original enrollment data
            formattedEnrollments[key].data.push(enrollment);
        });
        // Return an array of the formatted enrollment objects
        uniqueIds.forEach((id)=> {
            let previous = false;
            let active = false;
            if (formattedEnrollments[`${id}_Previously Enrolled`]) {
                previous = true;
            }
            if (formattedEnrollments[`${id}_Enrolled`]) {
                active = true;
            }

            // save previous enrollments to user for later selection
            if (active && previous) {
                formattedEnrollments[`${id}_Enrolled`].previouslyEnrolledSelection = formattedEnrollments[`${id}_Previously Enrolled`].selectOptions;
                formattedEnrollments[`${id}_Enrolled`].data = formattedEnrollments[`${id}_Enrolled`].data.concat(formattedEnrollments[`${id}_Previously Enrolled`].data);
                delete formattedEnrollments[`${id}_Previously Enrolled`];
            }
        });
        dispatch(setSavedPatientModalitiesState(currentModalities));
        dispatch(setFormValue({"key":"previousEnrollments", "value":enrollmentsByPatientId}));
        return Object.values(formattedEnrollments);
    };

    const getPatients = async () => {
        try {
            const [
                enrollments,
                patients,
            ] = await Promise.all([
            httpService.get(`careprogram/patient/enroll/list/${userId}`),
            httpService.get(`careprogram/patient/list/${userId}`),
            ]);
            const patientIds = Array.from(new Set(patients.data.data.map(patient => patient.id)));
            const formatted = formatPatients(enrollments.data.data, patientIds);
            setPatientList(formatted);
        } catch (error){
            console.log("Error fetching enrollments:",error);
        }
    };

    return (
        <div className={styles.MainContent}>
            {/* <div className={styles.Title}>
                Patient Enrollment
            </div> */}
            <div className={styles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={true}
                    isTeam={true}
                    addButtonLabel='Enroll Patient'
                    onAddHandler={onAddPatientHandler}
                    showDeleteButton={false}
                />
                <PerinHealthGrid
                    dataList={patientList}
                    headers={headers}
                    showCheckbox={false}
                    showEditIcon={true}
                    accounts={true}
                    onRowClick={onRowClickHandler}
                    moreEditOptions={true}
                    showOptions={showPatientOptions}
                    renderOptionCard={renderPatientCard}
                    showHeaderFilter={true}
                    showProfilePicture={'name'}
                    gridHeight="100%"
                />
            </div>
            {showEnrollmentPopup.showing &&
               renderPatientEnrollmentPopUp()
            }
        </div>
    )
}

export default PatientEnrollment;
