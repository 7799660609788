import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ProgressBar = ({ page, navigateToStep, getPageName, totalSteps }) => {

    const pageStatus = (step) => {
        if (step < page) return "complete";
        if (step === page) return "active";
        return "inactive";
    };

    return (
        <div className="progress-steps">
            {[...Array(totalSteps).keys()].map((step) => (
                <React.Fragment key={step}>
                    <div className="stepper-item" style={(pageStatus(step) === "complete" && navigateToStep) ? {cursor:"pointer"} : {}} onClick={()=>{
                                if (pageStatus(step) === "complete" && navigateToStep) {
                                    navigateToStep(getPageName(step))
                                } else {
                                    return;
                                }
                            }}>
                        <div className={`circle-${pageStatus(step)}`}></div>
                        {pageStatus(step) === "complete" && <FontAwesomeIcon icon="fa-solid fa-check" className="check-icon" />}
                        <p className={`step-${pageStatus(step)}`} style={{paddingTop:"5px"}}>{getPageName(step)}</p>
                    </div>
                    {step < totalSteps - 1 && (
                        <div className="line-container">
                            {pageStatus(step) === "complete" ? <div className="line-complete" /> : <div className="line" />}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div> 
    )
}

export default ProgressBar;