import React, {useState, useCallback, useEffect} from 'react';
import styles from '../Patient.module.css';
import PatientDetailDropdownCard from '../../../components/PatientDetailDropdownCard';
import QuestionnaireDetails from '../CardDetails/QuestionnaireDetails';
import { useSelector, useDispatch } from 'react-redux';
import QuestionnaireScoreCardView from '../modality-charts/QuestionnaireScoreCardView';
import useAxios from '../../../services/AxiosConfig';
import { setFilterItem } from '../Slices/PatientDetailFilterSlice';
import CustomToggle from '../../../components/CustomToggle';
import { SpecialtySelectedIcon } from '../../../components/icons/Icons';
import { FloatingLabel, Form } from 'react-bootstrap';
import inputStyles from '../../ManagementPages.module.css';
import { format } from 'date-fns';

const QuestionnaireView = () => {

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);
    const patientId = useSelector((state) => state.patient.id);
    const dispatch = useDispatch();

    const questionnaireIds = useSelector((state)=>state.patient.questionnaireIds);
    const [questionnaireData, setQuestionnaireData] = useState([]);
    const [missedData, setMissedData] = useState([]);
    const qFilter = useSelector((state)=>state.patientDetailFilter.questionnaireFilter);
    const startDate = useSelector((state) => state.patientDetailFilter.questionnaireStartDate);
    const endDate = useSelector((state) => state.patientDetailFilter.questionnaireEndDate);
    const timePeriod = useSelector((state) => state.patientDetailFilter.questionnaireTimePeriod);
    const [opacity, setOpacity] = useState({});

    const [listType, setListType] = useState("Completed");


    const PeriodFilters = [
        { label: "Week", value: "Week" },
        { label: "Month", value: "Month" },
        { label: "Custom", value: "Custom" },
    ];

    const convertDisplayDate = (dateStr) => {
        const localDate = new Date(dateStr);
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hour = String(localDate.getHours()).padStart(2, '0');
        const minute = String(localDate.getMinutes()).padStart(2, '0');

        return `${month}/${day}-${hour}:${minute}`;
    };

    const fetchQuestionnaireData = useCallback(() => {
        setQuestionnaireData([]);
        const fetchData = async () => {
            // for each questionnaire, fetch responses and save score + completed date
            const responsePromises = questionnaireIds.map(async (q) => {
            try {
                const responses = await axios.get(`/questionnaire/responses/list/${q.questionnaireId}/${patientId}?startDate=${startDate}&endDate=${endDate}`);
                if (Array.isArray(responses.data.data)) {
                return responses.data.data.map(response => {
                    let score = 0;
                    response.answers.forEach(a => {
                        score += a.score;
                    })
                    let retVal = response;
                    retVal["score"] = score;
                    retVal["possibleScore"] = q.totalPossibleScore;
                    retVal["qDate"] = convertDisplayDate(response.completedDate);
                    retVal["sortDate"] = response.completedDate;
                    return retVal;
                });
                } 
                return null;
            } catch (err) {
                console.log(err);
                return [];
            }
            });
            let qWithResponses = await Promise.all(responsePromises);
            qWithResponses = qWithResponses.flat();
            qWithResponses.sort((a, b) => new Date(a.completedDate) - new Date(b.completedDate));
            setQuestionnaireData(qWithResponses);
            // fetch all missed responses for time period
            let missedResponses = [];
            try {
                const responses = await axios.get(`/questionnaire/missedResponses/list/${patientId}?startDate=${startDate}&endDate=${endDate}`);
                if (Array.isArray(responses.data.data)) {
                    missedResponses = responses.data.data.map(missed => {
                        let retVal = missed;
                        retVal[`missed.score`] = -1;
                        retVal["qDate"] = convertDisplayDate(missed.dueDate);
                        retVal["sortDate"] = missed.dueDate;
                        return retVal;
                    });
                } 
            } catch (err) {
                console.log(err);
            }
            missedResponses.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
            setMissedData(missedResponses);

            setOpacity(questionnaireIds.reduce((acc, q) => {
                acc[q.name] = 1;
                return acc;
            }, {}));
        };

        fetchData();
    }, [startDate, endDate, axios, patientId]);

    useEffect(() => {
        fetchQuestionnaireData();
    }, [fetchQuestionnaireData]);

    const handleDateOrderChange = (value) => {

        const date = new Date();
        const today = new Date(date);
        today.setDate(today.getDate() + 1);
        let startDate, endDate;

        switch (value) {
            case 'Week':
                startDate = new Date();
                startDate.setDate(today.getDate() - 7);
                endDate = today;
                break;
            case 'Month':
                startDate = new Date();
                startDate.setDate(today.getDate() - 30);
                endDate = today;
                break;
            default:
                startDate = new Date();
                endDate = today;
        }

        startDate = startDate.toISOString().split('T')[0];
        endDate = endDate.toISOString().split('T')[0];

        dispatch(setFilterItem({ key: "questionnaireTimePeriod", value: value }));
        dispatch(setFilterItem({ key: "questionnaireStartDate", value: startDate }));
        dispatch(setFilterItem({ key: "questionnaireEndDate", value: endDate }));
    };

    const handleToggle = (value) => {
        dispatch(setFilterItem({ key: "questionnaireFilter", value: value }))
    };

    const handleStartDateChange = (event) => {
        dispatch(setFilterItem({ key: "questionnaireStartDate", value: event.target.value }));
    };

    const handleEndDateChange = (event) => {
        dispatch(setFilterItem({ key: "questionnaireEndDate", value: event.target.value }));
    };

    const descendingQData = [...questionnaireData].sort((a, b) => new Date(b.qDate) - new Date(a.qDate));

    return (
        <div className={styles.ViewContainer}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
                    <CustomToggle labels={[{value:"timeline", name:"Timeline"}, {value:"list", name:"List"}]} selectedToggle={qFilter} handleToggle={handleToggle} />
                    {qFilter === "list" && (
                        <div style={{position: "relative"}}>
                            <span className="floating-label">List Type</span>
                            <select 
                                className={inputStyles["styled-input-select"]}
                                style={{width:"141px"}}
                                onChange={(e) => setListType(e.target.value)}
                                value={listType}
                            >
                                <option value="Completed">Completed</option>
                                <option value="Missed">Missed</option>
                            </select>
                        </div>
                    )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <div className={styles.GraphPeriodButtons}>
                        {PeriodFilters.map((filter, index) => (
                            <div key={index} className={timePeriod === filter.value ? styles.TimePeriodSelected : ''} onClick={() => handleDateOrderChange(filter.value)}>
                                {timePeriod === filter.value && <SpecialtySelectedIcon />}
                                {filter.label}
                            </div>
                        ))}
                    </div>
                    {timePeriod === 'Custom' && (
                        <>
                            <Form.Group className='ps-5 pe-3'>
                                <FloatingLabel label="Start Date">
                                    <Form.Control
                                        type="date"
                                        className={`${styles.BorderedComponent}`}
                                        value={startDate}
                                        max={endDate || new Date().toISOString().split('T')[0]}
                                        onChange={handleStartDateChange}
                                        style={{ color: startDate ? "inherit" : "transparent" }}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group>
                                <FloatingLabel label="End Date">
                                    <Form.Control
                                        type="date"
                                        className={`${styles.BorderedComponent}`}
                                        value={endDate}
                                        min={startDate}
                                        max={new Date().toISOString().split('T')[0]}
                                        onChange={handleEndDateChange}
                                        style={{ color: endDate ? "inherit" : "transparent" }}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </>

                    )}
                </div>
            </div>
            {qFilter === "timeline" &&
                <div>
                     <QuestionnaireScoreCardView data={questionnaireData} opacity={opacity} setOpacity={setOpacity}/>
                </div>
            }
            {qFilter === "list" && (
                listType === "Completed" ? 
                descendingQData.map((q, index) => {
                    let label = {
                        name:"Completed",
                        color: "#1F5C29"
                    };
                    const formattedDate = format(new Date(q.completedDate), "MMMM do, yyyy");
                    return (
                        <div key={index}>
                            <PatientDetailDropdownCard 
                                title={q.name} 
                                label={label}
                                subtitle2={formattedDate}
                                cardInfo={<QuestionnaireDetails questionnaire={q}/>}
                            />
                           
                        </div>
                    )
                })
                : missedData.map((q, index) => {
                    let label = {
                        name:"Missed",
                        color: "#630F0F"
                    };
                    const formattedDate = format(new Date(q.dueDate), "MMMM do, yyyy");
                    return (
                        <div key={index}>
                            <PatientDetailDropdownCard 
                                title={q.taskName} 
                                label={label}
                                subtitle2={formattedDate}
                                cardInfo={<QuestionnaireDetails questionnaire={q}/>}
                            />
                           
                        </div>
                    )
                })
            )
            }
        </div>
    );
}

export default QuestionnaireView;