import React, { useState, useRef } from 'react';
import styles from './CardDetails.module.css';
import { Button } from 'react-bootstrap';
import { UserProfilePictureIcon, AttachmentIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';
import useAxios from '../../../services/AxiosConfig';
import { format } from 'date-fns';
import PainLocationView from '../../../components/PainLocationView';


const ReportsDetails = ({ data }) => { 
    const [noteType, setNoteType] = useState("patient");
    const [internalNewNote, setInternalNewNote] = useState("");
    const [patientNewNote, setPatientNewNote] = useState("");
    const [responses, setResponses] = useState(data.responses);
    const [unread, setUnread] = useState(data.Unread);

    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);    
    const patient = useSelector((state) => state.patient);

    const userAccountId = useSelector((state) => state.userInfo.id);
    const reportAttachments = data.attachments || [];


    const getResponses = async () => {
        try {
            const reportResponse = await axios.get(`contact/nonCriticalReportResponse/${userAccountId}/${data.nonCriticalReportId}/true`);
            if (reportResponse.data.data) {
                setResponses([reportResponse.data.data[0], ...responses]);
            } else {
              console.error('Error fetching patient info');
            }
          } catch (err) {
            console.log(err);
          }
    };

    const handlePatientNoteSubmission = async () => {

        const params = {
            nonCriticalReportId: data.nonCriticalReportId,
            senderId: userAccountId,
            response: patientNewNote
        };

        if (attachments.length > 0) {
            const attachmentsData = await Promise.all(
                attachments.map(async (file) => ({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    lastModified: file.lastModified,
                    data: await readFileAsBase64(file),
                }))
            );
            params["files"] = attachmentsData;
        }

        try {
            const response = await axios.post(`contact/nonCriticalReportResponse`, params);
            if (response.data.data) {
                setAttachments([]);
                setPatientNewNote("");
                setUnread(0);
                getResponses();
            }
        } catch (err) {
        console.error('Failed to send patient note:', err);
        }
    };

    const handleAttachmentChange = (e) => {
        const files = e.target.files;
        setAttachments([...attachments, ...files]);
    };

    const handleAttachmentIconClick = () => {
        fileInputRef.current.click();
    };

    const removeAttachment = (indexToRemove) => {
        setAttachments(attachments.filter((_, index) => index !== indexToRemove));
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 part
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleInternalNoteSubmission = async () => {
        try {
            const response = await axios.post(`contact/nonCriticalReportResponse`, {
                nonCriticalReportId: data.nonCriticalReportId,
                senderId: userAccountId,
                response: internalNewNote,
                isInternal: true
            });
            setInternalNewNote("");
            setResponses([response.data.data, ...responses]);
            // fetchResponses();
        } catch (err) {
        console.error('Failed to send patient note:', err);
        }
    };

    const staffLookup = patient.careTeam.reduce((acc, user) => {
        acc[user.id] = `${user.firstName} ${user.lastName}`;
        return acc;
    }, {});

    const internalNotes = responses.filter(note => note.isInternal);
    const patientNotes = responses.filter(note => !note.isInternal);
    const mappedNotes = noteType === "internal" ? internalNotes : patientNotes;

    return (
        <div className={styles.DetailsContainer}>
            <div className={styles.Detail}>
                <span className={styles.DetailLabelReports}>Description:</span>
                <div style={{width:"506px",overflow:"wrap"}}>
                    {data.description}
                </div>
            </div>
            <div style={{display: "flex", flexDirection:"column", alignItems:"flex-start", padding:"10px 0px"}}>
                <span className={styles.DetailLabelReports}>Pain Location:</span>
                <PainLocationView painLocations={data.painLocations}/>
            </div>
            <div className={styles.Detail}>
                <span className={styles.DetailLabelReports}>Severity Level:</span>
                {data.severity}
            </div>
            {reportAttachments.length > 0 &&
             <div className={styles.Detail} style={{zIndex: "2"}}>
                 <span className={styles.DetailLabelReports}>Images:</span>
                <div>
                {reportAttachments.map((file, index) => 
                    <div key={index} className={`${styles.AttachmentContainer} ${styles.Attachment}`}>
                        <a href={file.signedUrl} target="_blank" rel="noopener noreferrer" >{file.fileName}</a>
                    </div>
                )}
                </div>
             </div>
            }
            <div className={styles.ReportsTabs}>
                <div className={noteType==="internal" ? styles.NoteTabActive : styles.NoteTab} onClick={()=>setNoteType("internal")}>
                    <span>Internal Notes</span>
                </div>
                <div className={noteType==="patient" ? styles.NoteTabActive : styles.NoteTab} onClick={()=>setNoteType("patient")}>
                    <span>Reply to Patient</span>
                </div>
            </div>
            {noteType==="internal" && data.status !== "Resolved" && (
                <div style={{width:"100%"}}>
                    <div className={styles.NewNoteSection}>
                        <textarea
                            className={styles.NewNote}
                            placeholder='Add Notes'
                            value={internalNewNote}
                            onChange={(e)=>setInternalNewNote(e.target.value)}
                        >
                        </textarea>
                        <Button 
                            variant="secondary"
                            className={styles.NewNoteButton} 
                            onClick={handleInternalNoteSubmission}
                            disabled={internalNewNote===""}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            )}
            {noteType==="patient" && data.status !== "Resolved" && (
                <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                    <div className={styles.NewNoteSection}>
                        <textarea
                            className={styles.NewNote}
                            placeholder='Add Notes'
                            value={patientNewNote}
                            onChange={(e)=>setPatientNewNote(e.target.value)}
                        >
                        </textarea>
                        <div className={styles.AttachedFiles}>
                            {attachments.map((file, index) => (
                            <div key={index} className={styles.AttachedFile}>
                                <span>{file.name}</span>
                                <Button variant="link" onClick={() => removeAttachment(index)}>Remove</Button>
                            </div>
                            ))}
                        </div>
                        <div style={{display:"flex"}}>
                            <Button 
                                variant="secondary"
                                className={styles.NewNoteButton} 
                                onClick={handlePatientNoteSubmission}
                                disabled={patientNewNote===""}
                            >
                                Submit
                            </Button>
                            <Button variant="white" onClick={handleAttachmentIconClick}>
                                <AttachmentIcon />
                            </Button>
                            <input
                                type="file"
                                multiple
                                style={{ display: 'none' }}
                                onChange={handleAttachmentChange}
                                ref={fileInputRef}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="body-2" style={{paddingTop:"20px"}}>Notes:</div>
            {mappedNotes.length !== 0 && (
                mappedNotes.map((note, index)=>(
                    <div key={index} className={styles.ReportNotesContainer} style={{background: index < unread ? "rgba(203, 243, 210, 0.40)" : "white"}}>
                        <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                            {note.profilePictureURL ? (
                                <img 
                                src={note.profilePictureURL} 
                                alt="User Profile" 
                                style={{
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '50%',
                                    border: '2px solid grey',
                                    objectFit: 'cover'
                                  }}
                                />
                            ) : (
                                <UserProfilePictureIcon width="28" height="28"/>
                            )}
                            {note.senderId === patient.id ? patient.fullName : staffLookup[note.senderId] ? `${staffLookup[note.senderId]}` : `Physician ID: ${note.senderId}`}
                            
                            {` - ${format(new Date(note.responseDate), "MM/dd/yyyy hh:mm")}`}
                        </div>
                        <div style={{padding:"0 10px"}}>
                            {note.response}

                            {note.presignedURL && (
                            <div className={`${styles.AttachmentContainer} ${styles.Attachment}`}>
                                <a href={note.presignedURL} target="_blank" rel="noopener noreferrer" >{note.fileName}</a>
                            </div>

                        )}
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}
export default ReportsDetails;