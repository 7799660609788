import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './Chart.module.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AuscultationIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';
import ChartDetailView from './ChartDetailView';

const AuscultationCardView = React.forwardRef(({ className, healthEvaluationData, vitalName }, ref) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const { startDate, endDate } = useSelector((state) => state.patientDetailFilter);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const vitals = useSelector((state) => state.vital.modalities[vitalName]);
  const [opacity, setOpacity] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = containerWidth;
        setChartWidth(width);

        const height = 200; // Set a fixed height
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateData = useCallback(() => {
    // Group data by date and deviceName
    const groupedData = healthEvaluationData.reduce((acc, entry) => {
      const date = entry.date;
      if (!acc[date]) {
        acc[date] = { date };
      }
      acc[date][entry.deviceName] = entry;
      return acc;
    }, {});

    const transformedData = Object.values(groupedData);
    transformedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    setData(transformedData);
    const deviceOpacity = healthEvaluationData.reduce((acc, entry) => {
      const { deviceName } = entry;

      if (!acc[deviceName]) {
        acc[deviceName] = [];
      }
      acc[deviceName].push(entry);

      return acc;
    }, {});

    setOpacity(Object.keys(deviceOpacity).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {}));
    setDisplayData(getLatestMeasurement(healthEvaluationData));
  }, [healthEvaluationData]);

  useEffect(() => {
    updateData();
  }, [updateData, startDate, endDate]);

  const handleLegendClick = (e) => {
    const deviceName = e.value;
    setOpacity((op) => ({ ...op, [deviceName]: op[deviceName] === 1 ? 0 : 1 }));
  };

  const getLatestMeasurement = useCallback((healthEvaluationData) => {
    if (!healthEvaluationData || healthEvaluationData.length === 0) {
      return { measurement: 'No Data', latestDate: 'N/A' };
    }

    const deviceNamesData = healthEvaluationData.filter(entry => entry[vitalName] !== null);
    const latestMeasurement = deviceNamesData.reduce((latest, entry) => {
      const dateA = new Date(latest.completedDate);
      const dateB = new Date(entry.completedDate);
      return dateB > dateA ? entry : latest;
    }, deviceNamesData[0]);

    if (!latestMeasurement) {
      return { measurement: 'No Data', latestDate: 'N/A' };
    }

    return {
      measurement: latestMeasurement?.[vitalName] !== undefined ? Math.round(latestMeasurement?.[vitalName]) + ' BPM' : 'No Data',
      latestDate: 'Latest: ' + (latestMeasurement?.date || 'N/A'),
    };
  }, []);

  const deviceNames = Array.from(new Set(healthEvaluationData.map(entry => entry.deviceName)));

  const deviceNamesData = deviceNames.filter(deviceName =>
    healthEvaluationData.some(entry => entry.deviceName === deviceName && entry.AUSC !== null)
  );

  const colors = {
    0: '#496CA3',
    1: '#478CAA',
  };

  const handlePointClick = (deviceName, payload) => {
    const timeStamp = payload.timeStamp;
    setModalContent({ timeStamp, deviceName, modalityId: vitals.modalityId, vital: vitals.modalityName });
    setIsModalOpen(true);
  };

  return (
    <div className={`${styles.cardContainer} ${className}`} ref={chartContainerRef}>
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <AuscultationIcon className={styles.icon} />
          <span className={styles.vitalName}>{vitals.modalityName}</span>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.measurement}>
            <span className={styles.value}>{displayData.measurement}</span>
          </div>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.latestDate}>{displayData.latestDate}</div>
        </div>
      </div>
      <div className={styles.chartWrapper} ref={ref}>
        <LineChart width={chartWidth} height={chartHeight} data={data} margin={{ left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize={12} />
          <YAxis
            domain={0}
            allowDataOverflow={true}
            fontSize={12}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            height={30}
            align={"right"}
            iconType="square"
            wrapperStyle={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
            payload={deviceNamesData.map((deviceName, index) => ({
              value: deviceName,
              type: 'square',
              color: colors[index] || '#000000',
            }))}
            onClick={handleLegendClick}
          />
          {deviceNamesData.map((deviceName, index) => (
            <Line connectNulls
              key={deviceName}
              type="linear"
              dataKey={deviceName + '.AUSC'}
              stroke='#828282'
              strokeOpacity={opacity[deviceName] || 0}
              strokeWidth={3}
              dot={(props) => {
                const { cx, cy, value, key, payload } = props;
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={4}
                      fill='transparent'
                      stroke={'#828282'}
                      strokeOpacity={opacity[deviceName] || 0}
                      strokeWidth={2}
                      key={key}
                      style={{ pointerEvents: 'visible' }}
                    />
                  );
                } else {
                  return null;
                }
              }}
              activeDot={(props) => {
                const { cx, cy, value, key, payload } = props;
                const dotColor = '#828282';
                const strokeColor = '#828282';
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={4}
                      onClick={(e) => {
                        e.stopPropagation(); // Ensure the event doesn't bubble up
                        handlePointClick(deviceName, payload[deviceName]);
                      }}
                      style={{ pointerEvents: 'visible' }}
                      fill={dotColor === '#828282' ? `rgba(130, 130, 130, ${opacity[deviceName]})` : 'none'}
                      stroke={dotColor}
                      strokeOpacity={opacity[deviceName] || 0}
                      strokeWidth={2}
                      key={key}
                    />
                  );
                } else {
                  return null;
                }
              }}
            />
          ))}
        </LineChart>
      </div>
      <ChartDetailView
        isModalVisible={isModalOpen}
        customModal={styles.customModal}
        handleCloseModal={() => setIsModalOpen(false)}
        modalData={modalContent}
      />
    </div>
  );
});

export default AuscultationCardView;
