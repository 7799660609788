import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   groupName: "",
   groupId: "",
   organizationName: "",
   organizationId: "",
};

const PatientSubmittedReportsSlice = createSlice({
  name: 'patientSubmittedReportsSlice',
  initialState,
  reducers: {
    resetFilterState: (state) => {
      Object.assign(state, initialState);
    },
    setGroup: (state, action) => {
        const { name, id } = action.payload;
        state.groupName = name;
        state.groupId = id;
    },
    setOrganization: (state, action) => {
        const { name, id } = action.payload;
        state.organizationName = name;
        state.organizationId = id;
    },
  },
});

export const {
  resetFilterState,
  setGroup,
  setOrganization,
} = PatientSubmittedReportsSlice.actions;

export default PatientSubmittedReportsSlice.reducer;
