import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { VideoCallIcon } from "../../components/icons/Icons";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  startOfWeek,
  addDays,
  isSameDay,
  startOfDay,
  endOfDay,
  isToday,
  startOfToday,
  getDay,
  isSameMonth,
  endOfWeek
} from "date-fns";
import styles from "./Appointment.module.css";

function CalendarView({
  currentWeek,
  viewType,
  appointments,
  onAppointmentClick,
  physicians,
}) {
  const timelineRef = useRef(null);
  const now = new Date(); // Get current date and time
  const nowMinus15 = new Date(now.getTime() - 15 * 60 * 1000);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAppointments, setPopupAppointments] = useState([]);
  const [popupDay, setPopupDay] = useState([]);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const startHour = 6; // 8 AM
  const endHour = 24; // 11 PM
  const headerHeight = 90;

  const getCurrentTimePosition = () => {
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
  
    // Check if current time is within the calendar range
    if (currentHour < startHour) {
      return headerHeight; // Place at the top (8 AM)
    } else if (currentHour >= endHour) {
      return (endHour - startHour ) * 150 + headerHeight; // Place at the bottom (11 PM)
    }
  
    // Calculate minutes since startHour (8 AM)
    const minutesSinceStartOfDay = (currentHour - startHour) * 60 + currentMinutes;
  
    // Update: Each hour slot is now 150px tall
    const pixelsPerMinute = 150 / 60; // 1 minute = 2.5px
    return minutesSinceStartOfDay * pixelsPerMinute + headerHeight;
  };
  
  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentWeek, viewType]);

  const renderCurrentTimeLine = () => {
    const position = getCurrentTimePosition();

    return (
      <div
        ref={timelineRef}
        style={{
          position: 'absolute',
          top: `${position}px`,
          left: '0',
          right: '0',
          height: '2px',
          backgroundColor: 'rgba(29,87,105,1)',
          zIndex: 8,
        }}
      />
    );
  };


  const handleMoreClick = (additionalAppointments, event, day) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({
      top: rect.bottom + window.scrollY, // Position at the bottom of the day element
      left: rect.right // Position at the right edge of the day element
    });
    setPopupDay(day);
    setPopupAppointments(additionalAppointments);
    setIsPopupOpen(true);
  };

  const renderPopup = () => {
    if (!isPopupOpen) return null;

    const handlePopupClick = (appointment) => {
      setIsPopupOpen(false);
      onAppointmentClick(appointment); 
    };
  
    return (
      <div className={styles.appointmentPopupOverlay} onClick={() => setIsPopupOpen(false)}>
        <div
          className={styles.appointmentPopup}
          style={{ top: `${popupPosition.top}px`, left: `${popupPosition.left}px` }}
        >
          <span className={styles.appointmentPopupDate}>{format(popupDay, "MMM d")}</span>
          {popupAppointments.map(appointment => (
            <div key={appointment.appointmentId}
                 className={styles.appointmentPopupItem}
                 onClick={() => handlePopupClick(appointment)}>
              {`${appointment.patientName}, ${getPhysicianNameById(appointment.physicianId)}`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getPhysicianNameById = (physicianId) => {
    const physician = physicians.find(
      (p) => p.value.toString() === physicianId.toString()
    );
    return physician ? physician.label : "Unknown Physician";
  };

  function calculateAppointmentPositions(appointments) {
    let lastEndTimes = [];
    let columnsOccupied = [];
    let updatedAppointments = appointments.map(appointment => ({
      ...appointment, // Spread to copy properties
    }));
  
    updatedAppointments.sort(
      (a, b) =>
        new Date(a.appointmentStartDateTime) - new Date(b.appointmentStartDateTime)
    );
  
    updatedAppointments.forEach((appointment, index) => {
      const appointmentStart = new Date(appointment.appointmentStartDateTime);
      const appointmentEnd = new Date(appointment.appointmentEndDateTime);
      let placed = false;
  
      for (let i = 0; i < lastEndTimes.length; i++) {
        if (appointmentStart >= lastEndTimes[i]) {
          lastEndTimes[i] = appointmentEnd;
          updatedAppointments[index] = {
            ...appointment,
            column: i,
          };
          columnsOccupied[i] = columnsOccupied[i] || [];
          columnsOccupied[i].push({
            start: appointmentStart,
            end: appointmentEnd,
          });
          placed = true;
          break;
        }
      }
  
      if (!placed) {
        lastEndTimes.push(appointmentEnd);
        updatedAppointments[index] = {
          ...appointment,
          column: lastEndTimes.length - 1,
        };
        columnsOccupied[updatedAppointments[index].column] = [
          { start: appointmentStart, end: appointmentEnd },
        ];
      }
    });
  
    // Updating spanAll and totalColumns properties after initial placement
    const maxColumns = lastEndTimes.length;
    updatedAppointments = updatedAppointments.map(appointment => {
      const appointmentStart = new Date(appointment.appointmentStartDateTime);
      const appointmentEnd = new Date(appointment.appointmentEndDateTime);
      const overlap = columnsOccupied.some((column, index) => {
        return (
          column !== columnsOccupied[appointment.column] &&
          column.some(
            timeSlot =>
              appointmentStart < timeSlot.end && appointmentEnd > timeSlot.start
          )
        );
      });
  
      return {
        ...appointment,
        spanAll: !overlap && maxColumns > 1,
        totalColumns: maxColumns,
      };
    });
  
    return updatedAppointments; // Return new array of updated appointment objects
  }
  

  const renderWeeklyView = () => {
    const startDate = startOfWeek(currentWeek, { weekStartsOn: 0 });
    const daysOfWeek = Array.from({ length: 7 }, (_, index) =>
      addDays(startDate, index)
    );
    const hours = generateHours(6, 23);

    return (
        <div className={styles.calendarView}>
          <div className={styles.hourColumn}>
            <div className={styles.dayHeaderPlaceholder}></div>
            {hours.map((hour, index) => (
              <div key={index} className={`${styles.hourSlot} ${styles.borderLeft}`}>
                {hour.formatted}
              </div>
            ))}
          </div>

          {daysOfWeek.map((day, index) => {
            const dayAppointments = appointments.filter((appointment) =>
              isSameDay(new Date(appointment.appointmentStartDateTime), day)
            );

            // Calculate positions for the entire day's appointments
            const calculatedAppts = calculateAppointmentPositions(dayAppointments);

            return (
              <div key={day} 
                className={`${styles.dayColumn} ${isToday(day) ? styles.dayColumnToday : ''} ${index===6 && styles.borderRight}`}>
                <div className={isToday(day) ? styles.dayHeaderToday : styles.dayHeader} style={isToday(day) ? {background: "hsl(195, 27%, 93%)"} : {}}>
                  <div
                    className={styles.dayHeaderInfo}
                    style={{ textAlign: "center" }}
                  >
                    <div className={styles.dayHeaderFirst}>
                      {format(day, "EEE")}
                    </div>
                    <div className={styles.dayHeaderSecond}>
                      {format(day, "d")}
                    </div>
                  </div>
                </div>

                {renderCurrentTimeLine()}

                {hours.map((hour, index) => {
                  const hourAppointments = calculatedAppts.filter(
                    (appointment) =>
                      new Date(
                        appointment.appointmentStartDateTime
                      ).getHours() === hour.numeric
                  );

                  return (
                    <div
                      key={index}
                      className={styles.hourSlot}
                      style={{ position: "relative", height: "150px", borderLeft:"none" }}
                    >
                      {hourAppointments.map((appointment) => {
                        const startMinutes = new Date(
                          appointment.appointmentStartDateTime
                        ).getMinutes();
                        const durationInMinutes =
                          (new Date(appointment.appointmentEndDateTime) -
                            new Date(appointment.appointmentStartDateTime)) /
                          60000;

                          /*
                            let isInMeeting = currentMeetings.some(
                              (meeting) =>
                                meeting.isActive === appointment.appointmentId
                            );
                          */                        
                        let isInMeeting = appointment.isActive;
                        const endDateTime = new Date(appointment.appointmentEndDateTime);
                        const hasPassed = endDateTime < nowMinus15;

                        const meetingColor = hasPassed ? "#d3d3d3" 
                          : isInMeeting
                          ? "#1D5769"
                          : "#B2C7CE";
                        const textColor = hasPassed ? "#373737" 
                          : isInMeeting
                          ? "white" 
                          : "#373737";
                        const heightPercentage = (durationInMinutes / 60) * 100;
                        const shouldHideText = heightPercentage <= 10; // Determines if the text should be hidden

                        return (
                          <div key={appointment.appointmentId}>
                            <div
                              // key={appointment.appointmentId}
                              className={`${styles.appointment} ${
                                shouldHideText ? styles.hideText : ""
                              }`}
                              onClick={() => onAppointmentClick(appointment)}
                              style={{
                                backgroundColor: meetingColor,
                                top: `${(startMinutes / 60) * 100}%`,
                                height: `${heightPercentage}%`,
                                position: "absolute",
                                left: `${
                                  appointment.spanAll
                                    ? 0
                                    : (appointment.column /
                                        appointment.totalColumns) *
                                      100
                                }%`,
                                color: textColor,
                                width: `${
                                  appointment.spanAll
                                    ? "100%"
                                    : `${100 / appointment.totalColumns}%`
                                }`,
                              }}
                            >
                              {shouldHideText && (
                                <span className={styles.ellipsis}>...</span>
                              )}
                              {isInMeeting ? (
                                   <div className={styles.appointmentDetailsWaitingRoom}>         
                                    <div style={{flexShrink:"0"}}>
                                    <VideoCallIcon />
                                    </div>              
                                    <span className={styles.inMeetingMsg}>
                                      {shouldHideText ? "" : `${appointment.patientName} in waiting room`}
                                    </span>
                                  </div>
                                ) :
                                <div className={styles.appointmentDetails}>
                                  {appointment.patientName} <br />
                                  {getPhysicianNameById(appointment.physicianId)}                              
                                </div>
                                }
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
    );
  };

  const renderMonthView = () => {
    const start = startOfWeek(startOfMonth(currentWeek), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(currentWeek), { weekStartsOn: 1 });
    const days = eachDayOfInterval({ start, end });
  
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  
    return (
      <div style={{ width: "99%", height: "98%", margin: "auto" }}>
        <div className={styles.dayNames}>
          {dayNames.map((name) => (
            <div key={name} className={styles.monthDayName}>{name}</div>
          ))}
        </div>
        <div className={styles.monthView}>
          {days.map((day) => {
            const dayAppointments = appointments.filter((appointment) =>
              isSameDay(new Date(appointment.appointmentStartDateTime), day)
            ).sort(
              (a, b) =>
                new Date(a.appointmentStartDateTime) -
                new Date(b.appointmentStartDateTime)
            );
  
            const currentMonth = isSameMonth(startOfMonth(currentWeek), day);

            return (
              <div key={day} className={`${isToday(day) ? styles.daySquareToday : styles.daySquare} ${!currentMonth && styles.otherMonth}`}>
                <div className={styles.dateHeader}>{format(day, "d")}</div>
                {/* Render all appointments without slicing */}
                {dayAppointments.map((appointment) => {
                  const endDateTime = new Date(appointment.appointmentEndDateTime);
                  const startTime = format(new Date(appointment.appointmentStartDateTime), 'h:mm a'); // Formats time as '10:00 AM'
                  const hasPassed = endDateTime < nowMinus15;
                  return (
                    <div
                      key={appointment.appointmentId}
                      onClick={() => onAppointmentClick(appointment)}
                      className={styles.appointmentMonth}
                      style={{
                        backgroundColor: hasPassed ? "#d3d3d3" : "#B2C7CE",
                        color: hasPassed ? "#373737" : "#00162E",
                      }}
                    >
                      <div className={styles.appointmentMonthDetails}>
                        {`${startTime} - ${appointment.patientName} - ${getPhysicianNameById(appointment.physicianId)}`}
                      </div>
                    </div>
                  );
                })}

              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  

  const renderDayView = () => {
    const dayStart = startOfDay(currentWeek);
    const dayEnd = endOfDay(dayStart);
    const hours = generateHours(6, 23);

    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.appointmentStartDateTime) >= dayStart &&
        new Date(appointment.appointmentStartDateTime) <= dayEnd
    );

    const columns = calculateAppointmentPositions(dayAppointments);

    return (
      <div className={styles.calendarView}>
        {/* Hour Column */}
        <div className={styles.hourColumn}>
          <div className={styles.dayHeaderPlaceholder}></div>
          {hours.map((hour, index) => (
            <div key={index} className={`${styles.hourSlot} ${styles.borderLeft}`}>
              {hour.formatted}
            </div>
          ))}
        </div>
        {/* Day Column */}
        <div className={`${styles.dayColumn} ${styles.borderRight}`}>
          <div className={isToday(currentWeek) ? styles.dayHeaderToday : styles.dayHeader}>
            <div
              className={styles.dayHeaderInfo}
              style={{ textAlign: "center" }}
            >
              <div className={styles.dayHeaderFirst}>
                {format(currentWeek, "EEEE")}
              </div>
              <div className={styles.dayHeaderSecond}>
                {format(currentWeek, "d")}
              </div>
            </div>
          </div>

          {renderCurrentTimeLine()}
          
          {hours.map((hour) => {
            const hourAppointments = dayAppointments.filter(
              (appointment) =>
                new Date(appointment.appointmentStartDateTime).getHours() ===
                hour.numeric
            );

            return (
              <div
                key={hour.numeric}
                className={styles.hourSlot}
                style={{ position: "relative", height: "150px" }}
              >
                {hourAppointments.map((appointment) => {
                  const startMinutes = new Date(
                    appointment.appointmentStartDateTime
                  ).getMinutes();
                  const durationInMinutes =
                    (new Date(appointment.appointmentEndDateTime) -
                      new Date(appointment.appointmentStartDateTime)) /
                    60000;
                   /* 
                  let isInMeeting = currentMeetings.some(
                    (meeting) =>
                      meeting.appointmentId === appointment.appointmentId
                  );
                  */
                  let isInMeeting = appointment.isActive;
                  // let isInMeeting = true;
                  

                  const endDateTime = new Date(appointment.appointmentEndDateTime);
                  const hasPassed = endDateTime < nowMinus15;

                  const meetingColor = hasPassed ? "#d3d3d3"  
                    : isInMeeting ? "#1D5769"
                    : "#B2C7CE";
                  const textColor =  hasPassed ? "#373737" 
                    : isInMeeting ? "white" 
                    : "#00162E";
                  const heightPercentage = (durationInMinutes / 60) * 100;
                  const shouldHideText = heightPercentage <= 10; // Determines if the text should be hidden

                  return (
                    <div key={appointment.appointmentId}>
                      <div
                        className={`${styles.appointment} ${
                          shouldHideText ? styles.hideText : ""
                        }`}
                        onClick={() => onAppointmentClick(appointment)}
                        style={{
                          backgroundColor: meetingColor,
                          top: `${(startMinutes / 60) * 100}%`,
                          height: `${heightPercentage}%`,
                          position: "absolute",
                          left: `${
                            appointment.spanAll
                              ? 0
                              : (appointment.column /
                                  appointment.totalColumns) *
                                100
                          }%`,
                          color: textColor,
                          width: `${
                            appointment.spanAll
                              ? "100%"
                              : `${100 / appointment.totalColumns}%`
                          }`,
                        }}
                      >
                        {shouldHideText && (
                          <span className={styles.ellipsis}>...</span>
                        )}
                        {isInMeeting ? (
                          <div className={styles.appointmentDetailsWaitingRoom}>         
                            <div style={{flexShrink:"0"}}>
                            <VideoCallIcon />
                            </div>              
                            <span className={styles.inMeetingMsg}>
                              {`${appointment.patientName} in waiting room`}
                            </span>
                          </div>
                          ) :
                          <div className={styles.appointmentDetails}>
                            {appointment.patientName} <br />
                            {getPhysicianNameById(appointment.physicianId)}                              
                          </div>
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const generateHours = (startHour, endHour) => {
    const formatHour = (hour) => ({
      numeric: hour,
      formatted: new Date(0, 0, 0, hour).toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
      }),
    });

    const hours = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      hours.push(formatHour(hour));
    }

    return hours;
  };

  switch (viewType) {
    case "Day":
      return renderDayView();
    case "Week":
      return renderWeeklyView();
    case "Month":
      return renderMonthView();
    default:
      return renderWeeklyView();
  }
}

export default CalendarView;
