import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Col, Row, Container, FloatingLabel, Modal } from "react-bootstrap";
import styles from "./Appointment.module.css";
import { useSelector, useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { useDropzone } from "react-dropzone";
import { showAlert } from "../../components/PerinHealthAlertSlice";
import useAxios from "../../services/AxiosConfig";
import managementStyles from '../ManagementPages.module.css';
import { customStyles } from "../../components/AccountManagement/CustomStyles";

const daysOfWeek = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

function AppointmentForm({
  onClose,
  currentAppointment,
  onAppointmentCreated,
  physicians,
  role,
  currentPatient,
  currentAvailability,
  canManageAvailability = false,
  appointments
}) {
  // State variables for the form fields

  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [endTime, setEndTime] = useState("");
  const [notes, setNotes] = useState("");
  const [patient, setFullPatient] = useState("");
  const [patientId, setPatient] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patients, setPatients] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [physicianId, setPhysicianId] = useState(
    useSelector((state) => state.userInfo.id)
  );
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [startTime, setStartTime] = useState("");
  const userAccountId = useSelector((state) => state.userInfo.id);
  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [showNoAvailabilityWarning, setShowNoAvailabilityWarning] =
    useState(false);

  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);
  const [showConflictWarning, setShowConflictWarning] = useState(false);

  const submitDisabled = patientName === "" || phoneNumber === "" || !physicianId || !endTime || !startTime || countryCode ==="";

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [appointmentDate, setAppointmentDate] = useState(getCurrentDate());

  const updateTimeOptionsOnLoad = () => {
    const currentDate = getCurrentDate();
    const dayOfWeek = getDayOfWeek(currentDate);
    const isToday = true;

    setStartTimeOptions(
      generateTimeOptions(dayOfWeek, isToday).map((option) => ({
        value: option.props.value,
        label: option.props.children,
      }))
    );

    setEndTimeOptions(
      generateTimeOptions(dayOfWeek, isToday, true).map((option) => ({
        value: option.props.value,
        label: option.props.children,
      }))
    );
  };

  useEffect(() => {
    if (availability.length > 0) {
      updateTimeOptionsOnLoad();
    }
  }, [availability]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png", // Restrict file types to images only
  });

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
  };

    // Determine allowed roles and showPhysicianSelection flag
    const allowedRoles = ["Group Admin", "Super Admin"];
    const showPhysicianSelection = allowedRoles.includes(role) || canManageAvailability;
  
  // Determine whether this form is for editing or creating an appointment
  const isEditing = !!currentAppointment;
  const formTitle = isEditing ? "Update Appointment" : "Book Appointment";
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const dayIndex = new Date(date).getDay();
    return daysOfWeek[dayIndex];
  };

  const checkForConflicts = () => {
    const start = new Date(`${appointmentDate}T${convertTo24HourFormat(startTime)}`);
    const end = new Date(`${appointmentDate}T${convertTo24HourFormat(endTime)}`);
    return appointments.some(appointment => {
      const appointmentStart = new Date(appointment.appointmentStartDateTime);
      const appointmentEnd = new Date(appointment.appointmentEndDateTime);
      return (start < appointmentEnd && end > appointmentStart);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (checkForConflicts()) {
      setShowConflictWarning(true);
    } else {
      await submitAppointment();
    }
  };

  const submitAppointment = async () => {
    try {

    // Combine date part with start and end times
    const datePart = appointmentDate.split("T")[0];
    const formattedStartTime = convertTo24HourFormat(startTime);
    const formattedEndTime = convertTo24HourFormat(endTime);
    const combinedStartDateTime = new Date(`${datePart}T${formattedStartTime}:00`);
    const combinedEndDateTime = new Date(`${datePart}T${formattedEndTime}:00`);

    // Convert to UTC
    const utcStartDateTime = combinedStartDateTime.toISOString();
    const utcEndDateTime = combinedEndDateTime.toISOString();

    // Optionally convert startTime and endTime to UTC if needed for backend operations
    const utcStartTime = combinedStartDateTime.toISOString().split("T")[1].substring(0, 5);
    const utcEndTime = combinedEndDateTime.toISOString().split("T")[1].substring(0, 5);

    // Determine if this is a create or update operation
    const url = isEditing ? `/appointment/${currentAppointment.appointmentId}` : `/appointment/${physicianId}`;
    const method = isEditing ? "PUT" : "POST";

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("appointmentDate", new Date(appointmentDate).toISOString());
    formData.append("appointmentStartDateTime", utcStartDateTime);
    formData.append("appointmentEndDateTime", utcEndDateTime);
    formData.append("patientId", patientId);
    formData.append("email", email.trim());
    formData.append("countryCode", countryCode);
    formData.append("phoneNumber", phoneNumber.replace(/\D/g, ""));
    formData.append("notes", notes.trim());
    formData.append("physicianId", physicianId);
    formData.append("patientName", patientName.trim());
    formData.append("startTime", utcStartTime);
    formData.append("endTime", utcEndTime);

    httpService
      .request({
       method,
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    onClose();
    onAppointmentCreated();

    dispatch(
      showAlert({
        header: "Success",
        message: `Appointment ${isEditing ? "updated" : "booked"} successfully`,
        type: 'success',
      })
    );

  } catch (error) {
    console.error("Error saving appointment:", error);
    dispatch(
      showAlert({
        header: "Error",
        message: `Error ${isEditing ? "updating" : "booking"} appointment`,
        type: "danger",
      })
    );
  }
};

  const handleConflictProceed = async () => {
    setShowConflictWarning(false);
    await submitAppointment();
  };

  const formatPatients = (patientData) => {
    const formatted = patientData.map((patient) => ({
      label: patient.firstName + "" + patient.lastName,
      value: {
        firstName: patient.firstName,
        lastName: patient.lastName,
        id: patient.id,
        groups: patient.groups,
      },
    }));
    return formatted;
  };

  const fetchAvailability = async () => {
    try {
      const response = await httpService.get(
        `appointment/availability/${physicianId}`
      );
      // Check if data exists and is in the expected format
      if (response.data && Array.isArray(response.data.data)) {
        const fetchedAvailability = response.data.data.map((item) => ({
          ...item,
          start: convertTo12HourFormat(item.start),
          end: convertTo12HourFormat(item.end),
          day: daysOfWeek.find((day) => day.value === item.day).name,
        }));
        setAvailability(fetchedAvailability);
      } else {
        // Handle cases where data is missing or in an unexpected format
        setAvailability([]);
        console.log("No availability data found or data format incorrect");
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const convertToUTC = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  };

  useEffect(() => {
    fetchAvailability();
  }, [physicianId]);

  const handleDateChange = useCallback(
    async (e) => {
      try {
        setAppointmentDate(e.target.value);

        const selectedDate = new Date(e.target.value);
        const dayOfWeek = getDayOfWeek(e.target.value);
        const isAvailable = availability.some(
          (avail) => avail.day === dayOfWeek
        );

        if (!isAvailable) {
          setShowNoAvailabilityWarning(true);
        } else {
          setShowNoAvailabilityWarning(false);
        }

        setStartTimeOptions([]);
        setEndTimeOptions([]);

        const today = new Date();
        const formattedDate = today.getFullYear() + '-' 
            + String(today.getMonth() + 1).padStart(2, '0') + '-' 
            + String(today.getDate()).padStart(2, '0');
        const isToday = e.target.value === formattedDate; 

        // Now set the time options using availability
        setStartTimeOptions(
          generateTimeOptions(dayOfWeek,isToday).map((option) => ({
            value: option.props.value,
            label: option.props.children,
          }))
        );
        setEndTimeOptions(
          generateTimeOptions(dayOfWeek,isToday, true).map((option) => ({
            value: option.props.value,
            label: option.props.children,
          }))
        );

        setEndTime("");
        setStartTime("");
      } catch (error) {
        console.error("Error handling date change:", error);
      }
    },
    [
      availability,
      setAppointmentDate,
      setStartTimeOptions, 
      setEndTimeOptions,
      setStartTime,
      setEndTime,
    ]
  );

  const handlePatientSelection = useCallback(
    async (patientId) => {
        try {
          const response = await httpService.request({
            method: "get",
            headers: { "Content-Type": "application/json" },
            url: `user/${patientId}`,
          });
 
          const patientData = response.data.data;
 
          setEmail(patientData.email);
          setPhoneNumber(patientData.phone);
          setCountryCode(patientData.countryCode);
          setPatientName(`${patientData.firstName} ${patientData.lastName}`);
 
        } catch (error) {
          console.error("Failed to fetch patient details:", error);
          // Clear all fields or handle error specifically
          setEmail("");
          setPhoneNumber("");
          setCountryCode("");
          setPatientName("");
        }
     
    },
    [httpService]
  );

  const updateTimeOptions = (dayOfWeek, isToday = false) => {
    const dayAvailability = availability.filter((slot) => slot.day === dayOfWeek);

    const newStartTimeOptions = generateTimeOptions(dayOfWeek, isToday).map((option) => ({
      value: option.props.value,
      label: option.props.children,
    }));
  
    const newEndTimeOptions = generateTimeOptions(dayOfWeek, isToday, true).map((option) => ({
      value: option.props.value,
      label: option.props.children,
    }));
  
    setStartTimeOptions(newStartTimeOptions);
    setEndTimeOptions(newEndTimeOptions);
  };
  
  

  const generateTimeOptions = (day, isToday = false, forEndTime = false) => {
    const dayAvailability = availability.filter((slot) => slot.day === day);
    const now = new Date();
  
    const parseTime = (time) => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":");
      hours = hours === "12" ? 0 : parseInt(hours); // Convert "12" to "0" for 12-hour format
      if (modifier === "PM") hours += 12;
      return new Date().setHours(hours, minutes, 0, 0); // Using today's date
    };

    // Iterate through each availability slot
    const options = [];
    dayAvailability.forEach(({ start, end }) => {
      let currentTime = parseTime(start);
      const endTime = parseTime(end);

      while (forEndTime ? currentTime <= endTime : currentTime < endTime) {
        const date = new Date(currentTime);
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const time12h = `${hour % 12 || 12}:${minutes.toString().padStart(2, "0")} ${hour < 12 ? "AM" : "PM"}`;
  
        if (!isToday || (isToday && currentTime > now)) {
          options.push(
            <option key={time12h} value={time12h}>
              {time12h}
            </option>
          );
        }

        // Increment by 15 minutes
        currentTime = new Date(currentTime).setMinutes(date.getMinutes() + 15);
      }
    });

    // If generating end time options and 11:45 PM exists, add 11:59 PM
    if (forEndTime && options.some(option => option.props.value === "11:45 PM")) {
      options.push(
        <option key="11:59 PM" value="11:59 PM">
          11:59 PM
        </option>
      );
    }

    return options;
  };

  function convertTo24HourFormat(time) {
    // Split the time string
    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":");

    hours = parseInt(hours, 10);

    if (period.toUpperCase() === "PM" && hours !== 12) {
      hours += 12;
    } else if (period.toUpperCase() === "AM" && hours === 12) {
      // Midnight case
      hours = 0;
    }

    hours = hours.toString().padStart(2, "0");
    minutes = minutes ? minutes : "00";

    return `${hours}:${minutes}`;
  }

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(":");

    const hrs = parseInt(hours, 10);
    const isPM = hrs >= 12;

    const adjustedHour = hrs % 12 || 12; // Converts "00" or "12" to "12"

    return `${adjustedHour}:${minutes} ${isPM ? "PM" : "AM"}`;
  }

  const convertDate = (date) => {
    const appointmentDate = new Date(date);
    const year = appointmentDate.getUTCFullYear();
    const month = appointmentDate.getUTCMonth() + 1;
    const day = appointmentDate.getUTCDate();

    // Pad single digits
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const patientOptions = patients.map((patient) => ({
    value: patient.value.id,
    label: `${patient.value.firstName} ${patient.value.lastName}`,
  }));

  useEffect(() => {
    if (currentPatient) {
      // Prepopulate patient info
      setFullPatient({value:currentPatient.patientId, label:currentPatient.fullName});
      setPatientName(currentPatient.fullName);
      setPatient(currentPatient.patientId);
      setEmail(currentPatient.email);
      setPhoneNumber(currentPatient.phoneNumber);
      setCountryCode(currentPatient.countryCode);
    }
  }, [currentPatient]);

  useEffect(() => {
    if (currentAppointment) {

      const localStartTime = convertTo12HourFormat(new Date(currentAppointment.appointmentStartDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }));
      const localEndTime = convertTo12HourFormat(new Date(currentAppointment.appointmentEndDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }));

      // Prepopulate form
      setAppointmentDate(convertDate(currentAppointment.appointmentDate));
      setStartTime(localStartTime);
      setEndTime(localEndTime);
      setNotes(currentAppointment.notes);
      setEmail(currentAppointment.email);
      setPhoneNumber(currentAppointment.phoneNumber);
      setCountryCode(currentAppointment.countryCode);
      setPhysicianId(currentAppointment.physicianId);
      setPreviewUrl(currentAppointment.documentUrl); 
      setPatient(currentAppointment.patientId);


      const dayOfWeek = getDayOfWeek(currentAppointment.appointmentDate);
      updateTimeOptions(dayOfWeek); 

    }
  }, [currentAppointment, availability]);

  useEffect(() => {
    if (currentAppointment && patients.length > 0) {
      const selectedPatient = patients.find(
        (p) => p.value.id.toString() === currentAppointment.patientId.toString()
      );
      if (selectedPatient) {
        setFullPatient(selectedPatient);
        setPatient(selectedPatient.value.id);
        setPatientName(`${selectedPatient.value.firstName} ${selectedPatient.value.lastName}`);
        //handlePatientSelection(selectedPatient.value.id);
      }
    }
  }, [currentAppointment, patients, handlePatientSelection]);

  useEffect(() => {
    const fetchData = async () => {
      httpService
        .get(`careprogram/patient/list/${userAccountId}`)
        .then((responseData) => {
          if (Array.isArray(responseData.data.data)) {
            const seenIds = new Set();
            const filteredPatients = responseData.data.data.filter(
              (patient) => {
                if (!seenIds.has(patient.id)) {
                  seenIds.add(patient.id);
                  return true;
                }
                return false;
              }
            );
            const formattedPatients = formatPatients(filteredPatients);
            setPatients(formattedPatients);
          } else {
            console.error("Invalid data format:");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [httpService, userAccountId]);

  const handleStartTimeChange = (selectedOption) => {
    const newStartTime = selectedOption.value;
    setStartTime(newStartTime);

    if (newStartTime === "11:45 PM") {
      setEndTime("11:59 PM");
    } else {
  
      const time = convertTo24HourFormat(newStartTime);
      const [hours, minutes] = time.split(":").map(Number);
      let newMinutes = minutes + 15;
      let newHours = hours;
    
      if (newMinutes >= 60) {
        newMinutes -= 60;
        newHours += 1;
      }
      
      const newEndTime = convertTo12HourFormat(
        `${newHours.toString().padStart(2, "0")}:${newMinutes
          .toString()
          .padStart(2, "0")}`
      );
      setEndTime(newEndTime);
    }
  };

  //Custom control is used to style the input to show the label at the top left of the box
  const CustomControl = (props) => {
    const { children, ...restProps } = props;
    const hasValue = !!props.getValue().length;

    return (
      <components.Control {...restProps}>
        {hasValue && (
          <div
            style={{
              position: "absolute",
              left: "10px",
              top: "1px",
              fontSize: "8px",
              color: "#888",
              pointerEvents: "none",
              zIndex: 1,
            }}
          >
            {props.selectProps.placeholder}
          </div>
        )}
        {children}
      </components.Control>
    );
  };

  const customTimeSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      paddingLeft: 5,
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      backgroundColor: state.isFocused ? "lightgray" : "white",
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const dateMin = new Date().toLocaleDateString('fr-ca');

  
  
  return (
    <Container style={{height:"712px"}}>
      <h3 className="headline-6" style={{color:"#4F4F4F"}}>{`${isEditing ? "Update" : "Book"} Appointment`}</h3>
      <Form onSubmit={handleSubmit} >
        <div style={{display:"flex", flexDirection:"column", gap: "10px"}}>
        <Row>
          {showPhysicianSelection && (
            <>
              <span className={styles.formHeader} style={{paddingBottom:"12px"}}>Provider</span>
              <Form.Group as={Row} controlId="physicianId">
                <Col sm={12}>
                  <FloatingLabel label="Select Provider*">
                    <Form.Select
                      className={`${managementStyles.BorderedComponent}`}
                      value={physicianId}
                      placeholder=""
                      style={{width: "300px"}}
                      //isDisabled={disableName}
                      onChange={(e) => setPhysicianId(e.target.value)}
                    >
                      <option value=""></option>
                      {physicians.map((physician) => (
                        <option key={physician.value} value={physician.value}>
                          {physician.label}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Form.Group>
            </>
          )}
        </Row>
        <span className={styles.formHeader}>Date & Time </span>
        <Form.Group controlId="appointmentDate">
          <Row>
            <Col sm={12}>
              <Form.Group>
                  <FloatingLabel
                      label="Date"
                  >
                  <Form.Control
                      type="date"
                      className={`${managementStyles.BorderedComponent}`}
                      value={appointmentDate}
                      onChange={handleDateChange}
                      min={dateMin} 
                      style={{ color: appointmentDate ? "inherit" : "transparent", width: "300px" }}
                  />
                  </FloatingLabel>
              </Form.Group>
              {/* <div style={{ position: "relative" }}>
                <Form.Control
                  type="date"
                  className={`${managementStyles.BorderedComponent}`}
                  value={appointmentDate}
                  onChange={handleDateChange}
                  style={{ color: appointmentDate ? "inherit" : "transparent" }}
                />
                {!appointmentDate && (
                  <label
                    style={{
                      position: "absolute",
                      top: "7px",
                      left: "10px",
                      height: "100%",
                      lineHeight: "38px",
                      pointerEvents: "none",
                      color: "#6c757d",
                    }}
                  >
                    Date*
                  </label>
                )}
              </div> */}
            </Col>
          </Row>
          {showNoAvailabilityWarning &&
            <Row>
                <div className="alert alert-warning" role="alert">
                  No availability for the selected day.
                </div>
            </Row>
          }     
        </Form.Group>
        <Form.Group style={{width:"300px"}} >
          <Row>
            <Col xs={6} style={{ position: "relative", paddingRight:"5px"  }}>
              {startTime && <span className={"floating-label"} style={{zIndex:"1"}}>Start Time*</span>}
              <Select
                // components={{ Control: CustomControl }}
                value={
                  startTimeOptions.find(
                    (option) => option.value === startTime
                  ) || null
                }
                onChange={handleStartTimeChange}
                options={startTimeOptions}
                placeholder="Start Time*"
                styles={customStyles.popup}
              />
            </Col>
            <Col xs={6} style={{ position: "relative"}}>
              {endTime && <span className={"floating-label"} style={{zIndex:"1"}}>End Time*</span>}
              <Select
                // components={{ Control: CustomControl }}
                value={
                  endTimeOptions.find((option) => option.value === endTime) ||
                  null
                }
                onChange={(selectedOption) => setEndTime(selectedOption.value)}
                options={endTimeOptions}
                placeholder="End Time*"
                styles={customStyles.popup}
              />
            </Col>
          </Row>
        </Form.Group>

        <span className={styles.formHeader}>Patient</span>
        <Form.Group as={Row} controlId="patientId">
          <Col sm={12}>
            <div style={{position: "relative", width:"300px"}}>
                {patient && <span className={"floating-label"} style={{zIndex:"1"}}>Patient Name*</span>}
                <Select
                  styles={customStyles.popup}
                  options={patientOptions}
                  value={patient}
                  onChange={(selectedOption) => {
                    setFullPatient(selectedOption);
                    setPatient(selectedOption.value);
                    handlePatientSelection(selectedOption.value);
                  }}
                  placeholder="Select Patient*"
                  isDisabled={isEditing} 
                />
            </div>
          </Col>
        </Form.Group>

        {/* Email and Phone Number */}
        <Form.Group as={Row} controlId="patientEmail">
          <Col sm={12}>
            <FloatingLabel label="Patient Email">
              <Form.Control
                type="email"
                className={`${managementStyles.BorderedComponent}`}
                value={email}
                placeholder="Patient Email*"
                onChange={(e) => setEmail(e.target.value)}
                style={{width: "300px" }}
              />
            </FloatingLabel>
          </Col>
        </Form.Group>

        <Form.Group style={{width:"300px"}} >
          <Row>
          <Col xs={4}>
          <FloatingLabel label="Code">
            <Form.Control
              type="tel"
              style={{width:"100px"}}
              placeholder="Code"
              className={`${managementStyles.BorderedComponent}`}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            />
             </FloatingLabel>
          </Col>
          <Col xs={8}>
            <FloatingLabel label="Phone Number">
              <Form.Control
                type="tel"
                placeholder="Phone Number*"
                style={{width:"100%"}}
                className={`${managementStyles.BorderedComponent}`}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          </Row>
        </Form.Group>

        <span className={styles.formHeader}>Upload Insurance</span>
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed gray",
            padding: "20px",
            textAlign: "center",
            width:"300px",
            cursor: "pointer"
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop File or browse</p>
          ) : (
            <p>Drop File or browse</p>
          )}
        </div>
        {previewUrl && (
          <div style={{ marginTop: "20px", position: "relative" }}>
            <img
              src={previewUrl}
              alt="Preview"
              style={{ width: "60%", height: "20%", right: 0 }}
            />
            <Button
              variant="secondary"
              onClick={handleRemoveImage}
              style={{ position: "absolute", top: 0 }}
            >
              x
            </Button>
          </div>
        )}

        <span className={styles.formHeader}>Additional Info </span>
        <Form.Group as={Row} controlId="formNotes">
          <Col sm={12}>
            <Form.Control
              as="textarea"
              rows={3}
              style={{width:"300px"}}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Col>
        </Form.Group>
        </div>
        <Form.Group as={Row} style={{paddingLeft:"10px", paddingTop: "15px", display:"flex", columnGap:"10px"}}>
          <Button
            className={managementStyles.BackButton}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={managementStyles.CreateButton}
            variant="secondary"
            type="submit"
            disabled={submitDisabled}
          >
            {isEditing ? "Update Appointment" : "Book Appointment"}
          </Button>
        </Form.Group>
        <Modal show={showConflictWarning} onHide={() => setShowConflictWarning(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Conflict Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            An appointment is already scheduled during this time. Do you want to proceed with scheduling?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConflictWarning(false)}>Cancel</Button>
            <Button variant="primary" onClick={handleConflictProceed}>Proceed</Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Container>
  );
}

export default AppointmentForm;
