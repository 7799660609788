import React from "react";
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import '../../components/AccountManagement/Accounts.css';
import inputStyles from '../ManagementPages.module.css';

const ActivateEmergencyContact = ({userData, handleChange}) => {

    return (
        <div className="personal-info-container">
            <div className="personal-info-content">
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="fullName"
                        value={userData.emergencyContact.fullName}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    ></input>
                    <label className={userData.emergencyContact.fullName && inputStyles['filled']}>
                        Emergency Contact Full Name
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="relation"
                        value={userData.emergencyContact.relation}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    ></input>
                    <label className={userData.emergencyContact.relation && inputStyles['filled']}>
                    Relationship
                    </label>
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Gender</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="gender"
                        value={userData.emergencyContact.gender}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    >
                        <option value="">Select a Gender</option>
                        {DropdownData.gender.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                    <div className={inputStyles["styled-input-container"]} style={{width:"40%"}}>
                        <input
                            style={{width:"100%"}}
                            id="countryCode"
                            value={userData.emergencyContact.countryCode}
                            onChange={(e)=>handleChange(e,null,"emergencyContact")}
                        ></input>
                        <label className={userData.emergencyContact.countryCode && inputStyles['filled']}>
                            Country Code
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]} style={{width:"58%"}}>
                        <input
                            style={{width:"100%"}}
                            id="phone"
                            value={userData.emergencyContact.phone}
                            onChange={(e)=>handleChange(e,null,"emergencyContact")}
                        ></input>
                        <label className={userData.emergencyContact.phone && inputStyles['filled']}>
                            Phone Number
                        </label>
                    </div>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="email"
                        type="email"
                        value={userData.emergencyContact.email}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    ></input>
                    <label className={userData.emergencyContact.email && inputStyles['filled']}>
                        Email
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="address1"
                        value={userData.emergencyContact.address1}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    ></input>
                    <label className={userData.emergencyContact.address1 && inputStyles['filled']}>
                    Street Address
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="address2"
                        value={userData.emergencyContact.address2}
                        onChange={(e)=>handleChange(e,null,"emergencyContact")}
                    ></input>
                    <label className={userData.emergencyContact.address2 && inputStyles['filled']}>
                    Apartment, Suite, etc
                    </label>
                </div>
                <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                    <div className="input-block">
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="city"
                                value={userData.emergencyContact.city}
                                onChange={(e)=>handleChange(e, "city", "emergencyContact")}
                            ></input>
                            <label className={userData.emergencyContact.city && inputStyles['filled']}>
                            City
                            </label>
                        </div>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="state"
                                value={userData.emergencyContact.state}
                                onChange={(e)=>handleChange(e, "state", "emergencyContact")}
                            ></input>
                            <label className={userData.emergencyContact.state && inputStyles['filled']}>
                            State
                            </label>
                        </div>
                    </div>
                    <div className="input-block" style={{marginBottom:"0px", width: "100%"}}>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="country"
                                value={userData.emergencyContact.country}
                                onChange={(e)=>handleChange(e, "country", "emergencyContact")}
                            ></input>
                            <label className={userData.emergencyContact.country && inputStyles['filled']}>
                            Country
                            </label>
                        </div>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="zip"
                                value={userData.emergencyContact.zip}
                                onChange={(e)=>handleChange(e, "zip", "emergencyContact")}
                            ></input>
                            <label className={userData.emergencyContact.zip && inputStyles['filled']}>
                            Postal Code
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ActivateEmergencyContact;