import React, { useState } from "react";
import { DropdownData } from './DropdownData';
import './Accounts.css';
import styles from '../../views/ManagementPages.module.css';
import { setNestedField } from './RegistrationSlice';
import { useSelector, useDispatch } from 'react-redux';


const Preferences = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state)=>state.registration);
    const userUnitType = userData.preferences.unitType.includes("Metric") ? "Metric" : userData.preferences.unitType.includes("Imperial") ? "Imperial" : "";
    const [unitType, setUnitType] = useState(userUnitType);

    const handleUnitChange = (e) => {
        setUnitType(e.target.value);
        dispatch(setNestedField({parentKey:"preferences",nestedKey:e.target.id,value:e.target.value}))
    };

    return (
        <div className="create-account-inputs" style={{height:"100%"}}>
            <div style={{position: "relative"}}>
                <span className="floating-label">Date Format</span>
                <select
                    className={styles["styled-input-select"]}
                    id="dateFormat"
                    value={userData.preferences.dateFormat}
                    style={{width:"300px"}}
                    onChange={(e) => dispatch(setNestedField({parentKey:"preferences",nestedKey:"dateFormat",value:e.target.value}))}
                >
                    <option value="">Select Date Format</option>
                    {DropdownData.dateFormats.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{marginBottom:"10px"}}></div>
            <div className="unit-types" style={{display: "flex", width: "330px", justifyContent:"space-between"}}>
                Unit Type
                <div>
                    <input
                        id="unitType" 
                        type="radio"
                        value="Imperial"
                        checked={unitType === 'Imperial'}
                        onChange={handleUnitChange}
                    /> 
                    <span style={{paddingLeft: "5px"}}>Imperial (lbs, F)</span>
                </div>
                <div>
                    <input
                        id="unitType" 
                        type="radio"
                        value="Metric"
                        checked={unitType === 'Metric'}
                        onChange={handleUnitChange}
                    /> 

                    <span style={{paddingLeft: "5px"}}>Metric (kg, C)</span>
                </div>
            </div>
        </div>
  );
};

export default Preferences;
