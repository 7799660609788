import React from 'react';
import styles from './CardDetails.module.css';


const QuestionnaireDetails = ( { questionnaire } ) => {
    const questions = questionnaire.questions;
    const answers = questionnaire.answers ?? [];
    return (
        <div className={styles.DetailsContainer}>
            <span>{`Total ${questions.length} questions ${questionnaire.score !== undefined ? `Score | ${questionnaire.score}/${questionnaire.possibleScore}` : ""}`}</span>
            {questions.map((question, index) => {
                return (
                    <div key={index} className={styles.Question}>
                        <span>{`${index+1}. ${question.questionText}`}</span>
                        {answers.length > 0 && 
                        <>
                            <span>{answers[index].selectedAnswer}</span>
                            <span>{`Score: ${answers[index].score}`}</span>
                        </>
                        }
                    </div>
                )
            })}
        </div>
    )
}
export default QuestionnaireDetails;