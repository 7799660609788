import React, { useState, useCallback, useEffect } from 'react';
import styles from './PerinHealthGrid.module.css';

const PerinHealthGridResizable = ({ children }) => {
    const [node, setNode] = useState(null);

    const ref = useCallback((nodeEle) => {
        setNode(nodeEle);
    }, []);

    const handleMouseDown = useCallback((e) => {
        if (!node) {
            return;
        }

        const parent = node.parentElement;
        const startPos = {
            x: e.clientX,
            y: e.clientY,
        };
        const styles = window.getComputedStyle(parent);
        const w = parseInt(styles.width, 10);

        const handleMouseMove = (e) => {
            const dx = e.clientX - startPos.x;
            parent.style.width = `${w + dx}px`;
            updateCursor();
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            resetCursor();
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [node]);

    const updateCursor = () => {
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
    };

    const resetCursor = () => {
        document.body.style.removeProperty('cursor');
        document.body.style.removeProperty('user-select');
    };

    useEffect(() => {
        if (!node) {
            return;
        }
        node.addEventListener("mousedown", handleMouseDown);

        return () => {
            node.removeEventListener("mousedown", handleMouseDown);
        };
    }, [node, handleMouseDown]);

    return children({ ref, styles }); 
};

export default PerinHealthGridResizable;
