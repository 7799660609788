import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VideoAndMessage.module.css';
import {
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  ConsoleLogger,
  DefaultDeviceController,
} from 'amazon-chime-sdk-js';
import { Button, Form, CloseButton, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CallEndIcon, ExpandIcon, UserProfilePictureIcon, MinimizeIcon } from './icons/Icons';
import useAxios from '../services/AxiosConfig';
import { useWebSocket } from '../services/webSocket/useWebSocket';
import { setExpanded, setMinimized, setCurrentMeetingId } from '../slicers/CallContainerSlice';
import { showAlert} from './PerinHealthAlertSlice';

function VideoCallContainer({ audioOnly = false, onClose, data }) {
  const meetingSessionRef = useRef(null);
  const meetingIdRef = useRef(null);
  const meetingArnRef = useRef(null);
  const audioElementRef = useRef(null);
  const previewVideoRef = useRef(null);
  const [meetingData, setMeetingData] = useState(null);
  const [videoTiles, setVideoTiles] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [isVideoPreviewReady, setIsVideoPreviewReady] = useState(false);
  const [inPreviewMode, setInPreviewMode] = useState(true);
  const userId = useSelector((state) => state.userInfo.id);
  const userFullName = useSelector((state) => state.userInfo.fullName);
  const axiosConfig = useSelector(state => state.axiosConfig);
  const dispatch = useDispatch();
  const {expanded, minimized, joiningStaffCall }= useSelector(state => state.callContainer);
  const [attendeeCount, setAttendeeCount] = useState(0);
  const attendeesRef = useRef(new Set());

  const isWaiting = attendeeCount === 1;

  const localAttendeeIdRef = useRef(null);
  const [callDuration, setCallDuration] = useState(0);
  // initialize countdown for 60 seconds
  const [timeoutDuration, setTimeoutDuration] = useState(60);

  const { sendMessage } = useWebSocket();

  const httpService = useAxios(axiosConfig.envURL, dispatch);

  const toggleMute = useCallback(async () => {
    if (isMuted) {
      await meetingSessionRef.current.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      await meetingSessionRef.current.audioVideo.realtimeMuteLocalAudio();
    }
    setIsMuted(!isMuted);
  }, [isMuted]);

  const toggleVideo = useCallback(async () => {
    if (!meetingSessionRef.current) return;
    const audioVideo = meetingSessionRef.current.audioVideo;
    if (!audioVideo) return;

    if (!hideVideo) {
      audioVideo.stopLocalVideoTile();
      setHideVideo(true);
    } else {
      audioVideo.startLocalVideoTile();
      setHideVideo(false);
    }
  }, [hideVideo]);

  useEffect(() => {
    let timer;
    if (!inPreviewMode) {
      timer = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setCallDuration(0);
    }
    return () => clearInterval(timer);
  }, [inPreviewMode]);

  useEffect(() => {
    let countdownTimer;
    if (attendeeCount===1) {
      countdownTimer = setInterval(() => {
        setTimeoutDuration(prev => {
          // called recipient for 60 seconds, hang up and display unavailable message
          if (prev <= 0) { 
            clearInterval(countdownTimer);
            leaveMeeting();
            dispatch(
              showAlert({
                  header: "Staff Unavailable",
                  message: `${data.recipientName} is not available`,
                  type: 'warning',
                  dismissable: false
              }
            ));
            return prev;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      clearInterval(countdownTimer);
      setTimeoutDuration(60);
    }
    return () => clearInterval(countdownTimer);
  }, [attendeeCount]);

  const joinMeeting = useCallback(async () => {
    if (!meetingData) return;

    if (!audioOnly) {
      meetingSessionRef.current.audioVideo.stopVideoPreviewForVideoInput(previewVideoRef.current);
    }
    meetingSessionRef.current.audioVideo.start();

    if (!audioOnly) {
      meetingSessionRef.current.audioVideo.startLocalVideoTile();
    }

    sendMessage({
      type: 'staffHasJoined',
      meetingId: meetingIdRef.current,
      senderId: data.senderId,
      senderName: userFullName,
      recipientId: data.recipientId,
      isAudioOnly: audioOnly,
    });
    setInPreviewMode(false);
    try {
      // await startMediaCapture();
    } catch (err) {
      console.error('Failed to start media capture:', err);
    }
  }, [meetingData, audioOnly, data, sendMessage]);

  const leaveMeeting = useCallback(async () => {
    if (meetingSessionRef.current) {
      const audioVideo = meetingSessionRef.current.audioVideo;
      if (audioVideo) {
        await audioVideo.stopLocalVideoTile();
        await audioVideo.stopVideoInput();
        await audioVideo.stopAudioInput();
        await audioVideo.stop();
        audioVideo.unbindAudioElement();
      }
      if (meetingIdRef.current) {
        try {
          await httpService.delete(`/meeting/${meetingIdRef.current}`);
          // Clean up any other resources or perform additional state cleanup here
        } catch (err) {
          console.error('Failed to delete meeting:', err);
        }
      }
      console.log("Meeting and video stopped");
    }
    onClose();
    sendMessage({
      type: 'staffHasLeft',
      meetingId: meetingIdRef.current,
      senderId: data.senderId,
      recipientId: data.recipientId,
    });
    setMeetingData(null);
    meetingSessionRef.current = null;
  }, [httpService, onClose, data, sendMessage, dispatch]);


  useEffect(() => {
    const startMeetingSetup = async () => {
      try {

        const postData = {
          title: data.title,
          appointmentId: data.appointmentId,
          senderId: data.senderId,
          recipientId: data.recipientId,
          recipientName: data.recipientName,
          senderName: data.senderName,
          audioOnly: audioOnly
        };

        if (data.meetingId) {
          postData.meetingId = data.meetingId;
        }

        const meetingResponse = await httpService.request({
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          url: `/meeting/join`,
          data: JSON.stringify(postData),
        });

        if (meetingResponse.data.data.meeting && meetingResponse.data.data.attendee) {
          meetingIdRef.current = meetingResponse.data.data.meeting.MeetingId;
          meetingArnRef.current = meetingResponse.data.data.meeting.MeetingArn;
          setMeetingData({
            Meeting: meetingResponse.data.data.meeting,
            Attendee: meetingResponse.data.data.attendee,
          });
        }
      } catch (err) {
        console.error('Error setting up the meeting:', err);
      }
    };

    startMeetingSetup();
  }, [userId, data, httpService]);

  useEffect(() => {
    if (meetingData) {
      localAttendeeIdRef.current = meetingData.Attendee.AttendeeId;
    }
  }, [meetingData]);

  useEffect(() => {
    if (meetingIdRef.current) {
      dispatch(setCurrentMeetingId(meetingIdRef.current));
    }
  }, [meetingIdRef.current, dispatch]);

  useEffect(() => {
    if (!meetingData) return;

    const logger = new ConsoleLogger('MeetingLogs', LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    const configuration = new MeetingSessionConfiguration(meetingData.Meeting, meetingData.Attendee);
    const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

    meetingSessionRef.current = meetingSession;

    if (meetingSessionRef.current) {
      meetingSessionRef.current.audioVideo.transcriptionController.subscribeToTranscriptEvent((transcriptEvent) => {
        const results = transcriptEvent.results || [];
      });
    }

    const observer = {
      videoTileDidUpdate: tileState => {
        if (!tileState.boundAttendeeId || audioOnly) return;

        setVideoTiles(prevTiles => {
          // Check if tile already exists
          const tileIndex = prevTiles.findIndex(t => t.tileId === tileState.tileId);
          if (tileIndex !== -1) return prevTiles;

          // Create a new ref for the video element
          const newRef = React.createRef();
          return [...prevTiles, { tileId: tileState.tileId, ref: newRef, boundAttendeeId: tileState.boundAttendeeId }];
        });
      },
      videoTileWasRemoved: tileId => {
        if (audioOnly) return;
        setVideoTiles(prevTiles => {
          return prevTiles.filter(tile => tile.tileId !== tileId);
        });
      },
    };

    const controllerObserver = {
      eventDidReceive(name, attributes) {
        if (name === 'meetingEnded') {
          leaveMeeting();
        }
      }
    };

    const attendeePresenceCallback = (attendeeId, present) => {
      if (present) {
        attendeesRef.current.add(attendeeId);
      } else {
        attendeesRef.current.delete(attendeeId);
      }
      setAttendeeCount(attendeesRef.current.size);
    };

    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeePresenceCallback);

    meetingSession.audioVideo.addObserver(observer);
    meetingSession.eventController.addObserver(controllerObserver)

    meetingSession.audioVideo.addObserver(observer);

    const setupDevices = async () => {
      // Invoke devices
      meetingSession.audioVideo.setDeviceLabelTrigger(async () => {
        const constraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          },
          video: !audioOnly
        };
        return await navigator.mediaDevices.getUserMedia(constraints);
      });

      const audioInputDevices = await deviceController.listAudioInputDevices();
      await deviceController.startAudioInput(audioInputDevices[0].deviceId);
      meetingSession.audioVideo.bindAudioElement(audioElementRef.current);

      if (!audioOnly) {
        const videoInputDevices = await deviceController.listVideoInputDevices();
        if (videoInputDevices.length > 0) {
          await deviceController.startVideoInput(videoInputDevices[0].deviceId);
          await meetingSession.audioVideo.startVideoPreviewForVideoInput(previewVideoRef.current);
          setIsVideoPreviewReady(true);
        }
      }

      const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
      if (audioOutputDevices.length > 0) {
        await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
      }
    };

    if (previewVideoRef.current) {
      setupDevices();
    }

    return () => {
      meetingSession.audioVideo.transcriptionController.unsubscribeFromTranscriptEvent()
      meetingSession.audioVideo.removeObserver(observer);
      meetingSession.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(attendeePresenceCallback);
      meetingSession.eventController.removeObserver(controllerObserver);
    };
  }, [meetingData, audioOnly]);

  useEffect(() => {
    videoTiles.forEach(tile => {
      if (tile.ref.current) {
        meetingSessionRef.current.audioVideo.bindVideoElement(tile.tileId, tile.ref.current);
      }
    });
  }, [videoTiles]);

  const recipientVideoOff = videoTiles.length === 1 && attendeeCount > 1;

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const toggleModalSize = () => {
    dispatch(setExpanded());
  };

  const toggleMinimizeSize = () => {
    dispatch(setMinimized());
  };

  const getInitials = (name) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
     <div className={minimized ? styles.VideoCallContainerMinimized : expanded ? styles.VideoCallContainerExpanded : styles.VideoCallContainer}>
      <div className={styles.VideoCallTopSection}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap:"10px" }}>
          <Button variant="white" className={styles.expandIcon} onClick={toggleMinimizeSize}>
            <MinimizeIcon />
          </Button>
          <Button variant="white" className={styles.expandIcon} onClick={toggleModalSize}>
            <ExpandIcon />
          </Button>
          <CloseButton onClick={leaveMeeting} className={styles.closeButton} />
        </div>
      </div>
      <div className={styles.VideoCallMiddleSection}>
        <div style={{ width: '100%', height: '100%', position:"relative"}}>
          {audioOnly ? (
            <>
              <div className={`${styles.iconContainer} ${styles.icon}`}>
                <UserProfilePictureIcon width="88px" height="88px" />
                <div className={styles.initialsCall}>{data.recipientName?.split(' ')[0][0] + data.recipientName?.split(' ')[1][0]}</div>
              </div>
              <span style={{ marginTop: "10px" }} className="headline-5">{data.recipientName}</span>
              {!inPreviewMode && !minimized &&
                <span className="subtitle-1">{formatTime(callDuration)}</span>
              }
            </>
          ) : (
            inPreviewMode ? (
              <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <span className="subtitle-1">{`Ready to ${joiningStaffCall ? "join call with " : "call"} ${data.recipientName}`}</span>
                <span className="body-2">Please ensure your camera and microphone are enabled.</span>
                <video ref={previewVideoRef} autoPlay playsInline style={{ width: '100%', height: '100%', borderRadius: "12px" }} />
              </div>
            ) : (
              <div className={styles.videoArea}>
                {videoTiles.map(tile => (
                  <div key={tile.tileId} className={tile.boundAttendeeId === localAttendeeIdRef.current ? (expanded ? styles.localVideoTopRightExpanded : styles.localVideoTopRight) : styles.remoteVideo}>
                    <video
                      ref={tile.ref}
                      className={styles.videoElement}
                    />
                  </div>
                ))}
                {isWaiting && (
                  <div className={styles.waitingMessage}>
                    <div style={{fontSize:"20px"}}>Calling ...</div>
                    <div>{data.recipientName}</div>
                  </div>
                )}
                {recipientVideoOff && (
                  <div className={styles.waitingMessage}>
                    <div className={styles.initialsPlaceholderTest}>
                        <UserProfilePictureIcon width="88px" height="88px" />
                        <div className={styles.initialsTest}>
                          {getInitials(data.recipientName)}
                        </div>
                      </div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
        <audio ref={audioElementRef} style={{ display: 'none' }}></audio>
      </div>
      <div className={styles.VideoCallButtonSection} style={inPreviewMode ? {height:"110px"} : {height:"50px"}}>
        {!inPreviewMode && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div style={{ width: "25%" }}></div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "40%" }}>
              {!audioOnly &&
                <Button className={styles.muteButton} variant="light" onClick={toggleVideo}>
                  {hideVideo ? (
                    <FontAwesomeIcon style={{ color: "#4F4F4F", }} icon={['fas', 'video-slash']} size="xl" />
                  ) : (
                    <FontAwesomeIcon style={{ color: "#4F4F4F", }} icon={['fas', 'video']} size="xl" />
                  )}
                </Button>
              }
              <Button variant="light" onClick={toggleMute} style={{ width: "51px", marginRight: "5px" }} className={styles.muteButton}>
                {isMuted ? <FontAwesomeIcon icon="fa-solid fa-microphone-slash" style={{ color: "#4F4F4F", }} size="xl" /> : <FontAwesomeIcon icon="fa-solid fa-microphone" style={{ color: "#4F4F4F", }} size="xl" />}
              </Button>
              <Button variant="danger" onClick={leaveMeeting} className={styles.endCallButton}><CallEndIcon /></Button>
            </div>
            <div style={{ width: "25%", textAlign: 'right' }}>
              {!audioOnly &&
                <span className="subtitle-1">{formatTime(callDuration)}</span>
              }
            </div>
          </div>
        )}
        {inPreviewMode && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", gap: "20px", paddingTop: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Form style={{ display: "flex", alignItems: "center" }}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={!isMuted}
                  onChange={toggleMute}
                />
                {isMuted ? (
                  <FontAwesomeIcon icon="fa-solid fa-microphone-slash" style={{ color: "#4F4F4F", }} size="lg" />
                ) : (
                  <FontAwesomeIcon icon="fa-solid fa-microphone" style={{ color: "#4F4F4F", }} size="lg" />
                )}
              </Form>
            </div>
            <div style={{ width: "85%", display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: "20px" }}>
              <Button 
                className={styles.StartCallButton} 
                onClick={joinMeeting}
                disabled={!audioOnly ? !isVideoPreviewReady : false}
              >

                {(!audioOnly && !isVideoPreviewReady) && 
                  <Spinner className="spinner" animation="border" role="status" variant="secondary" />
                }
                {joiningStaffCall ? "Join Call" : "Start Call"}
              </Button>
              <Button className={styles.CancelCallButton} onClick={leaveMeeting}>Cancel</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

}

export default VideoCallContainer;
