import React from 'react';
import { Text } from 'recharts';

const PerinYAxisTick = ({ x, y, payload }) => {
  return (
    <Text x={x} y={y} textAnchor="end" verticalAnchor="middle" fontSize={11} fill="#666">
      {payload.value}
    </Text>
  );
};

export default PerinYAxisTick;
