import { useMemo, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { incrementRequestsCount, decrementRequestsCount } from './AxiosConfigSlice';
import { useNavigate } from 'react-router-dom';
import { resetUserState, setTokens } from '../slicers/UserSlice';
import { showTimeOutAlert } from '../slicers/TimeOutAlertSlice';

const useAxios = (baseURL, isSpinner = false) => {
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state.userInfo.tokens);
  const navigate = useNavigate();
  const tokensRef = useRef(tokens);

  useEffect(() => {
    tokensRef.current = tokens;
  }, [tokens]);

  const axiosInstance = useMemo(() => {
    const instance = axios.create({ baseURL });

    instance.interceptors.request.use(
      (config) => {
        if (tokensRef.current) {
          config.headers['authorization'] = `Bearer ${tokensRef.current.authorization}`;
          config.headers['refresh'] = `${tokensRef.current.refresh}`;
          config.headers['id'] = `${tokensRef.current.id}`;
        }

        if (!isSpinner) {
          dispatch(incrementRequestsCount());
        }

        return config;
      },
      (error) => {
        if (!isSpinner) {
          dispatch(decrementRequestsCount());
        }
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        if (!isSpinner) {
          dispatch(decrementRequestsCount());
        }

        // Check for the new access token in the response headers
        const newAccessToken = response.headers['authorization-refresh'];
        if (newAccessToken) {
          // Update tokens in Redux state
          const updatedTokens = {
            ...tokensRef.current,
            authorization: newAccessToken,
          };
          dispatch(setTokens(updatedTokens));
          tokensRef.current = updatedTokens; // Update tokensRef immediately
        }

        return response;
      },
      (error) => {
        if (!isSpinner) {
          dispatch(decrementRequestsCount());
        }
        const responseInfo = error.response?.data;
        if (responseInfo?.message === "Authentication failed. Please log in again.") {
          dispatch(resetUserState());
          navigate('/');
          dispatch(showTimeOutAlert());
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [dispatch, baseURL, isSpinner, navigate]);

  return axiosInstance;
};

export default useAxios;
