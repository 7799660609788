import React from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, ReferenceLine } from 'recharts';
import PerinTooltip from './PerinTooltip';
import PerinYAxisTick from './PerinYAxisTick';

const PerinLineChart = ({
    chartWidth,
    chartHeight,
    data,
    vitalName,
    vitals,
    deviceNamesData,
    colors,
    opacity,
    handleLegendClick,
    handlePointClick,
    highestMeasurement,
    lowestMeasurement,
}) => {

    const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

    const generateTicks = () => {
        let ticks = [
          lowestMeasurement !== null && lowestMeasurement < vitals?.lowerLimit ? lowestMeasurement : vitals?.lowerLimit - 5,
          vitals?.lowerLimit,
          vitals?.targetValue,
          vitals?.upperLimit,
          highestMeasurement !== null && highestMeasurement > vitals?.upperLimit ? highestMeasurement : vitals?.upperLimit
        ];
    
        const uniqueTicks = Array.from(new Set(ticks.filter(tick => tick !== undefined && tick !== null))).sort((a, b) => a - b);
        return uniqueTicks;
      };
    
      const calculateDomain = () => {
        const lowerLimit = vitals?.lowerLimit;
        const upperLimit = vitals?.upperLimit;
        let minDomain = lowerLimit;
    
        if (lowestMeasurement !== null && lowestMeasurement < lowerLimit) {
          minDomain = lowestMeasurement;
        }
    
        return [minDomain, upperLimit];
      };
    
    return (
        <LineChart width={chartWidth} height={chartHeight} data={data} margin={{ left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date"
              fontSize={12}
              padding={{ right: 5 }}
              />
            <YAxis
                domain={calculateDomain()}
                ticks={generateTicks()}
                tick={<PerinYAxisTick />}
                fontSize={12}
            />
            <Tooltip content={<PerinTooltip vitals={vitals} />} />
            <Legend
                verticalAlign="top"
                height={30}
                align={ "right"}
                iconType="square"
                wrapperStyle={{ color: 'red', fontSize: '14px'}}
                payload={deviceNamesData.map((deviceName, index) => ({
                    value: deviceName,
                    type: 'square',
                    color: colors[index] || '#000000',
                }))}
                onClick={handleLegendClick}
            />
            <ReferenceLine y={highestMeasurement > vitals?.upperLimit ? highestMeasurement : vitals?.upperLimit} stroke="red"/>
            <ReferenceArea y1={highestMeasurement > vitals?.upperLimit ? highestMeasurement : vitals?.upperLimit} y2={vitals?.upperLimit} fill="rgba(235, 182, 182, 0.12)" stroke="none" strokeDasharray="3 3" />
            <ReferenceLine y={vitals?.upperLimit} stroke="red" strokeDasharray="3 3" />
            <ReferenceLine y={vitals?.targetValue} stroke="green" strokeDasharray="3 3" />
            <ReferenceArea y1={lowestMeasurement < vitals?.lowerLimit ? lowestMeasurement : vitals?.lowerLimit -5} y2={vitals?.lowerLimit} fill="rgba(235, 182, 182, 0.12)" stroke="red" strokeDasharray="3 3" />
            {deviceNamesData?.map((deviceName, index) => (
                <Line connectNulls
                    key={deviceName}
                    type="linear"
                    dataKey={`${deviceName}.${vitalName}`}
                    stroke={colors[index] || '#000000'}
                    strokeOpacity={opacity[deviceName] || 0}
                    strokeWidth={2}
                    dot={(props) => {
                        const { cx, cy, value, key, payload } = props;
                        let dotColor = '#828282';
                        if (value < vitals?.lowerLimit || value > vitals?.upperLimit) {
                            dotColor = '#CA3B3B';
                        } else if (value === vitals?.targetValue) {
                            dotColor = '#2A7F38';
                        }
                        if (value !== undefined && value !== null) {
                            return (
                                <circle
                                    cx={cx}
                                    cy={cy}
                                    r={4}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePointClick(deviceName, payload[deviceName]);
                                    }}
                                    style={{ pointerEvents: 'visible' }}
                                    fill={opacity[deviceName] === 0 ? 'transparent' : dotColor === '#CA3B3B' ? `rgba(202, 59, 59, ${opacity[deviceName]})` : (dotColor === '#2A7F38' ? `rgba(42, 127, 56, ${opacity[deviceName]})` : '#828282')}
                                    stroke={dotColor}
                                    strokeOpacity={opacity[deviceName] || 0}
                                    strokeWidth={2}
                                    key={key}
                                />
                            );
                        } else {
                            return null;
                        }
                    }}
                    activeDot={(props) => {
                        const { cx, cy, value, key, payload } = props;
                        let dotColor = '#828282';
                        if (value < vitals?.lowerLimit || value > vitals?.upperLimit) {
                            dotColor = '#CA3B3B';
                        } else if (value === vitals?.targetValue) {
                            dotColor = '#2A7F38';
                        }
                        if (value !== undefined && value !== null) {
                            return (
                                <circle
                                    cx={cx}
                                    cy={cy}
                                    r={4}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePointClick(deviceName, payload[deviceName]);
                                    }}
                                    fill={dotColor === '#CA3B3B' ? `rgba(202, 59, 59, ${opacity[deviceName]})` : (dotColor === '#2A7F38' ? `rgba(42, 127, 56, ${opacity[deviceName]})` : '#828282')}
                                    style={{ pointerEvents: 'visible' }}
                                    stroke={dotColor}
                                    strokeOpacity={opacity[deviceName] || 0}
                                    strokeWidth={2}
                                    key={key}
                                />
                            );
                        } else {
                            return null;
                        }
                    }}
                />
            ))}
        </LineChart>
    );
};

export default PerinLineChart;
