import { faCalendarAlt, faComment, faComments, faPhone, faVideo } from '@fortawesome/free-solid-svg-icons';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  firstName: 'John',
  lastName: 'Doe',
  urn:'104523485',
  age:'71 y.o.',
  timeZone:'PST',
  language: 'English',
  groups: [],
  email: '',
  carePrograms: [],
  emergencyContact: {},
  healthInfo: {},
  address: {},
  preferences: {},
  phone: "",
  countryCode: '',
  mrn: "",
  ssn: "",
  canHaveAppointment: false,
  canManageAvailability: false,
  orgHierarchy: [],
  role: {},
  gender: '',
  fullName: '',
  notes: [],
  appointments: [],
  reports: [],
  careTeam: [],
  questionnaireIds: [],
  questionnaireResponses: [],
  patientHeaderButton : [
    { name: 'Chat', icon: faComment },
    { name: 'Video Call', icon: faVideo },
    { name: 'Phone Call', icon: faPhone },
    { name: 'Book Appointment', icon: faCalendarAlt }
  ],
  
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    updatePatientInfo: (state, action) => {
      const id = action.payload;
      state.id = id;
    },
    setPatientPrograms: (state, action) => {
      state.carePrograms = action.payload;
    },
    setPatientOrgHierarchy: (state, action) => {
      state.orgHierarchy = action.payload;
    },
    setPatientDetails: (state, action) => {
      const { emergencyContact, healthInfo, address, preferences, phone, countryCode, groups, mrn, ssn, role, firstName, lastName, email, canHaveAppointment, canManageAvailability, profilePictureURL} = action.payload;
      return {
        ...state,
        groups: groups,
        emergencyContact: emergencyContact || {},
        healthInfo: healthInfo || {},
        address: address,
        preferences: preferences,
        phone: phone,
        countryCode: countryCode,
        mrn: mrn || "",
        ssn: ssn || "",
        role: role,
        firstName: firstName,
        lastName: lastName,
        email: email,
        fullName:`${firstName} ${lastName}`,
        canManageAvailability: canManageAvailability,
        canHaveAppointment: canHaveAppointment,
        profilePictureURL: profilePictureURL  || null

      }
    },
    setFormField: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetPatientState: () => initialState,
  },
});

export const { updatePatientInfo, resetPatientState, setPatientPrograms, setPatientDetails, setFormField, setPatientOrgHierarchy } = patientSlice.actions;
export const selectPatientInfo = (state) => state.patient.info;

export default patientSlice.reducer;
