import React, { useState } from 'react';
import { ListGroup, Form, DropdownButton, Dropdown, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskTypeBGIcon, UnreadMessageIconIcon } from '../../components/icons/Icons';
import { setReloadTaskList, setSelectAll, setSelectedItem, setSelectedRows, toggleItem } from './TasksSlice';
import taskStyles from './Tasks.module.css';
import DateUtils from '../../components/DateUtils';
import PerinHealthModal from '../../components/PerinHealthModel';
import { closeModal, openModal } from '../../components/PerinHealthModelSlice';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import useAxios from '../../services/AxiosConfig';
import { updatePatientInfo } from '../../slicers/PatientSlice';
import { setMenuItem } from '../patient/Slices/PatientDetailSlice';
import DateTimePicker from './DateTimePicker';
import overDueDate from "../../assets/Status_Badge.png";
import dueDate from "../../assets/Reminder_Gray.png";

const TaskListPanel = () => {
  const navigate = useNavigate();
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const dispatch = useDispatch();
  const {
    dataList,
    searchText,
    startDate,
    endDate,
    startDateTimestamp,
    endDateTimestamp,
    selectedTaskTypes,
    selectedRows,
    selectedItem,
    selectAll, filter
  } = useSelector((state) => state.taskListPanel);
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const axios = useAxios(axiosConfig.envURL);

  const perinHealthModal = useSelector((state) => state.perinHealthModal);

  const filteredTasks = dataList?.filter(task => {
    const matchesSearch = (task?.description?.toLowerCase()?.includes(searchText?.toLowerCase() || '') ||
      task?.type?.toLowerCase().includes(searchText?.toLowerCase() || ''));
    const taskDate = new Date(task?.createdDate);
    const matchesDateRange = (
      (!startDate || taskDate >= new Date(startDateTimestamp)) &&
      (!endDate || taskDate <= new Date(endDateTimestamp))
    );

    const matchesTaskType = selectedTaskTypes?.length === 0 ||
      selectedTaskTypes?.some(type => type.label === task.type);

    const matchStatus = task?.status === filter?.value;

    return matchesSearch && matchesDateRange && matchesTaskType && matchStatus;
  });


  const handleSelectAll = () => {
    dispatch(setSelectAll(!selectAll));
  };

  const handleItemChange = (staffTaskId) => {
    dispatch(toggleItem(staffTaskId));
  };

  const handleItemClick = (event, task) => {
    dispatch(setSelectedItem(task));
    if (event.target.textContent === 'Pending') {

    } else if (event.target.textContent === 'Move back to Pending List') {
      dispatch(
        openModal({
          header: 'Move Task to pending list?',
          message: "Task will no longer be considered complete and will move back to the Pending Tasks list.",
          buttons: [
            { label: 'No', variant: 'secondary', onClickKey: 'NO' },
            { label: 'Yes', variant: 'primary', onClickKey: 'YES_MOVE_TO_PENDING' },
          ],
        })
      );
    } else if (event.target.textContent.includes("Remind")) {
      setShowDateTimePicker(true);
    }
  };

  const onClickTaskAction = (event, task) => {
    dispatch(setSelectedItem(task));
    if (event.target.textContent === 'Patient Vitals' || event.target.textContent === 'Note Review') {
      dispatch(
        openModal({
          header: 'Open Patient vital page?',
          message: "Do you want to view the patient vital page? Clicking 'Yes' will allow you to see the vitals that exceeded the threshold?",
          buttons: [
            { label: 'No', variant: 'secondary', onClickKey: 'NO' },
            { label: 'Yes', variant: 'primary', onClickKey: 'YES_PATIENT_VITALS' },
          ],
        })
      );
    } else if (event.target.textContent === 'Patient Submitted Report' || event.target.textContent === 'Patient responded to Report') {
      dispatch(
        openModal({
          header: 'Open Patient vital page?',
          message: "Do you want to view the new report? Clicking 'Yes' will take you to the patient-submitted reports page.",
          buttons: [
            { label: 'No', variant: 'secondary', onClickKey: 'NO' },
            { label: 'Yes', variant: 'primary', onClickKey: 'YES_PATIENT_REPORT' },
          ],
        })
      );
    } else if (event.currentTarget.label === 'Open Patient Report?') {
      dispatch(
        openModal({
          header: 'Open Patient vital page?',
          message: "Do you want to view the new report? Clicking 'Yes' will take you to the patient-submitted reports page.",
          buttons: [
            { label: 'No', variant: 'secondary', onClickKey: 'NO' },
            { label: 'Yes', variant: 'primary', onClickKey: 'YES_PATIENT_SUBMITTED_REPORT' },
          ],
        })
      );
    } else if (event.target.textContent === 'Patient Discharge' || event.target.textContent === 'Frequency Changed') {
      dispatch(
        openModal({
          header: 'Open Patient vital page?',
          message: "Do you want to view the patient vital page? Clicking 'Yes' will allow you to see the vitals that exceeded the threshold?",
          buttons: [
            { label: 'No', variant: 'secondary', onClickKey: 'NO' },
            { label: 'Yes', variant: 'primary', onClickKey: 'YES_CARE_PROGRAM' },
          ],
        })
      );
    }
  };

  const handleDialogButtonClick = (onClickKey) => {
    if (onClickKey === 'YES_PATIENT_VITALS') {
      dispatch(updatePatientInfo(selectedItem?.details?.patientDetails?.patientId));
      navigate('/patient-detail');
      onTaskStatusUpdateHandler("NewStatus");
      dispatch(closeModal());
    } else if (onClickKey === 'YES_PATIENT_REPORT') {
      dispatch(updatePatientInfo(selectedItem?.details?.patientDetails?.patientId));
      navigate('/patient-detail/Reports');
      onTaskStatusUpdateHandler("NewStatus");
      dispatch(closeModal());
    } else if (onClickKey === 'YES_CARE_PROGRAM') {
      dispatch(updatePatientInfo(selectedItem?.details?.patientDetails?.patientId));
      navigate('/patient-detail/CareProgram');
      onTaskStatusUpdateHandler("NewStatus");
      dispatch(closeModal());
    } else if (onClickKey === 'YES_MOVE_TO_PENDING') {
      onTaskStatusUpdateHandler("Pending");
      dispatch(closeModal());
    } else if (onClickKey === 'YES_MARK_COMPLETE') {
      onTaskStatusUpdateHandler("Completed");
      dispatch(closeModal());
    } else if (onClickKey === 'YES_ARCHIVE') {
      onTaskStatusUpdateHandler("Archived");
      dispatch(closeModal());
    } else {
      dispatch(closeModal());
    }
  };

  const onTaskStatusUpdateHandler = (taskAction) => {
    const urlMap = {
      Completed: '/staffTasks/complete/all',
      Archived: '/staffTasks/update',
      Pending: '/staffTasks/update',
      NewStatus: '/staffTasks/update',
    };

    // Select the correct URL based on the action
    const taskUpdateURL = urlMap[taskAction];

    const taskUpdatePromises = (selectedRows.length > 0 ? selectedRows : [selectedItem]).map((task) => {
      // For 'Completed', the taskId should be sent as a parameter
      if (taskAction === 'Completed') {
        const url = `${taskUpdateURL}/${task.staffTaskId}`;
        return axios.put(url); // Send only the URL without payload
      } else {
        // For 'Archive' and 'Pending', send the payload with the request
        const payload = {
          accountId: task.accountId,
          staffTaskId: task.staffTaskId,
          status: taskAction === 'NewStatus' ? task.status : taskAction,
        };
        return axios.put(taskUpdateURL, payload);
      }
    });


    Promise.all(taskUpdatePromises)
      .then((responses) => {
        dispatch(setSelectedRows([]));
        dispatch(setSelectAll(false));
        dispatch(closeModal());
        dispatch(setReloadTaskList(true));
        dispatch(
          showAlert({
            header: "Success",
            message: "Successfully Updated Task Status",
            type: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(closeModal());
        const errorMessage = error.response?.data?.message || 'An error occurred';
        dispatch(
          showAlert({
            header: "Error",
            message: `Could not Update Task Status: ${errorMessage}`,
            type: 'danger',
          })
        );
      });
  };

  const handleCloseDateTimePicker = () => {
    setShowDateTimePicker(false);
  };


  return (
    <div style={{ marginTop: '16px' }}>
      <Form.Check className={taskStyles.selectAllFormCheck}
        type="checkbox" style={{ marginLeft: '10px', marginBottom: '10px' }}
        label="Select All"
        checked={selectAll}
        onChange={handleSelectAll}
      />
      <div className={taskStyles.TaskListScroll}>
        <ListGroup>
          {filteredTasks.map((task, index) => (
            <ListGroup.Item
              key={index}
              style={{
                borderBottom: '1px solid #ddd',
                borderTop: '1px solid #ddd',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '10px',
                backgroundColor: task.checked ? '#f8f9fa' : '#ffffff'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Check className={taskStyles.selectAllFormCheck}
                    type="checkbox"
                    checked={task.checked}
                    onChange={() => handleItemChange(task.staffTaskId)}
                  />
                  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '5px' }}>
                    <div style={{ position: 'relative', width: '37px', height: '37px', borderRadius: '50%', overflow: 'hidden' }}>
                      {!task.taskTypeIcon && (
                        <TaskTypeBGIcon style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                      )}
                      {task.taskTypeIcon && (
                        <img
                          src={task.taskTypeIcon}
                          alt="Task Icon"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)',
                            objectFit: 'contain',
                          }}
                        />
                      )}
                    </div>
                    {task.newStatus && (
                      <div
                        className={taskStyles.unreadMessageIcon}
                        style={{
                          position: 'absolute',
                          marginTop: '20px',
                          top: '8px',
                          left: '80px'
                        }}
                      >
                        <UnreadMessageIconIcon style={{ width: '8px', height: '8px' }} />
                      </div>
                    )}
                  </span>

                  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginRight: '10px' }}>
                    <Button
                      variant="link"
                      className={taskStyles.buttonLink}
                      onClick={(event) => onClickTaskAction(event, task)}
                    >
                      {task.type}
                    </Button>
                  </span>
                  |
                  <span className={taskStyles.dateField}>
                    {DateUtils.formatDateToTaskMMMDDYYYY(task.createdDate)}
                  </span>

                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {task.isDueDatePassed ? (
                    <OverlayTrigger
                      placement='left'
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          <strong>{DateUtils.formatDateToMMDDYYYYHHMM(task.dueDate)}</strong>.
                        </Tooltip>
                      }
                    >
                      <img
                        src={overDueDate}
                        alt="Overdue Task Icon"
                      />
                    </OverlayTrigger>
                  ) : task.isDueSoon ? (
                    <OverlayTrigger
                      placement='left'
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          <strong>{DateUtils.formatDateToMMDDYYYYHHMM(task.dueDate)}</strong>.
                        </Tooltip>
                      }
                    >
                      <img
                        src={dueDate}
                        alt="Due Soon Task Icon"
                      />
                    </OverlayTrigger>
                  ) : null}

                  <DropdownButton
                    variant="link"
                    className="help-icon"
                    title={<span><FontAwesomeIcon icon="fa-solid fa-ellipsis" style={{ color: 'grey', fontSize: '24px' }} /></span>}
                  >
                    {filter?.value === 'Pending' && (
                      <Dropdown.Item
                        onClick={(event) => handleItemClick(event, task)}
                        style={{ fontWeight: 'bold', width: '60px', fontSize: '20px' }}
                      >
                        Reminder <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                      </Dropdown.Item>
                    )}
                    {filter?.value === 'Completed' && (
                      <Dropdown.Item
                        onClick={(event) => handleItemClick(event, task)}
                        style={{ fontWeight: 'bold' }}
                      >
                        Move back to Pending List
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </div>

              </div>
              <div className={taskStyles.description} dangerouslySetInnerHTML={{ __html: task?.description }} />

            </ListGroup.Item>
          ))
          }
        </ListGroup >
        {
          perinHealthModal.show && (
            <PerinHealthModal
              show={perinHealthModal.show}
              header={perinHealthModal.header}
              message={perinHealthModal.message}
              buttons={perinHealthModal.buttons}
              handleButtonClick={handleDialogButtonClick}
            />
          )
        }
        < DateTimePicker show={showDateTimePicker} onClose={handleCloseDateTimePicker} />

      </div >
    </div >
  );
};

export default TaskListPanel;
