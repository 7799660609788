import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Form, Image, InputGroup, Row, FloatingLabel } from 'react-bootstrap';
import styles from '../ContentManagement.module.css';
import { CheckIcon, LeftArrowIcon, UnionIcon } from '../../../components/icons/Icons';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../services/AxiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../../components/PerinHealthGridSlice';
import { setReloadGrid } from '../ContentManagementSlicer';
import { setFormValue, setOrgGroups } from './AddEditContentSlice';
import FileUpload from './FileUpload';
import managementStyles from '../../ManagementPages.module.css';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const AddEditContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    const [file, setFile] = useState({});
    const [selectedContentType, setSelectedContentType] = useState('');
    const [isLinkInputVisible, setIsLinkInputVisible] = useState(false);
    const contentManagementState = useSelector((state) => state.addEditContent);
    const organizationsHierarchy = useSelector((state) => state.userInfo.organizationsHierarchy);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    const orgGroups = contentManagementState.orgGroups;
    const selectedItem = perinHealthGrid.selectedItem;
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);

    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');

    const isEditMode = !!selectedItem && Object.keys(selectedItem).length > 0;

    const selectedOrgIdRef = useRef(null);

    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem).length !== 0) {
            const fileData = selectedItem.file || {};

            setFile(fileData);

            handleOrganizationChange({ target: { value: selectedItem.organizationId } });
            setSelectedOrgId(selectedItem.organizationId);
            setSelectedGroupId(selectedItem.groupId);
        } else {
            if (organizationsHierarchy.length === 1) {
                selectedOrgIdRef.current = organizationsHierarchy[0].organizationId;
                setSelectedOrgId(selectedOrgIdRef.current);
                handleOrganizationChange({ target: { value: selectedOrgIdRef.current } });
            }
        }
    }, [selectedItem, dispatch, organizationsHierarchy]);

    const onBackHandler = () => {
        dispatch(setSelectedItem({}));
        dispatch(setReloadGrid(true));
        navigate('/content-management');
    };

    const handleFileDrop = (acceptedFiles) => {
        const newFile = acceptedFiles.length > 0 ? acceptedFiles[0] : {};
        if (newFile) {
            newFile.url = URL.createObjectURL(newFile);
            setFile(newFile);
        }
    };

    const onInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'groupId') {
            const updatedSelectedItem = { ...selectedItem };

            organizationsHierarchy.forEach((organization) => {
                organization.groups.forEach((group) => {
                    updatedSelectedItem.groupId = group.groupId;

                    if (group.subgroups) {
                        group.subgroups.forEach((subgroup) => {
                            updatedSelectedItem.subGroupId = subgroup.subGroupId;

                            if (subgroup.teams) {
                                subgroup.teams.forEach((team) => {
                                    updatedSelectedItem.teamId = team.teamId;
                                });
                            }
                        });
                    }
                });
            });

            dispatch(setFormValue({
                name: 'groups',
                value: updatedSelectedItem
                    ? [
                        {
                            groupId: updatedSelectedItem.groupId,
                            subGroupId: updatedSelectedItem.subGroupId,
                            teamId: updatedSelectedItem.teamId,
                        },
                    ]
                    : [],
            }));
        } else if (name === 'type') {
            setSelectedContentType(value);
            if (!isEditMode || !file.ContentType || (isEditMode && value !== mapMimeTypeToKey(file.ContentType))) {
                setFile({});
            }
        } else {
            dispatch(setFormValue({ name, value }));
        }
    };

    const onAddLinkHandler = () => {
        setIsLinkInputVisible(true);
    };

    const renderFilePreview = (file) => {
        if (!file || (!file.ContentType && !file.type)) {
            return null;
        }

        const fileUrl = file.url || file.SignedUrl;
        const contentType = file.ContentType || file.type
        const fileTypeCategory = contentType.split('/')[0];

        switch (fileTypeCategory) {
            case 'image':
                return <Image src={fileUrl} alt="Preview" fluid />;
            case 'video':
                return (
                    <video className={styles.filePreview} controls>
                        <source src={fileUrl} type={contentType} />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'audio':
                return (
                    <audio controls>
                        <source src={fileUrl} type={contentType} />
                        Your browser does not support the audio tag.
                    </audio>
                );
            case 'application':
                return contentType === 'application/pdf' ? (
                    <div className={styles.filePreview}>
                        <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />
                    </div>
                ) : (
                    <div className={styles.filePreview}>
                        <p>No preview available for this application type.</p>
                    </div>
                );
            default:
                return (
                    <div className={styles.filePreview}>
                        <p>No preview available for this file type.</p>
                    </div>
                );
        }
    };

    const mapMimeTypeToKey = (mimeType) => {
        if (mimeType.startsWith('image/')) return 'image';
        if (mimeType.startsWith('audio/')) return 'audio';
        if (mimeType === 'video/mp4') return 'mp4';
        if (mimeType === 'application/pdf') return 'pdf';
        return 'link';
    };
    

    const getUploadComponent = () => {
        if (selectedContentType && selectedContentType !== 'link') {
            return (
                <Form.Group className="mb-3">
                    <Form.Label>Upload File</Form.Label>
                    <FileUpload onDrop={handleFileDrop} />
                </Form.Group>
            );
        } else if (selectedContentType === 'link') {
            return (
                <Form.Group className="mb-3">
                    <Button className={`${styles.AddLinkButton} mb-2`} onClick={onAddLinkHandler}>
                        <UnionIcon /> Add Link
                    </Button>
                    {isLinkInputVisible && (
                        <InputGroup className="mb-3">
                            <Form.Control
                                className={`${styles.LinkComponent}`}
                                value={contentManagementState.link}
                                onChange={onInputChange}
                                placeholder="Link"
                                aria-label="Link"
                            />
                            <Button variant="outline-secondary" className={styles.CheckButton}>
                                <CheckIcon />
                            </Button>
                        </InputGroup>
                    )}
                </Form.Group>
            );
        }
        return null;
    };


    const getSelectedOrganization = (selectedOrgId) => {
        return organizationsHierarchy.find((org) => org.organizationId === Number(selectedOrgId));
    };

    const getSelectedGroup = (selectedOrgId, selectedGroupId) => {
        const organization = getSelectedOrganization(selectedOrgId);
        if (organization && organization.groups.length > 0) {
            return organization.groups.find((group) => group.groupId === Number(selectedGroupId));
        }
        return null;
    };

    const handleOrganizationChange = (e) => {
        const selectedOrgId = e.target.value;
        setSelectedOrgId(selectedOrgId); // Update the selectedOrgId state
        selectedOrgIdRef.current = selectedOrgId;
        const organization = getSelectedOrganization(selectedOrgId);

        dispatch(setFormValue({ name: 'organizationId', value: organization?.organizationId }));
        dispatch(setFormValue({ name: 'organizationName', value: organization?.organizationName }));

        if (organization && organization.groups.length === 1) {
            const selectedGroup = organization.groups[0];
            setSelectedGroupId(selectedGroup.groupId);
            dispatch(setOrgGroups(organization.groups));
            dispatch(setFormValue({ name: 'groupId', value: selectedGroup.groupId }));
            handleGroupChange({ target: { value: selectedGroup.groupId } });
        } else {
            if (organization && organization.groups.length > 0) {
                const allGroups = [{ groupId: 0, groupName: 'All' }, ...organization.groups];
                dispatch(setOrgGroups(allGroups));
            } else {
                dispatch(setOrgGroups([{ groupId: 0, groupName: 'Select Group' }]));
                setSelectedGroupId('');
                dispatch(setFormValue({ name: 'groupId', value: '' }));
            }
        }
    };

    const handleGroupChange = (e) => {
        const selectedGroupId = e.target.value;
        setSelectedGroupId(selectedGroupId); // Update the selectedGroupId state
        const selectedOrgId = selectedOrgIdRef.current;
        const selectedGroup = getSelectedGroup(selectedOrgId, selectedGroupId);

        if (selectedGroup) {
            const { groupId, subgroups } = selectedGroup;
            const { subGroupId, teams } = subgroups ? subgroups[0] || {} : {};
            const { teamId } = teams ? teams[0] || {} : {};

            dispatch(setFormValue({ name: 'groupId', value: groupId }));
            dispatch(setFormValue({ name: 'subGroupId', value: subGroupId }));
            dispatch(setFormValue({ name: 'teamId', value: teamId }));
        } else {
            dispatch(setFormValue({ name: 'groupId', value: '' }));
            dispatch(setFormValue({ name: 'subGroupId', value: '' }));
            dispatch(setFormValue({ name: 'teamId', value: '' }));
        }
    };

    const onAddEditHandler = () => {
        const bodyForm = new FormData();
        const cleanedData = formatDataForSaving();
        
        const groupsArray = [{
            groupId: cleanedData.groupId,
            subGroupId: cleanedData.subGroupId,
            teamId: cleanedData.teamId
        }];
        if (Object.keys(file).length > 0) {
            bodyForm.append('file', file);
        } else {
            bodyForm.append('files', new File([], null));
        }
        //bodyForm.append('file', files[0]);
        if (isEditMode) {
            bodyForm.append('id', cleanedData.id);
        }
        bodyForm.append('name', cleanedData.name);
        bodyForm.append('description', cleanedData.description);
        bodyForm.append('location', "test");
        bodyForm.append('groups', JSON.stringify(groupsArray));

        const endpoint = isEditMode ? `content/${cleanedData.id}` : 'content';

        httpService({
            method: isEditMode ? 'put' : 'post',
            url: endpoint,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            data: bodyForm,
        })
            .then((response) => {
                console.log(`Content ${isEditMode ? 'updated' : 'added'} successfully:`, response.data);
                navigate('/content-management');
                dispatch(setReloadGrid(true));
                setFile({});
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Content ${isEditMode ? 'updated' : 'added'} successfully`,
                        type: 'success'
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Error ${isEditMode ? 'updating' : 'adding'} content`,
                        type: 'danger',
                        dismissable: true
                    })
                );
                console.error(`Error ${isEditMode ? 'updating' : 'adding'} content:`, error);
            });
    };

    const formatDataForSaving = () => {
        console.log('formatDataForSaving' + contentManagementState);
        return removeUndefined(contentManagementState);
    };

    const removeUndefined = (obj) => {
        const excludedAttributes = ['files', 'isUpdateMode', 'orgGroups'];
        if (!isEditMode) {
            excludedAttributes.push('id');
        }
        console.log('removeUndefined' + obj);

        const cleanedData = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                if (!excludedAttributes.includes(key) && value !== undefined) {
                    return [key, value];
                }
                return null;
            }).filter(entry => entry !== null)
        );

        return cleanedData;
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Content Management</span>
            </div>
            <div className={styles.MainCard}>
                <Container className={managementStyles.Container}>
                    <h3 className={managementStyles.ContentTitle}> {isEditMode ? 'Update Content' : 'Create Content'}</h3>
                    <Row className={managementStyles.ScrollContainer}>
                        <Col md={5}>
                            <Form className={styles.FormContainer}>
                                {isAdmin && (<Form.Group className="mb-3">
                                    <FloatingLabel controlId="floatingSelect" label="Organization">
                                    <Form.Select
                                        value={selectedOrgId || ""}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={handleOrganizationChange}
                                        style={{width:"300px"}}
                                        disabled={isEditMode}
                                    >
                                        <option value="">Select</option>
                                        {organizationsHierarchy.map((organization) => (
                                            <option key={organization.organizationId} value={organization.organizationId}>
                                                {organization.organizationName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>)}
                                <Form.Group className="mb-3">
                                    <FloatingLabel controlId="floatingSelect" label="Group">
                                    <Form.Select
                                        value={contentManagementState.groupId || ""}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={handleGroupChange}
                                        style={{width:"300px"}}
                                    >
                                        {orgGroups?.map((group) => (
                                            <option key={group.groupId} value={group.groupId}>
                                                {group.groupName}
                                            </option>
                                        ))}
                                     </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel 
                                        controlId="floatingInput"
                                        label="Name"
                                        className="mb-3"
                                    >
                                    <Form.Control
                                        name="name"
                                        placeholder="name"
                                        style={{width:"300px"}}
                                        value={contentManagementState.name}
                                        onChange={onInputChange}
                                        className={`${managementStyles.BorderedComponent}`}
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FloatingLabel 
                                        controlId="floatingTextarea2"
                                        label="Description"
                                        className="mb-3"
                                    >
                                    <Form.Control
                                        name="description"
                                        value={contentManagementState.description}
                                        onChange={onInputChange}
                                        as="textarea"
                                        style={{width:"300px"}}
                                        placeholder='Description'
                                        rows={2}
                                        className={`${managementStyles.BorderedComponentTextArea}`}
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FloatingLabel controlId="floatingSelect" label="Content Type">
                                    <Form.Select
                                        name="type"
                                        style={{width:"300px"}}
                                        value={isEditMode && file.ContentType ? mapMimeTypeToKey(file.ContentType) : selectedContentType}
                                        onChange={onInputChange}
                                        className={`${managementStyles.BorderedComponent}`}
                                    >
                                        <option value="">Select Content Type</option>
                                        <option value="image">Image</option>
                                        <option value="audio">Audio</option>
                                        <option value="mp4">Video</option>
                                        <option value="pdf">PDF</option>
                                        <option value="link">Link</option>
                                        </Form.Select>
                                    </FloatingLabel>
                            </Form.Group>
                                {selectedContentType && getUploadComponent()}
                            </Form>
                        </Col>
                        <Col md={7}>
                            {/* Preview for selected file */}
                            {Object.keys(file).length > 0 && (
                                <div className={styles.previewContainer}>
                                    {renderFilePreview(file)}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <div className={styles.Footer}>
                    <Row>
                        <Col>
                            <Button variant="primary" className={managementStyles.CreateButton} onClick={onAddEditHandler}>
                                {isEditMode ? 'Update' : 'Add'}
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" className={managementStyles.BackButton} onClick={onBackHandler}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default AddEditContent;
