import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { closeAlert } from '../PerinHealthAlertSlice'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Notifications.module.css';
import { CallEndIcon, PhoneCallIcon, BlueProfileIcon, UserProfilePictureIcon } from '../icons/Icons';
import { Button } from 'react-bootstrap';

const CallNotification = ( props) => {

    const { 
        show, 
        fullName,
        onDismiss,
        onAccept
    } = props;

    
    const firstName = fullName?.split(" ")[0] || "";
    const lastName = fullName?.split(" ")[1] || "";

    return (
        <div className={styles.CallNotificationContainer}>
            <Alert 
                show={show} 
                // show={true} 
                onClose={() => onDismiss()} 
                style={{padding:"5px 5px"}}
                className={styles.CallNotificationStyling}
            >
                <Alert.Heading className={styles.NotificationHeading}>
                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                            {firstName && lastName &&
                              <span className={styles.InitialsIcon}>{`${firstName[0]}${lastName[0]}`}</span>
                            }
                            <UserProfilePictureIcon width='88' height='88' />
                        </div>
                        {`${fullName} is calling you`}
                </Alert.Heading>
                <div style={{display:"flex", justifyContent:"space-between", columnGap:"10px", padding:"10px"}}>
                    <Button onClick={onAccept} className={styles.acceptCall}>
                        <PhoneCallIcon />
                        {"Start Call"}
                    </Button>
                    <Button onClick={onDismiss} className={styles.rejectCall}>
                        <CallEndIcon />
                        {"Reject"}
                    </Button>
                </div>
            </Alert>
        </div>
    );
};

export default CallNotification;
