import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, FloatingLabel } from 'react-bootstrap';
import styles from '../QuestionnaireManagement.module.css';
import { LeftArrowIcon } from '../../../components/icons/Icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    setFieldValue,
    setGroups,
} from './AddQuestionnaireSlice';
import { setSelectedItem } from '../../../components/PerinHealthGridSlice';
import managementStyles from '../../ManagementPages.module.css';
import ProgressBar from '../../../components/ProgressBar';

const QuestionnaireSetup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedOrgIdRef = useRef(null);
    const addQuestionnaireState = useSelector((state) => state.addQuestionnaire);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const isUpdateMode = addQuestionnaireState.isUpdateMode;
    const groups = addQuestionnaireState.groups;
    const organizationsHierarchy = useSelector((state) => state.userInfo.organizationsHierarchy);
    const userGroups = useSelector((state) => state.userInfo.groups);
    const selectedItem = useSelector(
        (state) => state.perinHealthGrid.selectedItem || {}
    );
    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem).length !== 0) {
            handleOrganizationChange({ target: { value: selectedItem.organizationId } });
    
            setSelectedOrgId(selectedItem.organizationId);
            setSelectedGroupId(selectedItem.groupId); 
        } else {
            if (organizationsHierarchy.length === 1) {
                selectedOrgIdRef.current = organizationsHierarchy[0].organizationId;
                setSelectedOrgId(selectedOrgIdRef.current);
                handleOrganizationChange({ target: { value: selectedOrgIdRef.current } });
            }
        }
    }, [selectedItem, dispatch, organizationsHierarchy]);

    const getSelectedOrganization = (selectedOrgId) => {
        return organizationsHierarchy.find((org) => org.organizationId === Number(selectedOrgId));
    };

    const getSelectedGroup = (selectedOrgId, selectedGroupId) => {
        const organization = getSelectedOrganization(selectedOrgId);
        if (organization && organization.groups.length > 0) {
            return organization.groups.find((group) => group.groupId === Number(selectedGroupId));
        }
        return null;
    };

    const handleOrganizationChange = (e) => {
        const selectedOrgId = e.target.value;
        setSelectedOrgId(selectedOrgId); 
        selectedOrgIdRef.current = selectedOrgId;
        const organization = getSelectedOrganization(selectedOrgId);
        
        handleControlChange('organizationId', organization?.organizationId);
        handleControlChange('organizationName', organization?.organizationName);
    
        if (organization && organization.groups.length === 1) {
            //dispatch(setGroups([organization.groups[0]]));
            const selectedGroup = organization.groups[0];
            setSelectedGroupId(selectedGroup.groupId);
            dispatch(setGroups(organization.groups));
            handleControlChange('groupId', selectedGroup.groupId);
            handleGroupChange({ target: { value: selectedGroup.groupId } });
        } else {
            if (organization && organization.groups.length > 0) {
                const allGroups = [{ groupId: 0, groupName: 'Select Group' }, ...organization.groups];
                dispatch(setGroups(allGroups));
            } else {
                dispatch(setGroups([{ groupId: 0, groupName: 'No Group' }]));
                setSelectedGroupId('');
                handleControlChange('groupId', '');
            }
        }
    };
    
    const handleGroupChange = (e) => {
        const selectedGroupId = e.target.value;
        setSelectedGroupId(selectedGroupId); 
        const selectedOrgId = selectedOrgIdRef.current;
        const selectedGroup = getSelectedGroup(selectedOrgId, selectedGroupId);
    
        if (selectedGroup) {
            const { groupId, groupName, subgroups } = selectedGroup;
            const { subGroupId, teams } = subgroups ? subgroups[0] || {} : {};
            const { teamId } = teams ? teams[0] || {} : {};
    
            handleControlChange('groupId', groupId);
            handleControlChange('groupName', groupName);
            handleControlChange('subGroupId', subGroupId);
            handleControlChange('teamId', teamId);
        } else {
            handleControlChange('groupId', '');
            handleControlChange('subGroupId', '');
            handleControlChange('teamId', '');
        }
    };


    const handleControlChange = (controlName, value) => {
        dispatch(setFieldValue({ [controlName]: value }));
    };

    const onQuestionaryTypeChange = (e) => {
        const selectedQuestionaryType = e.target.value;
        handleControlChange('type', selectedQuestionaryType);
    };

    const buttonDisabled = addQuestionnaireState.groupId === "" || addQuestionnaireState.name === "" || addQuestionnaireState.type === "";

    const onBackHandler = () => {
        dispatch(setSelectedItem({}));
        navigate('/questionnaire-management');
    };

    const onNextHandler = () => {
        navigate('/add-questionnaire');
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Questionnaire Setup";
        } else if (step === 1) {
            return "Add Questions";
        } else if (step === 2) {
            return "Preview & Publish";
        }
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={onBackHandler}>
                <LeftArrowIcon />
                <span>Back to Questionnaire Management</span>
            </div>
            <div className={managementStyles.MainCard}>
                <Container className={managementStyles.Container}>
                    <h3 className={managementStyles.ContentTitle}>{isUpdateMode ? 'Update' : 'Add'} Questionnaire</h3>
                    <div style={{paddingBottom:"15px"}}>
                        <ProgressBar page={0} getPageName={getPageName} totalSteps={3}/>
                    </div>
                    <Row className={managementStyles.ScrollContainer} style={{height:"70%"}}>
                        <Col md={6}>
                            <Form style={{width:"300px"}}>
                                {isAdmin && (<Form.Group className="mb-3">
                                    <FloatingLabel controlId="floatingSelect" label="Organization">
                                    <Form.Select
                                        value={selectedOrgId}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={handleOrganizationChange}
                                        style={{width:"300px"}}
                                        disabled={isUpdateMode}
                                    >
                                        <option value="">Select</option>
                                        {userGroups.map((organization) => (
                                            <option key={organization.organizationId} value={organization.organizationId}>
                                                {organization.organizationName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>)}
                                <Form.Group className="mb-3">
                                    <FloatingLabel controlId="floatingSelect" label="Group*">
                                    <Form.Select
                                        value={addQuestionnaireState.groupId}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={handleGroupChange}
                                        style={{width:"300px"}}
                                    >
                                        {groups?.map((group) => (
                                            <option key={group.groupId} value={group.groupId}>
                                                {group.groupName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel 
                                        controlId="floatingInput"
                                        label="Questionnaire Name*"
                                        className="mb-3"
                                    >
                                    <Form.Control
                                        value={addQuestionnaireState.name}
                                        placeholder="Questionnaire Name*"
                                        style={{width:"300px"}}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={(e) =>
                                            handleControlChange('name', e.target.value)
                                        }
                                    />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel 
                                        controlId="floatingInput"
                                        label="Type*"
                                        className="mb-3"
                                    >
                                    <Form.Select
                                        value={addQuestionnaireState.type}
                                        className={`${managementStyles.BorderedComponent}`}
                                        onChange={onQuestionaryTypeChange}
                                        style={{width:"300px"}}
                                    >
                                        <option value="">Select Questionnaire Type</option>
                                        <option value="Multiple Choice">Multiple Choice</option>
                                        <option value="Yes or No">Yes or No</option>
                                        <option value="Combination">Combination</option>
                                    </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FloatingLabel 
                                        controlId="floatingInput"
                                        label="Description"
                                        className="mb-3"
                                    >
                                    <Form.Control
                                        value={addQuestionnaireState.description}
                                        as="textarea"
                                        rows={2}
                                        style={{width:"300px"}}
                                        className={managementStyles.BorderedComponentTextArea}
                                        placeholder='Description'
                                        onChange={(e) =>
                                            handleControlChange('description', e.target.value)
                                        }
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <fieldset>
                                    <Form.Group className="mb-3">
                                        <Row className="mb-3 ">
                                            <Col xs={4}>
                                                <Form.Label>Patient Type</Form.Label>
                                            </Col>
                                            <Col xs={8} className={`${styles.RadioComponent}  align-items-center`}>
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        type="radio"
                                                        label="Child (2-11 Years Old)"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios1"
                                                        onChange={() => handleControlChange('patientType', 'Child')}
                                                        checked={addQuestionnaireState.patientType === 'Child'}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        type="radio"
                                                        label="Adult (12+ Years)"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                        onChange={() => handleControlChange('patientType', 'Adult')}
                                                        checked={addQuestionnaireState.patientType === 'Adult'}
                                                    />
                                                </div>
                                                <br></br>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </fieldset>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div className={styles.Footer}>
                    <Row>
                        <Col>
                            <Button variant="primary" disabled={buttonDisabled} className={managementStyles.CreateButton} onClick={onNextHandler}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireSetup;
