import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createAxiosInstance from '../../services/AxiosConfig';
import {
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    ConsoleLogger,
    DefaultDeviceController
} from 'amazon-chime-sdk-js';
import useAxios from '../../services/AxiosConfig';

const JoinMeetingTest = () => {
    const [meetingId, setMeetingId] = useState('');
    const [username, setUsername] = useState('');
    const [videoTiles, setVideoTiles] = useState([]);
    const axiosConfig = useSelector(state => state.axiosConfig);
    const dispatch = useDispatch();
    const meetingSessionRef = useRef(null);
    const meetingIdRef = useRef(null);
    const audioElementRef = useRef(null)

    const httpService = useAxios(axiosConfig.envURL, dispatch);

    const leaveMeeting = useCallback(() => {
        if (meetingSessionRef.current) {
          const audioVideo = meetingSessionRef.current.audioVideo;
          audioVideo.stopVideoInput();
          audioVideo.stopAudioInput();
          audioVideo.stopLocalVideoTile();
          audioVideo.stop();
          console.log("Meeting and video stopped");
        }
      }, []);

    const onJoinMeeting = async () => {
        try {
            const meetingResponse = await httpService.get(`/meeting/${meetingId}`);
            const attendeeResponse = await httpService.request({
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                url: `/meeting/attendee`,
                data: JSON.stringify({ accountId: username, meetingId: meetingId }),
            });

            if (meetingResponse.data.data && attendeeResponse.data.data) {
                meetingIdRef.current = meetingResponse.data.data.MeetingId
            }

            const logger = new ConsoleLogger('JoinLogger', LogLevel.INFO);
            const deviceController = new DefaultDeviceController(logger);
            const configuration = new MeetingSessionConfiguration(meetingResponse.data.data, attendeeResponse.data.data);
            const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

            meetingSessionRef.current = meetingSession;

            meetingSession.audioVideo.addObserver({
                videoTileDidUpdate: tileState => {
                    if (!tileState.boundAttendeeId) return;

                    setVideoTiles(prevTiles => {
                        const tileExists = prevTiles.some(tile => tile.tileId === tileState.tileId);
                        if (tileExists) return prevTiles;

                        const newRef = React.createRef();
                        return [...prevTiles, { tileId: tileState.tileId, ref: newRef }];
                    });
                },
                videoTileWasRemoved: tileId => {
                    setVideoTiles(prevTiles => prevTiles.filter(tile => tile.tileId !== tileId));
                    leaveMeeting();
                }
            });

            const videoInputDevices = await deviceController.listVideoInputDevices();
            const audioInputDevices = await deviceController.listAudioInputDevices();
            const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();


            if (videoInputDevices.length && audioInputDevices.length) {
                await deviceController.startVideoInput(videoInputDevices[0].deviceId);
                await deviceController.startAudioInput(audioInputDevices[0].deviceId);

                if (audioOutputDevices.length) {
                    await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
                    meetingSession.audioVideo.bindAudioElement(audioElementRef.current);
                }

                meetingSession.audioVideo.start();
                meetingSession.audioVideo.startLocalVideoTile();
            }
        } catch (error) {
            console.error('Failed to join the meeting:', error);
        }
    };

    const handleJoinClick = async () => {
        if (!meetingId || !username) {
            alert('Please provide both a meeting ID and a username.');
            return;
        }
        onJoinMeeting();
    };

    useEffect(() => {
        return () => {
            if (meetingSessionRef.current) {
                leaveMeeting();
            }
        };
    }, [leaveMeeting]);

    useEffect(() => {
        videoTiles.forEach(tile => {
            if (tile.ref.current) {
                meetingSessionRef.current.audioVideo.bindVideoElement(tile.tileId, tile.ref.current);
            }
        });
    }, [videoTiles]);

    return (
        <div>
            <h1>Join a Meeting</h1>
            <input
                type="text"
                placeholder="Meeting ID"
                value={meetingId}
                onChange={(e) => setMeetingId(e.target.value)}
            />
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <button onClick={handleJoinClick}>Join Meeting</button>
            <button onClick={leaveMeeting}>Stop Meeting</button>
            <div>
                {videoTiles.map(tile => (
                    <video key={tile.tileId} ref={tile.ref} autoPlay playsInline style={{ width: '480px', height: '360px' }} />
                ))}
                <audio ref={audioElementRef} style={{ display: 'none' }}></audio>
            </div>
        </div>
    );
};

export default JoinMeetingTest;
