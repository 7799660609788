import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRows, setSelectedItem, setSelectAll, setDataList } from '../../components/PerinHealthGridSlice';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import FilterManagementView from '../../components/FilterManagementView'; 
import { closeModal } from '../../components/PerinHealthModelSlice';
import managementStyles from '../ManagementPages.module.css';
import { setRoleInfo, setUpdatingState, resetRegistrationState } from '../../components/AccountManagement/RegistrationSlice';
import useAxios from '../../services/AxiosConfig';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const AccountManagement = () => {
    const userId = useSelector((state) => state.userInfo.id);
    const navigate = useNavigate();
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    let selectedRows = perinHealthGrid.selectedRows;
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);

    const headers = [
        { key: 'id', label: "ID", sortable:true },
        { key: 'fullName', label: "Name", sortable:true},
        { key: 'email', label: "Email", sortable:true},
        { key: 'roleName', label: "Role", sortable:true},
        { key: 'groups', label: 'Group', sortable:true},
        { key: 'subGroups', label: "Location", sortable:true},
        { key: 'teams', label: "Teams", sortable:true}
    ];

    useEffect(() => {
        dispatch(setSelectedItem({}));
        dispatch(resetRegistrationState());
        getUsers();
    }, []);

    const getUsers = () => {
        axios.get(`list/users/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const formatted = formatUsers(responseData.data.data);
                    dispatch(setDataList(formatted));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        axios.get(`role/list/${userId}`)
        .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
                setRoles(responseData.data.data);
            } else {
                console.error('Invalid data format:');
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const onRowClickHandler = (rowItem) => {
        const id = rowItem["id"];
        axios.get('user/'+ id)
            .then((responseData) => {
                if (responseData.data.data) {
                    dispatch(setUpdatingState(responseData.data.data));
                    navigate(`/create-account`);
                } else {
                    console.error('error');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onAddHandler = (selectedRole,roleId, category) => {
        if ( selectedRole ) {
            dispatch(setRoleInfo({"id":roleId, "name":selectedRole, "category":category}));
            navigate(`/create-account`);
        } else {
            return;
        }
    };

    const formatUsers = (data) => {
        const userIdSet = new Set();
        let newFormattedUserList = [];
        if (data.length >= 1) {
            newFormattedUserList = data.reduce((acc, user) => {
                if (!userIdSet.has(user.id)) { 
                    userIdSet.add(user.id);
                    let groups = [];
                    let subGroups = [];
                    let teams = [];
                    let groupIds = [];
                    let subGroupIds = [];
                    let teamIds = [];
                    let organizationIds = [];
                    if (user.groups) {
                        user.groups.forEach(group => {
                            if (group.groupName && !groups.includes(group.groupName)){
                                groups.push(group.groupName);
                                groupIds.push(group.groupId);
                            } 
                            if (group.subGroupName && !subGroups.includes(group.subGroupName)) {
                                subGroups.push(group.subGroupName);
                                subGroupIds.push(group.subGroupId);
                            } 
                            if (group.teamName && !teams.includes(group.teamName)) {
                                teams.push(group.teamName);
                                teamIds.push(group.teamId);
                            }
                            if (group.organizationId){
                                organizationIds.push(group.organizationId);
                            }
                        });
                    }
                    acc.push({
                        ...user,
                        groups: groups, 
                        subGroups: subGroups, 
                        teams: teams,
                        organizationIds: organizationIds,
                        groupIds: groupIds,
                        subGroupIds: subGroupIds,
                        teamIds: teamIds,
                        fullName: user.lastName + ", " + user.firstName,
                        roleName: user.role.name,
                        profilePictureURL: user.profilePictureURL
                    });
    
                }
                return acc;
            }, []);
        } 
        return newFormattedUserList;
    };

    const onDeleteHandler = () => {
        const deletePromises = selectedRows.map((user) => {
            const id = user.id;
            return axios.delete(`user/${id}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getUsers();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully deleted account`,
                        type: 'success',
                    })
                );
            })
            .catch((errors) => {
                dispatch(closeModal());
                const errorMessage = errors.response.data.message;
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Could not delete accounts: ${errorMessage}`,
                        type: 'danger',
                    })
                );
                console.error('Error deleting rows:', errors);
            });
    };

    return (
            <div className={managementStyles.MainContent}>
               {/*  <div className={managementStyles.Title}>
                    Accounts
                </div> */}
                <div className={managementStyles.MiddleContent}>
                    <FilterManagementView
                        isOrganization={true}
                        isGroup={true}
                        isSubGroup={true}
                        isTeam={true}
                        addButtonLabel='Create Account'
                        onAddHandler={onAddHandler}
                        onDeleteHandler={onDeleteHandler}
                        dropdownOptions={roles} 
                        modelHeader='Delete Account?' 
                    />
                    <PerinHealthGrid
                        dataList={perinHealthGrid.dataList}
                        headers={headers}
                        showCheckbox={true}
                        showEditIcon={true}
                        onRowClick={onRowClickHandler}
                        onDeleteRow={onDeleteHandler}
                        accounts={true}
                        showHeaderFilter={true}
                        showProfilePicture="fullName"
                        gridHeight='100%'
                        useChips={true}
                    />
                </div>
            </div>
    )
}

export default AccountManagement;
