import React from 'react';
import styles from './CardDetails.module.css';


const CareProgramDetails = ({ data, allModalities }) => {

    console.log("program data", data);
    const dischargeDate = data.enrollment.dischargeDate;

    const calculateTimeline = () => {
        const endDate = data.enrollment.endDate;
        const today = new Date();
        const end = new Date(endDate);
        const difference = end.getTime() - today.getTime();
        const days = Math.round(difference / (1000 * 3600 * 24));
        if (days < 0) {
            return 0;
        }
        return days;
    };

    const countdown = calculateTimeline();

    const initializeRules = () => {
        let notifications = [];
        let modalityFrequencies = [];
        data.devices.forEach((device) => {
            if (device.hasOwnProperty("rules")) {
                device.rules.forEach((rule) => {
                    let tempRule = {...rule};
                    if (rule.ruleType === "enrollment" && rule.actionType === "sendNotification") {
                        notifications.push(tempRule);
                    } else if (rule.ruleType === "enrollment" && rule.actionType === "increaseFrequency") {
                        modalityFrequencies.push(tempRule);
                    }
                })
            }
        });
        return {notifications, modalityFrequencies};
    };

    const rules = initializeRules();
    

    return (
        <div className={styles.DetailsContainer}>
            <div className={styles.Detail}>
                <span className={styles.DetailLabel}>Duration:</span>
                {data.duration}
            </div>
            <div className={styles.Detail}>
                <span className={styles.DetailLabel}>Devices:</span>
                {data.devices.length >= 1 ?
                    (() => {
                        const uniqueIds = new Set();
                        return data.devices.map(device => {
                            if (!uniqueIds.has(device.deviceId)) {
                                uniqueIds.add(device.deviceId);
                                return device.deviceName ?? device.deviceId;
                            } 
                            return null;
                        }).filter(Boolean).join(', ');
                    })() :
                    "None"
                }
            </div>
            <div className={styles.Detail}>
                <span className={styles.DetailLabel}>Rules:</span>
                {rules.notifications?.length >= 1 ||  rules.modalityFrequencies.length >= 1 ?
                    <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                        {rules.notifications.length > 0 &&
                            rules.notifications.map((not) => {
                                const modalityName = allModalities?.find((mod)=>mod.id === not.modalityId)?.name;
                                return (
                                    <span key={not.ruleId}>
                                    {` - Notify me if ${modalityName} is outside the threshold ${not.thresholdCount} times in ${not.timeFrequency} ${not.timeInterval}`}
                                    </span>
                                )
                            })
                        }
                        {rules.modalityFrequencies?.length > 0 &&
                            rules.modalityFrequencies.map((mod) => {
                                const modalityName = allModalities?.find((m)=>m.id === mod.modalityId)?.name;
                                return (
                                    <span key={mod.ruleId}>
                                    {` - Notify me if ${modalityName} is ${mod.thresholdType} the threshold ${mod.thresholdCount} times in ${mod.timeFrequency} ${mod.timeInterval} increase frequency for ${mod.frequencyMultiplier}x for ${mod.frequencyDurationValue} hour`}
                                    </span>
                                )
                            })
                        }
                    </div>
                :
                    "None"
                }
            </div>
            <div className={styles.Detail}>
                <span className={styles.DetailLabel}>Questionnaire:</span>
                {data.questionnaires.length >= 1 ?
                    (() => {
                        const uniqueIds = new Set();
                        return data.questionnaires.map(q => {
                            if (!uniqueIds.has(q.questionnaireId)) {
                                uniqueIds.add(q.questionnaireId);
                                return q.questionnaireName ?? q.questionnaireId;
                            } return null;
                        }).filter(Boolean).join(', ');
                    })()
                :
                    "None"
                }
            </div>
            <div className={styles.Detail}>
                <span className={styles.DetailLabel}>Content:</span>
                {data.contents.length >= 1 ?
                    (() => {
                        const uniqueIds = new Set();
                        return data.contents.map(content => {
                            if (!uniqueIds.has(content.contentId)) {
                                uniqueIds.add(content.contentId);
                                return content.contentName ?? content.contentId
                            } return null;
                        }).filter(Boolean).join(', ');
                    })()
                :
                    "None"
                }
            </div>
            <div className={styles.Detail}>
                {dischargeDate ?
                    <>
                        <span className={styles.DetailLabel}>Discharge Date:</span>
                        {dischargeDate}
                    </>
                :
                    <>
                        <span className={styles.DetailLabel}>Countdown Timeline:</span>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className={styles.ProgramCountdown} hidden={countdown > data.duration}>
                                <div className={styles.DaysLeft} style={{width:`${(1-(countdown/data.duration))*100}%`, borderRadius: `${countdown === 0 ? "8px" : "8px 0 0 8px"}`}}></div>
                            </div>
                            {countdown > data.duration ? "-" : `${countdown} days left`}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export default CareProgramDetails;