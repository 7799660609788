import React, { useEffect, useState } from 'react';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import styles from '../account-management/AccountManagement.module.css';
import { setSelectedRows, setSelectedItem, setSelectAll } from '../../components/PerinHealthGridSlice';
import { resetFilterState } from '../../slicers/FilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import { closeModal } from '../../components/PerinHealthModelSlice';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import managementStyles from '../ManagementPages.module.css';

const DeviceManagement = () => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    useEffect(() => {
        dispatch(resetFilterState());
        dispatch(setSelectedRows([]));
        dispatch(setSelectedItem({}));
    }, []);
    const navigate = useNavigate();
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    const userId = useSelector((state) => state.userInfo.id);
    let selectedRows = perinHealthGrid.selectedRows;

    const headers = [
        { key: 'id', label: "ID"},
        { key: 'manufacturer', label: "Device"},
        { key: 'model', label: "Model"},
        { key: 'quantity', label: "Quantity"},
        { key: 'groupName', label: 'Group'}
    ];
    
    const [deviceData, setDeviceData] = useState([]);

    useEffect(() => {
        getDevices();
    }, []);

    const getDevices = async () => {
        httpService.get(`device/group/${userId}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    setDeviceData(responseData.data.data);
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onAddDeviceHandler = () => {
        navigate('/add-device');
    }

    const onRowClickHandler = (rowItem) => {
        navigate(`/add-device`, { state: { editDeviceData: rowItem } });
    };

    const onDeleteHandler = () => {
        const deletePromises = selectedRows.map((device) => {
            const id = device.id;
            return httpService.delete(`device/group/${id}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getDevices();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Successfully deleted device.`,
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "There was an error deleting devices",
                        type: 'danger'
                    })
                );
            });
    };

    return (
        <div className={managementStyles.MainContent}>
            {/* <div className={managementStyles.Title}>
                Device Management
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={false}
                    isTeam={false}
                    addButtonLabel='Add Device'
                    onAddHandler={onAddDeviceHandler}
                    onDeleteHandler={onDeleteHandler}
                    modelHeader='Delete Device?' 
                />
                <PerinHealthGrid
                    dataList={deviceData}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    showHeaderFilter={true}
                    gridHeight="100%"
                />
            </div>
        </div>
    )
}

export default DeviceManagement;
