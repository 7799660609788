import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import sidebarImage from '../../assets/communityHospital.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../slicers/sidebarSlice';
import { AdmitButtonIcon, InboxIcon, TemplateManagementIcon, HardwareManagementIcon, OrganizationSetupIcon, PatientManagementIcon, RevenueManagementIcon } from '../../components/icons/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import AppTourPopup from '../app-tour/AppTourPopup';
import { setSelectedMenuItem } from '../../slicers/sidebarSlice';
import addIcon from '../../assets/Add_Icon.png';

const Sidebar = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarSlicer = useSelector((state) => state.sidebar);
  const appTour = useSelector((state) => state.appTour);
  const isSidebarOpen = sidebarSlicer.isSidebarOpen;
  const selectedMenuItem = sidebarSlicer.selectedMenuItem;
  const userInfo = useSelector((state) => state.userInfo);

    const { isAdmin, permissions } = useSelector((state) => state.userInfo);

  const isPermissionExists = (permissionName) => {
    return permissions.some(permission => permission.permissionId === permissionName || permission.permissionId === "*");
  };

  const sidebarWidth = isSidebarOpen ? '270px' : '60px';

  const sidebarRef = useRef(null); // Ref for sidebar

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const formattedDate = currentDateTime.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
    });

    const formattedTime = currentDateTime.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    const admitPatient = () => {
        navigate("/enroll-patient");
        dispatch(setSelectedMenuItem('Patient Enrollment'));
    };

    // Create refs for the sidebar menu items
    const dashboardRef = useRef(null);
    const patientEnrollmentRef = useRef(null);
    const appointmentsRef = useRef(null);
    const tasksRef = useRef(null);
    const billingRef = useRef(null);
    const newOrgRef = useRef(null);
    const orgManagementRef = useRef(null);
    const accountManagementRef = useRef(null);
    const roleManagementRef = useRef(null);
    const hubManagementRef = useRef(null);
    const deviceManagementRef = useRef(null);
    const contentManagementRef = useRef(null);
    const questionnaireManagementRef = useRef(null);
    const programManagementRef = useRef(null);
    const patientMessageRef = useRef(null);
    const referenceCodeRef = useRef(null);
    const careTeamMessageRef = useRef(null);
    const patientSubmittedReportRef = useRef(null);

    
    useEffect(() => {
        if (appTour.showAppTour) {
            const currentRef = getCurrentRef(appTour.currentStepLabel);
            if (currentRef && sidebarRef.current) {
                const sidebarRect = sidebarRef.current.getBoundingClientRect();
                const itemRect = currentRef.current.getBoundingClientRect();

                const scrollOffset = 120;

                if (itemRect.top < sidebarRect.top + scrollOffset) {
                    // Scroll up
                    sidebarRef.current.scrollTo({
                        top: sidebarRef.current.scrollTop + itemRect.top - sidebarRect.top - scrollOffset,
                        behavior: 'smooth'
                    });
                } else if (itemRect.bottom > sidebarRect.bottom - scrollOffset) {
                    // Scroll down
                    sidebarRef.current.scrollTo({
                        top: sidebarRef.current.scrollTop + itemRect.bottom - sidebarRect.bottom + scrollOffset,
                        behavior: 'smooth'
                    });
                }
            }
        }
    }, [appTour.currentStepIndex, appTour.showAppTour]);

    const getCurrentRef = (stepLabel) => {
        switch (stepLabel) {
            case 'Patient Dashboard': return dashboardRef;
            case 'Patient Enrollment': return patientEnrollmentRef;
            case 'Appointments': return appointmentsRef;
            case 'Billing': return billingRef;
            case 'Tasks': return tasksRef;
            case 'Add New Organization': return newOrgRef;
            case 'Organization Hierarchy Management': return orgManagementRef;
            case 'Account Management': return accountManagementRef;
            case 'Role Management': return roleManagementRef;
            case 'Hub Management': return hubManagementRef;
            case 'Device Management': return deviceManagementRef;
            case 'Content Management': return contentManagementRef;
            case 'Questionnaire Management': return questionnaireManagementRef;
            case 'Program Management': return programManagementRef;
            case 'Patient Messages': return patientMessageRef;
            case 'Staff Messages': return careTeamMessageRef;
            case 'Patient Medical Reports': return patientSubmittedReportRef;
            case 'Reference Code Management': return referenceCodeRef;
            default: return null;
        }
    };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'sidebar-open-scroll' : ''}`} style={{ width: sidebarWidth }} ref={sidebarRef}>
      <div className="hamburger" style={{ paddingLeft: isSidebarOpen ? '10px' : '15px', paddingTop: isSidebarOpen ? '13px' : '13px' }}>
        <FontAwesomeIcon icon={faBars} style={{ color: 'grey' }} onClick={() => { dispatch(toggleSidebar()) }} />
      </div>
      {isSidebarOpen && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>
          <img src={userInfo.organizationLogo || sidebarImage} alt="Sidebar Logo" className="sidebar-logo"/>
        </div>
      )}
      {isSidebarOpen && (
        <ul className="sidebar-menu">
          {isPermissionExists('Patient Enrollment') &&
            <div className="admit-button-container">
              <button className="admit-button" onClick={admitPatient}>
              <img src={addIcon} />
                <span>Enroll Patient</span>
              </button>
            </div>
          }
          <li className="blank-header"></li>
          <li className="category-header">
            <PatientManagementIcon />
            <span className="category-header-title">Patient Management</span>
          </li>
          {isPermissionExists('Patient Dashboard') &&
            <Link to="/overview-dashboard">
              <li ref={dashboardRef} className={selectedMenuItem === 'Patient Dashboard' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Patient Dashboard'))}>
                <span>Dashboard</span>
              </li>
            </Link>
          }
          {isPermissionExists('Patient Enrollment') &&
            <Link to="/patient-enrollment">
              <li ref={patientEnrollmentRef} className={selectedMenuItem === 'Patient Enrollment' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Patient Enrollment'))}>
                <span>Enrollment</span>
              </li>
            </Link>
          }
          {isPermissionExists('Appointments') &&
            <Link to="/patient-appointment">
              <li ref={appointmentsRef} className={selectedMenuItem === 'Appointments' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Appointments'))}>
                <span>Appointments</span>
              </li>
            </Link>
          }
          {isPermissionExists('Tasks') &&
            <Link to="/tasks">
              <li ref={tasksRef} className={selectedMenuItem === 'Task' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Task'))}>
                <span>Tasks</span>
                {sidebarSlicer?.taskListCount > 0 && (<Badge bg="danger" pill='true'>{sidebarSlicer.taskListCount}</Badge>)}
              </li>
            </Link>
          }
          <li className="blank-header"></li>
          {isPermissionExists('Billing') &&
            <>
              <li className="category-header">
                <RevenueManagementIcon />
                <span className="category-header-title">Revenue Management</span>
              </li>
              <li ref={billingRef} className={selectedMenuItem === 'Billing' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Billing'))}>
                <span>Billing</span>
              </li>
              <li className="blank-header"></li>
            </>
          }
          <li className="category-header">
            <OrganizationSetupIcon />
            <span className="category-header-title">Organization Setup</span>
          </li>
          {isAdmin &&
            <Link to="/new-organization">
              <li ref={newOrgRef} className={selectedMenuItem === 'Add New Organization' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Add New Organization'))}>
                <span>Organization</span>
              </li>
            </Link>
          }
          {isPermissionExists('Organization Hierarchy Management') &&
            <Link to="/organization-management">
              <li ref={orgManagementRef} className={selectedMenuItem === 'Organization Hierarchy Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Organization Hierarchy Management'))}>
                <span>Hierarchy</span>
              </li>
            </Link>
          }
          {isPermissionExists('Account Management') &&
            <Link to="/account-management">
              <li ref={accountManagementRef} className={selectedMenuItem === 'Account Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Account Management'))}>
                <span>Accounts</span>
              </li>
            </Link>
          }
          {isPermissionExists('Role Management') &&
            <Link to="/role-management">
              <li ref={roleManagementRef} className={selectedMenuItem === 'Role Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Role Management'))}>
                <span>Roles</span>
              </li>
            </Link>
          }
          {isPermissionExists('Reference Code Management') &&
            <Link to="/reference-code">
              <li ref={referenceCodeRef} className={selectedMenuItem === 'Reference Code Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Reference Code Management'))}>
                <span>Reference Code</span>
              </li>
            </Link>
          }
          {(isPermissionExists('Device Management') || isPermissionExists('Hub Management')) &&
            <>
              <li className="blank-header"></li>
              <li className="category-header">
                <HardwareManagementIcon />
                <span className="category-header-title">Hardware Management</span>
              </li>
            </>
          }
          {isPermissionExists('Hub Management') &&
            <Link to="/hub-management">
              <li ref={hubManagementRef} className={selectedMenuItem === 'Hub Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Hub Management'))}>
                <span>Hub</span>
              </li>
            </Link>
          }
          {isPermissionExists('Device Management') &&
            <Link to="/device-management">
              <li ref={deviceManagementRef} className={selectedMenuItem === 'Device Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Device Management'))}>
                <span>Devices</span>
              </li>
            </Link>
          }
          {(isPermissionExists('Content Management') || isPermissionExists('Questionnaire Management') || isPermissionExists('Program Management')) &&
            <>
              <li className="blank-header"></li>
              <li className="category-header">
                <TemplateManagementIcon />
                <span className="category-header-title">Template Management</span>
              </li>
            </>
          }
          {isPermissionExists('Content Management') &&
            <Link to="/content-management">
              <li ref={contentManagementRef} className={selectedMenuItem === 'Content Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Content Management'))}>
                <span>Content</span>
              </li>
            </Link>
          }
          {isPermissionExists('Questionnaire Management') &&
            <Link to="/questionnaire-management">
              <li ref={questionnaireManagementRef} className={selectedMenuItem === 'Questionnaire Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Questionnaire Management'))}>
                <span>Questionnaire</span>
              </li>
            </Link>
          }
          {isPermissionExists('Program Management') &&
            <Link to="/program-management">
              <li ref={programManagementRef} className={selectedMenuItem === 'Program Management' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Program Management'))}>
                <span>Program</span>
              </li>
            </Link>
          }
          <li className="blank-header"></li>
          <li className="category-header">
            <InboxIcon />
            <span className="category-header-title">Inbox</span>
          </li>
          {isPermissionExists('Patient Messages') &&
            <Link to="/patient-message">
              <li ref={patientMessageRef} className={selectedMenuItem === 'Patient Messages' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Patient Messages'))}>
                <span>Patient Messages</span>
                {sidebarSlicer?.unreadPatientMessageIds?.length > 0 && (<Badge bg="danger" pill='true'>{sidebarSlicer.unreadPatientMessageIds?.length}</Badge>)}
              </li>
            </Link>
          }
          {isPermissionExists('Care Team Messages') &&
            <Link to="/care-team-message">
              <li ref={careTeamMessageRef} className={selectedMenuItem === 'Care Team Messages' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Staff Messages'))}>
                <span>Staff Messages</span>
                {sidebarSlicer?.unreadStaffMessageIds?.length > 0 && (<Badge bg="danger" pill='true'>{sidebarSlicer.unreadStaffMessageIds?.length}</Badge>)}
              </li>
            </Link>
          }
          {isPermissionExists('Patient Medical Reports') &&
            <Link to="/patient-submitted-reports">
              <li ref={patientSubmittedReportRef} className={selectedMenuItem === 'Patient Medical Reports' ? 'selected' : ''} onClick={() => dispatch(setSelectedMenuItem('Patient Medical Reports'))}>
                <span>Patient-submitted Reports</span>
              </li>
            </Link>
          }
          <li className="blank-header"></li>
        </ul>
      )}
      {isSidebarOpen && (
        <div className={`date-time ${isSidebarOpen ? '' : 'sidebar-minimized'}`} style={!isSidebarOpen ? { paddingLeft: '0px' } : {}}>
          <div className='date-style'>{formattedDate}</div>
          <div className='time-style'>{formattedTime}</div>
        </div>
      )}
      {appTour.showAppTour && (
        <AppTourPopup
          showAppTour={appTour.showAppTour}
          targetRef={getCurrentRef(appTour.currentStepLabel)}
          dashboardRef={dashboardRef}
          patientEnrollmentRef={patientEnrollmentRef}
          appointmentsRef={appointmentsRef}
          billingRef={billingRef}
          tasksRef={tasksRef}
          newOrgRef={newOrgRef}
          orgManagementRef={orgManagementRef}
          accountManagementRef={accountManagementRef}
          roleManagementRef={roleManagementRef}
          hubManagementRef={hubManagementRef}
          deviceManagementRef={deviceManagementRef}
          contentManagementRef={contentManagementRef}
          questionnaireManagementRef={questionnaireManagementRef}
          referenceCodeRef={referenceCodeRef}
          programManagementRef={programManagementRef}
          patientMessageRef={patientMessageRef}
          careTeamMessageRef={careTeamMessageRef}
          patientSubmittedReportRef={patientSubmittedReportRef}
        />
      )}
    </div>
  );
});

export default Sidebar;
