import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: '',
    name: '',
    email: '',
    phone: '',
    groupName: '',
    subGroupName: '',
    teamName: '',
    logoId: "i123",
    logoURL: '',
    organizationStatus: true,
    selectedRows: [],
    selectAll: false,
    reloadGrid: true,
    isUpdateMode: false,
};

const newOrganizationSlice = createSlice({
    name: 'newOrganization',
    initialState,
    reducers: {
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setFieldValue: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = value;
                }
            });
        },
        setFormValue: (state, action) => {
            const { name, value } = action.payload;
            state[name] = value;
        },
        toggleUpdateMode: (state) => {
            state.isUpdateMode = !state.isUpdateMode;
        },
        resetNewOrganization: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const {
    setSelectAll,
    setSelectedRows,
    setFieldValue,
    setFormValue,
    resetNewOrganization,
    toggleUpdateMode,
} = newOrganizationSlice.actions;

export default newOrganizationSlice.reducer;
