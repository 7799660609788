import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    groupList: [],
    selectedRows: [],
    selectAll: false,
    reloadGrid: true
};
const questionnaireManagementSlice = createSlice({
    name: 'questionnaireManagement',
    initialState,
    reducers: {
        setGroupList: (state, action) => {
            state.groupList = action.payload;
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setReloadGrid: (state, action) => {
            state.reloadGrid = action.payload;
        },
        resetQuestionnaireManagement: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const { setGroupList, setSelectAll, setSelectedRows, setDataList, resetQuestionnaireManagement } = questionnaireManagementSlice.actions;

export default questionnaireManagementSlice.reducer;
