import React, { useState } from 'react';
import styles from '../PatientEnrollment.module.css';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { TrashIcon } from '../../../components/icons/Icons';
import { setNotification, removeNotification } from '../PatientModalitiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import inputStyles from '../../program-management/ProgramManagement.module.css';
import { showAlert } from '../../../components/PerinHealthAlertSlice';

const NotificationSetupCard = (props) => {
    const {
        options,
        modalityId
    } = props;

    const repeatIntervals = [
        {value:"day", label:"Day"},
        {value:"hour", label:"Hour"},
        {value:"month", label:"Month"},
    ];

    const dispatch = useDispatch();
    const allNotifications = useSelector((state)=>state.patientEnrollmentModalities.notifications);
    const notification = allNotifications.find(item => item.modalityId === modalityId);

    const handleDelete = () => {
        dispatch(removeNotification(modalityId));
    };

    const handleChange = (e) => {
        const id = e.target.id;
        let value = e.target.value;
        if (id !== "timeInterval") {
            value = parseInt(value);
        };
        dispatch(setNotification({key:id,value:value, modalityId:modalityId}));
    };

    const handleModalityChange = (e) => {
        const id = e.target.id;
        let value = parseInt(e.target.value);
        if (allNotifications.some((notif) => notif.modalityId === value)) {
            dispatch(
                showAlert({
                    header: "Error",
                    message: "Each Notification rule must have a unique id",
                    type: 'danger',
                })
            );
            dispatch(setNotification({key:id,value:0, modalityId:modalityId}));
        } else {
            dispatch(setNotification({key:id,value:value, modalityId:modalityId}));
        }
    };

    // const newOptions = options.filter(option => {
    //     let matchedNotification = allNotifications.find(not => not.modalityId === option.value);
    //     if (matchedNotification) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // });

    return (
        <div className={styles.notificationSetupContainer}>
            <div style={{display: "flex", gap: "12px", alignItems: "center"}}>
                <span>Notify me if</span>
                <select 
                    className={inputStyles.InputStyling} 
                    id="modalityId"
                    style={{width:"175px"}}
                    value={notification.modalityId}
                    onChange={handleModalityChange}
                >
                    <option value={0}>
                        {"Select Modality"}
                    </option>
                    {options.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
                <span>is outside the threshold</span>
                <select 
                    className={inputStyles.InputStyling} 
                    id="thresholdCount"
                    style={{width:"80px"}}
                    defaultValue={notification.thresholdCount}
                    onChange={handleChange}
                >
                    {Array.from(Array(10).keys()).map((index) => (
                        <option key={index + 1} value={index + 1}>
                            {index + 1}
                        </option>
                    ))}
                </select>
                <span>times in</span>
                <select 
                    className={inputStyles.InputStyling} 
                    id="timeFrequency"
                    style={{width:"80px"}}
                    defaultValue={notification.timeFrequency}
                    onChange={handleChange}
                >
                    {Array.from(Array(10).keys()).map((index) => (
                        <option key={index + 1} value={index + 1}>
                            {index + 1}
                        </option>
                    ))}
                </select>
                <select 
                    className={inputStyles.InputStyling} 
                    id="timeInterval"
                    style={{width:"104px"}}
                    defaultValue={notification.timeInterval}
                    onChange={handleChange}
                >
                    {repeatIntervals.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{display:"flex", alignItems:"center"}} onClick={handleDelete}>
                <TrashIcon />
            </div>
        </div>
    )

}

export default NotificationSetupCard;