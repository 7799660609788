import React, {useState} from 'react';
import ModalityMeasurementSelection from './ModalityMeasurementSelection';
import { useDispatch } from 'react-redux';
import inputStyles from '../views/program-management/ProgramManagement.module.css';
import styles from './ManagementDropdownCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnrollmentDropdowns } from './EnrollmentDropdown';
const DeviceSetupCard = (props) => {
    const {
        id, 
        modalities, 
        dataList, 
        setCardData, 
        setModality, 
        modalityState, 
        errorShowing, 
        disabled, 
        viewOnly, 
        allDevices
    } = props;

    const dispatch = useDispatch();
    const data = dataList.find((item)=> item.deviceId === id);
    const [selectedModality, setSelectedModality] = useState(modalities[0] ?? 0);
    const bloodPressurePresent = modalities.some(mod => mod.deviceModalityId === 8);
    const modalitiesList = bloodPressurePresent ? modalities.filter((mod)=>mod.deviceModalityId !== 8 && mod.deviceModalityId !== 23).concat([{deviceModalityId:8,modalityName:"Blood Pressure"}]) : modalities;

    const handleModalitySelection = (m) => {
        setSelectedModality(m);
    };

    const initStartTime = () => {
        let startStr = data.startTime;
        let startInt = 0;
        let interval = "AM";
        if (startStr !== ""){
            if (!startStr.includes('Z')) {
                startStr = startStr + '.000Z';
            }
            const localDate = new Date(startStr);
            const hour = localDate.getHours();
            startInt = hour % 12 || 12;
            interval = hour >= 12 ? "PM" : "AM";
        }
        return {startInt:startInt, interval:interval};
    };

    let [startTimeView, setStartTimeView] = useState(initStartTime);

    let frequencyErrorMessage = "Please provide a valid interval: \n Day: 1-365, Min: 1-60, Hour: 1-24"
    let frequencyErrorShowing = errorShowing.showing && ( data.frequency <= 0 ||
        (data.interval === "Day" && data.frequency > 365) ||
        (data.interval === "Min" && data.frequency > 60) ||
        (data.interval === "Hour" && data.frequency > 24)
    );

    let startTimeErrorMessage = "Please provide a valid start time"
    let startTimeErrorShowing = errorShowing.showing && data.startTime === "";

    const convertStartTime = (hour12, period) => {
        let hour24 = hour12;
        if (hour12 === -1) {
            return "";
        }
        if (period === 'PM' && hour12 !== 12) {
            hour24 += 12; // Add 12 hours for PM
        } else if (period === 'AM' && hour12===12){
            hour24 = 0;
        }
        // Construct the ISO string
        const currentDate = new Date();
        currentDate.setHours(hour24,0,0,0);
        let utcTimeStamp = "";
        try {
            utcTimeStamp = currentDate.toISOString();
        } catch (error) {
            console.error(error);
        }
        return utcTimeStamp;
    };

    const handleStartTime = (e) => {
        let name = e.target.id;
        let value = e.target.value;
        // check if the value is between 0-24
        const regex = /^(0?[0-9]|1[0-2])$/;
        if (name === "startInt") {
            if (value === "" || !(regex.test(value))) {
                value = -1;
            } else {
                value = Number(value);
            }
        }
        setStartTimeView({
            ...startTimeView,
            [name]: value
        });
    
        // Calculate ISO string and dispatch action
        const isoString = convertStartTime(
            name === "startInt" ? value : startTimeView.startInt,
            name === "interval" ? value : startTimeView.interval
        );
        dispatch(setCardData({ ...data, startTime: isoString }));
    };

    const handleFrequency = (e) => {
        let tempData = {...data};
        tempData['frequency'] = Number(e.target.value);
        if (data["interval"] === "") {
            tempData['interval'] = "Day";
        };
        dispatch(setCardData(tempData));
    };

    const handleIntervalChange = (e) => {
        let tempData = {...data};
        tempData['interval'] =e.target.value;
        dispatch(setCardData(tempData));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"100%", height:"100%"}}>
            <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"100%", height:"100%", padding:"20px 0 0 20px"}}>
                <span className={styles.Interval}>Interval</span>
                <div className={styles.IntervalRow}>
                    <span className={styles.Label} style={{width:"130px"}}>
                        {`Start Time${!viewOnly ? "*" : ":"}`}
                    </span>
                    {viewOnly ?
                        <div>
                            {`${startTimeView.startInt} ${startTimeView.interval}`}
                        </div>
                        :
                        <>
                            <input className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && data.startTime === "") ? {border:"1px solid red", width:"70px"} : {width:"70px"}}
                                type="number"
                                id="startInt"
                                disabled={disabled}
                                defaultValue={startTimeView.startInt}
                                onChange={handleStartTime}
                            ></input>
                            <select className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && data.startTime === "") ? {border:"1px solid red", width:"120px"} : {width:"120px"}}
                                id="interval"
                                disabled={disabled}
                                defaultValue={startTimeView.interval}
                                onChange={handleStartTime}
                            >
                                {EnrollmentDropdowns.timeIntervals.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </>}
                </div>
                {startTimeErrorShowing && 
                    <span className={inputStyles.ValidationMessage}>
                        {startTimeErrorMessage}
                    </span>
                }
                <div className={styles.IntervalRow}>
                    <span className={styles.Label} style={{width:"130px"}}>
                        {`Repeat Every${!viewOnly ? "*" : ":"}`}
                    </span>
                    {viewOnly ?
                        <div>
                            {`${data.frequency} ${data.interval}`}
                        </div>
                        :
                        <>
                            
                            <input className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && frequencyErrorShowing) ? {border:"1px solid red", width:"70px"} : {width:"70px"}}
                                value={data.frequency}
                                id="frequency"
                                maxLength={3}
                                disabled={disabled}
                                onChange={handleFrequency}
                            ></input>
                            <select className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && frequencyErrorShowing) ? {border:"1px solid red", width:"120px"} : {width:"120px"}}
                                disabled={disabled}
                                id="interval"
                                value={data.interval}
                                onChange={handleIntervalChange}
                            >
                                {EnrollmentDropdowns.repeatIntervals.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </>
                    }
                </div>
                {frequencyErrorShowing &&
                    <span className={inputStyles.ValidationMessage}>
                        {frequencyErrorMessage}
                    </span>
                }
                <div className={styles.IntervalRow}>
                    <span className={styles.Label} style={{width:"130px"}}>
                        {`Reading Types${!viewOnly ? "*" : ":"}`}
                    </span>
                    {viewOnly ?
                        <div>
                            {data.readingType}
                        </div>
                        :
                        <select className={inputStyles.InputStyling} 
                            style={{width:"200px"}}
                            id="readingType"
                            value={data.readingType}
                            disabled={disabled}
                            onChange={(e) => dispatch(setCardData({...data, readingType:e.target.value}))}
                        >
                            {EnrollmentDropdowns.readings.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    }
                </div>
            </div>
            <div className={styles.ModalitiesList}>
                {modalitiesList.map((item, index) => (
                    <div key={index} onClick={() => handleModalitySelection(item)} 
                        style={errorShowing.modalityErrors.some(id => id === item.deviceModalityId) ? {color: "red"} : {}}
                        className={selectedModality.deviceModalityId===item.deviceModalityId ? styles.ModalityLabelActive : styles.ModalityLabel}
                    >
                        {item.modalityName}
                    </div>
                ))}
            </div>
            <div>
                {modalitiesList.map((item, index) => (
                    <div key={index} hidden={item.modalityName===selectedModality.modalityName ? false : true}>
                        <ModalityMeasurementSelection 
                            deviceId={id} 
                            deviceModalityId={item.deviceModalityId} 
                            setModality={setModality} 
                            dataList={modalityState} 
                            disabled={disabled || viewOnly} 
                            errors={errorShowing}
                            allDevices={allDevices}
                        />
                    </div>
                ))}
            </div>
            {errorShowing.modalityShowing && errorShowing.deviceIdErrors.some(item => item === id) &&
                <div className={inputStyles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span className={inputStyles.ValidationMessage}>
                        {errorShowing.modalityMessage}
                    </span>
                </div>
            }
            <div style={{padding:"1px"}}></div>
        </div>
    )
}

export default DeviceSetupCard;