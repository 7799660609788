import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import inputStyles from '../../../ManagementPages.module.css';
import { DropdownData } from '../../../../components/AccountManagement/DropdownData';
import styles from '../../Patient.module.css';
import { showAlert } from '../../../../components/PerinHealthAlertSlice';
import { setFormField } from '../../../../slicers/PatientSlice';

const HealthInfoDetail= ({ setModal, setUpdateFn, setCancelFn }) => {

    const dispatch = useDispatch();
    const patient = useSelector((state)=>state.patient);
    const {firstName, lastName, healthInfo, preferences, gender} = patient;
    const unitType = preferences.unitType;
    const today = new Date().toLocaleDateString('fr-ca');
    const [showUpdateButtons, setShowUpdateButtons] = useState(false);

    const initWeight = () => {
        if (healthInfo.weight){
          return parseFloat(healthInfo.weight);
        } else {
          return "";
        }
    }
    const weight = initWeight();

    const [height, setHeight] = useState(parseInitialHeight(healthInfo.height, unitType));
    function parseInitialHeight(heightString, unitType) {
        if (!heightString) {
            return { feet: "", inches: "", cm: "" };
        }
        if (unitType === "Imperial") {
            const [feet, inches] = heightString.split("'").map(str => parseInt(str));
            if (feet) {
                return { feet, inches };
            } else {
                return { feet: "", inches: "" };
            }
        } else {
            const cm = parseInt(heightString);
            return { cm };
        }
    };

    const initialState = {
        dateOfBirth: healthInfo.dateOfBirth ? healthInfo.dateOfBirth.substring(0,10) : "",
        height: healthInfo.height || "",
        weight: weight,
        gender: gender || "",
        criticalLevel: healthInfo.criticalLevel || "",
        notes: healthInfo.notes || "",
    };

    const [ updatedInfo, setUpdatedInfo ] = useState(initialState);
    const [count, setCount] = useState(updatedInfo.notes.length);

    const showUpdateButton = () => {
        if (!showUpdateButtons) {
            setShowUpdateButtons(true);
        };
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        const tempData = {...updatedInfo};
        tempData[id] = value;
        if (id==="notes") {
            setCount(value.length);
        };
        setUpdatedInfo(tempData);
        showUpdateButton();
    };

    const handleHeightChange = (e, id) => {
        const tempData = {...updatedInfo};
        if (e.target.value) {
          const value = parseInt(e.target.value);
          setHeight(prevHeight => ({
              ...prevHeight,
              [id]: value
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${value}' ${height.inches}`;
              } else {
                  formattedHeight = `${height.feet}' ${value}`;
              }
          } else {
              formattedHeight = `${value}cm`;
          }
          tempData["height"] = formattedHeight;
        } else {
          setHeight(prevHeight => ({
            ...prevHeight,
            [id]: ""
          }));
          let formattedHeight;
          if (unitType === "Imperial") {
              if (id === "feet") {
                  formattedHeight = `${height.inches}"`;
              } else {
                  formattedHeight = `${height.feet}' ${""}`;
              }
          } else {
              formattedHeight = `${""}`;
          }
          tempData["height"] = formattedHeight;
        }
        setUpdatedInfo(tempData);
        showUpdateButton();
    };

    const handleUpdate = () => {
        const updatingData = {...patient, gender:updatedInfo.gender, healthInfo:updatedInfo};
        const updateFn = () => {
            dispatch(
                showAlert({
                    header: "Success",
                    message: "Health Info updated",
                    type: 'success',
                })
            );
            dispatch(setFormField({key:"gender", value:updatingData.gender}));
            dispatch(setFormField({key:"healthInfo", value:updatingData}));
            setShowUpdateButtons(false);
        }
        setUpdateFn(()=>updateFn);
        setCancelFn(()=>handleCancel);
        setModal({title:"Health Info", message:`Update ${firstName} ${lastName}'s health info?`, data:updatingData});
    };

    const handleCancel = () => {
        setUpdatedInfo(initialState);
        setShowUpdateButtons(false);
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"15px"}}>
            <div className="input-name"
              style={{paddingTop:"10px"}}>
                Date of Birth
            </div>
            <div className={inputStyles["styled-input-container"]}>
                  <span className="floating-label" >Date of Birth</span>
                  <input 
                    id="dateOfBirth"
                    type="date"
                    max={today}
                    value={updatedInfo.dateOfBirth}
                    onChange={handleChange}
                  ></input>
            </div>
            <div className="input-name"
              style={{paddingTop:"10px"}}>
                Height
            </div>
            {unitType === "Metric" ?
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="cm"
                        type="number"
                        value={height.cm}
                        onChange={(e) => handleHeightChange(e, "cm")}
                    ></input>
                    <label className={height.cm && inputStyles['filled']}>
                    cm
                    </label>
                </div>
              :
              <div className="input-block" style={{width: "300px", marginBottom:"0"}}>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="feet"
                        type="number"
                        min="0"
                        max="8"
                        style={{width:"147px"}}
                        value={height.feet}
                        onChange={(e) => handleHeightChange(e, "feet")}
                    ></input>
                        <label className={height.feet && inputStyles['filled']}>
                        ft
                        </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="inches"
                        type="number"
                        min="0"
                        max="12"
                        style={{width:"147px"}}
                        value={height.inches}
                        onChange={(e) => handleHeightChange(e, "inches")}
                    ></input>
                        <label className={height.inches && inputStyles['filled']}>
                        in
                        </label>
                </div>
              </div>
              
            }
            <div className="input-name">
                Weight
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    style={{width:"147px"}}
                    type="number"
                    id="weight"
                    value={updatedInfo.weight}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.weight && inputStyles['filled']}>
                    {unitType==="Metric" ? "kg" : "lbs"}
                </label>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Gender</span>
                <select
                    className={inputStyles["styled-input-select"]}
                    id="gender"
                    value={updatedInfo.gender}
                    onChange={handleChange}
                >
                    <option value="">Select a Gender</option>
                    {DropdownData.gender.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Critical Level</span>
                <select
                    className={inputStyles["styled-input-select"]}
                    id="criticalLevel"
                    value={updatedInfo.criticalLevel}
                    onChange={handleChange}
                >
                    <option value="">Select Critical Level</option>
                    {DropdownData.levels.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={inputStyles["styled-input-container"]} style={{width:"300px"}}>
                <textarea 
                    className={inputStyles["note-input"]}
                    id="notes"
                    type="text"
                    maxLength={250}
                    value={updatedInfo.notes}
                    onChange={handleChange}
                >
                </textarea>
                <label className={updatedInfo.notes && inputStyles['filled']}>
                    Notes
                </label>
                <span style={{fontSize:"10px", color:"#929395", marginLeft:"auto"}}>
                    {count}/250
                </span>
            </div>
            {showUpdateButtons &&
                <div style={{display:"flex", width: "100%", justifyContent:"space-between"}}>
                    <button variant='primary' className={styles.ModelButton} onClick={handleUpdate} >Save</button>
                    <button variant='secondary' className={styles.SecondaryButton} onClick={handleCancel} >Cancel</button>
                </div>
            }
        </div>
    );
}

export default HealthInfoDetail;