import React, {useState} from 'react';
import styles from './CardDetails.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { editDraft } from '../Slices/PatientDetailNotesSlice';
import { useDispatch } from 'react-redux';
import { ExpandedArrow, CollapsedArrow } from '../../../components/icons/Icons';


const PatientNotesDetails = ( { data, setAttachments, setUpdatingAttachments } ) => {
    const [showAttachments, setShowAttachments] = useState(false);
    const dispatch = useDispatch();

    const editDraftNote = () => {
        setAttachments([])
        setUpdatingAttachments(data.attachmentUrls);
        dispatch(editDraft(data));
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div style={{display:"flex", justifyContent:"space-between", width: "100%", height: "100%"}}>
            <div style={{ whiteSpace: 'pre-wrap', height: "100%"}}>
                <div dangerouslySetInnerHTML={{ __html: data.notes }} />
                {data.attachmentUrls.length > 0 &&
                    <div style={{paddingTop: "20px"}}>
                        <div onClick={()=>setShowAttachments(!showAttachments)} style={{display:"flex", gap: "10px", fontSize: "16px", alignItems:"center", cursor:"pointer", fontWeight:"600"}}>
                            {showAttachments ? <ExpandedArrow />  : <CollapsedArrow /> }
                            Note Attachments
                        </div>
                        {showAttachments &&
                        <div style={{display:"flex", overflow: "scroll", flexDirection:"column", columnGap:"10px", paddingTop: "20px"}}>
                        {data.attachmentUrls.map((file, index) => (
                            <div key={index} style={{ display: "flex", justifyContent: "flex-start", height: "100px", cursor:"pointer"}} onClick={() => openInNewTab(file)}>
                                <img src={file.signedUrl} style={{ maxHeight: "100px", objectFit: "contain" }} />
                            </div>
                        ))}
                    </div>
                        }
                    </div>
                }
            </div>
            {data.isDraft &&
                <div onClick={()=>editDraftNote()} style={{cursor:"pointer"}}>
                    <FontAwesomeIcon icon="fa-regular fa-pen-to-square" />
                </div>
            }
        </div>
    )
}
export default PatientNotesDetails;