import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { setContent, removeContent, setFormValue } from './PatientEnrollmentSlice';
import inputStyles from '../program-management/ProgramManagement.module.css';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';

const ContentEnrollment = ({ errors }) => {
    const content = useSelector((state)=>state.enrollmentLists.content);
    const dispatch = useDispatch();
    const contentNotes = useSelector((state)=>state.enrollPatientProgram.contentNotes);
    const contentList = useSelector((state)=>state.enrollPatientProgram.contents);
    const [count, setCount] = useState(0);
    const {reenrolling, viewing} = useSelector((state)=>state.enrollPatientProgram);

    const initSelectedContent = () => {
        // check to see if content already exists in the state,
        const tempContent = [];

        contentList.forEach((item, index)=> {
            let existingContentIndex = content.findIndex(content => content.id === item.contentId);
            if (existingContentIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempContent.push(content[existingContentIndex]);
            } else {
                tempContent.push({
                    id: item.contentId, 
                    name: item.contentName
                })
            }
        });
        return tempContent;
    };
    const [selectedContent, setSelectedContent] = useState(initSelectedContent());

    const checkSelectedItem = (id) => {
        let contentInList = false;
        selectedContent.forEach((item)=>{
            if (item.id===id){
                contentInList=true;
            } 
        });
        return contentInList;
    };

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const handleContentChange = (content) => {
        let contentInList = false;
        selectedContent.forEach((item)=>{
            if (item.id===content.id){
                contentInList = true;
            } 
        });
        if (contentInList){
            setSelectedContent(selectedContent.filter(item => item.id !== content.id));
            dispatch(removeContent(content.id))
        } else {
            setSelectedContent([content, ...selectedContent]);
            let id = content.id;
            dispatch(setContent({
                id, 
                startTime: getInitialStartTime(),
                frequency: 1,
                interval: "Day"
            }));
        }
    };

    const removeContentCard = (id) => {
        setSelectedContent(selectedContent.filter(item => item.id !== id));
        dispatch(removeContent(id));
    };

    const handleNotes = (e) => {
        setCount(e.target.value.length);
        dispatch(setFormValue({key:"contentNotes",value:e.target.value}));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
             <MultiSelectDropdown 
                options={content}
                label='name'
                checkedKey='id'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleContentChange}
                selectedItems={selectedContent}
                name='Content'
                disabled={reenrolling || viewing}
            />
            {errors.contentShowing && 
                <div className={inputStyles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span 
                        className={inputStyles.ValidationMessage}
                    >
                        {errors.contentMessage}
                    </span>
                </div>
            }
            <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                {selectedContent.map((content, index) => (
                    <div key={content.id}>
                        <ManagementDropdownCard 
                            open={index!==0 ? false : true} 
                            contentInfo={{title:content.name, id:content.id}}
                            cardType={"Content"} 
                            removeHandler={removeContentCard}
                            updateHandler={setContent}
                            dataList={contentList}
                            errorShowing={errors.contentShowing}
                            disabled={reenrolling || viewing}
                        />
                    </div>
                ))}
                {selectedContent.length >= 1 &&
                    <div style={{position: "relative", display:"flex", flexDirection:"column", width:"300px", paddingTop:"20px"}}>
                        <span className={inputStyles.FloatingLabel}>Notes</span>
                        <textarea className={inputStyles.NoteInput} 
                            id="description"
                            type="text"
                            maxLength={250}
                            disabled={reenrolling || viewing}
                            defaultValue={contentNotes}
                            onChange={e => handleNotes(e)}
                        >
                        </textarea>
                        <span style={{fontSize:"10px", color:"#929395", marginLeft:"auto"}}>
                            {count}/250
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default ContentEnrollment;