import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import inputStyles from '../views/program-management/ProgramManagement.module.css';
import styles from './ManagementDropdownCard.module.css';
import { Form } from "react-bootstrap";
import { EnrollmentDropdowns } from './EnrollmentDropdown';

const ModalityMeasurementSelection = (props) => {
    const {
        deviceId, 
        deviceModalityId, 
        setModality, 
        dataList, 
        disabled, 
        errors, 
        allDevices,
        isPerinPatch = false
    } = props;
    const data = dataList.find((item)=> (item.deviceId === deviceId && item.deviceModalityId === deviceModalityId));
    const initThresholdtoggle = data.upperLimit !== 0 || data.lowerLimit !== 0;
    const [thresholdToggled, setThresholdToggled] = useState(initThresholdtoggle);
    const initGoalToggled = data.targetValue !== 0;
    const [goalToggled, setGoalToggled] = useState(initGoalToggled);
    const dispatch = useDispatch();

    const showErrors = errors.modalityErrors.some(id => id === deviceModalityId);

    const userUnitPreference = useSelector((state) => state.userInfo.unitType);
    const findUnit = () => {
        let result = null;
        for (const device of allDevices) {
            result = device.modalities.find(modality => modality.deviceModalityId === deviceModalityId);
            if (result) {
                return userUnitPreference === "Metric" ? result.modalityUnitMetric : result.modalityUnitImperial;
            }
        }
        return result;
    };
    const unit = findUnit();

    // diastolic
    const diastolicData = (deviceModalityId === 8 && !isPerinPatch) ? 
        dataList.find((item)=> (item.deviceId === deviceId && item.deviceModalityId === 23)) 
        : 
        {deviceId: deviceId, deviceModalityId: 23, isAlarmActive: false, lowerLimit: 0, upperLimt: 0, targetValue:0};
    const initDiastolicThresholdToggle = diastolicData.upperLimit !== 0 || diastolicData.lowerLimit !== 0;
    const [diastolicThresholdToggled, setDiastolicThresholdToggled] = useState(initDiastolicThresholdToggle);
    const initDiastolicGoalToggled = diastolicData.targetValue !== 0;
    const [diastolicGoalToggled, setDiastolicGoalToggled] = useState(initDiastolicGoalToggled);


    const handleThresholdToggle = () => {
        const toggled = !thresholdToggled;
        if (!toggled) {
            // if user just toggled threshold off, set upperLimit and lowerLimit to 0
            dispatch(setModality({
                ...data,
                deviceId:deviceId, 
                deviceModalityId: deviceModalityId,
                upperLimit: 0,
                lowerLimit: 0
            }));
        }
        setThresholdToggled(toggled);
    };

    const handleGoalToggle = () => {
        const toggled = !goalToggled;
        if (!toggled) {
            // if user just toggled goal off, set targetGoal to 0
            dispatch(setModality({
                ...data,
                deviceId:deviceId, 
                deviceModalityId: deviceModalityId,
                targetValue: 0,
            }))
        }
        setGoalToggled(toggled);
    };

    const handleDiastolicThresholdToggle = () => {
        const toggled = !diastolicThresholdToggled;
        if (!toggled) {
            // if user just toggled threshold off, set upperLimit and lowerLimit to 0
            dispatch(setModality({
                ...data,
                deviceId:deviceId, 
                deviceModalityId: 23,
                upperLimit: 0,
                lowerLimit: 0
            }))
        }
        setDiastolicThresholdToggled(toggled);
    };

    const handleDiastolicGoalToggle = () => {
        const toggled = !diastolicGoalToggled;
        if (!toggled) {
            // if user just toggled goal off, set targetGoal to 0
            dispatch(setModality({
                ...data,
                deviceId:deviceId, 
                deviceModalityId: 23,
                targetValue: 0,
            }))
        }
        setDiastolicGoalToggled(toggled);
    };

    const isDisabled = disabled || (data.deviceId === 1 && EnrollmentDropdowns.disabledVitals.includes(data.modalityId));

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", paddingLeft: "20px"}}>
            {deviceModalityId === 8 && !isPerinPatch &&
                    <span>
                        Systolic
                    </span>
            }
            <div style={{display:"flex", columnGap:"10px", alignItems:"center", height:"44px"}}>
                <div style={{display: "flex", width:"110px", alignItems:"center"}}>
                    <Form>
                        <Form.Check
                            style={{paddingBottom:"2px"}}
                            type="switch"
                            id="custom-switch"
                            disabled={isDisabled}
                            checked={thresholdToggled}
                            onChange={handleThresholdToggle}
                        />
                    </Form>
                    <span className={styles.ModalityMeasurementLabel}>Threshold </span>
                </div>
                {thresholdToggled && 
                    <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                        <div style={{position:"relative"}}>
                            <span className={inputStyles.FloatingLabel}>Upper Limit</span>
                            <input className={inputStyles.InputStyling} 
                                style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                type="number"
                                id="upperLimit"
                                disabled={isDisabled}
                                defaultValue={data.upperLimit}
                                onChange={(e) => dispatch(setModality({
                                    ...data,
                                    upperLimit: Number(e.target.value)
                                }))}
                            ></input>
                        </div>
                        <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                        <div style={{position:"relative"}}>
                            <span className={inputStyles.FloatingLabel}>Lower Limit</span>
                            <input className={inputStyles.InputStyling} 
                                style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                type="number"
                                id="lowerLimit"
                                disabled={isDisabled}
                                defaultValue={data.lowerLimit}
                                onChange={(e) => dispatch(setModality({
                                    ...data,
                                    lowerLimit: Number(e.target.value)
                                }))}
                            ></input>
                        </div>
                        <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                    </div>
                }
            </div>
            <div style={{display:"flex", columnGap:"10px", alignItems:"center", height:"44px"}}>
                <div style={{display: "flex", width:"110px", alignItems:"center"}}>
                    <Form>
                        <Form.Check
                            style={{paddingBottom:"2px"}}
                            type="switch"
                            id="custom-switch"
                            disabled={isDisabled}
                            checked={goalToggled}
                            onChange={handleGoalToggle}
                        />
                    </Form>
                    <span className={styles.ModalityMeasurementLabel}>Goal</span>
                </div>
                {goalToggled && 
                    <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                        <div style={{position:"relative"}}>
                            <span className={inputStyles.FloatingLabel}>Target Value</span>
                            <input className={inputStyles.InputStyling} 
                                style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                type="number"
                                id="targetValue"
                                disabled={isDisabled}
                                defaultValue={data.targetValue}
                                onChange={(e) => dispatch(setModality({
                                    ...data,
                                    targetValue: Number(e.target.value)
                                }))}
                            ></input>
                        </div>
                        <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                    </div>
                }
            </div>
            {deviceModalityId === 8 && !isPerinPatch &&
                <div>
                    <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                        <span>
                            Diastolic
                        </span>
                    </div>
                    <div style={{display:"flex", columnGap:"10px", alignItems:"center", height:"44px"}}>
                        <div style={{display: "flex", width:"110px", alignItems:"center"}}>
                            <Form>
                                <Form.Check
                                    style={{paddingBottom:"2px"}}
                                    type="switch"
                                    disabled={isDisabled}
                                    id="custom-switch"
                                    checked={diastolicThresholdToggled}
                                    onChange={handleDiastolicThresholdToggle}
                                />
                            </Form>
                            <span className={styles.ModalityMeasurementLabel}>Threshold </span>
                        </div>
                        {diastolicThresholdToggled && 
                            <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                                <div style={{position:"relative"}}>
                                    <span className={inputStyles.FloatingLabel}>Upper Limit</span>
                                    <input className={inputStyles.InputStyling} 
                                        style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                        type="number"
                                        id="upperLimit"
                                        disabled={isDisabled}
                                        defaultValue={diastolicData.upperLimit}
                                        onChange={(e) => dispatch(setModality({
                                            ...diastolicData,
                                            deviceModalityId: 23,
                                            upperLimit: Number(e.target.value)
                                        }))}
                                    ></input>
                                </div>
                                <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                                <div style={{position:"relative"}}>
                                    <span className={inputStyles.FloatingLabel}>Lower Limit</span>
                                    <input className={inputStyles.InputStyling} 
                                        style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                        type="number"
                                        id="lowerLimit"
                                        disabled={isDisabled}
                                        defaultValue={diastolicData.lowerLimit}
                                        onChange={(e) => dispatch(setModality({
                                            ...diastolicData,
                                            deviceModalityId: 23,
                                            lowerLimit: Number(e.target.value)
                                        }))}
                                    ></input>
                                </div>
                                <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                            </div>
                        }     
                    </div>
                    <div style={{display:"flex", columnGap:"10px", alignItems:"center", height:"44px"}}>
                        <div style={{display: "flex", width:"110px", alignItems:"center"}}>
                            <Form>
                                <Form.Check
                                    style={{paddingBottom:"2px"}}
                                    type="switch"
                                    id="custom-switch"
                                    disabled={isDisabled}
                                    checked={diastolicGoalToggled}
                                    onChange={handleDiastolicGoalToggle}
                                />
                            </Form>
                            <span className={styles.ModalityMeasurementLabel}>Goal</span>
                        </div>
                        {diastolicGoalToggled && 
                            <div style={{display:"flex", columnGap:"10px", alignItems:"center"}}>
                                <div style={{position:"relative"}}>
                                    <span className={inputStyles.FloatingLabel}>Target Value</span>
                                    <input className={inputStyles.InputStyling} 
                                        style={showErrors ? {border:"1px solid red", width:"140px", padding:"17px 12px 10px 10px"} : {width:"140px", padding:"17px 12px 10px 10px"}}
                                        type="number"
                                        id="targetValue"
                                        disabled={isDisabled}
                                        defaultValue={diastolicData.targetValue}
                                        onChange={(e) => dispatch(setModality({
                                            ...diastolicData,
                                            deviceModalityId: 23,
                                            targetValue: Number(e.target.value)
                                        }))}
                                    ></input>
                                </div>
                                <span className={styles.ModalityMeasurementLabel}>{unit}</span>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ModalityMeasurementSelection;