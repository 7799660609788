import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChatIcon, UserProfilePictureIcon, VideoCallIcon, PhoneCallIcon, PlusIcon, ClipboardTextIcon } from '../../components/icons/Icons';
import styles from './Patient.module.css';
import inputStyles from '../ManagementPages.module.css';
import pauseButtonIcon from '../../assets/Pause_Button.png';
import { Dropdown, Button } from 'react-bootstrap';
import { DropdownData } from "../../components/AccountManagement/DropdownData";
import useAxios from '../../services/AxiosConfig';
import { showAlert } from '../../components/PerinHealthAlertSlice';
import { closeModal, openModal } from '../../components/PerinHealthModelSlice';
import PerinHealthModal from '../../components/PerinHealthModel';
import { setChatList, setChatVisible, setIsMeetingVisible, setNewChat, setRecipient, setSelectedIndex } from "../patient-message/PatientMessageSlicer";
import ChatVideoPopup from './ChatVideoPopup';
import AppointmentForm from '../appointment/AppointmentForm';
import AppointmentModal from '../appointment/AppointmentModal';
import { openPopup, collapsePopup, setPopupShowing, setMenuItem } from './Slices/PatientDetailSlice';
import { setCallInformation, openCallContainer } from '../../slicers/CallContainerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const PatientHeader = () => {
  const patientInfo = useSelector((state) => state.patient);
  const { popupShowing, popupView } = useSelector((state) => state.patientDetailsSlice);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const dischargePrograms = patientInfo.carePrograms.filter(program => !program.enrollment.dischargeDate);
  const userAccountId = useSelector((state) => state.userInfo.id);
  const userFullName = useSelector((state) => state.userInfo.fullName);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [teamIds, setTeamIds] = useState("");
  const [physicians, setPhysicians] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const patient = {
    email: patientInfo.email,
    phoneNumber: patientInfo.phone,
    countryCode: patientInfo.countryCode,
    patientId: patientInfo.id,
    fullName: patientInfo.fullName,
  };
  const callData = {
    title: `Patient Detail - ${userFullName} & ${patientInfo.fullName}`,
    appointmentId: null,
    senderId: userAccountId,
    recipientId: patientInfo.id,
    senderName: userFullName,
    recipientName: patientInfo.fullName
  };

  const dob = patientInfo.healthInfo.dateOfBirth ? patientInfo.healthInfo.dateOfBirth.substring(0, 10) : "";
  const profilePictureURL = patientInfo.profilePictureURL;

  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);
  const perinHealthModal = useSelector((state) => state.perinHealthModal);
  const userRole = useSelector((state) => state.userInfo.roleName);
  const organizationsHierarchy = useSelector((state) => state.userInfo.organizationsHierarchy);
  const callContainerShowing = useSelector((state) => state.callContainer.callContainerShowing);


  const fetchMessages = async (id) => {
    try {
      const responseData = await httpService.get(`chime/getAllMessageByUserId?userId=${userAccountId}&recipientType=PATIENT`);
      const patientIndex = responseData.data.getAllMessage.findIndex(arr =>
        arr.some(obj => obj.senderId === id || obj.recipientId === id)
      );
      if (patientIndex !== -1) {
        dispatch(setSelectedIndex(patientIndex));
        dispatch(setChatList(responseData.data.getAllMessage[patientIndex]));
      } else {
        dispatch(setChatList([]));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const extractTeamIds = (groups) => {
    let ids = [];
    groups.forEach((group) => {
      if (group.subgroups) {
        group.subgroups.forEach((subgroup) => {
          if (subgroup.teams) {
            subgroup.teams.forEach((team) => {
              ids.push(team.teamId);
            });
          }
        });
      }
    });
    return ids;
  };

  useEffect(() => {
    const getTeamIds = async () => {
      let allIds = [];
      organizationsHierarchy.forEach((org) => {
        allIds = allIds.concat(extractTeamIds(org.groups));
      });
      setTeamIds(allIds.join(", "));
    };
    // setSelectedPhysicianIds([user.id]);
    getTeamIds();
  }, [userAccountId, organizationsHierarchy]);

  useEffect(() => {
    const fetchPhysicians = async () => {
      try {
        httpService
          .get(`/organization/team/staff?teamIds=${encodeURIComponent(teamIds)}`)
          .then((responseData) => {
            const formattedPhysicians = responseData.data.data.map((item) => ({
              value: item.AccountInfoMain_Id,
              label: item.FullName,
            }));
            setPhysicians(formattedPhysicians);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    if (teamIds) {
      fetchPhysicians();
    }
  }, [teamIds, httpService]);

  const bookAppointment = () => {
    setOpenAppointment(true);
  };

  const onAppointmentCreated = () => {
    dispatch(
      showAlert({
        header: "Success",
        message: `Appointment Scheduled`,
        type: 'success'
      })
    );
  };

  const onContactSelection = (selectedProgram) => {
    if (selectedProgram === 'Chat') {
      handlePopup("messages");
    } else if (selectedProgram === 'Video Call') {
      startCall(false);
    } else if (selectedProgram === 'Phone Call') {
      startCall(true);
    } else if (selectedProgram === 'Book Appointment') {
      bookAppointment();
    }
  };

  const onDischargeSelection = (selected) => {
    dispatch(
      openModal({
        header: "Discharge From Program?",
        message: `Once you discharge ${patientInfo.firstName} ${patientInfo.lastName} from ${selected.name}, the action cannot be undone.`,
        buttons: [
          { label: 'Cancel', variant: 'secondary', onClickKey: 'NO' },
          { label: 'Discharge', variant: 'primary', onClickKey: selected },
        ],
      })
    );

  };

  const handleDischarge = async (onClickKey) => {
    if (onClickKey === 'NO') {
      dispatch(closeModal());
    } else {
      try {
        const response = await httpService.delete(`careprogram/patient/enroll/${onClickKey.enrollmentId}`);
        if (response) {
          dispatch(
            showAlert({
              header: "Success",
              message: `Patient has been discharged from ${onClickKey.name}`,
              type: 'success'
            })
          );
        }
      } catch (error) {
        dispatch(
          showAlert({
            header: "Error",
            message: "There was an error discharging the patient",
            type: 'danger'
          })
        );
      }
      dispatch(closeModal());
    }
  };

  const handlePopup = (view) => {
    const openView = view || "calendar";
    if (isPopupVisible && view === undefined) {
      dispatch(collapsePopup());
      setIsPopupVisible(false);
    } else {
      if (view === "messages") {
        dispatch(setChatVisible(true));
        dispatch(setSelectedIndex(0));
        fetchMessages(patientInfo.id);
        dispatch(setNewChat(false));
        dispatch(setIsMeetingVisible(false));
        dispatch(setRecipient({ 'id': patientInfo.id, name: patientInfo.fullName }));

      }
      dispatch(openPopup(openView));
      setIsPopupVisible(true);
    }
  };

  const startCall = (audioOnly) => {
    if (callContainerShowing) {
      return;
    }
    dispatch(collapsePopup());
    dispatch(setChatVisible(false));
    dispatch(setCallInformation({ "audioOnly": audioOnly, "callData": callData, viewType: "patientMessage" }));
    dispatch(openCallContainer());
  }

  const closePopup = () => {
    // hide entire popup container from view
    if (popupView === "audio" || popupView === "video") {
      return;
    }
    dispatch(setPopupShowing(false));
  };

  const handleIconClick = () => {
    dispatch(setMenuItem('Patient Details'));
  };



  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles['patient-container']} style={{ width: "100%" }}>
      <div className={styles['patient-header']} style={{ width: "100%" }}>
        <div className={styles['header-row']} >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", width: "100%" }}>

            <div style={{ display: "flex" }}>
              <div onClick={toggleExpand} style={{ cursor: "pointer", marginRight: "10px", marginTop: '10px' }}>
                <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
              </div>
              <div className={styles.headerProfilePicture}>
                <div className={styles.profileEditIcon} onClick={handleIconClick}>
                  <FontAwesomeIcon icon="fa-regular fa-pen-to-square" size="xs" />
                </div>
                {profilePictureURL ? (
                  <img
                    src={profilePictureURL}
                    alt="User Profile"
                    style={{
                      width: '42px',
                      height: '42px',
                      borderRadius: '50%',
                      border: '2px solid grey',
                      objectFit: 'cover'
                    }}
                  />
                ) : (
                  <div style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                    <span className={"profile-initials"}>
                      {`${patientInfo.firstName?.[0]}${patientInfo.lastName?.[0]}`}
                    </span>
                    <UserProfilePictureIcon width={42} height={42} />
                  </div>
                )}
              </div>

              {/* Patient details */}
              <div className={styles['patient-details']} style={{ textAlign: "left", marginLeft: "10px" }}>
                <div className="headline-4" style={{ color: "#373737" }}>{patientInfo.fullName}</div>
              </div>
            </div>

            <div className={styles['right-section']} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ position: "relative" }}>
                <span className="floating-label">Patient Condition</span>
                <select
                  className={inputStyles["styled-input-select"]}
                  style={{ width: "141px" }}
                  id="gender"
                >
                  {DropdownData.phases.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="primary" className={styles['header-button']}>
                  Contact
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.DischargeDropdownMenu}>
                  {patientInfo.patientHeaderButton.map((program, index) => (
                    <Dropdown.Item
                      key={index}
                      className={styles.DischargeDropdownItem}
                      onClick={() => onContactSelection(program.name)}
                    >
                      <FontAwesomeIcon icon={program.icon} className={styles.Icon} />
                      <span className='ms-3'>{program.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  disabled={dischargePrograms.length === 0}
                  variant="secondary"
                  className={styles['header-button']}
                >
                  Discharge
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.DischargeDropdownMenu}>
                  {dischargePrograms.map((program, index) => (
                    <Dropdown.Item
                      key={index}
                      className={styles.DischargeDropdownItem}
                      onClick={() => onDischargeSelection(program)}
                    >
                      {program.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={styles['patient-details']} style={{ textAlign: "left", marginLeft: "10px" }}>
            {isExpanded && (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                  <ClipboardTextIcon />
                  <span>{dischargePrograms.length > 1 ? `${dischargePrograms.length} programs` : dischargePrograms.length === 1 ? "1 Program" : "No Programs"}</span>
                  <span>{`${patientInfo.healthInfo.gender ?? ""}`}</span>
                  <span>{`MRN: ${patientInfo.mrn ?? ""}`}</span>
                  <span>{`DOB: ${dob}`}</span>
                  <span>{`Email: ${patientInfo.email}`}</span>
                  <span>{`Phone: ${patientInfo.countryCode} ${patientInfo.phone}`}</span>
                  <span>{` ${patientInfo.language}`}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "5px" }}>
                  <div className={styles['header-button-rpm']}>
                    RPM Days 99453
                    <div className={styles['header-button-rpm-box']}>
                      12/30
                    </div>
                  </div>
                  <div className={styles['header-button-rpm']}>
                    RPM Days 99454
                    <div className={styles['header-button-rpm-box']}>
                      08:45
                    </div>
                    <img src={pauseButtonIcon} alt="pause-button" className="pause-button-icon" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {popupShowing &&
        <div style={{ bottom: 20, right: 30, position: "absolute", zIndex: "100" }}>
          <ChatVideoPopup
            closePopup={() => closePopup()}
            handleTabSwitch={handlePopup}
          />
        </div>
      }
      <AppointmentModal isOpen={openAppointment} onClose={() => setOpenAppointment(false)}>
        {openAppointment &&
          <AppointmentForm
            onClose={() => setOpenAppointment(false)}
            onAppointmentCreated={onAppointmentCreated}
            physicians={physicians}
            role={userRole}
            currentPatient={patient}
          />
        }
      </AppointmentModal>
      {perinHealthModal.show && (
        <PerinHealthModal
          show={perinHealthModal.show}
          header={perinHealthModal.header}
          message={perinHealthModal.message}
          buttons={perinHealthModal.buttons}
          handleButtonClick={handleDischarge}
        />
      )}
    </div>
  );
};

export default PatientHeader;
