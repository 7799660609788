import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { closeModal } from './PerinHealthModelSlice'; 
import styles from './PerinHealthModel.module.css';

const PerinHealthModal = ({ show, header, message, buttons, handleButtonClick }) => {

    const dispatch = useDispatch();

    return (
        <Modal style={{fontFamily:"Roboto"}} show={show} onHide={() => dispatch(closeModal())} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize:"20px"}}>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <p>{message}</p>}
            </Modal.Body>
            <Modal.Footer>
                {buttons && buttons.map((button, index) => (
                    <Button 
                        key={index} 
                        variant={button.variant}
                        className={button.variant !== 'secondary' ?  styles.ModelButton : styles.SecondaryButton}
                        onClick={() => handleButtonClick && handleButtonClick(button.onClickKey)}
                    >
                        {button.label}
                    </Button>
                ))}
            </Modal.Footer>
        </Modal>
    );
};

export default PerinHealthModal;
