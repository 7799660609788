import React, { useState, useRef, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import inputStyles from '../ManagementPages.module.css';
import searchStyles from '../../components/FilterManagementView.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterItem, setSelectedCharts } from './Slices/PatientDetailFilterSlice';
import styles from './Patient.module.css';
import { SpecialtySelectedIcon } from '../../components/icons/Icons';
import 'react-datepicker/dist/react-datepicker.css';
import { FloatingLabel, Form, Popover, OverlayTrigger, Overlay } from 'react-bootstrap';

const Option = ({ children, isSelected, innerProps, selectProps, data }) => {
  const isSelectAll = data.value === 'select-all';
  return (
    <div 
      style={{ 
        padding: '8px', 
        display: 'flex', 
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: isSelectAll ? '#f0f0f0' : 'white'
      }} 
      {...innerProps}
    >
      <input 
        type="checkbox" 
        checked={isSelected} 
        readOnly 
        style={{ marginRight: '8px' }}
      />
      <span>{children}</span>
    </div>
  );
};

const MultiValue = () => null;

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedCount = getValue().length;
  const allOptionsCount = props.selectProps.options.length ; // Subtract 1 for the "Select All" option

  return (
    <components.ValueContainer {...props}>
      {children[0]}
      {(selectedCount === allOptionsCount && allOptionsCount!==0) ? 'All Selected' : 
       (selectedCount > 0 ? 
        `${selectedCount} Chart${selectedCount > 1 ? 's' : ''} Selected` 
       : 
       '')}
    </components.ValueContainer>
  );
};

const MenuList = ({ children, ...props }) => {
  const { selectProps } = props;
  const { options, value, onChange } = selectProps;
  const [selectAll, setSelectAll] = useState(false);

  const allOption = { value: 'select-all', label: 'Select All' };

  useEffect(() => {
    setSelectAll(value.length === options.filter(option => option.value !== 'select-all').length);
  }, [value, options]);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      onChange([]);
    } else {
      onChange(options.filter(option => option.value !== 'select-all'));
    }
    setSelectAll(!selectAll);
  }, [selectAll, onChange, options]);

  return (
    <components.MenuList {...props}>
      <Option
        isSelected={selectAll}
        selectProps={selectProps}
        innerProps={{ onClick: handleSelectAll }}
        data={allOption}
      >
        {allOption.label}
      </Option>
      {children}
    </components.MenuList>
  );
};

const FilterPatientView = (props) => {
    const {
        isSearch = false,
        isDate = false,
        isTimePeriod = false,
        isNote = false,
        isReport = false
    } = props

    const dispatch = useDispatch();

    const dateOptions = [
        { label: "Ascending", value: "ascending" },
        { label: "Descending", value: "descending" },
    ];

    const noteTypes = [
        { label: "All", value: "" },
        { label: "Doctor Notes", value: "Doctor Notes" },
        { label: "Transcription", value: "Transcription" },
        { label: "EHR Notes", value: "EHR Note" },
    ];

    const reportTypes = [
        {value:"all", label:"All"}, 
        {value:"new", label:"New"},
        {value:"open", label:"Open"},
        {value:"resolved", label:"Resolved"}
      ]

    const { dateOrder, timePeriod, noteType, startDate, endDate, chartOptions, selectedCharts, reportType } = useSelector((state) => state.patientDetailFilter);
    const filteredCharts = chartOptions.filter(chart => chart.showing);
    const [showDatePopover, setShowDatePopover] = useState(false);
    const customButtonRef = useRef(null);
    const selectRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateOrderChange = (value) => {

        const date = new Date();
        const today = new Date(date);
        today.setDate(today.getDate() + 1);
        let startDate, endDate;

        switch (value) {
            case 'Day':
                startDate = new Date();
                endDate = today;
                break;
            case 'Week':
                startDate = new Date();
                startDate.setDate(today.getDate() - 7);
                endDate = today;
                break;
            case 'Month':
                startDate = new Date();
                startDate.setDate(today.getDate() - 30);
                endDate = today;
                break;
            default:
                startDate = new Date();
                endDate = today;
        }

        startDate = startDate.toISOString().split('T')[0];
        endDate = endDate.toISOString().split('T')[0];

        dispatch(setFilterItem({ key: "timePeriod", value: value }));
        dispatch(setFilterItem({ key: "startDate", value: startDate }));
        dispatch(setFilterItem({ key: "endDate", value: endDate }));
    };

    const handleFilterChange = (e) => {
        const { id, value } = e.target;
        dispatch(setFilterItem({ key: id, value: value }))
    };

    const vitalFilters = [
        { label: "Day", value: "Day" },
        { label: "Week", value: "Week" },
        { label: "Month", value: "Month" },
        { label: "Custom", value: "Custom" },
    ];

    const handleStartDateChange = (event) => {
        dispatch(setFilterItem({ key: "startDate", value: event.target.value }));
    };

    const handleEndDateChange = (event) => {
        dispatch(setFilterItem({ key: "endDate", value: event.target.value }));
    };

    const handleCustomDateClick = (event) => {
        event.stopPropagation();
        setShowDatePopover(!showDatePopover);
    };

    const datePopover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Form.Group className='mb-3'>
                    <FloatingLabel label="Start Date">
                        <Form.Control
                            type="date"
                            className={`${styles.BorderedComponent}`}
                            value={startDate}
                            max={endDate || new Date().toISOString().split('T')[0]}
                            onChange={handleStartDateChange}
                            style={{ color: startDate ? "inherit" : "transparent" }}
                        />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group>
                    <FloatingLabel label="End Date">
                        <Form.Control
                            type="date"
                            className={`${styles.BorderedComponent}`}
                            value={endDate}
                            min={startDate}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={handleEndDateChange}
                            style={{ color: endDate ? "inherit" : "transparent" }}
                        />
                    </FloatingLabel>
                </Form.Group>
            </Popover.Body>
        </Popover>
    );

    const handleChartSelection = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        dispatch(setSelectedCharts(selectedValues));
    };

    return (
        <div style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            paddingLeft: "5px", 
            paddingRight: "10px",
            flexWrap: "nowrap",
            width: "100%"
        }}>
            <div style={{ 
                marginRight: "20px", 
                fontFamily: "Roboto", 
                fontSize: "16px", 
                fontWeight: 500, 
                lineHeight: "28px", 
                letterSpacing: "0.0015em", 
                textAlign: "left", 
                color: "#4F4F4F",
                flexShrink: 0,
                paddingLeft: "10px"
            }}>
                {(() => {
                    const today = new Date();
                    switch(timePeriod) {
                        case 'Day':
                            return today.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
                        case 'Week':
                            const weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
                            const weekEnd = new Date(today.setDate(today.getDate() - today.getDay() + 6));
                            return `${weekStart.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })} - ${weekEnd.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`;
                        case 'Month':
                            return today.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
                        case 'Custom':
                            if (startDate && endDate) {
                                const start = new Date(startDate);
                                const end = new Date(endDate);
                                start.setDate(start.getDate() + 1);
                                end.setDate(end.getDate() + 1);
                                return `${start.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })} - ${end.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`;
                            }
                            return 'Custom Date Range';
                        default:
                            return today.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
                    }
                })()}
            </div>
            <div style={{ flex: 1 }}></div>
            {isTimePeriod &&
                <div className={styles.GraphPeriodButtons} style={{ marginRight: '10px' }}>
                    {vitalFilters.map((filter, index) => (
                        <div 
                            key={index}
                            ref={filter.value === 'Custom' ? customButtonRef : null}
                            className={timePeriod === filter.value ? styles.TimePeriodSelected : ''}
                            onClick={(event) => {
                                handleDateOrderChange(filter.value);
                                if (filter.value === 'Custom') {
                                    handleCustomDateClick(event);
                                } else {
                                    setShowDatePopover(false);
                                }
                            }}
                        >
                            {timePeriod === filter.value && <SpecialtySelectedIcon />}
                            {filter.label}
                        </div>
                    ))}
                </div>
            }
            {isTimePeriod &&
                <div style={{ position: "relative", width: "300px" }} ref={selectRef}>
                    <Select
                    isMulti
                    options={filteredCharts.map(chart => ({ value: chart.value, label: chart.label }))}
                    value={selectedCharts?.map(value => ({ value, label: filteredCharts.find(c => c.value === value)?.label })) || []}
                    onChange={handleChartSelection}
                    onMenuOpen={() => setIsOpen(true)}
                    onMenuClose={() => setIsOpen(false)}
                    menuIsOpen={isOpen}
                    placeholder="Select Charts"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, ValueContainer, MenuList }}
                    styles={{
                        option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                        color: 'black',
                        }),
                        multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: '#e0e0e0',
                        }),
                        multiValueLabel: (provided) => ({
                        ...provided,
                        color: 'black',
                        }),
                        multiValueRemove: (provided) => ({
                        ...provided,
                        color: 'black',
                        ':hover': {
                            backgroundColor: '#c0c0c0',
                            color: 'black',
                        },
                        }),
                        valueContainer: (provided) => ({
                        ...provided,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        }),
                        placeholder: (provided) => ({
                        ...provided,
                        display: selectedCharts?.length ? 'none' : 'block',
                        }),
                    }}
                    />
                </div>
            }
            <Overlay
                show={showDatePopover}
                target={customButtonRef.current}
                placement="bottom"
                container={customButtonRef.current}
                containerPadding={20}
                rootClose
                onHide={() => setShowDatePopover(false)}
            >
                {datePopover}
            </Overlay>
            <div style={{ display: "flex", columnGap: "10px", flexWrap: "wrap" }}>
                {isSearch &&
                    <div style={{ display: "flex", alignItems: "center", position: "relative"}}>
                        <div style={{ position: "absolute", paddingLeft: "10px" }}>
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size="xs" style={{ color: "D9D9D9" }} />
                        </div>
                        <input
                            id="searchText"
                            type="text"
                            placeholder="Search Item"
                            className={searchStyles.SearchInput}
                            onChange={handleFilterChange}
                        />
                    </div>
                }
                {isDate && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Date</span>
                        <select
                            id="dateOrder"
                            style={{ width: "140px" }}
                            className={inputStyles["styled-input-select"]}
                            onChange={handleFilterChange}
                            value={dateOrder}
                        >
                            {dateOptions.map((date) => (
                                <option key={date.value} value={date.value}>
                                    {date.label}
                                </option>
                            ))}
                        </select>
                    </div>)
                }
                {isNote && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Note Type</span>
                        <select
                            id="noteType"
                            style={{ width: "140px" }}
                            className={inputStyles["styled-input-select"]}
                            onChange={handleFilterChange}
                            value={noteType}
                        >
                            {noteTypes.map((date) => (
                                <option key={date.label} value={date.value}>
                                    {date.label}
                                </option>
                            ))}
                        </select>
                    </div>)
                }
                {isReport && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Report Type</span>
                        <select
                            id="reportType"
                            style={{ width: "140px" }}
                            className={inputStyles["styled-input-select"]}
                            onChange={handleFilterChange}
                            value={reportType}
                        >
                            {reportTypes.map((date) => (
                                <option key={date.label} value={date.value}>
                                    {date.label}
                                </option>
                            ))}
                        </select>
                    </div>)
                }
            </div>
        </div>
    );
};

export default FilterPatientView;
