import React, { useState, useEffect, useRef } from 'react';
import styles from './MultiSelectDropdown.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiSelectDropdown = ( props ) => {
    const {
        options,
        label,
        checkedKey,
        checkSelectedItem,
        handleChange,
        selectedItems,
        name,
        disabled=false,
        error=false,
        isSearch=true,
        inputWidth="300px",
        floatingLabel=true
    } = props;

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownContainerRef = useRef(null);
    const dropdownListRef = useRef(null);
    const [dropdownMessage, setDropdownMessage] = useState(`Select ${name}`);
    const [searchText, setSearchText] = useState("");

    useEffect(()=> {
        let message = "Select " + name;
        if (disabled) {
            message = `No ${name} Selected`;
        }
        const len = selectedItems.length;
        if (len === 1) {
            message = selectedItems[0][label];
        } else if (len > 1) {
            message = len + " " + name + " Selected";
        }
        setDropdownMessage(message);
    },[handleChange]); 

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target) &&
                dropdownListRef.current && !dropdownListRef.current.contains(event.target)) {
                    setShowDropdown(false);
                    setSearchText("");
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, []);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    return (
<       div ref={dropdownContainerRef} style={{position:"relative"}}>
            {floatingLabel &&
                <span className="floating-label">{name}</span>
            }
            <div className={floatingLabel ? styles.InputDropdownContainerPadded : styles.InputDropdownContainer}
                style={disabled ? {backgroundColor:"light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))", width:`${inputWidth}`} : error ? {border:"1px solid red",width:`${inputWidth}`}:{width:`${inputWidth}`}} 
                onClick={() => {
                    if (disabled) {
                        return;
                    } else {
                        setShowDropdown(!showDropdown);
                    }
                }}
            >
                <span className={styles.Label}>{(options.length===0 && !disabled) ? `No ${name} Found` : dropdownMessage}</span>
                <div style={{float:"right"}}>
                    <FontAwesomeIcon icon="fa-solid fa-angle-down" size="xs"/>
                </div>
            </div>
            {showDropdown && 
                <div ref={dropdownListRef} className={styles.MultiSelectDropdownListContainer}>
                    {isSearch && 
                        <input
                            placeholder="Search"
                            className={styles.SearchInput}
                            value={searchText}
                            onChange={handleSearchChange}
                        >
                        </input>
                    }
                    {options.filter(item => item[label].toLowerCase().startsWith(searchText.toLowerCase()))
                        .map((item, index) => (
                            <label key={index} className={styles.MultiSelectDropdownLabel}>
                            <input
                                placeholder='Search'
                                type="checkbox"
                                checked={checkSelectedItem(item[checkedKey])}
                                onChange={() => handleChange(item)}
                            />
                            {item[label]}
                            </label>
                    ))}
                </div>
            }
        </div>
    )
}

export default MultiSelectDropdown;