import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detailTab: 'Vitals',
  popupShowing: false,
  popupView: 'calendar',
  popupOpen: true,
  vitalChartOrder: [
    'heartRate', 'oxygenSaturation', 'ECG', 'ECGHRV', 'bloodPressure',
    'temperature', 'respiratoryRate', 'weight', 'AUSC', 'steps', 'glucoseLevel'
  ],
};

const patientDetailSlice = createSlice({
  name: 'patientDetail',
  initialState,
  reducers: {
    resetPatientDetailsState: (state) => {
      state.popupShowing = initialState.popupShowing;
      state.popupView = initialState.popupView;
      state.popupOpen = initialState.popupOpen;
      state.vitalChartOrder = initialState.vitalChartOrder;
    },
    collapsePopup: (state ) => {
      state.popupOpen = false;
      state.popupView = 'calendar';
    },
    openPopup: (state, action) => {
      state.popupOpen = true;
      state.popupShowing = true;
      state.popupView = action.payload;
    },
    setPopupView: (state, action) => {
      state.popupView = action.payload;
    },
    setMenuItem: (state, action) => {
      state.detailTab = action.payload;
    },
    setPopupShowing: (state, action) => {
      state.popupShowing = action.payload;
    },
    setVitalChartOrder: (state, action) => {
      state.vitalChartOrder = action.payload;
    },
  },
});

export const {
  resetPatientDetailsState,
  collapsePopup,
  openPopup,
  setMenuItem,
  setPopupView,
  setPopupShowing,
  setVitalChartOrder
} = patientDetailSlice.actions;

export default patientDetailSlice.reducer;
