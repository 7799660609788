import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import styles from "./Appointment.module.css";
import useAxios from "../../services/AxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { updatePatientInfo } from '../../slicers/PatientSlice';
import { setMenuItem } from '../patient/Slices/PatientDetailSlice';
import { setNewNote, setNoteTitle } from '../patient/Slices/PatientDetailNotesSlice';
import sampleAudio from '../../assets/temp_testTranscript.mp4'; 

function AppointmentTranscription({ show, onHide, appointment }) {
  const [activeSegments, setActiveSegments] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const transcriptRefs = useRef([]);
  const [data, setData] = useState({
    ClinicalDocument: [],
    Transcript: [], 
    Audio: ''    
  });
  const [isTranscriptionReady, setIsTranscriptionReady] = useState(true);

  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Will delete once endpoint is live
  const testTranscriptionData = {
    "code": 200,
    "message": "Get scribe job has been sucessful",
    "data": {
        "ClinicalDocument": [
            {
                "SectionName": "CHIEF_COMPLAINT",
                "Summaries": [
                    {
                        "summary": "Loss of smell and taste.",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "HISTORY_OF_PRESENT_ILLNESS",
                "Summaries": [
                    {
                        "summary": "The patient presents to the clinic today with a chief complaint of loss of smell and taste over the past two days along with a mild dry cough.",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            },
                            {
                                "content": "Yes.",
                                "time": "0:30"
                            },
                            {
                                "content": "Uh, for two days.",
                                "time": "0:30"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    },
                    {
                        "summary": "They report noticing decreased ability to smell things as well as usual and seem to have lost their sense of taste.",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            }
                        ]
                    },
                    {
                        "summary": "They express concern these symptoms could be due to COVID-19.",
                        "linkedSegments": [
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            }
                        ]
                    },
                    {
                        "summary": "When asked to further describe the smell and taste changes, the patient states their sense of smell is not completely gone as they can still smell something if it smells very bad but can only taste very salty foods and not normal foods like banana.",
                        "linkedSegments": [
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            }
                        ]
                    },
                    {
                        "summary": "They note the symptoms have been consistent over the past two days.",
                        "linkedSegments": [
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    },
                    {
                        "summary": "The patient denies any previous similar episodes.",
                        "linkedSegments": [
                            {
                                "content": "No, no, nothing like this.",
                                "time": "0:39"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "REVIEW_OF_SYSTEMS",
                "Summaries": [
                    {
                        "summary": "Loss of smell",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            }
                        ]
                    },
                    {
                        "summary": "Loss of taste",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            }
                        ]
                    },
                    {
                        "summary": "Dry cough",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "PAST_MEDICAL_HISTORY",
                "Summaries": [
                    {
                        "summary": "- No significant past medical history. The patient reports this is the first time experiencing loss of smell and taste along with a cough.",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            },
                            {
                                "content": "Uh, have you ever had anything like this before?",
                                "time": "0:35"
                            },
                            {
                                "content": "No, no, nothing like this.",
                                "time": "0:39"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "ASSESSMENT",
                "Summaries": [
                    {
                        "summary": "Suspected COVID-19 based on loss of smell and taste along with dry cough over the past two days.",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "PLAN",
                "Summaries": [
                    {
                        "summary": "COVID-19 assessment\n  - Recommend self-isolating at home until the test results are available, to avoid potentially spreading the virus to others if the test comes back positive.\n",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            },
                            {
                                "content": "You said for a couple of days?",
                                "time": "0:27"
                            },
                            {
                                "content": "Uh, for two days.",
                                "time": "0:30"
                            },
                            {
                                "content": "Uh, have you ever had anything like this before?",
                                "time": "0:35"
                            },
                            {
                                "content": "No, no, nothing like this.",
                                "time": "0:39"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    },
                    {
                        "summary": "Treatment\n  - Depending on the test results and the progression of symptoms, provide additional treatment recommendations such as rest, hydration, over-the-counter medications to relieve symptoms.\n",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            },
                            {
                                "content": "You said for a couple of days?",
                                "time": "0:27"
                            },
                            {
                                "content": "Uh, for two days.",
                                "time": "0:30"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    },
                    {
                        "summary": "  - Monitor the patient's condition in case their symptoms worsen, in which case they may need additional medical care or monitoring.\n",
                        "linkedSegments": [
                            {
                                "content": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough.",
                                "time": "0:04"
                            },
                            {
                                "content": "Um, I'm just worried it's COVID.",
                                "time": "0:24"
                            },
                            {
                                "content": "You said for a couple of days?",
                                "time": "0:27"
                            },
                            {
                                "content": "Uh, for two days.",
                                "time": "0:30"
                            },
                            {
                                "content": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?",
                                "time": "0:43"
                            },
                            {
                                "content": "Um, the smell isn't totally gone.",
                                "time": "0:53"
                            },
                            {
                                "content": "I can still smell something if it smells really, really bad.",
                                "time": "0:56"
                            },
                            {
                                "content": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things.",
                                "time": "1:01"
                            },
                            {
                                "content": "Like I can't taste a banana.",
                                "time": "1:11"
                            },
                            {
                                "content": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?",
                                "time": "1:17"
                            },
                            {
                                "content": "It's been pretty consistent?",
                                "time": "1:23"
                            }
                        ]
                    }
                ]
            },
            {
                "SectionName": "PHYSICAL_EXAMINATION",
                "Summaries": []
            }
        ],
        "Transcript": [
            {
                "time": "0:04",
                "speaker": "Patient",
                "text": "Yeah."
            },
            {
                "time": "0:04",
                "speaker": "Patient",
                "text": "So, um, for the past two days I, I've, I've noticed that I can't smell things as, um, well, as I used to be able to smell them and I'm, I seem to have lost, uh, my sense of taste as well and I have this mild dry cough."
            },
            {
                "time": "0:24",
                "speaker": "Patient",
                "text": "Um, I'm just worried it's COVID."
            },
            {
                "time": "0:26",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "0:27",
                "speaker": "Clinician",
                "text": "You said for a couple of days?"
            },
            {
                "time": "0:30",
                "speaker": "Patient",
                "text": "Yes."
            },
            {
                "time": "0:30",
                "speaker": "Patient",
                "text": "Uh, for two days."
            },
            {
                "time": "0:33",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "0:34",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "0:35",
                "speaker": "Clinician",
                "text": "Uh, have you ever had anything like this before?"
            },
            {
                "time": "0:39",
                "speaker": "Patient",
                "text": "No, no, nothing like this."
            },
            {
                "time": "0:42",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "0:43",
                "speaker": "Clinician",
                "text": "Um, when you say you feel like you've lost sense of taste and smell, is it totally gone or do you taste a little bit or how, how is that working for you?"
            },
            {
                "time": "0:53",
                "speaker": "Patient",
                "text": "Um, the smell isn't totally gone."
            },
            {
                "time": "0:56",
                "speaker": "Patient",
                "text": "I can still smell something if it smells really, really bad."
            },
            {
                "time": "1:01",
                "speaker": "Patient",
                "text": "Um, and then I, and then I can taste really, really salty foods, but I can't taste normal things."
            },
            {
                "time": "1:11",
                "speaker": "Patient",
                "text": "Like I can't taste a banana."
            },
            {
                "time": "1:15",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "1:16",
                "speaker": "Clinician",
                "text": "Ok."
            },
            {
                "time": "1:17",
                "speaker": "Clinician",
                "text": "Has that been pretty consistent, uh, throughout the last couple of days or is it on and off?"
            },
            {
                "time": "1:23",
                "speaker": "Patient",
                "text": "It's been pretty consistent?"
            }
        ],
        "AudioUrl": "https://perinhealth-attachments-dev.s3.amazonaws.com/notes/93/meeting/20ffc5a1-435e-4319-98ab-4e7233e52713/concatenation/audio/67cbb3ff-4ad0-4d40-b4ae-0a84347a968e.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAVSO7TYEBH7G6VU7F%2F20240508%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240508T202559Z&X-Amz-Expires=3600&X-Amz-Signature=ef4eccffb5f5328c04a0fda52876ba6f3cc36bc607c52b104ff51f3afa1a297a&X-Amz-SignedHeaders=host&response-content-disposition=inline&x-id=GetObject"
    }
};


useEffect(()=>{
    const fetchData = async () => {
        
        httpService.get(`meeting/healthscribe/${appointment.meetingId}?patientId=${appointment.patientId}`)
        .then((responseData) => {
            setData(responseData.data.data);
            setIsTranscriptionReady(true);
        })
        .catch((err) => {
            //setData(testTranscriptionData.data); 
            setIsTranscriptionReady(false);
        });
    };
    fetchData();
},[]);


/*
  useEffect(() => {
    // Need to update to use API once everything is ready
    setData(testTranscriptionData.data); 
  }, []);
*/
  useEffect(() => {
    transcriptRefs.current = transcriptRefs.current.slice(0, data.Transcript.length);
  }, [data.Transcript]);

  const handleSummaryClick = (summary) => {
    const segmentTimes = summary.linkedSegments.map(segment => segment.time);
    setActiveSegments(segmentTimes); 

    // Scroll to the first segment if it exists
    if (segmentTimes.length > 0) {
      const segmentIndex = data.Transcript.findIndex(t => t.time === segmentTimes[0]);
      if (transcriptRefs.current[segmentIndex]) {
        transcriptRefs.current[segmentIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  const getClinicalNotesText = () => {
    const headerText = `Healthscribe Transcription Summary\nAppointment Date: ${new Date(appointment.appointmentDate).toLocaleDateString()}\n\n`;
    
    const sectionsText = data.ClinicalDocument.filter(section => section.Summaries.length > 0)
      .map(section => 
        `${formatSectionName(section.SectionName)}:\n${section.Summaries.map(summary => 
          summary.summary.replace(/^[^a-zA-Z]+/, '') // Clean up the summary text
        ).join("\n")}` 
      ).join("\n\n"); 

      const footerText = `\n\nAppointment ID: ${appointment.appointmentId}`;
    
    return headerText + sectionsText;
  };

  const handleTransferToNote = () => {
    dispatch(updatePatientInfo(appointment.patientId));
    dispatch(setMenuItem('Notes'));    
    dispatch(setNoteTitle({"title" : "Transcription", "meetingId" : appointment.meetingId }));
    dispatch(setNewNote(getClinicalNotesText()));
    navigate('/patient-detail');
  }

  const handleTranscriptClick = (timeString, index) => {
    const seconds = parseTimeToSeconds(timeString);
    if (audioRef.current) {
        audioRef.current.currentTime = seconds;
    }
    
    transcriptRefs.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const parseTimeToSeconds = (time) => {
    const [mins, secs] = time.split(":").map(Number);
    return mins * 60 + secs;
  };

  const isSegmentActive = (time) => {
    return activeSegments.includes(time);
  };

  function formatSectionName(name) {
    return name
      .toLowerCase() 
      .split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' ');
  }

  return (
    <Modal
      className={styles.transcriptionModal}
      show={show}
      onHide={onHide}
      centered
      size="xl"
    >
      <Modal.Title className={styles.transcriptionTitle}>
        Transcription
      </Modal.Title>
      <Modal.Body>
        <Row>
          <Col md={12} className={styles.transcriptionDetailHeader}>
              Visit Detail
          </Col>
        </Row>
        <Row >
          <Col md={12} className={styles.transcriptionDetail} >{appointment.patientName}</Col></Row>
        <Row >
         <Col md={12} className={styles.transcriptionDetail} >Date: {new Date(appointment.appointmentDate).toLocaleDateString()}</Col></Row>
         <Row>
          <Col md={12} className={styles.audioHeader}>
            Audio Transcription
          </Col>
         </Row>
        <Row className={styles.audioPlayerContainer}>
          <Col md={12}>
            
          {isTranscriptionReady ? (
            <audio ref={audioRef} controls src={sampleAudio} className={styles.audioPlayer}></audio>
            ) : (
            <div className={styles.audioNotReady}>Transcription processing...</div>
            )}
          </Col>
        </Row>
        {isTranscriptionReady ? (
        <>
        <Row>
          <Col md={6} className={styles.transcriptionDetailHeader}>
            Transcription
          </Col>
          <Col md={6} className={styles.transcriptionDetailHeader}>
            Insights
          </Col>
        </Row>
        <Row className={styles.content}>
          <Col md={6} className={styles.transcriptions}>
            {data.Transcript.map((transcript, index) => (
              <div key={index} 
                   ref={el => transcriptRefs.current[index] = el}
                   onClick={() => handleTranscriptClick(transcript.time, index)} 
                   className={isSegmentActive(transcript.time) ? styles.highlight : ''}>
                {transcript.time} - <b>{transcript.speaker}</b> - {transcript.text}
              </div>
            ))}
          </Col>
          <Col md={6} className={styles.insights}>
            {data.ClinicalDocument.filter(section => section.Summaries.length > 0).map((section, index) => (
              <div key={index}>
                <h3>{formatSectionName(section.SectionName)}</h3>
                <ul>
                {section.Summaries.map((summary, idx) => (
                  <div key={idx} onClick={() => handleSummaryClick(summary)}>
                    <li>{summary.summary.replace(/^[^a-zA-Z]+/, '')}</li>
                  </div>
                ))}
                </ul>
              </div>
            ))}
          </Col>
        </Row>
        </>
        ) : (<span></span>)}
      </Modal.Body>
      <Modal.Footer>
          <Button
            className={styles.formClose}
            variant="outlined"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            className={styles.formSubmit}
            variant="secondary"
            type="submit"
            onClick={handleTransferToNote}
            disabled={!isTranscriptionReady} 
          >
            Transfer to Notes
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppointmentTranscription;
