export const EnrollmentDropdowns = {
    timeIntervals: [
        {value:"AM", label:"AM"},
        {value:"PM", label:"PM"},
    ],
    repeatIntervals: [
        {value:"Day", label:"Day"},
        {value:"Min", label:"Min"},
        {value:"Hour", label:"Hour"},
        {value:"Week", label:"Week"},
        {value:"Month", label:"Month"},
    ],
    readings: [
        {value:"Automated", label:"Automated"},
        {value:"Manual", label:"Manual"},
        {value:"Both", label:"Both"}
    ],
    disabledVitals: [ // by modalityId
       14,  //fall
       17,   //activity type
       18,  //posture
       2    // pulse rate
    ]
      
};