import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { setSelectedMenuItem } from '../slicers/sidebarSlice';
import { UserProfilePictureIcon } from './icons/Icons';
import styles from './PatientAppointment.module.css';
import { setShowDetail, setSelectedAppointment } from '../views/appointment/AppointmentDetailSlicer';

const PatientAppointment = ({ appointments }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fullName = useSelector((state)=>state.userInfo.fullName);
  
  const calculateMinutesToMeeting = (appointmentStartDateTime) => {
    const appointmentStart = new Date(appointmentStartDateTime);
    const now = new Date();
  
    const timeDifference = (appointmentStart - now) / 1000 / 60; // difference in minutes
    if (timeDifference > 0 && timeDifference <= 30) {
      return `in ${Math.round(timeDifference)} mins`;
    } else if (timeDifference < 0){
      return `overdue by ${Math.round(timeDifference) * -1} mins`;
    } else {
      return null;
    }
  };

  const formatDate = (date) => {
    const givenDateUTC = new Date(date);
    const givenDate = new Date(givenDateUTC.getTime() - givenDateUTC.getTimezoneOffset() * 60000); 
    const today = new Date();
    const localToday = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
    const appointmentDate = givenDate.toISOString().slice(0, 10);

    if (appointmentDate === localToday) {
      return "Today";
    } else {
      const month = String(givenDate.getMonth() + 1).padStart(2, '0');
      const day = String(givenDate.getDate()).padStart(2, '0');
      return `${month}/${day}`;
    }
  };

  const formatTimeToLocal = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  let now = new Date()
  var nowMinus15 = new Date(now.getTime() - 15 * 60 * 1000);

  const sortedAppointments = appointments
  .filter(appointment => {
    return new Date(appointment.appointmentEndDateTime) >= nowMinus15;
  })
  .sort((a, b) => new Date(a.appointmentStartDateTime) - new Date(b.appointmentStartDateTime));

  const handleAppointmentClick = (appointment) => {
    dispatch(setSelectedMenuItem('Appointments'));
    dispatch(setShowDetail(true));
    dispatch(setSelectedAppointment(appointment));
    navigate("/patient-appointment");
  };
  
  return (
    <div className={styles.patientAppointmentContainer}>
      {sortedAppointments.length === 0 ?
        <div className={styles.noAppointments}>
          <span className="body-2">{`No upcoming appointments for ${fullName}`}</span>
        </div>
        : (
        sortedAppointments.slice(0,5).map((appointment, index) => {
          let now = new Date()
          let appointmentOverdue = new Date(appointment.appointmentStartDateTime) < now;
          return (
          <div key={index} className={styles.mainContainer} onClick={()=>handleAppointmentClick(appointment)}>
            <div className={styles.innerContainer}>
              <div className={styles.icon}>
                <span className={styles.initials}>
                  {`${appointment?.patientName.split(' ')[0][0]}${appointment?.patientName.split(' ')[1][0]}`}
                </span>
                <UserProfilePictureIcon width="42" height="42"/>
              </div>
              <div className={styles.userDetails}>
                <div className={styles.userName} style={{color: appointmentOverdue  ? "#630F0F" : "black"}} >{appointment.patientName}</div>
                <div className={styles.timestamp}>{formatDate(appointment.appointmentStartDateTime)}</div>
              </div>
            </div>
            <div className={styles.textContainer}>
              {appointment.isActive && (
                <span style={{color:"#1D5769", paddingRight:"10px"}}>Patient in waiting room</span>
              )}
              <div className={styles.additionalText} style={{color: appointmentOverdue  ? "#630F0F" : "#F67F18"}} >{calculateMinutesToMeeting(appointment.appointmentStartDateTime)}</div>
              <div className={styles.appointmentTimeContainer} style={appointmentOverdue ? {color: "white", background: "#630F0F"} : {color: "#373737", background: "#F3F3F3"}} >
                <p className={styles.appointmentTime}>{formatTimeToLocal(appointment.appointmentStartDateTime)}</p>
              </div>
            </div>
          </div>
        )})
        )
      }
    </div>

  );
};

export default PatientAppointment;
