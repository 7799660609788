import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
};

const TimeOutAlertSlice = createSlice({
  name: 'timeOutAlert',
  initialState,
  reducers: {
    showTimeOutAlert: (state) => {
      state.show = true;
    },
    closeTimeOutAlert: (state) => {
        state.show = false;
    },
  },
});

export const { showTimeOutAlert, closeTimeOutAlert } = TimeOutAlertSlice.actions;
export default TimeOutAlertSlice.reducer;
