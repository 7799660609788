import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './PatientMessage.module.css';
import {  setChatList, setChatVisible, setNewChat, setSelectedIndex, setRecipient } from './PatientMessageSlicer';
import PatientMessageList from './PatientMessageList';
import FilterChatView from '../../components/FilterChatView';

const PatientMessage = () => {
    const dispatch = useDispatch();

    const onNewMessageHandler = () => {
        dispatch(setSelectedIndex(-1));
        dispatch(setChatList([]));
        dispatch(setChatVisible(true));
        dispatch(setNewChat(true));
    }

    const handleChatContainerClose = () => {
        dispatch(setChatVisible(false));
        dispatch(setSelectedIndex(-1));
        dispatch(setChatList([]));
        dispatch(setNewChat(false));
        dispatch(setRecipient({ 'id': 0, name: "" }));
    };

    return (
        <div className={styles[`main-content full-width-content`]}>
            {/* <div className={styles.HeaderContent} >
                <span className={styles.label}>Patient Message</span>
            </div> */}
            <div className={styles.FilterContainer}>
                <FilterChatView
                    view='PatientMessage'
                    isOrganization={true}
                    isGroup={true}
                    isSubGroup={true}
                    isTeam={true}
                    showDeleteButton={false}
                    addButtonLabel={'New Message'}
                    onAddHandler={onNewMessageHandler}
                />
                <PatientMessageList
                    onCloseClick={handleChatContainerClose}
                    onItemClick={onNewMessageHandler} 
                />
            </div>

        </div>
    );
};

export default PatientMessage;
