import React from "react";
import '../../components/AccountManagement/Accounts.css';
import inputStyles from '../ManagementPages.module.css';

const ActivateRoleInfo = ({userData, handleChange}) => {

    const role = userData.role.category;
    return (
        <div style={{display: "flex", flexDirection:"column", gap:"10px"}}>
            {role!=="Patient" &&
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="role"
                        defaultValue={role} 
                        disabled={true}
                    ></input>
                    <label className={inputStyles['filled']}>
                        Role
                    </label>
                </div>
            }
            {role==="Doctor" &&
                <div className={inputStyles["styled-input-container"]}>
                    <input 
                        type="text"
                        id="npi"
                        value={userData.npi}
                        onChange={handleChange}
                    ></input>
                    <label className={userData.npi && inputStyles['filled']}>
                        NPI
                    </label>
                </div>
            }
            {role!=="Doctor" && role!=="Patient" &&
                <div className={inputStyles["styled-input-container"]}>
                    <input 
                        type="text"
                        id="employeeId"
                        value={userData.employeeId}
                        onChange={handleChange}
                    ></input>
                    <label className={userData.employeeId && inputStyles['filled']}>
                        EmployeeId
                    </label>
                </div>
            }
            {role==="Patient" &&
                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <div className={inputStyles["styled-input-container"]}>
                        <input 
                            type="text"
                            id="mrn"
                            value={userData.mrn}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.mrn && inputStyles['filled']}>
                            Medical Record Number
                        </label>
                    </div>
                </div>
                
            }
        </div>
    )

}

export default ActivateRoleInfo;