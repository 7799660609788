import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './Chart.module.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, Legend } from 'recharts';
import { HeartRateIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';
import ChartDetailView from './ChartDetailView';

const GlucoseLevelCardView = React.forwardRef(({ className, healthEvaluationData }, ref) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const { startDate, endDate } = useSelector((state) => state.patientDetailFilter);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const vitals = useSelector((state) => state.vital);
  const [opacity, setOpacity] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = vitalsExpanded ? containerWidth - 60 : containerWidth - 300;
        setChartWidth(width);
      }
      if (chartContainerRef.current) {
        const containerHeight = chartContainerRef.current.offsetHeight;
        const height = containerHeight > 210 ? 210 : containerHeight;
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateData = useCallback(() => {
    // Group data by date and deviceName
    const groupedData = healthEvaluationData.reduce((acc, entry) => {
      const date = entry.date;
      if (!acc[date]) {
        acc[date] = { date };
      }
      acc[date][entry.deviceName] = entry;
      return acc;
    }, {});

    const transformedData = Object.values(groupedData);
    transformedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    setData(transformedData);
    const deviceOpacity = healthEvaluationData.reduce((acc, entry) => {
      const { deviceName } = entry;

      if (!acc[deviceName]) {
        acc[deviceName] = [];
      }
      acc[deviceName].push(entry);

      return acc;
    }, {});

    setOpacity(Object.keys(deviceOpacity).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {}));
    setDisplayData(getLatestMeasurement(healthEvaluationData));
  }, [healthEvaluationData]);

  useEffect(() => {
    updateData();
  }, [updateData, startDate, endDate]);

  const handleLegendClick = (e) => {
    const deviceName = e.value;
    setOpacity((op) => ({ ...op, [deviceName]: op[deviceName] === 1 ? 0 : 1 }));
  };

  const getLatestMeasurement = useCallback((healthEvaluationData) => {
    if (!healthEvaluationData || healthEvaluationData.length === 0) {
      return { glucoseLevel: null, completedDate: null };
    }

    const deviceNamesData = healthEvaluationData.filter(entry => entry.heartRate !== null);
    const latestMeasurement = deviceNamesData.reduce((latest, entry) => {
      const dateA = new Date(latest.completedDate);
      const dateB = new Date(entry.completedDate);
      return dateB > dateA ? entry : latest;
    }, deviceNamesData[0]);

    return {
      measurement: latestMeasurement?.glucoseLevel !== undefined ? Math.round(latestMeasurement?.heartRate) + ' BPM' : 'No Data',
      latestDate: 'Latest: ' + (latestMeasurement?.date || 'N/A'),
    };
  }, []);

  const deviceNames = Array.from(new Set(healthEvaluationData.map(entry => entry.deviceName)));

  const deviceNamesData = deviceNames.filter(deviceName =>
    healthEvaluationData.some(entry => entry.deviceName === deviceName && entry.heartRate !== null)
  );



  const colors = {
    0: '#496CA3',
    1: '#2A7F38',
  };


  const handlePointClick = (deviceName, payload) => {
    const timeStamp = payload.timeStamp;
    setModalContent({ timeStamp, deviceName, modalityId: 11 , vital: vitals.glucosecLevelDeviceName});
    setIsModalOpen(true);
  };

  return (
    <div className={styles.cardContainer} ref={chartContainerRef} style={{flexDirection: vitalsExpanded ? "column" : "row"}}>
      <div className={styles.leftPanel}>
        <div className={styles.boxContainer} style={{flexDirection: vitalsExpanded ? "row" : "column"}}> 
          <div className={styles.topLabel} style={{ display: 'flex', flexDirection: 'column' }}>
            <HeartRateIcon />
            <span>{vitals.glucosecLevelDeviceName}</span>
          </div>
          <div>
            <div className={styles.displayLabel}>{displayData.measurement}</div>
            <div className={styles.updateTime}>{displayData.latestDate}</div>
          </div>
        </div>
      </div>
      <div className={styles.rightPanel} ref={ref}>
        <LineChart width={chartWidth} height={chartHeight} data={data} margin={{left: 20,bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            domain={[30, 130]}
            ticks={[30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130]}
            tickCount={11} />
          <Tooltip />
          <Legend verticalAlign="top"
            height={50} iconType="square"
            align={vitalsExpanded ? "left" : "center"}
            wrapperStyle={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}
            payload={deviceNamesData.map((deviceName, index) => ({
              value: deviceName,
              type: 'square',
              color: colors[index] || '#000000',
            }))}
            onClick={handleLegendClick}
          />
          <ReferenceArea y1={100} y2={130} fill="rgba(235, 182, 182, 0.12)" stroke="red" strokeDasharray="3 3" />
          <ReferenceArea y1={30} y2={60} fill="rgba(235, 182, 182, 0.12)" stroke="red" strokeDasharray="3 3" />
          {deviceNamesData.map((deviceName, index) => (
            <Line connectNulls
              key={deviceName}
              type="linear"
              dataKey={deviceName + '.glucoseLevel'}
              stroke={colors[index] || '#000000'}
              strokeOpacity={opacity[deviceName] || 0}
              strokeWidth={2}
              dot={(props) => {
                const { cx, cy, value, key, payload } = props;
                const dotColor = value <= 60 || value >= 100 ? '#CA3B3B' : '#828282';
                const strokeColor = value <= 60 || value >= 100 ? '#CA3B3B' : '#828282';
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={4}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handlePointClick(deviceName, payload[deviceName]);
                      }}
                      style={{ pointerEvents: 'visible' }}
                      fill={dotColor === '#CA3B3B' ? `rgba(202, 59, 59, ${opacity[deviceName]})` : 'none'}
                      stroke={dotColor}
                      strokeOpacity={opacity[deviceName] || 0}
                      strokeWidth={2}
                      key={key}
                    />
                  );
                } else {
                  return null;
                }
              }}
              activeDot={(props) => {
                const { cx, cy, value, key, payload } = props;
                const dotColor = value <= 60 || value >= 100 ? '#CA3B3B' : '#828282';
                const strokeColor = value <= 60 || value >= 100 ? '#CA3B3B' : '#828282';
                if (value !== undefined && value !== null) {
                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={4}
                      onClick={(e) => {
                        e.stopPropagation(); // Ensure the event doesn't bubble up
                        handlePointClick(deviceName, payload[deviceName]);
                      }}
                      style={{ pointerEvents: 'visible' }}
                      fill={dotColor === '#CA3B3B' ? `rgba(202, 59, 59, ${opacity[deviceName]})` : 'none'}
                      stroke={dotColor}
                      strokeOpacity={opacity[deviceName] || 0}
                      strokeWidth={2}
                      key={key}
                    />
                  );
                } else {
                  return null;
                }
              }}
            />
          ))}
        </LineChart>
      </div>
      <ChartDetailView
        isModalVisible={isModalOpen} customModal={styles.customModal}
        handleCloseModal={() => setIsModalOpen(false)}
        modalData={modalContent}
      />
    </div>
  );
});

export default GlucoseLevelCardView;
