// src/contexts/WebSocketContext.js
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMeetings, leaveMeeting } from '../../views/appointment/AppointmentSlicer';
import { addUnreadPatientMessageId, addUnreadStaffMessageId } from '../../slicers/sidebarSlice';
import { setCurrentCareTeamMeetings, removeCareTeamMeeting } from '../../views/care-team-message/CareTeamMessageSlicer';
import { setHasUnread } from '../../slicers/NotificationSlice';
import { showCallNotification, closeCallNotification } from '../../components/Notifications/CallNotificationSlice';
import { showAlert } from '../../components/PerinHealthAlertSlice';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, baseURL, userId, userRole }) => {
    const dispatch = useDispatch();
    const {unreadPatientMessageIds, unreadStaffMessageIds} = useSelector((state) => state.sidebar);
    const patientMessage = useSelector((state) => state.patientMessage);
    const recipient = patientMessage.recipient;
    const recipientRef = useRef(recipient);
    const staffRecipient = useSelector((state) => state.careTeam.recipient);
    const staffRecipientRef = useRef(staffRecipient);
    const ws = useRef(null);
    const [wsConnected, setWsConnected] = useState(false);
    const reconnectTimeout = useRef(null);
    const attemptRef = useRef(0);
    const maxAttempts = 20;
    const currentMeetingId = useSelector((state) => state.callContainer.currentMeetingId);
    const currentMeetingRef = useRef(currentMeetingId);

    useEffect(() => {
        const wsUrl = `${baseURL}?accountId=${userId}&roleCategory=${userRole}`;

        const connectWebSocket = () => {
            if (ws.current) {
                ws.current.close();
            }
            ws.current = new WebSocket(wsUrl);
            ws.current.onopen = () => {
                setWsConnected(true);
                attemptRef.current = 0;
                clearTimeout(reconnectTimeout.current);
            };

            ws.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log('Received Message:', message);

                if (message.type === 'ping') {
                    ws.current.send(JSON.stringify({ type: 'pong' }));
                } else {
                    handleMessage(message);
                }
            };

            ws.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.current.onclose = () => {
                console.log('WebSocket disconnected');
                setWsConnected(false);
                if (ws.current && attemptRef.current < maxAttempts) {
                    const delay = Math.min(60000, Math.pow(2, attemptRef.current) * 1000);
                    reconnectTimeout.current = setTimeout(connectWebSocket, delay);
                    attemptRef.current += 1;
                }
            };
        };

        connectWebSocket();

        return () => {
            if (ws.current) {
                ws.current.close();
                ws.current = null;
            }
            clearTimeout(reconnectTimeout.current);
        };
    }, [baseURL, userId, userRole]);

    // Add useEffect to watch recipient changes
    useEffect(() => {
        recipientRef.current = recipient;
    }, [recipient]);

    useEffect(() => {
        staffRecipientRef.current = staffRecipient;
    }, [staffRecipient]);

    useEffect(() => {
        currentMeetingRef.current = currentMeetingId;
    }, [currentMeetingId]);

    const handleMessage = (message) => {
        if(message.message === "Message processed successfully"){               
                const senderId = message.data.response.senderId;
                if (message.data?.response?.recipientType === "STAFF") {
                    if (!unreadStaffMessageIds.includes(senderId) && staffRecipientRef.current.id !== senderId) {
                        dispatch(addUnreadStaffMessageId(senderId));
                    }
                } else {
                    if (!unreadPatientMessageIds.includes(senderId) && recipientRef.current.id !== senderId) {
                        dispatch(addUnreadPatientMessageId(senderId));
                    }
                }
            return;
        }

        switch (message.type) {
            case 'connectionSuccess':
                console.log('Connection to websocket has been successful');
                break;
            case 'meetingJoined':
                dispatch(setCurrentMeetings(message));
                break;
            case 'patient_left':
                dispatch(leaveMeeting({ "meetingId": message.meetingId, "patientId": message.patientId }));
                break;
            case 'staff_left':
                dispatch(removeCareTeamMeeting(message));
                if (message.meetingId === currentMeetingRef.current) {
                    dispatch(showCallNotification({name: message.senderName, id: message.senderId, meetingId: message.meetingId, audioOnly: message.isAudioOnly}));
                }
                dispatch(closeCallNotification());
                break;
            case 'staff_joined':
                if (message.meetingId !== currentMeetingRef.current) {
                    dispatch(showCallNotification({name: message.senderName, id: message.senderId, meetingId: message.meetingId, audioOnly: message.isAudioOnly}));
                }
                break;
            case 'patient_unavailable':
                dispatch(
                    showAlert({
                      header: "Patient Unavailable",
                      message: `Patient ${message.patientName} is not available`,
                      type: 'warning',
                      dismissable: false
                    }
                ));
                break;
            case 'staff_unavailable':
                dispatch(
                    showAlert({
                        header: "Staff Unavailable",
                        message: `${message.senderName} is not available`,
                        type: 'warning',
                        dismissable: false
                    }
                ));
                break;
            case 'notification':
                console.log('received notification');
                dispatch(setHasUnread(true));
                break;
            default:
                console.warn('Unhandled message type:', message.type);
        }
    };

    return (
        <WebSocketContext.Provider value={{ ws: ws.current, wsConnected }}>
            {children}
        </WebSocketContext.Provider>
    );
};
