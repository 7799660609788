import React, { useState } from 'react';
import styles from './CustomToggle.module.css';

const CustomToggle = (props) => {
    const {
       labels,
       selectedToggle,
       handleToggle
    } = props;

    const [selected, setSelected] = useState(selectedToggle ?? labels[0].value);

    const handleChange = (value) => {
        setSelected(value);
        handleToggle(value);
    }

    return (
        <div className={styles.Toggle}>
            {labels.map((label) => 
                <>
                    <input type="radio" name="customToggle" value={label.value}
                        checked={selected===label.value}
                        onChange={handleChange}
                    ></input>
                    <label onClick={()=>handleChange(label.value)}> {label.name}</label>
                </>
            )}
        </div>
    );
};

export default CustomToggle;
