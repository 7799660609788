import React, {useState, useEffect} from 'react';
import styles from '../Patient.module.css';
import { useSelector } from 'react-redux';
import PatientDetailDropdownCard from '../../../components/PatientDetailDropdownCard';
import FilterPatientView from '../FilterPatientDetail';
import CareProgramDetails from '../CardDetails/CareProgramDetails';
import useAxios from '../../../services/AxiosConfig';

const CareProgramView= () => {

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);
    const userId = useSelector((state)=>state.userInfo.id);
    const carePrograms = useSelector((state)=>state.patient.carePrograms);
    const {dateOrder, searchText} = useSelector((state)=>state.patientDetailFilter);
    const [allDevices, setAllDevices] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const deviceResponse = await axios.get(`device/group/${userId}`);
                const deviceData = deviceResponse.data.data;
                let tempDevices = [];
                let uniqueDevices = new Set();
                if (deviceData.length >= 1) {
                    deviceData.forEach((device)=>{
                        if (!uniqueDevices.has(device.deviceId)) {
                            tempDevices.push(device);
                            uniqueDevices.add(device.deviceId);
                        }
                    })
                }
                setAllDevices(tempDevices);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
        fetchData();
    }, []);

    const calculateNewProgram = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();
        return start >= end;
    };

    const formatDate = (dateStr) => {
        const utcDate = new Date(dateStr);
        const localDate = utcDate.toLocaleString();
        const splitDate = localDate.split(',')[0].split('/');
        let retDate = new Date(splitDate[2], splitDate[0] - 1, splitDate[1]);
        let formattedDate = retDate.getFullYear() + '/' +
        ('0' + (retDate.getMonth() + 1)).slice(-2) + '/' +
        ('0' + retDate.getDate()).slice(-2);
        return formattedDate;
    };
    
    const sortedCarePrograms = [...carePrograms].filter(item => (
        item.name.toLowerCase().includes(searchText.toLowerCase())
    )).sort((a, b) => {
        const startDateA = new Date(a.enrollment.startDate);
        const startDateB = new Date(b.enrollment.startDate);
        if (dateOrder==="ascending") {
            return startDateA - startDateB;
        } else {
            return startDateB - startDateA;
        }
    });

    const initModalities = (devicesList) => {
        const modalitiesList = [];
        devicesList.forEach((device)=>{
            let foundDevice = allDevices.find(item => item.deviceId === device.deviceId)
            if (foundDevice) {
                foundDevice.modalities.forEach((mod)=>{
                    modalitiesList.push({id: mod.modalityId, name:mod.modalityName})
                })
            }
        })
        return modalitiesList;
    };

    return (
        <div className={styles.ViewContainer}>
            <FilterPatientView isSearch={true} isDate={true}/>
            {sortedCarePrograms.map((program, index) => {
                let dateSubtitle = `${formatDate(program.enrollment.startDate)} - ${formatDate(program.enrollment.endDate)}`
                let label = {
                    name:"In Program",
                    color: "#1F5C29"
                };
                if (program.enrollment.dischargeDate) {
                    label = {
                        name:"Discharged",
                        color: "#686869"
                    }
                } else if (calculateNewProgram(program.enrollment.startDate)) {
                    label = {
                        name:"New Program",
                        color: "#946705"
                    }
                }
                let allModalities = initModalities(program.devices);
                return (
                    <div key={index}>
                        <PatientDetailDropdownCard 
                            title={program.name} 
                            subtitle2={dateSubtitle}
                            label={label}
                            cardInfo={<CareProgramDetails data={program} allModalities={allModalities}/>}
                        />
                    </div>
                )
            })
            }
        </div>
    );
}

export default CareProgramView;