import React, { useState, useEffect } from "react";
import { Modal, Dropdown, Button } from "react-bootstrap";
import CancelConfirmationModal from "./CancelConfirmationModal";
import AppointmentSummary from "./AppointmentSummary";
import AppointmentTranscription from "./AppointmentTranscription";
import styles from "./Appointment.module.css";
import { UserProfilePictureIcon } from "../../components/icons/Icons";
import useAxios from "../../services/AxiosConfig";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch
import { showAlert } from '../../components/PerinHealthAlertSlice';

const AppointmentDetail = ({ show, onHide, appointment, onStartCall, onEdit, fetchAppointments, onMessage, physicians }) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showAppointmentSummary, setShowAppointmentSummary] = useState(false);
  const [showTranscription, setShowTranscription] = useState(false);
  const [appointmentPassed, setAppointmentPassed] = useState(false);
  const appointmentId = appointment ? appointment.appointmentId : null;

  const currentMeetings = useSelector((state) =>
    state.appointment.currentMeetings
  ).flat();

  const isInMeeting = currentMeetings.some(
    (meeting) =>
      meeting.appointmentId === appointment?.appointmentId
  );

  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);
  const dispatch = useDispatch(); // Use dispatch to trigger actions

  useEffect(() => {
    const now = new Date();
    const nowMinus15 = new Date(now.getTime() - 15 * 60 * 1000);
    const appointmentDate = new Date(appointment?.appointmentEndDateTime); 
    setAppointmentPassed(appointmentDate < nowMinus15);
  }, [appointment]);

  const handleCancelSuccess = async () => {
    setShowCancelConfirmation(false); // Ensure the confirmation modal is closed
    onHide(); // Hide the appointment detail modal if still needed

    try {
      await fetchAppointments(); // Fetch updated appointments
      dispatch(
        showAlert({
          header: "Success",
          message: "Appointment cancelled successfully",
          type: 'success',
        })
      );
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const getInitials = (name) => {
    if (!name) return ""; // Return empty if name is not available
    const parts = name.split(" ");
    if (parts.length === 2) {
      return parts[0][0] + parts[1][0]; // First letter of first and last name
    } else if (parts.length === 1) {
      return parts[0][0]; // Only one part of the name
    } else {
      return ""; // If name has no parts, return empty
    }
  };

  const calculateDuration = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    const durationInMinutes = Math.round((end - start) / (1000 * 60));
    return `${durationInMinutes} minutes`;
  };

  const getPhysicianNameById = (physicianId) => {
    const physician = physicians.find(
      (p) => p.value.toString() === physicianId.toString()
    );
    return physician ? physician.label : "Unknown Physician";
  };

  const cancelAppointment = async (appointmentId) => {
    if (!appointmentId) {
      console.error("No appointment ID provided");
      return;
    }
  
    try {
      // Execute the DELETE request
      const response = await httpService.delete(`/appointment/${appointmentId}`);
      
      // Check for success based on status code
      if (response.status === 200 || response.status === 204) {
        console.log("Appointment cancelled successfully", response.data);
        handleCancelSuccess(); // Close both modals and handle success
      } else {
        // Handle errors if the status code isn't what we expect
        throw new Error(response.data?.message || "Failed to cancel appointment");
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error cancelling appointment:", error.message);
      setShowCancelConfirmation(false); // Close the cancel confirmation modal on error
      dispatch(
        showAlert({
          header: "Error",
          message: `Failed to cancel appointment`,
          type: 'danger'
        })
      );
    }
  };

  const handleStartCallClick = () => {
    onHide();
    onStartCall(appointment);
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleShowTranscription = () => {
    setShowTranscription(true)
    onHide();
  }


  return (
    <>
      <Modal show={show} onHide={onHide} centered >
        <Modal.Header style={{padding:"28px 28px 0px 28px", borderBottom:"none"}} closeButton>
          <Modal.Title className={styles.detailHeader}>
            <div className={styles.iconContainer}>
              {appointment?.profilePictureURL ? (
                <img 
                  src={appointment?.profilePictureURL} 
                  alt="User Profile" 
                  style={{
                    width: '38px',
                    height: '38px',
                    borderRadius: '50%',
                    border: '2px solid grey',
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <>
                  <span className={styles.iconText}>{getInitials(appointment?.patientName)}</span>
                  <UserProfilePictureIcon width="38" height="38" />
                </>
              )}
            </div>
            {appointment?.patientName} Appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:"0px 28px 28px 28px"}}>
          <div className={styles.modalAppointmentTime}>{formatDate(appointment?.appointmentStartDateTime)}
            {appointment?.appointmentStartDateTime && appointment?.appointmentEndDateTime && (
            <span> - {calculateDuration(appointment?.appointmentStartDateTime, appointment?.appointmentEndDateTime)}</span>
         )}</div>
          <div className="d-flex justify-content-end gap-2">
            <Button className={styles.startCallButton} variant="secondary" onClick={handleStartCallClick} disabled={appointmentPassed} >
              {isInMeeting ? (<span>Join</span>) : (<span>Start</span>)}
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic" className={styles.detailDropdown}>
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {appointmentPassed ? (
                  <>
                  <Dropdown.Item onClick={() => setShowAppointmentSummary(true)}>Visit Summary</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowTranscription()}>Show Transcription</Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item onClick={() => onEdit(appointment)}>Edit Appointment</Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowCancelConfirmation(true)}>Cancel Appointment</Dropdown.Item>
                    <Dropdown.Item onClick={() => onMessage(appointment)}>Message Patient</Dropdown.Item>
                    <Dropdown.Item>Send Appointment Link</Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Modal.Body>
      </Modal>
      {showCancelConfirmation && (
        <CancelConfirmationModal
          show={showCancelConfirmation}
          onHide={() => setShowCancelConfirmation(false)}
          appointmentId={appointmentId}
          cancelAppointment={cancelAppointment}
          onAppointmentCanceled={handleCancelSuccess} // Pass handleCancelSuccess as callback
        />
      )}
      {showAppointmentSummary && (
        <AppointmentSummary
          show={showAppointmentSummary}
          onHide={() => setShowAppointmentSummary(false)}
          appointment={appointment}
          physicianName={getPhysicianNameById(appointment.physicianId)}
        />
      )}
      {showTranscription && (
        <AppointmentTranscription
          show={showTranscription}
          onHide={() => setShowTranscription(false)}
          appointment={appointment}
        />
      )}
    </>
  );
};

export default AppointmentDetail;
