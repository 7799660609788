import React, { useEffect, useState } from 'react';
import styles from './FilterManagementView.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSearchText, setSelectedGroup, setSelectedLocation, setSelectedTeam, setSelectedOrganization, setGroup, setLocation, setTeam, setSelectedCard } from '../slicers/FilterSlice';
import { closeModal, openModal } from './PerinHealthModelSlice';
import PerinHealthModal from './PerinHealthModel';
import inputStyles from '../views/ManagementPages.module.css';

const FilterManagementView = (props) => {
    const {
        addButtonLabel,
        onAddHandler,
        onDeleteHandler,
        isOrganization,
        isGroup,
        isSubGroup,
        isTeam,
        isSortBy,
        dropdownOptions = [],
        modelHeader,
        showDeleteButton = true,
        showAddButton = true,
        cardFilter = false
    } = props;

    const dispatch = useDispatch();
    const { groups, locations, teams, selectedCard } = useSelector((state) => state.filters);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    const deleteDisabled = perinHealthGrid.selectedRows.length <= 0;
    const addDisabled = perinHealthGrid.selectedRows.length > 0;
    const userGroups = useSelector((state) => state.userInfo.organizationsHierarchy);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const perinHealthModal = useSelector((state) => state.perinHealthModal);

    const [defaultOrganizationId] = userGroups.map((organization) => organization.organizationId);

    const [selectedGroupValue, setSelectedGroupValue] = useState('');
    const [selectedLocationValue, setSelectedLocationValue] = useState('');
    const [selectedTeamValue, setSelectedTeamValue] = useState('');
    const [selectedOrganizationValue, setSelectedOrganizationValue] = useState('');

    

    const initDropdowns = (allGroups) => {
        const defaultGroupId = allGroups[0].groupId;
        const selectedGroup = allGroups.find(group => group.groupId === defaultGroupId);
        if (selectedGroup && selectedGroup.subgroups && selectedGroup.subgroups.length > 0 && isSubGroup) {
            const allSubgroups = selectedGroup.subgroups;
            let dispatchingSubGroups = allSubgroups;
            if (allSubgroups.length > 1) {
                dispatchingSubGroups = [{ subGroupId: '', subGroupName: 'All' }, ...allSubgroups];
            }
            dispatch(setSelectedLocation(allSubgroups.length > 1 ? "" : allSubgroups[0].subGroupId));
            dispatch(setLocation(dispatchingSubGroups));

            if (allSubgroups.length === 1 && allSubgroups[0].teams && allSubgroups[0].teams.length > 0) {
                const allTeams = allSubgroups[0].teams;
                let dispatchingTeams = allTeams;
                if (allTeams.length > 1) {
                    dispatchingTeams = [{ teamId: '', teamName: 'All' }, ...allTeams];
                }
                dispatch(setTeam(dispatchingTeams));

                const defaultTeamId = allTeams[0].teamId;
                dispatch(setSelectedTeam(allTeams.length > 1 ? "" : defaultTeamId));
            }
        }
    };

    useEffect(() => {
        if (!isAdmin && userGroups.length === 1) {
            // only one organization
            const organization = userGroups.find((org) => org.organizationId === Number(defaultOrganizationId));
            if (organization && organization.groups.length > 0) {
                const allGroups = [...organization.groups];
                dispatch(setGroup(allGroups));
                initDropdowns(allGroups);
            }
        } else if (!isAdmin && userGroups.length > 1){
            // multiple organizations
            const allGroups = [];
            userGroups.forEach((org)=> {
                allGroups.push(...org.groups);
            })
            dispatch(setGroup([{ groupId: '', groupName: 'All' }, ...allGroups]));
        } else {
            // user is admin, all other dropdowns will be displayed on org selection
            dispatch(setSearchText(''));
            dispatch(setGroup([]));
            dispatch(setLocation([]));
            dispatch(setTeam([]));
        }
    }, [isAdmin, defaultOrganizationId, dispatch]);

    const handleSearchChange = (e) => {
        dispatch(setSearchText(e.target.value));
    };

    const handleOrganizationChange = (e) => {
        const value = e.target.value;
        setSelectedOrganizationValue(value);
        dispatch(setSelectedOrganization(value));
        dispatch(setSelectedGroup(''));
        dispatch(setSelectedLocation(''));
        dispatch(setSelectedTeam(''));
        dispatch(setLocation([]));
        dispatch(setTeam([]));

        const organization = userGroups.find((org) => org.organizationId === Number(e.target.value));
        if (organization && organization.groups.length > 0) {
            const allGroups = [...organization.groups];
            dispatch(setGroup(allGroups));

            if (allGroups.length === 1) {
                initDropdowns(allGroups);
            } else {
                dispatch(setGroup([{ groupId: '', groupName: 'All' }, ...organization.groups]));
            }
        } else {
            dispatch(setGroup([]));
        }
    };



    const handleGroupChange = (e) => {
        const value = e.target.value;
        setSelectedGroupValue(value);
        dispatch(setSelectedGroup(value));
        dispatch(setSelectedLocation(''));
        dispatch(setSelectedTeam(''));
        dispatch(setLocation([{ subGroupId: '', subGroupName: 'All' }]));
        dispatch(setTeam([{ teamId: '', teamName: 'All' }]));

        const selectedGroup = groups?.find((group) => group.groupId === Number(e.target.value));
        if (selectedGroup && selectedGroup.subgroups && selectedGroup.subgroups.length > 0) {
            const allSubgroups = selectedGroup.subgroups;
            let dispatchingSubGroups = allSubgroups;
            if (allSubgroups.length > 1) {
                dispatchingSubGroups = [{ subGroupId: '', subGroupName: 'All' }, ...allSubgroups];
            }
            dispatch(setLocation(dispatchingSubGroups));
            if (allSubgroups.length === 1 && isSubGroup) {
                const selectedSubgroup = allSubgroups.find((subgroup) => subgroup.subGroupId === Number(allSubgroups[0].subGroupId));
                if (selectedSubgroup && selectedSubgroup.teams && selectedSubgroup.teams.length > 0) {
                    const allTeams = [{ teamId: '', teamName: 'All' }, ...selectedSubgroup.teams];
                    dispatch(setTeam(allTeams));
                }
            }
            
        }
    };

    const handleLocationChange = (e) => {
        const value = e.target.value;
        setSelectedLocationValue(value);
        dispatch(setSelectedLocation(value));
        dispatch(setSelectedTeam(''));
        dispatch(setTeam([{ teamId: '', teamName: 'All' }]));

        const selectedSubgroup = locations?.find((subgroup) => subgroup.subGroupId === Number(e.target.value));
        if (selectedSubgroup && selectedSubgroup.teams && selectedSubgroup.teams.length > 0) {
            const allTeams = [{ teamId: '', teamName: 'All' }, ...selectedSubgroup.teams];
            dispatch(setTeam(allTeams));
        }
    };

    const handleTeamChange = (e) => {
        const value = e.target.value;
        setSelectedTeamValue(value);
        dispatch(setSelectedTeam(value));
    };

    const handleSortByChange = () => {
       // dispatch(setSelectedTeam(e.target.value));
    };

    const clearAllButton = () => {
        dispatch(setSelectedCard(null));
        setSelectedGroupValue('');
        setSelectedLocationValue('');
        setSelectedTeamValue('');
        setSelectedOrganizationValue('');
        dispatch(setSelectedGroup(''));
        dispatch(setSelectedLocation(''));
        dispatch(setSelectedTeam(''));
        dispatch(setSelectedOrganization(''));
    }

    const onAddClickHandler = () => {
        onAddHandler();
    };

    const handleDialogButtonClick = (onClickKey) => {
        if (onClickKey === 'YES') {
            onDeleteHandler();
        } else {
            dispatch(closeModal());
        }
    };

    const onDeleteRowHandler = () => {
        dispatch(
            openModal({
                header: modelHeader,
                message: "Do you want to delete the selected record?",
                buttons: [
                    { label: 'Delete', variant: 'primary', onClickKey: 'YES' },
                    { label: 'Cancel', variant: 'secondary', onClickKey: 'NO' },
                ],
            })
        );

    };

    return (
        <div className={styles.FilterManagmentView}>
            <div className={`${styles.LeftSection} col-1`}>
                <div style={{ display: "flex", alignItems: "center", position:"relative" }}>
                    <div style={{ position: "absolute", paddingLeft: "10px" }}>
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" size="xs" style={{ color: "D9D9D9" }} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearchChange}
                        className={styles.SearchInput}
                    />
                </div>
            </div>

            <div className={`${styles.RightSection} col-11 justify-content-end`}>
                {cardFilter && selectedCard && (
                    <div className={styles.ClearAllLabel} onClick={clearAllButton}>
                        Clear All
                    </div>
                )}
                {isAdmin && isOrganization && (
                    <div style={{ position: "relative"}}>
                        <span className="floating-label">Organization</span>
                        <select 
                            className={inputStyles["styled-input-select"]}
                            onChange={handleOrganizationChange} 
                        >
                            <option value="">Select</option>
                            {userGroups.map((organization) => (
                                <option key={organization.organizationId} value={organization.organizationId}>
                                    {organization.organizationName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {isGroup && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Group</span>
                        <select 
                            className={inputStyles["styled-input-select"]}
                            onChange={handleGroupChange}
                            value={selectedGroupValue}
                        >
                            {groups?.map((group) => (
                                <option key={group.groupId} value={group.groupId}>
                                    {group.groupName}
                                </option>
                            ))}
                        </select>
                    </div>)
                }


                {isSubGroup && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Location</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleLocationChange}
                            value={selectedLocationValue}
                        >
                            {locations?.map((subgroup) => (
                                <option key={subgroup.subGroupId} value={subgroup.subGroupId}>
                                    {subgroup.subGroupName}
                                </option>
                            ))}
                        </select>
                    </div>)
                }
                {isTeam && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Team</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleTeamChange}
                            value={selectedTeamValue}
                        >
                            {teams.map((team) => (
                                <option key={team.teamId} value={team.teamId}>
                                    {team.teamName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                {isSortBy && (
                    <div style={{ position: "relative" }}>
                        <span className="floating-label">Sort By</span>
                        <select
                            className={inputStyles["styled-input-select"]}
                            onChange={handleSortByChange}
                        >
                            <option value="name">Name</option>
                            <option value="date">Date</option>
                            <option value="rating">Rating</option>
                        </select>
                    </div>
                )}
                {showDeleteButton &&
                    <Button
                        variant="secondary"
                        disabled={deleteDisabled}
                        className={deleteDisabled ? styles.DeleteButtonDisabled : styles.DeleteButton}
                        onClick={onDeleteRowHandler}
                    >
                        <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{color:deleteDisabled ? '#B9B9B9' : 'black'}}/>
                        Delete
                    </Button>
                }
                {showAddButton && 
                    (dropdownOptions.length >= 1 ?
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="secondary"
                                disabled={addDisabled}
                                className={addDisabled ? styles.AddContentButtonDisabled : styles.AddContentButton}
                            >
                                {addButtonLabel}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={styles.AddDropdownMenu}>
                                {dropdownOptions.map((option, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        className={styles.AddDropdownItem}
                                        onClick={() => onAddHandler(option.name, option.id, option.category)}
                                    >
                                        {isAdmin ?
                                            `${option.name} - ${option.organizationName}`
                                        :
                                            `${option.name}`
                                        }
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <Button
                            variant="secondary"
                            className={addDisabled ? styles.AddContentButtonDisabled : styles.AddContentButton}
                            disabled={addDisabled}
                            onClick={onAddClickHandler}
                        >
                            {addButtonLabel}
                        </Button>)
                }
                {perinHealthModal.show && (
                    <PerinHealthModal
                        show={perinHealthModal.show}
                        header={perinHealthModal.header}
                        message={perinHealthModal.message}
                        buttons={perinHealthModal.buttons}
                        handleButtonClick={handleDialogButtonClick}
                    />
                )}
            </div>
        </div>
    );
};

export default FilterManagementView;
