import React from 'react';
import styles from './Chart.module.css';

const PerinTooltip = ({ active, payload, label, vitals }) => {
  if (active && payload && payload.length) {
    const dataKey = payload[0].dataKey.split('.');
    const deviceName = dataKey[0];
    const vitalType = dataKey[1];
    const data = payload[0].payload[deviceName] ?? payload[0].payload;
    return (
      <div className={styles.PerinTooltip}>
        <p style={{ color: 'black' }}>{`Device Name: ${deviceName}`}</p>
        <p style={{ color: 'red' }}>{`Upper Limit: ${vitals.upperLimit}`}</p>
        <p style={{ color: 'green' }}>{`Target Value: ${vitals.targetValue}`}</p>
        <p style={{ color: 'green' }}>{`Current Value: ${data[vitalType] ?? 'N/A'}`}</p>
        <p style={{ color: 'red' }}>{`Lower Limit: ${vitals.lowerLimit}`}</p>
      </div>
    );
  }

  return null;
};

export default PerinTooltip;
