import React from "react";
import { DropdownData } from './DropdownData';
import styles from '../../views/ManagementPages.module.css';
import './Accounts.css';
import { setNestedField } from './RegistrationSlice';
import { useSelector, useDispatch } from 'react-redux';


const EmergencyContact = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state)=>state.registration);

    const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch(setNestedField({parentKey:"emergencyContact",nestedKey:id, value:value}));
    };

    return (
        <div className="create-account-inputs">
            <div className={styles["styled-input-container"]}>
                <input
                    id="fullName"
                    pattern="[A-Za-z]+" 
                    title="Please enter letters only"
                    defaultValue={userData.emergencyContact.fullName}
                    onChange={handleChange}
                    style={{width:"300px"}}
                ></input>
                <label className={userData.emergencyContact.fullName && styles['filled']}>
                    Emergency Contact Full Name
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                   id="relation"
                   defaultValue={userData.emergencyContact.relation}
                   onChange={handleChange}
                   style={{width:"300px"}}
                ></input>
                <label className={userData.emergencyContact.relation && styles['filled']}>
                    Relationship
                </label>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Gender</span>
                <select
                    className={styles["styled-input-select"]}
                    id="gender"
                    value={userData.emergencyContact.gender}
                    onChange={handleChange}
                    style={{width:"300px"}}
                >
                    <option value="">Select a Gender</option>
                    {DropdownData.gender.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Country Code</span>
                    <select
                        className={styles["styled-input-select"]}
                        id="countryCode"
                        style={{width:"120px"}}
                        value={userData.emergencyContact.countryCode}
                        onChange={handleChange}
                    >
                        <option value="">Code</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                    </select>
                </div>
                <div className={styles["styled-input-container"]}>
                    <input
                        style={{width:"175px"}}
                        id="phone"
                        type="number"
                        defaultValue={userData.emergencyContact.phone}
                        onChange={handleChange}
                    ></input>
                    <label className={userData.emergencyContact.phone && styles['filled']}>
                        Phone Number
                    </label>
                </div>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="email"
                    type="email"
                    defaultValue={userData.emergencyContact.email}
                    onChange={handleChange}
                    style={{width:"300px"}}
                ></input>
                <label className={userData.emergencyContact.email && styles['filled']}>
                    Email
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="address1"
                    defaultValue={userData.emergencyContact.address1}
                    onChange={handleChange}
                    style={{width:"300px"}}
                ></input>
                <label className={userData.emergencyContact.address1 && styles['filled']}>
                    Street Address (Ave, St, Blvd, etc.)
                </label>
            </div>
            <div className={styles["styled-input-container"]}>
                <input
                    id="address2"
                    defaultValue={userData.emergencyContact.address2}
                    onChange={handleChange}
                    style={{width:"300px"}}
                ></input>
                <label className={userData.emergencyContact.address2 && styles['filled']}>
                Apartment, Suite, etc
                </label>
            </div>
            <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                <div className="input-block">
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="city"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.emergencyContact.city}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.emergencyContact.city && styles['filled']}>
                        City
                        </label>
                    </div>
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="state"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.emergencyContact.state}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.emergencyContact.state && styles['filled']}>
                        State
                        </label>
                    </div>
                </div>
                <div className="input-block" style={{marginBottom:"0px"}}>
                    <div className={styles["styled-input-container"]}>
                        <input
                            id="country"
                            type="text"
                            style={{width:"147px"}}
                            defaultValue={userData.emergencyContact.country}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.emergencyContact.country && styles['filled']}>
                        Country
                        </label>
                    </div>
                    <div className={styles["styled-input-container"]}>
                        <input
                            style={{width:"147px"}}
                            type="number"
                            id="zip"
                            defaultValue={userData.emergencyContact.zip}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.emergencyContact.zip && styles['filled']}>
                        Postal Code
                        </label>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default EmergencyContact;
