// AddQuestionnaireSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationId:'',
  organizationName:'',
  groupId: '',
  groupName: '',
  name: '',
  description: '',
  questionnaireId: '',
  type: '',
  patientType: '',
  selectedQuestionnaireType: '',
  receivedTime:'',
  questions: [
    {
      questionId: 1,
      answerType: '',
      questionText: '',
      possibleAnswers: []
    }
  ],
  previewQuestions: [],
  showPreview: false,
  isUpdateMode: false,
  isUpdateQuestion: false,
  groups: []
};

const addQuestionnaireSlice = createSlice({
  name: 'addQuestionnaire',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (state.hasOwnProperty(key)) {
          state[key] = value;
        }
      });
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
    setGroupId: (state, action) => {
      state.groupId = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setPatientType: (state, action) => {
      state.patientType = action.payload;
    },
    setSelectedQuestionnaireType: (state, action) => {
      state.selectedQuestionnaireType = action.payload;
    },
    setQuestionnaireId: (state, action) => {
      state.questionnaireId = action.payload;
    },
    setShowPreview: (state, action) => {
      state.showPreview = action.payload;
    },
    setQuestion: (state, action) => {
      const { questionId, ...rest } = action.payload;

      if (!state.questions) {
        state.questions = [];
      }

      const existingQuestionIndex = state.questions.findIndex((question) => question.questionId === questionId);

      if (existingQuestionIndex !== -1) {
        const updatedQuestions = state.questions.map((question) =>
          question.questionId === questionId ? { ...question, ...rest } : question
        );
        state.questions = updatedQuestions;
      } else {
        state.questions.push({ questionId: questionId, ...rest });
      }
    },

    setUpdateQuestion: (state, action) => {
      const { questionId, ...rest } = action.payload;
    
      const existingQuestionIndex = state.previewQuestions.findIndex((question) => question.questionId === questionId);
    
      if (existingQuestionIndex !== -1) {
        state.previewQuestions[existingQuestionIndex] = action.payload;
      }
    },
    

    setAnswer: (state, action) => {
      const { questionId, possibleAnswers } = action.payload;
    
      const questionToUpdate = state.questions.find(question => question.questionId === questionId);
    
      if (questionToUpdate) {
        questionToUpdate.possibleAnswers = possibleAnswers;
      } else {
        const newQuestion = {
          questionId: questionId,
          answerType: '', 
          questionText: '', 
          possibleAnswers: possibleAnswers
        };
    
        state.questions.push(newQuestion);
      }
    },
    
    deleteQuestion: (state, action) => {
      const questionId = action.payload;
      state.previewQuestions = state.previewQuestions.filter((question) => question.questionId !== questionId);
    },
    addQuestionToPreview: (state, action) => {
      state.previewQuestions.push(action.payload);
    },
    clearPreviewQuestions: (state) => {
      state.previewQuestions = [];
    },
    clearQuestion: (state, action) => {
      state.questions = [];
    },
    setIsUpdateMode: (state, action) => {
      state.isUpdateMode = action.payload;
    },
    setIsUpdateQuestion: (state, action) => {
      state.isUpdateQuestion = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    resetAddQuestionnaire: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setFieldValue,
  setGroupName,
  setName,
  setDescription,
  setType,
  setGroupId,
  setPatientType,
  setQuestionnaireId,
  setSelectedQuestionnaireType,
  setUploadedFiles,
  setQuestion,
  setUpdateQuestion,
  setAnswer,
  deleteQuestion,
  addQuestionToPreview,
  clearPreviewQuestions,
  clearQuestion,
  resetAddQuestionnaire,
  setShowPreview,
  setIsUpdateMode,
  setIsUpdateQuestion,
  setGroups,
} = addQuestionnaireSlice.actions;

export default addQuestionnaireSlice.reducer;
