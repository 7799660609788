import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeMenuItem: null,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload;
    },
  },
});

export const { setActiveMenuItem } = mainSlice.actions;
export const selectActiveMenuItem = (state) => state.main.activeMenuItem;
export default mainSlice.reducer;
