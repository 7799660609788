import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../services/AxiosConfig';
import styles from './VideoAndMessage.module.css';

const ChatDisplayContainer = ({ messages }) => {

    // console.log("messages", messages);
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL, dispatch);
    const userInfoState = useSelector((state) => state.userInfo);
    const userId = userInfoState.id;
    const userRole = userInfoState.userRole;

    const chatDisplayRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(true);

    const scrollToBottom = () => {
        chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    };

    useEffect(() => {
        if (shouldScroll) {
            scrollToBottom();
        }
    }, [messages, shouldScroll]);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = chatDisplayRef.current;
        const atBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
        setShouldScroll(atBottom);
    };

    function formatTime(dateString) {
        const messageDate = new Date(dateString);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        if (messageDate.toDateString() === currentDate.toDateString()) {
        // Today's message
        return messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        } else if (messageDate.toDateString() === yesterday.toDateString()) {
        // Yesterday's message
        return `Yesterday ${messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
        } else {
        // Older than yesterday
        return `${messageDate.getMonth() + 1}/${messageDate.getDate()} ${messageDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
        }
    };

    
    useEffect(() => {
        const markMessagesAsUnread = async () => {
            if (messages && messages.length > 0) {
                const unreadMessages = messages.filter(message => !message.readStatus && message.recipientId === userId);
                if (unreadMessages.length > 0) {
                    const params = {
                        channelArn : unreadMessages[0].channelArn,
                        userId : userId
                    };
                    try {
                        await httpService.post('chime/updateReadStatus', params);
                    } catch (error) {
                        console.error('Error marking messages as unread:', error);
                    }
                }
            }
        };

        markMessagesAsUnread();
    }, [messages, httpService]);

    const onAttachmentClick = async (e, message) => {
        e.preventDefault(); 
        const params = {
            messageId : message.messageId
        };
        try {
            const response =  await httpService.post('chime/getAttachment', params);
            window.open(response.data.signedUrl, '_blank');
        } catch (error) {
            console.error('Error calling API:', error);
        }
    };
    return (
        <div ref={chatDisplayRef}  className={styles.ChatDisplayBox} onScroll={handleScroll}>
            {messages?.map((message, index) => (
                <div key={index} className={styles.MessageContainer}>
                    <div className={`${message.sent ? styles.SenderBox : styles.ReceiverBox}`}>
                        <span className={styles.Sender}>{`${message.senderName}, ${formatTime(message.createdTime)}`}</span>
                    </div>
                    <div className={`${styles.Message} ${message.sent ? styles.OutgoingMessage : styles.IncomingMessage}`}>
                        {message.message.trim() && (
                            <span className={styles.Content}>{message.message}</span>
                        )}
                        {message.attachment && (
                            <div className={`${styles.AttachmentContainer} ${styles.Attachment}`}>
                                <a href={message.attachment} target="_blank" rel="noopener noreferrer" onClick={(e) => onAttachmentClick(e, message)}>{message.fileName}</a>
                            </div>

                        )}
                    </div>
                </div>

            ))}
        </div>
    );
};

export default ChatDisplayContainer;
