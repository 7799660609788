import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Illustration from '../../assets/Illustration.png';
import styles from './AppTour.module.css';

const Welcome = ({ show, handleClose, handleNext }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className={styles.ModelHeader}></Modal.Header>
            <Modal.Body>
                <h3 className={styles.Heading}>Welcome!</h3>
                <p className={styles.NoWrap}>Welcome to the Perin Health Provider app! Our app can help you:</p>
                <ul>
                    <li>Streamline patient management</li>
                    <li>Manage billing</li>
                    <li>Organize tasks efficiently</li>
                    <li>Simplify your responsibilities</li>
                    <li>Enhance communication with patients and your team</li>
                </ul>
                <img src={Illustration} alt="Welcome" style={{ width: '100%' }} />
            </Modal.Body>
            <Modal.Footer className={styles.ModelFooter}>
                <Button onClick={handleClose} className={styles.BackButton}>
                    Cancel
                </Button>
                <Button onClick={handleNext} className={styles.NextButton}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Welcome;
