export const DropdownData = {
    addressTypes: [
        { value: 'Home', label: 'Home Address' },
        { value: 'Work', label: 'Work Address' },
        { value: 'Billing', label: 'Billing Address' },
        { value: 'Shipping', label: 'Shipping Address' },
        { value: 'Other', label: 'Other Address' },
    ],
    
    dateFormats: [
        { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
        { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' }
    ],

    flags: [
        {label: "1", value: 1},
        {label: "2", value: 2},
        {label: "3", value: 3},
        {label: "4", value: 4},
        {label: "5", value: 5}
    ],

      levels: [
        { value: 1, label: 'Low' },
        { value: 2, label: 'Medium' },
        { value: 3, label: 'High' }
      ],

      phases: [
        { value: 'Stable', label: 'Stable' },
        { value: 'Unstable', label: 'Unstable' },
        { value: 'Deteriorating', label: 'Deteriorating' },
        { value: 'Terminal', label: 'Terminal' }  
      ],

      gender: [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
      ],

      ethnicity: [
        { value: 'Asian', label: 'Asian' },
        { value: 'Black or African American', label: 'Black or African American' },
        { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
        { value: 'Native Hawaiian or Other Pacific Islander', label: 'Native Hawaiian or Other Pacific Islander' },
        { value: 'White', label: 'White' },
        { value: 'Other', label: 'Other' },
      ],
      
};