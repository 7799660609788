import React, { useEffect } from 'react';
import styles from '../Patient.module.css';
import { useSelector, useDispatch  } from 'react-redux';
import PatientDetailDropdownCard from '../../../components/PatientDetailDropdownCard';
import FilterPatientView from '../FilterPatientDetail';
import ReportsDetails from '../CardDetails/ReportsDetails';
import { format } from 'date-fns';
import useAxios from '../../../services/AxiosConfig';
import { setFormField } from '../../../slicers/PatientSlice';
import CustomToggle from '../../../components/CustomToggle';

const ReportsView= () => {
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);
    const userAccountId = useSelector((state) => state.userInfo.id);
    const {id, reports} = useSelector((state) => state.patient);

    const { searchText, dateOrder, reportType } = useSelector((state) => state.patientDetailFilter);
    
    const sortedReports = [...reports]
     .filter(item => (
         item.description.toLowerCase().includes(searchText.toLowerCase()) && (
           (reportType == "resolved" && item.status == "Resolved") ||
           ((item.Unread !== 0 || item.responses.length !== 0) && reportType == "open" && item.status != "Resolved") ||
           (reportType == "new" && item.status != "Resolved" && item.Unread == 0 && item.responses.length == 0) ||
           (reportType == "all")
         )
     ))
    .sort((a, b) => {
        const startDateA = new Date(a.createdDate);
        const startDateB = new Date(b.createdDate);
        if (dateOrder==="ascending") {
            return startDateA - startDateB;
        } else {
            return startDateB - startDateA;
        }
    });

    const getResponses = async (data) => {
        try {
          const responsesPromises = data.map(async (report) => {
            try {
              const response = await axios.get(`contact/nonCriticalReportResponse/${userAccountId}/${report.nonCriticalReportId}/true`);
              if (Array.isArray(response.data.data)) {
                return response.data.data;
              } else {
                return [];
              }
            } catch (err) {
              console.log(err);
              return [];
            }
          });
      
          const responses = await Promise.all(responsesPromises);
          data.forEach((report, index) => {
            report["responses"] = responses[index];
          });
          return data;
        } catch (err) {
          console.error('Error fetching responses:', err);
          return data; 
        }
    };

    const fetchReports = async () => {
        try {
            const reportResponse = await axios.get(`contact/nonCriticalReport/${id}/${userAccountId}`);
            if (Array.isArray(reportResponse.data.data)) {
              const dataWithResponses = await getResponses(reportResponse.data.data);
              dispatch(setFormField({ key: "reports", value: dataWithResponses }));
            } else {
              console.error('Error fetching patient info');
            }
          } catch (err) {
            console.log(err);
          }
    };

    useEffect(()=>{
        fetchReports();
    },[]);

    const handleFirstOpen = (report) => async () => {
      if (report.Unread > 0) {
        try {
          await axios.post(`contact/nonCriticalReport/markResponsesAsRead`, {
              nonCriticalReportId: report.nonCriticalReportId,
              accountId: userAccountId
          });
          let updatedReport = { ...report, Unread: 0 };
          let updatedReports = reports.map((r) =>
            r.nonCriticalReportId === report.nonCriticalReportId ? updatedReport : r
          );
          dispatch(setFormField({ key: "reports", value: updatedReports }));

          // fetchResponses();
          } catch (err) {
              console.error('Failed to send patient note:', err);
          }
      }
      return;
    };
    

    return (
        <div className={styles.ViewContainer}>
            <FilterPatientView isSearch={true} isDate={true} isReport={true}/>
            {sortedReports.map((report, index) => {
                let unreadlabel = {
                    name: report.Unread,
                    color: "#9F1919"
                };
                let openlabel = {
                  name: "Open",
                  color: "#634D02"
                };
                let newlabel = {
                  name: "New",
                  color: "#1F5C29"
                };
                let Resolvedlabel = {
                    name: "Resolved",
                    color: "#686869"
                };
                const formattedDate = format(new Date(report.createdDate), "MMMM do, yyyy");

                return (
                    <div key={index}>
                        <PatientDetailDropdownCard 
                            title={report.title || "Non Critical Report"} 
                            subtitle2={formattedDate}
                            label={report.status === "Resolved" ? Resolvedlabel : report.Unread !== 0 ? unreadlabel : report.responses.length !== 0 ? openlabel : newlabel}
                            cardHeight="auto"
                            cardInfo={<ReportsDetails data={report}/>}
                            onFirstOpen={handleFirstOpen(report)}
                        />
                    </div>
                )
            })
            }
        </div>
    );
}

export default ReportsView;