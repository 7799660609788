import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import useAxios from "../../services/AxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styles from './Appointment.module.css';
import { AddTimeIcon, TrashIcon } from "../../components/icons/Icons";
import ManagementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice'; // Import showAlert

const daysOfWeek = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

const convertTo24Hour = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

const convertTo12Hour = (time24h) => {
  let [hours, minutes] = time24h.split(":");
  hours = parseInt(hours, 10);
  const modifier = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${modifier}`;
};

const generateTimeOptions = () => {
  const options = [];
  for (let hour = 6; hour < 24; hour++) {
    const time12h = `${hour % 12 || 12}:00 ${hour < 12 ? "AM" : "PM"}`;
    options.push(
      <option key={time12h} value={time12h}>
        {time12h}
      </option>
    );
  }
  options.push(
    <option key="11:59 PM" value="11:59 PM">
      11:59 PM
    </option>
  );
  
  return options;
};

const AvailabilityComponent = ({
  show,
  onHide,
  selectedPhysicianId,
  physicians,
  role,
  canManageAvailability
}) => {
  const [availability, setAvailability] = useState([]);
  const [validationError, setValidationError] = useState(false);

  const allowedRoles = ["Group Admin", "Super Admin"];
  const showPhysicianSelection = allowedRoles.includes(role) || canManageAvailability;

  const [selectedPhysician, setSelectedPhysician] = useState(
    showPhysicianSelection ? "" : selectedPhysicianId
  );

  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        if (!selectedPhysician) return; // Prevent fetching if no physician is selected

        const response = await httpService.get(
          `appointment/availability/${selectedPhysician}`
        );
        if (response.data && Array.isArray(response.data.data)) {
          const fetchedAvailability = response.data.data.map((item) => ({
            ...item,
            start: convertTo12Hour(item.start),
            end: convertTo12Hour(item.end),
            day: daysOfWeek.find((day) => day.value === item.day).name,
          }));
          setAvailability(fetchedAvailability);
        } else {
          setAvailability([]);
          console.log("No availability data found or data format incorrect");
        }
      } catch (error) {
        console.error("Error fetching availability:", error);
        setValidationError(true);
      }
    };

    fetchAvailability();
  }, [selectedPhysician, httpService]);

  const addTimeSlot = (dayValue) => {
    const dayName = daysOfWeek.find((day) => day.value === dayValue).name;
    const newSlot = {
      day: dayName,
      dayValue: dayValue, // For saving
      start: "",
      end: "",
      physicianAvailabilityId: uuidv4(),
    };
    setAvailability((prev) => [...prev, newSlot]);
  };

  const handleTimeChange = (id, field, value) => {
    setAvailability((prev) =>
      prev.map((slot) =>
        slot.physicianAvailabilityId === id ? { ...slot, [field]: value } : slot
      )
    );
  };

  const deleteTimeSlot = (id) => {
    setAvailability((prev) =>
      prev.filter((slot) => slot.physicianAvailabilityId !== id)
    );
  };

  const handleSubmit = () => {
    if (!selectedPhysician) {
      alert("Please select a physician before submitting.");
      return;
    }

    const isValid = availability.every((slot) => {
      const start = new Date(`1970/01/01 ${convertTo24Hour(slot.start)}`);
      const end = new Date(`1970/01/01 ${convertTo24Hour(slot.end)}`);
      return start < end;
    });

    if (!isValid) {
      setValidationError(true);
      return;
    }

    const submissionData = availability.map((slot) => ({
      ...slot,
      day: daysOfWeek.find((d) => d.name === slot.day).value,
      start: convertTo24Hour(slot.start),
      end: convertTo24Hour(slot.end),
    }));

    const url = `appointment/availability/${selectedPhysician}`;
    const method = "POST";

    httpService
      .request({
        method,
        url,
        data: submissionData,
      })
      .then((response) => {
        dispatch(
          showAlert({
            header: "Success",
            message: "Availability saved successfully",
            type: 'success',
          })
        );
        onHide();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        dispatch(
          showAlert({
            header: "Error",
            message: "Failed to save availability",
            type: 'danger',
          })
        );
      });
  };

  return (
    <Modal style={{fontFamily:"Roboto"}} show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:"20px", color:"#4F4F4F", fontWeight:"700", letterSpacing:"0.03px"}}>Setup Availability</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:"16px"}}>
        {showPhysicianSelection && (
          <>
          <Form.Group as={Col} controlId="formGridPhysician">
            <Form.Select
              value={selectedPhysician}
              onChange={(e) => setSelectedPhysician(e.target.value)}
            >
              <option value="">Select a Physician</option>
              {physicians.map((physician) => (
                <option key={physician.value} value={physician.value}>
                  {physician.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <br />
          </>
        )}
        {validationError && (
          <Alert variant="danger">
            Please ensure all end times are after start times and all time slots
            are filled correctly.
          </Alert>
        )}
        {daysOfWeek.map((day) => (
          <React.Fragment key={day.value}>
            <Row className="align-items-center mb-3">
              <Col xs={8}>
                <div className="subtitle-2" style={{color:"#4F4F4F"}}>{day.name}</div>
              </Col>
              <Col xs={4} className="text-end">
                <Button
                  className={styles.AddTimeSlotButton}
                  variant="outline-primary"
                  size="sm"
                  onClick={() => addTimeSlot(day.value)}
                >
                  <div style={{display:"flex", alignItems:"center", columnGap:"10px"}}>
                    <AddTimeIcon />
                    Add Time
                  </div>
                </Button>
              </Col>
            </Row>
            {availability
              .filter((slot) => slot.day === day.name)
              .map((slot, index) => (
                <Row
                  key={slot.physicianAvailabilityId}
                  className="align-items-center mb-2"
                >
                  <Col xs={2}></Col>
                  <Col xs={4} className="offset-xs-2">
                    <Form.Select
                      style={{width:"120px", padding:"10px 12px", color:"#4F4F4F", fontWeight:"500"}}
                      value={slot.start}
                      onChange={(e) =>
                        handleTimeChange(
                          slot.physicianAvailabilityId,
                          "start",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Start Time</option>
                      {generateTimeOptions()}
                    </Form.Select>
                  </Col>
                  <Col xs={4}>
                    <Form.Select
                      style={{width:"120px", padding:"10px 12px", color:"#4F4F4F", fontWeight:"500"}}
                      value={slot.end}
                      onChange={(e) =>
                        handleTimeChange(
                          slot.physicianAvailabilityId,
                          "end",
                          e.target.value
                        )
                      }
                    >
                      <option value="">End Time</option>
                      {generateTimeOptions()}
                    </Form.Select>
                  </Col>
                  <Col xs={2}>
                    <Button
                      className={styles["delete-button"]}
                      variant="danger"
                      size="sm"
                      onClick={() =>
                        deleteTimeSlot(slot.physicianAvailabilityId)
                      }
                    >
                      <TrashIcon />
                    </Button>
                  </Col>
                </Row>
              ))}
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button className={ManagementStyles.BackButton} variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button className={ManagementStyles.CreateButton} variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AvailabilityComponent;
