import React, {useState, useEffect} from 'react';
import ModalityMeasurementSelection from './ModalityMeasurementSelection';
import inputStyles from '../views/program-management/ProgramManagement.module.css';
import styles from './ManagementDropdownCard.module.css';
import toggleStyles from './CustomToggle.module.css';
import { EnrollmentDropdowns } from './EnrollmentDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PerinPatchModalityTypeSection = (props) => {
    const {
        modalityVitals,
        selectedVital,
        handleChange,
        updateStartTime,
        handleContinuousChange,
        handleFrequency,
        handleIntervalChange,
        viewOnly,
        errorShowing,
        disabled,
        allDevices,
        modalityState,
        setModality,
        allSameStartTime,
        handleAllSameStartTime
    } = props;

    const data = modalityState.find((m) => m.deviceModalityId === modalityVitals[0].deviceModalityId);
    const [continuous, setContinuous] = useState(data.isContinuous);

    const initStartTime = () => {
        let startStr = data.startTime;
        let startInt = 0;
        let interval = "AM";
        if (startStr !== ""){
            if (!startStr.includes('Z')) {
                startStr = startStr + '.000Z';
            }
            const localDate = new Date(startStr);
            const hour = localDate.getHours();
            startInt = hour % 12 || 12;
            interval = hour >= 12 ? "PM" : "AM";
        }
        return {startInt:startInt, interval:interval};
    };

    let [startTimeView, setStartTimeView] = useState(initStartTime);

    const handleStartTime = (e) => {
        let name = e.target.id;
        let value = e.target.value;
        // check if the value is between 0-24
        const regex = /^(0?[0-9]|1[0-2])$/;
        if (name === "startInt") {
            if (value === "" || !(regex.test(value))) {
                value = -1;
            } else {
                value = Number(value);
            }
        }
        setStartTimeView({
            ...startTimeView,
            [name]: value
        });
        updateStartTime(name, value, startTimeView);
    };

    const toggleContinuous = () => {
        const newContinuous = !continuous;
        setContinuous(newContinuous)
        handleContinuousChange(newContinuous)
    };

    const toggleAllSameStartTime = () => {
        handleAllSameStartTime();
    };

    useEffect(()=> {
        setStartTimeView(initStartTime)
    },[data.startTime])

    let frequencyErrorMessage = "Please provide a valid interval: \n Day: 1-365, Min: 1-60, Hour: 1-24"
    let frequencyErrorShowing = errorShowing.showing && !data.isContinuous && ( data.frequency <= 0 ||
        (data.interval === "Day" && data.frequency > 365) ||
        (data.interval === "Min" && data.frequency > 60) ||
        (data.interval === "Hour" && data.frequency > 24)
    );

    let startTimeErrorMessage = "Please provide a valid start time"
    let startTimeErrorShowing = errorShowing.showing && data.startTime === "";

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <span className={styles.Interval}>Interval</span>
            <div className={styles.IntervalRow}>
                <span className={styles.Label} style={{width:"130px"}}>
                    {`Start Time${!viewOnly ? "*" : ":"}`}
                </span>
                {viewOnly ?
                    <div>
                        {`${startTimeView.startInt} ${startTimeView.interval}`}
                    </div>
                    :
                    <>
                        <input className={inputStyles.InputStyling} 
                            style={(errorShowing.showing && data.startTime === "") ? {border:"1px solid red", width:"70px"} : {width:"70px"}}
                            type="number"
                            id="startInt"
                            disabled={disabled}
                            value={startTimeView.startInt}
                            onChange={handleStartTime}
                        ></input>
                        <select className={inputStyles.InputStyling} 
                            style={(errorShowing.showing && data.startTime === "") ? {border:"1px solid red", width:"120px"} : {width:"120px"}}
                            id="interval"
                            disabled={disabled}
                            value={startTimeView.interval}
                            onChange={handleStartTime}
                        >
                            {EnrollmentDropdowns.timeIntervals.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <input type="checkbox"
                            checked={allSameStartTime}
                            onChange={()=>toggleAllSameStartTime()}
                            disabled={disabled}
                        ></input>
                        <span className={styles.Label} style={{marginLeft:"-5px"}}>Apply to All</span>
                    </>}
            </div>
            {startTimeErrorShowing && 
                <span className={inputStyles.ValidationMessage}>
                    {startTimeErrorMessage}
                </span>
            }
            <div className={styles.IntervalRow}>
                <span className={styles.Label} style={{width:"130px"}}>
                    {`Repeat Every${!viewOnly ? "*" : ":"}`}
                </span>
                {viewOnly ?
                    <div>
                        {continuous ? "Continuous" : `${data.frequency} ${data.interval}`}
                    </div>
                    :
                    <>
                        {data.interval === "Min" ?
                            <select className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && frequencyErrorShowing) ? {border:"1px solid red", width:"70px"} : {width:"70px"}}
                                disabled={continuous || disabled}
                                id="frequency"
                                value={data.frequency}
                                onChange={handleFrequency}
                            >
                                {[5,10,15,20,25,30,35,40,45,50,55].map((i)=> 
                                    <option key={i} value={i}>
                                        {i}
                                    </option>
                                )}
                            </select>
                        :
                            <input 
                                className={inputStyles.InputStyling} 
                                style={(errorShowing.showing && frequencyErrorShowing) ? {border:"1px solid red", width:"70px"} : {width:"70px"}}
                                value={data.frequency}
                                id="frequency"
                                maxLength={3}
                                disabled={continuous || disabled}
                                onChange={handleFrequency}
                            ></input>
                        }
                        <select className={inputStyles.InputStyling} 
                            style={(errorShowing.showing && frequencyErrorShowing) ? {border:"1px solid red", width:"120px"} : {width:"120px"}}
                            disabled={continuous || disabled}
                            id="interval"
                            value={data.interval}
                            onChange={handleIntervalChange}
                        >
                            {EnrollmentDropdowns.repeatIntervals.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <input type="checkbox"
                            checked={continuous}
                            onChange={()=>toggleContinuous()}
                            disabled={disabled}
                        ></input>
                        <span className={styles.Label} style={{marginLeft:"-5px"}}>Continuous</span>
                    </>
                }
            </div>
            <div style={{width:"auto"}}>
                <div className={toggleStyles.Toggle}>
                {modalityVitals.map((vital) => 
                    <React.Fragment key={vital.deviceModalityId}>
                        <input 
                            type="radio" 
                            name="customToggle" 
                            value={vital.deviceModalityId}
                            checked={selectedVital.deviceModalityId===vital.deviceModalityId}
                            onChange={()=>handleChange(vital)}
                        ></input>
                        <label onClick={()=>handleChange(vital)}> {vital.modalityName}</label>
                    </React.Fragment>
                )}
                </div>
            </div>
            <div>
                {modalityVitals.map((item, index) => (
                    <div key={index} hidden={item.deviceModalityId===selectedVital.deviceModalityId ? false : true}>
                        <ModalityMeasurementSelection 
                            deviceId={1} //perin patch id
                            deviceModalityId={item.deviceModalityId} 
                            setModality={setModality} 
                            dataList={modalityState} 
                            disabled={disabled || viewOnly} 
                            errors={errorShowing}
                            allDevices={allDevices}
                            isPerinPatch={true}
                        />
                    </div>
                ))}
            </div>
            {frequencyErrorShowing &&
                <span className={inputStyles.ValidationMessage}>
                    {frequencyErrorMessage}
                </span>
            }
            {errorShowing.modalityShowing && errorShowing.deviceIdErrors.some(item => item === 1) &&
                <div className={inputStyles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{color:"red"}}/>
                    <span className={inputStyles.ValidationMessage}>
                        {errorShowing.modalityMessage}
                    </span>
                </div>
            }
            <div style={{padding:"1px"}}></div>
        </div>
    )
}

export default PerinPatchModalityTypeSection;