import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import managementStyles from '../ManagementPages.module.css';
import CustomTasksToggle from '../../components/CustomTasksToggle'
import { setCompletedTaskTypeCounts, setDataList, setFilterItem, setPendingTaskTypeCounts, setReloadTaskList } from './TasksSlice';
import TaskListPanel from './TaskListPanel';
import TaskFilterView from './TaskFilterView';
import useAxios from '../../services/AxiosConfig';
import { setTaskListCount } from '../../slicers/sidebarSlice';

const Tasks = () => {
    const dispatch = useDispatch();
    const taskListPanel = useSelector((state) => state.taskListPanel);
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);
    const userRole = useSelector((state) => state.userInfo.roleCategory);
    const userId = useSelector((state) => state.userInfo.id);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const staffTasksResponse = await axios.get(`staffTasks/list/${userId}`);
                if (staffTasksResponse.data.data) {
                    const tasksWithChecked = staffTasksResponse.data.data.map(task => {
                        const dueDate = new Date(task.dueDate);
                        const currentDate = new Date();
                        const isDueDatePassed = dueDate < currentDate;
                        const isDueSoon = !isDueDatePassed && (dueDate - currentDate) <= 24 * 60 * 60 * 1000;

                        return {
                            ...task,
                            checked: false,
                            isDueDatePassed,
                            isDueSoon,
                        };
                    });

                    tasksWithChecked.sort((a, b) => {
                        const dateA = new Date(a.dueDate);
                        const dateB = new Date(b.dueDate);

                        // Prioritize tasks with passed due dates
                        if (a.isDueDatePassed && !b.isDueDatePassed) return -1;
                        if (!a.isDueDatePassed && b.isDueDatePassed) return 1;

                        // Sort by due date (descending order)
                        if (dateA < dateB) return 1;
                        if (dateA > dateB) return -1;

                        // If dates are the same, sort by type (assuming type is a string)
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;

                        return 0;
                    });

                    const newCount = tasksWithChecked.filter(task => task.status === 'Pending').length;
                    dispatch(setTaskListCount(newCount));
                    dispatch(setDataList(tasksWithChecked));
                    const pendingCount = tasksWithChecked?.filter(task => task.status === 'Pending').length;
                    dispatch(setPendingTaskTypeCounts(pendingCount));
                    const completedCount = tasksWithChecked?.filter(task => task.status === 'Completed').length;
                    dispatch(setCompletedTaskTypeCounts(completedCount));
                    dispatch(setReloadTaskList(false));

                } else {
                    console.error('Error fetching Task List');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        if (userId && taskListPanel.reloadTaskList) {
            fetchData();
        }
    }, [userId, taskListPanel.reloadTaskList, dispatch]);

    const handleToggle = (value) => {
        dispatch(setFilterItem({ key: "filter", value: value }));
        const count = taskListPanel?.dataList?.filter(task => task.status === value).length;
        if (value === 'Pending')
            dispatch(setPendingTaskTypeCounts(count));
        else if (value === 'Completed')
            dispatch(setCompletedTaskTypeCounts(count));
    };

    return (
        <div className={managementStyles.MainContent}>
            {/* <div className={managementStyles.Title}>{`${taskListPanel?.filter?.value} Tasks (${taskListPanel?.taskTypeCounts})`}</div> */}
            <div className={managementStyles.MiddleContent}>
                <div style={{ width: '60vw' }}>
                    <CustomTasksToggle label1="Pending" label2="Completed" handleToggle={handleToggle} />
                    <TaskFilterView />
                    <TaskListPanel />
                </div>
            </div>
        </div>
    );
}

export default Tasks;