import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import { setDataList, setSelectAll, setSelectedItem, setSelectedRows } from '../../components/PerinHealthGridSlice';
import styles from './OrganizationManagement.module.css';
import { useNavigate } from 'react-router-dom';
import FilterManagementView from '../../components/FilterManagementView';
import managementStyles from '../ManagementPages.module.css';
import useAxios from '../../services/AxiosConfig';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const OrganizationManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL)
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    const {isAdmin, id} = useSelector((state) => state.userInfo);

    const headers = [
        { key: 'organizationId', label: "ID" },
        { key: 'organizationName', label: "Organization" }
    ];

    useEffect(() => {
        dispatch(setSelectedItem({}));
        getOrganizationById();  
    }, []);

    const getOrganizationById = () => {
        let groupsApi = isAdmin ? "admin/groups" : `list/groups/${id}`;
         axios.get(groupsApi)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const updatedDataList = responseData.data.data.map((item, index) => {
                        if (!item.id) {
                            return { ...item, id: index };
                        }
                        return item;
                    });
                
                    dispatch(setDataList(updatedDataList));
                   // dispatch(setDataList(responseData.data.data));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            }); 
    }

    const onRowClickHandler = (rowItem) => {
        dispatch(setSelectedItem(rowItem));
        navigate('/new-organization-management');
    }
    const onDeleteHandler = () => {
        const deletePromises = perinHealthGrid.selectedRows.map((selectedItem) => {
            const itemId = selectedItem.id;
            console.log(`Deleting row with ID ${itemId}`);
            return axios.delete(`content/${itemId}`);
        });

        Promise.all(deletePromises)
            .then((responses) => {
                console.log('Successfully deleted selected rows:', responses);
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getOrganizationById();
                dispatch(
                    showAlert({
                        header: "Success",
                        message: `Deleted organizations successfully`,
                        type: 'success'
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting rows:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: `Error deleting organizations`,
                        type: 'danger'
                    })
                );
            });
    }

    const onAddOrganizationHandler = () => {
        navigate('/new-organization-management');
    };

    return (
        <div className={managementStyles.MainContent}>
            {/* <div className={managementStyles.Title} >
                <span className={styles.label}>Organization Hierarchy Management</span>
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView 
                    addButtonLabel={'Add Org Hierarchy'} 
                    onAddHandler={onAddOrganizationHandler} 
                    onDeleteHandler={onDeleteHandler} 
                    showDeleteButton={false}
                    showAddButton={false}
                />
                <PerinHealthGrid
                    dataList={perinHealthGrid.dataList}
                    headers={headers}
                    onRowValueClick={onRowClickHandler}
                    gridHeight="100%"
                    rowValueAction={'organizationName'}
                />
            </div>
        </div>
    );
};

export default OrganizationManagement;
