import React, { useCallback, useEffect, useState } from "react";
import { Form, Button, FloatingLabel, CloseButton } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
    setUserRole,
    setUserGroups,
    setUserUniqueGroups,
    setUserOrganizations,
    setOrganizationsHierarchy,
    setTokens,
    setPermissions,
    setUser,
    setPreferences
} from '../slicers/UserSlice';
import '../components/AccountManagement/Accounts.css';
import styles from '../views/account-management/AccountManagement.module.css';
import useAxios from "../services/AxiosConfig";
import inputStyles from './ManagementPages.module.css';
import { showAlert } from "../components/PerinHealthAlertSlice";
import { closeTimeOutAlert } from "../slicers/TimeOutAlertSlice";
import PerinHealthAlert from '../components/PerinHealthAlert';
import { addUnreadPatientMessageId, clearUnreadPatientMessageIds, setNewStaffMessageCount, setTaskListCount } from "../slicers/sidebarSlice";
import { resetAppTourState, setFieldValue } from "./app-tour/AppTourSlice";
import { setSelectedMenuItem } from '../slicers/sidebarSlice';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);

    const timeOutAlert = useSelector((state) => state.timeOutAlert);
    const perinHealthAlert = useSelector((state) => state.perinHealthAlert);

    const [showPassword, setShowPassword] = useState(false);
    const [userLogin, setUserLogin] = useState({
        username: "",
        password: ""
    });
    const [mfaCode, setMfaCode] = useState('');
    const [session, setSession] = useState(null);
    const [challengeName, setChallengeName] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(()=> {
        checkRememberMe();
    },[])


    const storeRememeberMe = () => {
        try {
            localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
            if (rememberMe) {
                localStorage.setItem('username', JSON.stringify(userLogin.username));
            } else {
                localStorage.setItem('username', JSON.stringify(""));
            }
        } catch (error) {
            console.error('Error storing email for remember me:', error);
        }
    };
    
    const checkRememberMe = () => {
        try {
            const rememberMe = Boolean(JSON.parse(localStorage.getItem('rememberMe')));
            if (rememberMe) {
                const username = JSON.parse(localStorage.getItem('username'));
                setUserLogin({...userLogin, "username": username})
            }
            if (rememberMe !== null) {
                setRememberMe(rememberMe);
            }
        } catch (error) {
            console.error('Error getting stored email fo rmemerme:', error);
            return null;
        }
    };

    const handleChange = (e) => {
        const { id, type, checked, value } = e.target;
        setUserLogin((prevUserLogin) => ({
            ...prevUserLogin,
            [id]: type === "checkbox" ? checked : value,
        }));
    };

    const handleLandingPage = (role) => {
        const landings = {
            "Super Admin": '/organization-management',
            "Admin": '/account-management',
            "Patient": '/patient-message',
        };
        const sideBarName = {
            "Super Admin": 'Organization Hierarchy Management',
            "Admin": 'Account Management',
            "Patient": 'Patient Messages',
        };
        if (landings[role.category]) {
            dispatch(setSelectedMenuItem(sideBarName[role.category]));
            navigate(landings[role.category]);
        } else {
            dispatch(setSelectedMenuItem('Patient Dashboard'));
            navigate('overview-dashboard');
        }
    };

    const handleUniqueOrganizations = (data) => {
        let uniqueOrgNames = new Set();
        let tempGroups = [];
        dispatch(setOrganizationsHierarchy(data));
        data.forEach(org => {
            uniqueOrgNames.add(org.organizationName);
            org.groups.forEach(group => {
                if (group.groupId !== null)
                    tempGroups.push({
                        name: group.groupName,
                        id: group.groupId,
                    });
            });
        });
        let tempOrgs = Array.from(uniqueOrgNames).map(orgName => ({
            name: orgName,
            id: data.find(org => org.organizationName === orgName).organizationId,
        }));

        dispatch(setUserOrganizations(tempOrgs));
        dispatch(setUserUniqueGroups(tempGroups));
    };

    const handleGroups = async (data, tokens) => {
        let groupsApi = data.role.name === "Super Admin" ? "admin/groups" : `list/groups/${data.id}`;
        axios.get(groupsApi, {
            headers: {
                authorization: `${tokens.authorization}`,
                refresh: `${tokens.refresh}`,
                id: `${tokens.id}`,
            }
        })
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    const groupData = responseData.data.data;
                    handleUniqueOrganizations(groupData);
                    dispatch(setUserGroups(groupData));
                } else {
                    console.error('Invalid data format:');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.request({
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                url: 'signin',
                data: JSON.stringify(userLogin),
            });

            if (response.data.data.ChallengeName && response.data.data.Session) {
                setSession(response.data.data.Session);
                setChallengeName(response.data.data.ChallengeName);
            } else if (response.data.data.user) {
                storeRememeberMe()
                handleSuccessfulLogin(response.data.data);
            }
        } catch (error) {
            dispatch(
                showAlert({
                    header: "Error signing in",
                    message: "signin error",
                    type: 'danger',
                })
            );
        }
    };

    const handleMfaSubmit = async () => {
        try {
            const response = await axios.request({
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                url: 'verify-mfa',
                data: JSON.stringify({ username: userLogin.username, session, mfaCode }),
            });

            if (response.data.data.user) {
                handleSuccessfulLogin(response.data.data);
            }
        } catch (error) {
            dispatch(showAlert({
                header: "Error verifying MFA",
                message: "MFA verification error",
                type: 'danger',
            }));
        }
    };

    const handleSuccessfulLogin = async (data) => {
        dispatch(resetAppTourState());
        dispatch(setTokens(data.tokens));
        const userData = data.user;
        dispatch(setPermissions(userData.role.permissions));
        dispatch(setUser(userData));
        dispatch(setUserRole(userData.role));
        if (userData.preferences) {
            dispatch(setPreferences(userData.preferences));
        }
        const islastLoginEmpty = userData.lastLogin === null;
        dispatch(setFieldValue({ name: 'showWelcome', value: islastLoginEmpty }));
        await handleGroups(userData, data.tokens);
        dispatch(closeTimeOutAlert());
        getAllPatientMessageByUserId(userData.id);
        getAllStaffMessageByUserId(userData.id);
        getAllNewStaffTasks(userData.id);
        localStorage.setItem('selectedPhysicianIds', "");
        handleLandingPage(userData.role);
    };

    const getAllPatientMessageByUserId = useCallback(async (userId) => {
        try {
            const responseData = await axios.get(`chime/getAllMessageByUserId?userId=${userId}&recipientType=PATIENT`);
            const messages = responseData.data.getAllMessage || [];

            const uniquePatientIds = new Set();
            messages.forEach(message => {
                message.forEach(item => {
                    if (item.senderId !== userId && !item.readStatus) {
                        uniquePatientIds.add(item.senderId);
                    }
                });
            });

            dispatch(clearUnreadPatientMessageIds());
            uniquePatientIds.forEach(patientId => {
                dispatch(addUnreadPatientMessageId(patientId));
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    const getAllStaffMessageByUserId = useCallback(async (userId) => {
        try {
            const responseData = await axios.get(`chime/getAllMessageByUserId?userId=${userId}&recipientType=STAFF`);
            const messages = responseData.data.getAllMessage || [];
            const allMessages = messages.flat();
            const newCount = allMessages.reduce((count, message) => {
                if (message.senderId === userId) {
                    return count;
                }
                return message.readStatus ? count : count + 1;
            }, 0);
            dispatch(setNewStaffMessageCount(newCount));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

   const getAllNewStaffTasks = useCallback(async (userId) => {
        try {
            const staffTasksResponse = await axios.get(`staffTasks/list/${userId}`);
            if (staffTasksResponse.data.data) {

                const newCount = staffTasksResponse.data.data?.filter(task => task.status === 'Pending').length;
                dispatch(setTaskListCount(newCount));
            }
            else {
                console.error('Error fetching Task List');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    },[]);

    return (
        <div className='background-container'>
            <div className='main-container'>
                {perinHealthAlert.show && (
                    <PerinHealthAlert
                        show={perinHealthAlert.show}
                        type={perinHealthAlert.type}
                        header={perinHealthAlert.header}
                        message={perinHealthAlert.message}
                        dismissable={perinHealthAlert.dismissable}
                    />
                )}
                <div className="login-container">
                    {session ? (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <h2 className="reg-title" style={{ margin: "30px 0 20px 0" }}>Enter MFA Code</h2>
                            <Form.Group>
                                <FloatingLabel
                                    label="MFA Code*"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        id="mfaCode"
                                        value={mfaCode}
                                        style={{ width: "100%" }}
                                        placeholder="MFA Code*"
                                        className={`${inputStyles.BorderedComponent}`}
                                        onChange={(e) => setMfaCode(e.target.value)}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Button className={styles.LoginButton} style={{ margin: "30px 0 20px 0" }} onClick={handleMfaSubmit}>
                                Submit MFA Code
                            </Button>
                        </div>
                    ) : (
                        <>
                            <h2 className="reg-title" style={{ margin: "30px 0 20px 0", width: "80%" }}>Login</h2>
                            {timeOutAlert.show && (
                                <div style={{ width: "80%" }}>
                                    <div className={inputStyles.TimeOutAlertContainer}>
                                        <span className="body-2">You've been signed out due to privacy. Sign back in for secure access.</span>
                                        <CloseButton onClick={() => dispatch(closeTimeOutAlert())} />
                                    </div>
                                </div>
                            )}
                            <Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", align: "left", width: "80%", flex: "1", overflow: "auto" }}>
                                <Form.Group>
                                    <FloatingLabel
                                        label="Email/Phone Number*"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            id="username"
                                            value={userLogin.username}
                                            style={{ width: "100%" }}
                                            placeholder="Email/Phone Number*"
                                            className={`${inputStyles.BorderedComponent}`}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group>
                                    <FloatingLabel
                                        label="Password*"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            className={`${inputStyles.BorderedComponent}`}
                                            style={{ width: "100%" }}
                                            id="password"
                                            placeholder="Password*"
                                            type={
                                                showPassword ? "text" : "password"
                                            }
                                            value={userLogin.password}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className={inputStyles["styled-icon-container"]} onClick={() =>
                                            setShowPassword((prev) => !prev)}
                                        >
                                            {showPassword ? <FontAwesomeIcon icon="fa-regular fa-eye" className="eye-icon" size="lg" />
                                                : <FontAwesomeIcon icon="fa-regular fa-eye-slash" className="eye-icon" size="lg" />}
                                        </div>
                                    </FloatingLabel>
                                </Form.Group>
                                <div style={{ margin: "10px 0 20px 0", display: "flex", justifyContent: "space-between" }}>
                                    <label style={{ display: 'flex', color: '#3B3E40', alignItems: 'center' }}>
                                        <input
                                            id="remember_info"
                                            style={{ width: "20px", height: "20px" }}
                                            type='checkbox'
                                            checked={rememberMe}
                                            onChange={()=>setRememberMe(!rememberMe)}

                                        />
                                        <span style={{ paddingLeft: '8px' }}>
                                            Remember me
                                        </span>
                                    </label>
                                    <p style={{ color: '#1D5769', cursor: 'pointer', margin: "auto 0 auto auto" }} onClick={() => navigate('/forgot-password')}>
                                        Forgot Password
                                    </p>
                                </div>
                                <Button className={styles.LoginButton}
                                    type="submit"
                                >
                                    Login
                                </Button>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Login;
