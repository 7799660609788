import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedRows: [],
    selectedIndex: -1,
    chatList:[],
    recipient:{},
    reloadGrid: true,
    newChat:false,
    isMeetingVisible: false,
    isUpdateMode: false,
    isChatVisible : false,
    reloadMessageList:false,
    receiveMessage: false,
    
};

const patientMessageSlice = createSlice({
    name: 'patientMessage',
    initialState,
    reducers: {
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectedIndex: (state, action) => {
            state.selectedIndex = action.payload;
        },
        setNewChat: (state, action) => {
            state.newChat = action.payload;
        },
        setChatList: (state, action) => {
            state.chatList = action.payload.slice().reverse();
        },
        appendMessageToList: (state, action) => {
            state.chatList.push(action.payload);
          },
        setRecipient: (state, action) => {
            state.recipient = action.payload;
        },
        setFieldValue: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = value;
                }
            });
        },
        setFormValue: (state, action) => {
            const { name, value } = action.payload;
            state[name] = value;
        },
        toggleUpdateMode: (state) => {
            state.isUpdateMode = !state.isUpdateMode;
        },

        setChatVisible : (state, action) => {
            state.isChatVisible = action.payload;
        },

        setIsMeetingVisible : (state, action) => {
            state.isMeetingVisible = action.payload;
        },

        setReloadMessageList : (state, action) => {
            state.reloadMessageList = action.payload;
        },
        setReceiveMessage : (state, action) => {
            state.receiveMessage = action.payload;
        },
        resetPatientMessage: (state) => {
            Object.assign(state, initialState);
        },
        
    },
});

export const {
    setChatList,
    setNewChat,
    setSelectedRows,
    setSelectedIndex,
    setRecipient,
    setFieldValue,
    setFormValue,
    setIsMeetingVisible,
    appendMessageToList,
    resetPatientMessage,
    toggleUpdateMode,
    setChatVisible,
    setReloadMessageList,

} = patientMessageSlice.actions;

export default patientMessageSlice.reducer;
