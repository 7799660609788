import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requestsCount: 0,
  envURL: '', 
  envMode: '',
};

const axiosConfigSlice = createSlice({
  name: 'axiosConfig',
  initialState,
  reducers: {
    incrementRequestsCount: (state) => {
      state.requestsCount += 1;
    },
    decrementRequestsCount: (state) => {
      state.requestsCount -= 1;
    },
    resetRequestsCount: (state) => {
      state.requestsCount = 0;
    },
    setEnvURLAndMode: (state, action) => {
      state.envURL = action.payload.envURL;
      state.envMode = action.payload.envMode;
    },
  },
});

export const {
  incrementRequestsCount,
  decrementRequestsCount,
  resetRequestsCount,
  setEnvURLAndMode,
} = axiosConfigSlice.actions;

export default axiosConfigSlice.reducer;
