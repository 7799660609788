import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Button, CloseButton, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { AttachmentIcon, UserProfilePictureIcon, VideoCallChatIcon, VoiceCallChatIcon } from './icons/Icons';
import styles from './VideoAndMessage.module.css';
import { appendMessageToList, setChatList, setNewChat, setRecipient, setReloadMessageList } from '../views/patient-message/PatientMessageSlicer';
import ChatDisplayContainer from './ChatDisplayContainer';
import useAxios from '../services/AxiosConfig';
import { appendMessageToStaffList, setReloadStaffMessageList, setStaffChatList, setStaffNewChat, setStaffRecipient } from '../views/care-team-message/CareTeamMessageSlicer';
import { useWebSocket } from '../services/webSocket/useWebSocket';

const ChatContainer = ({ view, onStartVideoCall, onStartAudioCall, handleClose, showActions = true }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isUserSelected, setIsUserSelected] = useState(false);

  const [attachments, setAttachments] = useState([]);
  const [userList, setUserList] = useState([]);
  const fileInputRef = useRef(null);

  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL, dispatch);

  const userInfoState = useSelector((state) => state.userInfo);
  const userId = userInfoState.id;
  const userRole = userInfoState.roleName;

  const patientMessage = useSelector((state) => state.patientMessage);
  const staffMessage = useSelector((state) => state.careTeam);
  const chatList = view === "staffMessage" ? staffMessage.chatList : patientMessage.chatList;
  const newChat = view === "staffMessage" ? staffMessage.newChat : patientMessage.newChat;
  const recipient = view === "staffMessage" ? staffMessage.recipient : patientMessage.recipient;
  const { sendMessage, receivedMessage } = useWebSocket();

  useEffect(() => {
    const getUsers = async () => {
      let api = 'careprogram/patient/list/' + userId;
      let groupId = userInfoState?.groups[0]?.groups[0]?.groupId;
      if (view === "staffMessage") {
        if (userRole === "Super Admin") {
          groupId = 0;
        }
        api = 'list/staffAdmin/' + groupId
      }
      httpService.get(api)
        .then((responseData) => {
          if (Array.isArray(responseData.data.data)) {
            let formatted = [];
            if (view === "staffMessage") {
              formatted = formatStaffUsers(responseData.data.data, userId);
            } else {
              formatted = formatPatientUsers(responseData.data.data, userId);
            }
            setUserList(formatted);
          } else {
            console.error('Invalid data format:');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getUsers();
  }, [0]);

  const formatPatientUsers = (data, userId) => {
    const userIdSet = new Set();
    let newFormattedUserList = [];
    if (data.length >= 1) {
      newFormattedUserList = data.reduce((acc, user) => {
        if (user.id !== userId && !userIdSet.has(user.id)) {
          userIdSet.add(user.id);
          let groups = [];
          let subGroups = [];
          let teams = [];
          let groupIds = [];
          let subGroupIds = [];
          let teamIds = [];
          let organizationIds = [];
          if (user.groups) {
            user.groups.forEach(group => {
              if (group.groupName && !groups.includes(group.groupName)) {
                groups.push(group.groupName);
                groupIds.push(group.groupId);
              }
              if (group.subGroupName && !subGroups.includes(group.subGroupName)) {
                subGroups.push(group.subGroupName);
                subGroupIds.push(group.subGroupId);
              }
              if (group.teamName && !teams.includes(group.teamName)) {
                teams.push(group.teamName);
                teamIds.push(group.teamId);
              }
              if (group.organizationId) {
                organizationIds.push(group.organizationId);
              }
            });
          }
          acc.push({
            ...user,
            groups: groups,
            subGroups: subGroups,
            teams: teams,
            organizationIds: organizationIds,
            groupIds: groupIds,
            subGroupIds: subGroupIds,
            teamIds: teamIds,
            fullName: user.lastName + ", " + user.firstName,
            label: user.firstName + " " + user.lastName,
            value: user.id,
            roleName: user.role.name
          });

        }
        return acc;
      }, []);
    }
    return newFormattedUserList;
  };

  const formatStaffUsers = (users, userId) => {
    return users.filter(user => user.id !== userId).map(user => {
      return {
        ...user,
        fullName: `${user.lastName}, ${user.firstName}`,
        label: `${user.firstName} ${user.lastName}`,
        value: user.id
      };
    });
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setAttachments([...attachments, ...files]);
  };

  const handleAttachmentIconClick = () => {
    fileInputRef.current.click();
  };

  const removeAttachment = (indexToRemove) => {
    setAttachments(attachments.filter((_, index) => index !== indexToRemove));
  };

  const handleRecipientChange = (selectedOption) => {
    setIsUserSelected(true);
    if (view === "staffMessage") {
      dispatch(setStaffNewChat(false));
      dispatch(setStaffRecipient({ 'id': parseInt(selectedOption.id), name: selectedOption.label, role: selectedOption.roleName }));
    } else {
      dispatch(setNewChat(false));
      dispatch(setRecipient({ 'id': parseInt(selectedOption.id), name: selectedOption.label, role: selectedOption.roleName }));
    }

  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 part
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const send = useCallback(async () => {

    const attachmentsData = await Promise.all(
      attachments.map(async (file) => ({
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        data: await readFileAsBase64(file),
      }))
    );

    const messageData = {
      senderId: userId,
      recipientId: recipient?.id,
      message,
      senderName: `${userInfoState.firstName} ${userInfoState.lastName}`,
      recipientName: recipient?.name,
      messageChannelArn: chatList[0]?.channelArn || null,
      attachments: attachmentsData,
      recipientType: view === "staffMessage" ? "STAFF" : "PATIENT",
      type: userRole === 'Patient' ? "messageFromPatient" : recipient.role === 'Patient' ? "messageFromPhysician" : "messageFromStaff",
      physicianId: userRole !== 'Patient' ? userId : recipient.id,
      patientId: userRole === 'Patient' ? userId : recipient.id,
    };

    sendMessage(messageData);
    setAttachments([]);
    setMessage('')
    //Add 1 second delay before reloading message list
    setTimeout(() => {
      if (view === "staffMessage") {
        dispatch(setReloadStaffMessageList(true));
      } else {
        dispatch(setReloadMessageList(true));
      }
    }, 1000); 
  }, [sendMessage]);

  const currentTime = new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });


  return (
    <div className={styles.ChatMainContainer}>
      {!newChat && (<div className={styles.TopSection}>
        <div className={styles.Username}>
          <div className={`${styles.iconContainer} ${styles.icon}`}>
            <span className={styles.initials}>
              {`${recipient?.name?.split(' ')[0][0] ?? ""}${recipient?.name?.split(' ')[1][0] ?? ""}`}
            </span>
            <UserProfilePictureIcon />
          </div>
          <span className={styles.chatName}>{recipient?.name}</span>
        </div>
        {showActions &&
          <div className={styles.Actions}>
            <Button variant="light" onClick={onStartVideoCall} >
              <VideoCallChatIcon />
            </Button>
            <Button variant="light" onClick={onStartAudioCall} >
              <VoiceCallChatIcon />
            </Button>
            <CloseButton onClick={handleClose} className={styles.CloseButton} />
          </div>
        }
      </div>)}
      {newChat && (<div className={styles.TopSection}>
        <div className={styles.UserSelect}>
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="color"
            options={userList}
            onChange={handleRecipientChange}
          /></div>
        <div className={styles.Actions}>
          <CloseButton onClick={handleClose} className={styles.CloseButton} />
        </div>
      </div>)}
      <hr></hr>
      <ChatDisplayContainer messages={chatList} />
      <div className={styles.AttachedFiles}>
        {attachments.map((file, index) => (
          <div key={index} className={styles.AttachedFile}>
            <span>{file.name}</span>
            <Button variant="link" onClick={() => removeAttachment(index)}>Remove</Button>
          </div>
        ))}
      </div>
      <hr style={{ height: '.5px' }}></hr>
      <div className={styles.BottomSection}>
        <Button variant="white" onClick={handleAttachmentIconClick}>
          <AttachmentIcon />
        </Button>
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleAttachmentChange}
          ref={fileInputRef}
        />

        <Form.Control
          type="text"
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              send();
            }
          }}
        />
        <Button className={styles.sendButton} onClick={send}> <FontAwesomeIcon icon="fas fa-paper-plane" /></Button>
      </div>
    </div>
  );
};

export default ChatContainer;
