import React, { useState, useEffect } from 'react';
import PerinHealthGrid from '../../components/PerinHealthGrid';
import styles from '../role-management/RoleManagement.module.css';
import { setSelectAll, setSelectedItem, setSelectedRows } from '../../components/PerinHealthGridSlice';
import { resetFilterState } from '../../slicers/FilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../services/AxiosConfig';
import FilterManagementView from '../../components/FilterManagementView';
import { closeModal } from '../../components/PerinHealthModelSlice';
import managementStyles from '../ManagementPages.module.css';
import { showAlert } from '../../components/PerinHealthAlertSlice';

const RoleManagement = () => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);
    useEffect(() => {
        dispatch(resetFilterState());
        dispatch(setSelectedRows([]));
        dispatch(setSelectedItem({}));
    }, []);
    const navigate = useNavigate();
    const userId = useSelector((state) => state.userInfo.id);
    const filter = useSelector((state) => state.filter);
    const roleManagementState = useSelector((state) => state.contentManagement);
    const perinHealthGrid = useSelector((state) => state.perinHealthGrid);
    let selectedRows = perinHealthGrid.selectedRows;

    const headers = [
        { key: 'id', label: "ID" },
        { key: 'name', label: "Name" },
        { key: 'description', label: "Description"},
        { key: 'organizationName', label: "Organization"},
        { key: 'createdAt', label: "Date Created"}
    ];

    const [roleData, setRoleData] = useState([]);

    useEffect(() => {
        getRoles();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
      };
      
      const getRoles = async () => {
        dispatch(resetFilterState());
        httpService.get(`role/list/${userId}`)
          .then((responseData) => {
            if (Array.isArray(responseData.data.data)) {
              const formattedRoles = responseData.data.data.map(role => ({
                ...role,
                createdAt: formatDate(role.createdAt),
              }));
              setRoleData(formattedRoles);
            } else {
              console.error('Invalid data format:');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      
    

    const onAddRoleHandler = () => {
        navigate('/add-edit-role');
    };

    const onRowClickHandler = (rowItem) => {
        navigate(`/add-edit-role`, { state: { editRoleData: rowItem } });
    };

    const onDeleteHandler = () => {
        const deletePromises = selectedRows.map((role) => {
            const id = role.id;
            return httpService.delete(`role/${id}`);
        });
    
        Promise.all(deletePromises)
            .then(() => {
                // Filter out the deleted roles from the roleData state
                const updatedRoleData = roleData.filter(role => !selectedRows.some(row => row.id === role.id));
                setRoleData(updatedRoleData);
    
                dispatch(setSelectedRows([]));
                dispatch(setSelectAll(false));
                getRoles();
                dispatch(closeModal());
                dispatch(
                    showAlert({
                        header: "Success",
                        message: "Deleted roles successfully",
                        type: 'success',
                    })
                );
            })
            .catch((errors) => {
                console.error('Error deleting roles:', errors);
                dispatch(
                    showAlert({
                        header: "Error",
                        message: "Error deleting roles",
                        type: 'danger',
                    })
                );
            });
    };

    return (
        <div className={managementStyles.MainContent}>
           {/*  <div className={managementStyles.Title}>
            <span className={styles.label}>Role Management</span>
            </div> */}
            <div className={managementStyles.MiddleContent}>
                <FilterManagementView
                    isOrganization={true}
                    isGroup={false}
                    isSubGroup={false}
                    isTeam={false}
                    addButtonLabel='Add Role'
                    onAddHandler={onAddRoleHandler}
                    onDeleteHandler={onDeleteHandler}
                    modelHeader='Delete Role?' 
                />
                <PerinHealthGrid
                    dataList={roleData}
                    headers={headers}
                    showCheckbox={true}
                    showEditIcon={true}
                    onRowClick={onRowClickHandler}
                    onDeleteRow={onDeleteHandler}
                    showHeaderFilter={true}
                    gridHeight="100%"
                />
            </div>
        </div>
    );
};

export default RoleManagement;
