import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, FloatingLabel, Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from '../QuestionnaireManagement.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AddQuestionIcon, LeftArrowIcon } from '../../../components/icons/Icons';
import { useNavigate } from 'react-router-dom';
import AnswerComponent from './AnswerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestionToPreview, clearQuestion, deleteQuestion, setAnswer, setIsUpdateQuestion, setQuestion, setShowPreview, setUpdateQuestion } from './AddQuestionnaireSlice';
import InternalPreviewComponent from './InternalPreviewComponent';
import managementStyles from '../../ManagementPages.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../../components/ProgressBar';

const AddQuestionnaire = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addQuestionnaireState = useSelector((state) => state.addQuestionnaire);
    const isUpdateMode = addQuestionnaireState.isUpdateMode;
    const isUpdateQuestion = addQuestionnaireState.isUpdateQuestion;
    const previewQuestions = addQuestionnaireState.previewQuestions || [];
    const questions = addQuestionnaireState.questions || [];
    const showPreview = addQuestionnaireState.showPreview || [];

    const [activeQuestionType, setActiveQuestionType] = useState('');

    const onBackHandler = () => {
        navigate('/questionnaire-setup');
    };

    const onInputChange = (e, questionId) => {
        const { name, value } = e.target;

        const currentQuestion = questions.find((question) => question.questionId === questionId);

        if (currentQuestion) {
            const updatedQuestion = { ...currentQuestion, [name]: value };

            if (name === 'answerType') {
                updatedQuestion.possibleAnswers = getInitialPossibleAnswers(value);
            }

            dispatch(setQuestion({ questionId, ...updatedQuestion }));
        }
    };

    const getInitialPossibleAnswers = (questionType) => {
        switch (questionType) {
            case 'Multi Select':
                return [{ answerId: 1, text: 'Answer 1', answerText: '', score: 0 }];
            case 'Single Select':
                return [{ answerId: 1, text: 'Answer 1', answerText: '', score: 0 }];
            case 'Yes or No':
                return [
                    { answerId: 1, text: 'Answer 1', answerText: 'Yes', score: 0 },
                    { answerId: 2, text: 'Answer 2', answerText: 'No', score: 0 }
                ];
            default:
                return [];
        }
    };


    const onAddOption = (questionId) => {
        // Handle adding an option here if needed
    };

    const onAnswerChange = (e, questionId) => {
        const { value } = e.target;
        dispatch(setAnswer({ questionId, answer: value }));
    };

    const addQuestionHandler = () => {
        if (isUpdateQuestion) {
            const lastQuestion = questions[questions.length - 1];
            dispatch(setUpdateQuestion(lastQuestion));
            dispatch(setIsUpdateQuestion(false));
            dispatch(clearQuestion());
        } else {
            const lastPreviewQuestion = previewQuestions[previewQuestions.length - 1];
            const lastQuestion = questions[questions.length - 1];
            if (lastQuestion) {
                dispatch(addQuestionToPreview(lastQuestion));
                setActiveQuestionType('');
                dispatch(setShowPreview(true));
                dispatch(clearQuestion());
            }
            const newQuestionId = lastQuestion ? lastQuestion.questionId + 1 : lastPreviewQuestion ? lastPreviewQuestion.questionId + 1 : 1;
            if (lastQuestion && lastQuestion.possibleAnswers.length < 2) {
                console.error('At least two options are required for the question.');
                return;
            }

            const newQuestion = {
                questionId: newQuestionId,
                answerType: activeQuestionType,
                questionText: '',
                possibleAnswers: [],
            };

            dispatch(setQuestion(newQuestion));
        }

    };


    const renderQuestionComponent = (question) => {
        switch (question.answerType) {
            case 'Single Select':
                return (
                    <Col xs={12} className="mt-2">
                        <AnswerComponent
                            questionType="Single Select Choice"
                            onAddOption={() => onAddOption(question.questionId)}
                            questionId={question.questionId}
                            possibleAnswers={question.possibleAnswers}
                            optionsCount={question.possibleAnswers.length}
                        />
                    </Col>
                );
            case 'Multi Select':
                return (
                    <Col xs={12} className="mt-2">
                        <AnswerComponent
                            questionType="Multi Select"
                            onAddOption={() => onAddOption(question.questionId)}
                            questionId={question.questionId}
                            possibleAnswers={question.possibleAnswers}
                            optionsCount={question.possibleAnswers.length}
                        />
                    </Col>
            );
            case 'Yes or No':
                return (
                    <Col xs={12} className="mt-2">
                        <AnswerComponent
                            questionType="Yes or No"
                            onAddOption={() => onAddOption(question.questionId)}
                            questionId={question.questionId}
                            possibleAnswers={question.possibleAnswers}
                            optionsCount={question.possibleAnswers.length}
                        />
                    </Col>
                );
            default:
                return null;
        }
    };

    const onPreviewEdit = (question) => {
        dispatch(setIsUpdateQuestion(true));
        dispatch(clearQuestion());
        //dispatch(deleteQuestion(question.questionId));
        dispatch(setQuestion(question));
    };

    const onPreviewDelete = (question) => {
        dispatch(deleteQuestion(question.questionId));
    };

    const onCancelHandler = () => {
        dispatch(setIsUpdateQuestion(false));
        dispatch(clearQuestion());
    };

    const AnswerTypeTooltip = (
        <Tooltip id="button-tooltip-2">
            Single Select: Choose only one option from a list of choices.
            Multi Select: Select multiple options from a list of choices.
            Binary Choice (Yes or No): Select one option from two possible answers
        </Tooltip>
    );

    const navigateToManagement = () => {
        navigate('/questionnaire-management');
    };

    const getPageName = (step) => {
        if (step === 0) {
            return "Questionnaire Setup";
        } else if (step === 1) {
            return "Add Questions";
        } else if (step === 2) {
            return "Preview & Publish";
        }
    };

    return (
        <div className={managementStyles.MainContent}>
            <div className={managementStyles.ReturnToManagement} onClick={navigateToManagement}>
                <LeftArrowIcon />
                <span>Back to Questionnaire Management</span>
            </div>
            <div className={managementStyles.MainCard}>
                <Container className={managementStyles.Container}>
                    <h3 className={managementStyles.ContentTitle} >{isUpdateMode ? 'Update' : 'Create'} Questionnaire</h3>
                    <div style={{paddingBottom:"15px"}}>
                        <ProgressBar page={1} getPageName={getPageName} navigateToStep={onBackHandler} totalSteps={3}/>
                    </div>
                    <div className={styles.ScrollContainer}>
                        {showPreview && (
                            <div>
                                {isUpdateQuestion ? 
                                    previewQuestions.filter(item => item.questionId < questions[0].questionId)
                                    .map((question, index) => (
                                        <InternalPreviewComponent
                                            key={question?.questionId || index}
                                            question={question}
                                            onEdit={onPreviewEdit}
                                            onDelete={onPreviewDelete}
                                        />
                                    ))
                                    :
                                    previewQuestions.map((question, index) => (
                                        <InternalPreviewComponent
                                            key={question?.questionId || index}
                                            question={question}
                                            onEdit={onPreviewEdit}
                                            onDelete={onPreviewDelete}
                                        />
                                    ))
                                }
                            </div>
                        )}
                        <Form className={styles.QuestionForm} hidden={questions.length===0}>
                            {questions.map((question) => (
                                <Container key={question.questionId} style={{padding:"0"}}>
                                    <Form.Label className={styles.QuestionFormTitle}>{`Question ${question.questionId}`}</Form.Label>
                                    <Row className="mb-2 align-items-center">
                                        <Col xs={8}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel 
                                                    controlId="floatingInput"
                                                    label="Enter your question"
                                                    className="mb-3"
                                                >
                                                <Form.Control
                                                    className={`${managementStyles.BorderedComponent}`}
                                                    size='sm'
                                                    name="questionText"
                                                    type="text"
                                                    style={{width:"100%"}}
                                                    placeholder="Enter your question"
                                                    value={question.questionText || ''}
                                                    onChange={(e) => onInputChange(e, question.questionId)}
                                                />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3}>
                                            <Form.Group className="mb-3">
                                                <FloatingLabel controlId="floatingSelect" label="Answer Type">
                                                <Form.Select
                                                    size='sm'
                                                    style={{width:"100%"}}
                                                    name="answerType"
                                                    value={question.answerType}
                                                    onChange={(e) => onInputChange(e, question.questionId)}
                                                    className={`${managementStyles.BorderedComponent}`}
                                                >
                                                    <option value="">Answer Type</option>
                                                    <option value="Multi Select">Multi Select</option>
                                                    <option value="Single Select">Single Select</option>
                                                    <option value="Yes or No">Yes or No</option>
                                                </Form.Select>
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={1} className="mb-3">
                                            <OverlayTrigger placement="bottom" overlay={AnswerTypeTooltip}>
                                                <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: '#686869'}}/>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                {renderQuestionComponent(question)}
                                <div className={`d-flex ${"mt-3"}`}>
                                    <Button variant="primary" className={`${styles.SaveButton} me-3`} onClick={addQuestionHandler}>
                                        Save
                                    </Button>
                                    <Button variant="primary" className={`${styles.CancelButton}`} style={{backgroundColor:'#F3F3F3'}} onClick={onCancelHandler}>
                                        Cancel
                                    </Button>
                                </div>
                                </Container>
                                
                            ))}
                        </Form>
                        {showPreview && isUpdateQuestion && (
                            <div>
                                {previewQuestions.filter(item => item.questionId > questions[0].questionId)
                                .map((question, index) => (
                                    <InternalPreviewComponent
                                        key={question?.questionId || index}
                                        question={question}
                                        onEdit={onPreviewEdit}
                                        onDelete={onPreviewDelete}
                                    />
                                ))}
                            </div>
                        )}
                    <Col className='pb-5'>
                        <div className="d-flex">
                            {!isUpdateQuestion &&
                                <Button variant="primary" className={`${styles.AddQuestionButton} me-3`} onClick={addQuestionHandler}>
                                    <AddQuestionIcon /> Add Question
                                </Button>
                            }
                        </div>
                    </Col>
                    </div>
                </Container>
                <div className={styles.Footer}>
                    <Row>
                        <Col>
                            <Button variant="primary" className={managementStyles.CreateButton} onClick={() => { navigate('/preview-question') }}> Next</Button>
                        </Col>
                        <Col>
                            <Button className={managementStyles.BackButton} variant="secondary" onClick={onBackHandler}>Back</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default AddQuestionnaire;
