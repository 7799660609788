import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalities: [],
    notifications: [],
    modalityFrequencies: []
};

const patientModalitiesSlice = createSlice({
    name: "patientEnrollmentModalities",
    initialState,
    reducers: {
        resetModalitiesState: (state) => {
            Object.assign(state, initialState);
        },
        setModality : (state, action) => {
            const { deviceId, deviceModalityId, ...rest } = action.payload;
            const existingModalityIndex = state.modalities.findIndex(mod => (mod.deviceId === deviceId && mod.deviceModalityId === deviceModalityId));
            if (existingModalityIndex !== -1) {
                const updatedMod = state.modalities.map((mod, index) => 
                    index === existingModalityIndex ? { ...mod, ...rest} : mod
                )
                return { ...state, modalities: updatedMod };
            } else {
                const newModality = { deviceId: deviceId, deviceModalityId: deviceModalityId, ...rest };
                return { ...state, modalities: [...state.modalities, newModality] };
            }
        },
        removeModality : (state, action) => {
            const deviceId = action.payload;
            const updatedModalities = state.modalities.filter(item => item.deviceId !== deviceId);
            state.modalities = updatedModalities;
        },

        setModalitiesState: (state, action) => {
            const { programDevices, devicesList, modalityValues} = action.payload;
            let uniqueDeviceIds = [];
            if (programDevices.length >= 1) {
                programDevices.forEach(device => {
                    if (!uniqueDeviceIds.some(e => e.deviceId === device.deviceId)) {
                        uniqueDeviceIds.push(device);
                    }
                });
            };

            const getInitialStartTime = () => {
                const currentDate = new Date();
                currentDate.setHours(9,0,0,0);
                let utcTimeStamp = currentDate.toISOString();
                return utcTimeStamp;
            };

            const modalityInfo = {
                deviceId: '',
                deviceModalityId: '',
                upperLimit: 0,
                lowerLimit: 0,
                targetValue: 0,
                isContinuous: false,
                startTime: getInitialStartTime(),
                frequency: 1,
                interval: "Day",
                isAlarmActive: false
            };

            let newModalities = [];
            let newNotifications = [];
            let newModalityFrequencies = [];
            uniqueDeviceIds.forEach((device)=> {
                // get all modalities for a device
                let modalitiesFound = devicesList.find(item => item.deviceId === device.deviceId);
                if (modalitiesFound) {
                    let modalities = modalitiesFound.modalities;
                    // if a modality is already listed in the program, save that data
                    // otherwise, save the modality with 0 in place of values
                    let modalityVals = modalities.map((mod) => {
                        let tempMod = {...modalityInfo};
                        tempMod['deviceModalityId'] = mod.deviceModalityId;
                        tempMod['deviceId'] = device.deviceId;
                        tempMod['modalityName'] = mod.modalityName;
                        tempMod['modalityId'] = mod.modalityId;

                        // check if patient has current values for modality and use those values
                        if (modalityValues.hasOwnProperty(mod.modalityId)) {
                            let found = modalityValues[mod.modalityId];
                            tempMod['upperLimit'] = found.upperLimit;
                            tempMod['lowerLimit'] = found.lowerLimit;
                            tempMod['targetValue'] = found.targetValue;
                            tempMod['isContinuous'] = found.isContinuous;
                            tempMod['startTime'] = found.startTime;
                            tempMod['frequency'] = found.frequency;
                            tempMod['interval'] = found.interval;
                        }

                        let found=programDevices.find((element)=>element.deviceModalityId===mod.deviceModalityId);
                        if (found) {
                            // if patient doesnt have current enrollments with values for the current modality, add template or saved modalities
                            if (!modalityValues.hasOwnProperty(mod.modalityId)) {
                                tempMod['upperLimit'] = found.upperLimit;
                                tempMod['lowerLimit'] = found.lowerLimit;
                                tempMod['targetValue'] = found.targetValue;
                                tempMod['isAlarmActive'] = found.isAlarmActive;
                                tempMod['isContinuous'] = found.isContinuous;
                                tempMod['startTime'] = found.startTime;
                                tempMod['frequency'] = found.frequency;
                                tempMod['interval'] = found.interval;
                            }

                            // save rules if updating and modality has rules
                            if (found.hasOwnProperty("rules")) {
                                found.rules.forEach((rule) => {
                                    let tempRule = {...rule};
                                    if (tempRule.hasOwnProperty("updatedAt")) {
                                        delete tempRule["updatedAt"];
                                    }
                                    if (tempRule.hasOwnProperty("createdAt")) {
                                        delete tempRule["createdAt"];
                                    }
                                    if (rule.ruleType === "enrollment" && rule.actionType === "sendNotification") {
                                        newNotifications.push(tempRule);
                                    } else if (rule.ruleType === "enrollment" && rule.actionType === "increaseFrequency") {
                                        newModalityFrequencies.push(tempRule);
                                    }
                                })
                            }

                        }
                        // if there is no saved/template/current modality values, save with initial values (0,0,0)                       
                        return tempMod;
                    })
                    newModalities = [...newModalities, ...modalityVals];
                }
            })
            return {
                modalities: newModalities,
                notifications: newNotifications,
                modalityFrequencies: newModalityFrequencies
            };
        },
        setNotification: (state, action) => {
            const {key, value, modalityId} = action.payload;
            const index = state.notifications.findIndex(notification => notification.modalityId === modalityId);

            if (index !== -1) {
                const updatedNotifications = [...state.notifications];
                // Update the state with the given key, value pair
                updatedNotifications[index] = {
                    ...updatedNotifications[index], // Keep existing properties
                    [key]: value
                };
                state.notifications = updatedNotifications
            }
        },
        removeNotification: (state, action) => {
            const modalityId = action.payload;
            const tempNotifications = state.notifications.filter((notification)=>notification.modalityId !== modalityId);
            state.notifications = tempNotifications;
        },
        setModFrequency: (state, action) => {
            const {key, value, modalityId} = action.payload;
            const index = state.modalityFrequencies.findIndex(mod => mod.modalityId === modalityId);

            if (index !== -1) {
                const updatedModFrequencies = [...state.modalityFrequencies];
                // Update the state with the given key, value pair
                updatedModFrequencies[index] = {
                    ...updatedModFrequencies[index], // Keep existing properties
                    [key]: value
                };

                state.modalityFrequencies = updatedModFrequencies
            };
        },
        removeModFrequency: (state, action) => {
            const modalityId = action.payload;
            const tempModalityFrequencies = state.modalityFrequencies.filter((mod)=>mod.modalityId !== modalityId);
            state.modalityFrequencies = tempModalityFrequencies;
        },
        setStateValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        }
    }
});

export const { 
    resetModalitiesState, 
    setModality, 
    removeModality, 
    setModalitiesState, 
    removeModFrequency,
    setModFrequency,
    setNotification,
    removeNotification,
    setStateValue 
} = patientModalitiesSlice.actions;
  
  export default patientModalitiesSlice.reducer;