import React, {useState, useRef, useEffect } from 'react';
import styles from '../Patient.module.css';
import FilterPatientDetail from '../FilterPatientDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfilePictureIcon} from '../../../components/icons/Icons';
import EllipsesIcon from '../../../components/icons/EllipsesIcon';
import { faVideo, faPhone, faComments } from '@fortawesome/free-solid-svg-icons';
import useAxios from '../../../services/AxiosConfig';
import ChatContainer from '../../../components/ChatContainer';
import { Form } from 'react-bootstrap';
import { setStaffChatList, setStaffRecipient, setStaffChatVisible, setStaffNewChat, setStaffSelectedIndex } from '../../care-team-message/CareTeamMessageSlicer';
import { setCallInformation, openCallContainer } from '../../../slicers/CallContainerSlice';

const CareTeamView= () => {

    const dispatch = useDispatch();
    const axiosConfig = useSelector((state) => state.axiosConfig);
    const axios = useAxios(axiosConfig.envURL);
    const userAccountId = useSelector((state) => state.userInfo.id);
    const userFullName = useSelector((state) => state.userInfo.fullName);

    const carePrograms = useSelector((state)=>state.patient.carePrograms);
    const sortedCarePrograms = [...carePrograms].sort((a, b) => {
        return new Date(b.enrollment.startDate) - new Date(a.enrollment.startDate);
    });
    const callContainerShowing = useSelector((state)=>state.callContainer.callContainerShowing);

    const fetchMessages = async (id) => {
        try {
            const responseData = await axios.get(`chime/getAllMessageByUserId?userId=${userAccountId}&recipientType=STAFF`);
            const patientIndex = responseData.data.getAllMessage.findIndex(arr =>
              arr.some(obj => obj.senderId === id || obj.recipientId === id)
            );
            if (patientIndex !== -1) {
              dispatch(setStaffChatList(responseData.data.getAllMessage[patientIndex]));
            } else {
              dispatch(setStaffChatList([]));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [showStaffChat, setShowStaffChat] = useState(false);
    const [callData, setCallData] = useState({});

    const callStaff = (id, name, audioOnly) => {
        if (callContainerShowing) {
            return;
        }
        const callData = {
            title: `Patient Detail Care Team - ${userFullName} & ${name}`,
            appointmentId: null,
            senderId: userAccountId,
            recipientId: id,
            senderName: userFullName,
            recipientName: name
        };
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData, viewType: "staffMessage"}));
        dispatch(openCallContainer());
    };

    const closeChat = () => {
        dispatch(setStaffChatVisible(false));
        dispatch(setStaffSelectedIndex(-1));
        dispatch(setStaffChatList([]));
        dispatch(setStaffNewChat(false));
        setShowStaffChat(false);
    };

    const chatStaff = (id, name) => {
        if (callContainerShowing) {
            return;
        }
        fetchMessages(id);
        setCallData({
            title: `Patient Detail Care Team - ${userFullName} & ${name}`,
            appointmentId: null,
            senderId: userAccountId,
            recipientId: id,
            senderName: userFullName,
            recipientName: name
        });
        dispatch(setStaffSelectedIndex(0));
        dispatch(setStaffNewChat(false));
        dispatch(setStaffRecipient({ 'id': id, name: name}));
        setShowStaffChat(true);
    };

    const handleChatStartCall = (audioOnly) => {
        closeChat();
        dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData, viewType: "staffMessage"}));
        dispatch(openCallContainer());
    };

    const careTeam = useSelector((state)=>state.patient.careTeam);
    const searchText = useSelector((state)=>state.patientDetailFilter.searchText);

    const sortedTeam = [...careTeam].filter(item => (
        item.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.title?.toLowerCase().includes(searchText.toLowerCase())
    ));

    const labelColors = {
        "Doctor": "rgba(183, 192, 238, 0.38)",
        "Nurse": "rgba(71, 140, 170, 0.20)",
        "Staff": "rgba(71, 140, 170, 0.20)",
    };

    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const otherButtons = useRef(null);
    const [selectedRow, setSelectedRow] = useState(false);

    const handleOptionsClick = (id) => {
        if (selectedRow === id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(id);
        }
    };

    const checkProgram = (staff) => {
        const teamIds = staff.groups.map(team => team.teamId);
        let programName = "";
    
        for (const program of sortedCarePrograms) {
            let groups = program.enrollment.groups;
            const hasMatchingTeamId = groups.some(group => teamIds.includes(group.teamId));
    
            if (hasMatchingTeamId) {
                programName = program.name;
                break;
            }
        }
        return programName;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)) {
                    setSelectedRow(null);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, []);

    return (
        <div className={styles.ViewContainer}>
           {showStaffChat &&
                <Form className={styles.ChatContainer}>
                    <ChatContainer 
                        view="staffMessage"
                        handleClose={closeChat} 
                        onStartVideoCall={() => handleChatStartCall(false)}
                        onStartAudioCall={() => handleChatStartCall(true)}
                    />
                </Form>
           }
           <FilterPatientDetail isSearch={true} />
           {sortedTeam.map((staff) => {
               let staffName = `${staff.firstName} ${staff.lastName}`
                return (
                    <div className={styles.CareTeamListItem} key={staff.id}>
                        <div style={{display:"flex", gap:"20px", alignItems:"center"}}>
                            {staff.profilePictureURL ? (
                                <img 
                                    src={staff.profilePictureURL} 
                                    alt="User Profile" 
                                    style={{
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                        border: '2px solid grey',
                                        objectFit: 'cover'
                                        }}
                                />
                            ) : (
                                <UserProfilePictureIcon width="42" height="42"/>
                                        )}
                            <div style={{fontWeight:"500"}}>
                                {staffName}
                            </div>
                            <div className={styles.BorderedItem}>
                                {checkProgram(staff)}
                            </div>
                            {staff.title &&
                                <div className={styles.SpecialtyContainer} style={{backgroundColor:`${labelColors[staff.role.roleCategory]}`}}>
                                    {staff.title}
                                </div>
                            }
                        </div>
                        <div>
                            <button ref={selectedRow === staff.id ? buttonRef : otherButtons} style={{background:"white", border:"none", display:"flex", alignItems:"center"}}onClick={()=>handleOptionsClick(staff.id)}>
                                <EllipsesIcon />
                            </button>
                            {selectedRow === staff.id &&
                                <div ref={containerRef} className={styles.CareTeamOptionsContainer}>
                                    <div className={styles.careTeamOptions} onClick={()=>chatStaff(staff.id, staffName)}>
                                        <FontAwesomeIcon icon={faComments} /> Message
                                    </div>
                                    <div className={styles.careTeamOptions} onClick={()=>callStaff(staff.id, staffName, false)}>
                                        <FontAwesomeIcon icon={faVideo} /> Video Call
                                    </div>
                                    <div className={styles.careTeamOptions} onClick={()=>callStaff(staff.id, staffName, true)}>
                                        <FontAwesomeIcon icon={faPhone} />Phone Call
                                    </div>
                                </div>
                            }
                            </div>
                    </div>
                )
            })
            }
        </div>
    );
}

export default CareTeamView;