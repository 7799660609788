import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadIcon } from '../../../components/icons/Icons';

const FileUploadOrganization = ({ fileType, onDrop, imageURL, acceptedTypes }) => {
    const acceptType = (() => {
        if (fileType === 'image') {
            return {
                'image/jpeg': [],
                'image/png': [],
            };
        } else if (fileType === 'audio') {
            return {
                'audio/*': [],
            };
        } else if (fileType === 'mp4') {
            return {
                'video/mp4': [],
            };
        } else if (fileType === 'pdf') {
            return {
                'application/pdf':[]
            };
        }
        return {}; 
    })();
    

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: acceptType,
        onDrop: onDrop
    });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            {imageURL ? (
                <img
                    src={imageURL}
                    alt="Logo"
                    style={{ maxWidth: '200px', maxHeight: '100px' }}
                />
            ) : (
                <div style={{display:"flex", alignItems:"center", columnGap:"10px", justifyContent:"center"}}>
                    <FileUploadIcon />
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <p className="subtitle-1" style={{padding:"0"}}>Drop file or browse</p>
                        <span className="body-2">{acceptedTypes}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default FileUploadOrganization;
