// Main.js
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HelpIcon, UserProfilePictureIcon } from '../components/icons/Icons';
import { selectActiveMenuItem, setActiveMenuItem } from '../slicers/MainSlice';
import { resetUserState } from '../slicers/UserSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import MainContent from './MainContent';
import Sidebar from './sidenav/Sidebar';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import PerinHealthAlert from '../components/PerinHealthAlert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Welcome from './app-tour/Welcome';
import ApplicationGuide from './app-tour/ApplicationGuide';
import { resetAppTourState, setFieldValue } from './app-tour/AppTourSlice';
import VideoCallContainer from '../components/VideoCallContainer';
import BellNotification from '../components/BellNotification';
import { closeCall, setCallInformation, openCallContainer, setJoiningStaffCall } from '../slicers/CallContainerSlice';
import CallNotification from '../components/Notifications/CallNotification';
import { closeCallNotification } from '../components/Notifications/CallNotificationSlice';
import { useWebSocket } from '../services/webSocket/useWebSocket';
import useAxios from '../services/AxiosConfig';
import AppointmentVideoCall from '../views/appointment/AppointmentVideoCall';
import { closeAppointment } from './appointment/AppointmentSlicer';
import UserOptionsCard from '../views/account/UserOptionsCard';
import ManageAccountModal from '../views/account/ManageAccountModal';
import useIdleTimeout from '../services/hooks/useIdleTimeout';
import PreferencesModal from './account/PreferencesModal';
import { toggleSidebar } from '../slicers/sidebarSlice';

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const { id, fullName, firstName, lastName } = userInfo;
  const { sendMessage } = useWebSocket();
  const location = useLocation();

  const axiosConfig = useSelector((state) => state.axiosConfig);
  const axios = useAxios(axiosConfig.envURL, dispatch);
  const perinHealthAlert = useSelector((state) => state.perinHealthAlert);
  const appTour = useSelector((state) => state.appTour);
  const callNotification = useSelector((state) => state.callNotification);

  const sidebarSlicer = useSelector((state) => state.sidebar);
  const isSidebarOpen = sidebarSlicer.isSidebarOpen;
  const activeMenuItem = useSelector(selectActiveMenuItem);

  const { callContainerShowing, audioOnly, callData, expanded } = useSelector((state) => state.callContainer);
  const appointmentInfo = useSelector((state) => state.appointment);

  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showManageAccount, setShowManageAccount] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const iconRef = useRef(null);

  const handleMenuItemClick = (menuItem) => {
    dispatch(setActiveMenuItem(menuItem));
  };

  const handleSignOut = async () => {
    try {
      await axios.post('/signout')
    } catch (error) {
      console.error('Failed to sign out:', error);
      throw error;
    }

    dispatch(resetUserState());
    navigate('/');
  };

  useIdleTimeout(10 * 60 * 1000, handleSignOut);

  const handleCloseWelcome = () => {
    dispatch(setFieldValue({ name: 'showWelcome', value: false }));
  };

  const handleNextFromWelcome = () => {
    dispatch(setFieldValue({ name: 'showWelcome', value: false }));
    dispatch(setFieldValue({ name: 'showAppGuide', value: true }));
  };

  const handleCloseTour = () => {
    dispatch(setFieldValue({ name: 'showAppGuide', value: false }));
  };

  const handleNextStep = () => {
    dispatch(setFieldValue({ name: 'showAppGuide', value: false }));
    dispatch(setFieldValue({ name: 'showAppTour', value: true }));
  };

  const handleCallContainerClose = () => {
    dispatch(closeCall());
  };

  const handleCloseAppointment = () => {
    dispatch(closeAppointment());
  };

  const roleTooltip = <Tooltip id="tooltip">{userInfo.roleName}</Tooltip>

  const handleItemClick = (event) => {
    // Check if the clicked item is 'Onboarding'
    if (event.target.textContent === 'Onboarding') {
      // Only dispatch toggleSidebar if the sidebar is currently closed
      if (!isSidebarOpen) {
        dispatch(toggleSidebar());
      }
      // Reset the app tour state and show the welcome screen
      dispatch(resetAppTourState());
      dispatch(setFieldValue({ name: 'showWelcome', value: true }));
    }
  };


  const dismissCallNotification = async () => {
    dispatch(closeCallNotification());
    try {
      await axios.delete(`/meeting/${callNotification.meetingId}`);
    } catch (err) {
      console.error('Failed to delete meeting:', err);
    }
    sendMessage({
      type: 'staff_decline',
      senderId: id,
      senderName: fullName,
      recipientId: callNotification.id,
      recipientName: callNotification.name,
      meetingId: callNotification.meetingId
    });
  };

  const acceptCallNotification = () => {
    const callData = {
      title: `Staff Message ${callNotification.audioOnly ? "Audio" : "Video"} Call - ${fullName} & ${callNotification.name}`,
      appointmentId: null,
      senderId: id,
      recipientId: callNotification.id,
      senderName: fullName,
      recipientName: callNotification.name,
      meetingId: callNotification.meetingId
    };
    dispatch(setJoiningStaffCall());
    dispatch(closeCallNotification());
    dispatch(setCallInformation({ "audioOnly": callNotification.audioOnly, "callData": callData, viewType: "staffMessage" }));
    dispatch(openCallContainer());
  };

  const helpStyle = {
    color: '#fff',
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#0d6efd',
    },
    '&:active': {
      backgroundColor: '#fff',
      color: '#0d6efd', // Change the text color for better visibility
    },
    '&:focus': {
      boxShadow: '0 0 0 0.25rem rgba(49, 132, 253, 0.5)',
    },
    '&:disabled': {
      backgroundColor: '#fff',
      color: '#0d6efd',
      borderColor: '#fff',
    },
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowUserOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  const handleManageAccountClick = () => {
    setShowManageAccount(true);
    setShowUserOptions(false);
  };

  const handleManageAccountClose = () => {
    setShowManageAccount(false);
  };

  const handlePreferencesClick = () => {
    setShowPreferences(true);
    setShowUserOptions(false);
  };

  const handlePreferencesClose = () => {
    setShowPreferences(false);
    setShowUserOptions(false);
  };

  const titleHidden = [
    'patient-detail',
    'new-organization-management',
    'add-new-organization',
    'create-account',
    'add-edit-role',
    'add-reference-code',
    'add-device',
    'add-edit-content',
    'questionnaire-setup',
    'add-questionnaire',
    'preview-question',
    'add-program',
    'enroll-patient'
  ].some(path => location.pathname.includes(path));

  return (
    <div className={`main-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      {axiosConfig.requestsCount !== 0 && (
        <div className="overlay">
          <Spinner className="spinner" animation="border" role="status" variant="secondary" style={{ width: '6rem', height: '6rem' }} />
        </div>
      )}
      <div className={`sidebar ${isSidebarOpen ? 'sidebar-opened' : 'sidebar-minimized'}`}>
        <Sidebar onMenuItemClick={handleMenuItemClick} />
      </div>
      <div className={`main-content ${isSidebarOpen ? 'content-with-sidebar' : 'full-width-content'}`}>
        <div className="sub-content" style={{ display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="selectedMenuItem">{titleHidden ? "" : sidebarSlicer.selectedMenuItem }</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="userInfo-content">
              <DropdownButton variant="secondary" title={<span><HelpIcon /></span>} className="help-icon">
                <Dropdown.Item onClick={handleItemClick}>Instruction of Use</Dropdown.Item>
                <Dropdown.Item onClick={handleItemClick}>FAQ</Dropdown.Item>
                <Dropdown.Item onClick={handleItemClick}>Onboarding</Dropdown.Item>
              </DropdownButton>
              <BellNotification />
              <div ref={iconRef} style={{ position: 'relative', display: 'inline-block' }}>
                <div onClick={() => setShowUserOptions((prev) => !prev)}>
                  {userInfo.profilePictureURL !== "" ?
                    <img
                      src={userInfo.profilePictureURL}
                      alt="Profile_Picture"
                      style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        border: '1px solid #1D5769',
                        objectFit: 'cover'
                      }}
                    />
                    :
                    <div style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                      <span className={"profile-initials"}>
                        {`${firstName?.[0]}${lastName?.[0]}`}
                      </span>
                      <UserProfilePictureIcon width={32} height={32} />
                    </div>
                  }
                </div>
                {showUserOptions && (
                  <UserOptionsCard
                    onClose={() => setShowUserOptions(false)}
                    onManageAccountClick={handleManageAccountClick}
                    onPreferencesClick={handlePreferencesClick}
                    onSignOut={handleSignOut}
                  />
                )}
              </div>
              <div className={'user-fullName'}>{fullName}</div>
            </div>
          </div>
        </div>

        {callNotification.show && (
          <CallNotification
            show={callNotification.show}
            fullName={callNotification.name}
            onDismiss={dismissCallNotification}
            onAccept={acceptCallNotification}
          />
        )}
        {perinHealthAlert.show && (
          <PerinHealthAlert
            show={perinHealthAlert.show}
            type={perinHealthAlert.type}
            header={perinHealthAlert.header}
            message={perinHealthAlert.message}
            dismissable={perinHealthAlert.dismissable}
          />
        )}
        <MainContent activeMenuItem={activeMenuItem} />
        <Welcome show={appTour.showWelcome}
          handleClose={handleCloseWelcome}
          handleNext={handleNextFromWelcome}
        />
        <ApplicationGuide show={appTour.showAppGuide}
          handleClose={handleCloseTour}
          handleNext={handleNextStep}
        />
      </div>
      {callContainerShowing &&
        <div style={expanded ? { display: "flex", position: "absolute", zIndex: "1000", alignitems: "center", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" } : { bottom: "20px", right: "30px", position: "absolute", zIndex: "1000" }}>
          <VideoCallContainer
            onClose={handleCallContainerClose}
            audioOnly={audioOnly}
            data={callData}
          />
        </div>
      }
      {appointmentInfo.showing &&
        <div style={!appointmentInfo.minimized ? { display: "flex", position: "absolute", zIndex: "1000", alignitems: "center", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" } : { bottom: "20px", right: "30px", position: "absolute", zIndex: "1000" }}>
          <AppointmentVideoCall
            onClose={handleCloseAppointment}
            data={appointmentInfo.appointmentData}
          />
        </div>
      }
      <ManageAccountModal show={showManageAccount} handleClose={handleManageAccountClose} />
      <PreferencesModal show={showPreferences} handleClose={handlePreferencesClose} />
    </div>
  );
};

export default Main;
