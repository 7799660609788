import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  fullName: '',
  lastName: '',
  roleName: '',
  roleId: '',
  roleCategory: '',
  id: '',
  lastLogin:false,
  canManageAvailability: false,
  isAdmin:false,
  groups: [],
  uniqueGroups: [],
  organizations: [],
  organizationsHierarchy: [],
  tokens: null,
  permissions: [],
  dateFormat:'',
  unitType: '',
  address: {},
  profilePictureURL: '',
  organizationLogo: '',
  email: '',
  countryCode: '',
  phone: '',
  timeZone: '',
  isLoggedIn: false,
};
 
export const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      const {name, id, category} = action.payload;
      state.roleName = name;
      if(name === "Super Admin"){
        state.isAdmin = true;
      };
      state.roleId = id;
      state.roleCategory = category;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
      state.isLoggedIn = true;
    },
    clearTokens: (state) => {
      state.tokens = null;
    },
    setLastLogin: (state, action) => {
      state.lastLogin = action.payload === null || action.payload === undefined ? false : true;
    },
    setUserGroups: (state, action) => {
      state.groups = action.payload;
    },
    setUserUniqueGroups: (state, action) => {
      state.uniqueGroups = action.payload;
    },
    setUserOrganizations: (state, action) => {
      state.organizations = action.payload;
    },

    setOrganizationsHierarchy: (state, action) => {
      state.organizationsHierarchy = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    resetUserState: (state) => {
      Object.assign(state, initialState);
    },
    setPreferences: (state, action) => {
      const {dateFormat, unitType, timeZone} = action.payload;
      if (dateFormat) {
        state.dateFormat = dateFormat;
      }
      if (unitType) {
        state.unitType = unitType;
      }
      if (timeZone && timeZone !== null) {
        state.timeZone = timeZone;
      }
    },
    setUserItem: (state, action) => {
      const {key, value} = action.payload;
      state[key] = value;
    },
    setUser: (state, action) => {
      const {id, firstName, lastName, email, countryCode, phone, canManageAvailability, address, profilePictureURL, organizationLogo} = action.payload;
      state.id = id;
      state.firstName = firstName;
      state.lastName = lastName;
      state.fullName = `${firstName} ${lastName}`;
      state.email = email;
      if (countryCode) {
        state.countryCode = countryCode;
      }
      if (phone) {
        state.phone = phone;
      }
      if (canManageAvailability) {
        state.canManageAvailability = canManageAvailability;
      }
      if (address) {
        state.address = address;
      }
      if (profilePictureURL) {
        state.profilePictureURL = profilePictureURL;
      }
      if (organizationLogo) {
        state.organizationLogo = organizationLogo;
      }
    }
  },
});
 
export const { setUserRole, setUserGroups, setUserUniqueGroups, setUserOrganizations,setLastLogin,
  setOrganizationsHierarchy, resetUserState, setTokens, clearTokens, setPermissions, setPreferences, setUserItem, setUser } = userSlice.actions;
 
export default userSlice.reducer;