import React, { useState } from "react";
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import '../../components/AccountManagement/Accounts.css';
import inputStyles from '../ManagementPages.module.css';

const ActivatePersonalInfo = ({userData, handleChange}) => {

    const role = userData.role.name;

    const [unitType, setUnitType] = useState(userData.preferences.unitType);
    const handleUnitChange = (e) => {
        setUnitType(e.target.value);
        handleChange(e,null,"preferences")
    };

    return (
        <div className="personal-info-container">
            <div className="personal-info-content">
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                    ></input>
                    <label className={userData.firstName && inputStyles['filled']}>
                        First Name
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="lastName"
                        value={userData.lastName} 
                        onChange={handleChange}
                    ></input>
                    <label className={userData.lastName && inputStyles['filled']}>
                        Last Name
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="emaiil"
                        defaultValue={userData.email} 
                        disabled={true}
                    ></input>
                    <label className={userData.email && inputStyles['filled']}>
                        Email
                    </label>
                </div>
                <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                    <div className={inputStyles["styled-input-container"]} style={{width:"40%"}}>
                        <input
                            style={{width:"100%"}}
                            id="countryCode"
                            value={userData.countryCode}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.countryCode && inputStyles['filled']}>
                            Country Code
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]} style={{width:"57%"}}>
                        <input
                            style={{width:"100%"}}
                            id="phone"
                            value={userData.phone}
                            onChange={handleChange}
                        ></input>
                        <label className={userData.phone && inputStyles['filled']}>
                            Phone Number
                        </label>
                    </div>
                </div>
                <div className="input-name" style={{color:"#5E5E5E",fontSize:"13px"}}>
                    Address Info
                </div>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Address Type</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="type"
                        value={userData.address.type}
                        onChange={(e) => 
                            handleChange(e, "type", "address")
                        }
                    >
                        <option value="">Address Type</option>
                        {DropdownData.addressTypes.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="address1"
                        value={userData.address.address1}
                        onChange={(e)=>handleChange(e,null,"address")}
                    ></input>
                    <label className={userData.address.address1 && inputStyles['filled']}>
                        Street Address (Ave, St, Blvd, etc.)
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="address2"
                        value={userData.address.address2}
                        onChange={(e)=>handleChange(e,null,"address")}
                    ></input>
                    <label className={userData.address.address2 && inputStyles['filled']}>
                        Apartment, Suite, etc
                    </label>
                </div>
                <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                    <div className="input-block" style={{width: "100%"}}>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="city"
                                value={userData.address.city}
                                onChange={(e)=>handleChange(e, "city", "address")}
                            ></input>
                            <label className={userData.address.city && inputStyles['filled']}>
                            City
                            </label>
                        </div>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="state"
                                value={userData.address.state}
                                onChange={(e)=>handleChange(e, "state", "address")}
                            ></input>
                            <label className={userData.address.state && inputStyles['filled']}>
                            State
                            </label>
                        </div>
                    </div>
                    <div className="input-block" style={{marginBottom:"0px", width: "100%"}}>
                    <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="text"
                                style={{width:"100%"}}
                                id="country"
                                value={userData.address.country}
                                onChange={(e)=>handleChange(e, "country", "address")}
                            ></input>
                            <label className={userData.address.country && inputStyles['filled']}>
                            Country
                            </label>
                        </div>
                        <div className={inputStyles["styled-input-container"]} style={{width:"49%"}}>
                            <input
                                type="number"
                                style={{width:"100%"}}
                                id="zip"
                                value={userData.address.zip}
                                onChange={(e)=>handleChange(e, "zip", "address")}
                            ></input>
                            <label className={userData.address.zip && inputStyles['filled']}>
                            Postal Code
                            </label>
                        </div>
                    </div>
                </div>
                
                {role==="Patient" &&
                    <div style={{display: "flex", flexDirection:"column", rowGap:"10px"}}>
                        <div className="input-name" style={{color:"#5E5E5E",fontSize:"13px"}}>
                            Preferences
                        </div>
                        <div style={{position: "relative"}}>
                            <span className="floating-label">Date Format</span>
                            <select
                                className={inputStyles["styled-input-select"]}
                                id="dateFormat"
                                value={userData.preferences.dateFormat}
                                onChange={(e)=>handleChange(e, "dateFormat", "preferences")}
                            >
                                <option value="">Select Date Format</option>
                                {DropdownData.dateFormats.map((type) => (
                                    <option key={type.value} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="unit-types" style={{display: "flex", width: "330px", justifyContent:"space-between", marginBottom:"20px"}}>
                            Unit Type
                            <div>
                                <input
                                    id="unitType" 
                                    type="radio"
                                    value="Imperial"
                                    checked={unitType === 'Imperial'}
                                    onChange={handleUnitChange}
                                /> 
                                <span style={{paddingLeft: "5px"}}>Imperial (lbs, F)</span>
                            </div>
                            <div>
                                <input
                                    id="unitType" 
                                    type="radio"
                                    value="Metric"
                                    checked={unitType === 'Metric'}
                                    onChange={handleUnitChange}
                                /> 
                                <span style={{paddingLeft: "5px"}}>Metric (kg, C)</span>
                            </div>
                        </div>
                    </div>
                }
                <span style={{paddingBottom:"20px"}}></span>
            </div>
        </div>
    )

}

export default ActivatePersonalInfo;