import React, {useEffect, useState, useRef} from 'react';
import styles from '../Patient.module.css';
import PatientDetailDropdownCard from '../../../components/PatientDetailDropdownCard';
import FilterPatientView from '../FilterPatientDetail';
import { EmergencyContactIcon, TranscriptionIcon, EHRIcon, AttachmentIcon  } from '../../../components/icons/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { setNewNote, addNote, setNotes, clearNote } from '../Slices/PatientDetailNotesSlice';
import { Button } from 'react-bootstrap';
import { openModal, closeModal } from '../../../components/PerinHealthModelSlice';
import PerinHealthModal from '../../../components/PerinHealthModel';
import { showAlert } from '../../../components/PerinHealthAlertSlice';
import PatientNotesDetails from '../CardDetails/PatientNotesDetails';
import useAxios from "../../../services/AxiosConfig";

const NotesView = () => {

    const signedInUser = useSelector((state)=>state.userInfo);
    const dispatch = useDispatch();
    const {newNoteText, notes, meetingId, title, draftId } = useSelector((state)=>state.patientDetailNotes);
    const patient = useSelector((state)=>state.patient);
    const careTeam = patient.careTeam;
    const perinHealthModal = useSelector((state) => state.perinHealthModal);
    const { searchText, noteType, dateOrder } = useSelector((state) => state.patientDetailFilter);

    const [attachments, setAttachments] = useState([]);
    const [updatingAttachments, setUpdatingAttachments] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const fileInputRef = useRef(null);

    const editingDraft = draftId !== "";

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const today = new Date();

    const sortedNotes = [...notes].filter(item => (
        (item.title.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.doctor.toLowerCase().includes(searchText.toLowerCase())) &&
        (item.title === noteType || noteType === "")
    )).sort((a, b) => {
        const startDateA = new Date(a.updatedAt);
        const startDateB = new Date(b.updatedAt);
        if (dateOrder==="ascending") {
            return startDateA - startDateB;
        } else {
            return startDateB - startDateA;
        }
    });

    function getIcon(iconName) {
        switch (iconName) {
            case "EHR Note":
                return <EHRIcon />;
            case "Transcription":
                return <TranscriptionIcon />;
            default:
                return <EmergencyContactIcon />;
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  
    };

    const fetchPatientNotes = async() => {
        try{
            const response = await httpService.get(`patient/${patient.id}/notes`);
            const fetchPromises = response.data.data.map(async (note) => {
                try {
                    // Fetch the note content
                    let textContent = "Not available";
                    const responseData = await fetch(note.noteUrl);
                    const text = await responseData.text();
                    textContent = text;
                                
                    let doctorName = note.physicianId;
                    // Fetch the doctor's name using the physicianId
                    await httpService.get(`user/${note.physicianId}`).then((responseData) => {
                        doctorName = `${responseData.data.data.firstName} ${responseData.data.data.lastName}`;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    // let doctor = careTeam.find((item)=>item.id === note.physicianId)
                    // if (doctor) {
                    //     doctorName = `${doctor.firstName} ${doctor.lastName}`;
                    // }
                    

                    // Return a new object with only the desired properties
                    return {
                        patientId: note.patientId,
                        noteId: note.noteId,
                        title: note.noteUrl.split("/").slice(-2, -1)[0],  // Extract the note type from the URL
                        doctor: doctorName,
                        updatedAt: note.updatedAt,
                        submissionDate: formatDate(note.updatedAt),
                        notes: textContent,
                        isDraft: note.isDraft,
                        attachmentUrls: note.attachmentUrls
                    };
                } catch (error) {
                    console.log(`Failed to fetch content for note ID ${note.noteId}: ${error}`);
                    return null; // Ignore this note if there's an error
                }
            });

            const notesWithContent = (await Promise.all(fetchPromises)).filter(note => note !== null); 
            if(notesWithContent.length > 0){
                dispatch(setNotes(notesWithContent));
            }
            
        }catch (error){

        }
    };

    useEffect(()=>{
        fetchPatientNotes();
    },[]);

    const handleNote = (submitting) => {
        if (submitting) {
            dispatch(
                openModal({
                    header: "Submit New Note",
                    message: `Add this note to ${patient.firstName} ${patient.lastName}'s history?`,
                    buttons: [
                        { label: 'Submit Note', variant: 'primary', onClickKey: "Submit" },
                        { label: 'Cancel', variant: 'secondary', onClickKey: "Cancel" },
                    ],
                })
            );
        } else {
            if (editingDraft){
                updateNote(true)
            } else {
                saveNoteToS3(true)
            }
        }
    };

    const updateNote = async (draftMode) => {
        const file = new Blob([newNoteText], { type: 'text/plain' });
        const formData = new FormData();
        formData.append('isDraft', draftMode);
        formData.append('noteFile', file, `notes.txt`);

        if (attachments.length > 0) {
            for (const attachment of attachments) {
                const blob = new Blob([attachment], { type: attachment.type });
                formData.append('attachments', blob, attachment.name);
            }
        }

        if (deletedFiles.length > 0) {
            formData.append('filesToRemove', deletedFiles);
        }

        try {
            await httpService.put(`/patient/notes/update/${draftId}`, formData)
            .then(response => {
                return response;
            })
            .catch(err => console.error('Failed to add note:', err));
        } catch (error) {
            console.error('Error adding note:', error);
            throw error;
        }
        setDeletedFiles([]);
        setAttachments([]);
        setUpdatingAttachments([]);
        dispatch(clearNote());
        fetchPatientNotes();
    }

    const saveNoteToS3 = async (draftMode) => {
        try {

            const file = new Blob([newNoteText], { type: 'text/plain' });

            const formData = new FormData();
            formData.append('patientId', patient.id);
            formData.append('physicianId', signedInUser.id);
            formData.append('noteType', title || "Doctor Notes");
            formData.append('isDraft', draftMode);
            

            if(title === "Transcription"){
                formData.append('noteFile', file, `${meetingId}transcript.txt`);
                formData.append('meetingId', meetingId);
            }
            else{
                const currentDate = new Date();
                const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
                const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}-${currentDate.getMinutes().toString().padStart(2, '0')}-${currentDate.getSeconds().toString().padStart(2, '0')}`;

                formData.append('noteFile', file, `${formattedDate}_${formattedTime}_notes.txt`);
            }

            if (attachments.length > 0) {
                for (const attachment of attachments) {
                    const blob = new Blob([attachment], { type: attachment.type });
                    formData.append('attachments', blob, attachment.name);
                }
            }
    
            await httpService.post(`patient/notes`, formData)
              .then(response => {
                setAttachments([]);
                return response;
              })
              .catch(err => console.error('Failed to add note:', err));
 
        } catch (error) {
            console.error('Error adding note:', error);
            throw error;
        }
        dispatch(clearNote());
        fetchPatientNotes();
    };

    const handleModalOptions = (onClickKey) => {
        if (onClickKey === "Submit") {
            dispatch(closeModal());
            if (editingDraft) {
                updateNote(false);
            } else {
                saveNoteToS3(false);
            }
            dispatch(
                showAlert({
                    header: "Success",
                    message: "New patient note added",
                    type: 'success',
                })
            );
        } else {
            dispatch(closeModal());
        }
    };

    const DraftLabel = {
        name: "Draft",
        color: "#686869"
    };

    const handleAttachmentChange = (e) => {
        const files = Array.from(e.target.files);
        setAttachments((prevAttachments) => [...prevAttachments, ...files]);
    };

    const handleAttachmentIconClick = () => {
        fileInputRef.current.click();
    };

    const removeAttachment = (indexToRemove) => {
        setAttachments(attachments.filter((_, index) => index !== indexToRemove));
    };

    const removeUpdatingAttachment = (indexToRemove) => {
        const deletingFile = updatingAttachments[indexToRemove];
        setDeletedFiles([...deletedFiles, deletingFile.attachmentUrl]);
        setUpdatingAttachments(updatingAttachments.filter((_, index) => index !== indexToRemove));
    };

    const cancelEditingDraft = () => {
        setAttachments([]);
        setDeletedFiles([]);
        setUpdatingAttachments([]);
        dispatch(clearNote())
    };

    return (
        <div className={styles.ViewContainer}>
            <div className={styles.NewNoteSection}>
                <textarea
                    className={styles.NewNote}
                    placeholder='Add Notes'
                    value={newNoteText}
                    onChange={(e)=>dispatch(setNewNote( e.target.value))}
                >
                </textarea>
                    {editingDraft && updatingAttachments.map((file, index) => (
                        <div key={index} className={styles.AttachedFile}>
                            <span>{file.fileName}</span>
                            <Button variant="link" onClick={() => removeUpdatingAttachment(index)}>Remove</Button>
                        </div>
                    ))}
                    {attachments.map((file, index) => (
                        <div key={index} className={styles.AttachedFile}>
                            <span>{file.name}</span>
                            <Button variant="link" onClick={() => removeAttachment(index)}>Remove</Button>
                        </div>
                    ))}
                <div style={{display:"flex", columnGap:"10px", paddingLeft:"5px"}}>
                    <input type="checkbox" id="ehr"></input>
                    <label className="body-2" for="ehr">Transfer to EHR</label>
                </div>
                <div style={{display:"flex", gap: "10px"}}>
                    <Button 
                        variant="secondary"
                        className={styles.NewNoteButton} 
                        onClick={()=>handleNote(true)}
                        disabled={newNoteText===""}
                    >
                        Submit Note
                    </Button>
                    <Button 
                        variant="secondary"
                        className={styles.SaveDraftButton} 
                        onClick={()=>handleNote(false)}
                        disabled={newNoteText===""}
                    >
                        {editingDraft ? "Save Draft" : "Save as Draft"}
                    </Button>
                    <Button variant="white" onClick={handleAttachmentIconClick}>
                        <AttachmentIcon />
                    </Button>
                    <input
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleAttachmentChange}
                        ref={fileInputRef}
                    />
                    {editingDraft &&
                        <Button 
                            variant="secondary"
                            className={styles.CancelDraftButton} 
                            onClick={cancelEditingDraft}
                        >
                            Cancel
                        </Button>
                    }
                </div>
            </div>
            <span className={styles.NotesTitle}>Note History</span>
            <FilterPatientView isSearch={true} isDate={true} isNote={true}/>
            {sortedNotes.map((note, index) => {
                return (
                    <div key={index}>
                        <PatientDetailDropdownCard 
                            title={note.title} 
                            subtitle={note.doctor}
                            subtitle2={note.submissionDate}
                            icon={getIcon(note.title)}
                            label={note.isDraft ? DraftLabel : null}
                            cardInfo={<PatientNotesDetails data={note} setAttachments={setAttachments} setUpdatingAttachments={setUpdatingAttachments}/>}
                        />
                    </div>
                )
            })
            }
            {perinHealthModal.show && (
                <PerinHealthModal
                    show={perinHealthModal.show}
                    header={perinHealthModal.header}
                    message={perinHealthModal.message}
                    buttons={perinHealthModal.buttons}
                    handleButtonClick={handleModalOptions}
                />
            )}
        </div>
    );
}

export default NotesView;