import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../program-management/ProgramManagement.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setModality, removeModality } from './PatientModalitiesSlice';
import { setDevice, removeDevice, setFormValue, setHubSerialNumber } from './PatientEnrollmentSlice';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import { Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import inputStyles from '../../views/program-management/ProgramManagement.module.css';

const DeviceEnrollment = ({ errors }) => {
    const dispatch = useDispatch();
    const devices = useSelector((state) => state.enrollmentLists.devices);
    const deviceNotes = useSelector((state) => state.enrollPatientProgram.deviceNotes);
    const deviceList = useSelector((state) => state.enrollPatientProgram.devices);
    const { reenrolling, viewing } = useSelector((state) => state.enrollPatientProgram);
    const [count, setCount] = useState(0);
    const patientId = useSelector((state) => state.enrollPatientProgram.patient.id);
    const savedModalityValues = useSelector((state) => state.savedPatientModalities.patientRecentModalities[patientId]) ?? {};

    const hubs = useSelector(state => state.enrollPatientProgram.hubs);
    const [localHubSerialNumber, setLocalHubSerialNumber] = useState('');
    const [showHubSerialInput, setShowHubSerialInput] = useState(false);
    

    const initSelectedDevices = () => {
        const tempDevices = [];
        deviceList.forEach((item) => {
            let exisitingDeviceIndex = devices.findIndex(device => device.deviceId === item.deviceId);
            if (exisitingDeviceIndex !== -1) {
                tempDevices.push(devices[exisitingDeviceIndex]);
            } else {
                tempDevices.push({
                    id: item.deviceId, 
                    name: item.deviceName
                })
            }
        });
        return tempDevices;
    };
    const [selectedDevices, setSelectedDevices] = useState(initSelectedDevices());

    const getInitialStartTime = () => {
        const currentDate = new Date();
        currentDate.setHours(9,0,0,0);
        let utcTimeStamp = currentDate.toISOString();
        return utcTimeStamp;
    };

    const deviceInfo = {
        deviceId: '',
        serialNumber: '',
        deviceModalityId: 0,
        lowerLimit: 0,
        upperLimit: 0,
        targetValue: 0,
        isAlarmActive: false,
        startTime: getInitialStartTime(),
        frequency: 1,
        interval: "Day",
        readingType: "Automated",
        isContinuous: false
    };

    const modalityInfo = {
        deviceId: '',
        deviceModalityId: '',
        modalityId: '',
        modalityName: '',
        upperLimit: 0,
        lowerLimit: 0,
        targetValue: 0,
        isAlarmActive: false,
        isContinuous: false,
        startTime: getInitialStartTime(),
        frequency: 1,
        interval: "Day"
    };

    const checkSelectedItem = (model) => {
        return selectedDevices.some(item => item.model === model);
    };

    const handleDeviceChange = (device) => {
        const deviceInList = selectedDevices.some(item => item.deviceId === device.deviceId);
        if (deviceInList) {
            setSelectedDevices(selectedDevices.filter(item => item.deviceId !== device.deviceId));
            dispatch(removeDevice(device.deviceId));
            dispatch(removeModality(device.deviceId));
        } else {
            setSelectedDevices([device, ...selectedDevices]);
            let tempDevice = { ...deviceInfo, deviceId: device.deviceId };
            dispatch(setDevice(tempDevice));
            device.modalities.forEach((m) => {
                let tempModality = { 
                    ...modalityInfo, 
                    deviceId: device.deviceId, 
                    deviceModalityId: m.deviceModalityId,
                    modalityName: m.modalityName,
                    modalityId: m.modalityId 
                };
                if (savedModalityValues.hasOwnProperty(m.modalityId)) {
                    tempModality.upperLimit = savedModalityValues[m.modalityId].upperLimit;
                    tempModality.lowerLimit = savedModalityValues[m.modalityId].lowerLimit;
                    tempModality.targetValue = savedModalityValues[m.modalityId].targetValue;
                }
                dispatch(setModality(tempModality));
            });
        }
    };

    const removeDeviceCard = (id) => {
        setSelectedDevices(selectedDevices.filter(item => item.deviceId !== id));
        dispatch(removeDevice(id));
        dispatch(removeModality(id));
    };

    const handleNotes = (e) => {
        setCount(e.target.value.length);
        dispatch(setFormValue({ key: "deviceNotes", value: e.target.value }));
    };

    useEffect(() => {
        if (hubs?.length > 0 && hubs[0]?.serialNumber) {
            setLocalHubSerialNumber(hubs[0].serialNumber);
            setShowHubSerialInput(true);
        } else {
            setLocalHubSerialNumber('');
            setShowHubSerialInput(false);
        }
    }, [hubs]);    

    const handleToggleSerialInput = () => {
        setShowHubSerialInput(!showHubSerialInput);
    };

    const handleHubSerialNumberChange = (e) => {
        const newSerialNumber = e.target.value;
        setLocalHubSerialNumber(newSerialNumber);
        dispatch(setHubSerialNumber({ index: 0, serialNumber: newSerialNumber }));
    };
    
    const hubInfoTooltip = (
        <Tooltip id="hub-info-tooltip">
            The Hub is an enhanced patient monitoring device that integrates with wearable devices to ensure seamless data transfer.
        </Tooltip>
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <MultiSelectDropdown
                options={devices}
                label='model'
                checkedKey='model'
                checkSelectedItem={checkSelectedItem}
                handleChange={handleDeviceChange}
                selectedItems={selectedDevices}
                name='Devices'
                disabled={reenrolling || viewing}
            />
            <div className={styles.DropdownContainer} style={{ display: 'none' }}>
                <div className={showHubSerialInput ? styles.CardTitleOpened : styles.CardTitleClosed} style={{ display: "flex", alignItems: "center" }}>
                    <Form style={{ marginRight: "10px", display: 'none' }}>
                        <Form.Check
                            type="switch"
                            id="hubSerialToggle"
                            checked={showHubSerialInput}
                            onChange={handleToggleSerialInput}
                        />
                    </Form>
                    <span style={{
                        color: "#3F4346",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "28px",
                        display: 'none'
                    }} className={styles.CardTitleLabel}>Hub</span>
                    <OverlayTrigger placement="right" overlay={hubInfoTooltip}>
                        <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{ marginLeft: "10px", color: '#686869', display: 'none' }} />
                    </OverlayTrigger>
                    {showHubSerialInput && (
                        <input
                            type="text"
                            placeholder="Serial Number"
                            className={inputStyles.InputStyling}
                            value={localHubSerialNumber}
                            onChange={handleHubSerialNumberChange}
                            style={{ width: "158px", height: "38px", marginLeft: "10px" }}
                        />
                    )}
                </div>
            </div>
            {errors.showing &&
                <div className={styles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{ color: "red" }} />
                    <span className={styles.ValidationMessage}>{errors.message}</span>
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {selectedDevices.map((device, index) => (
                    <div key={device.deviceId}>
                        <ManagementDropdownCard
                            open={index === 0}
                            contentInfo={{ title: device.model, id: device.deviceId, modalities: device.modalities }}
                            cardType={"Device"}
                            removeHandler={removeDeviceCard}
                            updateHandler={setDevice}
                            dataList={deviceList}
                            patientEnrollment={true}
                            setModality={setModality}
                            errorShowing={errors}
                            disabled={reenrolling || viewing}
                        />
                    </div>
                ))}
                {selectedDevices.length >= 1 &&
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "300px", paddingTop: "20px" }}>
                        <span className={styles.FloatingLabel}>Notes</span>
                        <textarea className={styles.NoteInput}
                            id="description"
                            type="text"
                            maxLength={250}
                            disabled={viewing || reenrolling}
                            defaultValue={deviceNotes}
                            onChange={e => handleNotes(e)}
                        >
                        </textarea>
                        <span style={{ fontSize: "10px", color: "#929395", marginLeft: "auto" }}>
                            {count}/250
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default DeviceEnrollment;
