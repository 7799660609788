import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationId: null,
  name: '',
  description: '',
  category: '',
  createdBy: {
    accountId: null
  },
  permissions: [],
  isUpdateMode: false,
};

const addEditRoleSlice = createSlice({
  name: 'addEditRole',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (state.hasOwnProperty(key)) {
          state[key] = value;
        }
      });
    },
    setPermission: (state, action) => {
      const { permissionId } = action.payload;
      state.permissions = [{ permissionId }];
    },
    toggleUpdateMode: (state) => {
      state.isUpdateMode = !state.isUpdateMode;
    },
    resetAddEditRole: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const { setFieldValue, setPermission, toggleUpdateMode, resetAddEditRole } = addEditRoleSlice.actions;

export default addEditRoleSlice.reducer;
