import { createSlice } from '@reduxjs/toolkit';

const  initialState = {
    selectedRows: [],
    selectAll: false,
    reloadGrid: true,
};
const contentManagementSlice = createSlice({
    name: 'contentManagement',
    initialState,
    reducers: {
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setReloadGrid: (state, action) => {
            state.reloadGrid = action.payload;
        },
        resetContentManagement: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const { setSelectAll, setSelectedRows, setReloadGrid , resetContentManagement} = contentManagementSlice.actions;

export default contentManagementSlice.reducer;
