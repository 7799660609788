import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LeftArrowTriangleIcon } from '../../components/icons/Icons';
import styles from './AppTour.module.css';
import { setFieldValue } from './AppTourSlice';

const StepPopup = ({
    handleClose,
    handleNext,
    handleBack,
    targetRef,
    title,
    content,
    isFirstStep,
    isLastStep,
    currentStepIndex,
    tourStepsLength
}) => {
    const dispatch = useDispatch();
    const appTour = useSelector((state) => state.appTour);
    const [popupStyle, setPopupStyle] = useState({});
    const scrollTimeoutRef = useRef(null);
    const modalBodyRef = useRef(null);  // Reference to the modal body

    const updatePosition = useCallback(() => {
        const targetPosition = targetRef?.current?.getBoundingClientRect() || { top: 0, left: 0 };
        const windowHeight = window.innerHeight;
        const modalBodyHeight = modalBodyRef.current ? modalBodyRef.current.getBoundingClientRect().height : 110;
        const calculateHeight = (modalBodyHeight < 110 ? 135 : (135 + ((modalBodyHeight - 110) / 2)));
        let top = targetPosition.top + window.scrollY - calculateHeight;

        if (targetPosition.top - calculateHeight > windowHeight) {
            top = targetPosition.top + window.scrollY - 245;
        }
    
        // Ensure the top position doesn't go out of the bottom window boundary
        if (top + 290 > windowHeight) {
            top = windowHeight - 290;
        }

        setPopupStyle({
            position: 'absolute',
            top: top,
            width: '1080px',
            height: 'auto',
        });

        dispatch(setFieldValue({ name: 'navigateToItem', value: false }));
    }, [dispatch, targetRef]);

    useEffect(() => {
        updatePosition();
        const handleScroll = () => {
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
            scrollTimeoutRef.current = setTimeout(() => {
                requestAnimationFrame(updatePosition);
            }, 100);
        };

        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [updatePosition]);

    useEffect(() => {
        if (appTour.navigateToItem) {
            updatePosition();
        }
    }, [appTour.currentStepIndex, appTour.navigateToItem, updatePosition]);

    const arrowStyle = {
        position: 'absolute',
        top: '50%',
        left: '-25px',
        transform: 'translateY(-50%)',
    };

    const squareStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '-290px',
        width: '270px',
        height: '40px',
        backgroundColor: '#fff',
        border: '2px solid white',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        paddingLeft: '10px',
        fontWeight: 'bold',
    };

    return (
        <Modal
            className="modal"
            show={appTour.showAppTour}
            onHide={handleClose}
            style={popupStyle}
            size="md"
        >
            <Modal.Header closeButton className={styles.ModelHeader}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modalBodyRef}> {/* Add reference to modal body */}
                <div style={squareStyle}>{title}</div>
                <div style={arrowStyle}>
                    <LeftArrowTriangleIcon width="70" height="70" />
                </div>
                <p style={{ fontWeight: '400' }}>{content}</p>
                <p>{`${currentStepIndex + 1} of ${tourStepsLength}`}</p>
            </Modal.Body>
            <Modal.Footer className={styles.ModelFooter}>
                <>
                    <Button onClick={handleBack} className={styles.BackButton}>
                        {isFirstStep ? 'Skip' : 'Back'}
                    </Button>
                    <Button onClick={handleNext} className={styles.NextButton}>
                        {isLastStep ? 'Finish' : 'Next'}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};

export default StepPopup;
