import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './PatientEnrollment.module.css';
import ManagementDropdownCard from '../../components/ManagementDropdownCard';
import useAxios from '../../services/AxiosConfig';

const ReviewEnrollment = () => {

    const data = useSelector((state) => state.enrollPatientProgram);

    const axiosConfig = useSelector((state) => state.axiosConfig);
    const httpService = useAxios(axiosConfig.envURL);

    const getPatientGroups = () => {
        httpService.get(`list/groups/${data.patient.id}`)
            .then((responseData) => {
                if (Array.isArray(responseData.data.data)) {
                    let allGroups = [];
                    responseData.data.data.forEach(org => {
                        org.groups.forEach(group => {
                            if (group.groupId !== null)
                                allGroups.push({
                                    name: group.groupName,
                                    id: group.groupId,
                                    subgroups: group.subgroups
                                });
                        });
                    });
                    setAllPatientGroups(allGroups);
                } else {
                    console.error("Can't fetch patient groups");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getPatientGroups()
    }, []);

    const [allPatientGroups, setAllPatientGroups] = useState([]);
    
    const formatDateFromUTC = (date) => {
        if (date === "") return "";
        if (date.length === 10) return date;
        const utcDate = new Date(date);
        const localDate = utcDate.toLocaleString();
        const splitDate = localDate.split(',')[0].split('/');
        let retDate = new Date(splitDate[2], splitDate[0] - 1, splitDate[1]);
        let formattedDate = retDate.getFullYear() + '-' +
            ('0' + (retDate.getMonth() + 1)).slice(-2) + '-' +
            ('0' + retDate.getDate()).slice(-2);
        return formattedDate;
    };
    const admitDisplayDate = formatDateFromUTC(data.enrollment.startDate);
    const dischargeDateDisplay = formatDateFromUTC(data.enrollment.endDate);
    
    const hubSerialNumber = (data.hubs?.length > 0 && data.hubs[0]?.serialNumber) ? data.hubs[0].serialNumber : "";
    
    const initTeamNames = () => {
        let retString = "";
        let patientTeams = Array.from(
            new Set(
              allPatientGroups.flatMap((group) => 
                group.subgroups.flatMap((subgroup) => 
                  subgroup.teams
                )
              )
            )
        );
        data.enrollment.groups.forEach((team)=> {
            if (team.teamName) {
                retString += `${team.teamName}, `;
            } else {
                let foundTeam = patientTeams.find((t) => t.teamId == team.teamId)
                retString += `${foundTeam?.teamName}, `;
            }
        })
        return retString.slice(0, -2);
    };

    const teamNames = initTeamNames();

    const initGroupNames = () => {
        let retString = "";
        const uniqueGroups = Array.from(
            new Set(data.enrollment.groups.map((group) => group.groupId))
        );
        uniqueGroups.forEach((group)=> {
            let foundGroup = allPatientGroups.find((g) => g.id == group)
            retString += `${foundGroup?.name}, `;
        })
        return retString.slice(0, -2);
    };

    const groupNames = initGroupNames();

    const errorMessages = {
        program:{patientShowing:false,programShowing:false,durationShowing:false,groupShowing:false,},
        device:{showing:false, modalityShowing: false,modalityErrors: [],deviceIdErrors: []
        },
        rules: {repeatNotificationShowing: false,repeatModalityFrequencyShowing: false,
        },
        questionnaire:{showing:false},
        content:{contentShowing:false,programShowing:false,},
    };

    const {devices, questionnaires, content} = useSelector((state) => state.enrollmentLists);

    const deviceList = data.devices;
    const initSelectedDevices = () => {
        const tempDevices = [];
        deviceList.forEach((item) => {
            let exisitingDeviceIndex = devices.findIndex(device => device.deviceId === item.deviceId);
            if (exisitingDeviceIndex !== -1) {
                tempDevices.push(devices[exisitingDeviceIndex]);
            } else {
                tempDevices.push({
                    id: item.deviceId, 
                    name: item.deviceName
                })
            }
        });
        return tempDevices;
    };
    const selectedDevices = initSelectedDevices();

    const initModalities = () => {
        const modalitiesList = [];
        deviceList.forEach((device)=>{
            let foundDevice = devices.find(item => item.deviceId === device.deviceId)
            if (foundDevice) {
                foundDevice.modalities.forEach((mod)=>{
                    modalitiesList.push({id: mod.modalityId, name:mod.modalityName})
                })
            }
        })
        return modalitiesList;
    };
    const allModalities = initModalities();
    const { notifications, modalityFrequencies } = useSelector((state)=>state.patientEnrollmentModalities);

    const questionnaireList = data.questionnaires;
    const initSelectedQuestionnaires = () => {
        // check to see if questionnaire already exists in the state,
        const tempQs = [];
        questionnaireList.forEach((item)=> {
            let existingQIndex = questionnaires.findIndex(q => q.questionnaireId === item.questionnaireId);
            if (existingQIndex !== -1){
                // if addProgram.questionnaire has a match in the questionnaire list, add to selectedQuestionnaires
                tempQs.push(questionnaires[existingQIndex]);
            } else {
                tempQs.push({
                    id: item.questionnaireId,
                    name: item.questionnaireName
                })
            }
        })
        return tempQs;
    };
    const selectedQuestionnaires = initSelectedQuestionnaires();

    const contentList = data.contents;
    const initSelectedContent = () => {
        // check to see if content already exists in the state,
        const tempContent = [];

        contentList.forEach((item, index)=> {
            let existingContentIndex = content.findIndex(content => content.id === item.contentId);
            if (existingContentIndex !== -1){
                // if addProgram.content has a match in the content list, add to selectedContent
                tempContent.push(content[existingContentIndex]);
            } else {
                tempContent.push({
                    id: item.contentId, 
                    name: item.contentName
                })
            }
        });
        return tempContent;
    };
    const selectedContent = initSelectedContent();
    
    return (
        <div style={{display:"flex", flexDirection:"column", gap:"5px"}}>
            <div>
                <span className="subtitle-2">Patient</span>
                <div className="body-2">{`${data.patient.firstName} ${data.patient.lastName}`}</div>
            </div>
            <hr></hr>
            <span className="subtitle-2">Program Information</span>
            <div>{`Program Name: ${data.name}`}</div>
            <div>{`Group: ${groupNames}`}</div>
            <div>{`Team: ${teamNames}`}</div>
            <div>{`Notes: ${data.description}`}</div>
            <div>{`Duration: ${data.duration} days`}</div>
            <div>{`Admit Date: ${admitDisplayDate}`}</div>
            <div>{`Expected Discharge Date: ${dischargeDateDisplay}`}</div>
            <hr></hr>
            <span className="subtitle-2">Device Setup</span>
            {hubSerialNumber !== "" && 
                <>
                <span>Hub</span>
                <div>{`Serial Number:: ${hubSerialNumber}`}</div>
                </>
            }
            {selectedDevices.length > 0 ?
                <>
                    <div className={styles.viewProgramContentCards}>
                    {selectedDevices.map((device, index) => (
                        <div key={index}>
                            <ManagementDropdownCard 
                                open={index === 0}
                                contentInfo={{title:device.model, id:device.deviceId, modalities:device.modalities}} 
                                cardType={"Device"} 
                                dataList={deviceList}
                                patientEnrollment={true}
                                viewOnly={true}
                                errorShowing={errorMessages.device}
                            />
                        </div>
                    ))}
                    </div>
                    <div>{`Notes: ${data.deviceNotes}`}</div>
                </>
                :
                <div>{"None"}</div>
            }
            <hr></hr>
            <span className="subtitle-2">Enrollment Rules</span>
            {notifications.length > 0 &&
                <>
                    <span>Notification</span>
                    {notifications.map((not) => {
                        const modalityName = allModalities.find((mod)=>mod.id === not.modalityId)?.name;
                        return (
                            <span key={not.ruleId} style={{paddingLeft: "10px"}}>
                            {` - Notify me if ${modalityName} is outside the threshold ${not.thresholdCount} times in ${not.timeFrequency} ${not.timeInterval}`}
                            </span>
                        )
                    })}
                </>
            }
            {modalityFrequencies.length > 0 &&
                <>
                    <span>Modality Frequency</span>
                    {modalityFrequencies.map((mod) => {
                        const modalityName = allModalities.find((m)=>m.id === mod.modalityId)?.name;
                        return (
                            <span key={mod.ruleId} style={{paddingLeft: "10px"}}>
                            {` - Notify me if ${modalityName} is ${mod.thresholdType} the threshold ${mod.thresholdCount} times in ${mod.timeFrequency} ${mod.timeInterval} increase frequency for ${mod.frequencyMultiplier}x for ${mod.frequencyDurationValue} hour`}
                            </span>
                        )
                    })}
                </>
            }
            {modalityFrequencies.length == 0 && notifications.length == 0 && 
            <div>{"None"}</div>
            }
            <hr></hr>
            <span className="subtitle-2">Questionnaire Setup</span>
            {selectedQuestionnaires.length > 0 ? 
                <>
                    <div className={styles.viewProgramContentCards}>
                        {selectedQuestionnaires.map((q, index) => (
                            <div key={q.questionnaireId}>
                                <ManagementDropdownCard 
                                    open={index!==0 ? false : true} 
                                    contentInfo={{title:q.name, id:q.questionnaireId}}
                                    cardType={"Questionnaire"} 
                                    dataList={questionnaireList}
                                    viewOnly={true}
                                    errorShowing={errorMessages.questionnaire}
                                />
                            </div>
                        ))}
                    </div>
                    <div>{`Notes: ${data.questionnaireNotes}`}</div>
                </>
                :
                <div>{"None"}</div>
            }
            <hr></hr>
            <span className="subtitle-2">Content Setup</span>
            {selectedContent.length > 0 ?
                <>
                    <div className={styles.viewProgramContentCards}>
                        {selectedContent.map((content, index) => (
                            <div key={content.id}>
                                <ManagementDropdownCard 
                                    open={index!==0 ? false : true} 
                                    contentInfo={{title:content.name, id:content.id}}
                                    cardType={"Content"} 
                                    dataList={contentList}
                                    viewOnly={true}
                                    errorShowing={errorMessages.content}
                                />
                            </div>
                        ))}
                    </div>
                    <div>{`Notes: ${data.contentNotes}`}</div>
                </>
                :
                <div>{"None"}</div>
            }
        </div>
    )
}

export default ReviewEnrollment;