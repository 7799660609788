import React, { useState, useEffect } from 'react';

const allPermissions = {
    admin: [
        'Patient Dashboard',
        'Patient Enrollment',
        'Appointments',
        'Billing',
        'Tasks',
        'Add New Organization',
        'Organization Hierarchy Management',
        'Account Management',
        'Role Management',
        'Reference Code Management',
        'Hub Management',
        'Device Management',
        'Content Management',
        'Questionnaire Management',
        'Program Management',
        'Patient Messages',
        'Patient Medical Reports',
        'Care Team Messages',
    ],
    staff: [
        'Patient Dashboard',
        'Patient Enrollment',
        'Appointments',
        'Tasks',
        'Account Management',
        'Billing',
        'Patient Messages',
        'Patient Medical Reports',
        'Care Team Messages',
    ],
    nurse: [
        'Patient Dashboard',
        'Patient Enrollment',
        'Appointments',
        'Tasks',
        'Account Management',
        'Billing',
        'Patient Messages',
        'Patient Medical Reports',
        'Care Team Messages',
    ],
    doctor: [
        'Patient Dashboard',
        'Patient Enrollment',
        'Appointments',
        'Tasks',
        'Account Management',
        'Billing',
        'Patient Messages',
        'Patient Medical Reports',
        'Care Team Messages',
    ],
    patient: [
        'Appointments',
        'Tasks',
        'Messages',
    ],
};

const ManageRolePermissions = ({ roleType, initialPermissions = [], updatePermissions }) => {
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        // Initialize permissions based on the role type
        const updatedPermissions = allPermissions[roleType].reduce((acc, permission) => {
            acc[permission] = initialPermissions.length === 0 ? true : initialPermissions.includes(permission);
            return acc;
        }, {});
        setPermissions(updatedPermissions);
    }, []);

    useEffect(() => {
        updatePermissions(Object.keys(permissions).filter(key => permissions[key]));
    }, [permissions]);

    const handlePermissionChange = (permission) => {
        setPermissions(prevPermissions => {
            const updatedPermissions = {
                ...prevPermissions,
                [permission]: !prevPermissions[permission]
            };
            return updatedPermissions;
        });
    };

    return (
        <div className="permissions-container">
            <div className="permissions-title">Manage Role Permissions</div>
            {allPermissions[roleType].map(permission => (
                <div key={permission} className="permission-checkbox">
                    <input
                        type="checkbox"
                        id={permission}
                        name={permission}
                        checked={permissions[permission] || false}
                        onChange={() => handlePermissionChange(permission)}
                    />
                    <label htmlFor={permission}>{permission}</label>
                </div>
            ))}
        </div>
    );
};

export default ManageRolePermissions;