import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Form, FloatingLabel } from 'react-bootstrap';
import { setProgramGroupId, setProgramName, setProgramDescription, setProgramDuration } from './AddProgramSlice';
import managementStyles from '../ManagementPages.module.css';

const ProgramSetup = ({errors, selectedOrg, setSelectedOrg}) => {

    const dispatch = useDispatch();
    const groupData = useSelector((state) => state.userInfo.groups);
    const uniqueGroups = useSelector((state) => state.userInfo.uniqueGroups);
    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const programData = useSelector((state) => state.addProgram);

    const showGroupError = errors.groupShowing && (programData.program.groupId === 0 || programData.program.groupId === "");
    const showNameError = errors.nameShowing && programData.program.name === "";

    useEffect(() => {
        const initGroupId = (programData.program.groupId === "" && uniqueGroups.length === 1) ? uniqueGroups[0].id : programData.program.groupId;
        dispatch(setProgramGroupId(initGroupId));
    }, [groupData]);
    
    const [count, setCount] = useState(0);

    const handleNotes = (e) => {
      setCount(e.target.value.length);
      dispatch(setProgramDescription(e.target.value));
    };

    const handleOrganization = (e) => {
        setSelectedOrg(Number(e.target.value));
        let tempGroups = [];
        groupData.filter(organization => organization.organizationId === Number(e.target.value))
                .forEach((organization) => (
                    organization.groups.map((group) => (
                        tempGroups.push(group.groupId)
            ))
        ))
        let newGroupId = 0;
        if (tempGroups.length >= 1) {
            newGroupId = Number(tempGroups[0]);
        }
        dispatch(setProgramGroupId(newGroupId));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"300px"}}>
            <Container style={{padding:"0"}}>
                <Form className={managementStyles.FormContainer}>
                    {isAdmin &&
                        <Form.Group className="mb-3">
                            <FloatingLabel label="Organization">
                            <Form.Select
                                className={`${managementStyles.BorderedComponent}`}
                                placeholder={selectedOrg}
                                disabled={programData.updating}
                                value={selectedOrg}
                                style={showGroupError ? {border:"1px solid red", width: "300px"} : {width: "300px"}}
                                onChange={handleOrganization}
                            >
                                <option key={0} value={0}>Select an Organization</option>
                                {groupData.map((org)=> (
                                    <option key={org.organizationId} id={org.organizationId} value={org.organizationId}>{org.organizationName}</option>
                                ))}
                            </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Group*">
                        <Form.Select
                            className={managementStyles.BorderedComponent}
                            id="groupId"
                            disabled={programData.updating}
                            value={programData.program.groupId}
                            style={showGroupError ? {border:"1px solid red",width: "300px"} : {width: "300px"}}
                            onChange={(e) => dispatch(setProgramGroupId(Number(e.target.value)))}
                        >
                            {isAdmin ? 
                                groupData.filter(organization => organization.organizationId === selectedOrg)
                                    .map((organization) => (
                                        organization.groups.map((group) => (
                                            <option key={group.groupId} value={group.groupId}>
                                            {group.groupName}
                                            </option>
                                        ))
                                    ))
                                :
                                uniqueGroups.map((group)=> (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))
                            }
                        </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel 
                            label="Program Name*"
                            className="mb-3"
                        >
                        <Form.Control
                            className={`${managementStyles.BorderedComponent}`}
                            placeholder="Program Name*"
                            id="programName" 
                            style={showNameError ? {border:"1px solid red", width: "300px"} : {width: "300px"}}
                            value={programData.program.name}
                            onChange={(e) => dispatch(setProgramName(e.target.value))}
                        />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel 
                            label="Description"
                            className="mb-3"
                        >
                        <Form.Control
                            maxLength={250}
                            value={programData.program.description}
                            onChange={e => handleNotes(e)}
                            as="textarea"
                            placeholder='Description'
                            rows={2}
                            style={{width:"300px"}}
                            className={`${managementStyles.BorderedComponentTextArea}`}
                        />
                        <div className={managementStyles.DescriptionCount}>
                            {count}/250
                        </div>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                            <FloatingLabel 
                                label="Duration"
                                className="mb-3"
                            >
                                <Form.Control
                                    placeholder="Quantity*"
                                    className={`${managementStyles.BorderedComponent}`}
                                    id="duration"
                                    type="text"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    onKeyDown={(e) => {
                                        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                            e.preventDefault();
                                        }
                                    }}
                                    defaultValue={programData.program.duration}
                                    style={{width:"140px"}} 
                                    onChange={(e) => dispatch(setProgramDuration(Number(e.target.value)))}
                                />
                            </FloatingLabel>
                            <span style={{position: 'relative', top: '-9px'}}>Days</span>
                        </div>
                    </Form.Group>
                </Form>
            </Container>
        </div>
    )
}

export default ProgramSetup;