import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalities: [],
};

const modalitiesSlice = createSlice({
    name: "modalities",
    initialState,
    reducers: {
        resetModalitiesState: (state) => {
            Object.assign(state, initialState);
        },
        setModality : (state, action) => {
            const { deviceId, deviceModalityId, ...rest } = action.payload;
            const existingModalityIndex = state.modalities.findIndex(mod => (mod.deviceId === deviceId && mod.deviceModalityId === deviceModalityId));
            if (existingModalityIndex !== -1) {
                const updatedMod = state.modalities.map((mod, index) => 
                    index === existingModalityIndex ? { ...mod, ...rest} : mod
                )
                return { ...state, modalities: updatedMod };
            } else {
                const newModality = { deviceId: deviceId, deviceModalityId: deviceModalityId, ...rest };
                return { ...state, modalities: [...state.modalities, newModality] };
            }
        },
        removeModality : (state, action) => {
            const deviceId = action.payload;
            const updatedModalities = state.modalities.filter(item => item.deviceId !== deviceId);
            state.modalities = updatedModalities;
        },

        setModalitiesState: (state, action) => {
            const { programDevices, devicesList} = action.payload;
            let uniqueDeviceIds = [];
            if (programDevices.length >= 1) {
                programDevices.forEach(device => {
                    if (!uniqueDeviceIds.some(e => e.deviceId === device.deviceId)) {
                        uniqueDeviceIds.push(device);
                    }
                });
            };

            const getInitialStartTime = () => {
                const currentDate = new Date();
                currentDate.setHours(9,0,0,0);
                let utcTimeStamp = currentDate.toISOString();
                return utcTimeStamp;
            };

            const modalityInfo = {
                deviceId: '',
                deviceModalityId: '',
                upperLimit: 0,
                lowerLimit: 0,
                targetValue: 0,
                isContinuous: false,
                startTime: getInitialStartTime(),
                frequency: 1,
                interval: "Day",
                isAlarmActive: false
            };

            let newModalities = [];
            uniqueDeviceIds.forEach((device)=> {
                // get all modalities for a device
                let modalitiesFound = devicesList.find(item => item.deviceId === device.deviceId);
                if (modalitiesFound) {
                    let modalities = modalitiesFound.modalities;
                    // if a modality is already listed in the program, save that data
                    // otherwise, save the modality with 0 in place of values
                    let modalityVals = modalities.map((mod) => {
                        let tempMod = {...modalityInfo};
                        tempMod['deviceModalityId'] = mod.deviceModalityId;
                        tempMod['deviceId'] = device.deviceId;
                        let found=programDevices.find((element)=>element.deviceModalityId===mod.deviceModalityId);
                        if (found) {
                            tempMod['upperLimit'] = found.upperLimit;
                            tempMod['lowerLimit'] = found.lowerLimit;
                            tempMod['targetValue'] = found.targetValue;
                            tempMod['isAlarmActive'] = found.isAlarmActive;
                            tempMod['templateId'] = found.templateId;
                            tempMod['isContinuous'] = found.isContinuous;
                            tempMod['startTime'] = found.startTime;
                            tempMod['frequency'] = found.frequency;
                            tempMod['interval'] = found.interval;
                        }
                        return tempMod;
                    })
                    newModalities = [...newModalities, ...modalityVals];
                }
            })
            return {
                modalities: newModalities
            };
        },
    }
});

export const { resetModalitiesState, setModality, removeModality, setModalitiesState } = modalitiesSlice.actions;
  
  export default modalitiesSlice.reducer;