import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
};

const cardFilterSlice = createSlice({
  name: 'dashboardCardFilter',
  initialState,
  reducers: {
    setActiveCardFilter: (state, action) => {
      state.name = action.payload;
    },
    resetCardFilterState: (state) => {
        Object.assign(state, initialState);
    },
  },
});

export const { setActiveCardFilter, resetCardFilterState } = cardFilterSlice.actions;
export default cardFilterSlice.reducer;