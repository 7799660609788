import React, { useState, createContext, useContext, useEffect } from 'react';
import { ExpandedArrow, CollapsedArrow } from '../components/icons/Icons';
import styles from './OrganizationTreeSelect.module.css';

const SelectionContext = createContext();

export const SelectionProvider = ({ children, initialSelections = [] }) => {
  const generateDistinctCombinations = (paths) => {
    const allPathsSet = new Set();

    paths.forEach(path => {
      let combination = [];
      path.forEach((item, index) => {
        combination.push(item);
        allPathsSet.add(JSON.stringify([...combination])); // Add each combination to the set
      });
    });

    // Convert the set back to an array of arrays
    return Array.from(allPathsSet).map(item => JSON.parse(item));
  };

  // Generate initial paths including O+G, O+G+S, and O+G+S+T
  const initialPaths = generateDistinctCombinations(initialSelections.map(selection => {
    const path = [];
    if (selection.organizationId) path.push(`O${selection.organizationId}`);
    if (selection.groupId) path.push(`G${selection.groupId}`);
    if (selection.subGroupId) path.push(`S${selection.subGroupId}`);
    if (selection.teamId) path.push(`T${selection.teamId}`);
    return path;
  }));

  const [selectedPaths, setSelectedPaths] = useState(initialPaths);

  const determineChildType = (child) => {
    if (child.subgroups) return 'G';
    if (child.teams) return 'S';
 
    return 'T';
  };

  const extractSelectedTeamsInfo = () => {
    const teamInfo = selectedPaths.map(path => {
      const pathIds = path.map(p => ({
        type: p.substring(0, 1),
        id: parseInt(p.substring(1), 10)
      }));
      
      const team = pathIds.find(p => p.type === 'T');
      const subgroup = pathIds.find(p => p.type === 'S');
      const group = pathIds.find(p => p.type === 'G');
      const organization = pathIds.find(p => p.type === 'O');
  
      return {
        organizationId: organization ? organization.id : 0,
        groupId: group ? group.id : 0,
        subGroupId: subgroup ? subgroup.id : 0,
        teamId: team ? team.id : 0
      };
    });
  
    return { groups: teamInfo };
  };

  const toggleSelection = (nodeId, path, children = [], type) => {
    setSelectedPaths(prev => {
      const pathAsString = JSON.stringify(path);
      const exists = prev.some(p => JSON.stringify(p) === pathAsString);
  
      if (exists) { // Deselect the node and all its children
        let newPaths = prev.filter(p => !p.some(id => id === nodeId));
  
        // Recursively remove all child paths
        const removeChildPaths = (paths, node, nodeType) => {
          const nodeChildren = node.groups || node.subgroups || node.teams || [];
          nodeChildren.forEach(child => {
            const childNodeId = `${nodeType[0].toUpperCase()}${child.organizationId || child.groupId || child.subGroupId || child.teamId}`;
            paths = paths.filter(p => !p.includes(childNodeId));
            const childType = determineChildType(child); // Determine the child type
            paths = removeChildPaths(paths, child, childType);
          });
          return paths;
        };
  
        newPaths = removeChildPaths(newPaths, { groups: children }, type);
        return newPaths;
      } else { // Select the node
        return [...prev, path];
      }
    });
  };

  return (
    <SelectionContext.Provider value={{ selectedPaths, toggleSelection, extractSelectedTeamsInfo }}>
      {children}
    </SelectionContext.Provider>
  );
};

const TreeNode = ({ node, path = [], type }) => {
  const { selectedPaths, toggleSelection } = useContext(SelectionContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [forcedRender, setForcedRender] = useState(false); // Force re-render

  const nodeId = `${type[0].toUpperCase()}${node.organizationId || node.groupId || node.subGroupId || node.teamId}`;
  const nodeName = node.organizationName || node.groupName || node.subGroupName || node.teamName;
  const children = node.groups || node.subgroups || node.teams || [];
  const hasChildren = children.length > 0;

  const isChecked = selectedPaths.some(selectedPath => selectedPath.includes(nodeId));

  const handleCheck = () => {
    const updatedPath = [...path, nodeId];
    toggleSelection(nodeId, updatedPath, children, type);
    setForcedRender(!forcedRender); // Force re-render to sync UI
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

    const determineChildType = (child) => {
    if (child.subgroups) return 'G';
    if (child.teams) return 'S';
 
    return 'T';
  };

  const borderStyle = type === 'O' ? styles.organizationBorder : type === 'G' ? styles.groupBorder : '';

  return (
    <li className={`${styles.styleTypeNone} ${borderStyle}`}>
      <div style={{ display: 'flex', alignItems: 'center', height: "52px" }}>
        {hasChildren && (
          <div onClick={toggleExpand} style={{ marginRight: '10px', cursor: 'pointer' }}>
            {isExpanded ? <ExpandedArrow /> : <CollapsedArrow />}
          </div>
        )}
        <input 
          type="checkbox" 
          checked={isChecked} 
          onChange={handleCheck} 
          onClick={(e) => e.stopPropagation()} // Ensure the click event doesn't bubble up
        /> 
        <span className={styles.ListText}>{nodeName}</span>
      </div>
      {isExpanded && hasChildren && (
        <ul className={styles.styleTypeNone}>
          {children.map((child) => {
            const childId = child.organizationId || child.groupId || child.subGroupId || child.teamId;
            const childType = determineChildType(child);
            return (
              <TreeNode
                key={`${childType[0].toUpperCase()}${childId}`}
                node={child}
                path={[...path, nodeId]}
                type={childType}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
};

const OrganizationTreeSelect = ({ organizations }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);

  useEffect(() => {

    const filterTree = (node) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
    
      // recursively checks each node to see if it or any of its children contains the search term. It returns the node if it matches or has children that match.
      const nodeMatchesSearchTerm = (n) => {
        return n.organizationName?.toLowerCase().includes(lowerSearchTerm) ||
               n.groupName?.toLowerCase().includes(lowerSearchTerm) ||
               n.subGroupName?.toLowerCase().includes(lowerSearchTerm) ||
               n.teamName?.toLowerCase().includes(lowerSearchTerm);
      };
  
      const matchCurrentNode = nodeMatchesSearchTerm(node);
    
      // identify the correct key for children based on the node type.
      const childrenKey = node.groups ? 'groups' : node.subgroups ? 'subgroups' : node.teams ? 'teams' : null;
      let children = [];
      if (childrenKey) {
        children = node[childrenKey].map(filterTree).filter(Boolean); //filter tree and remove nulls
      }
    
      // If the current node matches or any of its children match, then keep this node
      if (matchCurrentNode || children.length > 0) {
        // If aleady matching, create a new object for the node that only includes matching children
        return { ...node, [childrenKey]: children };
      }
    
      // If there's no match at this node or its children, return null
      return null;
    };

    // Apply the filter to each organization
    if (searchTerm) {
      const filtered = organizations.map(filterTree).filter(Boolean); 
      setFilteredOrganizations(filtered);
    } else { // SHow all
      setFilteredOrganizations(organizations); 
    }
  }, [searchTerm, organizations]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div style={{height:"490px", overflowY:"scroll"}}>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        className={styles.SearchInput}
      />
      <ul>
        {filteredOrganizations.map((org) => (
          <TreeNode key={org.organizationId} node={org} type="O" path={[]} />
        ))}
      </ul>
    </div>
  );
};

// Hook to get selected teams
export const useSelectedTeams = () => {
    const context = useContext(SelectionContext);
    if (!context) {
        throw new Error('useSelectedTeams must be used within a SelectionProvider');
    }
    
    return context.extractSelectedTeamsInfo;
};

export default OrganizationTreeSelect;