import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  selectedGroup: '',
  selectedLocation: '',
  selectedTeam: '',
  selectedOrganization: '',
  organizations: [],
  groups: [],
  locations: [],
  teams: [],
  selectedCard: ''
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    resetFilterState: (state) => {
      return {
        ...state,
        searchText: initialState.searchText,
        selectedGroup: initialState.selectedGroup,
        selectedLocation: initialState.selectedLocation,
        selectedTeam: initialState.selectedTeam,
        selectedOrganization: initialState.selectedOrganization,
      };
    },

    setFilter: (state, action) => {
      state.searchText = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },

    setGroup: (state, action) => {
      state.groups = action.payload;
    },
    setLocation: (state, action) => {
      state.locations = action.payload;
    },
    setTeam: (state, action) => {
      state.teams = action.payload;
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
  },
});

export const {
  resetFilterState,
  setFilter,
  setSearchText,
  setSelectedGroup,
  setSelectedLocation,
  setSelectedTeam,
  setSelectedOrganization,
  setOrganizations,
  setGroup,
  setLocation,
  setTeam,
  setSelectedCard
} = filterSlice.actions;

export default filterSlice.reducer;
