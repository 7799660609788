import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './PatientEnrollment.module.css';
import NotificationSetupCard from './Components/NotificationSetupCard';
import { PlusIcon } from '../../components/icons/Icons';
import ModalityFrequencySetupCard from './Components/ModalityFrequencySetupCard';
import { setStateValue } from './PatientModalitiesSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import errorStyles from '../program-management/ProgramManagement.module.css';

const EnrollmentRules = ({errors}) => {
    const deviceList = useSelector((state)=>state.enrollmentLists.devices);
    const dispatch = useDispatch();
    const { devices, viewing } = useSelector((state)=>state.enrollPatientProgram);
    const modState = useSelector((state)=>state.patientEnrollmentModalities);
    const { notifications, modalityFrequencies } = useSelector((state)=>state.patientEnrollmentModalities);
    const initModalities = () => {
        const modalitiesList = [];
        devices.forEach((device)=>{
            let foundDevice = deviceList.find(item => item.deviceId === device.deviceId)
            if (foundDevice) {
                foundDevice.modalities.forEach((mod)=>{
                    modalitiesList.push({value: mod.modalityId, label:mod.modalityName})
                })
            }
        })
        return modalitiesList;
    };

    const allModalities = initModalities();

    const notificationTemplate = {
        ruleType: "enrollment",
        actionType: "sendNotification",
        modalityId: 0,
        thresholdCount: 1,
        timeFrequency: 1,
        timeInterval: "day",
        thresholdType: "outside",
    };

    const modalityFrequencyTemplate = {
        ruleType: "enrollment",
        actionType: "increaseFrequency",
        modalityId: 0,
        thresholdType: "above",
        thresholdCount: 1,
        timeFrequency: 1,
        timeInterval: "day",
        frequencyMultiplier: 1,
        frequencyDurationValue: 1,
        frequencyDurationUnit: "hour"
    };

    const addNotification = () => {
        const newNotification = notificationTemplate;
        const tempNotifications = [...notifications];
        tempNotifications.push(newNotification);
        dispatch(setStateValue({key:"notifications",value:tempNotifications}));
    };

    const addModalityFrequency = () => {
        const newModalityFrequency = modalityFrequencyTemplate;
        const tempModalityFrequencies = [...modalityFrequencies];
        tempModalityFrequencies.push(newModalityFrequency);
        dispatch(setStateValue({key:"modalityFrequencies",value:tempModalityFrequencies}));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
            <span className="subtitle-2" style={{color:"#4F4F4F", paddingTop:"10px"}}>{"Notification"}</span>
            <span className="body-2" style={{color:"#4F4F4F", paddingBottom:"10px"}}>{`Set notification frequency for the devices ${devices.length===0 ? " - No Devices Selected" : ""}`}</span>
            {errors.repeatNotificationShowing &&
                <div className={errorStyles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{ color: "red" }} />
                    <span className={errorStyles.ValidationMessage}>{errors.repeatNotificationMessage}</span>
                </div>
            }
            {devices.length!==0 &&
                <div style={{display:"flex", flexDirection:"column", gap:"10px", paddingLeft: "10px"}}>
                    {notifications.map((notification, index)=>(
                        <NotificationSetupCard options={allModalities} modalityId={notification.modalityId} key={index}/>
                    ))}
                    {!viewing &&
                        <button onClick={addNotification} className={styles.AddNotificationButton}>
                            <PlusIcon color="#00162E"/>Add
                        </button>
                    }
                </div>
            }
            <span className="subtitle-2" style={{color:"#4F4F4F", paddingTop:"10px"}}>{"Modality Frequency"}</span>
            {errors.repeatModalityFrequencyShowing &&
                <div className={errorStyles.ValidationMessageContainer}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="xs" style={{ color: "red" }} />
                    <span className={errorStyles.ValidationMessage}>{errors.repeatModalityFrequencyMessage}</span>
                </div>
            }
            {devices.length!==0 &&
                <div style={{display:"flex", flexDirection:"column", gap:"10px", paddingLeft: "10px"}}>
                    {modalityFrequencies.map((mod, index)=>(
                        <ModalityFrequencySetupCard options={allModalities} modalityId={mod.modalityId} key={index}/>
                    ))}
                    {!viewing &&
                        <button onClick={addModalityFrequency} className={styles.AddNotificationButton}>
                            <PlusIcon color="#00162E"/>Add
                        </button>
                    }
                </div>
            }
        </div>
    )
}

export default EnrollmentRules;