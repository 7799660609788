import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  header: '',
  message: '',
  dismissable: false,
  type: ''
};

const PerinHealtAlertSlice = createSlice({
  name: 'perinHealthAlert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      const { header, message, dismissable=false, type } = action.payload;
      return {
        ...state,
        show: true,
        header: header,
        message: message,
        dismissable: dismissable,
        type: type
      };
    },
    closeAlert: (state) => {
      return {
        ...state,
        show: false,
      };
    },
  },
});

export const { showAlert, closeAlert, setInputValue } = PerinHealtAlertSlice.actions;
export default PerinHealtAlertSlice.reducer;
