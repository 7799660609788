import React, { useState } from "react";
import { DropdownData } from '../../components/AccountManagement/DropdownData';
import '../../components/AccountManagement/Accounts.css';
import inputStyles from '../ManagementPages.module.css';

const ActivatePreferences = ({userData, handleChange}) => {

    const [unitType, setUnitType] = useState(userData.preferences.unitType);

    const handleUnitChange = (e) => {
        setUnitType(e.target.value);
        handleChange(e,null,"preferences");
    };


    return (
        <div style={{display: "flex", flexDirection:"column", gap:"10px"}}>
        <div>
            <p className="input-name" style={{color:"#5E5E5E"}}>Preferences</p>
            <div style={{display: "flex", flexDirection:"column", rowGap:"10px"}}>
                <div style={{position: "relative"}}>
                    <span className="floating-label">Date Format</span>
                    <select
                        className={inputStyles["styled-input-select"]}
                        id="dateFormat"
                        value={userData.preferences.dateFormat}
                        onChange={(e)=>handleChange(e, "dateFormat", "preferences")}
                    >
                        <option value="">Select Date Format</option>
                        {DropdownData.dateFormats.map((type) => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="unit-types" style={{display: "flex", width: "99%", justifyContent:"space-between", marginBottom:"20px"}}>
                    Unit Type
                    <div>
                        <input
                            id="unitType" 
                            type="radio"
                            value="Imperial"
                            checked={unitType === 'Imperial'}
                            onChange={handleUnitChange}
                        /> 
                        <span style={{paddingLeft: "5px"}}>Imperial (lbs, F)</span>
                    </div>
                    <div>
                        <input
                            id="unitType" 
                            type="radio"
                            value="Metric"
                            checked={unitType === 'Metric'}
                            onChange={handleUnitChange}
                        /> 
                        <span style={{paddingLeft: "5px"}}>Metric (kg, C)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}

export default ActivatePreferences;