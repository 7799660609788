import React, { useState, useRef } from 'react';
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { setDateRange } from './TasksSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './DateRangePicker.css';

const DateRangePicker = () => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [startMonth, setStartMonth] = useState(new Date());
  const [endMonth, setEndMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const inputRef = useRef(null);

  const handleShow = () => {
    const inputRect = inputRef.current.getBoundingClientRect();
    setModalPosition({
      top: inputRect.bottom,
      left: inputRect.left + window.scrollX,
    });
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  const handleSave = () => {
    const normalizeStartDate = (date) => {
      const normalizedDate = new Date(date);
      normalizedDate.setHours(0, 0, 0, 0); // Set to 12:00 AM
      return normalizedDate.getTime(); // Return as timestamp
    };

    const normalizeEndDate = (date) => {
      const normalizedDate = new Date(date);
      normalizedDate.setHours(23, 59, 59, 999); // Set to 11:59 PM
      return normalizedDate.getTime(); // Return as timestamp
    };

    if (startDate && endDate) {
      const normalizedStartDate = normalizeStartDate(startDate);
      const normalizedEndDate = normalizeEndDate(endDate);

      setInputValue(`${new Date(normalizedStartDate).toLocaleDateString()} - ${new Date(normalizedEndDate).toLocaleDateString()}`);
      dispatch(setDateRange({ key: 'startDate', value: new Date(normalizedStartDate).toLocaleDateString() }));
      dispatch(setDateRange({ key: 'endDate', value: new Date(normalizedEndDate).toLocaleDateString() }));
      dispatch(setDateRange({ key: 'startDateTimestamp', value: normalizedStartDate }));
      dispatch(setDateRange({ key: 'endDateTimestamp', value: normalizedEndDate }));
    } else if (startDate) {
      const normalizedStartDate = normalizeStartDate(startDate);

      setInputValue(`Start: ${new Date(normalizedStartDate).toLocaleDateString()}`);
      dispatch(setDateRange({ key: 'startDate', value: new Date(normalizedStartDate).toLocaleDateString() }));
      dispatch(setDateRange({ key: 'startDateTimestamp', value: normalizedStartDate }));
    } else if (endDate) {
      const normalizedEndDate = normalizeEndDate(endDate);

      setInputValue(`End: ${new Date(normalizedEndDate).toLocaleDateString()}`);
      dispatch(setDateRange({ key: 'endDate', value: new Date(normalizedEndDate).toLocaleDateString() }));
      dispatch(setDateRange({ key: 'endDateTimestamp', value: normalizedEndDate }));
    }
    handleClose();
  };



  const handleMonthChange = (direction) => {
    const newStartMonth = new Date(startMonth);
    const newEndMonth = new Date(endMonth);

    newStartMonth.setMonth(newStartMonth.getMonth() + direction);
    newEndMonth.setMonth(newEndMonth.getMonth() + direction);

    setStartMonth(newStartMonth);
    setEndMonth(newEndMonth);
  };

  const handleClearStartDate = () => {
    setStartDate(null);
    dispatch(setDateRange({ key: 'startDate', value: '' }));
    dispatch(setDateRange({ key: 'startDateTimestamp', value: '' }));
  };

  const handleClearEndDate = () => {
    setEndDate(null);
    dispatch(setDateRange({ key: 'endDate', value: '' }));
    dispatch(setDateRange({ key: 'endDateTimestamp', value: '' }));
  };

  return (
    <div style={{ position: 'relative', paddingLeft: '10px' }}>
      <Form.Control
        type="text"
        style={{ width: '15vw', height: '44px', position: 'relative' }}
        placeholder="Date"
        value={inputValue}
        onClick={handleShow}
        readOnly
        ref={inputRef}
      />
      <FontAwesomeIcon
        icon={faTimesCircle}
        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
          setInputValue('');
          dispatch(setDateRange({ key: 'startDate', value: '' }));
          dispatch(setDateRange({ key: 'endDate', value: '' }));
          dispatch(setDateRange({ key: 'startDateTimestamp', value: startDate }));
          dispatch(setDateRange({ key: 'endDateTimestamp', value: endDate }));
        }}
      />

      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          size='lg' backdrop={false}
          style={{
            maxWidth: '530px',
            width: '530px',
            top: modalPosition.top,
            left: modalPosition.left,
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', gap: '10px', position: 'relative', paddingLeft: '10px' }}>
              <FloatingLabel controlId="floatingStartDate" label="Start Date" className="mb-3">
                <Form.Control
                  type="text"
                  value={startDate ? startDate.toLocaleDateString() : ''}
                  readOnly
                />
                {startDate && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    onClick={handleClearStartDate}
                  />
                )}
              </FloatingLabel>
              <span style={{ display: 'inline-block', width: '40px', textAlign: 'center' }}>to</span>
              <FloatingLabel controlId="floatingEndDate" label="End Date" className="mb-3">
                <Form.Control
                  type="text"
                  value={endDate ? endDate.toLocaleDateString() : ''}
                  readOnly
                />
                {endDate && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    onClick={handleClearEndDate}
                  />
                )}
              </FloatingLabel>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <DatePicker
                selected={startDate}
                onChange={date => {
                  if (startDate && date > startDate) {
                    setEndDate(date);
                  } else {
                    setStartDate(date);
                  }
                }}
                isClearable={true}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                monthsShown={2}
                inline
                calendarStartDay={1}
                renderCustomHeader={({
                  monthDate,
                  customHeaderCount
                }) => (
                  <div className="custom-header">
                    <button
                      aria-label="Previous Month"
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
                      onClick={() => handleMonthChange(-1)}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                        {'<'}
                      </span>
                    </button>
                    <span className="react-datepicker__current-month">
                      {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </span>
                    <button
                      aria-label="Next Month"
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
                      onClick={() => handleMonthChange(1)}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                        {'>'}
                      </span>
                    </button>
                  </div>
                )}
                openToDate={startMonth}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default DateRangePicker;
