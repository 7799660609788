import React from 'react';
import styles from './PainLocationView.module.css';
import { BodyImageFront, BodyImageBack, LFHead, RFHead, LFNeck, RFNeck, LFArm, RFArm, LFForearm, RFForearm, LFHand, RFHand, LFTorsoUpper, RFTorsoUpper, LFTorsoCenter, RFTorsoCenter, LFTorsoLower, RFTorsoLower, LFLegUpper, RFLegUpper, LFLegLower, RFLegLower, LFFoot, RFFoot, LBHead, RBHead, LBNeck, RBNeck, LBArm, RBArm, LBForearm, RBForearm, LBHand, RBHand, LBTorsoUpper, RBTorsoUpper, LBTorsoCenter, RBTorsoCenter, LBTorsoLower, RBTorsoLower, LBLegUpper, RBLegUpper, LBLegLower, RBLegLower, LBFoot, RBFoot } from './icons/Icons';

const PainLocationView = ({painLocations}) => {

    const BodyPainFront = painLocations.front;
    const BodyPainBack = painLocations.back;

    return (
        <div className={styles.PainViewContainer}>
            <div className={styles.BodyContainer}>
            {/* Front Side */}
                <div className={styles.IconWrapper}>
                    <BodyImageFront />
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"95px", left: "86px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Head ? '1':'0.1'}}>
                        <RFHead />
                    </div>
                    <div className={styles.IconWrapper} style={{ marginLeft: "-14px", opacity: BodyPainFront.Left_Head ? '1':'0.1'}}>
                        <LFHead />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper}  style={{top:"142px", left: "71px"}} >
                    <div className={styles.IconWrapper} style={{opacity: BodyPainFront.Right_Neck ? '1':'0.1'}}>
                        <RFNeck />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-21px", opacity: BodyPainFront.Left_Neck ? '1':'0.1'}}>
                        <LFNeck />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"147px", left: "59px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Arm ? '1':'0.1'}}>
                        <RFArm />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "35px", opacity: BodyPainFront.Left_Arm ? '1':'0.1'}}>
                        <LFArm />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"197px", left: "53px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Forearm ? '1':'0.1'}}>
                        <RFForearm />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "43px", opacity: BodyPainFront.Left_Forearm ? '1':'0.1'}}>
                        <LFForearm />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"260px", left: "36px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainFront.Right_Hand ? '1':'0.1'}}>
                        <RFHand />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "60px", opacity: BodyPainFront.Left_Hand ? '1':'0.1'}}>
                        <LFHand />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"156px", left: "74px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Torso_Upper ? '1':'0.1'}}>
                        <RFTorsoUpper />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-19px", opacity: BodyPainFront.Left_Torso_Upper ? '1':'0.1'}}>
                        <LFTorsoUpper />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"181px", left: "75px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Torso_Center ? '1':'0.1'}}>
                        <RFTorsoCenter />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-19px", opacity: BodyPainFront.Left_Torso_Center ? '1':'0.1'}}>
                        <LFTorsoCenter />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"233px", left: "73px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainFront.Right_Torso_Lower ? '1':'0.1'}}>
                        <RFTorsoLower />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-21px", opacity: BodyPainFront.Left_Torso_Lower ? '1':'0.1'}}>
                        <LFTorsoLower />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"237px", left: "69px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Leg_Upper ? '1':'0.1'}}>
                        <RFLegUpper />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-23px", opacity: BodyPainFront.Left_Leg_Upper ? '1':'0.1'}}>
                        <LFLegUpper />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"308px", left: "79px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Leg_Lower ? '1':'0.1'}}>
                        <RFLegLower />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-14px", opacity: BodyPainFront.Left_Leg_Lower ? '1':'0.1'}}>
                        <LFLegLower />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"418px", left: "89px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainFront.Right_Foot ? '1':'0.1'}}>
                        <RFFoot />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-14px", opacity: BodyPainFront.Left_Foot ? '1':'0.1'}}>
                        <LFFoot />
                    </div>
                </div>
            </div>

            {/* Back Side */}
            <div className={styles.BodyContainer}>
                <div className={styles.IconWrapper}>
                    <BodyImageBack />
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"95px", left: "86px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainBack.Left_Head ? '1':'0.1'}}>
                        <LBHead />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-14px", opacity: BodyPainBack.Right_Head ? '1':'0.1'}}>
                        <RBHead />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"150px", left: "64px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainBack.Left_Neck ? '1':'0.1'}}>
                        <LBNeck />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-25px", opacity: BodyPainBack.Right_Neck ? '1':'0.1'}}>
                        <RBNeck />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"146px", left: "55px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainBack.Left_Arm ? '1':'0.1'}}>
                        <LBArm />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "39px", opacity: BodyPainBack.Right_Arm ? '1':'0.1'}}>
                        <RBArm />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"210px", left: "52px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Forearm ? '1':'0.1'}}>
                        <LBForearm />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "46px", opacity: BodyPainBack.Right_Forearm ? '1':'0.1'}}>
                        <RBForearm />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"260px", left: "35px"}}>
                    <div className={styles.IconWrapper} style={{opacity: BodyPainBack.Left_Hand ? '1':'0.1'}}>
                        <LBHand />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "60px", opacity: BodyPainBack.Right_Hand ? '1':'0.1'}}>
                        <RBHand />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"151px", left: "64px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Torso_Upper ? '1':'0.1'}}>
                        <LBTorsoUpper />
                    </div>
                    <div className={styles.IconWrapper} style={{ marginLeft: "-24px", opacity: BodyPainBack.Right_Torso_Upper ? '1':'0.1'}}>
                        <RBTorsoUpper />
                    </div>
                </div>
                 <div className={styles.BodySectionWrapper} style={{top:"216px", left: "71px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Torso_Center ? '1':'0.1'}}>
                        <LBTorsoCenter />
                    </div>
                    <div className={styles.IconWrapper} style={{ marginLeft: "-21px", opacity: BodyPainBack.Right_Torso_Center ? '1':'0.1'}}>
                        <RBTorsoCenter />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"245px", left: "66px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Torso_Lower ? '1':'0.1'}}>
                        <LBTorsoLower />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-24px", opacity: BodyPainBack.Right_Torso_Lower ? '1':'0.1'}}>
                        <RBTorsoLower />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"260px", left: "66px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Leg_Upper ? '1':'0.1'}}>
                        <LBLegUpper />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-24px", opacity: BodyPainBack.Right_Leg_Upper ? '1':'0.1'}}>
                        <RBLegUpper />
                    </div>
                </div>
                 <div className={styles.BodySectionWrapper} style={{top:"323px", left: "81px"}}>
                    <div className={styles.IconWrapper} style={{  opacity: BodyPainBack.Left_Leg_Lower ? '1':'0.1'}}>
                        <LBLegLower />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-16px", opacity: BodyPainBack.Right_Leg_Lower ? '1':'0.1'}}>
                        <RBLegLower />
                    </div>
                </div>
                <div className={styles.BodySectionWrapper} style={{top:"419px", left: "75px"}}>
                    <div className={styles.IconWrapper} style={{ opacity: BodyPainBack.Left_Foot ? '1':'0.1'}}>
                        <LBFoot />
                    </div>
                    <div className={styles.IconWrapper} style={{marginLeft: "-16px", opacity: BodyPainBack.Right_Foot ? '1':'0.1'}}>
                        <RBFoot />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PainLocationView;