import React, {useRef, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PatientEnrollmentGridOptionCard.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientInfo, setAddingProgram, setOrganizationId, setEnrollmentState, setFormValue, setGroups } from './PatientEnrollmentSlice';

const PatientEnrollmentGridOptionCard = ({ item, onClose, setPopup, buttonRef }) => {

    const enrolled = item.status === "Enrolled";
    const hasPreviousEnrollments = enrolled && item.previouslyEnrolledSelection.length !== 0;
    const previouslyEnrolled = item.status === "Previously Enrolled";
    const optionsContainerRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOptionClick = (popupType) => {
        if (popupType === 'adding') {
            dispatch(setPatientInfo(item.data[0].patient));
            dispatch(setAddingProgram(true));
            dispatch(setOrganizationId(item.organizationId));
            dispatch(setGroups(item.data[0].enrollment.groups));
            navigate('/enroll-patient');
        } else if (popupType === "update") {
            const updatingInfo = item.data[0];
            dispatch(setEnrollmentState(updatingInfo));
            dispatch(setFormValue({key:"updatingProgramOptions", value:item.data.filter((item) => !item.enrollment.dischargeDate)}));
            navigate('/enroll-patient');
            onClose();

        } else if (popupType === "view" && item.selectOptions.length === 1) {
            const viewingInfo = item.data[0];
            const groups = viewingInfo.enrollment.groups;
            const organizationId = viewingInfo.hasOwnProperty("organizationId") ? viewingInfo.organizationId : false;
            const newGroups = groups.map((group)=> {
                return organizationId ? {...group,organizationId:organizationId} : group
            });
            dispatch(setGroups(newGroups));
            dispatch(setEnrollmentState(viewingInfo));
            dispatch(setFormValue({key:"viewing", value:true}));
            dispatch(setFormValue({key:"updating", value:false}));
            navigate('/view-program');
            onClose();

        } else {
            setPopup({
                showing: true,
                item: item,
                type: popupType
            })
            onClose();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)) {
                    onClose();
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, []);

    return (
        <div ref={optionsContainerRef} className={styles['option-container']} style={{width:"225px", transform:"translateX(-65%)"}}>
            {enrolled && 
                <div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('adding')}>
                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                        <span className={styles['option-label']}>Add Care Program</span>
                    </div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('update')}>
                        <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                        <span className={styles['option-label']}>Update</span>
                    </div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('remove')}>
                        <FontAwesomeIcon icon="fa-solid fa-ban" />
                        <span className={styles['option-label']}>Discharge</span>
                    </div>
                    {hasPreviousEnrollments && 
                        <div className={styles['option-row']} onClick={()=>handleOptionClick('reenroll')}>
                            <FontAwesomeIcon icon="fa-solid fa-repeat" />
                            <span className={styles['option-label']}>Re-enroll in previous program</span>
                        </div>
                    }
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('view')}>
                        <FontAwesomeIcon icon="fa-regular fa-eye" />
                        <span className={styles['option-label']}>View (read-only)</span>
                    </div>
                </div>
            }
            {previouslyEnrolled &&
                <div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('reenroll')}>
                    <FontAwesomeIcon icon="fa-solid fa-plus"/>
                        <span className={styles['option-label']}>Re-enroll in previous program</span>
                    </div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('adding')}>
                        <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                        <span className={styles['option-label']}>Enroll in a new program</span>
                    </div>
                    <div className={styles['option-row']} onClick={()=>handleOptionClick('view')}>
                        <FontAwesomeIcon icon="fa-regular fa-eye" />
                        <span className={styles['option-label']}>View (read-only)</span>
                    </div>
                </div>
            
            }
        </div>
    );

}

export default PatientEnrollmentGridOptionCard;