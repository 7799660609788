import React, { useState, useEffect, useCallback, useRef } from "react";
import CalendarView from "./CalendarView";
import AppointmentModal from "./AppointmentModal";
import AppointmentForm from "./AppointmentForm";
import AppointmentDetail from "./AppointmentDetail";
import styles from "./Appointment.module.css";
import AvailabilityComponent from "./AvailabilityComponent";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ManagementStyles from '../ManagementPages.module.css';
import { Form } from "react-bootstrap";
import ChatContainer from '../../components/ChatContainer';
import { useSelector, useDispatch } from "react-redux";
import { LeftVector, RightVector, PlusAppointmentIcon, AppointmentIcon } from "../../components/icons/Icons";
import {setChatList, setChatVisible, setIsMeetingVisible, setNewChat, setRecipient, setSelectedIndex} from "../patient-message/PatientMessageSlicer";
import { addWeeks, subWeeks, addMonths, subMonths, addDays, subDays, format } from "date-fns";
import { setVideoCallVisibility } from '../../components/VideoAndMessageSlice';
import useAxios from "../../services/AxiosConfig";
import { setCallInformation, openCallContainer } from '../../slicers/CallContainerSlice';
import { setShowDetail, setSelectedAppointment } from './AppointmentDetailSlicer';
import { setAppointmentData } from './AppointmentSlicer';
import { useWebSocket } from '../../services/webSocket/useWebSocket';

function AppointmentCalendar() {
  const [activeModal, setActiveModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(new Date()); 
  const [physicians, setPhysicians] = useState([]);
  const [selectedPhysicianIds, setSelectedPhysicianIds] = useState([]);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [teamIds, setTeamIds] = useState("");
  const [viewType, setViewType] = useState("Week"); // Default to 'Week' view
  const [isChatVisible, setIsChatVisible] = useState(false);
  const patientMessage = useSelector((state) => state.patientMessage);
  const recipient = patientMessage.recipient;
  const { receivedMessage } = useWebSocket();

  const {showDetail, selectedAppointment } = useSelector((state) => state.appointmentDetail);
  // const {showDetail } = useSelector((state) => state.appointmentDetail);

  const dispatch = useDispatch();
  const axiosConfig = useSelector((state) => state.axiosConfig);
  const httpService = useAxios(axiosConfig.envURL, true);
  const userAccountId = useSelector((state) => state.userInfo.id);
  const userRole = useSelector((state) => state.userInfo.roleName);
  const user = useSelector((state) => state.userInfo);
  const openModal = (modalName) => setActiveModal(modalName);
  const closeModal = () => setActiveModal(null);
  

  const goToNextPeriod = () => {
    switch (viewType) {
      case 'Week':
        setCurrentWeek(current => addWeeks(current, 1));
        break;
      case 'Month':
        setCurrentWeek(current => addMonths(current, 1));
        break;
      case 'Day':
        setCurrentWeek(current => addDays(current, 1));
        break;
      default:
        break;
    }
  };
  
  const goToPreviousPeriod = () => {
    switch (viewType) {
      case 'Week':
        setCurrentWeek(current => subWeeks(current, 1));
        break;
      case 'Month':
        setCurrentWeek(current => subMonths(current, 1));
        break;
      case 'Day':
        setCurrentWeek(current => subDays(current, 1));
        break;
      default:
        break;
    }
  };

  const fetchAppointments = useCallback(async () => {
    if (!selectedPhysicianIds.length) return;
    try {
        const idsParam = selectedPhysicianIds.join(",");
        const response = await httpService.get(`/appointment?physicianIds=${encodeURIComponent(idsParam)}`);
        const validAppointments = response.data.data.filter(appointment => !appointment.deletedAt);
        setAppointments(validAppointments);
    } catch (error) {
        console.error("Failed to fetch appointments:", error);
    }
}, [selectedPhysicianIds, httpService]);

  const handleAppointmentClick = (appointment) => {
    dispatch(setSelectedAppointment(appointment));
    dispatch(setShowDetail(true));
  };

  const extractTeamIds = (groups) => {
    let ids = [];
    groups.forEach((group) => {
      if (group.subgroups) {
        group.subgroups.forEach((subgroup) => {
          if (subgroup.teams) {
            subgroup.teams.forEach((team) => {
              ids.push(team.teamId);
            });
          }
        });
      }
    });
    return ids;
  };

  useEffect(() => {
    const getTeamIds = async () => {
      let allIds = [];
      user.organizationsHierarchy.forEach((org) => {
        allIds = allIds.concat(extractTeamIds(org.groups));
      });
      setTeamIds(allIds.join(", "));
    };    
    setSelectedPhysicianIds([user.id]);
    getTeamIds();
  }, [user.id, user.organizationsHierarchy]);

  useEffect(() => {
    const fetchPhysicians = async () => {
      try {
        httpService
          .get(`/organization/team/staff?teamIds=${encodeURIComponent(teamIds)}`)
          .then((responseData) => {
            const formattedPhysicians = responseData.data.data.map((item) => ({
              value: item.AccountInfoMain_Id,
              label: item.FullName,
            }));
            setPhysicians(formattedPhysicians);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    if (teamIds) {
        fetchPhysicians();
        fetchAppointments();
    }
}, [teamIds, fetchAppointments, httpService]); 


  useEffect(() => {
    fetchAppointments();
  }, [selectedPhysicianIds, fetchAppointments]); // Refetch appointments when physician IDs change

  //Temporarily fetch appointments every 3 seconds 
  useEffect(() => {
    const intervalId = setInterval(() => {
          fetchAppointments();
      }, 3000); 

      return () => clearInterval(intervalId); 
  }, [fetchAppointments]);


  useEffect(() => {
    // Retrieve selected physician IDs from localStorage
    const savedSelectedPhysicianIds = localStorage.getItem('selectedPhysicianIds');
    if (savedSelectedPhysicianIds) {
      setSelectedPhysicianIds(JSON.parse(savedSelectedPhysicianIds));
    }
  }, []);

  useEffect(() => {
    // Store selected physician IDs in localStorage
    localStorage.setItem('selectedPhysicianIds', JSON.stringify(selectedPhysicianIds));
  }, [selectedPhysicianIds]);

  const onAppointmentCreated = () => {
    fetchAppointments(); 
  };

  const addAppointment = (appointment) => {
    setAppointments([...appointments, appointment]);
  };

  const editAppointment = (appointment) => {
    setCurrentAppointment(appointment); // Prepares the form with the appointment data
    setActiveModal("appointmentEdit"); // Opens the AppointmentForm in edit mode
    dispatch(setShowDetail(false));
  };

  const handleSetupAvailability = () => {
    setShowAvailabilityModal(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayDate = () => {
    switch (viewType) {
      case 'Day':
        return `${format(currentWeek, "MMMM yyyy")}`
      case 'Week':
        return `${format(currentWeek, "MMMM yyyy")}`; 
      case 'Month':
        return `${format(currentWeek, "MMMM yyyy")}`; 
      default:
        return format(currentWeek, "MMMM yyyy"); // Default to Month view if viewType is undefined
    }
  };

  const handleToggle = (value) => {
    const currentIndex = selectedPhysicianIds.indexOf(value);
    const newSelectedIds = [...selectedPhysicianIds];

    if (currentIndex === -1) {
      newSelectedIds.push(value);
    } else {
      newSelectedIds.splice(currentIndex, 1);
    }

    setSelectedPhysicianIds(newSelectedIds);
  };

  const fetchMessages = async (id) => {
    try {
        const responseData = await httpService.get(`chime/getAllMessageByUserId?userId=${userAccountId}&recipientType=PATIENT`);
        const patientIndex = responseData.data.getAllMessage.findIndex(arr =>
          arr.some(obj => obj.senderId === id || obj.recipientId === id)
        );
        if (patientIndex !== -1) {
          dispatch(setChatList(responseData.data.getAllMessage[patientIndex]));
        } else {
          dispatch(setChatList([]));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (receivedMessage || patientMessage.reloadMessageList) {
      fetchMessages(recipient.id);
    }
}, [receivedMessage, patientMessage.reloadMessageList]);

  const messagePatient = (appointment) => {
    dispatch(setShowDetail(false));
    dispatch(setSelectedIndex(0));
    fetchMessages(appointment.patientId);
    dispatch(setNewChat(false));
    dispatch(setIsMeetingVisible(false));
    dispatch(setRecipient({ 'id': appointment.patientId, name: appointment.patientName }));
    setIsChatVisible(true);
  };

  const handleContainerClose = () => {
    // closing chat container or chat video container
    setIsChatVisible(false);
    dispatch(setChatVisible(false));
    dispatch(setSelectedIndex(-1));
    dispatch(setChatList([]));
    dispatch(setNewChat(false));
  };

  const onStartChatCall = (audioOnly) => {
      setIsChatVisible(false)
      const callData = { 
        title: `Appointment Message Call`,
        appointmentId: null,
        senderId: userAccountId,
        recipientId: recipient.id,
        senderName: user.fullName,
        recipientName: recipient.name,
      };
      dispatch(setCallInformation({"audioOnly":audioOnly, "callData":callData,viewType: "patientMessage"}));
      dispatch(openCallContainer());
  };

  const handleStartVideoCall = (appointment) => {
    dispatch(setSelectedAppointment(appointment));
    dispatch(setAppointmentData({
      title: 'Test video call from appointments',
      appointmentId: appointment.appointmentId, // Assuming appointment has an id
      physicianId: appointment.physicianId,
      patientId: appointment.patientId,
      accountRole: userRole,
      accountName: user.fullName,
      patientName: appointment.patientName,
      meetingId: appointment.meetingId
    }));
  };

  return (
    <div>
     {/*  <h1 className={ManagementStyles.Title} style={{paddingLeft:"20px"}}>Appointments</h1> */}
      <div className={styles["appointments-container"]}>
        <div className={`${styles["header-row"]} ${styles["action-button"]}`}>
          <div className={styles["header-row-section"]}>
            <span className='subtitle-2' style={{color:"#4F4F4F"}}>{displayDate()} {" "}</span>
            <Button className={styles["header-vector-button"]} variant="primary" onClick={goToPreviousPeriod}>
              <LeftVector />
            </Button>
            <Button className={styles["header-vector-button"]} variant="primary" onClick={goToNextPeriod}>
              <RightVector />
            </Button>
            <div style={{ position: "relative", width:"140px"}}>
                <span className="floating-label">View</span>
                <select 
                    className={ManagementStyles["styled-input-select"]}
                    value={viewType} 
                    style={{width:"140px"}}
                    onChange={(e) => setViewType(e.target.value)}
                >
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                </select>
            </div>
          </div>
          <div className={styles["header-row-section"]}>
            <div className={styles["header-dropdown"]}>
                <Button
                  aria-controls="physicianList"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className={styles["header-dropdown-btn"]}
                >
                  <span className={styles["header-dropdown-btn-txt"]}>
                    Team Schedule{" "}
                  </span>{" "}
                  <FontAwesomeIcon
                    className={styles["header-dropdown-btn-icon"]}
                    icon="fa-solid fa-angle-down"
                    size="xs"
                  />
                </Button>
                <Menu
                  id="physicianList"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {physicians.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleToggle(option.value)}
                    >
                      <Checkbox
                        checked={selectedPhysicianIds.includes(option.value)}
                        tabIndex={-1}
                        disableRipple
                        value={option.value}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Menu>
            </div>
            <div
              className={styles["header-button-white"]}
              onClick={handleSetupAvailability}
            >
              <div className={styles["header-button-label-white"]}>
                <AppointmentIcon />
                <div>Setup Availability</div>
              </div>
            </div>
            <div className={styles["header-button"]}>
              <div
                className={styles["header-button-label"]}
                onClick={() => openModal("appointment")}
              >
                <PlusAppointmentIcon />
                <div>Book Appointment</div>
              </div>
            </div>
          </div>
        </div>
        <AppointmentModal isOpen={activeModal !== null} onClose={closeModal}>
          {activeModal === "appointment" && (
            <AppointmentForm
              onClose={closeModal}
              addAppointment={addAppointment}
              onAppointmentCreated={onAppointmentCreated}
              physicians={physicians}
              role={userRole}
              canManageAvailability={user.canManageAvailability}
              appointments={appointments}
            />
          )}
          {activeModal === "appointmentEdit" && (
            <AppointmentForm
              onClose={closeModal}
              addAppointment={addAppointment}
              onAppointmentCreated={onAppointmentCreated}
              editAppointment={editAppointment}
              currentAppointment={currentAppointment}
              physicians={physicians}
              role={userRole}
              canManageAvailability={user.canManageAvailability}
              appointments={appointments}
            />
          )}
        </AppointmentModal>
        <div style={{height:"85vh", display:"flex", overflow:"auto"}}>
          <CalendarView
            currentWeek={currentWeek}
            viewType={viewType}
            appointments={appointments}
            editAppointment={editAppointment}
            onAppointmentClick={handleAppointmentClick}
            physicians={physicians}
          />
        </div>
        {isChatVisible && (
          <div style={{bottom: 20, right: 30, position:"absolute", zIndex:"100", display:"flex"}}>
            <Form className={styles.ChatContainer}>
                <ChatContainer handleClose={handleContainerClose} onStartVideoCall={() => onStartChatCall(false)} onStartAudioCall={() => onStartChatCall(true)} />
            </Form>
          </div>
        )}
        <AppointmentDetail
          show={showDetail}
          onHide={() => dispatch(setShowDetail(false))}
          appointment={selectedAppointment}
          onStartCall={handleStartVideoCall}
          // onCancel={handleCancel}
          onEdit={editAppointment}
          onMessage={messagePatient}
          fetchAppointments={fetchAppointments}
          physicians={physicians}
        />
        <AvailabilityComponent
          show={showAvailabilityModal}
          onHide={() => setShowAvailabilityModal(false)}
          selectedPhysicianId={userAccountId}
          physicians={physicians}
          role={userRole}
          canManageAvailability={user.canManageAvailability}
        />
      </div>
    </div>
  );
}

export default AppointmentCalendar;
