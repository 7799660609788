import React, { useState } from 'react';
import styles from '../../Patient.module.css';
import { useDispatch, useSelector } from 'react-redux';
import inputStyles from '../../../ManagementPages.module.css';
import { DropdownData } from '../../../../components/AccountManagement/DropdownData';
import { showAlert } from '../../../../components/PerinHealthAlertSlice';

const PersonalInfoDetail= ({ setModal, setUpdateFn, setCancelFn }) => {

    const dispatch = useDispatch();
    const patient = useSelector((state)=>state.patient);
    const {firstName, lastName, email, countryCode, phone, address} = patient;
    const [showUpdateButtons, setShowUpdateButtons] = useState(false);
    const initialState = {
        firstName: firstName,
        lastName: lastName ,
        email: email,
        countryCode: countryCode,
        phone: phone,
        address: {
            type: address.type || "",
            address1: address.address1 || "",
            address2: address.address2 || "",
            city: address.city || "",
            state: address.state || "",
            country: address.country || "",
            zip: address.zip || "",

        }
    };
    const [ updatedInfo, setUpdatedInfo ] = useState(initialState);

    const handleChange = (e) => {
        const { id, value } = e.target;
        const tempData = {...updatedInfo};
        tempData[id] = value;
        setUpdatedInfo(tempData);
        if (!showUpdateButtons) {
            setShowUpdateButtons(true);
        }
    };

    const handleAddressChange = (e) => {
        const { id, value } = e.target;
        const tempData = {...updatedInfo};
        tempData.address[id] = value;
        setUpdatedInfo(tempData);
        if (!showUpdateButtons) {
            setShowUpdateButtons(true);
        }
    };

    const handleUpdate = () => {
        const updatingInfo = {
            ...patient,
            ...updatedInfo,
        };
        const updateFn = () => {
            dispatch(
                showAlert({
                    header: "Success",
                    message: "Personal Info updated",
                    type: 'success',
                })
            );
            setShowUpdateButtons(false);
        }
        setUpdateFn(()=>updateFn);
        setCancelFn(()=>handleCancel);
        setModal({title:"Personal Info", message:`Update ${firstName} ${lastName}'s personal info?`, data:updatingInfo});
    };

    const handleCancel = () => {
        setUpdatedInfo(initialState);
        setShowUpdateButtons(false);
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"15px"}}>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="firstName"
                    value={updatedInfo.firstName}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.firstName && inputStyles['filled']}>
                    First Name*
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input 
                    id="lastName"
                    value={updatedInfo.lastName}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.lastName && inputStyles['filled']}>
                    Last Name*
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="email"
                    value={updatedInfo.email}
                    onChange={handleChange}
                ></input>
                <label className={updatedInfo.email && inputStyles['filled']}>
                    Email*
                </label>
            </div>
            <div style={{display:"flex",width: "300px",justifyContent:"space-between", height:"44px"}}>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="countryCode"
                        type="number"
                        style={{width:"120px"}}
                        value={updatedInfo.countryCode}
                        onChange={handleChange}
                    ></input>
                    <label className={updatedInfo.countryCode && inputStyles['filled']}>
                        Country Code
                    </label>
                </div>
                <div className={inputStyles["styled-input-container"]}>
                    <input
                        id="phone"
                        type="number"
                        style={{width:"170px"}}
                        value={updatedInfo.phone}
                        onChange={handleChange}
                    ></input>
                    <label className={updatedInfo.phone && inputStyles['filled']}>
                        Phone Number*
                    </label>
                </div>
            </div>
            <div style={{position: "relative"}}>
                <span className="floating-label">Address Type</span>
                <select
                    className={inputStyles["styled-input-select"]}
                    id="type"
                    value={updatedInfo.address.type}
                    onChange={handleAddressChange}
                >
                    <option value="">Address Type</option>
                    {DropdownData.addressTypes.map((type) => (
                        <option key={type.value} value={type.value}>
                            {type.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="address1"
                    value={updatedInfo.address.address1}
                    onChange={handleAddressChange}
                ></input>
                <label className={updatedInfo.address.address1 && inputStyles['filled']}>
                    Street Address (Ave, St, Blvd, etc.)
                </label>
            </div>
            <div className={inputStyles["styled-input-container"]}>
                <input
                    id="address2"
                    value={updatedInfo.address.address2}
                    onChange={handleAddressChange}
                ></input>
                <label className={updatedInfo.address.address2 && inputStyles['filled']}>
                Apartment, Suite, etc
                </label>
            </div>
            <div style={{display:"flex", flexDirection:"column", width: "300px"}}>
                <div className="input-block">
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="city"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.address.city}
                            onChange={handleAddressChange}
                        ></input>
                        <label className={updatedInfo.address.city && inputStyles['filled']}>
                        City
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="state"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.address.state}
                            onChange={handleAddressChange}
                        ></input>
                        <label className={updatedInfo.address.state && inputStyles['filled']}>
                        State
                        </label>
                    </div>
                </div>
                <div className="input-block" style={{marginBottom:"0px"}}>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            id="country"
                            type="text"
                            style={{width:"147px"}}
                            value={updatedInfo.address.country}
                            onChange={handleAddressChange}
                        ></input>
                        <label className={updatedInfo.address.country && inputStyles['filled']}>
                        Country
                        </label>
                    </div>
                    <div className={inputStyles["styled-input-container"]}>
                        <input
                            style={{width:"147px"}}
                            type="number"
                            id="zip"
                            value={updatedInfo.address.zip}
                            onChange={handleAddressChange}
                        ></input>
                        <label className={updatedInfo.address.zip && inputStyles['filled']}>
                        Postal Code
                        </label>
                    </div>
                </div>
            </div>
            {showUpdateButtons &&
                <div style={{display:"flex", width: "100%", justifyContent:"space-between"}}>
                    <button variant='primary' className={styles.ModelButton} onClick={handleUpdate} >Save</button>
                    <button variant='secondary' className={styles.SecondaryButton} onClick={handleCancel} >Cancel</button>
                </div>
            }
        </div>
    );
}

export default PersonalInfoDetail;