import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import inputStyles from '../views/program-management/ProgramManagement.module.css';
import styles from './ManagementDropdownCard.module.css';
import { EnrollmentDropdowns } from './EnrollmentDropdown';

const QuestionnaireSetupCard = ({isQuestionnaire = false, id, dataList, setCardData, errorShowing, disabled, viewOnly}) => {

    const dispatch = useDispatch();
    const data = dataList.find((item)=> (isQuestionnaire ? item.questionnaireId : item.contentId) === id);
    const initStartTime = () => {
        let startStr = data.startTime;
        let startInt = 0;
        let interval = "AM";
        if (startStr !== ""){
            if (!startStr.includes('Z')) {
                startStr = startStr + '.000Z';
            }
            const localDate = new Date(startStr);
            const hour = localDate.getHours();
            startInt = hour % 12 || 12;
            interval = hour >= 12 ? "PM" : "AM";
        }
        return {startInt:startInt, interval:interval};
    };

    let [startTimeView, setStartTimeView] = useState(initStartTime);

    let frequencyErrorMessage = "Please provide a valid interval: \n Day: 1-365, Min: 1-60, Hour: 1-24"
    let frequencyErrorShowing = errorShowing && ( data.frequency <= 0 ||
        (data.interval === "Day" && data.frequency > 365) ||
        (data.interval === "Min" && data.frequency > 60) ||
        (data.interval === "Hour" && data.frequency > 24)
    );

    let startTimeErrorMessage = "Please provide a valid start time"
    let startTimeErrorShowing = errorShowing && data.startTime === "";

    const convertStartTime = (hour12, period) => {
        let hour24 = hour12;
        if (hour12 === -1) {
            return "";
        }
        if (period === 'PM' && hour12 !== 12) {
            hour24 += 12; // Add 12 hours for PM
        } else if (period === 'AM' && hour12===12){
            hour24 = 0;
        }
        // Construct the ISO string
        const currentDate = new Date();
        currentDate.setHours(hour24,0,0,0);
        let utcTimeStamp = "";
        try {
            utcTimeStamp = currentDate.toISOString();
        } catch (error) {
            console.error(error);
        }
        return utcTimeStamp;
    };

    const handleStartTime = (e) => {
        let name = e.target.id;
        let value = e.target.value;
        // check if the value is between 0-24
        const regex = /^(0?[0-9]|1[0-2])$/;
        if (name === "startInt") {
            if (value === "" || !(regex.test(value))) {
                value = -1;
            } else {
                value = Number(value);
            }
        }
        setStartTimeView({
            ...startTimeView,
            [name]: value
        });
    
        // Calculate ISO string and dispatch action
        const isoString = convertStartTime(
            name === "startInt" ? value : startTimeView.startInt,
            name === "interval" ? value : startTimeView.interval
        );
        dispatch(setCardData({ id: id, startTime: isoString, frequency: data.frequency, interval: data.interval }));
    };

    const handleInterval = (e) => {
        // in case user did not actually select 'day' but using that as interval
        const newInterval = data.interval === '' ? "Day" : data.interval;
        dispatch(setCardData({id:id, interval:newInterval, frequency:Number(e.target.value), startTime:data.startTime}));
    };

    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"100%", height:"100%", padding:"20px"}}>
            <span className={styles.Interval}>Interval</span>
            <div className={styles.IntervalRow}>
                <span className={styles.Label} style={{width:"90px"}}>
                    {`Start Time${!viewOnly ? "*" : ":"}`}
                </span>
                {viewOnly ?
                    <div>
                        {`${startTimeView.startInt} ${startTimeView.interval}`}
                    </div>
                    :
                    <>
                        <input 
                            style={(errorShowing && startTimeErrorShowing) ? {border:"1px solid red", width:"50px"} : {width:"50px"}}
                            className={inputStyles.InputStyling} 
                            id="startInt"
                            maxLength={2}
                            disabled={disabled}
                            defaultValue={startTimeView.startInt}
                            onChange={handleStartTime}
                        >
                        </input>
                        <select className={inputStyles.InputStyling} 
                            style={(errorShowing && startTimeErrorShowing) ? {border:"1px solid red", width:"140px"} : {width:"140px"}}
                            id="interval"
                            defaultValue={startTimeView.interval}
                            onChange={handleStartTime}
                            disabled={disabled}
                        >
                            {EnrollmentDropdowns.timeIntervals.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </>
                }
                {startTimeErrorShowing && 
                    <span className={inputStyles.ValidationMessage}>
                        {startTimeErrorMessage}
                    </span>
                }
            </div>
            <div className={styles.IntervalRow}>
                <span className={styles.Label} style={{width:"90px"}}>
                    {`Repeat Every${!viewOnly ? "*" : ":"}`}
                </span>
                {viewOnly ?
                    <div>
                        {`${data.frequency} ${data.interval}`}
                    </div>
                    :
                    <>
                    <input 
                        className={inputStyles.InputStyling} 
                        style={frequencyErrorShowing ? {border:"1px solid red", width:"50px"} : {width:"50px"}}
                        defaultValue={data.frequency}
                        maxLength={3}
                        onChange={handleInterval}
                        disabled={disabled}
                    ></input>
                    <select 
                        className={inputStyles.InputStyling} 
                        style={frequencyErrorShowing ? {border:"1px solid red", width:"140px"} : {width:"140px"}}
                        defaultValue={data.interval}
                        disabled={disabled}
                        onChange={(e) => dispatch(setCardData({id:id, interval:e.target.value, frequency:data.frequency, startTime:data.startTime}))}
                    >
                        {EnrollmentDropdowns.repeatIntervals.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </>}
                {frequencyErrorShowing &&
                    <span className={inputStyles.ValidationMessage}>
                        {frequencyErrorMessage}
                    </span>
                }
            </div>
        </div>
    )
}

export default QuestionnaireSetupCard;