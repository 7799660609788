import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './Chart.module.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { StepsIcon } from '../../../components/icons/Icons';
import { useSelector } from 'react-redux';
import ChartDetailView from './ChartDetailView';

const StepCardView = React.forwardRef(({ className, healthEvaluationData, vitalName }, ref) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const { startDate, endDate } = useSelector((state) => state.patientDetailFilter);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const vitals = useSelector((state) => state.vital.modalities[vitalName]);
  const [opacity, setOpacity] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const vitalsExpanded = useSelector((state) => state.patientDetailFilter.vitalsExpanded);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.offsetWidth;
        const width = containerWidth;
        setChartWidth(width);

        const height = 200; // Set a fixed height
        setChartHeight(height);
      }
    };
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateData = useCallback(() => {
    // Group data by date and deviceName
    const groupedData = healthEvaluationData.reduce((acc, entry) => {
      const date = entry.date;
      if (!acc[date]) {
        acc[date] = { date };
      }
      acc[date][entry.deviceName] = entry;
      return acc;
    }, {});

    const transformedData = Object.values(groupedData);
    transformedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    setData(transformedData);
    const deviceOpacity = healthEvaluationData.reduce((acc, entry) => {
      const { deviceName } = entry;

      if (!acc[deviceName]) {
        acc[deviceName] = [];
      }
      acc[deviceName].push(entry);

      return acc;
    }, {});

    setOpacity(Object.keys(deviceOpacity).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {}));
    setDisplayData(getLatestMeasurement(healthEvaluationData, deviceOpacity));
  }, [healthEvaluationData]);

  useEffect(() => {
    updateData();
  }, [updateData, startDate, endDate]);

  const handleLegendClick = (e) => {
    const deviceName = e.value;
    setOpacity((op) => ({ ...op, [deviceName]: op[deviceName] === 1 ? 0 : 1 }));
  };

  const getLatestMeasurement = useCallback((healthEvaluationData) => {
    if (!healthEvaluationData || healthEvaluationData.length === 0) {
      return { measurement: 'No Data', latestDate: 'N/A' };
    }

    const deviceNamesData = healthEvaluationData.filter(entry => entry.steps !== null);
    const latestMeasurement = deviceNamesData.reduce((latest, entry) => {
      const dateA = new Date(latest.completedDate);
      const dateB = new Date(entry.completedDate);
      return dateB > dateA ? entry : latest;
    }, deviceNamesData[0]);

    return {
      measurement: latestMeasurement?.steps !== undefined ? latestMeasurement?.steps + ' Steps'  : 'No Data',
      latestDate: 'Latest: ' + (latestMeasurement?.date  || 'N/A'),
    };
  }, []);

  const deviceNames = Array.from(new Set(healthEvaluationData.map(entry => entry.deviceName)));

  const deviceNamesData = deviceNames.filter(deviceName =>
    healthEvaluationData.some(entry => entry.deviceName === deviceName && entry.steps !== null)
  );

  const colors = {
    0: '#496CA3',
    1: '#478CAA',
  };

  const handleBarClick = (data, index) => {
    setModalContent({ ...data, modalityId: vitals.modalityId, vital: vitals.modalityName });
    setIsModalOpen(true);
  };

  return (
    <div className={`${styles.cardContainer} ${className}`} ref={chartContainerRef}>
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <StepsIcon className={styles.icon} />
          <span className={styles.vitalName}>{vitals.modalityName}</span>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.measurement}>
            <span className={styles.value}>{displayData.measurement}</span>
          </div>
        </div>
        <div className={`${styles.headerSection} ${styles.divider}`}>
          <div className={styles.latestDate}>{displayData.latestDate}</div>
        </div>
      </div>
      <div className={styles.chartWrapper} ref={ref}>
        <BarChart width={chartWidth} height={chartHeight} data={data} margin={{left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize={12} />
          <YAxis 
            domain={[0, 2500]} 
            ticks={[0, 500, 1000, 1500, 2000, 2500]} 
            tickCount={6}
            fontSize={12}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            height={30}
            iconType="square"
            align={"right"}
            wrapperStyle={{ color: 'red', fontSize: '14px' }}
            payload={
              deviceNamesData.map((deviceName, index) => ({
                value: deviceName,
                type: 'square',
                color: colors[index] || '#000000',
            }))}
            onClick={handleLegendClick}
          />
          {deviceNamesData.map((deviceName, index) => (
            <Bar
              key={deviceName}
              dataKey={deviceName + '.steps'}
              fill={opacity[deviceName] === 1 ? `rgba(73, 108, 163, ${opacity[deviceName]})` : 'none'}
              strokeOpacity={opacity[deviceName] || 0}
              onClick={handleBarClick}
            />
          ))}
        </BarChart>
      </div>
      <ChartDetailView
        isModalVisible={isModalOpen}
        customModal={styles.customModal}
        handleCloseModal={() => setIsModalOpen(false)}
        modalData={modalContent}
      />
    </div>
  );
});

export default StepCardView;
